import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JobCategory } from '../../classes/job';
import { UserService } from '../../services/user-service.service';
import { ClientService } from '../../partners/services/client.service';

@Component({
  selector: 'app-setting-jobcategory',
  templateUrl: './setting-jobcategory.component.html',
  styleUrls: ['./setting-jobcategory.component.scss']
})
export class SettingJobcategoryComponent implements OnInit {

  @Input() category: JobCategory;
  @Output() editCategory: EventEmitter<JobCategory> = new EventEmitter();
  @Output() deleteCategory: EventEmitter<JobCategory> = new EventEmitter();

  constructor(
    public userService: UserService,
    public clientService: ClientService
  ) { }

  ngOnInit() {

  }

  deleteValue(category: JobCategory) {
    this.deleteCategory.emit(category);
  }

  editValue(category: JobCategory) {
    this.editCategory.emit(category);
  }

}
