import { TranslateService } from '@ngx-translate/core';
import { ClientAnalyticsService } from './../../../services/client-analytics.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Filter } from '../../../library/classes/filter';
import { COLOR_VARIABLES } from './../../../utils/color-variables';

@Component({
  selector: 'app-partner-analytics-industries',
  templateUrl: './partner-analytics-industries.component.html',
  styleUrls: ['./partner-analytics-industries.component.scss']
})
export class PartnerAnalyticsIndustriesComponent implements OnInit, OnDestroy {

  _parameters: Filter[];

  @Input() set parameters(parameters: Filter[]) {
    this._parameters = parameters;
    this.getIndustriesData();
  }

  get parameters(): Filter[] {
    return this._parameters;
  }

  loading = false;

  clientsByIndustry: {
    labels: string[],
    data: { [key: string]: number }[]
  } = {
      labels: [],
      data: [],
    };

  columnsConfig = {
    chart: {
      height: 300,
      spacingTop: 50
    },
    colors: [COLOR_VARIABLES['$_CHART_MEDIUM_BLUE']],
    yAxis: {
      visible: true,
      labels: {
        enabled: true,
        style: {
          color: COLOR_VARIABLES['$_AUX_GREY_15']
        }
      },
      title: {
        enabled: false
      }
    },
  };

  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private clientAnalyticsService: ClientAnalyticsService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getIndustriesData() {
    setTimeout(() => this.loading = true, 0);

    this.componentSubscriptions.industries$ = this.clientAnalyticsService.getGroupIndustriesAnalytics(...this.parameters).subscribe(
      data => setTimeout(() => this.serializeIndustriesData(data), 300),
      error => this.loading = false
    );
  }

  serializeIndustriesData(data: { total: number, industry: string }[]) {
    this.clientsByIndustry.labels = data.map(industry => this.translateService.instant('__' + industry.industry));
    this.clientsByIndustry.data = data.map(industry => ({ [this.translateService.instant('__' + industry.industry)]: industry.total }));

    setTimeout(() => this.loading = false, 0);
  }

}
