import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user-service.service';


@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(public auth: UserService, public router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.goToMyPath(childRoute, state);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.goToMyPath(route, state);
  }

  goToMyPath(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const shouldNavigateToLogin = !this.auth.checkIsLogged() || state.url === '/';

    if (shouldNavigateToLogin) {

      if (state.url && state.url != "/") {
        this.router.navigate(['login'], { queryParams: { refer: state.url } });
      } else {
        this.router.navigate(['login']);
      }

      return false;
    }

    return this.auth.checkHasPermissions(route.data.permission, route.data.customPermissions || null);
  }

}
