import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateService } from '../../services/candidate.service';
import { Candidate } from '../../classes/candidate';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { InstrumentsService } from '../instruments.service';
import { AlertsService } from '../../services/alerts.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cute-done',
  templateUrl: './cute-done.component.html',
  styleUrls: ['./cute-done.component.scss']
})
export class CuteDoneComponent implements OnInit, OnDestroy {

  infoDialogTerms = '';
  hideInfoDialog = true;
  client: Client = new Client();
  logo_url: string;
  currentLanguage: string;
  loading = false;
  currentPrivacy: string;
  checked = false;
  errorValidate = false;
  modalEnabled = false;
  candidate_uuid: string;
  error_404 = false;

  instruments: any[] = [];

  @ViewChild('privacyModal', { read: TemplateRef, static: true }) privacyPolicyModal: TemplateRef<any>;
  privacyPolicyModalRef: BsModalRef;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private candidateService: CandidateService,
    private clientService: ClientService,
    private instrumentService: InstrumentsService,
    private alertService: AlertsService,
    private modalService: BsModalService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.loading = false;
    this.candidate_uuid = this.activatedRoute.snapshot.paramMap.get('uuid');
    this.getCandidateHub(this.candidate_uuid);
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getCandidateHub(uuid) {
    this.componentSubscriptions.candidateServiceGetCandidateHub$ = this.candidateService.getCandidateHub(uuid)
      .subscribe(
        data => {
          this.instruments = this.checkSpecificConfigurationInstruments(data);
          this.getClientDetails(data[0].client_uuid);
          this.instruments.filter(instrument => instrument.languages[0])
            .forEach(instrument => instrument.currentLanguage = instrument.languages[0].value);
        },
        error => {
          this.error_404 = error
          console.log(error)
        }) ;
  }

  checkSpecificConfigurationInstruments(data: any) {
    data.forEach(element => {
      element.name = element.config.config.name ? element.config.config.name : element.name;
      element.description = element.config.config.description ? element.config.config.description : element.description;
    });
    return data;
  }

  getClientDetails(client_uuid) {
    this.componentSubscriptions.clientServiceGetPublicClient$ = this.clientService.getPublicClient(client_uuid)
      .subscribe(
        client => {
          this.client = Object.assign(client);
          this.client.uuid = client_uuid;
          this.setClientFont();
          this.setClientFavicon();
        },
        error => console.log(error)
      )
  }

  setClientFont() {
    const sheet: any = document.styleSheets[10];
    const rules = sheet.cssRules || sheet.rules;

    rules[1].style.fontFamily =
      this.client.extra_config.appearance.font.primary ?
        this.client.extra_config.appearance.font.primary : 'Roboto, sans-serif';
  }

  setClientFavicon() {
    const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = this.client.extra_config.appearance.favicon ? this.client.extra_config.appearance.favicon : 'favicon.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  showDetailsDialog() {
    this.infoDialogTerms = 'lleno';
    setTimeout(() => {
      this.hideInfoDialog = false;
    }, 10);
  }

  closeInfoDialog() {
    this.hideInfoDialog = true;
    setTimeout(() => {
      this.infoDialogTerms = '';
    }, 500);
  }

  allInstrumentsAreDone() {
    return this.instruments.length > 0 && this.instruments.every(instrument => instrument.status === 'OK' || instrument.status === 'DON');
  }

  setupLanguageInstrument(instrument) {
    const privacy = instrument.config.config.privacy == null ? "0" : "1";
    if (instrument.config.config.privacy == null || (instrument.config.config.privacy && this.checked == true)) {
      this.loading = true;
      this.componentSubscriptions.instrumentServiceSetTupInstrumentUrl$s = this.instrumentService.setupInstrumentUrl(instrument.id, instrument.currentLanguage, privacy)
        .subscribe(
          data => {
            this.checked = false;
            if (!data.error) {
              window.location.href = data.url;
            } else {
              const message = data.error;
              this.alertService.setAlert({ type: 'error', message });
              this.checked = false;
              this.loading = false;
            }
          }
        );
    } else {
      this.errorValidate = true;
    }
  }

  showPolicyPrivacyModal(modalTemplate, currentPrivacy: string) {
    const modalText = this.translateService.instant(currentPrivacy, { 'client_name': this.client.name });
    this.currentPrivacy = modalText;
    this.privacyPolicyModalRef = this.modalService.show(modalTemplate, { backdrop: 'static' });
  }

  closePolicyPrivacyModal() {
    this.privacyPolicyModalRef.hide();
  }

  showHelpModal() {
    this.modalEnabled = true;
  }
  closedHelpModal(closed: boolean) {
    this.modalEnabled = closed;
  }

}
