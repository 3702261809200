import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-candidate-interview-vidassess-ai',
  templateUrl: './candidate-interview-vidassess-ai.component.html',
  styleUrls: ['./candidate-interview-vidassess-ai.component.scss']
})
export class CandidateInterviewVidassessAiComponent implements OnInit {
  
  @Input() report: any;
  name: string;
  chart : {data: { [key: string]: number[]}, labels: any[]} = {data:{'candidate': [], 'profile_reference': []}, labels:[]};
  constructor() { }

  ngOnInit() {
    this.name = Object.keys(this.report)[0];
    this.serializeDataForCharts(Object.values(this.report)[0]);
  }

  serializeDataForCharts(report) {
    let scoresValues = Object.entries(report).filter(element => !element[0].includes("__")).filter(element => element[0] != "type");
    this.chart.labels =  scoresValues.map(element => {return element[0]})
    const scores = scoresValues.map(element => {return +element[1]})
    this.chart.data.candidate = scores;
  }

}
