import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Profession } from '../../classes/profession';
import { UserService } from '../../services/user-service.service';
import { ProfessionService } from '../../services/profession.service';
import { AlertsService } from '../../services/alerts.service';
import { SortPipe } from '../../pipes/sort.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Filter } from '../../library/classes/filter';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';

@Component({
  selector: 'app-profession-table',
  templateUrl: './profession-table.component.html',
  styleUrls: ['./profession-table.component.scss'],
  providers: [SortPipe]
})
export class ProfessionTableComponent implements OnInit, OnDestroy {

  @Output() showWizard: EventEmitter<any> = new EventEmitter();
  @Output() profession_emit: EventEmitter<Profession> = new EventEmitter();

  loading = true;

  client_id: number;
  professions: Profession[] = [];
  client: Client = new Client();
  profession_active: number;
  inActionProfession: Profession = null;

  modalRef: BsModalRef;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private professionService: ProfessionService,
    private router: Router,
    private alertService: AlertsService,
    private sortPipe: SortPipe,
    public userService: UserService,
    public clientService: ClientService,
    private modalService: BsModalService
  ) { }


  ngOnInit() {
    this.client_id = +this.activatedRoute.parent.snapshot.paramMap.get('client');
    this.getProfessions();
    this.subscribeParameters();
    this.getClient();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  subscribeParameters() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(params => {
      this.profession_active = +params.get('profession');
    });
  }

  getProfessions() {
    this.componentSubscriptions.professionServiceList$ = this.professionService.list(new Filter('group', this.client_id))
      .subscribe(
        profession_list => {
          this.professions = profession_list;
          if (this.professions.length > 0) {
            this.professions = this.sortPipe.transform(this.professions, ['name', 'id']);
            this.getProfession(this.professions[0].id);
          }
          this.loading = false;
        }
      );
  }

  getProfession(id: number) {
    const queryParams = { relativeTo: this.activatedRoute, queryParams: { profession: id } };
    this.router.navigate([], queryParams);
  }

  getClient() {

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(this.client_id)
      .subscribe(
        response => this.client = response,
        error => console.log(error)
      );
  }

  updateProfession(profession: Profession) {
    this.showWizard.emit({ show: true, type: Profession.UPDATE_ACTION, profession });
  }

  cloneProfession(profession: Profession) {
    this.showWizard.emit({ show: true, type: Profession.CLONE_ACTION, profession });
  }

  deleteProfession(profession: Profession) {
    this.componentSubscriptions.professionServiceRemove$ = this.professionService.remove(profession.id)
      .subscribe(
        () => {
          this.modalRef.hide();

          const profession_index = this.professions.indexOf(profession);
          this.professions.splice(profession_index, 1);
          this.profession_emit.emit(this.professions.length > 0 ? this.professions[0] : null);
          this.inActionProfession = null;
          this.alertService.setAlert({ type: 'success', message: '__profDeletedOk' });
        },
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error)));
  }

  showModalAndSetInActionProfession(profession: Profession, modalTemplate) {
    this.inActionProfession = profession;
    this.modalRef = this.modalService.show(modalTemplate, { backdrop: 'static' });
  }

}
