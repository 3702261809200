export const COLOR_VARIABLES = {

  '$_PRIMARY_COLOR': '#6A48C7',
  '$_PRIMARY_COLOR_LIGHT': '#875CFF',
  '$_PRIMARY_COLOR_LIGHTER': '#BFA9FF',
  '$_PRIMARY_COLOR_DARK': '#543F8F',
  '$_ANOTHER_PRIMARY_COLOR': '#4c3983',
  '$_SECONDARY_COLOR': '#5A9FDB',
  '$_SECONDARY_COLOR_HOVER': '#72afe6',
  '$_AUX_PURPLE_1': '#F6F4F8',
  '$_AUX_PURPLE_5': '#ece9f8',
  '$_AUX_PURPLE_10': '#cdc6e5',
  '$_AUX_PURPLE_15': '#9C92D4',
  '$_AUX_PURPLE_20': '#766CAB',
  '$_AUX_PURPLE_25': '#3C3866',
  '$_AUX_PURPLE_30': '#3A276E',
  '$_INACTIVE_COLOR_FILL': '#cdc6e5',
  '$_INACTIVE_COLOR_BORDER': '#5941a9',
  '$_TOOLBAR_COLOR': '#474570',
  '$_TOOLBAR_TEXT_COLOR': '#c8c6ec',
  '$_TOOLBAR_AUX_COLOR': '#575489',
  '$_TABLE_ACTIONS_BACKGROUND': '#EEEEFE',
  '$_AUX_GREY_1': '#F4F6F8',
  '$_AUX_GREY_5': '#E8EAF1',
  '$_AUX_GREY_10': '#ededed',
  '$_AUX_GREY_12': '#d8dee5',
  '$_AUX_GREY_15': '#B4B4B4',
  '$_AUX_GREY_20': '#8c929d',
  '$_AUX_GREY_25': '#50526B',
  '$_AUX_GREY_30': '#4A4A4A',
  '$_AUX_GREY_35': '#2b344b',
  '$_SECONDARY_COLOR_DARK': '#2b344b',
  '$_AUX_GRAY_COLOR': '#f5f6f7',
  '$_DIV_LINE_COLOR': '#ededed',
  '$_TEXT_PRIMARY_COLOR': '#2b344b',
  '$_TEXT_COLOR_LIGHT': '#8c929d',
  '$_ERROR_COLOR': '#ef4051',
  '$_SUCCESS_COLOR': '#24a91c',
  '$_WARNING_COLOR': '#e49115',
  '$_APPROVED_COLOR': '#3292E6',
  '$_APPROVED_COLOR_LIGHT': '#D9EDFF',
  '$_REJECTED_COLOR': '#C0BEBE',
  '$_REJECTED_COLOR_LIGHT': '#E5E7E9',
  '$_PENDING_COLOR': '#F78600',
  '$_PENDING_COLOR_MEDIUM': '#ffc46f',
  '$_PENDING_COLOR_LIGHT': '#FFEBC2',
  '$_AUX_COLOR_LIGHT': '#f8f9fa',
  '$_AUX_COLOR_DARK': '#ededed',
  '$_AUX_COLOR_BLUE': '#4c83f2',
  '$_AUX_COLOR_RED': '#FF9180',
  '$_AUX_COLOR_GREEN': '#45CB85',
  '$_AUX_COLOR_TURQUOISE': '#3e93b7',
  '$_AUX_COLOR_ORANGE': '#ffbf34',
  '$_AUX_COLOR_RED_LIGHT': '#fdeae8',
  '$_AUX_COLOR_GREEN_LIGHT': '#eaf6eb',
  '$_AUX_COLOR_ORANGE_LIGHT': '#fff3e6',
  '$_SIDEBAR_SETTINGS_COLOR': '#f8f9fa',
  '$_SECONDARY_BUTTON_COLOR': '#b1b3bb',
  '$_CHART_ORANGE': '#ff9e43',
  '$_CHART_LIGTH_GREEN': '#a5f1d0',
  '$_CHART_LIGTH_BLUE': '#bad6fc',
  '$_CHART_MEDIUM_BLUE': '#83BBFF',
  '$_CHART_PURPLE': '#727dea',
  '$_CHART_GREYISH_LIGHT_BLUE': '#d5dff4',
  '$_CHART_GREYISH_LIGHT_PURPLE': '#bebccf',
  '$_CHART_LIGHT_PURPLE': '#98b0ff',
  '$_CHART_DARK_PURPLE': '#3f4592',
  '$_CHART_PINK': '#f1b1cc',
}