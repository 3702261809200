
export class InstrumentReport {

    instruments: number[];
    language: string;
    report_id: number;
    slug: string;
    description: string;
    is_feedback: boolean;
    normset: string;
}