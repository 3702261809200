import { PARTNER_URL } from '../constants';
import { RestService } from '../../library/classes/rest-service';
import { Injectable } from '@angular/core';
import { Partner } from '../models/partner';
import { Filter } from '../../library/classes/filter';
import { Observable } from 'rxjs';
import { buildFilter } from '../../library/utils';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class PartnerService extends RestService<Partner> {
  endpoint = PARTNER_URL;

  getPartnerlist(...filters: Filter[]): Observable<HttpResponse<Partner[]>> {
    const filter = buildFilter(...filters);
    const url = `${this.endpoint}${filter}`;
    return this.httpClient.get<Partner[]>(url, { observe: 'response' });
  }
}
