import { ClientService } from '../../services/client.service';
import { Subject } from 'rxjs';
import { Filter } from '../../../library/classes/filter';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router/';
import { UserService } from '../../../services/user-service.service';
import { HttpResponse } from '@angular/common/http';
import { setFilter } from '../../../library/utils';
import { Client } from '../../models/client';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-client-table',
  templateUrl: './client-table.component.html',
  styleUrls: ['./client-table.component.scss']
})

export class ClientTableComponent implements OnInit, OnDestroy {
  loading = true;
  sorted = true;

  clientParent: string;

  elements: any = {
    elements: [],
    headers: []
  };

  parameters: Filter[] = [];

  searchTerm = new Subject<string>();
  total = 0;
  name_order = 1;

  showCreateClient = false;

  search = '';
  selectedDashboard: number = null;

  showingAnalytics = false;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.clientParent = this.activatedRoute.snapshot.queryParams.parent;
    this.startSearch();
    this.parameters = setFilter(
      this.parameters,
      new Filter('partner', this.clientParent)
    );
    this.getClients();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  startSearch(): void {
    this.componentSubscriptions.searchTermPipe$ = this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((search: string) => {
          this.parameters = setFilter(this.parameters, new Filter('page', 1));
          this.parameters = setFilter(
            this.parameters,
            new Filter('search', search)
          );

          return this.clientService.getClientlist(...this.parameters);

        }))

      .subscribe(clients => this.saveElementsTable(clients));
  }

  createClient() {
    this.showCreateClient = true;
  }

  sortName() {
    this.name_order = this.name_order <= 0 ? 1 : -1;
    this.parameters = setFilter(this.parameters, new Filter('ordering', this.name_order <= 0 ? '-name' : 'name'));
    this.getClients();
  }

  saveElementsTable(clients: HttpResponse<Client[]>) {
    this.total = +clients.headers.get('count');
    this.elements.elements = clients.body;

    const elements_excluded = ['name', 'id'];
    this.elements.headers = Object.keys(this.elements.elements[0] || {}).filter(
      key => !elements_excluded.includes(key)
    );

    this.loading = false;
  }

  getClients() {
    this.componentSubscriptions.getClientList$ = this.clientService
      .getClientlist(...this.parameters)
      .subscribe(clients => this.saveElementsTable(clients));
  }

  clientSaved() {
    this.getClients();
    this.showCreateClient = false;
  }

  changePage(event: { page, itemsPerPage }) {
    this.parameters = setFilter(this.parameters, new Filter('page', event.page));
    this.getClients();
  }

  openDashboard(id: number) {
    this.selectedDashboard = id;
  }
}
