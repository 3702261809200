import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from "../../services/user-service.service";
import { ClientService } from '../../partners/services/client.service';

@Component({
  selector: 'app-main-settings-client',
  templateUrl: './main-settings-client.component.html',
  styleUrls: ['./main-settings-client.component.scss']
})
export class MainSettingsClientComponent implements OnInit {

  current_url = 'setting-client/';
  constructor(
    private router: Router,
    private clientService: ClientService,
    private activateRoute: ActivatedRoute,
    private userService: UserService,
  ) { }

  ngOnInit() {
    if (this.activateRoute.children.length === 0) {
      this.router.navigate([this.router.url, 'settings']);
    }
    this.current_url += this.activateRoute.snapshot.params.client;
  }



}
