import { InterviewGuides } from './interview';
import { Candidate } from './candidate';
import { Profession } from './profession';
import { StatusPhases, PhaseConfig } from './phases';
import { EmailState } from './wsgi-email';
import { INSTRUMENT_TYPES } from '../instruments/constants';
import { ReportConfig } from './reportConfig';

export class Job {
  static JOB_PRI = 'PRI';
  static JOB_PUB = 'PUB';
  static JOB_BTH = 'BTH';
  static JOB_DRA = 'DRA';
  static TEXT_PRI = '__private';
  static TEXT_PUB = '__public';
  static TEXT_BTH = '__publicAndPrivate';
  static TEXT_DRA = '__draft';
  static JOB_AUTO_TRANS_YES = 'YES';
  static JOB_AUTO_TRANS_NO = 'NO';
  static TEXT_TRANS_YES = 'enabled';
  static TEXT_TRANS_NO = 'disabled';
  static CREATE_ACTION = 'create';
  static UPDATE_ACTION = 'update';
  static CLONE_ACTION = 'clone';
  static REMOVE_ACTION = 'remove';
  static CLOSE_ACTION = 'close';

  static MORNING = 'MORNING';
  static AFTERNOON = 'AFTERNOON';
  static NIGHT = 'NIGHT';
  static SPLIT = 'SPLIT';
  static ROTATING = 'ROTATING';
  static WEEKEND = 'WEEKEND';
  static COMPLETE = 'COMPLETE';

  static PARTIAL = 'PARTIAL';
  static CONTINUOUS = 'CONTINUOUS';
  static FULL = 'FULL';

  static CLOSED = 'closed';
  static ACTIVE = 'active';
  static EXPIRED = 'expired';

  static PRIVACIES = [
    { value: Job.JOB_PUB, title: Job.TEXT_PUB },
    { value: Job.JOB_PRI, title: Job.TEXT_PRI },
    { value: Job.JOB_DRA, title: Job.TEXT_DRA },
    { value: Job.JOB_BTH, title: Job.TEXT_BTH },
  ];

  static STATUSES = [
    { value: 'active', title: '__active' },
    { value: 'closed', title: '__closed' },
    { value: 'expired', title: '__expired' }
  ];

  static WORK_DAYS = [
    { value: 'MON', title: '__monday', checked: false},
    { value: 'TUE', title: '__tuesday', checked: false},
    { value: 'WED', title: '__wednesday', checked: false},
    { value: 'THU', title: '__thursday', checked: false},
    { value: 'FRI', title: '__friday', checked: false},
    { value: 'SAT', title: '__saturday', checked: false},
    { value: 'SUN', title: '__sunday', checked: false},
  ]


  static SHIFT_TURN_CHOICES = [
    { value: Job.MORNING, title: '__morning'},
    { value: Job.AFTERNOON, title: '__afternoon'},
    { value: Job.NIGHT, title: '__night'},
    { value: Job.SPLIT, title: '__split'},
    { value: Job.ROTATING, title: '__rotating'},
    { value: Job.WEEKEND, title: '__weekend'},
    { value: Job.COMPLETE, title: '__complete'},
  ];

  static SHIFT_TIME_CHOICES = [
    { value: Job.PARTIAL, title: '__partial'},
    { value: Job.CONTINUOUS, title: '__continuous'},
    { value: Job.FULL, title: '__full'},
  ];

  static CLOSE_CAUSES = [
    '__closeJobCause1',
    '__closeJobCause2',
    '__closeJobCause4',
    '__closeJobCause5',
    '__closeJobCause6'
  ];
  static HIRED_CLOSE_CAUSES = [
    '__hiredCloseJobCause1',
    '__hiredCloseJobCause2'
  ];

  id?: number;
  name = '';
  description = '';
  internal_code = '';
  landing: number;
  landing_name: string;
  form: any;
  interview_guides: InterviewGuides = new InterviewGuides();
  emails: any = new EmailState();
  group: number;
  profession: number;
  privacy: string;
  instruments: any[] = [];
  auto_transitions: string;
  expiration_time: Date | string;
  always_opened: boolean = false;
  closed: Date | string;
  profession_id: string;
  profession_name: string;
  categories: any;
  categories_data: any[] = [];
  instruments_data: any[];
  slug?: string;
  candidates?: Candidate[];
  transitions: { INS, CAN, PHO, HR, OA, BUS, HIR, total, suitable };
  banner = 'https://s3-eu-west-1.amazonaws.com/aplygo-saas/media/demo-bg.jpg';
  phases: StatusPhases = new StatusPhases();
  phase_config: PhaseConfig = new PhaseConfig();
  emails_notifications: { 'email': string } = { 'email': '' };
  social_media: { 'description': string, 'image': string } = { 'description': '', 'image': '' };
  landing_url = '';
  selected?: boolean;
  multiposting_config?: any;
  working_days?: string;
  vacancies?: number;
  shift_turn?: string;
  shift_time?: string;
  reports_config?: ReportConfig[];

  setMultipostingConfig(config) {
    this.multiposting_config = {
      job_title: config.job_title,
      positions: config.positions,
      level: config.level,
      contract: config.contract,
      salary: {
        min: config.salary_min,
        max: config.salary_max,
        time_range: config.salary_time_range,
        show_salary: config.show_salary
      },
      description: config.description,
      minimum_studies: config.minimum_studies,
      minimum_experience: config.minimum_experience,
      location: {
        country: config.location_country,
        city: config.location_city,
        post_code: config.location_post_code
      },
    }
  }

  saveReportsConfig(job: Job) {
    let reportsSelected = [];
    for (const report of job.reports_config){
      let reportForJob = new ReportConfig(report.id, report.slug, report.description, report.instruments, report.is_feedback, report.language, report.normset, report.origin, report.report_id);
      reportForJob.selected = true;
      reportForJob.visible = true;
      reportsSelected.push(reportForJob);
    }
    return reportsSelected;
  }

  static buildFromJob(job: Job) {
    const newJob = Object.assign(new Job(), job);

    newJob.categories = job.categories_data.length > 0 ? job.categories_data : undefined;
    newJob.form = job.form.form || job.form;

    newJob.setInstrumentData(job.instruments_data);
    newJob.reports_config = newJob.saveReportsConfig(job)
    newJob.profession = +job.profession_id;
    newJob.banner = newJob.banner ? newJob.banner : 'https://s3-eu-west-1.amazonaws.com/aplygo-saas/media/demo-bg.jpg';
    newJob.phase_config = PhaseConfig.buildFromPhaseConfig(job.phase_config);
    return newJob;
  }

  setProfession(profession: Profession) {
    this.form = profession.form.form || profession.form;
    this.interview_guides = profession.interview_guides;
    this.phase_config = Object.keys(profession.phase_config)
      .length > 0 ?
      profession.phase_config : new PhaseConfig();

    this.setInstrumentData(profession.instruments_data);

    this.instruments_data = this.instruments;

    this.privacy = Job.JOB_PUB;
    this.auto_transitions = Job.JOB_AUTO_TRANS_YES;
    this.emails = profession.emails || new EmailState();
    this.emails_notifications = profession.emails_notifications;
  }

  getJobLocation(): JobCategory {
    return (this.categories || [])
      .find(jobCategory => jobCategory.category === CATEGORIES_TYPES.LOCATION);
  }

  private setInstrumentData(instruments_data: any): void {
    this.instruments = instruments_data
      .filter(instrument => instrument.origin !== INSTRUMENT_TYPES.FORMS)
      .map(instrument => ({
        id: instrument.instrument_id,
        config: instrument.config,
        origin: instrument.origin,
        provider_origin: instrument.provider_origin,
        phase: instrument.phase
      }));
  }

}

export class JobCategoryData {
  id?: number;
  key: string = CATEGORIES_DATA_TYPES.NAME;
  value: string = '';
}

export class JobCategory {

  public static CATEGORIES = [
    { title: 'organization', value: 'ORG' },
    { title: 'function', value: 'FUN' },
    { title: 'location', value: 'LOC' },
    { title: 'profession', value: 'PRO' }
  ];

  id?: number;
  value: string;
  category: string;
  group?: number;
  category_data: JobCategoryData[] = [];

  constructor(category: string, value?: string, id?: number, category_data?: JobCategoryData[], group?: number) {
    this.category = category;
    this.value = value;
    this.id = id;
    this.category_data = category_data || [];
    this.group = group;
  }

  getJobCategoryFormData() {

    let dict = {}
    this.category_data.forEach(element => dict[element.key] = element);

    switch(this.category) {
      case CATEGORIES_TYPES.LOCATION:
        this.category_data = [
          {
            id: dict[CATEGORIES_DATA_TYPES.NAME] ? dict[CATEGORIES_DATA_TYPES.NAME].id : undefined,
            key: CATEGORIES_DATA_TYPES.NAME,
            value: dict[CATEGORIES_DATA_TYPES.NAME] ? dict[CATEGORIES_DATA_TYPES.NAME].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.IDENTIFIER] ? dict[CATEGORIES_DATA_TYPES.IDENTIFIER].id : undefined,
            key: CATEGORIES_DATA_TYPES.IDENTIFIER,
            value: dict[CATEGORIES_DATA_TYPES.IDENTIFIER] ? dict[CATEGORIES_DATA_TYPES.IDENTIFIER].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.ADDRESS] ? dict[CATEGORIES_DATA_TYPES.ADDRESS].id : undefined,
            key: CATEGORIES_DATA_TYPES.ADDRESS,
            value: dict[CATEGORIES_DATA_TYPES.ADDRESS] ? dict[CATEGORIES_DATA_TYPES.ADDRESS].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.POSTAL_CODE] ? dict[CATEGORIES_DATA_TYPES.POSTAL_CODE].id : undefined,
            key: CATEGORIES_DATA_TYPES.POSTAL_CODE,
            value: dict[CATEGORIES_DATA_TYPES.POSTAL_CODE] ? dict[CATEGORIES_DATA_TYPES.POSTAL_CODE].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.CITY] ? dict[CATEGORIES_DATA_TYPES.CITY].id : undefined,
            key: CATEGORIES_DATA_TYPES.CITY,
            value: dict[CATEGORIES_DATA_TYPES.CITY] ? dict[CATEGORIES_DATA_TYPES.CITY].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.PROVINCE_NAME] ? dict[CATEGORIES_DATA_TYPES.PROVINCE_NAME].id : undefined,
            key: CATEGORIES_DATA_TYPES.PROVINCE_NAME,
            value: dict[CATEGORIES_DATA_TYPES.PROVINCE_NAME] ? dict[CATEGORIES_DATA_TYPES.PROVINCE_NAME].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.PROVINCE_ID] ? dict[CATEGORIES_DATA_TYPES.PROVINCE_ID].id : undefined,
            key: CATEGORIES_DATA_TYPES.PROVINCE_ID,
            value: dict[CATEGORIES_DATA_TYPES.PROVINCE_ID] ? dict[CATEGORIES_DATA_TYPES.PROVINCE_ID].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.COUNTRY] ? dict[CATEGORIES_DATA_TYPES.COUNTRY].id : undefined,
            key: CATEGORIES_DATA_TYPES.COUNTRY,
            value: dict[CATEGORIES_DATA_TYPES.COUNTRY] ? dict[CATEGORIES_DATA_TYPES.COUNTRY].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.LATITUDE] ? dict[CATEGORIES_DATA_TYPES.LATITUDE].id : undefined,
            key: CATEGORIES_DATA_TYPES.LATITUDE,
            value: dict[CATEGORIES_DATA_TYPES.LATITUDE] ? dict[CATEGORIES_DATA_TYPES.LATITUDE].value : '',
          },
          {
            id: dict[CATEGORIES_DATA_TYPES.LONGITUDE] ? dict[CATEGORIES_DATA_TYPES.LONGITUDE].id : undefined,
            key: CATEGORIES_DATA_TYPES.LONGITUDE,
            value: dict[CATEGORIES_DATA_TYPES.LONGITUDE] ? dict[CATEGORIES_DATA_TYPES.LONGITUDE].value : '',
          }
        ];
        break;
      default:
        this.category_data = [
          { id: dict[CATEGORIES_DATA_TYPES.NAME] ? dict[CATEGORIES_DATA_TYPES.NAME].id : undefined,
            key: CATEGORIES_DATA_TYPES.NAME,
            value: dict[CATEGORIES_DATA_TYPES.NAME] ? dict[CATEGORIES_DATA_TYPES.NAME].value : '',},
        ]
    }
  }

  validateCategoryData() {

    // Remove categories data that have no value
    let all_categories_data = {};
    this.category_data.forEach(element => all_categories_data[element.key] = element);

    let valid_categories_data: JobCategoryData[] = [];


    for(let category_data_key in all_categories_data) {
      if(all_categories_data[category_data_key].value) {
        valid_categories_data = valid_categories_data.concat(all_categories_data[category_data_key]);
      }
    }

    this.category_data = valid_categories_data;
  }

  static jobCategoryToTemplate(jobCategories: JobCategory[]) {

    const jobCategoriesTemplate = JobCategory.startJobCategories();

    // Add all elements.

    jobCategories.forEach((jobCategory: JobCategory) => {

      const jobCategoryFound = JobCategory.getNameJobCategory(jobCategory.category);

      if (jobCategoryFound) {
        jobCategoriesTemplate.find(
          value => value.category === jobCategoryFound).values.push({ value: jobCategory.value, id: jobCategory.id });
      }
    });

    // Sort all elements.

    for (let i = 0; i < jobCategoriesTemplate.length; i++) {
      jobCategoriesTemplate[i].values.sort((a, b) => (a.value > b.value) ? 1 : -1);
    }

    // Return elements,

    return jobCategoriesTemplate;
  }

  static getNameJobCategory(code: string): string {
    const jobCategory = JobCategory.CATEGORIES.find(category => code === category.value);
    return jobCategory ? jobCategory.title : null;
  }

  static startJobCategories(): JobCategoryTemplate[] {
    return JobCategory.CATEGORIES.map(category =>
      new Object({ category: category.title, 'values': [] }) as JobCategoryTemplate
    );
  }

  static removeCategoriesAll(jobCategories: JobCategoryTemplate[]): JobCategoryTemplate[] {
    const categoriesWithoutAll = jobCategories.map(categoryGroup => {
      categoryGroup.values = categoryGroup.values.filter(category => category.value.toUpperCase() !== 'ALL');
      return categoryGroup;
    })
    return categoriesWithoutAll;
  }
}

export interface JobCategoryTemplate {
  category: string;
  values: { value: string, id?: number }[];
}

export enum CATEGORIES_TYPES {
  ORGANIZATION = 'ORG',
  LOCATION = 'LOC',
  FUNCTION = 'FUN',
  PROFESSION = 'PRO'
}

export const CATEGORIES_TYPES_LABELS = {
  ORG: 'organization',
  LOC: 'location',
  FUN:'function',
  PRO: 'profession'
}

export enum CATEGORIES_DATA_TYPES {
  NAME = "NAME",
  IDENTIFIER = "IDEN",
  ADDRESS = "ADDR",
  POSTAL_CODE = "PCOD",
  CITY = "CITY",
  PROVINCE_NAME = "PRON",
  PROVINCE_ID = "PROI",
  COUNTRY = "CTRY",
  LATITUDE = "LATI",
  LONGITUDE = "LONG",
}
