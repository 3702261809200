import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../../../services/user-service.service';
import { User } from '../../../classes/user';
import { UserDataService } from '../../../services/user-data-service.service';
import { ALERT_TYPES, AlertsService } from '../../../services/alerts.service';
import { RecoverPasswordService } from '../../../services/recover-password.service';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent implements OnInit, OnDestroy {

  profileForm: FormGroup;
  user: User;

  componentSubscriptions: any = {};

  constructor(
    private form: FormBuilder,
    private userService: UserService,
    private userDataService: UserDataService,
    private recoverService: RecoverPasswordService,
    private alertService: AlertsService
  ) { }

  ngOnInit() {

    this.user = this.userService.getUser();
    this.createForm();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  createForm() {
    this.profileForm = this.form.group({
      first_name: [this.user.first_name],
      last_name: [this.user.last_name],
      email: [this.user.email]
    });
  }

  saveUserInfo() {
   
    this.user.first_name = this.profileForm.get('first_name').value;
    this.user.last_name = this.profileForm.get('last_name').value;
    this.componentSubscriptions.userDataServicePartialUpdateUser$ = this.userDataService.partialUpdateUser(this.user).subscribe(res => this.user = res)
    this.userService.saveUser();
  }

  recover(): void {
    this.recoverService.resetPassword(this.user.email).then(
      response => {
        this.alertService.setAlert({type:ALERT_TYPES.SUCCESS, message:'__modifyEmailSent'})
      },
      error => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error));
      }
    );
  }

}
