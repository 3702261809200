import { INDUSTRIES, ACTIVE } from '../../../partners/constants';
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { UserService } from '../../../services/user-service.service';
import { AlertsService, ALERT_TYPES } from '../../../services/alerts.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../services/client.service';
import { Client } from '../../models/client';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit, OnDestroy {

  client = new Client();
  industries = INDUSTRIES;
  logoUrl = null;
  logoFile: File = null;
  publicMailSender = '';

  @Input() type;

  @Output() saved = new EventEmitter<any>();

  componentSubscriptions: any = {};

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.client.parent = this.getClientParent();
  }

  cancel() {
    this.saved.emit({
      hide: true,
      saved: false,
      error: false
    });
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  async createClient() {
    this.client.terms_and_conditions = '';
    this.client.extra_config.appearance.logo = this.client.logo;
    this.client.status = ACTIVE;
    this._createClient();
  }

  _createClient() {
    this.componentSubscriptions.clientServiceCreate$ = this.clientService.create(this.client);
    if (this.type === 'client') {
      this.updateEntityEmailSender();
    }
    this.clientService.create(this.client)
      .subscribe(
        response => {
          this.saved.emit({
            saved: true,
            hide: true,
            error: false
          });
          this._showSuccessAlert();
        },
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
      );
  }

  updateEntityEmailSender() {
    this.client.email_sender = this.publicMailSender.concat('@aplygo.com');
  }

  async _showSuccessAlert() {
    const translate_type = this.type === 'Client' ? this.translationService.instant('__client') :
      this.translationService.instant('__partner');
    this.componentSubscriptions.translationServiceGet$ = this.translationService.get('__typeCreatedOK', { type: translate_type })
      .subscribe((message: string) => {
        this.alertService.setAlert({ type: ALERT_TYPES.SUCCESS, message });
      });
  }

  getClientParent() {
    switch (this.type) {
      case 'partner':
        return null;
      case 'client':
        const clientParent = this.activatedRoute.snapshot.queryParams.parent;
        return clientParent ? clientParent : this.userService.getUser()
          .group.id;
    }
  }

}
