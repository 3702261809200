import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { LayoutService, LayoutActions } from '../../services/layout.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-dashboard-candidates-pool',
  templateUrl: './dashboard-candidates-pool.component.html',
  styleUrls: ['./dashboard-candidates-pool.component.scss']
})
export class DashboardCandidatesPoolComponent implements OnInit, OnDestroy {
  ks: string;
  subscription: Subscription;
  candidateSelected = false;
  selectedRow: number;
  layoutActions = LayoutActions;

  componentSubscriptions: any = {};

  constructor(private keyboardShortcutService: KeyboardShortcutService, private router: Router, private activatedRoute: ActivatedRoute, public layoutService: LayoutService) { }
  ngOnInit() {
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
    this.subscribeCandidateParam();
    this.layoutService.handleLayoutServiceAction(LayoutActions.RESET_JOB_DASHBOARD);
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'JobDashboardComponent.AltS':
        this.goToSettings();
        break;
    }
  }
  goToSettings() {
    const client = this.activatedRoute.snapshot.paramMap.get('client');
    this.router.navigate(['setting-client', client]);
  }
  subscribeCandidateParam() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(queryParam => {
      this.candidateSelected = Boolean(queryParam.get('candidate'));
      if (!this.candidateSelected) {
        this.selectedRow = null;
      }
    });
  }
}
