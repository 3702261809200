import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-box',
  templateUrl: './no-data-box.component.html',
  styleUrls: ['./no-data-box.component.scss']
})
export class NoDataBoxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
