export class Step {
  id: number;
  name: string;
  requirements: string[] | null;

  constructor(id: number, name: string, requirements: string[] | null){
    this.id = id;
    this.name = name;
    this.requirements = requirements;
  }
}
