import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges, AfterViewChecked } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { slugify } from '../../library/utils';
import { COLOR_VARIABLES } from './../../utils/color-variables';
declare const Highcharts: any;

@Component({
  selector: 'app-bars',
  templateUrl: './bars.component.html',
  styleUrls: ['./bars.component.scss']
})
export class BarsComponent implements OnInit, AfterViewChecked {

  @Input() data: any;
  //@Input() data: {data:number, name:string, color:string}[];
  @Input() labels: string[];
  @Input() name: string;
  @Input() extra?: any;
  @Input() type?= 'column';

  config = {
    chart: {
      type: '',
      reflow: true
    },
    colors: [COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT'], COLOR_VARIABLES['$_PENDING_COLOR_MEDIUM'], COLOR_VARIABLES['$_AUX_COLOR_TURQUOISE']],
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [],
      crosshair: true
    },
    yAxis: {
      min: 0
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0px">{series.name}: </td>' +
        '<td style="padding:0px"><b>{point.y:1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: []
  };

  constructor(
    private hostElement: ElementRef,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.bars-chart__content'), this.config);
  }

  ngAfterViewChecked() {
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }

  _setData() {
    this.config.series.push({
      name: this.translateService.instant('__' + slugify(this.data.name)),
      data: this.data.values.map(value => value.data)
    });

    this.config.chart.type = this.type;
    this.config.xAxis.categories = this.labels.map(label => this.translateService.instant(label));
    this.config = Object.assign(this.config, this.extra || {});
  }

}
