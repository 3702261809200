import { OnInit, Component, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientService } from "../../partners/services/client.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-unsubscription-landing',
  templateUrl: './unsubscription-landing.component.html',
  styleUrls: ['./unsubscription-landing.component.scss'],
})
export class UnsubscriptionLandingComponent implements OnInit, OnDestroy {

  subscriptionUuid: string;
  client: any;
  hub: string;
  error_404: boolean = false;

  @ViewChild('unsubscribeModal', { static: true }) unsubscribeModal;
  modalRef: BsModalRef;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {

    this.subscriptionUuid = this.activatedRoute.snapshot.params.uuid;

    this.componentSubscriptions.clientServiceGetClientFromSubscription$ = this.clientService.getClientFromSubscription(this.subscriptionUuid)
      .subscribe(config => {
        this.client = config;
        this.clientService.saveClient(this.client);

        this.hub = this.client.origin === 'PRI' ?
          `https://${this.client.domain}/#/private-jobs-hub/${this.client.client_uuid}` :
          `https://${this.client.domain}/#/jobs-hub/${this.client.client_uuid}`;
      },
        error => {
          this.error_404 = true;
        }
      )
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  showUnsubscribeModal() {
    this.modalRef = this.modalService.show(this.unsubscribeModal, { class: '', backdrop: 'static' });
    this.componentSubscriptions.clientServiceUnsubscribe$ = this.clientService.unsubscribe(this.subscriptionUuid)
      .subscribe();
  }
}
