import { COLOR_VARIABLES } from './../../utils/color-variables';
import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { unSlugify, slugify } from '../../library/utils';
import { TranslateService } from '@ngx-translate/core';
import { LiteralTranformPipe } from '../../pipes/literal-transform.pipe';
declare const Highcharts: any;

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LiteralTranformPipe]
})
export class RadarChartComponent implements OnInit, OnChanges {

  @Input() data: { [key: string]: number[] } = { candidate: [0] };
  @Input() labels: string[];
  @Input() name: string;
  @Input() max = 10;
  @Input() height = 250;

  config = {

    chart: {
      polar: true,
      type: 'area',
      animation: false,
      height: '250px',
      reflow: true
    },

    title: {
      text: '',
      x: -80
    },

    pane: {
      size: '90%'
    },

    credits: {
      enabled: false
    },

    xAxis: {
      categories: [],
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        allowOverlap: true,
        crop: false,
        distance: 18,
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
          textTransform: 'capitalize',
          fontSize: '11px',
          letterSpacing: '-0.2px',
          fontFamily: '"Roboto", sans-serif'
        }
      }
    },

    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      max: this.max,
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'vertical',
            y: 70,
          }
        }
      }]
    },

    plotOptions: {
      line: {
        animation: true
      }
    },
    tooltip: {
      shared: true,
      pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
    },
    series: []

  };

  constructor(
    private hostElement: ElementRef,
    private translateService: TranslateService,
    private literalTransform: LiteralTranformPipe
  ) { }

  ngOnInit() {
    this.config.chart.height = `${this.height}px`;
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.radar-chart__content'), this.config);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes.data.currentValue) !== JSON.stringify(changes.data.previousValue)) {
      this._setData();
      this.hostElement.nativeElement.querySelector('.radar-chart__content').innerHtml = '';
      Highcharts.chart(this.hostElement.nativeElement.querySelector('.radar-chart__content'), this.config);
    }
  }

  _setData() {
    this.config.yAxis.max = this.max;
    const colors = [COLOR_VARIABLES['$_CHART_PURPLE'], COLOR_VARIABLES['$_AUX_COLOR_ORANGE'], COLOR_VARIABLES['$_AUX_COLOR_TURQUOISE']];
    this.config.series = Object.keys(this.data).map((value, index) =>
    ({
      'name': this.translateService.instant('__' + slugify(value).toLowerCase()),
      'data': this.data[value], pointPlacement: 'on', color: colors[index]
    }));
    this.config.xAxis.categories = this.labels.map(label => this.translateService.instant(this.literalTransform.transform(unSlugify(label))));
  }
}
