import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { JobService } from '../../services/job.service';
import { AlertsService } from '../../services/alerts.service';
import { Constants } from '../../classes/constants';

@Component({
  selector: 'app-upload-img',
  templateUrl: './upload-img.component.html',
  styleUrls: ['./upload-img.component.scss']
})
export class UploadImgComponent implements OnInit, OnDestroy {

  @Input() imgUrl: string;
  @Output() imgUrlChange: EventEmitter < string > = new EventEmitter < string > ();

  @Input() defaultImgUrl: string;

  @Input() title: string = '';
  @Input() comments: string = '';
  @Input() imgIsPrivate = true;
  @Input() allowRemoveImg: boolean = true;

  loading: boolean = false;

  componentSubscriptions: any = {};

  constructor(
    private jobService: JobService,
    private alertService: AlertsService,
  ) {}

  ngOnInit() {
    if (!this.imgUrl && this.defaultImgUrl) {
      this.imgUrl = this.defaultImgUrl;
    }
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  canRemoveImg() {
    return this.imgUrl && this.allowRemoveImg && this.defaultImgUrl !== this.imgUrl;
  }

  removeImg() {
    this.imgUrl = this.defaultImgUrl ? this.defaultImgUrl : '';
    this.emitImg();
  }

  uploadImage(img: File) {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', img);

    const submitImage = this.imgIsPrivate ?
          this.jobService.submitFile.bind(this.jobService) :
          this.jobService.submitPublicFile.bind(this.jobService);

    this.componentSubscriptions.submitImage$ = submitImage(formData)
      .subscribe(
        response => {
          this.imgUrl = this.imgIsPrivate ? response.uuid : response.url;
          this.emitImg();
          this.loading = false;
        },
        error => {
          this.alertService.setAlert(this.alertService.formatErrorMessage(error));
          this.loading = false;
        }
      );
  }

  emitImg() {
    this.imgUrlChange.emit(this.imgUrl);
  }

  getImgUrl() {
    const jwtToken = localStorage.getItem(Constants.JWT);
    return this.imgUrl.indexOf('http') === -1 && this.imgIsPrivate ? `${Constants.FILE_URL}${this.imgUrl}/?jwt=${jwtToken}` : this.imgUrl;
  }

}
