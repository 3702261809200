import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bounce-rate-item',
  templateUrl: './bounce-rate-item.component.html',
  styleUrls: ['./bounce-rate-item.component.scss']
})
export class BounceRateItemComponent implements OnInit {

  @Input() name: string;
  @Input() visits: number;
  @Input() rate: number;
  @Input() color: string;

  constructor() { }

  ngOnInit() {
  }

}
