import { FormService } from '../instruments/form.service';
import { OriginsService } from '../instruments/origins.service';
import { FormTemplate } from '../classes/form';
import { Step } from '../classes/step';
import { Observable, combineLatest } from 'rxjs';
import { JobService } from '../services/job.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, ElementRef, Input, ChangeDetectorRef, AfterViewInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Profession } from '../classes/profession';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Job } from '../classes/job';
import { Landing } from '../classes/landing';
import { ProfessionService } from '../services/profession.service';
import { InstrumentService } from '../instruments/instrument.service';
import { AlertsService } from '../services/alerts.service';
import { ClientService } from '../partners/services/client.service';
import { Form } from '../classes/form';
import { PHASES, WizardPhase, StatusPhases, OTHER_ASSESMENT } from '../classes/phases';
import { Filter } from '../library/classes/filter';
import { Client } from '../partners/models/client';
import { Instrument, WizardInstruments, Origin } from '../instruments/models/instrument';
import { INSTRUMENT_TYPES } from '../instruments/constants';
import { NetworkService } from '../services/network.service';
import { Interview, InterviewGuides } from '../classes/interview';
import { InterviewsService } from './interviews/interviews.service';
import { MultipostingComponent } from '../comps/multiposting/multiposting.component';
import { take } from 'rxjs/operators';
import { ReportConfig } from '../classes/reportConfig';
import { InstrumentsService } from '../instruments/instruments.service';

@Component({
  selector: 'app-wizard-job',
  templateUrl: './wizard-job.component.html',
  styleUrls: ['./wizard-job.component.scss']
})
export class WizardJobComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = true;

  phases = PHASES;

  candidaturePhase = 'CAN';
  otherAssessmentPhase = 'OA';
  candidaturePhaseActive: boolean = true;
  otherAssessmentPhaseActive: boolean = true;

  steps = [
    new Step(1, '__general',
      ['profession', 'name', 'description', 'auto_transitions', 'privacy', 'categories', 'working_days', 'shift_turn', 'shift_time', 'vacancies']),
    new Step(2, '__landingPage', ['landing']),
    new Step(3, '__inscriptionForm', ['form']),
    new Step(4, '__evaluation', ['instruments']),
    new Step(5, '__interview', null),
    new Step(6, '__emails', null),
    new Step(7, '__share', null),
    new Step(8, '__overview', null),
  ];

  getPublishMessageByPrivacy = {
    PRI: '__saveAsPrivate',
    PUB: '__publish',
    DRA: '__saveAsDraft',
    BTH: '__publish'
  };

  type = 'draft';
  profesion_list: Profession[] = [];
  profession: Profession;
  finished = false;
  tab_index = 0;
  step_index = 1;

  modalRef: BsModalRef;

  job = new Job();
  landing: Landing;
  professionLandingId: number = null;
  showLanding = false;
  update = false;
  clone = false;
  instrumentsSelected: number[] | Instrument[] = [];
  categorySelected: any;
  instruments: Instrument[] = [];
  selectedFormInstruments: Instrument[] = [];
  selectedFormOAInstruments: Instrument[] = [];

  private _selectedFormOAInstruments: Instrument[] = [];

  selectedFormOAInterviews: Interview[] = [];
  client: Client = new Client();
  privacy: string = Job.JOB_PUB;
  wizarJobFormBanner = '';
  wizardJobComments = '';

  instrumentsData: WizardInstruments = new WizardInstruments();
  reportsData: Array<ReportConfig> = new Array<ReportConfig>();

  formOptions = Form.JOB_OPTIONS;

  inputJob: Job;

  statusPhases: WizardPhase[] = PHASES.map(phase => new WizardPhase(phase.name, phase.code, false));

  saveRequests: boolean;

  landingReady: boolean = false;

  today: Date = new Date();

  @Input() set myJob(value: any) {
    this.inputJob = value.job;
    const action: String = value.type;
    this.setJobFlags(action);
    if (this.inputJob) {
      this.checkActivePhases(this.inputJob.phase_config);
    }

  }

  workingDays = [];
  selectedDays = [];

  shiftTurnChoices = [];
  shiftTimeChoices = [];

  JOB_STATICS = {
    PUB: Job.JOB_PUB,
    PRI: Job.JOB_PRI,
    PUB_TEXT: Job.TEXT_PUB,
    PRI_TEXT: Job.TEXT_PRI,
    DRA: Job.JOB_DRA
  };


  @Output() hideWizard: EventEmitter<any> = new EventEmitter;

  @ViewChild(MultipostingComponent, { static: true }) child: MultipostingComponent;
  @ViewChild('forcastedJobsNumberReachedDialog', { read: TemplateRef, static: true }) forcastedJobsNumberReachedTemplate: TemplateRef<any>;

  componentSubscriptions: any = {};

  constructor(
    private professionService: ProfessionService,
    public jobService: JobService,
    private modalService: BsModalService,
    private hostElement: ElementRef,
    private activatedRoute: ActivatedRoute,
    private instrumentService: InstrumentService,
    private clientService: ClientService,
    private alertService: AlertsService,
    private originService: OriginsService,
    private formService: FormService,
    public networkService: NetworkService,
    public interviewsService: InterviewsService,
    private cdr: ChangeDetectorRef,
    private instrumentsService: InstrumentsService
  ) { }

  ngOnInit() {
    this.client.id = +this.activatedRoute.snapshot.paramMap.get('client');
    this.workingDays = Job.WORK_DAYS;
    this.shiftTurnChoices = Job.SHIFT_TURN_CHOICES;
    this.shiftTimeChoices = Job.SHIFT_TIME_CHOICES;
    this.getWizardData();
    this.setEventListeners();
    this.clientService.getActiveJobsCount(this.client.id);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();

  }

  ngOnDestroy() {
    this.workingDays.forEach(day => day.checked = false);
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  getWizardData() {
    this.componentSubscriptions.getObservablesData$ = this.getObservablesData()
      .subscribe(({ client, instrumentsProviders, instruments, formTemplates, professions, job }) => {
        this.client = client;

        // if (this.client.extra_config.multiposting != null) {
        //   let step = new Step(7, '__sharePost', ['multiposting_config']);
        //   this.steps[this.steps.length - 2] = step;
        // }

        this.profesion_list = professions;
        this.job.group = client.id;
        this.instrumentsData = new WizardInstruments(
          instrumentsProviders,
          instruments,
          client.instruments_config,
          formTemplates
        );
        this.reportsData = this.client.reports_config;
        if (this.clone || this.update) {
          this.actionJob(job);
        }

        if (!this.update && (this.client.active_jobs >= this.client.forecasted_jobs || !this.client.terms_and_conditions)) {
          this.privacy = Job.JOB_DRA;
          this.job.privacy = Job.JOB_DRA;
        }
      });
  }

  getObservablesData(): Observable<any> {
    return combineLatest(
      ...this.getObservables(),
      (
        client: Client,
        instrumentsProviders: Origin[],
        instruments: Instrument[],
        formTemplates: FormTemplate[],
        professions: Profession,
        job: Job | {} = {},
      ) => ({ client, instrumentsProviders, instruments, professions, job, formTemplates })
    );
  }

  getObservables(): Observable<any>[] {
    let observablesArray = [
      this.clientService.loadClient(this.client.id),
      this.originService.list(),
      this.instrumentService.list(new Filter('group', this.client.id)),
      this.formService.list(new Filter('group', this.client.id)),
      this.professionService.list(new Filter('group', String(this.client.id))),
    ];
    observablesArray = this.clone || this.update ? observablesArray.concat(this.getEditJobObservables()) : observablesArray;

    return observablesArray;
  }

  getEditJobObservables(): Observable<any> {

    const jobObservablesArray = [
      this.jobService.getJobDetail(String(this.inputJob.id))
    ];
    return combineLatest(...jobObservablesArray, (job) => ({ job }));
  }

  actionJob({ job }) {
    this.job = Job.buildFromJob(job);
    this.instrumentsService.setSelectedReports(this.job.reports_config);
    this.privacy = this.job.privacy;
    this.mapWorkingDays('array');

    this.selectedFormInstruments = this.getInstrumentForm();

    this.instrumentsSelected = this.job.instruments;

    this.landing = new Landing(this.job.landing, this.job.landing_name, this.job.group);

    this.job.landing = null;

    this.showLanding = true;
    this.loading = false;

    if (this.clone) {
      this.cloneJob(job);
    }
    this.selectedFormOAInstruments = this.getFormOAInstruments();
    this.selectedFormOAInterviews = this.getFormOAInterviews();
  }

  setEventListeners() {
    if (!this.update) {
      window.onbeforeunload = this.alertExit.bind(this);
    }
    this.finished = false;
    window.onmessage = this.iframeListener.bind(this);
  }

  alertExit = e => {
    if (!e.target.querySelector('.btn-mail')) {
      if (this.job) {
        this.finished = true;
        this.job.landing = this.landing.id;
        this.job.expiration_time = this.job.expiration_time ? new Date(this.job.expiration_time as string) : null;
      }
      window.onmessage = null;
      setTimeout(this.setEventListeners.bind(this), 0);
      (e || window.event)
        .returnValue = null;
      return null;
    }
  }

  iframeListener = data => {
    if (data.data && data.data.type && data.data.type.includes('builder') && this.finished) {
      this.saveJob();

    } else if (data.data && data.data.type && data.data.type === 'landingReady') {
      this.landingReady = true;
    }
  }

  landingIsReady(stepId: number): boolean {
    return stepId > 2 ? this.landingReady : true;
  }

  saveJob() {
    this.job.privacy = this.privacy;
    this.job.reports_config = this.instrumentsService.getSelectedReports().length ? this.instrumentsService.getSelectedReports() : [];
    if (this.privacy === 'DRA' || this.jobService.isExpired(this.job) || this.job.closed) {
      this.job.multiposting_config = {};
    }

    const job_creation$ = this.update ? this.jobService.updateJob(this.job) : this.jobService.createJob(this.job);
    this.componentSubscriptions.job_creationPipe$ = job_creation$.pipe(take(1))
      .subscribe(
        (job) => {
          this.job = job;
          if (this.privacy !== 'DRA' && !this.job.closed && !this.jobService.isExpired(this.job)) {
            this.saveRequests = true;
          }
          this.confirmedAnswer();
          this.clientService.getActiveJobsCount(this.client.id);
        },
        error => {

          this.loading = false;
          this.finished = false;
          if (Object.entries(error.error)[0].join(': ').includes('Forecast number of jobs has been reached')) {
            this.preventModal(this.forcastedJobsNumberReachedTemplate);
          } else {
            this.alertService.setAlert(this.alertService.formatErrorMessage(error));
          }
        }
      );
  }

  lastStep() {
    return this.step_index === this.steps.length;
  }

  nextStep(): void {
    this.step_index += 1;
    this.hostElement.nativeElement.querySelector('.wizard-container')
      .scrollTop = 0;
  }

  previousStep() {
    this.step_index = this.step_index <= 0 ? 0 : this.step_index - 1;
    this.hostElement.nativeElement.querySelector('.wizard-container')
      .scrollTop = 0;
  }

  validateForm(form) {
    const labelAreFilled = form.every(element => element.label || element.label === '');
    this.job.form = labelAreFilled ? form : null;
  }

  cancelWizard() {
    window.onbeforeunload = null;
    this.hideWizard.emit(true);
  }

  getProfession(profession_id) {

    this.componentSubscriptions.professionServiceRetrieve$ = this.professionService.retrieve(profession_id)
      .subscribe(
        profession => {
          this.job.setProfession(profession);
          this.job.profession = profession_id;

          this.selectedFormInstruments = profession.instruments_data.filter(instrument => instrument.origin === INSTRUMENT_TYPES.FORMS);
          this.selectedFormOAInstruments = this.getFormOAInstruments();

          this.selectedFormOAInterviews = this.getFormOAInterviews();

          if (profession.reports_config.length) {
            this.instrumentsService.setSelectedReports(profession.reports_config);
          }
          const landing = profession.landing as Landing;

          this.landing = new Landing(landing.id, landing.name, this.job.group);
          this.professionLandingId = landing.id;

          if (this.job.name) {
            this.landing.name = this.job.name;
          }

          this.showLanding = true;
          this.instrumentsSelected = this.job.instruments;
        },
        error => console.log(error)
      );
  }

  changeJobName(event) {
    this.job.name = event;
    if (this.job.profession) {
      this.landing.name = event;
    }
  }

  setActive(event) {
    this.job.auto_transitions = event.checked ? Job.JOB_AUTO_TRANS_YES : Job.JOB_AUTO_TRANS_NO;
  }

  createJob(type) {

    this.type = type;
    this.job.landing = this.landing.id;
    this.job.expiration_time = this.job.expiration_time ? new Date(this.job.expiration_time as string) : null;
    this.job.landing ? this.finished = true : this.saveJob();
    this.loading = true;
  }

  confirmedAnswer() {
    window.onbeforeunload = null;
    window.onmessage = null;
    this.finished = false;
    const message = this.update ? 'Job edited correctly' : 'Job saved correctly';

    this.alertService.setAlert({ type: 'success', message });
    setTimeout(() => this.hideWizard.emit(true), 500);
  }

  setJobFlags(action) {
    switch (action) {
      case Job.UPDATE_ACTION:
        this.update = true;
        break;
      case Job.CLONE_ACTION:
        this.clone = true;
        break;
    }
  }

  cloneJob(job) {
    this.job.id = null;
    this.job.name = job.name + ' - copy';
    this.job.internal_code = null;
    this.job['close_cause'] = '';
    this.job.closed = null;
    this.job.expiration_time = null;
  }

  preventModal(modalTemplate) {
    this.modalRef = this.modalService.show(modalTemplate, { class: 'prevent-dialog', backdrop: 'static' });
  }

  publish() {
    this.getSelectedInterviews();
    this.createJob('job');
  }

  getSelectedInterviews() {

    const interviewGuides: InterviewGuides = new InterviewGuides();

    Object.keys(this.job.interview_guides).forEach(key => {
      if (this.job.interview_guides[key].length > 0) {
        interviewGuides[key] = this.job.interview_guides[key].filter(elem => elem.selected);
      } else {
        interviewGuides[key] = [];
      }
    });

    this.job.interview_guides = interviewGuides;

  }

  saveAsDraft() {
    this.privacy = 'DRA';
    this.createJob('draft');
  }

  _getCurrentStep(): Step {
    return this.steps.find(step => step.id === this.step_index);
  }

  checkRequirements(): boolean {

    return this._getCurrentStep()
      .requirements ? this._getCurrentStep()
        .requirements.every(key => this.job[key]) : true;
  }

  checkDisableStep(stepId: number) {
    const nextReqStep = this.getNextRequirementStep();
    return (nextReqStep && nextReqStep.id < stepId) || !this.landingIsReady(stepId);
  }

  getNextRequirementStep() {
    const nextStep = !this.checkRequirements() ?
      this._getCurrentStep() :
      this.steps.slice(this.step_index)
        .find(step => step.requirements != null && step.requirements.every(key => !this.job[key]));
    return nextStep;
  }

  isCurrentStep(step_id): boolean {
    return this.step_index === step_id;
  }

  handlePhasesStatus(statusPhases: StatusPhases) {
    Object.keys(this.job.phase_config)
      .forEach(phase => {
        this.job.phase_config[phase].is_active = statusPhases[phase];
      });
    this.job.phase_config = Object.assign({}, this.job.phase_config);

    if (!this.job.phase_config.CAN.is_active) {
      this.cleanInstrumentsSelected();
    }
    this.checkActivePhases(this.job.phase_config);
  }

  checkActivePhases(phases) {
    this.candidaturePhaseActive = phases[this.candidaturePhase].is_active;
    this.otherAssessmentPhaseActive = phases[this.otherAssessmentPhase].is_active;
  }

  cleanInstrumentsSelected() {
    this.instrumentsSelected = [];
    this.selectedFormInstruments = [];
  }

  isPhaseActive(phaseCode: string): boolean {
    return this.job.phase_config[phaseCode].is_active;
  }

  setCuteInstrument(instruments: Instrument[]) {
    if (!instruments) {
      this.job.instruments = null;
    } else {
      this.job.instruments = this._selectedFormOAInstruments;

      this.job.instruments = this.job.instruments.concat(instruments);
    }

  }

  private getInstrumentForm(): Instrument[] {
    return this.job.instruments_data.filter(instrument => instrument.origin === INSTRUMENT_TYPES.FORMS);
  }

  getFormOAInstruments() {
    return this.job.instruments.filter(instrument => instrument.phase === OTHER_ASSESMENT.code);
  }

  getFormOAInterviews() {
    return this.job.interview_guides.OA;
  }

  setOAInstruments(instruments: Instrument[]) {
    const onlineAssessmentInstruments = (this.job.instruments || []).filter(instrument => instrument.phase !== OTHER_ASSESMENT.code);
    this.job.instruments = onlineAssessmentInstruments.concat(instruments);

    this._selectedFormOAInstruments = ((this.job.instruments as Instrument[]) || this.selectedFormOAInstruments)
      .filter(instrument => instrument.phase === OTHER_ASSESMENT.code);
  }

  setOAInterviews(interviews: Interview[]) {
    this.job.interview_guides.OA = interviews;
  }

  stepChange(id: number) {
    this.step_index = id;
    if (this.step_index === 7) {
      this.child.setTabIndex();
    }
  }

  setWorkingDays(event) {
    event.checked = !event.checked;
    this.mapWorkingDays();
  }

  mapWorkingDays(mode = 'binary-string') {
    let mapped_working_days = null;
    if (mode === 'binary-string') {
      mapped_working_days = this.workingDays.reduce((finalObj, element) => {
        finalObj += element.checked ? '1' : '0';
        return finalObj;
      }, '');
      this.job.working_days = mapped_working_days;
    } else {
      if (this.job.working_days) {
        const arrayDays = this.job.working_days.split("");
        mapped_working_days = arrayDays.reduce((finalObj, day, index) => {
          let mappedDay = this.workingDays[index];
          mappedDay.checked = day === '1' ? true : false;
          return finalObj.concat(mappedDay);
        }, []);
        this.workingDays = mapped_working_days;
        this.selectedDays = this.workingDays.filter(element => element.checked === true).map(element => element.value);
      }
    }
  }

  validateVacancies(vacancies) {
    if (vacancies < 1) {
      this.job.vacancies = 1;
    }
  }

}
