export class Group {

    static PARTNER = 'partner';
    static CLIENT = 'client';
    static CLIENT_ACTIVE = 'ACT';
    static CLIENT_INACTIVE = 'INA';

    id: number;
    name: string;
    parent: Group;
    contact: string;
    description: string;
    site: string;
    status: string = Group.CLIENT_ACTIVE;
    client_email: string;

    isPartner() {
        return this.parent == null;
    }

    getType() {
        if (this.isPartner) {
            return Group.PARTNER;
        }
        return Group.CLIENT;
    }
}

export class ProfessionsByGroup {

    total_jobs: number;
    total_candidates: number;
    professions: [{
        total_jobs: number;
        profession: {
            id: number;
            name: string;
        };
        total_candidates: number;
    }] 
}