import { Component, OnInit, Input } from '@angular/core';
import { Candidate } from '../../classes/candidate';
import { Form } from '../../classes/form';
import { Constants } from '../../classes/constants';

@Component({
  selector: 'app-form-responses',
  templateUrl: './form-responses.component.html',
  styleUrls: ['./form-responses.component.scss']
})
export class FormResponsesComponent implements OnInit {

  _responses: ResponseSerialize[] = [];
  token =  localStorage.getItem(Constants.JWT);

  constructor() { }

  @Input() set responses (responses: ResponseJsonType[]){
    this._responses = this.serializeResponses( FormResponse.generateFromJson(responses));
  }

  @Input() set killerQuestions (kq: Form){
    this.setKQResponses(Object.keys(kq || {}));
  }

  ngOnInit() {
    this.checkIfResponseIsUrl();
  }

  serializeResponses(responses: FormResponse[]): ResponseSerialize[] {
    return responses.map(response => response.serializeResponse());
  }

  checkIfResponseIsUrl() {
  }

  setKQResponses(kq:string[]){
    this._responses.map(response => {
      if(kq.includes(response.slug)){
        response.setKQValue(response.slug);
      }
    })
  }

}

class ResponseSerialize {
  question: string;
  answer: string;
  slug: string;
  kq: boolean;

  constructor(question: string, answer: string, slug:string) {
    this.question = question;
    this.answer = answer;
    this.slug = slug;
    this.kq = false;
  }

  setKQValue(value:string){
    this.kq = value === this.slug;
  }

}

class FormResponse {
  label: string;
  answer: string;
  slug: string;

  constructor(label:string, answer:string, slug:string){
    this.answer = answer;
    this.label = label;
    this.slug = slug;
  }

  static generateFromJson(responsesJson: ResponseJsonType[]):FormResponse[] {
    return  responsesJson.map(response => new FormResponse(response.label, this._getAnswerString(response.value), response.name));
  }

  static _getAnswerString(value: FormAnswerType | FormAnswerType[] ): string {
    switch (FormResponse.getResponseType(value)) {
      case 'array':
        return ( value as FormAnswerType[]).map(option => `${option.label}<br>`).join('');
      case 'object':
        return ((value as FormAnswerType).label || ''  ).toString();
      case 'null':
        return null;
      default:
        return value.toString();
    }
  }

  static getResponseType(value: any): string {
    if (value === null) {
      return 'null';
    } else if (value === '') {
        return 'null';
    } else if (Array.isArray(value)) {
      return 'array';
    }
    return typeof value;
  }

  serializeResponse(): ResponseSerialize {
    return new ResponseSerialize(this.label, this.answer, this.slug);
  }



}

interface ResponseJsonType {
  label: string;
  name: string;
  value: FormAnswerType;
}

interface FormAnswerType {
  value: string | string[];
  label: string | string[];
}



