import { ClientDataService } from './services/client-data.service';
import { MultipostingService } from './services/multiposting.service';
import { SettingsTagsManagerComponent } from './tags/components/settings-tags-manager/settings-tags-manager.component';
import { TagsControlService } from './tags/services/tags-control.service';
import { TagsService } from './services/tag.service';
import { environment } from './../environments/environment';
import { FormService } from './instruments/form.service';
import { OriginsService } from './instruments/origins.service';
import { LoginGuardService } from './guards/login-guard.service';
import { Constants } from './classes/constants';
import { CandidateService } from './services/candidate.service';
import { InstrumentService } from './instruments/instrument.service';
import { InstrumentsService } from './instruments/instruments.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule, SafeUrl } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppComponent } from './app.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { Routes, routingConfiguration } from './classes/routes';
import { UserService } from './services/user-service.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { RecoverPasswordService } from './services/recover-password.service';
import { Jwtinterceptor } from './services/jwtinterceptor';
import { WizardComponent } from './components/wizard/wizard.component';
import { LandingBuilderService } from './services/landing-builder-service.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProfessionDetailComponent } from './components/profession-detail/profession-detail.component';
import { FormRenderComponent } from './components/form-render/form-render.component';
import { SettingClientComponent } from './components/setting-client/setting-client.component';
import { DashboardCandidateComponent } from './components/dashboard-candidate/dashboard-candidate.component';
import { SidebarSettingsClientComponent } from './components/sidebar-settings-client/sidebar-settings-client.component';
import { UsersSettingsClientComponent } from './components/users-settings-client/users-settings-client.component';
import { MainSettingsClientComponent } from './components/main-settings-client/main-settings-client.component';
import { ClientAnalyticsService } from './services/client-analytics.service';
import { ProfessionTableComponent } from './components/profession-table/profession-table.component';
import { ProfessionService } from './services/profession.service';
import { JobService } from './services/job.service';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { JobDashboardComponent } from './components/job-dashboard/job-dashboard.component';
import { CandidatesTableComponent } from './components/candidates-table/candidates-table.component';
import { JobAnalyticsComponent } from './components/job-analytics/job-analytics.component';
import { UserDataService } from './services/user-data-service.service';
import { BarsComponent } from './components/bars/bars.component';
import { HorizontalBarsComponent } from './components/horizontal-bars/horizontal-bars.component';
import { PiechartComponent } from './components/piechart/piechart.component';
import { AgeGenderChartComponent } from './components/age-gender-chart/age-gender-chart.component';
import { PublicCandidateComponent } from './components/public-candidate/public-candidate.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { TableChartComponent } from './components/table-chart/table-chart.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { JobEmptyComponent } from './components/job-empty/job-empty.component';
import { JobHeaderComponent } from './components/job-header/job-header.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { AllCandidatesTableComponent } from './components/all-candidates-table/all-candidates-table.component';
import { DashboardCandidateMultijobComponent } from './components/dashboard-candidate-multijob/dashboard-candidate-multijob.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertsService } from './services/alerts.service';
import { NoServiceComponent } from './components/no-service/no-service.component';
import { KeyboardShortcutService } from './services/keyboard-shortcut.service';
import { HotkeyModule } from 'angular2-hotkeys';
import { ClientHubComponent } from './components/client-hub/client-hub.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { DashboardClientComponent } from './components/dashboard-client/dashboard-client.component';
import { CandidateTimelineComponent } from './components/candidate-timeline/candidate-timeline.component';
import { CandidateEvaluationComponent } from './components/candidate-evaluation/candidate-evaluation.component';
import { ClientAppearanceComponent } from './components/client-appearance/client-appearance.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { SidebarHeaderComponent } from './components/sidebar-header/sidebar-header.component';
import { FormResponsesComponent } from './components/form-responses/form-responses.component';
import { HelpComponent } from './components/help/help.component';
import { CandidateRatingComponent } from './components/candidate-rating/candidate-rating.component';
import { DocRenderComponent } from './components/doc-render/doc-render.component';
import { ReputationAnalyticsComponent } from './components/reputation-analytics/reputation-analytics.component';
import { ReputationAnalyticsCardComponent } from './components/reputation-analytics-card/reputation-analytics-card.component';
import { SemiCircleChartComponent } from './components/semi-circle-chart/semi-circle-chart.component';
import { FunnelTableComponent } from './components/funnel-table/funnel-table.component';
import { CandidateInterviewComponent } from './components/candidate-interview/candidate-interview.component';
import { TalentPointsComponent } from './components/talent-points/talent-points.component';
import { CandidatesBarMultiactionComponent } from './components/candidates-bar-multiaction/candidates-bar-multiaction.component';
import { SidebarJobActionsComponent } from './components/sidebar-job-actions/sidebar-job-actions.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutService } from './services/layout.service';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { CandidateDocsHandlerComponent } from './components/candidate-docs-handler/candidate-docs-handler.component';
import { LandingListComponent } from './components/landing-list/landing-list.component';
import { PartnerTableComponent } from './partners/components/partner-table/partner-table.component';
import { PartnerService } from './partners/services/partner.service';

import { CreateClientComponent } from './partners/components/create-client/create-client.component';
import { ClientKpiService } from './partners/services/client-kpi.service';
import { ClientService } from './partners/services/client.service';
import { ClientTableComponent } from './partners/components/client-table/client-table.component';
import { FormJobService } from './instruments/form-job.service';
import { CuteDoneComponent } from './instruments/cute-done/cute-done.component';
import { CandidateInstrumentFormComponent } from './instruments/candidate-instrument-form/candidate-instrument-form.component';
import { VerifyCodeComponent } from './components/verify-code/verify-code.component';
import { VerifyCodeGuardGuard } from './guards/verify-code-guard.guard';
import { NetworkService } from './services/network.service';
import { CandidateInterviewVidassessComponent } from './components/candidate-interview-vidassess/candidate-interview-vidassess.component';
import { CandidateMultijobSelectorComponent } from './components/candidate-multijob-selector/candidate-multijob-selector.component';
import { SidebarCandidatesPoolComponent } from './components/sidebar-candidates-pool/sidebar-candidates-pool.component';
import { DashboardCandidatesPoolComponent } from './components/dashboard-candidates-pool/DashboardCandidatesPoolComponent';
import { CandidatePoolDetailsComponent } from './components/candidate-pool-details/candidate-pool-details.component';
import { FiltersComponent } from './components/filters/filters.component';
import { JobFiltersComponent } from './components/job-filters/job-filters.component';
import { CandidatesFiltersComponent } from './components/candidates-filters/candidates-filters.component';
import { AccountComponent } from './components/account/account.component';
import { AccountProfileComponent } from './components/account/account-profile/account-profile.component';
import { MultipostingAccountsComponent } from './components/account/multiposting-accounts/multiposting-accounts.component';

import { WizardJobModule } from './wizard-job/wizard-job.module';
import { PipesModule } from './pipes/pipes.module';
import { MaterialModule } from './material.module';
import { ComponentsModule } from './comps/components.module';
import { JobsSubscriptionComponent } from './components/client-hub/jobs-subscription/jobs-subscription.component';
import { UnsubscriptionLandingComponent } from './components/unsubscription-landing/unsubscription-landing.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AllCandidatesViewerComponent } from './components/all-candidates-viewer/all-candidates-viewer.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { SidebarDashboardClientComponent } from './components/sidebar-dashboard-client/sidebar-dashboard-client.component';
import { NewDashboardClientComponent } from './components/new-dashboard-client/new-dashboard-client.component';
import { CustomDashboardClientComponent } from './components/custom-dashboard-client/custom-dashboard-client.component';
import { DashboardClientContainerComponent } from './components/dashboard-client-container/dashboard-client-container.component';
import { SettingOrganizationComponent } from './components/setting-organization/setting-organization.component';
import { SettingJobcategoryComponent } from './components/setting-jobcategory/setting-jobcategory.component';
import { NewDashboardClientFiltersComponent } from './components/new-dashboard-client-filters/new-dashboard-client-filters.component';
import { InstrumentReportFormComponent } from './instruments/instrument-report-form/instrument-report-form.component';
import { JobsSubscriptionFilterComponent } from './components/client-hub/jobs-subscription-filter/jobs-subscription-filter.component';
import { JobsSubscriptionFirstStepComponent } from './components/client-hub/jobs-subscription-first-step/jobs-subscription-first-step.component';
import { CandidateInterviewVideoaskComponent } from './components/candidate-interview-videoask/candidate-interview-videoask.component';
import { VideoPlayerComponent } from './utils/video-player/video-player.component';
import { AudioPlayerComponent } from './utils/audio-player/audio-player.component';
import { ClientTranslationService } from './services/client-translation.service';
import { CandidateInterviewVidassessAiComponent } from './components/candidate-interview-vidassess-ai/candidate-interview-vidassess-ai.component';
import { InterpretativeComponent } from './components/interpretative/interpretative.component';
import { HelpEmailComponent } from './utils/help-email/help-email.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PartnerAnalyticsComponent } from './partners/components/partner-analytics/partner-analytics.component';
import { PartnerAnalyticsConversionComponent } from './partners/components/partner-analytics-conversion/partner-analytics-conversion.component';
import { PartnerAnalyticsJobsComponent } from './partners/components/partner-analytics-jobs/partner-analytics-jobs.component';
import { PartnerAnalyticsTopJobsComponent } from './partners/components/partner-analytics-top-jobs/partner-analytics-top-jobs.component';
import { PartnerAnalyticsInstrumentsComponent } from './partners/components/partner-analytics-instruments/partner-analytics-instruments.component';
import { PartnerAnalyticsIndustriesComponent } from './partners/components/partner-analytics-industries/partner-analytics-industries.component';
import { PartnerAnalyticsFiltersComponent } from './partners/components/partner-analytics-filters/partner-analytics-filters.component';
import { CandidateVerifiedComponent } from './components/candidate-verified/candidate-verified.component';
import { AppliedTagsDisplayComponent } from './tags/components/applied-tags-display/applied-tags-display.component';
import { CandidatesTagsManagerComponent } from './tags/components/candidates-tags-manager/candidates-tags-manager.component';
import { MatMenuModule } from '@angular/material/menu';
import { OptionExtendedSearchComponent } from './components/option-extended-search/option-extended-search.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormRenderService } from './services/form-render.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, Constants.TRANSLATIONS_URL + environment.environment_language_file, '.json');
}

@NgModule({
  imports: [
    ClickOutsideModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(Routes, routingConfiguration),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule,
    PaginationModule.forRoot(),
    HotkeyModule.forRoot(),
    ColorPickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
    WizardJobModule,
    PipesModule,
    MaterialModule,
    ComponentsModule,
    MatMenuModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    PartnerTableComponent,
    DashboardComponent,
    ModalDialogComponent,
    RecoverPasswordComponent,
    SidebarComponent,
    SafeUrlPipe,
    WizardComponent,
    CreateClientComponent,
    ProfessionDetailComponent,
    FormRenderComponent,
    SettingClientComponent,
    DashboardCandidateComponent,
    SidebarSettingsClientComponent,
    SidebarDashboardClientComponent,
    SidebarCandidatesPoolComponent,
    SettingClientComponent,
    UsersSettingsClientComponent,
    MainSettingsClientComponent,
    ProfessionTableComponent,
    JobDashboardComponent,
    CandidatesTableComponent,
    JobAnalyticsComponent,
    BarsComponent,
    HorizontalBarsComponent,
    PiechartComponent,
    AgeGenderChartComponent,
    PublicCandidateComponent,
    CuteDoneComponent,
    LineChartComponent,
    TableChartComponent,
    CreateUserComponent,
    JobEmptyComponent,
    JobHeaderComponent,
    LandingListComponent,
    AllCandidatesTableComponent,
    DashboardCandidateMultijobComponent,
    DashboardCandidatesPoolComponent,
    AlertComponent,
    NoServiceComponent,
    ClientHubComponent,
    NoResultsComponent,
    DashboardClientComponent,
    CandidateTimelineComponent,
    CandidateEvaluationComponent,
    ClientAppearanceComponent,
    SidebarHeaderComponent,
    FormResponsesComponent,
    HelpComponent,
    CandidateRatingComponent,
    DocRenderComponent,
    ReputationAnalyticsComponent,
    ReputationAnalyticsCardComponent,
    SemiCircleChartComponent,
    FunnelTableComponent,
    TalentPointsComponent,
    FunnelTableComponent,
    CandidateInterviewComponent,
    CandidatesBarMultiactionComponent,
    SidebarJobActionsComponent,
    DateTimePickerComponent,
    CandidateDocsHandlerComponent,
    ClientTableComponent,
    CandidateInstrumentFormComponent,
    VerifyCodeComponent,
    CandidateInterviewVidassessComponent,
    CandidateMultijobSelectorComponent,
    CandidatePoolDetailsComponent,
    FiltersComponent,
    JobFiltersComponent,
    CandidatesFiltersComponent,
    AccountComponent,
    AccountProfileComponent,
    MultipostingAccountsComponent,
    JobsSubscriptionComponent,
    UnsubscriptionLandingComponent,
    PrivacyPolicyComponent,
    AllCandidatesViewerComponent,
    DonutChartComponent,
    DashboardClientContainerComponent,
    NewDashboardClientComponent,
    NewDashboardClientFiltersComponent,
    CustomDashboardClientComponent,
    SettingOrganizationComponent,
    SettingJobcategoryComponent,
    InstrumentReportFormComponent,
    JobsSubscriptionFilterComponent,
    JobsSubscriptionFirstStepComponent,
    CandidateInterviewVideoaskComponent,
    VideoPlayerComponent,
    AudioPlayerComponent,
    CandidateInterviewVidassessAiComponent,
    InterpretativeComponent,
    HelpEmailComponent,
    FeedbackComponent,
    PartnerAnalyticsComponent,
    PartnerAnalyticsConversionComponent,
    PartnerAnalyticsJobsComponent,
    PartnerAnalyticsTopJobsComponent,
    PartnerAnalyticsInstrumentsComponent,
    PartnerAnalyticsIndustriesComponent,
    PartnerAnalyticsFiltersComponent,
    CandidateVerifiedComponent,
    SettingsTagsManagerComponent,
    AppliedTagsDisplayComponent,
    CandidatesTagsManagerComponent,
    OptionExtendedSearchComponent
  ],
  entryComponents: [
    ModalDialogComponent,
    JobsSubscriptionComponent,
    JobsSubscriptionFirstStepComponent,
  ],
  providers: [
    LandingBuilderService,
    UserService,
    AuthGuardService,
    LoginGuardService,
    VerifyCodeGuardGuard,
    RecoverPasswordService,
    BsModalService,
    BsDatepickerModule,
    ClientService,
    ClientAnalyticsService,
    ProfessionService,
    JobService,
    InstrumentService,
    InstrumentsService,
    CandidateService,
    UserDataService,
    AlertsService,
    KeyboardShortcutService,
    LayoutService,
    PartnerService,
    ClientKpiService,
    FormService,
    FormJobService,
    OriginsService,
    NetworkService,
    ClientTranslationService,
    TagsService,
    TagsControlService,
    MultipostingService,
    ClientDataService,
    FormRenderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Jwtinterceptor,
      multi: true
    },
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
