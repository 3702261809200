import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AlertsService } from './../../services/alerts.service';
import { Job } from './../../classes/job';
import { CandidateService } from './../../services/candidate.service';
import { JobService } from './../../services/job.service';
import { CANDIDATURE, PHONE, PHASES, findPhaseIndexByCode, INTERVIEW_PHASES, StatusPhases, HIRED } from './../../classes/phases';
import { Candidate, TASK_TYPES } from './../../classes/candidate';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Renderer2, ViewChild, TemplateRef } from '@angular/core';
import { INSCRIPTION, Status } from '../../classes/phases';
import { InterviewGuides } from '../../classes/interview';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import * as moment from 'moment';
import { UserService } from '../../services/user-service.service';
import { TranslateService } from '@ngx-translate/core';
import { InstrumentService } from '../../instruments/instrument.service';
import { Filter } from '../../library/classes/filter';
import { take } from 'rxjs/operators';
import { Tag } from '../../classes/tag';

@Component({
  selector: 'app-candidates-bar-multiaction',
  templateUrl: './candidates-bar-multiaction.component.html',
  styleUrls: [ './candidates-bar-multiaction.component.scss' ],
})
export class CandidatesBarMultiactionComponent implements OnInit, OnDestroy {

  candidatesSelected: Candidate[]
  @Input()
  set selectedCandidates(candidates: Candidate[]) {
    this.candidatesSelected = candidates;
    this.canAssignTags = candidates.every(c => c.candidate !== undefined)
    this.canAssignTasks = candidates.every(c => c.mark !== undefined)
  }
  @Input() statusSelected: string;
  @Input() job_id: number;
  @Input() phase: string;
  @Input() isCandidateDetail = false;
  @Input() interview_guides: InterviewGuides = new InterviewGuides();
  @Input() poolOrigin = false;




  @Input() set statusPhases(statusPhases: StatusPhases) {
    this._statusPhases = statusPhases;
    this.setBackTransitions();
  }
  _statusPhases: StatusPhases = new StatusPhases();

  @Output() action: EventEmitter<string> = new EventEmitter();
  @Output() update: EventEmitter<boolean> = new EventEmitter();
  @Output() interview: EventEmitter<string> = new EventEmitter();

  buttonTransitionsSelected = false;

  FORWARD_TRANSITIONS: string[] = [ HIRED.code ];
  FORWARD_STATUS: string[] = [ Status.PASS.value ];

  BACK_TRANSITIONS: string[] = [ INSCRIPTION.code, CANDIDATURE.code ];
  BACK_STATUS: string[] = [ Status.KO.value ];

  status = Status;
  phases = PHASES;
  CANDIDATE_ACTIONS = Candidate.ACTIONS;
  TASK_TYPES = TASK_TYPES;

  candidateIds: string[] = [];
  candidateTags: Tag[] = [];

  @Output() tagsChange: EventEmitter<Tag[]> = new EventEmitter();

  scheduleDateModal = false;
  scheduledDate: Date = null;
  currentDate = new Date();

  @ViewChild('scheduleModal', { read: TemplateRef, static: true }) editModalTemplate: TemplateRef<any>;
  scheduleModalRef: BsModalRef;

  @Output() vidassessUrlEmitter: EventEmitter<string> = new EventEmitter();

  componentSubscriptions: any = {};

  canAssignTags: boolean = false;
  canAssignTasks: boolean = false;

  constructor(
    private alertService: AlertsService,
    private candidateService: CandidateService,
    private renderer: Renderer2,
    private modalService: BsModalService,
    public userService: UserService,
    private translateService: TranslateService,
    private instrumentService: InstrumentService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  setBackTransitions() {
    const firstInterviewPhase = INTERVIEW_PHASES.find(phase => this._statusPhases[ phase.code ]);
    this.BACK_TRANSITIONS = this.BACK_TRANSITIONS.concat(firstInterviewPhase.code);
  }

  showPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'block');
  }

  dismissPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'none');
  }

  getTagData(candidatesSelected: Candidate[]) {

    this.candidateIds = candidatesSelected.map(candidate => this.poolOrigin ? candidate.id : candidate.candidate.id);

    const allCandidateTags: Tag[] = candidatesSelected.reduce(
      (result, candidate) => result.concat(this.poolOrigin ? candidate.tags : candidate.candidate.tags),
      []
    );

    const uniqueTags: Tag[] = allCandidateTags.filter((currentTag, i) => allCandidateTags.findIndex(tag => tag.id === currentTag.id) === i);

    this.candidateTags = uniqueTags.filter(tag =>
      candidatesSelected.every(candidate => {
        if (this.poolOrigin) {
          return candidate.tags.some(candidateTag => candidateTag.id === tag.id);
        } else {
          return candidate.candidate.tags.some(candidateTag => candidateTag.id === tag.id);
        }
      })
    );
  }

  showMoveBackCandidate(): boolean {
    return this.candidatesSelected.every(candidate =>
      !this.BACK_TRANSITIONS.includes(candidate.transitions) && this.BACK_STATUS.includes(candidate.status)
    );
  }

  showMoveForwardCandidate(): boolean {
    return this.candidatesSelected.every(candidate =>
      !this.FORWARD_TRANSITIONS.includes(candidate.transitions) && this.FORWARD_STATUS.includes(candidate.status)
    );
  }

  sameStatus(): boolean {
    const pass = this.candidatesSelected.every(candidate =>
      candidate.status === Status.PASS.value
    );
    const pnd = this.candidatesSelected.every(candidate =>
      candidate.status === Status.PND.value
    );
    const ko = this.candidatesSelected.every(candidate =>
      candidate.status === Status.KO.value
    );
    return pass || pnd || ko;
  }

  showChangePassCandidate(): boolean {
    return this.candidatesSelected[ 0 ] && this.candidatesSelected.every(candidate =>
      candidate.status !== Status.PASS.value
    );
  }

  showChangeKoCandidate(): boolean {
    return this.candidatesSelected[ 0 ] && this.candidatesSelected.every(candidate =>
      candidate.status !== Status.KO.value
    );
  }

  showChangePndCandidate(): boolean {
    return this.candidatesSelected[ 0 ] && this.candidatesSelected.every(candidate =>
      candidate.status !== Status.PND.value
    );
  }

  moveCandidateForward() {
    const candidates_ids = this.candidatesSelected.map(candidate => +candidate.id);
    this.componentSubscriptions.candidateServiceMoveCandidatesPipe$ = this.candidateService.moveCandidates(candidates_ids, this.job_id).pipe(take(1)).subscribe(
      response => this.moveCandidate(),
      error => this.alertService.setAlert(undefined, error)
    );
  }

  moveCandidateBack() {
    const prevPhase: number = this.findPrevAvailablePhase();
    this.componentSubscriptions.candidateServiceChangeCandidateStatusAndTransition$ = this.candidateService.changeCandidatesStatusAndTransition(
      Status.PND.value,
      PHASES[ prevPhase ].code,
      ...this.candidatesSelected,
    ).pipe(take(1)).subscribe(
      response => this.moveCandidate(),
      error => this.alertService.setAlert(undefined, error)
    );
  }

  findPrevAvailablePhase(): number {
    const backwardsPhases = PHASES.slice(2, findPhaseIndexByCode(this.phase));
    const jumpTo = backwardsPhases.reverse().find(phase => {
      return this._statusPhases[ phase.code ];
    });
    return findPhaseIndexByCode(jumpTo.code);
  }

  moveCandidate() {
    this.update.emit(true);
    this.candidatesSelected.splice(0, this.candidatesSelected.length);
  }

  changeStatus(phase: string) {
    this.componentSubscriptions.candidateServiceChangeCandidatesStatusPhase$ = this.candidateService.changeCandidatesStatus(
      phase,
      ...this.candidatesSelected
    ).subscribe(
      response => {
        this.candidatesSelected.forEach(candidate => candidate.status = phase);
        if (this.isCandidateDetail) {
          this.emitAction(Candidate.ACTIONS.CHANGE_STATUS);
        }
        this.update.emit(true);
      },
      error => this.alertService.setAlert(undefined, error)
    );
  }

  getPhaseLabel(phase: string): string {
    return PHASES[ findPhaseIndexByCode(phase) ].name;
  }

  isInterviewPhase(): boolean {
    return INTERVIEW_PHASES.some(phase => phase.code === (this.phase || ''));
  }

  doInterview(interview) {
    this.interview.emit(interview);
  }

  openScheduleModal(modalTemplate) {
    this.scheduleModalRef = this.modalService.show(modalTemplate, { backdrop: 'static' });
  }

  closeScheduleModal() {
    this.scheduledDate = null;
    this.scheduleModalRef.hide();
  }

  getFirstCandidateTask(): string {
    return this.candidatesSelected.length > 0 ? this.candidatesSelected[ 0 ].mark : null;
  }

  sameTask() {
    let task = undefined;
    return this.candidatesSelected.every((candidate, i) => {
      task = task === undefined && candidate.mark !== undefined ? candidate.mark : task
      return candidate.mark === task;
    });
  }

  selectTask(selectedTask: string) {
    if (selectedTask === TASK_TYPES.SCHEDULED) {
      this.closeScheduleModal();
    }
    this.candidatesSelected = this.candidatesSelected.map(candidate => {
      candidate.mark = selectedTask;
      return candidate;
    });
    this.emitAction(Candidate.ACTIONS.TASK);
  }

  handleTask(task: string) {
    const data = this._getTaskData(task);
    !this.getFirstCandidateTask() ? this.addTask(task, data) : this.updateTask(task, data);
  }

  private _getTaskData(task: string): any {
    const toCall = this.translateService.instant('__toCall');
    const toSchedule = this.translateService.instant('__toSchedule');
    const scheduledText = this.translateService.instant('__scheduled');
    switch (task) {
      case TASK_TYPES.PHONED:
        return { message: toCall };
      case TASK_TYPES.TO_SCHEDULE:
        return { message: toSchedule };
      case TASK_TYPES.SCHEDULED:
        return {
          message: scheduledText + `: ${moment(this.scheduledDate).format('LLL')}`,
          start_date: this.scheduledDate,
          duration: 1
        };
    }
  }

  addTask(task: string, data: any) {
    this.candidatesSelected.forEach(candidate => {
      data.candidate = candidate.id;
      this.componentSubscriptions.candidateServiceAddTask$ = this.candidateService.addTask(candidate, task, data).subscribe(
        res => this.selectTask(res.params.mark_type),
        error => this.alertService.setAlert(undefined, error)
      );
    });
  }

  updateTask(task: string, data: any) {
    this.candidatesSelected.forEach(candidate => {
      data.candidate = candidate.id;
      this.componentSubscriptions.candidateServiceUpdateTask$ = this.candidateService.updateTask(candidate, task, data).subscribe(
        res => this.selectTask(res.params.mark_type),
        error => this.alertService.setAlert(undefined, error)
      );
    });
  }

  deleteTask() {
    this.candidatesSelected.forEach(candidate => {
      this.componentSubscriptions.candidateServiceRemoveTask$ = this.candidateService.removeTask(candidate, this.getFirstCandidateTask()).subscribe(
        res => this.selectTask(null),
        error => this.alertService.setAlert(undefined, error)
      );
    });
  }

  emitAction(action: string) {
    this.action.emit(action);
  }

  clickVidassess(vidassessId: number, action: string) {

    this.vidassessUrlEmitter.emit(undefined);
    this.emitAction(action);

    this.componentSubscriptions.instrumentServiceGetVidassessUrl$ = this.instrumentService.getVidassessUrl(vidassessId).subscribe(
      (resp: any) => {
        this.vidassessUrlEmitter.emit(resp.url);
      }, error => {
        this.alertService.setAlert({ type: 'error', message: error.error.error });
      }
    );

  }

  showAutomaticEmailStatusMessage(): boolean {
    return this.phase === 'INS' || this.phase === 'CAN';
  }

  showAutomaticEmailTransitionMessage(): boolean {

    if (this.phase === 'INS') {

      const nextPhase = [ 'CAN', 'PHO', 'OA' ].find(phaseKey => this._statusPhases[ phaseKey ])
      return nextPhase === 'CAN' || nextPhase === 'OA';

    } else if (this._statusPhases.OA) {

      const possiblePreviousPhases = [ 'INS', 'CAN', 'PHO' ];

      const lastActivatedIndex: number = possiblePreviousPhases.reduce(
        (result, currentPhase, index) => {
          return this._statusPhases[ currentPhase ] ? index : result;
        },
        0);

      return this.phase === possiblePreviousPhases[ lastActivatedIndex ];

    } else {

      return false;

    }
  }

  emitTagsChange() {
    this.tagsChange.emit();
  }
}
