export const DummyFormInfojobs = [
  {
    "type": "header",
    "label": "About the offer",
    "subtype": "h3"
  },
  {
    "name": "jobTitle",
    "type": "text",
    "label": "Offer title",
    "subtype": "text",
    "required": true
  },
  {
    "name": "jobDescription",
    "type": "textarea",
    "label": "Offer description",
    "subtype": "textarea",
    "required": "true",
    "placeholder": "Describe responsabilities and functions the candidate will face in the position."
  },
  {
    "name": "jobIndustryId",
    "type": "select",
    "label": "Job industry",
    "values": [
      {
        "image": "",
        "label": "Administración de empresas",
        "value": 10,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Administración Pública",
        "value": 20,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Atención a clientes",
        "value": 170,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Calidad, producción e I+D",
        "value": 30,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Comercial y ventas",
        "value": 190,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Compras, logística y almacén",
        "value": 40,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Diseño y artes gráficas",
        "value": 50,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Educación y formación",
        "value": 60,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Finanzas y banca",
        "value": 70,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Informática y telecomunicaciones",
        "value": 150,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ingenieros y técnicos",
        "value": 80,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Inmobiliario y construcción",
        "value": 90,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Legal",
        "value": 100,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Marketing y comunicación",
        "value": 110,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Profesiones, artes y oficios",
        "value": 120,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Recursos humanos",
        "value": 130,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sanidad y salud",
        "value": 140,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sector Farmacéutico",
        "value": 210,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Turismo y restauración",
        "value": 160,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ventas al detalle",
        "value": 200,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Otros",
        "value": 180,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Classify the offer by its industry",
    "queryparams": "type=CATEGORIES",
    "refresh_on_change": true
  },
  {
    "name": "jobSubIndustryId",
    "type": "select",
    "label": "Job sub-industry",
    "values": [],
    "required": true,
    "placeholder": "Classify the offer by its sub-industry",
    "queryparams": "parent={jobIndustryId}&type=SUBCATEGORIES"
  },
  {
    "type": "header",
    "label": "Location",
    "subtype": "h3"
  },
  {
    "name": "countryId",
    "type": "select",
    "label": "Country",
    "values": [
      {
        "image": "",
        "label": "Afganistán",
        "value": 86,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Albania",
        "value": 72,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Alemania",
        "value": 1,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Andorra",
        "value": 2,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Angola",
        "value": 84,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Antigua y Barbuda",
        "value": 87,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Arabia Saudita",
        "value": 88,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Argelia",
        "value": 89,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Argentina",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Armenia",
        "value": 90,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Australia",
        "value": 59,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Austria",
        "value": 4,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Azerbaiyán",
        "value": 91,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bahamas",
        "value": 92,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bangladés",
        "value": 94,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Barbados",
        "value": 95,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Baréin",
        "value": 93,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bélgica",
        "value": 5,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Belice",
        "value": 96,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Benín",
        "value": 97,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bermuda",
        "value": 210,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bielorrusia",
        "value": 73,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Birmania",
        "value": 98,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bolivia",
        "value": 6,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bosnia-Herzegovina",
        "value": 74,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Botsuana",
        "value": 99,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Brasil",
        "value": 7,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Brunéi",
        "value": 100,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bulgaria",
        "value": 8,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Burkina Faso",
        "value": 101,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Burundi",
        "value": 102,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bután",
        "value": 103,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Cabo Verde",
        "value": 104,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Camboya",
        "value": 105,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Camerún",
        "value": 106,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Canadá",
        "value": 63,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Catar",
        "value": 171,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Chad",
        "value": 108,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Chile",
        "value": 10,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "China",
        "value": 55,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Chipre",
        "value": 65,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ciudad del Vaticano",
        "value": 81,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Colombia",
        "value": 11,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Comoras",
        "value": 109,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Corea del Norte",
        "value": 112,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Corea del Sur",
        "value": 113,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Costa de Marfil",
        "value": 114,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Costa Rica",
        "value": 12,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Croacia",
        "value": 82,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Cuba",
        "value": 13,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Dinamarca",
        "value": 14,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Dominica",
        "value": 115,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ecuador",
        "value": 15,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Egipto",
        "value": 116,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "El Salvador",
        "value": 16,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Emiratos Árabes Unidos",
        "value": 117,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Eritrea",
        "value": 118,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Eslovaquia",
        "value": 71,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Eslovenia",
        "value": 66,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "España",
        "value": 17,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Estados Unidos",
        "value": 18,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Estonia",
        "value": 67,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Etiopía",
        "value": 119,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Filipinas",
        "value": 120,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Finlandia",
        "value": 19,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Fiyi",
        "value": 121,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Francia",
        "value": 20,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Gabón",
        "value": 122,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Gambia",
        "value": 123,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Georgia",
        "value": 124,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ghana",
        "value": 125,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Granada",
        "value": 126,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Grecia",
        "value": 21,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Guatemala",
        "value": 22,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Guinea",
        "value": 127,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Guinea Ecuatorial",
        "value": 128,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Guinea-Bissau",
        "value": 129,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Guyana",
        "value": 130,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Haití",
        "value": 131,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Holanda",
        "value": 23,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Honduras",
        "value": 132,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Hungría",
        "value": 24,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "India",
        "value": 56,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Indonesia",
        "value": 133,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Irak",
        "value": 135,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Irán",
        "value": 134,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Irlanda",
        "value": 49,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Islandia",
        "value": 25,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Islas Marshall",
        "value": 155,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Islas Salomón",
        "value": 173,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Israel",
        "value": 136,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Italia",
        "value": 26,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Jamaica",
        "value": 137,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Japón",
        "value": 27,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Jordania",
        "value": 138,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Kazajistán",
        "value": 139,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Kenia",
        "value": 140,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Kirguistán",
        "value": 141,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Kiribati",
        "value": 142,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Kuwait",
        "value": 143,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Laos",
        "value": 144,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Lesoto",
        "value": 145,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Letonia",
        "value": 68,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Líbano",
        "value": 146,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Liberia",
        "value": 147,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Libia",
        "value": 148,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Liechtenstein",
        "value": 50,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Lituania",
        "value": 69,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Luxemburgo",
        "value": 28,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Madagascar",
        "value": 149,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Malasia",
        "value": 150,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Malaui",
        "value": 151,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Maldivas",
        "value": 152,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Malí",
        "value": 153,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Malta",
        "value": 70,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Marruecos",
        "value": 154,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Mauricio",
        "value": 156,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Mauritania",
        "value": 157,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "México",
        "value": 29,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Micronesia",
        "value": 158,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Moldavia",
        "value": 76,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Monaco",
        "value": 77,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Mongolia",
        "value": 159,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Montenegro",
        "value": 160,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Mozambique",
        "value": 161,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Namibia",
        "value": 162,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Nauru",
        "value": 163,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Nepal",
        "value": 164,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Nicaragua",
        "value": 30,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Níger",
        "value": 165,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Nigeria",
        "value": 166,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Noruega",
        "value": 31,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Nueva Zelanda",
        "value": 60,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Omán",
        "value": 167,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Otro",
        "value": 32,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Pakistán",
        "value": 168,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Palaos",
        "value": 169,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Panamá",
        "value": 33,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Papúa Nueva Guinea",
        "value": 170,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Paraguay",
        "value": 34,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Perú",
        "value": 35,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Polonia",
        "value": 36,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Portugal",
        "value": 37,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Puerto Rico",
        "value": 38,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Reino Unido",
        "value": 39,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Rep. Dem. del Congo",
        "value": 111,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "República Centroafricana",
        "value": 107,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "República Checa",
        "value": 9,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "República de Macedonia",
        "value": 75,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "República del Congo",
        "value": 110,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Republica Dominicana",
        "value": 40,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Resto de África",
        "value": 85,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Resto de Asia",
        "value": 57,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Resto de Europa",
        "value": 51,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Resto de Lat.",
        "value": 53,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Resto de Oceania",
        "value": 61,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ruanda",
        "value": 172,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Rumania",
        "value": 41,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Rusia",
        "value": 42,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Samoa",
        "value": 174,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "San Cristóbal y Nieves",
        "value": 175,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "San Marino",
        "value": 78,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "San Vicente y las Granadinas",
        "value": 176,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Santa Lucía",
        "value": 177,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Santo Tomé y Príncipe",
        "value": 178,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Senegal",
        "value": 179,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Serbia y Montenegro",
        "value": 47,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Seychelles",
        "value": 180,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sierra Leona",
        "value": 181,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Singapur",
        "value": 182,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Siria",
        "value": 183,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Somalia",
        "value": 184,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sri Lanka",
        "value": 185,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Suazilandia",
        "value": 186,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sudáfrica",
        "value": 187,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sudán",
        "value": 188,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sudán del Sur",
        "value": 189,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Suecia",
        "value": 43,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Suiza",
        "value": 44,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Surinam",
        "value": 190,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Tailandia",
        "value": 191,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Taiwán",
        "value": 209,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Tanzania",
        "value": 192,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Tayikistán",
        "value": 193,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Timor Oriental",
        "value": 194,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Togo",
        "value": 195,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Tonga",
        "value": 196,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Trinidad y Tobago",
        "value": 197,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Túnez",
        "value": 198,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Turkmenistán",
        "value": 199,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Turquía",
        "value": 79,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Tuvalu",
        "value": 200,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ucrania",
        "value": 80,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Uganda",
        "value": 201,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Uruguay",
        "value": 45,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Uzbekistán",
        "value": 202,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Vanuatu",
        "value": 203,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Venezuela",
        "value": 46,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Vietnam",
        "value": 204,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Yemen",
        "value": 205,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Yibuti",
        "value": 206,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Zambia",
        "value": 207,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Zimbabue",
        "value": 208,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Where is it located?",
    "queryparams": "type=COUNTRIES",
    "refresh_on_change": true
  },
  {
    "name": "select-1632752065201",
    "type": "select",
    "label": "Province",
    "values": [],
    "required": true,
    "placeholder": "A little bit more detailed",
    "queryparams": "parent={countryId}&type=PROVINCES"
  },
  {
    "name": "city",
    "type": "text",
    "label": "City",
    "subtype": "text",
    "required": true,
    "placeholder": "Specify the city"
  },
  {
    "name": "zip",
    "type": "number",
    "label": "Postcode",
    "required": true,
    "placeholder": "ZIP, Postcode"
  },
  {
    "type": "header",
    "label": "What are you offering",
    "subtype": "h3"
  },
  {
    "name": "numberOfVacancies",
    "type": "number",
    "label": "How many positions for this offer?",
    "required": true,
    "placeholder": "Number of vacancies"
  },
  {
    "name": "contractTypeId",
    "type": "select",
    "label": "Contract type",
    "values": [
      {
        "image": "",
        "label": "Autónomo",
        "value": 10,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "De duración determinada",
        "value": 8,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "De relevo",
        "value": 6,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Fijo discontinuo",
        "value": 9,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Formativo",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Indefinido",
        "value": 1,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "A tiempo parcial",
        "value": 4,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Otros contratos",
        "value": 5,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Which type of contract are you offering?",
    "queryparams": "type=CONTRACTS"
  },
  {
    "name": "workingDayId",
    "type": "select",
    "label": "_WorkingHours",
    "values": [
      {
        "image": "",
        "label": "Completa",
        "value": 1,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Indiferente",
        "value": 10,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Parcial - Mañana",
        "value": 2,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Parcial - Tarde",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Parcial - Noche",
        "value": 4,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Parcial - Indiferente",
        "value": 5,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Intensiva - Mañana",
        "value": 6,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Intensiva - Tarde",
        "value": 7,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Intensiva - Noche",
        "value": 8,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Intensiva - Indiferente",
        "value": 9,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Define the working hours of the offer",
    "queryparams": "type=DAYS"
  },
  {
    "name": "salaryPerId",
    "type": "select",
    "label": "",
    "values": [
      {
        "image": "",
        "label": "Bruto/año",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bruto/mes",
        "value": 2,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bruto/hora",
        "value": 1,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Period",
    "queryparams": "type=WAGEPERIOD"
  },
  {
    "name": "salaryFromId",
    "type": "select",
    "label": "Minimum Salary",
    "values": [
      {
        "image": "",
        "label": "3 €",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "5 €",
        "value": 4,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "7 €",
        "value": 5,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "12 €",
        "value": 10,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "18 €",
        "value": 20,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "24 €",
        "value": 30,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "30 €",
        "value": 40,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "36 €",
        "value": 50,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "42 €",
        "value": 60,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "48 €",
        "value": 70,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "60 €",
        "value": 80,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "72 €",
        "value": 90,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "90 €",
        "value": 100,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "120 €",
        "value": 110,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "150 €",
        "value": 120,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "200 €",
        "value": 122,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "300 €",
        "value": 124,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "450 €",
        "value": 126,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "600 €",
        "value": 130,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "900 €",
        "value": 140,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "1.200 €",
        "value": 150,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "1.500 €",
        "value": 160,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "1.800 €",
        "value": 170,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "2.100 €",
        "value": 180,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "2.400 €",
        "value": 190,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "2.700 €",
        "value": 200,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.000 €",
        "value": 210,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.200 €",
        "value": 910,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.400 €",
        "value": 920,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.600 €",
        "value": 220,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.800 €",
        "value": 930,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "4.000 €",
        "value": 940,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "4.200 €",
        "value": 230,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "5.000 €",
        "value": 950,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "6.000 €",
        "value": 240,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "8.000 €",
        "value": 400,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "10.000 €",
        "value": 410,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "12.000 €",
        "value": 250,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "15.000 €",
        "value": 255,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "18.000 €",
        "value": 260,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "21.000 €",
        "value": 265,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "24.000 €",
        "value": 270,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "27.000 €",
        "value": 275,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "30.000 €",
        "value": 280,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "33.000 €",
        "value": 285,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "36.000 €",
        "value": 290,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "39.000 €",
        "value": 295,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "40.000 €",
        "value": 960,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "41.000 €",
        "value": 970,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "42.000 €",
        "value": 300,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "43.000 €",
        "value": 980,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "44.000 €",
        "value": 420,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "45.000 €",
        "value": 990,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "46.000 €",
        "value": 430,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "48.000 €",
        "value": 310,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "50.000 €",
        "value": 440,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "52.000 €",
        "value": 450,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "54.000 €",
        "value": 320,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "56.000 €",
        "value": 460,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "58.000 €",
        "value": 470,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "60.000 €",
        "value": 330,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "62.000 €",
        "value": 480,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "64.000 €",
        "value": 490,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "66.000 €",
        "value": 500,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "68.000 €",
        "value": 510,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "70.000 €",
        "value": 520,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "72.000 €",
        "value": 340,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "74.000 €",
        "value": 530,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "76.000 €",
        "value": 540,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "78.000 €",
        "value": 550,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "80.000 €",
        "value": 560,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "82.000 €",
        "value": 570,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "84.000 €",
        "value": 580,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "86.000 €",
        "value": 590,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "88.000 €",
        "value": 600,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "90.000 €",
        "value": 350,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "92.000 €",
        "value": 610,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "94.000 €",
        "value": 620,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "96.000 €",
        "value": 630,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "98.000 €",
        "value": 640,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "100.000 €",
        "value": 650,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "102.000 €",
        "value": 660,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "104.000 €",
        "value": 670,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "106.000 €",
        "value": 680,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "108.000 €",
        "value": 690,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "110.000 €",
        "value": 700,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "112.000 €",
        "value": 710,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "114.000 €",
        "value": 720,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "116.000 €",
        "value": 730,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "118.000 €",
        "value": 740,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "120.000 €",
        "value": 360,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "125.000 €",
        "value": 750,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "130.000 €",
        "value": 760,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "135.000 €",
        "value": 770,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "140.000 €",
        "value": 780,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "145.000 €",
        "value": 790,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "150.000 €",
        "value": 370,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "155.000 €",
        "value": 800,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "160.000 €",
        "value": 810,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "165.000 €",
        "value": 820,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "170.000 €",
        "value": 830,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "175.000 €",
        "value": 840,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "180.000 €",
        "value": 380,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "185.000 €",
        "value": 850,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "190.000 €",
        "value": 860,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "200.000 €",
        "value": 870,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "210.000 €",
        "value": 880,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "220.000 €",
        "value": 890,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "230.000 €",
        "value": 900,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "240.000 €",
        "value": 390,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Minimum Salary",
    "queryparams": "type=WAGEAMOUNT"
  },
  {
    "name": "salaryToId",
    "type": "select",
    "label": "Maximum Salary",
    "values": [
      {
        "image": "",
        "label": "3 €",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "5 €",
        "value": 4,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "7 €",
        "value": 5,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "12 €",
        "value": 10,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "18 €",
        "value": 20,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "24 €",
        "value": 30,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "30 €",
        "value": 40,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "36 €",
        "value": 50,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "42 €",
        "value": 60,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "48 €",
        "value": 70,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "60 €",
        "value": 80,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "72 €",
        "value": 90,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "90 €",
        "value": 100,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "120 €",
        "value": 110,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "150 €",
        "value": 120,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "200 €",
        "value": 122,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "300 €",
        "value": 124,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "450 €",
        "value": 126,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "600 €",
        "value": 130,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "900 €",
        "value": 140,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "1.200 €",
        "value": 150,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "1.500 €",
        "value": 160,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "1.800 €",
        "value": 170,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "2.100 €",
        "value": 180,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "2.400 €",
        "value": 190,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "2.700 €",
        "value": 200,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.000 €",
        "value": 210,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.200 €",
        "value": 910,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.400 €",
        "value": 920,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.600 €",
        "value": 220,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "3.800 €",
        "value": 930,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "4.000 €",
        "value": 940,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "4.200 €",
        "value": 230,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "5.000 €",
        "value": 950,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "6.000 €",
        "value": 240,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "8.000 €",
        "value": 400,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "10.000 €",
        "value": 410,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "12.000 €",
        "value": 250,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "15.000 €",
        "value": 255,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "18.000 €",
        "value": 260,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "21.000 €",
        "value": 265,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "24.000 €",
        "value": 270,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "27.000 €",
        "value": 275,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "30.000 €",
        "value": 280,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "33.000 €",
        "value": 285,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "36.000 €",
        "value": 290,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "39.000 €",
        "value": 295,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "40.000 €",
        "value": 960,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "41.000 €",
        "value": 970,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "42.000 €",
        "value": 300,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "43.000 €",
        "value": 980,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "44.000 €",
        "value": 420,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "45.000 €",
        "value": 990,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "46.000 €",
        "value": 430,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "48.000 €",
        "value": 310,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "50.000 €",
        "value": 440,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "52.000 €",
        "value": 450,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "54.000 €",
        "value": 320,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "56.000 €",
        "value": 460,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "58.000 €",
        "value": 470,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "60.000 €",
        "value": 330,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "62.000 €",
        "value": 480,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "64.000 €",
        "value": 490,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "66.000 €",
        "value": 500,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "68.000 €",
        "value": 510,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "70.000 €",
        "value": 520,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "72.000 €",
        "value": 340,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "74.000 €",
        "value": 530,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "76.000 €",
        "value": 540,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "78.000 €",
        "value": 550,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "80.000 €",
        "value": 560,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "82.000 €",
        "value": 570,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "84.000 €",
        "value": 580,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "86.000 €",
        "value": 590,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "88.000 €",
        "value": 600,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "90.000 €",
        "value": 350,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "92.000 €",
        "value": 610,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "94.000 €",
        "value": 620,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "96.000 €",
        "value": 630,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "98.000 €",
        "value": 640,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "100.000 €",
        "value": 650,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "102.000 €",
        "value": 660,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "104.000 €",
        "value": 670,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "106.000 €",
        "value": 680,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "108.000 €",
        "value": 690,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "110.000 €",
        "value": 700,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "112.000 €",
        "value": 710,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "114.000 €",
        "value": 720,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "116.000 €",
        "value": 730,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "118.000 €",
        "value": 740,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "120.000 €",
        "value": 360,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "125.000 €",
        "value": 750,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "130.000 €",
        "value": 760,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "135.000 €",
        "value": 770,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "140.000 €",
        "value": 780,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "145.000 €",
        "value": 790,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "150.000 €",
        "value": 370,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "155.000 €",
        "value": 800,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "160.000 €",
        "value": 810,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "165.000 €",
        "value": 820,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "170.000 €",
        "value": 830,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "175.000 €",
        "value": 840,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "180.000 €",
        "value": 380,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "185.000 €",
        "value": 850,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "190.000 €",
        "value": 860,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "200.000 €",
        "value": 870,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "210.000 €",
        "value": 880,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "220.000 €",
        "value": 890,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "230.000 €",
        "value": 900,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "240.000 €",
        "value": 390,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Maximum Salary",
    "queryparams": "type=WAGEAMOUNT"
  },
  {
    "type": "header",
    "label": "Candidate's requirements",
    "subtype": "h3"
  },
  {
    "name": "minimumExperienceId",
    "type": "select",
    "label": "Minimum Experience",
    "values": [
      {
        "image": "",
        "label": "No Requerida",
        "value": 1,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Al menos 1 año",
        "value": 2,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Al menos 2 años",
        "value": 6,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Al menos 3 años",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Al menos 4 años",
        "value": 4,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Más de 5 años",
        "value": 5,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Más de 10 años",
        "value": 8,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Minimum Experience",
    "queryparams": "type=MINEXPERIENCES"
  },
  {
    "name": "minimumStudiesId",
    "type": "select",
    "label": "Minimum Studies",
    "values": [
      {
        "image": "",
        "label": "Otros títulos, certificaciones y carnés",
        "value": 160,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Otros cursos y formación no reglada",
        "value": 170,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Sin estudios",
        "value": 10,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Educación Secundaria Obligatoria",
        "value": 20,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Enseñanzas artísticas (regladas)",
        "value": 23,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Enseñanzas deportivas (regladas)",
        "value": 27,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Formación Profesional Grado Medio",
        "value": 30,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ciclo Formativo Grado Medio",
        "value": 35,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Bachillerato",
        "value": 50,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Formación Profesional Grado Superior",
        "value": 55,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ciclo Formativo Grado Superior",
        "value": 60,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Diplomatura",
        "value": 110,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ingeniería Técnica",
        "value": 120,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Grado",
        "value": 125,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Licenciatura",
        "value": 140,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Ingeniería Superior",
        "value": 130,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Postgrado",
        "value": 143,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Máster",
        "value": 147,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Doctorado",
        "value": 150,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Minimum Experience",
    "queryparams": "type=STUDIESLEVEL"
  },
  {
    "name": "levelId",
    "type": "select",
    "label": "Level",
    "values": [
      {
        "image": "",
        "label": "Becario/a - Prácticas",
        "value": 1,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Empleado/a",
        "value": 2,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Especialista",
        "value": 3,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Mando intermedio",
        "value": 5,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Dirección / gerencia",
        "value": 7,
        "disabled": false,
        "selected": false
      },
      {
        "image": "",
        "label": "Consejo directivo",
        "value": 9,
        "disabled": false,
        "selected": false
      }
    ],
    "required": true,
    "placeholder": "Required level",
    "queryparams": "type=LABORALLEVEL"
  },
  {
    "name": "urlExternalApplication",
    "type": "text",
    "label": "",
    "backend": true,
    "subtype": "text",
    "required": true,
    "placeholder": ""
  },
  {
    "name": "jobReference",
    "type": "text",
    "label": "",
    "backend": true,
    "subtype": "text",
    "required": true,
    "placeholder": ""
  }
]

export const DummyFormLinkedin = [
  {
    "type": "header",
    "label": "__aboutTheOffer",
    "subtype": "h3"
  }, {
    "name": "jobTitle",
    "type": "text",
    "label": "__offerTitle",
    "value": null,
    "subtype": "text",
    "required": true
  }, {
    "name": "jobDescription",
    "type": "textarea",
    "label": "__offerDescription",
    "value": null,
    "subtype": "textarea",
    "required": true,
    "placeholder": "__DescribeResponsabilitiesAndFunctionsTheCandidateWillFaceInThePosition."
  }, {
    "name": "jobSector",
    "type": "text",
    "label": "__jobSector",
    "value": null,
    "subtype": "text",
    "required": true,
    "placeholder": "__jobSectorDescription"
  }, {
    "name": "jobFunction",
    "type": "text",
    "label": "__jobFunction",
    "value": null,
    "subtype": "text",
    "required": true,
    "placeholder": "__jobFunctionDescription"
  }, {
    "name": "laborModel",
    "type": "select",
    "label": "__laborModel",
    "values": [{
      "image": "",
      "label": "__onsiteLaborModel",
      "value": "ONSITE",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__remoteLaborModel",
      "value": "REMOTE",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__hybridLaborModel",
      "value": "HYBRID",
      "disabled": false,
      "selected": false
    }],
    "required": true
  }, {
    "type": "header",
    "label": "Location",
    "subtype": "h3"
  }, {
    "name": "location",
    "type": "text",
    "label": "__location",
    "value": null,
    "subtype": "text",
    "required": true,
    "placeholder": "__whereIsItLocated?"
  }, {
    "type": "header",
    "label": "__whatAreYouOffering",
    "subtype": "h3"
  }, {
    "name": "contractTypeId",
    "type": "select",
    "label": "__contractType",
    "values": [{
      "image": "",
      "label": "__employmentStatusFullTime",
      "value": "FULL_TIME",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__employmentStatusPartTime",
      "value": "PART_TIME",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__employmentStatusContract",
      "value": "CONTRACT",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__employmentStatusTemporary",
      "value": "TEMPORARY",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__employmentStatusVolunteer",
      "value": "VOLUNTEER",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__employmentStatusInternship",
      "value": "INTERNSHIP",
      "disabled": false,
      "selected": false
    }],
    "required": true,
    "placeholder": "__whichTypeOfContractAreYouOffering?"
  }, {
    "type": "header",
    "label": "__candidatesRequirements",
    "subtype": "h3"
  }, {
    "name": "experienceLevel",
    "type": "select",
    "label": "__experienceLevelLinkedin",
    "values": [{
      "image": "",
      "label": "__experienceLevelInternship",
      "value": "INTERNSHIP",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__experienceLevelEntryLevel",
      "value": "ENTRY_LEVEL",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__experienceLevelAssociate",
      "value": "ASSOCIATE",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__experienceLevelMidSeniorLevel",
      "value": "MID_SENIOR_LEVEL",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__experienceLevelDirector",
      "value": "DIRECTOR",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__experienceLevelExecutive",
      "value": "EXECUTIVE",
      "disabled": false,
      "selected": false
    }, {
      "image": "",
      "label": "__experienceLevelNotApplicable",
      "value": "NOT_APPLICABLE",
      "disabled": false,
      "selected": false
    }],
    "required": true,
    "placeholder": "__requiredExperienceLevel"
  }, {
    "name": "urlExternalApplication",
    "type": "text",
    "label": "__jobUrl",
    "value": null,
    "backend": true,
    "subtype": "text",
    "disabled": true,
    "required": true,
    "placeholder": ""
  }
]


export const DummyFormInfojobsMAL = [{
  "type": "header",
  "label": "__aboutTheOffer",
  "subtype": "h3"
},
{
  "name": "jobTitle",
  "type": "text",
  "label": "__offerTitle",
  "subtype": "text",
  "required": true,
  "value": null
},
{
  "name": "jobDescription",
  "type": "textarea",
  "label": "__offerDescription",
  "subtype": "textarea",
  "required": true,
  "placeholder": "__DescribeResponsabilitiesAndFunctionsTheCandidateWillFaceInThePosition.",
  "value": null
},
{
  "name": "jobIndustryId",
  "type": "select",
  "label": "__JobIndustry",
  "values": [],
  "required": true,
  "placeholder": "__classifyTheOfferByTtsIndustry",
  "refresh_on_change": true,
  "queryparams": "type=CATEGORIES"
},
{
  "name": "jobSubIndustryId",
  "type": "select",
  "label": "__jobSubindustry",
  "values": [],
  "required": true,
  "placeholder": "__classifyTheOfferByItsSubindustry",
  "queryparams": "parent={jobIndustryId}&type=SUBCATEGORIES"
},
{
  "type": "header",
  "label": "Location",
  "subtype": "h3"
},
{
  "name": "countryId",
  "type": "select",
  "label": "__country",
  "values": [],
  "required": true,
  "placeholder": "__whereIsItLocated?",
  "refresh_on_change": true,
  "queryparams": "type=COUNTRIES"
},
{
  "name": "provinceId",
  "type": "select",
  "label": "__province",
  "values": [],
  "required": true,
  "placeholder": "__aLittleBitMoreDetailed",
  "queryparams": "parent={countryId}&type=PROVINCES"
},
{
  "name": "city",
  "type": "text",
  "label": "__city",
  "subtype": "text",
  "required": true,
  "placeholder": "__specifyTheCity",
  "value": null
},
{
  "name": "zip",
  "type": "number",
  "label": "__postcode",
  "required": true,
  "placeholder": "__zipPostcode",
  "value": null
},
{
  "type": "header",
  "label": "__whatAreYouOffering",
  "subtype": "h3"
},
{
  "name": "numberOfVacancies",
  "type": "number",
  "label": "__howManyPositionsForThisOffer?",
  "required": true,
  "placeholder": "__numberOfVacancies",
  "value": null
},
{
  "name": "contractTypeId",
  "type": "select",
  "label": "__contractType",
  "values": [],
  "required": true,
  "placeholder": "__whichTypeOfContractAreYouOffering?",
  "queryparams": "type=CONTRACTS"
},
{
  "name": "workingDayId",
  "type": "select",
  "label": "__workingHours",
  "values": [],
  "required": true,
  "placeholder": "__defineTheWorkingHoursOfTheOffer",
  "queryparams": "type=DAYS"
},
{
  "name": "salaryPerId",
  "type": "select",
  "label": "",
  "values": [],
  "required": true,
  "placeholder": "__period",
  "queryparams": "type=WAGEPERIOD"
},
{
  "name": "salaryFromId",
  "type": "select",
  "label": "__minimumSalary",
  "values": [],
  "placeholder": "__minimumSalary",
  "required": true,
  "queryparams": "type=WAGEAMOUNT"
},
{
  "name": "salaryToId",
  "type": "select",
  "label": "__maximumSalary",
  "values": [],
  "placeholder": "__maximumSalary",
  "required": true,
  "queryparams": "type=WAGEAMOUNT"
},
{
  "type": "header",
  "label": "__candidatesRequirements",
  "subtype": "h3"
},
{
  "name": "minimumExperienceId",
  "type": "select",
  "label": "__minimumExperience",
  "values": [],
  "placeholder": "__minimumExperience",
  "required": true,
  "queryparams": "type=MINEXPERIENCES"
},
{
  "name": "minimumStudiesId",
  "type": "select",
  "label": "_minimumStudies",
  "values": [],
  "placeholder": "__minimumStudies",
  "required": true,
  "queryparams": "type=STUDIESLEVEL"
},
{
  "name": "levelId",
  "type": "select",
  "label": "__level",
  "values": [],
  "required": true,
  "placeholder": "__requiredLevel",
  "queryparams": "type=LABORALLEVEL"
},
{
  "name": "urlExternalApplication",
  "type": "text",
  "label": "",
  "subtype": "text",
  "required": true,
  "placeholder": "",
  "backend": true,
  "hidden": true,
  "value": null
},
{
  "name": "jobReference",
  "type": "text",
  "label": "",
  "subtype": "text",
  "required": true,
  "placeholder": "",
  "backend": true,
  "hidden": true,
  "value": null
}
]
