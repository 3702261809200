import { FormElement } from './../classes/custom-forms';
import { Filter } from '../library/classes/filter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../classes/constants';
import { buildFilter } from '../library/utils';
import { MultipostingRequestData, SaveMultiposingFormResponse } from '../classes/multiposting';

@Injectable()
export class MultipostingService {

  constructor(private httpClient: HttpClient) { }

  getMultipostingProviderForm(groupProviderId: number, jobId: number): Observable<SaveMultiposingFormResponse> {
    const url = Constants.MULTIPOSTING_PROVIDER_FORM.replace('{id}', groupProviderId.toString()).replace('{job-id}', jobId.toString());
    return this.httpClient.get<SaveMultiposingFormResponse>(url);
  }

  createMultipostingProviderForm(groupProviderId: number, jobId: number, filled_form: FormElement[], refresh_field: string): Observable<SaveMultiposingFormResponse> {
    const url = Constants.MULTIPOSTING_PROVIDER_FORM.replace('{id}', groupProviderId.toString()).replace('{job-id}', jobId.toString());
    return this.httpClient.post<SaveMultiposingFormResponse>(url, { filled_form, refresh_field });
  }

  saveMultipostingProviderForm(group_multiposting_provider: number, config: FormElement[], job: number): Observable<SaveMultiposingFormResponse> {
    const url = Constants.MULTIPOSTING_JOBS.replace('{id}', group_multiposting_provider.toString());
    return this.httpClient.post<SaveMultiposingFormResponse>(url, { group_multiposting_provider, job, config });
  }

  editMultipostingProviderForm(config: FormElement[], postId: number): Observable<SaveMultiposingFormResponse> {
    const url = Constants.MULTIPOSTING_JOBS_DETAIL.replace('{id}', postId.toString());
    return this.httpClient.put<SaveMultiposingFormResponse>(url, { config });
  }

  getMultipostingPostDetail(postId: number): Observable<SaveMultiposingFormResponse> {
    const url = Constants.MULTIPOSTING_JOBS_DETAIL.replace('{id}', postId.toString());
    return this.httpClient.get<SaveMultiposingFormResponse>(url);
  }

  getJobMultipostingRequests(jobId: number): Observable<MultipostingRequestData[]> {
    const url = Constants.MULTIPOSTING_REQUESTS_JOB.replace('{id}', jobId.toString());
    return this.httpClient.get<MultipostingRequestData[]>(url);
  }

  createMultipostingRequest(job_multiposting: number): Observable<any> {
    const url = Constants.MULTIPOSTING_REQUESTS;
    return this.httpClient.post<any>(url, { job_multiposting, extra_config: {} });
  }

  retryMultipostingRequest(postId: number): Observable<any> {
    const url = Constants.MULTIPOSTING_REQUESTS_RETRY.replace('{id}', postId.toString());
    return this.httpClient.post<any>(url, {});
  }
}
