import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../classes/constants';

@Injectable()
export class RecoverPasswordService {

  constructor(private httpClient: HttpClient) {}

  resetPassword(email: string): Promise < any > {
    return this.httpClient.post(Constants.RESET_PASSWORD, { email })
      .toPromise();
  }

  newPassword(token, uid, new_password1, new_password2): Promise < any > {
    return this.httpClient.post(Constants.CONFIRM_NEW_PASSWORD, { token, uid, new_password1, new_password2 })
      .toPromise();
  }

}
