import { Status, PHASES } from './phases';

export interface CandidateFilters {
    name: string;
    label: string;
    group: string;
    values:  Array< {
      value: string,
      label: string,
    } >;
}

export interface DatabaseCandidateFilters {
  form: CandidateFilters[];
  instruments: CandidateFilters[];
  interviews: CandidateFilters[];
}

export const StatusFilter: CandidateFilters = {
  name: 'status',
  label: '__status',
  group: 'general',
  values: [
    { value: Status.PASS.value, label: Status.PASS.label },
    { value: Status.PND.value, label: Status.PND.label},
    { value: Status.KO.value, label: Status.KO.label },
  ]
};

export const RatingFilter: CandidateFilters = {
  name: 'rate',
  label: '__rating',
  group: 'general',
  values: [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
  ]
};

export const TaskFilter: CandidateFilters = {
  name: 'mark',
  label: '__task',
  group: 'general',
  values: [
    { value: 'phoned', label: '__toCall' },
    { value: 'to-schedule', label: '__toSchedule' },
    { value: 'scheduled', label: '__scheduled' },
    { value: 'to-rate', label: '__videoInterviewTask' },
  ]
}

export const SeenFilter: CandidateFilters = {
  name: 'seen',
  label: '__new',
  group: 'general',
  values: [
    { value: 'false', label: '__newCandidates' },
    { value: 'true', label: '__viewedCandidates' }
  ]
}
export class FiltersGroup {
  type: string;
  label: string;
  collapsed = true;

  constructor(type: string, label: string, collapsed?: boolean) {
    this.type = type;
    this.label = label;
    this.collapsed = collapsed;
  }
}

export const GENERAL_FILTERS_GROUP = new FiltersGroup('general', '__generalFilters', false);
export const FORM_FILTERS_GROUP = new FiltersGroup('form', '__inscriptionForm', true);
export const INSTRUMENTS_FILTERS_GROUP = new FiltersGroup('instruments', '__onlineAssessment', true);
export const INTERVIEWS_FILTERS_GROUP = new FiltersGroup('interviews', '__interviews', true);

export const FiltersGroupDictionary = {
  'general': GENERAL_FILTERS_GROUP,
  'form': FORM_FILTERS_GROUP,
  'instruments': INSTRUMENTS_FILTERS_GROUP,
  'interviews': INTERVIEWS_FILTERS_GROUP,
}
