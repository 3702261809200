import { Component, OnInit  } from '@angular/core';

@Component({
  selector: 'app-job-empty',
  templateUrl: './job-empty.component.html',
  styleUrls: ['./job-empty.component.scss']
})
export class JobEmptyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
