import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { WsgiEmail, EmailState, Email, PhaseEmails, PhaseOAEmails } from '../../classes/wsgi-email';
import { UserService } from '../../services/user-service.service';
import { AlertsService } from '../../services/alerts.service';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ActivatedRoute } from '@angular/router';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { LOAD_IMAGE_ERROR } from '../../../assets/ckeditor/load-image-error';
import { SIZE_ERROR } from '../../../assets/ckeditor/size-error';
import { ClientTranslationService } from './../../services/client-translation.service';
import { Subject } from 'rxjs';
import { CandidateService } from '../../services/candidate.service';
import { calculateContentSize, getBase64FromFile, splitStringInLines } from '../../utils';
declare const CKEDITOR: any;


@Component({
  selector: 'app-wsgieditor-emails',
  templateUrl: './wsgieditor-emails.component.html',
  styleUrls: [ './wsgieditor-emails.component.scss' ]
})
export class WsgieditorEmailsComponent implements OnInit, OnDestroy {

  @Input() set emails(value: EmailState) {
    this._emails = this.filterEmails(value);
    this._emails = this.sortEmailsData(this.emails);
    this._setEmailsOthers();
  }

  get emails(): EmailState {
    return this._emails;
  }

  @Input() readOnly = false;
  @Input() instrumentData;
  @Input() candidaturePhaseActive;
  @Input() otherAssessmentPhaseActive;

  config = {
    extraPlugins: 'colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: [ 'Format', 'Font' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'variables', items: [ '__jobName', '__clientName', '__candidateName' ] },
      { name: 'images', items: [ 'Image', '__uploadImage' ] }
    ],
    ignoreEmptyParagraph: true,
  };

  emailDesc = {
    INSCRIPTIONS: {
      PENDING: '__inscriptionPending',
      PASS: '__inscriptionPass',
      KO: '__inscriptionKo'
    },
    CANDIDATURES: {
      PENDING: '__candidaturesPending',
      PASS: '__candidaturePass',
      KO: '__candidatureKo'
    },
    OTHER_ASSESSMENTS: {
      PENDING: '__otherAssessmentPending',
    },
    OTHERS: {}
  };

  _emails = new EmailState();
  emailSelected: WsgiEmail;
  isEmailEmpety: boolean;
  stateSelected: string;
  modalRef: BsModalRef;
  buttons = WsgiEmail.VARS;
  emailIconSrc = '';
  titleRepeat: boolean;
  titleEmpty: boolean;
  update = false;
  initialTitle: string;
  emailsOthers: any = [];
  client: Client = new Client();

  uploadImageVariable = { title: '__uploadImage', icon: 'upload.png' };
  editorContent$: Subject<String> = new Subject();
  editorContentSize = 0;
  disableSend = true;
  loadingImages = false;

  @Output() emailsChange: EventEmitter<EmailState> = new EventEmitter();

  componentSubscriptions: any = {};

  @ViewChild('inputFile', { static: true }) inputFile;

  constructor(
    private modalService: BsModalService,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    public clientService: ClientService,
    private translateService: TranslateService,
    public candidateService: CandidateService,
    private clientTranslationService: ClientTranslationService
  ) { }

  ngOnInit() {
    this.getClientAndConfig();
    this.subcribeToEditorContentDebounce();
    this.subcribeToEditorContent();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  getClientAndConfig() {
    const client_id = +this.activatedRoute.snapshot.paramMap.get('client');

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(client_id)
      .subscribe(
        response => {
          this.client = Object.assign(new Client, response);
          this.setReadOnlyConfig();
        },
        error => console.log(error)
      );
  }

  setReadOnlyConfig() {
    this.readOnly = this.readOnly || !this.clientService.isActionActive(this.client);
  }

  setEmailStatus(phase: string, status: string, newStatus: boolean) {
    this.emails[ phase ][ status ].send_email = newStatus;
    this.emitEmails();
  }

  editEmail(title: string, state: string, emailModalTemplate) {
    this.initialTitle = title;
    this.emailSelected = new WsgiEmail(title, { content: this.emails[ state ][ title ].content, subject: this.emails[ state ][ title ].subject });
    this.stateSelected = state;
    this.update = true;
    this.modalRef = this.modalService.show(emailModalTemplate, { class: 'gray modal-lg', backdrop: 'static' });
    this.editorContent$.next(this.emailSelected.data.content);
  }

  createEmailOther(state: string, emailModalOtherTemplate) {
    this.emailSelected = new WsgiEmail('', { content: '', subject: '' });
    this.stateSelected = state;
    this.modalRef = this.modalService.show(emailModalOtherTemplate, { class: 'gray modal-lg', backdrop: 'static' });
  }

  deleteEmailTemplateOther() {
    const emailToDelete = this.emailSelected.title;
    delete this.emails.OTHERS[ emailToDelete ];
    this.modalRef.hide();
    this._setEmailsOthers();
    this.emitEmails();
  }

  closeModal() {
    const subjectTrim = this.emailSelected.data.subject.trim();
    const textToTrim = this.textTrimHtml(this.emailSelected.data.content);

    if (subjectTrim === '') {
      this.emailSelected.data.subject = '';
    }

    if (textToTrim === '') {
      this.emailSelected.data.content = '';
    }

    EmailState.addEmail(this.stateSelected, this.emailSelected, this._emails);
    this.update = false;
    this.modalRef.hide();
    this._setEmailsOthers();
    this.emitEmails();
    const alert = this._getAlertText(subjectTrim, textToTrim);
    this.alertService.setAlert(alert);
  }

  textTrimHtml(textToTrim) {
    const preTrim = textToTrim.replace(/(?:<[^>]+>)|&nbsp;|' '|\n/gi, '');
    return preTrim.trim();
  }

  _getAlertText(subject: string, body: string): { message: string, type: string; } {
    const flag = subject && body ? true : false;
    const flagAnd = subject || body ? true : false;
    this.isEmailEmpety = flag;

    const subject_translation = this.translateService.instant('__subject');
    const and_translation = this.translateService.instant('__and');
    const body_translation = this.translateService.instant('__body');
    const are_translation = this.translateService.instant('__are');
    const is_translation = this.translateService.instant('__is');

    const subject_text = !Boolean(subject) ? subject_translation : '';
    const flagAnd_text = !Boolean(flagAnd) ? and_translation + ' ' : '';
    const body_text = !Boolean(body) ? body_translation : '';
    const flagAndPlus = !Boolean(flagAnd) ? are_translation + ' ' : is_translation;

    const email_empty_text = this.translateService.instant('__emptyEmailText');
    const empty_text = this.translateService.instant('__empty');
    const emailSaved = this.translateService.instant('__emailSaved');

    const prep = !flag ? 'and ' : '';
    const alert = !flag ? {
      message: email_empty_text + `${subject_text} ${flagAnd_text} ${body_text} ${flagAndPlus}` + empty_text,
      type: 'warning'
    } : {
      message: emailSaved,
      type: 'success'
    };
    return alert;
  }

  addVar(button) {
    // tslint:disable-next-line:forin
    for (const instance in CKEDITOR.instances) {
      CKEDITOR.instances[ instance ].insertText(button.value);
    }
  }


  uploadImage(event) {
    event.target.files[ 0 ].size < 1_000_000 ?
      getBase64FromFile(event.target.files[ 0 ]).then(
        base64Image => {
          // incluir img en el email
          for (const instance in CKEDITOR.instances) {
            CKEDITOR.instances[ instance ].insertHtml(`<img alt="" src="${base64Image}" />`);
          }
        }
      )
      :
      this.insertSizeError();
  }

  emitEmailContentChange($event) {
    this.editorContent$.next($event);
  }

  subcribeToEditorContentDebounce() {
    this.componentSubscriptions.editorContent$ = this.editorContent$.pipe(
      debounceTime(500)
    ).subscribe(
      editorContent => {
        this.transformImageUrlToBase64(editorContent);
      });
  }
  subcribeToEditorContent() {
    this.componentSubscriptions.editorContent$ = this.editorContent$.pipe(
      throttleTime(1000)
    ).subscribe(
      editorContent => {
        if (editorContent) {
          const contentSizeInfo = calculateContentSize(editorContent);
          this.editorContentSize = contentSizeInfo.size;
          this.disableSend = contentSizeInfo.exeedsMaxSize;
        }
      });
  }

  async transformImageUrlToBase64(editorContent) {
    //Regex buscar img con srg url
    const regex = /<img\s.+src="((?!data:)[^"]+)"/m;
    const matches = editorContent.match(regex);
    const url = matches && matches[ 1 ];
    //si url
    if (url) {
      this.loadingImages = true;
      this.componentSubscriptions.base64Image$ = this.candidateService.getImageAsBase64(url).subscribe(
        base64Image => {
          this.emailSelected.data.content = editorContent.replace(`src="${url}"`, `src="${splitStringInLines(base64Image.dataUrl)}"`);
          this.loadingImages = false;
          this.editorContent$.next(this.emailSelected.data.content);
        },
        error => {
          /* ERRORS
            { "code": 0, "message": "Unespecified error." }
            { "code": 1, "message": "The resource exceeds the maximum file size." }
            { "code": 2, "message": "The resource is not an image." }

            We only control the size one, for the other two we use a generic error
          */
          error.error.error.code === '1'
            ?
            this.insertSizeError(false, editorContent, url)
            :
            this.insertGenericError(editorContent, url);
          this.loadingImages = false;
        });
    }
  }

  insertSizeError(localImage = true, editorContent?, url?) {
    const languageKey = this.getLanguageKey();
    //For local image there is no img tag to replase, so we insert it, otherwise we just replase the src with the error

    if (localImage) {
      // tslint:disable-next-line:forin
      for (const instance in CKEDITOR.instances) {
        CKEDITOR.instances[ instance ].insertHtml(`<img alt="" src="${SIZE_ERROR[ languageKey ]}" style="height:auto; width:600px"/>`);
      }
    } else {
      this.emailSelected.data.content = editorContent.replace(`src="${url}"`, `src="${SIZE_ERROR[ languageKey ]}" style="height:auto; width:600px"`);
    }
  }

  insertGenericError(editorContent, url) {
    const languageKey = this.getLanguageKey();
    this.emailSelected.data.content = editorContent.replace(`src="${url}"`, `src="${LOAD_IMAGE_ERROR[ languageKey ]}" style="height:auto; width:600px"`);
    this.loadingImages = false;
  }

  getLanguageKey() {
    const browserLanguage = this.clientTranslationService.getNavigatorLanguage();

    switch (browserLanguage) {
      case 'de':
      case 'el':
      case 'en':
      case 'es':
      case 'fr':
      case 'it':
        return browserLanguage;

      case 'pt':
      case 'pt-br':
        return 'pt';

      default:
        return 'en';
    }
  }

  _setEmailsOthers() {
    this.emailsOthers = Object.entries(this._emails[ 'OTHERS' ]);
  }

  filterEmails(emails: EmailState): EmailState {
    const possiblePhases = [ 'INSCRIPTIONS', 'CANDIDATURES', 'OTHER_ASSESSMENTS', 'OTHERS' ];
    return Object.keys(emails)
      .filter(phaseKey => possiblePhases.includes(phaseKey))
      .reduce((prev, phaseKey) => {
        prev[ phaseKey ] = phaseKey === 'OTHERS' ? (emails.OTHERS || {}) : this._createEmails(emails[ phaseKey ]);
        return prev;
      }, new EmailState());
  }

  private _createEmails(phaseEmails: PhaseEmails): any {
    if (Object.keys(phaseEmails).length > 1) {
      return Object.keys(phaseEmails)
        .reduce((prev, emailKey) => {
          prev[ emailKey ] = Object.assign(new Email(), phaseEmails[ emailKey ]);
          return prev;
        }, new PhaseEmails());
    } else {
      return Object.keys(phaseEmails)
        .reduce((prev, emailKey) => {
          prev[ emailKey ] = Object.assign(new Email(), phaseEmails[ emailKey ]);
          return prev;
        }, new PhaseOAEmails());
    }
  }

  sortEmailsData(emails: EmailState): EmailState {
    const labels = [ 'INSCRIPTIONS', 'CANDIDATURES', 'OTHER_ASSESSMENTS', 'OTHERS' ];
    const data = new EmailState();
    labels.forEach(value => (data[ value ] = emails[ value ] || {}));
    return data;
  }

  isEmpty(email: { content: string, subject: string; }): { img: string, tooltip: string; } {
    const emptyEmail = this.translateService.instant('__emptyEmail');
    const isEmpty = this.textTrimHtml(email.content)
      .length === 0 || this.textTrimHtml(email.subject)
        .length === 0;
    const img = isEmpty ? 'assets/email-icon-alert.png' : 'assets/email-icon.png';
    const tooltip = isEmpty ? emptyEmail : '';
    return { img, tooltip };
  }

  existTitle(): Boolean {
    const isTitleInEmails = Object.keys(this.emails.OTHERS || {})
      .includes(this.emailSelected.title) && this.update;
    const hasChangedTitle = this.initialTitle === this.emailSelected.title && this.initialTitle !== '';
    return isTitleInEmails && !hasChangedTitle;
  }

  isTitleEmpty(): Boolean {
    return this.emailSelected.title === '' || this.emailSelected.title == null;
  }

  emitEmails() {
    this.emailsChange.emit(this.emails);
  }

  checkInstrumentsPhase(title: string) {
    let canInstrument = false;
    let oaInstrument = false;

    if (title === 'PENDING') {

      if (this.instrumentData) {
        this.instrumentData.forEach(instrument => {
          if (instrument.phase === 'CAN') {
            canInstrument = true;
          } else if (instrument.phase === 'OA') {
            oaInstrument = true;
          }
        });
      }
    }
    return [ canInstrument, oaInstrument ];
  }

  disabledByInstruments(state: string, title: string): boolean {

    if (title === 'PENDING') {

      let canInstrument = false;
      let oaInstrument = false;

      [ canInstrument, oaInstrument ] = this.checkInstrumentsPhase(title);

      switch (state) {

        case 'CANDIDATURES':
          if (canInstrument && this.candidaturePhaseActive) {
            this.setEmailStatus(state, title, true);
          }
          return canInstrument && this.candidaturePhaseActive;

        case 'OTHER_ASSESSMENTS':
          if (oaInstrument && this.otherAssessmentPhaseActive) {
            this.setEmailStatus(state, title, true);
          }
          return oaInstrument && this.otherAssessmentPhaseActive;

        default:
          return false;
      }
    } else {
      return false;
    }
  }

  handleVariablesCkeditor(variable: string) {
    variable = variable.replace(/{{(<([^>]+)>)+/ig, '{{');
    variable = variable.replace(/(<([^>]+)>)+}}/ig, '}}');
    return variable;
  }
}
