import { RestService } from '../../library/classes/rest-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { User } from '../../classes/user';
import { Constants } from '../../classes/constants';
import { buildFilter } from '../../library/utils';
import { JobCategory } from '../../classes/job';
import { Filter } from '../../library/classes/filter';
import { EmailTemplate } from '../../classes/wsgi-email';
import { Client, ClientLite } from '../models/client';
import { UserService } from '../../services/user-service.service';
import { ProfessionsByGroup } from '../../classes/group';
import { Subscription } from '../models/subscription';

@Injectable()
export class ClientService extends RestService<Client> {

  endpoint = Constants.GROUP_URL;

  constructor(httpClient: HttpClient, private userService: UserService) {
    super(httpClient);
  }

  getClientlist(...filters: Filter[]): Observable<HttpResponse<Client[]>> {
    const filter = buildFilter(...filters);
    const url = `${Constants.PARTNER_CLIENT_LIST}${filter}`;
    return this.httpClient.get<Client[]>(url, { observe: 'response' });
  }


  getClientlistLite(...filters: Filter[]): Observable<ClientLite[]> {
    const filter = buildFilter(...filters);
    const url = `${Constants.PARTNER_CLIENT_LIST_LITE}${filter}`;
    return this.httpClient.get<ClientLite[]>(url);
  }

  getClientEmails(id: number): Observable<EmailTemplate[]> {
    const filter = buildFilter(new Filter('group', String(id)));
    const url = `${Constants.EMAIL_TEMPLATES}${filter}`;
    return this.httpClient.get<EmailTemplate[]>(url);
  }

  setClientEmails(client: Client, emails: EmailTemplate) {
    const url = emails.id ? `${Constants.EMAIL_TEMPLATES}${emails.id}/` : Constants.EMAIL_TEMPLATES;
    const http_request = emails.id ? this.httpClient.put<any>(url, emails)
      .toPromise() : this.httpClient.post<any>(url, emails)
        .toPromise();
    return http_request;
  }

  getJobCategories(client: number): Observable<JobCategory[]> {
    const filter = buildFilter(new Filter('group', String(client)));
    const url = `${Constants.JOB_CATEGORIES_URL}${filter}`;
    return this.httpClient.get<JobCategory[]>(url);
  }

  getJobCategoriesByUUID(clientUUID: string): Observable<JobCategory[]> {
    return this.httpClient.get<JobCategory[]>(Constants.JOB_CATEGORIES_BY_CLIENT_UUID_URL.replace('{uuid}', clientUUID));
  }

  // TODO: Remove method when users service will be a RestService
  getUsersByClient(client_id: string): Observable<User[]> {
    const filter = buildFilter(new Filter('group', client_id));
    const url = `${Constants.USERS_URL}${filter}`;
    return this.httpClient.get<User[]>(url);
  }

  submitFile(file: FormData): Observable<any> {
    return this.httpClient.post<any>(Constants.SUBMIT_FILE, file);
  }

  getPublicClient(client_uuid: string): Observable<any> {
    return this.httpClient.get<any>(Constants.PUBLIC_CLIENT_URL.replace('{uuid}', client_uuid));
  }

  getPublicProfessions(client_uuid: string): Observable<any> {
    return this.httpClient.get<any>(Constants.CLIENT_HUB_URL.replace('{uuid}', client_uuid));
  }

  getPrivateProfessions(client_uuid: string): Observable<any> {
    return this.httpClient.get<any>(Constants.CLIENT_HUB_PRIVATE_URL.replace('{uuid}', client_uuid));
  }

  getActiveJobsCount(clientId: number) {
    this.httpClient.get<any>(Constants.CLIENT_ACTIVE_JOBS_COUNT.replace('{id}', clientId.toString())).subscribe(
      result => {
        this.loadClient(clientId).subscribe(
          client => {
            client.active_jobs = result.active_jobs;
            this.saveClient(client);
          }
        );
      }
    );
  }

  isActionActive(client = new Client()) {
    return client.status !== 'INA' || this.userService.hasHQPermissions();
  }

  getProfessionsAndJobsByClient(id: number, ...filter: Filter[]): Observable<HttpResponse<ProfessionsByGroup>> {
    const query = buildFilter(...filter);
    const url = Constants.PROFESSIONS_LIST_BY_GROUP.replace('{id}', id.toString()) + '/' + query;
    return this.httpClient.get<ProfessionsByGroup>(url, { observe: 'response' });
  }

  createSubscriptionForClient(subscription: Subscription, uuid: string): Observable<Subscription> {
    return this.httpClient.post<any>(Constants.SUBSCRIPTIONS.replace('{uuid}', uuid), subscription);
  }

  unsubscribe(uuid: string): Observable<any> {
    return this.httpClient.put<any>(Constants.UNSUBSCRIBE.replace('{uuid}', uuid), {});
  }

  getClientFromSubscription(uuid: string): Observable<any> {
    return this.httpClient.get<any>(Constants.CLIENT_FROM_SUBSCRIPTION.replace('{uuid}', uuid));
  }

  loadClient(client_id): Observable<Client> {

    return this.retrieve(client_id);

    /*
    const localClient = JSON.parse(localStorage.getItem(Constants.CLIENT_LOCAL_STORAGE))

    if (localClient && +localClient.id === +client_id) {
      return of(Object.assign(new Client, localClient))
    } else {
      return this.retrieve(client_id).pipe(
        map((client)=> {
        localStorage.setItem(Constants.CLIENT_LOCAL_STORAGE, JSON.stringify(client));
        return client
      }));
    }
    */
  }

  saveClient(client: Client) {

    /*
    localStorage.setItem(Constants.CLIENT_LOCAL_STORAGE, JSON.stringify(client));
    */
  }

  // Job Categories
  createJobCategory(jobCategory: JobCategory) {
    return this.httpClient.post<any>(Constants.JOB_CATEGORIES_URL, jobCategory);
  }

  deleteJobCategory(client_id: number, jobCategoryId: number) {
    return this.httpClient.delete<any>(Constants.JOB_CATEGORY_URL.replace('{id}', jobCategoryId.toString()));
  }

  editJobCategory(jobCategory: JobCategory) {
    return this.httpClient.put<any>(Constants.JOB_CATEGORY_URL.replace('{id}', jobCategory.id.toString()), jobCategory);
  }
}
