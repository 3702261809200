import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { InstrumentService } from '../../../instruments/instrument.service';
import { ClientService } from '../../../partners/services/client.service';
import { Client } from '../../../partners/models/client';
import { ActivatedRoute } from '@angular/router';
import { InstrumentCreationData, Instrument } from '../../../instruments/models/instrument';
import { INSTRUMENT_TYPES } from '../../../instruments/constants';
import { FormJob } from '../../../instruments/models/candidate-form';
import { Filter } from '../../../library/classes/filter';

@Component({
  selector: 'app-instruments-list',
  templateUrl: './instruments-list.component.html',
  styleUrls: ['./instruments-list.component.scss']
})

export class InstrumentsListComponent implements OnInit, OnDestroy {

  _instruments: any[] = [];
  @Input() set instruments(value: any[]) {
    this._instruments = value || [];
    if (this._instruments[0] instanceof InstrumentCreationData) {
      this._instruments = this._instruments.map(instrument => instrument.id);
    }
    this.filterInstruments();
  }

  @Input() formInstruments: FormJob[] = [];
  instrumentTypes = INSTRUMENT_TYPES;
  instruments_remote: Instrument[] = [];
  showInstruments: Instrument[] = [];
  client: Client = new Client();

  componentSubscriptions: any = {};

  constructor(
    private instrumentService: InstrumentService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService) { }

  ngOnInit() {
    this.client.id = +this.activatedRoute.snapshot.paramMap.get('client');
    this.getInstrument();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getInstrument() {
    this.componentSubscriptions.instrumentServiceList$ = this.instrumentService.list(new Filter('group', this.client.id)).subscribe(
      instruments_remote => {
        this.instruments_remote = instruments_remote;
        this.filterInstruments();
      }
    );
  }

  filterInstruments() {
    this.showInstruments = this.instruments_remote.filter(instrument =>
      this._instruments.includes(instrument.id) && instrument.provider_origin !== INSTRUMENT_TYPES.FORMS
    );
  }
}


