import { Filter } from './../../library/classes/filter';

import { Job, JobCategory, JobCategoryTemplate } from './../../classes/job';
import { Component, OnInit, OnDestroy, EventEmitter, Output, ViewChild, ElementRef, Renderer2, AfterContentChecked } from '@angular/core';
import { Router, ActivatedRoute, PRIMARY_OUTLET, NavigationEnd, NavigationExtras } from '@angular/router';
import { buildQueryParams } from '../../library/utils';
import { UserService } from '../../services/user-service.service';
import { Profession } from './../../classes/profession';
import { ProfessionService } from './../../services/profession.service';
import { AlertsService } from '../../services/alerts.service';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { Subscription, Observable, fromEvent, of, combineLatest } from 'rxjs';
import { setFilter } from '../../library/utils';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LayoutService, LayoutActions } from '../../services/layout.service';
import { JobService } from '../../services/job.service';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { ShortPhasesLabels } from '../../classes/phases';
import { TranslateService } from '@ngx-translate/core';
import { delayedRetry, handleDisconnectedError } from '../../utils';
import { map, debounceTime, distinctUntilChanged, catchError, takeWhile, filter } from 'rxjs/operators';
import { SidebarHeaderComponent } from '../sidebar-header/sidebar-header.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Output() wizard: EventEmitter<any> = new EventEmitter();
  ks: string;
  subscription: Subscription;
  buttonCollapse = false;
  loading = true;


  @ViewChild('searchJob', { static: true }) searchJob;
  @ViewChild('sidebarContainer', { static: true }) sidebarContainer;
  @ViewChild('filtersTrigger', { static: true }) filtersTrigger: MatMenuTrigger;

  search = '';
  client: Client = new Client();
  queryParams: any = { queryParams: {} };
  jobs: Job[] = [];
  parameters: Filter[] = [];
  templates: Profession[] = [];
  categories = JobCategory.CATEGORIES;
  privacies = Job.PRIVACIES;
  statuses = Job.STATUSES;
  jobCategoriesTemplate: JobCategoryTemplate[] = [];
  sortingFields = [
    { value: 'expirationDate', title: '__expirationTime', selected: false },
    { value: 'createdDate', title: '__creationTime', selected: true },
    { value: 'closedDate', title: '__closeTime', selected: false }
  ];
  sortingOrder = [
    { value: '', title: '__ascending', selected: false },
    { value: '-', title: '__descending', selected: true }
  ];
  filters = {
    ordering: null,
    template: null,
    privacy: null,
    status: null,
    organization: null,
    function: null,
    location: null,
    profession: null
  };
  appliedFilters: { id: string, value: string }[];
  job_id: number;
  link_active = 'candidates';
  job_active: string = null;
  inActionJob: Job = null;
  candidateSelected = 'open-sidebar';
  toggle_sidebar = true;

  modalRef: BsModalRef;

  isCustomNav = false;
  customNavItems: any;

  totalJobs = 0;
  moreJobs: boolean;

  scrollHeight = 0;

  @ViewChild(SidebarHeaderComponent, { static: true }) child: SidebarHeaderComponent;

  @ViewChild('filtersRow', { static: true }) filtersRow: ElementRef;
  @ViewChild('jobsList', { static: true }) jobsList: ElementRef;
  saveScrollHeight: any;
  scrolledInit: boolean = false;

  componentSubscriptions: any = {};

  constructor(
    public jobService: JobService,
    private router: Router,
    public userService: UserService,
    public clientService: ClientService,
    private professionService: ProfessionService,
    public activatedRoute: ActivatedRoute,
    private keyboardShortcutService: KeyboardShortcutService,
    public layoutService: LayoutService,
    public translateService: TranslateService,
    private alertService: AlertsService,
    private renderer: Renderer2
  ) { }

  checkCurrentLocation(location: string) {
    switch (location) {
      case '__jobs':
        this.isCustomNav = false;
        break;
      case '__account':
        this.isCustomNav = true;
        const navItems = [
          { title: '__myProfileNavTitle', description: '__myProfileNavDesc', route: 'profile', enabled: true },
          { title: '__multipostingAccountsNavTitle', description: '__multipostingAccountsNavDesc', route: 'multiposting', enabled: true }
        ];
        this.customNavItems = navItems.filter(item => item.enabled);
        break;
    }
  }

  ngOnInit() {
    this.scrollToCollapse();
    this.client.id = +this.activatedRoute.snapshot.paramMap.get('client');
    this.job_id = +this.activatedRoute.snapshot.paramMap.get('job');
    this.job_active = `${this.job_id}`;
    this.initializeJobParams();
    this.setData();
    this.subscribeRouter();
    this.subscribeCandidateParam();
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
    this.searchJobs();
  }

  moveSideBarToUrlJob() {

    const url: string[] = this.router.url.split('/');
    const jobActiveId = url[3];
    const jobSelected = document.getElementById(jobActiveId);

    if (jobSelected) {
      jobSelected.scrollIntoView({ block: "end", behavior: "smooth" });
      this.scrolledInit = true;
    }
  }

  searchJobs() {
    this.componentSubscriptions.fromEventPipe$ = fromEvent(this.searchJob.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.resetJobsPage();
        this.parameters = setFilter(this.parameters, new Filter('search', text));
        this.loading = true;
        this.componentSubscriptions.jobServiceGetJobList$ = this.jobService.getJobList(...this.parameters)
          .subscribe(
            response => {
              this.setJobs(response);
            }
          );
      });
  }

  setData() {

    this.componentSubscriptions.getDataPipe$ = this.getData()
      .pipe(
        delayedRetry(3000, 3)
      )
      .subscribe(data => {
        this.setJobs(data.jobs);
        this.client = data.client;
        this.templates = data.professionsList;
        this.templates.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.jobCategoriesTemplate = JobCategory.jobCategoryToTemplate(data.organizationClient);
        this.client.categories = data.organizationClient;
      },
        err => {
          handleDisconnectedError(err, this.alertService);
        }
      );
  }

  getData(): Observable<any> {
    const data = combineLatest(
      ...this.getRequiredObservables(),
      (jobs, client, professionsList, organizationClient) => ({
        jobs,
        client,
        professionsList,
        organizationClient
      })
    );

    return data;
  }

  getRequiredObservables() {
    const observablesArray = [
      this.jobService.getJobList(...this.parameters),
      this.clientService.loadClient(this.client.id),
      this.professionService.list(new Filter('group', this.client.id))
        .pipe(catchError(() => of([]))),
      this.clientService.getJobCategories(this.client.id)
    ];
    return observablesArray;
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'SidebarComponent.Down':
        this.goToNextJob();
        break;
      case 'SidebarComponent.Up':
        this.goToPreviousJob();
        break;
      case 'SidebarComponent.AltA':
        this.goToCandidatesPool();
        break;
      case 'SidebarComponent.ShiftN':
        this.createJob();
        break;
      case 'SidebarComponent.AltShiftF':
        this.goToSearch();
        break;
    }
  }

  goToNextJob() {
    if (!this.activatedRoute.snapshot.queryParams['candidate']) {
      let index = this.jobs.findIndex(job => job.id === +this.job_active);
      if (++index < this.jobs.length) {
        this.navigateTo([this.jobs[index].id, this.getCurrentPath()]);
      }
    }
  }

  goToPreviousJob() {
    if (!this.activatedRoute.snapshot.queryParams['candidate']) {
      let index = this.jobs.findIndex(job => job.id === +this.job_active);
      if (index > 0) {
        this.navigateTo([this.jobs[--index].id, this.getCurrentPath()]);
      }
    }
  }

  goToCandidatesPool() {
    let client = this.client.id;
    this.router.navigate(['main', client, 'all-candidates']);
  }

  goToSearch() {
    setTimeout(() => {
      this.searchJob.nativeElement.focus();
    }, 100);
  }

  getCurrentPath() {
    return this.link_active === 'all-candidates' ? 'candidates' : this.link_active;
  }

  subscribeRouter() {
    this.componentSubscriptions.routerEventsFilterPipe$ = this.router.events.pipe(
      filter(url => url instanceof NavigationEnd)
    )
      .pipe(
        map(
          (url: NavigationEnd) => this.router.parseUrl(url.url)
            .root.children[PRIMARY_OUTLET].segments
        ),
        takeWhile(url => url[0].path === 'main'))
      .subscribe(
        url => {

          if (Object.keys(ShortPhasesLabels)
            .indexOf(url[url.length - 1].path) !== -1) {
            this.link_active = url[3].path;
            this.job_active = url[2].path;
          } else {
            this.link_active = url.pop()
              .path;
            this.job_active = url.pop()
              .path;
            if (this.job_active === 'main') {
              this.navigateToJobs();
            }
          }

        }
      );
  }

  getJobDetail(job: Job) {
    const queryParams = { name: 'job', value: String(job.id) };
    this.router.navigate([], buildQueryParams(queryParams, this.activatedRoute));
  }

  setJobs(response, filter?: string) {
    this.totalJobs = response.headers.get('Count');
    this.moreJobs = response.headers.get('Next-Page') !== 'None' ? true : false;

    if (filter === 'pagination') {
      this.jobs = this.jobs.concat(response.body.map(job => Object.assign(new Job(), job)));
    } else {
      this.jobs = response.body.map(job => Object.assign(new Job(), job));
    }

    this.navigateToJobs();

    setTimeout(() => {
      if (!this.scrolledInit) {
        this.moveSideBarToUrlJob();
      }
    }, 2500);
  }

  navigateToNoJobsComponent(hasSelectedFilters: Boolean) {
    if (hasSelectedFilters) {
      this.router.navigate(['no-results'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['no-job'], { relativeTo: this.activatedRoute });
    }
  }

  setActiveAttributesFromPath() {
    const pathFromRoot = this.router.parseUrl(this.router.url)
      .root.children[PRIMARY_OUTLET].segments;
    this.link_active = pathFromRoot[3].path;
    this.job_active = pathFromRoot[2].path;
  }

  navigateToFirstOrCurrentJob() {
    this.job_active = String(this.jobs[0].id);
    if (this.activatedRoute.firstChild) {
      const job = this.activatedRoute.firstChild.snapshot.paramMap.get('job');
      const isInFiltered = this.jobs.some(job_object => job_object.id === +job);
      this.job_active = job && isInFiltered ? job : this.job_active;
    }
    this.navigateTo([this.job_active, 'candidates'], { relativeTo: this.activatedRoute });
  }

  navigateTo(route: any[], extras: NavigationExtras = { relativeTo: this.activatedRoute }) {
    if (this.link_active !== 'analytics') {
      route.push('INS');
    }
    if (!this.layoutService.isCandidateActionOpen()) {
      this.router.navigate(route, extras);
    } else {
      this.layoutService.handleLayoutServiceAction(LayoutActions.ATTEMPT_CANDIDATE_ACTION_CLOSE, { route, extras });
    }
  }

  navigateToJobs() {
    const hasSelectedFilters = Object.keys(this.filters)
      .length > 0;
    const thereAreJobs = this.jobs.length > 0;

    if (!thereAreJobs) {
      this.navigateToNoJobsComponent(hasSelectedFilters);
    } else {
      if (this.activatedRoute.firstChild) {
        if (!hasSelectedFilters) {
          this.setActiveAttributesFromPath();
        }
      } else {
        this.navigateToFirstOrCurrentJob();
      }
    }
    this.loading = false;
  }

  subscribeCandidateParam() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        Boolean(queryParam.get('candidate')) ?
          this.layoutService.handleLayoutServiceAction(LayoutActions.COLLAPSE_JOBS_SIDEBAR) :
          this.layoutService.handleLayoutServiceAction(LayoutActions.EXPAND_JOBS_SIDEBAR);
      }
    );
  }

  executeJobAction(action) {
    switch (action.type) {
      case Job.CREATE_ACTION:
        this.createJob();
        break;
      case Job.UPDATE_ACTION:
        this.editJob(action.job);
        break;
      case Job.CLONE_ACTION:
        this.cloneJob(action.job);
        break;
      case Job.CLOSE_ACTION:
        this.closeJob(action.job);
        break;
      case Job.REMOVE_ACTION:
        this.removeJob(action.job);
        break;
    }
  }

  createJob() {
    this.wizard.emit({ show: true, type: 'job' });
  }

  editJob(job: Job) {
    this.wizard.emit({ show: true, type: Job.UPDATE_ACTION, job });
  }

  cloneJob(job: Job) {
    this.wizard.emit({ show: true, type: Job.CLONE_ACTION, job });
  }

  closeJob(closedJob: Job) {
    this.jobs.find(job => job.id === closedJob.id)
      .closed = closedJob.closed;
  }

  removeJob(job: Job) {
    this.inActionJob = null;
    const jobIndex = this.jobs.findIndex(element => element.id === job.id);
    if (jobIndex >= 0) {
      this.jobs.splice(jobIndex, 1);
      if (job.id === +this.job_active) {
        this.navigateToFirstOrCurrentJob();
      }
    }
  }

  closeFilters() {
    this.filtersTrigger.closeMenu();
  }

  setSortFilter(filter) {
    this.filters.ordering = filter;
    this.filterJobs();
  }

  setSortField(selectedField) {
    this.sortingFields.map(field => field.selected = field.value === selectedField);
    this.filters.ordering = this.getOrderingFilter();
  }

  setSortOrder(selectedOrder) {
    this.sortingOrder.map(field => field.selected = field.value === selectedOrder);
    this.filters.ordering = this.getOrderingFilter();
  }

  getOrderingFilter(): string {
    const sortOrder = this.findSelectedElement(this.sortingOrder);
    const sortField = this.findSelectedElement(this.sortingFields);
    return `${sortOrder.value}${sortField.value}`;
  }

  getSelectedSortLabel(): string {
    const sortOrder = this.findSelectedElement(this.sortingOrder);
    const sortField = this.findSelectedElement(this.sortingFields);
    return `${this.translateService.instant(sortOrder.title)} ${this.translateService.instant(sortField.title)}`;
  }

  findSelectedElement(elements) {
    return elements.find(e => e.selected);
  }

  filterJobs(filter?: string) {
    this.loading = true;

    if (filter !== 'pagination') {
      this.resetJobsPage();
    }

    this.appliedFilters = Object.entries(this.filters)
      .filter(([, value]) => value)
      .map(filter => this._getIdAndValueFromFilter(filter));

    Object.keys(this.filters)
      .forEach(filter => {
        const filterObj = this._setParameters(filter);
        if (filterObj !== null) {
          this.parameters = setFilter(
            this.parameters, this._setParameters(filter)
          );
        }
      });

    if (!this.filters.status) {
      this.parameters = setFilter(this.parameters, new Filter('status', 'active'));
    }

    this.componentSubscriptions.jobServiceGetJobListFilterJobs$ = this.jobService.getJobList(...this.parameters)
      .subscribe(
        response => {
          this.setJobs(response, filter);
          this.setJobsListPosition();
        }
      );

  }

  setJobsListPosition() {
    if (this.jobsList) {
      this.renderer.setStyle(this.jobsList.nativeElement, 'padding-top', `${this.filtersRow.nativeElement.clientHeight}px`);
    }
  }

  _setParameters(filter: string): Filter {

    if (filter === 'template' && this.filters[filter] !== null) {
      return new Filter('profession', this.filters[filter].id);
    }

    if (filter === 'organization' && this.filters[filter] !== null) {
      return new Filter('organization', this.filters[filter].id);
    } else if (filter === 'function' && this.filters[filter] !== null) {
      return new Filter('function', this.filters[filter].id);
    } else if (filter === 'location' && this.filters[filter] !== null) {
      return new Filter('location', this.filters[filter].id);
    } else if (filter === 'profession' && this.filters[filter] !== null) {
      return new Filter('job_profession', this.filters[filter].id);
    }

    if (filter === 'status') {
      return this.filters[filter] === null ? new Filter(filter, 'active') : new Filter(filter, this.filters[filter]);
    }

    if (filter === 'privacy') {
      return new Filter('privacy', this.filters[filter])
    }

    if (filter === 'organization' && this.filters[filter] == null) {
      return new Filter(filter, null);
    } else if (filter === 'function' && this.filters[filter] == null) {
      return new Filter(filter, null);
    } else if (filter === 'location' && this.filters[filter] == null) {
      return new Filter(filter, null);
    } else if (filter === 'profession' && this.filters[filter] == null) {
      return new Filter('job_profession', null);
    }

    if (filter === 'ordering') {
      return new Filter('ordering', this.filters[filter])
    }

    return null;
  }

  resetFilters(isSelected = false) {
    const selectedKeys = isSelected ?
      ['template', 'organization', 'function', 'location', 'profession'] :
      ['ordering', 'template', 'privacy', 'status', 'organization', 'function', 'location', 'profession'];
    this._resetFilter(selectedKeys);
    this.initializeJobParams();
    this.filterJobs();
  }

  _resetFilter(keys = Object.keys(this.filters)) {
    keys.forEach(key => this.filters[key] = null);
  }

  removeTag(tag) {

    switch (tag.id) {
      case '-expirationDate':
      case 'expirationDate':
      case '-createdDate':
      case 'createdDate':
      case '-closedDate':
      case 'closedDate':
        this.filters.ordering = '';
        break;
      case 'number':
        this.filters.template = '';
        break;
      case 'active':
      case 'closed':
      case 'expired':
        this.filters.status = '';
        break;
      case 'DRA':
      case 'PRI':
      case 'PUB':
      case 'BTH':
        this.filters.privacy = '';
        break;
      default:
        {

          if (this.filters['organization'] && this.filters['organization'].id == tag.id) {
            this.filters['organization'] = '';
          }
          if (this.filters['location'] && this.filters['location'].id == tag.id) {
            this.filters['location'] = '';
          }
          if (this.filters['profession'] && this.filters['profession'].id == tag.id) {
            this.filters['profession'] = '';
          }
          if (this.filters['function'] && this.filters['function'].id == tag.id) {
            this.filters['function'] = '';
          }
          if (this.filters['template'] && this.filters['template'].id == tag.id) {
            this.filters['template'] = '';
          }
          break;
        }
    }

    this.filterJobs();
  }


  initializeJobParams() {
    this.parameters = [];
    this.parameters = setFilter(this.parameters, new Filter('group', String(this.client.id)));
    this.parameters = setFilter(this.parameters, new Filter('page', '1'));
    this.parameters = setFilter(this.parameters, new Filter('status', 'active'));
    this.appliedFilters = [];
  }

  _getIdAndValueFromFilter([filter_key, filter_value]): { id: string, value: string } {

    switch (filter_key) {
      case 'template':
        return { id: filter_value.id, value: filter_value.name };
      case 'organization':
      case 'function':
      case 'profession':
      case 'location':
        return { id: filter_value.id, value: filter_value.value };
      case 'privacy':
        return {
          id: filter_value, value: Job.PRIVACIES.find(privacy => privacy.value === filter_value)
            .title
        };
      case 'status':
        return {
          id: filter_value, value: Job.STATUSES.find(privacy => privacy.value === filter_value)
            .title
        };
      case 'ordering':
        return { id: filter_value, value: this.getSelectedSortLabel() };
      default:
        return { id: filter_key, value: filter_value };
    }
  }

  updateJobsList($event: { index: number, job: Job }) {
    this.jobs[$event.index] = $event.job;
  }

  showMoreJobs() {
    const pageParam: Filter = this.parameters.find(param => param.name === 'page');
    pageParam.value = (Number(pageParam.value) + 1).toString();
    this.filterJobs('pagination');
  }

  resetJobsPage() {
    const pageParam: Filter = this.parameters.find(param => param.name === 'page');
    pageParam.value = '1';
  }

  scrollHandler(e) {

    if (e.target.scrollTop < this.scrollHeight && e.target.scrollTop === 0) {
      this.child.normalizeHeader();
    } else if (e.target.scrollTop > 0) {
      this.child.collapseHeader();
    }
    this.scrollHeight = e.target.scrollTop;
  }

  setHeightScroll() {
    this.saveScrollHeight = this.scrollHeight;
  }

  scrollToCollapse() {
    this.componentSubscriptions.layoutServiceJobDashboardSubjectsJobsSidebarCollapsed$ = this.layoutService.jobDashboardSubjects.jobsSidebarCollapsed.subscribe(
      status => {
        if (!status) {
          setTimeout(() => this.sidebarContainer.nativeElement.scrollTop = this.saveScrollHeight, 100);
        }
      }
    )
  }

}
