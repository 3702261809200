import { InstrumentCreationData } from '../../../instruments/models/instrument';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Instrument } from '../../../instruments/models/instrument';
import { Interview } from '../../../classes/interview';
import { InterviewsService } from '../interviews.service';
import { INSTRUMENT_TYPES } from '../../../instruments/constants';

@Component({
  selector: 'app-interviews-list',
  templateUrl: './interviews-list.component.html',
  styleUrls: ['./interviews-list.component.scss']
})
export class InterviewsListComponent implements OnInit {

  @Output() selectedInstrumentsChange: EventEmitter < InstrumentCreationData[] > = new EventEmitter();
  @Output() removeInterviewEmitter: EventEmitter < Interview > = new EventEmitter();

  _OAinstruments: Instrument[];

  @Input() interviews: Interview[];
  @Input() currentPhase: string;
  @Input() update: boolean;

  @Input() set OAinstruments(instruments: Instrument[]) {
    this._OAinstruments = instruments;
    this.emitSelectedInstruments();
  }

  instrumentTypes = INSTRUMENT_TYPES;

  constructor(
    private interviewsService: InterviewsService
  ) {}

  ngOnInit() {
  }

  selectInstrument(instrument: Instrument) {
    instrument.selected = !instrument.selected;
    this.emitSelectedInstruments();
  }

  emitSelectedInstruments() {
    const selectedInstruments = this._OAinstruments
      .filter((instrument: any) => instrument.selected)
      .map(instrument => InstrumentCreationData.buildFromInstrumentsData(instrument));
    this.selectedInstrumentsChange.emit(selectedInstruments);
  }

  editInterview(interview: Interview) {
    this.interviewsService.activateBuilder();
    this.interviewsService.setInterviewToEdit(interview);
    this.interviewsService.setEditorMode(true);
  }

  removeInterview(interview: Interview) {
    const rmInterviewIndex = this.interviews.findIndex(x => x.name === interview.name);
    this.interviews.splice(rmInterviewIndex, 1);
  }

}
