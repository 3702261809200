import { LayoutService } from './../../services/layout.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user-service.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';

@Component({
  selector: 'app-sidebar-dashboard-client',
  templateUrl: './sidebar-dashboard-client.component.html',
  styleUrls: ['./sidebar-dashboard-client.component.scss'],
  animations: [
    trigger('sidebarState', [
      state('collapsed-sidebar', style({
        width: '40px',
        transition: 'width'
      })),
      state('open-sidebar', style({
        transition: 'width'
      })),
      transition('collapsed-sidebar => open-sidebar', animate('300ms ease-in')),
      transition('open-sidebar => collapsed-sidebar', animate('300ms ease-in'))
    ])
  ]
})

export class SidebarDashboardClientComponent implements OnInit {

  client: Client = new Client();
  collapsed = 'open-sidebar';
  buttonCollapse = false;

  constructor(
    public userService: UserService,
    private activateRoute: ActivatedRoute,
    private clientService: ClientService,
    public layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.client.id = +this.activateRoute.snapshot.paramMap.get('client');
    this.getClient();
  }

  getClient() {

    this.clientService.loadClient(this.client.id)
      .subscribe(
        client => { this.client = client; },
        error => console.log(error)
      );
  }

  onToggleSidebar(collapsedSidebar: Boolean) {
    this.collapsed = collapsedSidebar ? 'collapsed-sidebar' : 'open-sidebar';
  }

}
