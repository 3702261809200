import { Component, OnInit, Input, Renderer2, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-option-extended-search',
  templateUrl: './option-extended-search.component.html',
  styleUrls: ['./option-extended-search.component.scss']
})
export class OptionExtendedSearchComponent implements OnInit {

  extendedSearch = false;
  @Output() extendedSearchChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit() {
  }

  showPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'block');
  }

  dismissPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'none');
  }

  emitExtendedSearchChange() {
    this.extendedSearchChange.emit(this.extendedSearch);
  }

}
