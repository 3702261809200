import { CandidatesSummary } from './client-analytics';
import { Phase, INSCRIPTION } from './phases';

export class FunnelData {
  label: string;
  data: number;
  readonly bar_width: string;
  readonly conversion: string;
  readonly code: string;

  constructor (label: string,phaseCandidates: number, totalCandidates: number , previousPhaseCandidates: number, phaseCode:string ) {
      this.label = label;
      this.data = phaseCandidates;
      this.bar_width = FunnelData.calculateBarPerc(phaseCandidates, totalCandidates) + '%';
      this.conversion = FunnelData.calculateConversionRate(previousPhaseCandidates, phaseCandidates);
      this.code = phaseCode;
  }

  static getFunnelFromPhasesData (phases: Phase[], data:  { [key: string]: number }, totalCandidates: number): FunnelData[] {
    return phases.reduce((resultData, phase, i) =>
        resultData.concat( new FunnelData(
          phase.name,
          data[phase.code],
          totalCandidates,
          i === 0 ? 0 : resultData[i - 1].data,
          i === 0 ? INSCRIPTION.code : phase.code
        ))
        , []);
  }

  static calculateBarPerc(phaseCandidates: number, totalCandidates: number): number {
    const valuePercentage = phaseCandidates ? ((phaseCandidates / totalCandidates ) * 100) : 0;
    return valuePercentage;
  }

  static calculateConversionRate(previousPhaseCandidates: number, phaseCandidates: number): string {
    const conversionPercentage = ((phaseCandidates / previousPhaseCandidates) * 100 );
    const limitedPercentage = conversionPercentage > 100 ? '100%' : `${Math.round(conversionPercentage)}%`;
    return previousPhaseCandidates !== 0 ? limitedPercentage : '-';
  }

  static calculateFunnelSummatoryQuantities(phases: Phase[], candidateSummary: { [key: string]: number })  {
    const reversedPhases = phases.slice().reverse();
    const correctSummary = reversedPhases.reduce((summary, currentPhase, i) => {
      summary[currentPhase.code] = i === 0 ? candidateSummary[currentPhase.code] : candidateSummary[currentPhase.code] + summary[reversedPhases[i - 1].code];
      return summary;
    }, {});
    return correctSummary;
  }

}
