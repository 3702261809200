import { Filter } from './../library/classes/filter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../classes/constants';
import { buildFilter } from '../library/utils';
import { JobsStatus, CandidatesStatus, JobSummary, ProfessionSummary, CandidatesSummary, HireSummary, GlobalAnalytics, JobSituation, CandidatesBounceRate, VisitsByDevice, UserVisitsFromCampaigns } from '../classes/client-analytics';

@Injectable()
export class ClientAnalyticsService {

  constructor(private httpClient: HttpClient) { }

  getJobsStatus(client_id, ...parameters: Filter[]): Observable<JobsStatus> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_JOBS_STATUS.replace('{id}', client_id) + query;
    return this.httpClient.get<JobsStatus>(url);
  }

  getCandidatesStatus(client_id, ...parameters: Filter[]): Observable<CandidatesStatus> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_STATUS.replace('{id}', client_id) + query;
    return this.httpClient.get<CandidatesStatus>(url);
  }

  getJobsSummary(client_id, ...parameters: Filter[]): Observable<JobSummary[]> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_JOBS_SUMMARY.replace('{id}', client_id) + query;
    return this.httpClient.get<JobSummary[]>(url);
  }

  getGroupJobsSummary(...parameters: Filter[]): Observable<JobSummary[]> {
    const query = buildFilter(...parameters);
    const url = Constants.GROUP_ANALYTICS_JOBS_SUMMARY + query;
    return this.httpClient.get<JobSummary[]>(url);
  }

  getProfessionsSummary(client_id, ...parameters: Filter[]): Observable<ProfessionSummary[]> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_PROFESSIONS_SUMMARY.replace('{id}', client_id) + query;
    return this.httpClient.get<ProfessionSummary[]>(url);
  }

  getCandidatesSummary(client_id, ...parameters: Filter[]): Observable<CandidatesSummary> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_SUMMARY.replace('{id}', client_id) + query;
    return this.httpClient.get<CandidatesSummary>(url);
  }

  getHireSummary(client_id, ...parameters: Filter[]): Observable<HireSummary> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_HIRE_SUMMARY.replace('{id}', client_id) + query;
    return this.httpClient.get<HireSummary>(url);
  }

  getMainGlobalAnalytics(client_id, ...parameters: Filter[]): Observable<GlobalAnalytics> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_MAIN_ANALYTICS.replace('{id}', client_id) + query;
    return this.httpClient.get<GlobalAnalytics>(url);
  }

  getGroupTopJobsByCandidates(...parameters: Filter[]): Observable<{ client_name: string, total: number, job_name: string }[]> {
    const query = buildFilter(...parameters);
    const url = Constants.GROUP_ANALYTICS_TOP_JOBS_BY_CANDIDATES + query;
    return this.httpClient.get<{ client_name: string, total: number, job_name: string }[]>(url);
  }

  getUserVisitsByDate(client_id, ...parameters: Filter[]): any {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_USER_BY_DATE.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getUserVisitsByCountry(client_id, ...parameters: Filter[]): any {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_USER_BY_COUNTRY.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getGroupJobsAnalytics(...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.GROUP_ANALYTICS_JOBS_SITUATION_BY_STATUS + query;
    return this.httpClient.get<any>(url);
  }

  getGroupIndustriesAnalytics(...parameters: Filter[]): Observable<{ total: number, industry: string }[]> {
    const query = buildFilter(...parameters);
    const url = Constants.GROUP_ANALYTICS_CLIENTS_BY_INDUSTRY + query;
    return this.httpClient.get<any>(url);
  }

  getJobsSituationByCategories(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_JOBS_SITUATION_BY_CATEGORIES.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getApplicantsPerType(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_PER_TYPE.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getApplicantsPerAgeGender(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_PER_AGE_GENDER.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getApplicantsPerCampaigns(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_PER_CAMPAIGNS.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getApplicantsPerInstruments(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_PER_INSTRUMENTS.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getAllInstruments(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_ALL_INSTRUMENTS.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getApplicantsPerInterviews(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_PER_INTERVIEWS.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }
  getTimesPerInterviews(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_TIMES_PER_INTERVIEWS.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getGroupAnalyticsConversionRate(...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.GROUP_ANALYTICS_CANDIDATES_CONVERSION_RATE + query;
    return this.httpClient.get<any>(url);
  }

  getGroupAnalyticsInstrumentsByProvider(...parameters: Filter[]): Observable<{
    [key: string]: {
      count: number,
      instrument_name: string,
      instrument_id: number
    }[]
  }> {
    const query = buildFilter(...parameters);
    const url = Constants.GROUP_ANALYTICS_INSTRUMENTS_BY_PROVIDER + query;
    return this.httpClient.get<any>(url);
  }

  getTimeToPhases(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_TIME_TO_PHASES.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

  getCandidatesBounceRates(client_id, ...parameters: Filter[]): Observable<CandidatesBounceRate> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_CANDIDATES_BOUNCE_RATE.replace('{id}', client_id) + query;
    return this.httpClient.get<CandidatesBounceRate>(url);
  }

  getGroupTopJobsByVisits(...parameters: Filter[]): Observable<{ client_name: string, total: number, job_name: string }[]> {
    const query = buildFilter(...parameters);
    const url = Constants.GROUP_ANALYTICS_TOP_JOBS_BY_VISITS + query;
    return this.httpClient.get<{ client_name: string, total: number, job_name: string }[]>(url);
  }

  getVisitsByDevice(client_id, ...parameters: Filter[]): Observable<VisitsByDevice> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_VISITS_BY_DEVICE.replace('{id}', client_id) + query;
    return this.httpClient.get<VisitsByDevice>(url);
  }

  getUserVisitsFromCampaigns(client_id, ...parameters: Filter[]): Observable<any> {
    const query = buildFilter(...parameters);
    const url = Constants.CLIENT_ANALYTICS_USER_VISITS_FROM_CAMPAIGNS.replace('{id}', client_id) + query;
    return this.httpClient.get<any>(url);
  }

}
