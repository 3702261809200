import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { SupportTicket, HelpRequestTypes } from '../../classes/supportTicket';
import { UserService } from '../../services/user-service.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help-email',
  templateUrl: './help-email.component.html',
  styleUrls: ['./help-email.component.scss']
})
export class HelpEmailComponent implements OnInit {
  config = {
    extraPlugins: 'divarea,colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: ['Format', 'Font']},
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat'] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ]},
      { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
      { name: 'links', items: [ 'Link', 'Unlink']}
    ]
  };
  
  @Input() title: string = '__helpModalTitle';
  @Input() describeRequest: string = '__describeRequest';
  @Input() describeRequestExtended: string = '__describeRequestExtended';
  @Input() enabledTypeOfRequest: boolean = true;
  @Input() client: number;
  @Input() identifier: string = 'Empty';

  @Output() closed = new EventEmitter < any > ();
  
  helpRequestSent: boolean;
  helpRequestSentError: boolean;
  helpRequestTypes = HelpRequestTypes;
  supportTicket: SupportTicket;

  modalRef: BsModalRef;

  @ViewChild('helpModal', { read: TemplateRef, static: true })
  helpModal: TemplateRef < any > ;

  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.title = this.translateService.instant(this.title);
    this.describeRequest = this.translateService.instant(this.describeRequest);
    this.describeRequestExtended = this.translateService.instant(this.describeRequestExtended);

    this.helpRequestSent = this.helpRequestSentError = false;
    this.supportTicket = new SupportTicket();
    this.supportTicket.identifier = this.identifier;
    this.modalRef = this.modalService.show(this.helpModal, { backdrop: 'static' });
    if (this.enabledTypeOfRequest == false){
      this.supportTicket.request_type = 'bug';
    }
  }

  validate() {

    if (typeof this.supportTicket.request_type != 'undefined' && this.supportTicket.request_type != '' && typeof this.supportTicket.subject != 'undefined' && this.supportTicket.subject != '' && typeof this.supportTicket.body != 'undefined' && this.supportTicket.body != '') {
      return true;
    } else {
      return false;
    }
  }
  setHelpRequestType(helpRequestType) {

    this.supportTicket.request_type = helpRequestType;
  }
  sendHelpRequest() {

    if (this.validate()) {
      this.supportTicket.client = this.client;
      this.userService.sendSupportTicket(this.supportTicket)
        .then(
          response => {
            this.helpRequestSent = true;
          }
        )
        .catch(

          (error) => {
            this.helpRequestSentError = true;
            console.log(error);
          }
        )
    } else {
      this.helpRequestSentError = true;
      
    }
  }

  closeModal(){
    this.closed.emit(false);
    this.modalRef.hide()
  }

}
