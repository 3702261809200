import { Filter } from './../../library/classes/filter';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router/';
import { ClientAnalyticsService } from './../../services/client-analytics.service';

import { ClientAnalytics } from './../../classes/client-analytics';
import { Observable, combineLatest } from 'rxjs';
import { JobCategory } from '../../classes/job';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { setFilter } from '../../library/utils';
import { PHASES, Phase } from '../../classes/phases';
import { FunnelData } from '../../classes/funnel-data';
import { Profession } from '../../classes/profession';
import { ProfessionService } from '../../services/profession.service';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { switchMap, take } from 'rxjs/operators';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dashboard-client',
  templateUrl: './dashboard-client.component.html',
  styleUrls: ['./dashboard-client.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})

export class DashboardClientComponent implements OnInit, OnDestroy {

  professions: Profession[] = [];
  client: Client = new Client();
  client_id: number;
  organizations: Array<any> = [];
  locations: Array<any> = [];
  functions: Array<any> = [];

  filters = {
    professions: [],
    organization: [],
    function: [],
    location: [],
    status: null,
    time: null,
    created_start: null,
    created_end: null,
  };
  states: Array<{ name, value }> = [
    { name: 'Open', value: 'open' },
    { name: 'Closed', value: 'close' }
  ];
  dateFilters: { name: string, id: number, time: string, quantity: any }[] = [
    { name: 'Today', id: 0, time: 'days', quantity: 0 },
    { name: 'Last Week', id: 1, time: 'weeks', quantity: 1 },
    { name: 'Last Month', id: 2, time: 'months', quantity: 1 },
    { name: 'Last Three Month', id: 3, time: 'months', quantity: 3 },
    { name: 'Last Six Month', id: 4, time: 'months', quantity: 6 },
    { name: 'Last Year', id: 5, time: 'years', quantity: 1 },
  ];

  parameters: Filter[] = [];
  data: ClientAnalytics = new ClientAnalytics();

  colors = ['orange', 'green', 'blue', 'pink', 'turquoise', 'purple'];

  maxCandidatesInJob: number;
  maxJobsInProfession: number;
  maxCandidatesInProfession: number;
  seeAllJobsByProfession: Boolean = false;
  seeAllJobsByCandidates: Boolean = false;

  phases: Phase[] = PHASES;

  componentSubscriptions: any = {};

  constructor(
    private clientAnalyticsService: ClientAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private professionService: ProfessionService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.getAllProfessions();
    this.getClientAnalytics();
    this.getOrganizationClient();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getAllProfessions() {
    this.client_id = +this.activatedRoute.snapshot.paramMap.get('client');
    this.componentSubscriptions.professionServiceList$ = this.professionService.list(new Filter('group', this.client_id))
      .subscribe(response => this.professions = response);
  }

  getClientAnalytics() {
    this.componentSubscriptions.activatedRouteParamMapPipe$ = this.activatedRoute.paramMap.pipe(switchMap((param) => {
      this.client_id = +param.get('client');
      return this.getData();
    }))
      .subscribe(data => {
        this.data = data;
      });
  }

  getData(): Observable<any> {
    const clientAnalytics = combineLatest(
      ...this.getAnalyticsObservables(),
      (jobStatus, candidateStatus: any, jobSummary, professionSummary, candidateSummary, hireSummary) =>
        ClientAnalytics.buildClientAnalytics(
          jobStatus,
          candidateStatus,
          jobSummary,
          professionSummary,
          hireSummary,
          this.parseMaxJobsInProfession(professionSummary),
          this.parseMaxCandidatesInProfession(professionSummary),
          this.parsemaxCandidatesInJob(jobSummary),
          FunnelData.getFunnelFromPhasesData(
            PHASES,
            candidateSummary,
            candidateSummary.INS)
        )
    )
      .pipe(take(1));

    return clientAnalytics;
  }

  getAnalyticsObservables() {
    const observablesArray = [
      this.clientAnalyticsService.getJobsStatus(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getCandidatesStatus(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getJobsSummary(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getProfessionsSummary(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getCandidatesSummary(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getHireSummary(this.client_id, ...this.parameters),
    ];
    return observablesArray;
  }

  parseMaxJobsInProfession(data) {
    return Math.max(...data.map(profession => profession.jobs));
  }

  parseMaxCandidatesInProfession(data) {
    return Math.max(...data.map(profession => profession.candidates_total));
  }

  parsemaxCandidatesInJob(data) {
    return Math.max(...data.map(job => job.total));
  }

  getColors(candidate: any) {
    const colLength = this.colors.length;
    if (colLength > 0 && candidate.email) {
      const index = candidate.email[0].charCodeAt(0) % this.colors.length;
      return this.colors[index];
    }
  }

  getCandidateInitials(candidate) {
    if (candidate.first_name && candidate.last_name) {
      return `${candidate.first_name[0]}${candidate.last_name[0]}`;
    }
    return `${candidate.email[0]}${candidate.email[1]}`;
  }

  getOrganizationClient() {

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(this.client_id)
      .subscribe(
        response => {
          this.client = response
          this.getClientCategories();
        },
        error => console.log(error),
      )
  }

  getClientCategories() {
    this.componentSubscriptions.clientServiceGetJobCategories$ = this.clientService.getJobCategories(this.client_id)
      .subscribe(
        jobCategories => {
          ({ organizations: this.organizations, locations: this.locations, functions: this.functions } =
            this._initJobCategoriesFilter(jobCategories)
          );
        },
        error => console.log(error)
      );
  }

  setClientFilter(filter, value) {
    this.filters[filter] = value;

    const timeFilters = ['created_start', 'created_end'];
    if (timeFilters.includes(filter)) {
      value = this._formatDateFilter(value);
      this.filters.time = null;
    }
    if (filter === 'time') {
      filter = 'created_start';
      value = this._formatDateFilterToTimeAgo(value);
      this.parameters = this.parameters.filter(param => param.name !== 'created_end' && param.name !== 'created_start');
      this.filters.created_start = null;
      this.filters.created_end = null;
    }
    this.parameters = setFilter(this.parameters, new Filter(filter, value));
    this.getClientAnalytics();
  }

  _initJobCategoriesFilter(jobCategories: JobCategory[]) {
    const organizations = jobCategories.filter(jobCategory => jobCategory.category === 'ORG');
    const locations = jobCategories.filter(jobCategory => jobCategory.category === 'LOC');
    const functions = jobCategories.filter(jobCategory => jobCategory.category === 'FUN');
    return { organizations, locations, functions };
  }

  selectAll(filter: string, values: any[], id_key: string) {
    this.filters[filter] = values.map(e => e[id_key]);
  }

  deselectAll(categoryName) {
    this.filters[categoryName] = [];
  }

  toggle(values, filter: string, key: string) {
    if (values.length === this.filters[filter].length - 1) {
      this.deselectAll(filter);
    } else {
      this.selectAll(filter, values, key);
    }
    this.setClientFilter(filter, this.filters[filter]);
  }

  _formatDateFilterToTimeAgo(value) {
    const today = moment()
      .startOf('day');
    const start = today.subtract(this.dateFilters[value].quantity, this.dateFilters[value].time);
    return start.toISOString();
  }

  _formatDateFilter(value) {
    return moment(value)
      .toISOString();
  }

}