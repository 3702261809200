import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { customHighchartsRadius } from '../../utils';
import { TranslateService } from '@ngx-translate/core';
import { COLOR_VARIABLES } from './../../utils/color-variables';
declare const Highcharts: any;

@Component({
  selector: 'app-horizontal-bars',
  templateUrl: './horizontal-bars.component.html',
  styleUrls: ['./horizontal-bars.component.scss']
})
export class HorizontalBarsComponent implements OnInit, OnChanges {


  @Input() data: any[];
  @Input() labels: string[];
  @Input() name: string;
  @Input() configuration: { legendEnabled: boolean, colors: string[] };
  @Input() feedBackTool: boolean = false;

  @Input() customConfig: any;


  config: any = {
    chart: {
      type: 'bar',
      events: {
        load: function () {
          this.reflow();
        }
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: null,
      crosshair: true,
      lineWidth: 0,
      labels: {
        style: {
          color: COLOR_VARIABLES['$_CHART_GREYISH_LIGHT_PURPLE'],
        }
      }
    },
    yAxis: {
      min: 0,
      gridLineWidth: 0,
      max: null,
      reversedStacks: false,
      labels: {
        enabled: true
      },
      title: {
        text: null
      }
    },
    legend: {
      enabled: true,
      reversed: false
    },
    tooltip: {
      formatter: function () {
        let tooltipText = '<span><b>' + this.points[0].key + '</b></span><table>';
        this.points.forEach(element => {
          tooltipText += "<tr><td style='padding:0; color: " + element.series.color + "'>" + element.series.name + ': ' + element.y + '</td></tr>';
        });
        tooltipText += "<tr><td style='padding:0'>Total: " + this.points[0].total + '</td></tr></table';
        return tooltipText;
      },
      shared: true,
      useHTML: true
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        stacking: 'normal',
        pointWidth: 20,
      }
    },
    series: null,
    credits: {
      enabled: false
    },
    colors: [
    ],
  };

  constructor(
    private hostElement: ElementRef,
    private translateService: TranslateService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.charts'), this.config);
  }

  _setData() {

    if (this.configuration) {
      this.config.legend.enabled = this.configuration.legendEnabled;
      this.config.colors = this.configuration.colors;
    }

    this.config.series = this.data;
    this.config.title.text = this.name;
    this.config.xAxis.categories = this.labels;



    if (this.feedBackTool) {

      this.config.plotOptions.series.pointWidth = 18;

      this.config.xAxis.labels = {
        align: 'left',
        reserveSpace: true,
        useHTML: true,
        formatter: function () {
          return `<span class="feedback-analytics-labels">${this.value}</span>`;
        }
      },

        this.config.yAxis.stackLabels = {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
          }
        }

      this.config.tooltip.formatter = function () {
        let tooltipText = '<table>';
        this.points.forEach(element => {
          if (element.y > 0) {
            tooltipText += "<tr><td style='padding:0; color: " + element.series.color + "'>" + element.series.name + ': ' + element.y + '</td></tr>';
          }
        });
        tooltipText += "<tr><td style='padding:0'>Total: " + this.points[0].total + '</td></tr></table';
        return tooltipText;
      }

    }

    if (this.customConfig) {
      Object.keys(this.customConfig).forEach(configKey => {
        this.config[configKey] = Object.assign(this.config[configKey], this.customConfig[configKey]);
      });
    }
  }
}
