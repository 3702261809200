import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

  @Output() dateChange: EventEmitter<Date> = new EventEmitter;

  @Input() minDate: Date = null;

  pickedDate: {
    date: Date,
    hour: string,
    minutes: string
  } = {
    date: null,
    hour: null,
    minutes: null
  };

  hours: string[] = [ "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
  minutes: string[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];


  constructor() { }

  ngOnInit() {
  }

  getScheduledDateTime(): Date {
    const scheduledTime = this.pickedDate.date.setHours(+this.pickedDate.hour, +this.pickedDate.minutes);
    return new Date(scheduledTime);
  }

  dateCompleted(): boolean {
    return Object.values(this.pickedDate).every(val => Boolean(val));
  }

  checkDate() {
    if (this.dateCompleted()) {
      this.dateChange.emit(this.getScheduledDateTime());
    }
  }


}
