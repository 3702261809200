export class InstrumentsConfig {
  cute: {
    project_id: string,
    client_id: string,
    secure_code: string,
  } = {
    project_id: '',
    client_id: '',
    secure_code: '',
  };
  taleo: {
    key: string,
  } = {
    key: '',
  };
  successFactors: {
    key: string,
  } = {
    key: '',
  };
}
