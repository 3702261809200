import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
declare const Highcharts: any;

@Component({
  selector: 'app-basic-line-chart',
  templateUrl: './basic-line-chart.component.html',
  styleUrls: ['./basic-line-chart.component.scss']
})
export class BasicLineChartComponent implements OnInit, OnChanges {

  @Input() lineData: number[];
  @Input() categories: string[];

  @Input() customConfig: any = {};

  config = {
    chart: {
      type: 'line',
      events: {
        load: function () {
          this.reflow();
        }
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: ''
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: [],
      visible: true,
    },
    yAxis: {
      visible: false
    },
    series: [],
    plotOptions: {      
      column: {
        pointWidth: 20
      },
      line: {
        dataLabels: {
          enabled: true
        }
      }
    }
  };

  constructor(private hostElement: ElementRef) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.customConfig) {
      Object.keys(this.customConfig).forEach(configKey => {
        this.config[configKey] = Object.assign(this.config[configKey], this.customConfig[configKey]);
      });
    }

    if (this.lineData) {
      this._setData();
      Highcharts.chart(this.hostElement.nativeElement.querySelector('.line-chart__content'), this.config);
    }
  }

  _setData() {

    const data = [{
      data: this.lineData
    }];

    this.config.series = data;
    this.config.xAxis.categories = this.categories;
  }
}
