import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Job } from '../../classes/job';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-multiposting',
  templateUrl: './multiposting.component.html',
  styleUrls: ['./multiposting.component.scss'],
})
export class MultipostingComponent {

  _job: Job = new Job();
  multipostingJobConfigStatus = 'INVALID';

  @Output() matTabChange: EventEmitter<number> = new EventEmitter();
  @Output() navigatingToConfig: EventEmitter<boolean> = new EventEmitter();

  @Input() step: number;
  @Input() parent: string;

  @Input() set job(job: Job) {
    this._job = job;
    this.jobName = job.name;
  }

  get job(): Job {
    return this._job;
  }

  @Input() clientId: number;

  @Input() jobName: string;
  @Input() hidePostTab: boolean;

  @ViewChild('tab', { static: true }) tab: MatTabGroup;

  constructor() { }

  ngOnInit() {
  }

  onTabClick(event: MatTabChangeEvent) {
    this.matTabChange.emit(event.index);
    document.getElementsByClassName('mat-tab-label')[0].classList.remove('custom-tab-active');
  }

  setTabIndex() {
    if (this.tab.selectedIndex === 0) {
      document.getElementsByClassName('mat-tab-label')[0].classList.add('custom-tab-active');
    }
  }
}
