import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
declare const Highcharts: any;
import { COLOR_VARIABLES } from './../../utils/color-variables';

@Component({
  selector: 'app-age-gender-chart',
  templateUrl: './age-gender-chart.component.html',
  styleUrls: ['./age-gender-chart.component.scss']
})
export class AgeGenderChartComponent implements OnInit {

  @Input() male: number[];
  @Input() female: number[];
  @Input() labels: string[];
  @Input() name: string;
  @Input() subtitle: string;

  config = {
    chart: {
      type: 'bar'
    },
    colors: [COLOR_VARIABLES['$_CHART_MEDIUM_BLUE'], COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT'], COLOR_VARIABLES['$_AUX_COLOR_TURQUOISE']],
    title: {
      text: 'Population pyramid for Germany, 2015'
    },
    credits: {
      enabled: false
    },
    subtitle: {
      text: 'Total number of inscriptions by age and gender'
    },
    xAxis: [],
    yAxis: {
      title: {
        text: null
      },
      labels: {
        formatter: function () {
          return Math.abs(this.value);
        }
      }
    },

    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.series.name + ', age ' + this.point.category + '</b><br/>' +
          'Population: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
      }
    },

    series: []
  };

  constructor(private hostElement: ElementRef) { }

  ngOnInit() {
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.age-chart__content'), this.config);
  }

  _setData() {
    this.config.xAxis = this._buildAxes();
    this.config.series.push({ name: 'Male', data: this.male.slice(3, 14).map(data => data * -1) });
    this.config.series.push({ name: 'Female', data: this.female.slice(3, 14) });
    this.config.title.text = this.name;
    this.config.subtitle.text = this.subtitle;
  }

  _buildAxes(): any[] {
    const male_axes = {
      categories: this.labels.slice(3, 14),
      reversed: false,
      labels: {
        step: 1
      }
    }

    const axes = [male_axes]
    return axes;
  }

}

