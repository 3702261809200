import { JobCategory } from './../../classes/job';
import { JobService } from './../../services/job.service';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

class JobCategoryToSelect {
  category: string;
  values: {label: string, value: number}[];

  constructor(category: string, values: {label: string, value: number}[]) {
    this.category = category;
    this.values = values;
  }
}

@Component({
  selector: 'app-job-categories',
  templateUrl: './job-categories.component.html',
  styleUrls: ['./job-categories.component.scss']
})
export class JobCategoriesComponent implements OnInit, OnDestroy {

  _clientId: number;

  @Input() set clientId (clientId: number) {
    if (clientId && clientId !== this._clientId) {
      this._clientId = clientId;
      this.getJobCategories(clientId.toString());
    }
  }

  @Input() set categoriesDataFromJob(categories: any[]) {
    this.jobCategoriesData = categories;
    if (this.availableCategories.length) {
      this.jobCategoriesForJob();
    }
  }

  @Output() selectedCategoriesIdsChange: EventEmitter<number[]> = new EventEmitter();
  @Output() categoriesDataFromJobChange: EventEmitter<JobCategory[]> = new EventEmitter();

  jobCategoriesData = [];

  jobCategoriesDB: JobCategory[] = [];
  availableCategories: JobCategoryToSelect[] = [];


  selectedCategories = {
    organization: null,
    function: null,
    location: null ,
    profession: null
  };

  componentSubscriptions: any = {};

  constructor(
    private jobService: JobService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getJobCategories(clientId: string) {
    this.componentSubscriptions.jobServiceGetJobCategories$ = this.jobService.getJobCategories(clientId).subscribe(
      categories => {
        this.jobCategoriesDB = categories;
        this.serializeAvailableCategories();

        if (this.jobCategoriesData.length) {
          this.jobCategoriesForJob();
        }
      },
      error => console.log(error)
    );
  }

  serializeAvailableCategories() {
    const parsedCategories = JobCategory.jobCategoryToTemplate(this.jobCategoriesDB);

    this.availableCategories = parsedCategories.map(category => {
      const sortedValues = this.sortJobCategoryValues(category.values);
      return new JobCategoryToSelect(
        category.category,
        sortedValues.map(value => ({label: value.value, value: value.id}))
      );
    });
  }

  sortJobCategoryValues(items: {value: string, id?: number}[]) {
    const compare = (a, b) => {
      if (b.value === 'ALL') {
        return 1;
      }
      if (a.value === 'ALL') {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
      return 0;
    };
    return items.sort(compare);
  }

  jobCategoriesForJob() {
    this.selectedCategories = this.availableCategories.reduce(
      (previousValue, cat) => {
        const jobCategory = (this.jobCategoriesData || []).find(element =>
          cat.category === JobCategory.getNameJobCategory(element.category)
        ) || { id: 0 };

        return Object.assign(previousValue, {
          [cat.category]: jobCategory.id
        });
      }, {} as any);
    this.setSelectedJobCategories();
  }

  setSelectedJobCategories() {
    const selectedCategories = Object.values(this.selectedCategories);
    const everyCategoryAreAssigned = selectedCategories.every(categorySelected => categorySelected) && selectedCategories.length > 0;

    if (everyCategoryAreAssigned) {
      this.emitCategoriesChange();
    }
  }

  getSelectedJobcategoriesFromDb(categories: number[]): JobCategory[] {
    return this.jobCategoriesDB
      .filter(category => categories.includes(category.id))
      .sort((a, b) => categories.indexOf(a.id) - categories.indexOf(b.id));
  }

  setJobCategory(category, jobCategory) {
    this.selectedCategories[category] = jobCategory;
    this.setSelectedJobCategories();
  }

  emitCategoriesChange() {
    this.selectedCategoriesIdsChange.emit(Object.values(this.selectedCategories));
    this.categoriesDataFromJobChange.emit(this.getSelectedJobcategoriesFromDb(Object.values(this.selectedCategories)));
  }

}
