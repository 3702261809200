import { Component, Input, OnInit } from '@angular/core';
import { EMPLOYEE_STATUS } from './../../classes/candidate';

@Component({
  selector: 'app-candidate-verified',
  templateUrl: './candidate-verified.component.html',
  styleUrls: ['./candidate-verified.component.scss']
})
export class CandidateVerifiedComponent implements OnInit {

  @Input() candidateStatus: EMPLOYEE_STATUS;
  @Input() clientFavicon: string;

  @Input() negative = false;

  employeeStatus = EMPLOYEE_STATUS;

  constructor() { }

  ngOnInit() {
  }

}
