import { Job } from './job';
import { Candidate } from './candidate';
import { FunnelData } from './funnel-data';

export class ClientAnalytics {

  jobsStatus: JobsStatus = new JobsStatus();
  candidatesStatus: CandidatesStatus = new CandidatesStatus();
  jobsSummary: JobSummary[] = [];
  professionsByJobsSummary: ProfessionSummary[] = [];
  professionsByCandidatesSummary: ProfessionSummary[] = [];
  hireSummary: HireSummary = new HireSummary();
  maxCandidatesInJob = 0;
  maxJobsInProfession = 0;
  maxCandidatesInProfession = 0;
  candidatesFunnel: FunnelData[];

  static buildClientAnalytics(jobStatus: JobsStatus,
    candidatesStatus: CandidatesStatus,
    jobsSummary: JobSummary[],
    professionsSummary: ProfessionSummary[],
    hireSummary: HireSummary,
    maxJobsInProfession: number,
    maxCandidatesInProfession: number,
    maxCandidatesInJob: number,
    candidatesFunnel: FunnelData[]
  ): ClientAnalytics {

    const clientAnalytics = new ClientAnalytics();
    clientAnalytics.jobsStatus = jobStatus;
    clientAnalytics.candidatesStatus = candidatesStatus;
    clientAnalytics.jobsSummary = jobsSummary;
    clientAnalytics.professionsByJobsSummary = professionsSummary;
    clientAnalytics.professionsByCandidatesSummary = professionsSummary;
    clientAnalytics.hireSummary = hireSummary;
    clientAnalytics.maxJobsInProfession = maxJobsInProfession;
    clientAnalytics.maxCandidatesInProfession = maxCandidatesInProfession;
    clientAnalytics.maxCandidatesInJob = maxCandidatesInJob;
    clientAnalytics.candidatesFunnel = candidatesFunnel;
    return clientAnalytics;
  }
}

export class GlobalAnalytics {
  jobs_count: number;
  candidates_count: number;
  candidates_assessed_count: number;
  suitable_candidates_count: number;
  hired_candidates_count: number;
  templates_count: number;
  active_candidates_count: number;
  expired_candidates_count: number;

  constructor() {
    this.jobs_count = 0;
    this.candidates_count = 0;
    this.candidates_assessed_count = 0;
    this.suitable_candidates_count = 0;
    this.templates_count = 0;
    this.hired_candidates_count = 0;
    this.active_candidates_count = 0;
    this.expired_candidates_count = 0;
  }

  static buildGlobalAnalytics(jobsCount, candidatesCount, assessedCount, suitableCandidatesCount, hired_candidates_count, templates_count, active_candidates_count, expired_candidates_count) {
    const globalAnalytics = new GlobalAnalytics();
    globalAnalytics.jobs_count = jobsCount;
    globalAnalytics.candidates_count = candidatesCount;
    globalAnalytics.candidates_assessed_count = assessedCount;
    globalAnalytics.suitable_candidates_count = suitableCandidatesCount;
    globalAnalytics.hired_candidates_count = hired_candidates_count;
    globalAnalytics.templates_count = templates_count;
    globalAnalytics.active_candidates_count = active_candidates_count;
    globalAnalytics.expired_candidates_count = expired_candidates_count;

    return globalAnalytics;
  }
}

export class JobsStatus {

  id: number;
  open: number;
  close: number;
  expired: number;
  jobs: Job[];

}


export class CandidatesStatus {

  id: number;
  name: string;
  total: number;
  hired: number;
  candidates: Candidate[];

}

export class PhasesSummary {
  INS: number;
  CAN: number;
  PHO: number;
  HR: number;
  OA: number;
  BUS: number;
  HIR: number;
}


export class JobSummary extends PhasesSummary {
  id: number;
  name: string;
  closed: Date | null;
  expirated: Boolean;
  description: string;
  total: number;
}

export class ProfessionSummary extends PhasesSummary {
  id: number;
  name: string;
  jobs: number;
  candidates_total: number;
}

export class HireSummary {
  decision: number;
  to_hired: number;
  to_finalist: number;
  to_interviews: number;
}

export class CandidatesSummary {
  total: number;
}

export class JobSituation {
  expired_jobs: number;
  active_jobs: number;
  public_and_private_jobs: number;
  public_jobs: number;
  private_jobs: number;
  closed_jobs: number;
  close_reasons: {}
}

export const ReputationAnalyticsDictionary = [
  {
    title: 'Visibility',
    values: {
      index: 'vis_idx',
      icon: 'vis_c'
    }
  },
  {
    title: 'Employability',
    values: {
      index: 'emp_idx',
      icon: 'emp_c'
    }
  },
  {
    title: 'Findibility',
    values: {
      index: 'find_idx',
      icon: 'find_c'
    }
  },
  {
    title: 'Ambassadors',
    values: {
      index: 'emb_idx',
      icon: 'emb_c'
    }
  },
];

export const ReputationAnalyticsIcons = {
  "+": "fa-arrow-up",
  "-": "fa-arrow-down",
  "=": "fa-equals",
};

export class CandidatesBounceRate {
  bounce_rates: {
    __registrations?: number,
    __registrationForm?: number,
    __landingVisits?: number
  };

  visits: {
    __registrations?: number,
    __registrationForm?: number,
    __landingVisits?: number
  };
}

export class CandidateBounceItem {
  name: string;
  visits: number;
  color?: string;
  rate?: number;
}

export class VisitsByDevice {
  tablet: number;
  mobile_percentage: number;
  desktop: number;
  total: number;
  mobile: number;
  tablet_percentage: number;
  desktop_percentage: number;
}

export class DonutChartItem {
  labels: string[];
  data: number[];
  total?: number;

  constructor() {
    this.labels = [];
    this.data = [];
    this.total = 0;
  }
}


export class UserVisitsFromCampaigns {
  campaign: string;
  count: number;
  source?: string;

}

export class CampaignsSlugs {
  facebookSlugs = ['facebook', 'fcb'];
  linkedinSlugs = ['linkedin', 'lnk'];
  twitterSlugs = ['twitter', 'twt'];
  instagramSlugs = ['instagram', 'ins'];
  indeedSlugs = ['indeed', 'ind'];
  infojobsSlugs = ['infojobs', 'inj'];
  newsletterSlugs = ['newsletter', 'new'];

  getCampaignsSlugsList() {
    return [this.facebookSlugs, this.linkedinSlugs, this.twitterSlugs, this.instagramSlugs,
    this.indeedSlugs, this.infojobsSlugs, this.newsletterSlugs];
  }
}
