import { INSTRUMENT_TYPES } from './../../instruments/constants';
import { FormJob } from './../../instruments/models/candidate-form';
import { Constants } from '../../classes/constants';
import { Landing } from './../../classes/landing';
import { ProfessionService } from './../../services/profession.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user-service.service';
import { ActivatedRoute } from '@angular/router';
import { Profession } from '../../classes/profession';
import { ClientService } from '../../partners/services/client.service';
import { Client } from '../../partners/models/client';

@Component({
  selector: 'app-profession-detail',
  templateUrl: './profession-detail.component.html',
  styleUrls: ['./profession-detail.component.scss']
})
export class ProfessionDetailComponent implements OnInit, OnDestroy {

  wizard: any = { show: false, type: 'create', profession: '' };

  loading = true;

  profession: Profession;
  client: Client = new Client();
  landing_url: string;

  instrumentIds: number[] = [];
  formInstruments: FormJob[] = [];

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private professionService: ProfessionService,
    public userService: UserService,
    public clientService: ClientService,
  ) { }

  ngOnInit() {
    this.subscribeProfessionParameters();
    this.getClient();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  subscribeProfessionParameters() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        this.loading = true;
        if (queryParam.get('profession')) {
          this.getProfessionDetail(+queryParam.get('profession'))
        } else {
          this.loading = false;
        }
      }
    );
  }

  getProfessionDetail(professionID) {
    this.componentSubscriptions.professionServiceRetrieve$ = this.professionService.retrieve(professionID)
      .subscribe(
        profession => {
          this.profession = Object.assign(Profession.getDefaultProfession(), profession);

          this.formInstruments = this.getInstrumentForm();

          this.profession.setInstrumentsData(profession.instruments_data as any);
          this.loading = false;
          const landing = this.profession.landing;
          this.landing_url = this.getPreviewUrl(landing as Landing);
        }
      );
  }

  getClient() {

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(+this.activatedRoute.parent.snapshot.paramMap.get('client'))
      .subscribe(
        response => this.client = response,
        error => console.log(error)
      );
  }

  showWizard(type, profession?) {
    this.wizard = { show: true, type, profession };
  }

  getPreviewUrl(landing: Landing): string {
    return Constants.LANDING_PREVIEW_URL.replace('{id}', String(landing.id))
      .replace('{jwt}', String(localStorage.getItem(Constants.JWT)));
  }

  private getInstrumentForm(): FormJob[] {
    const formInstruments = this.profession.instruments_data.filter(instrument => instrument.origin === INSTRUMENT_TYPES.FORMS)
      .map(form => {
        const formJob = Object.assign(new FormJob(), form);
        formJob.config = form.config.config;
        return formJob;
      });

    return formInstruments;
  }
}
