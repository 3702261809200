import { ClientService } from './../partners/services/client.service';
import { AlertsService } from './alerts.service';
import { UserDataService } from './user-data-service.service';
import { DatabaseCandidateFilters } from './../classes/filters';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Injectable, OnChanges, OnDestroy } from "@angular/core"



@Injectable()
export class ClientDataService {

  groupId: number;
  dataGroup: any = {};
  dataGroup$: BehaviorSubject<any> = new BehaviorSubject(this.dataGroup);

  componentSubscriptions: { [ key: string ]: Subscription } = {};

  constructor(private activatedRoute: ActivatedRoute,
    private userDataService: UserDataService,
    private alertService: AlertsService,
    private clientService: ClientService) {
  }

  getGroupsData(groupId) {

    this.componentSubscriptions.retrieveSocialNetworkUserGroup$ =
      this.clientService.loadClient(groupId).subscribe(response => {
        this.dataGroup = response;
        this.emitDataGroup();
      },
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error)));
  }

  emitDataGroup() {
    this.dataGroup$.next(this.dataGroup);
  }
};
