import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ClientService } from '../../partners/services/client.service';
import { UserService } from '../../services/user-service.service';
import { JobCategory, CATEGORIES_TYPES, CATEGORIES_TYPES_LABELS } from '../../classes/job';
import { UserDataService } from '../../services/user-data-service.service';
import { AlertsService, Alert, ALERT_TYPES } from '../../services/alerts.service';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-setting-organization',
  templateUrl: './setting-organization.component.html',
  styleUrls: ['./setting-organization.component.scss']
})
export class SettingOrganizationComponent implements OnInit, OnDestroy {

  currentJobCategory: JobCategory = null;

  loading: boolean = false;
  categoryTypes = CATEGORIES_TYPES;
  categoryTypeLabels = CATEGORIES_TYPES_LABELS;

  organizations: JobCategory[] = [];
  locations: JobCategory[] = [];
  functions: JobCategory[] = [];
  professions: JobCategory[] = [];

  client: number;

  editModalRef: BsModalRef;
  @ViewChild('editJobCategoryModal', { read: TemplateRef, static: true }) editModalTemplate: TemplateRef<any>;

  deleteModalRef: BsModalRef;
  @ViewChild('deleteJobCategoryModal', { read: TemplateRef, static: true }) deleteModalTemplate: TemplateRef<any>;

  addJobCategoryModalRef: BsModalRef;
  @ViewChild('addJobCategoryModal', { read: TemplateRef, static: true }) addJobCategoryModalTemplate: TemplateRef<any>;

  componentSubscriptions: any = {};

  constructor(
    public userService: UserService,
    public clientService: ClientService,
    public userDataService: UserDataService,
    public alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    public modalService: BsModalService
  ) { }

  ngOnInit() {

    this.client = +this.activatedRoute.parent.snapshot.params.client;
    this.getJobCategoriesForClient(this.client);
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getJobCategoriesForClient(client_id: number) {

    this.loading = true;

    this.componentSubscriptions.clientServiceGetJobCategories$ = this.clientService.getJobCategories(client_id).subscribe(
      data => {

        this.organizations = [];
        this.functions = [];
        this.locations = [];
        this.professions = [];

        data.forEach((element) => {
          switch (element.category) {
            case CATEGORIES_TYPES.ORGANIZATION:
              this.organizations.push(new JobCategory(element.category, element.value,
                element.id, element.category_data, element.group));
              break;
            case CATEGORIES_TYPES.FUNCTION:
              this.functions.push(new JobCategory(element.category, element.value,
                element.id, element.category_data, element.group));
              break;
            case CATEGORIES_TYPES.LOCATION:
              this.locations.push(new JobCategory(element.category, element.value,
                element.id, element.category_data, element.group));
              break;
            case CATEGORIES_TYPES.PROFESSION:
              this.professions.push(new JobCategory(element.category, element.value,
                element.id, element.category_data, element.group));
              break;
          }
        });

        this.organizations.sort((a, b) => (a.value.localeCompare(b.value)));
        this.functions.sort((a, b) => (a.value.localeCompare(b.value)));
        this.locations.sort((a, b) => (a.value.localeCompare(b.value)));
        this.professions.sort((a, b) => (a.value.localeCompare(b.value)));

        this.loading = false;
      },
      error => { console.log(error) }
    );
  }

  addJobCategory(category: string) {

    this.currentJobCategory = new JobCategory(category);
    this.currentJobCategory.group = this.client;
    this.currentJobCategory.getJobCategoryFormData();

    this.addJobCategoryModalRef = this.modalService.show(this.addJobCategoryModalTemplate, { backdrop: 'static' });
  }

  closeModal() {
    if (this.addJobCategoryModalRef) this.addJobCategoryModalRef.hide();
    if (this.editModalRef) this.editModalRef.hide();
    if (this.deleteModalRef) this.deleteModalRef.hide();
  }

  saveJobCategory() {

    this.loading = true;
    this.currentJobCategory.validateCategoryData();
    this.componentSubscriptions.clientServiceCreateJobCategory$ = this.clientService.createJobCategory(this.currentJobCategory).subscribe(
      () => {
        this.getJobCategoriesForClient(this.client);
        this.closeModal();
      },
      (error) => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error))
        this.loading = false;
        this.closeModal();
      }
    );
  }

  showEditJobCategory(event: JobCategory) {

    this.currentJobCategory = event;
    this.currentJobCategory.getJobCategoryFormData();
    this.editModalRef = this.modalService.show(this.editModalTemplate, { backdrop: 'static' });
  }

  showDeleteJobCategory(event: JobCategory) {
    this.currentJobCategory = event;
    this.deleteModalRef = this.modalService.show(this.deleteModalTemplate, { backdrop: 'static' });
  }

  deleteJobCategory() {
    this.loading = true;
    this.componentSubscriptions.clientServiceDeleteJobCategory$ = this.clientService.deleteJobCategory(this.client, this.currentJobCategory.id).subscribe(
      () => {
        this.getJobCategoriesForClient(this.client);
        this.closeModal();
      },
      () => {
        this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, '__errorJobCategoryInUse'));
        this.loading = false;
        this.closeModal();
      }
    );
  }

  editJobCategory() {
    this.loading = true;
    this.currentJobCategory.validateCategoryData();
    this.componentSubscriptions.clientServiceEditJobCategory$ = this.clientService.editJobCategory(this.currentJobCategory).subscribe(
      () => {
        this.getJobCategoriesForClient(this.client);
        this.closeModal();
      },
      (error) => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error));
        this.loading = false;
        this.closeModal();
      }
    );
  }

}
