export const environment = {
  production: false,
  BASE_URL: 'https://dev.aplygo.com/',
  hotjar_code: 905509,
  company_color: '#ff9900',
  sas: false,
  imageUrl: 'static/images/',
  environment_language_file: 'dev-aplygo_',
  feedbackClient: 'dev',
  cookiebot_uuid: '8bd9ee58-d4eb-43eb-9dcf-cd38161f9fcd'
};
