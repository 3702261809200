import { NgModule } from '@angular/core';

// Components
import { WizardJobComponent } from './wizard-job.component';
import { ActivePhasesFunnelComponent } from './active-phases-funnel/active-phases-funnel.component';
import { LandingBuilderComponent } from './landing-builder/landing-builder.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { InterviewsPhasesComponent } from './interviews/interviews-phases/interviews-phases.component';
import { InterviewsBuilderComponent } from './interviews/interviews-builder/interviews-builder.component';
import { InstrumentsListComponent } from './instruments/instruments-list/instruments-list.component';
import { InterviewsListComponent } from './interviews/interviews-list/interviews-list.component';
import { InstrumentsComponent } from './instruments/instruments.component';
import { InstrumentsFormsComponent } from './instruments/instruments-forms/instruments-forms.component';
import { InstrumentsCuteComponent } from './instruments/instruments-cute/instruments-cute.component';
import { InstrumentsInfoComponent } from './instruments/instruments-info/instruments-info.component';
import { InstrumentsProvidersComponent } from './instruments/instruments-providers/instruments-providers.component';
import { InstrumentsSelectedComponent } from './instruments/instruments-selected/instruments-selected.component';
import { InstrumentsTktComponent } from './instruments/instruments-tkt/instruments-tkt.component';
import { ReportsCuteComponent } from './instruments/reports-cute/reports-cute.component';
import { ReportsDisneyComponent } from './instruments/reports-disney/reports-disney.component';
import { JobCategoriesComponent } from './job-categories/job-categories.component';
import { RgpdDisclaimerComponent } from './rgpd-disclaimer/rgpd-disclaimer.component';

// Modules
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { MaterialModule } from '../material.module';
import { ComponentsModule } from '../comps/components.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';

// Services
import { InterviewsService } from './interviews/interviews.service';

@NgModule({
  declarations: [
    WizardJobComponent,
    ActivePhasesFunnelComponent,
    LandingBuilderComponent,
    FormBuilderComponent,
    InterviewsPhasesComponent,
    InterviewsBuilderComponent,
    InterviewsListComponent,
    InstrumentsListComponent,
    InstrumentsComponent,
    InstrumentsFormsComponent,
    InstrumentsCuteComponent,
    InstrumentsInfoComponent,
    InstrumentsProvidersComponent,
    InstrumentsSelectedComponent,
    InstrumentsTktComponent,
    ReportsCuteComponent,
    JobCategoriesComponent,
    ReportsDisneyComponent,
    RgpdDisclaimerComponent
  ],
  exports: [
    WizardJobComponent,
    ActivePhasesFunnelComponent,
    LandingBuilderComponent,
    FormBuilderComponent,
    InterviewsPhasesComponent,
    InterviewsBuilderComponent,
    InterviewsListComponent,
    InstrumentsListComponent,
    InstrumentsComponent,
    InstrumentsFormsComponent,
    InstrumentsCuteComponent,
    InstrumentsInfoComponent,
    InstrumentsProvidersComponent,
    InstrumentsSelectedComponent,
    InstrumentsTktComponent,
    RgpdDisclaimerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    PipesModule,
    MaterialModule,
    ComponentsModule,
    CollapseModule
  ],
  providers: [
    InterviewsService
  ]
})

export class WizardJobModule { }
