import { Pipe, PipeTransform } from '@angular/core';
import { unSlugify } from '../library/utils';


@Pipe({
  name: 'unslugify'
})
export class UnslugifyPipe implements PipeTransform {

  transform(text: string, args?: any): string {
    return unSlugify(text);
  }

}
