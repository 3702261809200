import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../partners/services/client.service';
import { ActivatedRoute } from '@angular/router';
import { Client } from '../../partners/models/client';

@Component({
  selector: 'app-custom-dashboard-client',
  templateUrl: './custom-dashboard-client.component.html',
  styleUrls: ['./custom-dashboard-client.component.scss']
})
export class CustomDashboardClientComponent implements OnInit {

  client: Client = new Client();
  loading = true;

  constructor(
    public clientService: ClientService,
    public activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.getClient();
  }

  getClient() {
    this.clientService.loadClient(+this.activatedRoute.parent.snapshot.paramMap.get('client'))
      .subscribe(
        response => {
          this.client = response;
          this.loading = false;
        },
        error => console.log(error)
      );
  }
}
