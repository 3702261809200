import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from './../../../services/user-service.service';
import { Subscription, Subject } from 'rxjs';
import { Tag } from './../../../classes/tag';
import { Alert, AlertsService, ALERT_TYPES } from './../../../services/alerts.service';
import { TagsControlService } from './../../services/tags-control.service';
import { TagsService } from './../../../services/tag.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-candidates-tags-manager',
  templateUrl: './candidates-tags-manager.component.html',
  styleUrls: ['./candidates-tags-manager.component.scss']
})
export class CandidatesTagsManagerComponent implements OnInit, OnDestroy {

  @Input() candidatesIds: number[] = [];

  allAppliedTags: Tag[] = [];
  filteredAppliedTags: Tag[] = [];

  @Input() set appliedTags(appliedTags: Tag[]) {
    this.allAppliedTags = appliedTags;
    this.updateAppliedTags(appliedTags);
  }

  @Input() readonly = false;

  @Output() appliedTagsChange: EventEmitter<Tag[]> = new EventEmitter();

  allTags: Tag[] = [];
  availableTags: Tag[] = [];

  addingTagsIds: number[] = [];
  removingTagsIds: number[] = [];

  searchTerm = '';
  search$ = new Subject<string>();

  linkToManageTags = false;

  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private tagsService: TagsService,
    public tagsControlService: TagsControlService,
    private alertsService: AlertsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.subscribeToAvailableTags();
    this.linkToManageTags = this.userService.hasClientAdminPermissions();
    this.doSearch();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  subscribeToAvailableTags() {
    this.tagsControlService.availableTags$.subscribe(
      tags => {
        this.allTags = tags.map(tag => Object.assign(new Tag(), tag));
        this.setAvailableTags();
      },
    );
  }

  updateAppliedTags(tags: Tag[]) {
    this.allAppliedTags = this.filterDuplicatedTags(tags);

    this.filteredAppliedTags = tags.filter(tag => this.stringMatchesSearch(tag.name));

    this.setAvailableTags();
  }

  filterDuplicatedTags(tags: Tag[]): Tag[] {
    const singleIdsOrder: number[] = Array.from(new Set(tags.map(tag => tag.id)));
    return tags.filter((tag: Tag, i: number) => singleIdsOrder.indexOf(tag.id) === i);
  }

  setAvailableTags() {
    const appliedTagsIds = this.allAppliedTags.map(tag => tag.id);
    this.availableTags = this.allTags.filter(tag => !appliedTagsIds.includes(tag.id) && this.stringMatchesSearch(tag.name));
  }

  stringMatchesSearch(text: string): boolean {
    return text.toLowerCase().normalize('NFD').includes(this.searchTerm.toLowerCase().normalize('NFD'));
  }

  assignTags(tags: Tag[]) {
    const tagsIds = tags.map(tag => tag.id);

    this.addingTagsIds = [...this.addingTagsIds, ...tagsIds];

    this.tagsService.assignTagsToCandidates(this.tagsControlService.getClientUuid(), this.candidatesIds, tagsIds).subscribe(
      res => {
        this.updateAppliedTags(this.allAppliedTags.concat(tags));
        this.emitTagsChange();
        this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__tagAddedSuccessfully'));
      },
      error => this.alertsService.setAlert(null, error),
      () => this.addingTagsIds = this.addingTagsIds.filter(id => !tagsIds.includes(id))
    );
  }

  removeTags(tags: Tag[]) {
    const tagsIds = tags.map(tag => +tag.id);

    this.removingTagsIds = [...this.removingTagsIds, ...tagsIds];

    this.tagsService.removeTagsFromCandidates(this.tagsControlService.getClientUuid(), this.candidatesIds, tagsIds).subscribe(
      res => {
        this.updateAppliedTags(this.allAppliedTags.filter(tag => !tagsIds.includes(tag.id)));
        this.emitTagsChange();
        this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__tagRemovedSuccessfully'));
      },
      error => this.alertsService.setAlert(null, error),
      () => this.removingTagsIds = this.removingTagsIds.filter(id => !tagsIds.includes(id))
    );
  }

  doSearch(): void {
    this.componentSubscriptions.searchTerm$ = this.search$
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(
        search => {
          this.searchTerm = search;
          this.updateAppliedTags(this.allAppliedTags);
        }
      );
  }

  emitTagsChange() {
    this.appliedTagsChange.emit(this.allAppliedTags);
  }

}
