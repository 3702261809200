import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { JobService } from '../../../services/job.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { Job } from '../../../classes/job';

export interface SocialNetworksI {
  name: string;
  img?: string;
  icon?: string;
  url: string;
}

@Component({
  selector: 'app-multiposting-share',
  templateUrl: './multiposting-share.component.html',
  styleUrls: ['./multiposting-share.component.scss']
})
export class MultipostingShareComponent implements OnInit, OnDestroy {

  @Input() parent: string;
  @Input() job: Job;

  socialNetworks: SocialNetworksI[] = [
    {
      name: 'Infojobs',
      img: '../../../../assets/company-logo/company_infojobs.png',
      url: '?utm_source=infojobs&utm_medium=aplygo&utm_campaign=infojobs_mkt'
    },
    {
      name: 'Indeed',
      img: '../../../../assets/company-logo/company_indeed.png',
      url: '?utm_source=indeed&utm_medium=aplygo&utm_campaign=indeed_mkt'
    },
    {
      name: 'Linkedin',
      img: '../../../../assets/company-logo/company_linkedin.png',
      url: '?utm_source=linkedin&utm_medium=aplygo&utm_campaign=linkedin_mkt'
    },
    {
      name: 'Facebook',
      img: '../../../../assets/company-logo/company_facebook.png',
      url: '?utm_source=facebook&utm_medium=aplygo&utm_campaign=facebook_mkt'
    },
    {
      name: 'Twitter',
      img: '../../../../assets/company-logo/company_twitter.png',
      url: '?utm_source=twitter&utm_medium=aplygo&utm_campaign=twitter_mkt'
    },
    {
      name: 'Instagram',
      img: '../../../../assets/company-logo/company_instagram.png',
      url: '?utm_source=instagram&utm_medium=aplygo&utm_campaign=instagram_mkt'
    },
    {
      name: 'Newsletter',
      img: '../../../../assets/email-icon.png',
      url: '?utm_source=newsletter&utm_medium=aplygo&utm_campaign=newsletter_mkt'
    }
  ];

  componentSubscriptions: any = {};

  constructor(
    private jobService: JobService,
    private translateService: TranslateService,
    private alertService: AlertsService
  ) { }

  ngOnInit() {
    if (this.job && this.job.privacy === 'PRI') {
      this.pushPrivacyUrl();
    }
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  pushPrivacyUrl() {
    this.socialNetworks.push({
      name: `${this.translateService.instant('__private')}`,
      icon: 'work',
      url: '?origin=PRI'
    });
  }

  copyCampaign(network) {
    this.componentSubscriptions.jobServiceGetLandingUrl$ = this.jobService.getLandingUrl(this.job.id.toString()).subscribe(data => {
      const url = `${data.landing_url}${network.url}`;
      const el = document.createElement('textarea');
      el.value = url;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      const translate_message = this.translateService.instant('__campaignUrlCorrectly');
      this.alertService.setAlert({ type: 'success', message: `${network.name} ${translate_message}` });
    }, error => {
      const translate_message = this.translateService.instant('__campaignUrlError');
      this.alertService.setAlert({ type: 'error', message: `${network.name} ${translate_message}` });
    });
  }

  openCampaingsMail() {
    this.componentSubscriptions.jobServiceGetLandingUrlOpenCampaingMail$ = this.jobService.getLandingUrl(this.job.id.toString())
      .subscribe(data => {
        const landingUrl = data.landing_url;
        const campaignsMail = this.socialNetworks.map(element => `${landingUrl}${element.url}`);
        this.createCampaignsMail(campaignsMail);
      }, () => {
        const translate_message = this.translateService.instant('__campaignUrlError');
        this.alertService.setAlert({ type: 'error', message: translate_message });
      });

  }

  createCampaignsMail(campaigns) {
    window.open(`mailto:?subject=${this.translateService.instant('__campaignsLinks') + this.job.name || ''}&body=${encodeURIComponent(campaigns.join('\n'))}`);
  }
}
