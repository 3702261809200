export class Appearance {
  description = '';
  color = {
    primary: '',
    secondary: ''
  };

  font = {
    primary: '',
    secondary: ''
  };

  logo = '';
  favicon = '';

  images = {
    cover: ''
  };
}
