import { FormJob } from '../../instruments/models/candidate-form';
import { FormTemplate } from '../../classes/form';
import { UserService } from '../../services/user-service.service';
import { InstrumentService } from '../../instruments/instrument.service';
import { environment } from '../../../environments/environment';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { WizardInstruments, Instrument, Origin, ReportOrigin, InstrumentCreationData, InstrumentCreationDataV2} from '../../instruments/models/instrument';
import { INSTRUMENT_TYPES, intrumentsOrder, tktInstruments } from '../../instruments/constants';
import { InstrumentsService } from '../../instruments/instruments.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ReportConfig } from '../../classes/reportConfig';

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.scss']
})
export class InstrumentsComponent implements OnInit, OnDestroy {

  errors: string[] = [];
  environmentIsProduction = environment.production;
  _instrumentsSelected: Instrument[] = [];

  testProviders: Origin[] = [];
  reportProviders: ReportOrigin [] = []; 
  reportsAvailable: ReportConfig[] = [];
  reportsFeedback: ReportConfig[] = [];

  availableInstrumentsForms = true;

  @Input() client: string;

  @Input() set instrumentsData(data: WizardInstruments) {
    this.availableInstrumentsForms = !data.instruments.find(instrument => instrument.provider_origin === INSTRUMENT_TYPES.FORMS) ? false : true;
    this.setTestProviders(data.instrumentsProviders, data.instrumentsClientConfig);
    this.setInstruments(data.instruments);
    this.availableForms = data.formTemplates;
  }

  @Input() set reportsData(data: Array<ReportConfig>) {
    this.setReportProviders(data);
  }

  @Input() set instrumentsSelected(instruments: any[]) {
    this._instrumentsSelected = instruments ? instruments : [];
    this.serializeSelectedInstruments();
  }

  @Input() set selectedFormInstruments(formInstruments: any[]) {
    this.selectedFormsIds = this.getFormsId(formInstruments || []);
    this.selectedForms = this.availableForms.filter(formTemplate => this.selectedFormsIds.indexOf(formTemplate.id) > -1);
  }

  @Input() update: Boolean;
  @Input() killerTalentPoints = 0;

  @Output() instrumentsChange: EventEmitter<any[]> = new EventEmitter();
  @Output() formsChange: EventEmitter<any[]> = new EventEmitter();

  @Output() killerTalentPointsChange: EventEmitter<number> = new EventEmitter();

  instruments: Instrument[] = [];

  instrumentTypes = INSTRUMENT_TYPES;

  editingWeight: Array<boolean> = [];
  editingFieldWeight: Array<boolean> = [];

  primitiveFormInstrument: Instrument;
  availableForms: FormTemplate[] = [];
  selectedForms: FormTemplate[] = [];
  selectedFormsIds: number[] = [];

  componentSubscriptions: any = {};

  constructor(
    private instrumentService: InstrumentService,
    private userService: UserService,
    public instrumentsService: InstrumentsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  setTestProviders(dataProviders: Origin[], clientConfig: { [key: string]: any }) {

    this.testProviders = this.filterAvailableProviders(dataProviders, clientConfig);
    const instrumentsFormsIndex = this.testProviders.findIndex(provider => provider.provider === INSTRUMENT_TYPES.FORMS);
    if (!this.availableInstrumentsForms && instrumentsFormsIndex !== -1) {
      this.testProviders.splice(this.testProviders.findIndex(provider => provider.provider === INSTRUMENT_TYPES.FORMS), 1);
    }

    this.setSelectedProviders();
    this.changeNameOfFormInstrument();
  }

  setReportProviders(data: Array<ReportConfig>) {
    this.reportsFeedback = [];

    if (data.length > 0) {
      for (const config of data) {
        if (config.is_feedback) {
          this.reportsFeedback.push(new ReportConfig(config.origin, config.slug, config.description, config.instruments, config.is_feedback, config.language, config.normset, config.origin, config.report_id));
        }
      }

      let providersReports = this.reportsFeedback.map(report => { return report.origin });
      providersReports = providersReports.filter((item,index) => providersReports.indexOf(item) === index);

      for (const provider of providersReports) {
        this.reportProviders.push(new ReportOrigin(provider, '__'+provider, provider));
      }
      
    }
  }

  filterAvailableProviders(providers: Origin[], clientConfig: { [key: string]: any }) {

    const filteredProviders = providers.filter(provider => {
      const providerConfig = clientConfig[provider.provider] || {};
      return Object.values(providerConfig)
        .length > 0 &&
        Object.values(providerConfig)
          .every(configKey => Boolean(configKey));
    });

    return filteredProviders;
  }

  setSelectedProviders(): void {

    this.testProviders = this.testProviders.map((provider, i) => {
      provider.selected = i === 0;
      if (provider.selected) {
        this.instrumentsService.selectTestProvider(this.testProviders, provider);
      }
      return provider;
    });
  }

  changeNameOfFormInstrument(): void {

    this.testProviders.forEach(provider =>
      provider.name = provider.provider === INSTRUMENT_TYPES.FORMS ? this.client : provider.name);
  }

  setInstruments(dataInstruments: Instrument[]) {
    this.primitiveFormInstrument = dataInstruments.find(instrument => instrument.provider_origin === INSTRUMENT_TYPES.FORMS);
    this.instruments = this.serializeInstruments(dataInstruments.filter(instrument => instrument.provider_origin !== INSTRUMENT_TYPES.FORMS));
    this.addTKTInstruments();
    if (this._instrumentsSelected.length > 0) {
      this.serializeSelectedInstruments();
    }
    this.setSelectedProviders();
  }

  serializeInstruments(instruments: Instrument[]): Instrument[] {
    const sortedInstruments = this.sortInstruments(instruments);
    const serializedInstruments = sortedInstruments.map(instrument => {
      instrument.selected = false;
      instrument.job_config = this._getDefaultConfig(instrument);
      instrument.model = 'reference';
      instrument.talent_rank = this.getDefaultTalentRank(instrument);
      instrument.weight = 0;
      instrument.visibility = true;
      return instrument;
    });
    return serializedInstruments;
  }

  sortInstruments(instruments: Instrument[]): Instrument[] {
    const sortedInstruments = instruments.sort(
      (a, b) => intrumentsOrder.indexOf(a.config.instrumentid) - intrumentsOrder.indexOf(b.config.instrumentid)
    );
    return sortedInstruments;
  }

  addTKTInstruments(): void {

    if (this.testProviders.find(provider => provider.provider === INSTRUMENT_TYPES.TKT)) {
      this.instruments = this.instruments.concat(tktInstruments);
    }
  }

  getSelectedInstrumentsAndForms(): any[] {
    return [].concat(...this.instrumentsService.getSelectedInstruments(this.instruments), this.serializeFormsToInstruments(this.selectedForms));
  }

  serializeSelectedInstruments(): void {
    const selectedInstrumentIDs = this._instrumentsSelected.map(instrument => instrument.id);

    this.instruments = this.instruments.map(instrument => {

      const selectedInstrument = this._instrumentsSelected.find(selectInstr => selectInstr.id === instrument.id);
      instrument.selected = selectedInstrument ? true : false;

      if (selectedInstrument) {
        instrument.weight = this.getSelectedInstrumentWeight(selectedInstrument, selectedInstrumentIDs.indexOf(instrument.id));
        instrument = this._serializeInstrumentConfig(instrument, selectedInstrument);
      }
      return instrument;
    });

    this.emitSelectedInstruments();
  }

  _serializeInstrumentConfig(instrument: Instrument, selectedInstrument: any): Instrument {

    if (selectedInstrument.config) {

      instrument.job_config = selectedInstrument.config.config ? selectedInstrument.config.config : instrument.job_config;

      // Version 2

      let talentRank = instrument.talent_rank;

      if (!instrument.config.version || instrument.config.version !== 2) {

        let mappingIdKey = instrument.job_config.mappingid;

        if (mappingIdKey === null || mappingIdKey === undefined) {
          mappingIdKey = 'default';
        }

        talentRank = this.instrumentsService.getTalentRank(instrument, '' + mappingIdKey);
      }

      instrument.talent_rank = selectedInstrument.config.talent_rank ? this._addReferenceFieldToSelectedTalentRank(selectedInstrument.config.talent_rank, talentRank, instrument.config.version) : instrument.talent_rank;
    }
    return instrument;
  }

  _addReferenceFieldToSelectedTalentRank(selectedTalentRank, instrumentTalentRank, version) {
    if (version === 2) {
      selectedTalentRank.forEach(selectedItem => {
        instrumentTalentRank.forEach(group => {
          if (group.name === selectedItem.group) {
            (group.fields || [])
              .forEach(item => {
                if (item.field === selectedItem.field) {
                  item.reference_field = selectedItem.reference_field;
                }
              });
          }
        });
      });
    } else {
      selectedTalentRank.forEach(selectedItem => {
        instrumentTalentRank.forEach(item => {
          if (item.field === selectedItem.field) {
            item.reference_field = selectedItem.reference_field;
          }
        });
      });
    }

    return instrumentTalentRank;
  }

  getTalentRankForGroups(instrument: Instrument, groups: string[]): InstrumentCreationDataV2['talent_rank'] {

    // Version 2
    const talentRank = groups.map(group => ({
      'name': group,
      'scale': instrument.config.rankeable_fields[group].scale,
      'fields': instrument.config.rankeable_fields[group].fields.map(field =>
        ({ field: field.name, reference_field: instrument.config.rankeable_fields[group].scale.max || 1 })
      )
    }));

    return talentRank;
  }

  getDefaultTalentRank(instrument: Instrument): InstrumentCreationData['talent_rank'][] | InstrumentCreationDataV2['talent_rank'] {
    const rankeableFieldsGroup = 'default';
    const rankeableFields = Object.keys(instrument.config.rankeable_fields)
      .length >= 1;

    // Version 2
    if (instrument.config.version && instrument.config.version === 2 && rankeableFields) {

      const groups = Object.keys(instrument.config.rankeable_fields);

      return this.getTalentRankForGroups(instrument, groups);

    } else if (instrument.config.rankeable_alternative_field) {
      const alternativeValue = instrument.config.alternatives[instrument.config.rankeable_alternative_field][0].value;

      return this.instrumentsService.getTalentRank(instrument, alternativeValue);

    } else {

      return this.instrumentsService.getTalentRank(instrument, rankeableFieldsGroup);

    }
  }

  saveKillerTalentPoints(killerTalent: number) {
    this.killerTalentPoints = killerTalent;
    this.killerTalentPointsChange.emit(this.killerTalentPoints);
  }

  
  emitSelectedInstruments() {
    const serializedSelectedInstruments = this.instrumentsService.getSelectedInstruments(this.instruments)
      .map(instrument => Object.assign(new InstrumentCreationData(), this.parseSelectedInstrument(instrument)));

    this.checkValidFeedbackReports(serializedSelectedInstruments);

    this.validateSelectedInstruments();
    const valueEmit = this.errors.length > 0 ?
      null : [].concat(...serializedSelectedInstruments, this.serializeFormsToInstruments(this.selectedForms));
    this.instrumentsChange.emit(valueEmit);
  }

  emitSelectedForms() {
    this.formsChange.emit(this.serializeFormsToInstruments(this.selectedForms));
  }

  checkValidFeedbackReports(instruments: InstrumentCreationData[]) {
    if (this.reportsFeedback && instruments) {
      this.reportsFeedback.map(report => report.visible = false);
      const reportsIds = instruments.filter(instrument => instrument.provider_origin == INSTRUMENT_TYPES.CUTE || instrument.provider_origin == INSTRUMENT_TYPES.TKT).map((instrument) => { return instrument.instrument_id });
      const reportsSelected = this.instrumentsService.getSelectedReportsIds() || [];
      this.reportsFeedback.filter(report => report.instruments.every(id => reportsIds.includes(id))).map(report => report.visible = true);
      this.reportsFeedback.filter(report => reportsSelected.includes(report.report_id)).map(report => report.selected = true);
      this.instrumentsService.setSelectedReports(this.reportsFeedback);
    }
  }

  getCuteReports(){
    return this.instrumentsService.getVisibleReport(INSTRUMENT_TYPES.CUTE);
  }

  getDisneyReports(){
    return this.instrumentsService.getVisibleReport(INSTRUMENT_TYPES.TKT);
  }

  validateSelectedInstruments() {
    const totalEvaluation = this.translateService.instant('__totalEvaluation');
    const evaluationWeight = this.translateService.instant('__evaluationWeight');
    this.errors = [];
    if (this.instrumentsService.getSelectedInstruments(this.instruments)
      .length > 0 && this.instrumentsService.getTotalInstrumentWeight(this.instruments) !== 100) {
      this.errors.push('-' + totalEvaluation);
    }
    if (!this.areInstrumentsWeightGreaterThanZero()) {
      this.errors.push('-' + evaluationWeight);
    }
  }

  parseSelectedInstrument(instrument: Instrument): InstrumentCreationData {
    let talent_rank = [];
    if (!instrument.config.version || instrument.config.version === 1) {
      talent_rank = instrument.talent_rank.map(field =>
        ({ group: 'default', field: field.field, weighting: 0, reference_field: field.reference_field })
      );
    } else {
      instrument.talent_rank.forEach(group =>
        group.fields.forEach(field => {
          talent_rank.push({ group: group.name, field: field.field, weighting: 0, reference_field: field.reference_field });
        })
      );
    }

    return {
      id: instrument.id,
      instrument_id: instrument.config.instrumentid,
      config: instrument.job_config,
      origin: instrument.origin as string,
      provider_origin: instrument.provider_origin as string,
      instrument_weighting: instrument.weight / 100,
      phase: instrument.phase,
      reference_weighting: this._getInstrumentWeighting(instrument),
      min_value: 1,
      talent_rank
    };
  }

  _getInstrumentWeighting(instrument: Instrument): number {
    const prevInstrument = this._instrumentsSelected.find(selectedInstrument => selectedInstrument.id === instrument.id);
    return prevInstrument && prevInstrument.config ? prevInstrument.config.reference_weighting : 100;
  }

  getTotalInstrumentDuration(): number {
    const totalDuration = this.instrumentsService.getSelectedInstruments(this.instruments)
      .map(inst => inst.duration ? inst.duration : 10)
      .reduce((total, inst_duration) => total + inst_duration, 0);
    return totalDuration;
  }

  toggleWeightEdit(instrumentPosition: number) {
    const weight = 'weight';
    this.editingWeight[instrumentPosition] = !this.editingWeight[instrumentPosition];
    if (this.editingWeight[instrumentPosition]) {
      const inputId = weight + instrumentPosition;
      setTimeout(() => this.focusInput(inputId), 100);
    } else {
      this.emitSelectedInstruments();
    }
  }

  focusInput(inputId: string) {
    const input: any = document.getElementById(inputId);
    input.select();
  }

  // closeInfoDialog() {
  //   this.instrumentsService.viewInfoDialog = false;
  //   this.instrumentsService.infoDialogInstrument = null;
  // }

  _getDefaultConfig(instrument: Instrument): Instrument['job_config'] {
    return Object.entries(instrument.config.alternatives)
      .reduce((prev, [key, values]) => {
        prev[key] = values[0].value;
        return prev;
      }, {});
  }

  setDefaultConfig(instrument: Instrument) {
    this.componentSubscriptions.instrumentServiceRetrieve$ = this.instrumentService.retrieve(instrument.id)
      .pipe(take(1))
      .subscribe(response => {
        const config_instrument = Object.assign(response);
        config_instrument.config = this._getDefaultConfig(response);
        return config_instrument.config;
      });
  }

  getSelectedInstrumentWeight(settedInstrument: Instrument, instrumentPosition: number): number {
    return settedInstrument.config && settedInstrument.config.instrument_weighting ?
      (Math.round(settedInstrument.config.instrument_weighting * 100)) :
      this.instrumentsService.calculateWeightPercentage(100, this._instrumentsSelected.length, instrumentPosition);
  }

  areInstrumentsWeightGreaterThanZero(): boolean {
    return this.instrumentsService.getSelectedInstruments(this.instruments)
      .every(instrument => instrument.weight > 0);
  }

  handleSelectedForms(formTemplates: FormTemplate[]) {
    this.selectedForms = formTemplates;
    this.setContentToForms();
    this.emitSelectedInstruments();
    this.emitSelectedForms();
  }

  setContentToForms() {
    const formReduce = {};
    this.selectedForms.forEach(form => formReduce[form.id] = form.content);

    this.availableForms = this.availableForms.map(selForm => ({ ...selForm, content: formReduce[selForm.id] }));
  }

  serializeFormsToInstruments(formTemplates: FormTemplate[]): FormJob[] {
    return formTemplates.map(form => FormJob.fromFormTemplate(form, this.primitiveFormInstrument));
  }

  private getFormsId(formInstruments: Instrument[] | FormJob[]): number[] {
    let formIds = [];
    const firstForm = formInstruments[0];

    if (firstForm && (firstForm as Instrument)
      .config.config) {
      formIds = (formInstruments as Instrument[])
        .map(form => form.config.config.form_template_id);
    } else if (firstForm instanceof FormJob) {
      formIds = (formInstruments as FormJob[])
        .map(form => form.config.form_template_id);
    }

    return formIds;
  }

}
