import { Profession} from './../classes/profession';
import { Filter } from './../library/classes/filter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buildFilter } from '../library/utils';
import { Constants } from '../classes/constants';
import { Subject ,  Observable } from 'rxjs';
import { RestService } from '../library/classes/rest-service';
import { ProfessionsByGroup } from '../classes/group';

@Injectable()
export class ProfessionService extends RestService < Profession > {

  endpoint = Constants.PROFESSION_URL;

  create(profession: Profession): Observable < Profession > {
    return this.httpClient.post < Profession > (this.endpoint, this.removeInterviewEmails(profession));
  }

  update(profession: Profession): Observable < Profession > {
    const url = `${this.endpoint}${profession.id}/`;
    return this.httpClient.put < Profession > (url, this.removeInterviewEmails(profession));
  }

  saveProfessionToLocal(profession) {
    localStorage.setItem(Constants.PROFESSION, JSON.stringify(profession));
  }

  clearLocalProfession() {
    localStorage.removeItem(Constants.PROFESSION);
  }

  getLocalProfession(): Profession {
    return JSON.parse(localStorage.getItem(Constants.PROFESSION));
  }

  removeInterviewEmails(profession: Profession): Profession {
    delete profession.emails['INTERVIEWS'];
    return profession;
  }

  saveProfessionsFromGroupToLocal(professions) {
    localStorage.setItem(Constants.PROFESSION_FROM_GROUP, JSON.stringify(professions));
  }

  clearLocalProfessionsFromGroup() {
    localStorage.removeItem(Constants.PROFESSION_FROM_GROUP);
  }

  getLocalProfessionsFromGroup(): ProfessionsByGroup {
    return JSON.parse(localStorage.getItem(Constants.PROFESSION_FROM_GROUP));
  }
}
