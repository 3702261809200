import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyvalue'
})
export class KeyValuePipe implements PipeTransform {

  transform(dictionary: any = {}  , args?: any): any {
    return Object.keys(dictionary || {}).map((key) => {
      return dictionary[key];
    });
  }

}
