import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Subject ,  Observable } from 'rxjs';

class HotkeyConfig {
  [key: string]: string[];
}

class ConfigModel {
  hotkeys: HotkeyConfig;
}

export class KeyboardShortcut {
  name: string;
  combo: string;
  ev: KeyboardEvent;
}

@Injectable()
export class KeyboardShortcutService {

  private subject: Subject<KeyboardShortcut>;
  commands: Observable<KeyboardShortcut>;

  constructor(private hotkeysService: HotkeysService,
    private httpClient: HttpClient) {
    this.subject = new Subject<KeyboardShortcut>();
    this.commands = this.subject.asObservable();
    this.httpClient.get('../../assets/keyboard-shortcuts.json').toPromise()
      .then(r => r as ConfigModel)
      .then(c => {
        for (const key in c.hotkeys) {
          const commands = c.hotkeys[key];
          hotkeysService.add(new Hotkey(key, (ev, combo) => this.hotkey(ev, combo, commands)));
        }
      });
  }

  hotkey(ev: KeyboardEvent, combo: string, commands: string[]): boolean {
    commands.forEach(c => {

      const command = {
        name: c,
        ev: ev,
        combo: combo
      } as KeyboardShortcut;
      this.subject.next(command);
    });
    return true;
  }
}
