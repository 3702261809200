import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CandidateService } from '../../services/candidate.service';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'app-candidate-rating',
  templateUrl: './candidate-rating.component.html',
  styleUrls: ['./candidate-rating.component.scss']
})
export class CandidateRatingComponent implements OnInit, OnDestroy {

  stars: number[] = [1,2,3,4];

  @Input() rate:number;
  @Output() rateChange: EventEmitter<number> = new EventEmitter <number>();

  @Input() editable: boolean = false;
  @Input() candidateUuid:number;

  @Input() size: string = '';

  hoverValue: number = 0;

  componentSubscriptions: any = {};

  constructor(
    private candidateService: CandidateService,
    private alertService: AlertsService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  getStarClass(value:number): string {
    if ( this.editable && this.hoverValue > 0) {
      return value <= this.hoverValue ? 'star' : 'star_border';
    }
    return value <= this.rate ? 'star' : 'star_border';
  }

  rateCandidate(value: number) {
    if ( this.editable) {
      if(value === this.rate) {
        value = 0
      }
      this.componentSubscriptions.candidateServiceRateCandidate$ = this.candidateService.rateCandidate(this.candidateUuid, value).subscribe(
        candidate => this.emitRate(candidate.rate),
        error => this.alertService.setAlert({type: '', message: ''}, error),
      );
    }
  }

  emitRate(rate: number) {
    this.rateChange.emit(rate);
  }

}
