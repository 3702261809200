import { FormTemplate } from './../../classes/form';
import { InstrumentsConfig } from '../../partners/models/client-instrument-config';
import { IRestModel } from './../../library/models/rest-model';


export class Instrument implements IRestModel {
  id: number;
  instrument_id?: number;
  description: string;
  name: string;
  weight?= 0;
  duration?= 0;
  origin: number | string;
  provider_origin: string;
  phase?: string;
  config: {
    version?: number,
    alternatives: any,
    rankeable_fields: any,
    rankeable_alternative_field: string,
    instrument_weighting?: number,
    reference_weighting?: number,
    config?: any,
    instrumentid?: number,
  } = {
      alternatives: {},
      rankeable_fields: {},
      rankeable_alternative_field: 'default'
    };
  rankeable_fields: {};
  job_config: any;
  selected = false;
  model: string;
  talent_rank: any[];
  visibility: boolean;
  media: string[];
  appearance: {
    color: string,
    long_description: string;
  };
}

export class InstrumentCreationData {
  id: number;
  instrument_id?: number;
  config: {
    languageid: number,
    normsetid: number,
    scale: number,
  };
  instrument_weighting: number;
  reference_weighting: number;
  min_value: number;
  origin: string;
  phase: string;
  provider_origin: string;
  talent_rank: {
    field: string,
    weighting: number,
    reference_field: number,
    group?: string
  }[];

  static buildFromInstrumentsData(obj: any): InstrumentCreationData {
    const instrumentCreationData = Object.assign(new InstrumentCreationData(), obj);
    instrumentCreationData.id = obj.instrument_id || obj.id;
    instrumentCreationData.provider_origin = obj.provider_origin || obj.origin;
    instrumentCreationData.origin = instrumentCreationData.provider_origin;
    instrumentCreationData.phase = obj.phase;
    return instrumentCreationData;
  }
}

export interface Field {
  field: string;
  reference_field: number;
}

export class InstrumentCreationDataV2 {
  id: number;
  config: {
    languageid: number,
    normsetid: number,
    scale: number,
  };
  instrument_weighting: number;
  reference_weighting: number;
  min_value: number;
  talent_rank: {
    fields: string[] | Field[],
    name: string,
    scale: number | { max: number, min: number },
  }[];

  static buildFromInstrumentsData(obj: any) {
    const instrumentCreationData = Object.assign(new InstrumentCreationData(), obj);
    instrumentCreationData.id = obj.instrument_id;
    return instrumentCreationData;
  }
}

export class Origin {

  name = '';
  id: number;
  status: string;
  slug: string;
  provider: string;
  selected?= false;

  constructor(name: string, id: number, status: string, slug: string, provider: string) {
    this.name = name;
    this.id = id;
    this.status = status;
    this.slug = slug;
    this.provider = this.provider;
  }
}

export class ReportOrigin {
  id: string = '';
  name = '';
  slug: string;
  selected?= false;

  constructor(id: string, name: string, slug: string) {
    this.id = id;
    this.name = name;
    this.slug = slug;
  }
}

export interface InstrumentExplanation {
  weighting: number;
  points: number;
}

export class WizardInstruments {
  instrumentsProviders: Origin[] = [];
  instruments: Instrument[] = [];
  instrumentsClientConfig: InstrumentsConfig = new InstrumentsConfig();
  formTemplates: FormTemplate[] = [];

  constructor(
    instrumentsProviders: Origin[] = [],
    instruments: Instrument[] = [],
    instrumentsClientConfig = new InstrumentsConfig(),
    formTemplates: FormTemplate[] = []
  ) {
    this.instrumentsProviders = instrumentsProviders,
      this.instruments = instruments,
      this.instrumentsClientConfig = instrumentsClientConfig;
    this.formTemplates = formTemplates;
  }

}
