export class ReportConfig {
    id: string;
    slug: string;
    description: string;
    instruments: number[];
    is_feedback: boolean;
    language: string;
    normset: string;
    origin: string;
    report_id: string;
    selected ? = false;
    visible ? = false;
  
    constructor (id:string, slug:string, description:string, instruments:number[], is_feedback:boolean, language:string, normset: string, origin:string, report_id: string) {
      this.id = id;
      this.slug = slug;
      this.description = description;
      this.instruments = instruments;
      this.is_feedback = is_feedback;
      this.language = language;
      this.normset = normset;
      this.origin = origin;
      this.report_id = report_id;
      this.selected = false;
      this.visible = false;
    }
  }