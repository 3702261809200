
import {throwError as observableThrowError,  Observable, empty } from 'rxjs';
import { AlertsService } from './alerts.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { UserService } from './user-service.service';
import { Constants } from '../classes/constants';
import { catchError } from 'rxjs/operators';

@Injectable()
export class Jwtinterceptor implements HttpInterceptor {

  constructor(private router: Router, private inj: Injector) {}

  intercept(req: HttpRequest < any > ,
    handler: HttpHandler): Observable < HttpEvent < any >> {
    const idToken = localStorage.getItem(Constants.JWT);
    const isTranslationUrl = req.url.includes(Constants.TRANSLATIONS_URL);
    if (idToken && !isTranslationUrl) {
      const cloned = req.clone({
        "headers": req.headers.set("Authorization", "JWT " + idToken)
      });

      return this.handlerError(handler.handle(cloned));
    } else {
      return this.handlerError(handler.handle(req))
    }
  }


  handlerError(obs: Observable < HttpEvent < any >> ): Observable < HttpEvent < any >> {
    return obs.pipe(catchError(e => {
      if (this.router.url !== '/login' && e.status === 401) {
        this.inj.get(UserService)
          .logout();
        this.inj.get(AlertsService)
          .setAlert({ type: 'warning', message: 'You have to authenticate again to verify your access' })
        this.router.navigate(['/login'])
        return empty();
      }
      return observableThrowError(e);
    }));
  }

}
