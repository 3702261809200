import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-rgpd-disclaimer',
  templateUrl: './rgpd-disclaimer.component.html',
  styleUrls: ['./rgpd-disclaimer.component.scss']
})
export class RgpdDisclaimerComponent implements OnInit {
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  openModal (modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, {backdrop: 'static'});
  }


}
