import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {


  time: Array<any> = [
    {
      seconds: 1,
      title: '__s',
    },
    {
      seconds: 60,
      title: '__min',
    },
    {
      seconds: 3600,
      title: '__hours',
    },
    {
      seconds: 86400,
      title: '__days',
    },
    {
      seconds: 604800,
      title: '__weeks',
    },
    {
      seconds: 2419200,
      title: '__months',
    }
  ];


  constructor(private translateService: TranslateService){}

  transform(value: number): any {
    this.time = this.translateTitleTime()

    if (value < this.time[1].seconds) {
      const firstNumber = Math.trunc(value);
      return `${firstNumber} ${this.translateService.instant(this.time[0].title)}`;
    }
    if (value > this.time[5].seconds) {
      const firstNumber = Math.trunc(value / this.time[5].seconds);
      const secondNumber = Math.trunc(value % this.time[4].seconds / this.getSeconds(4));
      return `${firstNumber} ${this.time[5].title} ${secondNumber} ${this.getTitle(4)}`;
    }
    for (let i = 1; i < this.time.length; i++) {
      if (value < this.time[i].seconds) {
        const firstNumber = Math.trunc(value / this.getSeconds(i));
        const secondNumber = Math.trunc(value % this.getSeconds(i) / this.getSeconds(i, 2));
        return `${firstNumber} ${this.getTitle(i)} ${secondNumber} ${this.getTitle(i, 2)}`;
      }
    }
  }

  transformToDays(value: number): any {
    this.time = this.translateTitleTime();
    return `${(value / this.time[3].seconds).toFixed(2)} ${this.translateService.instant(this.time[3].title)}`;
  }

  translateTitleTime(): any[]{
    return this.time.map( t =>  {
      t.title = this.translateService.instant(t.title);
      return t;
    });
  }

  getSeconds(i: number, modifier: number = 1 ) {
    const value = i >= modifier ? this.time[i - modifier].seconds : 1;
    return value;
  }
  getTitle(i: number, modifier: number = 1) {
    const value = i >= modifier ? this.time[i - modifier].title : 's';
    return value;
  }
}
