import { Injectable, OnDestroy } from '@angular/core';
import { Instrument, Origin, InstrumentCreationData, ReportOrigin } from '../instruments/models/instrument';
import { INSTRUMENT_TYPES } from '../instruments/constants';
import { InstrumentService } from './instrument.service';
import { Constants } from '../classes/constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { ReportConfig } from '../classes/reportConfig';

@Injectable()
export class InstrumentsService implements OnDestroy{

  viewMode: string;
  viewProvider: string;
  viewConfigDialog = false;
  configDialogInstrument: Instrument = null;
  viewSelected: { selected: boolean, message: string };
  reportsSelected: ReportConfig[];

  infoDialogInstrument: Instrument;
  viewInfoDialog = false;

  componentSubscriptions: any = {};

  constructor(
    private instrumentService: InstrumentService,
    private httpClient: HttpClient,
  ) { }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  selectTestProvider(providers: Origin[], provider: Origin) {

    providers.forEach(origin => origin.selected = provider.id === origin.id);
    this.viewProvider = provider.provider;
    this.viewMode = "INSTRUMENTS";
  }

  selectReportProvider(providers: ReportOrigin[], provider: ReportOrigin) {

    providers.forEach(origin => origin.selected = provider.id === origin.id);
    this.viewProvider = provider.slug;
    this.viewMode = "REPORTS";
  }

  getSelectedInstruments(instruments: Instrument[]): Instrument[] {

    return instruments.filter(instrument => instrument.selected && instrument.provider_origin !== INSTRUMENT_TYPES.FORMS);
  }

  getSelectedReports() {
    return this.reportsSelected.filter(report => report.selected);
  }

  getSelectedReportsIds() {
    if (this.reportsSelected) {
      return this.reportsSelected.filter(report => report.selected).map(report => { return report.report_id });
    }
  }

  getVisibleReport(origin: string) {
    return this.reportsSelected.filter(report => report.visible && report.origin == origin);
  }

  setSelectedReports(reports: ReportConfig[]) {

    reports.map(report => {
      if (report.visible == false) {
        report.selected = false;
      }
    });

    this.reportsSelected = reports;
  }

  changeSelectedOneReport(currentReport: ReportConfig) {
    this.reportsSelected.filter(report => report.report_id === currentReport.report_id).map(report => report.selected = !report.selected);
  }

  getVisibleInstruments(instruments: Instrument[]): Instrument[] {

    return instruments.filter(instrument => instrument.visibility && instrument.origin !== 10);
  }

  showConfigDialog(instrument: Instrument) {

    this.componentSubscriptions.instrumentServiceRetrieve$ = this.instrumentService.retrieve(instrument.id)
      .pipe(take(1))
      .subscribe(response => {
        this.viewConfigDialog = true;
        this.configDialogInstrument = instrument;
        this.configDialogInstrument.config = response.config;
      });
  }

  closeConfigDialog() {

    this.viewConfigDialog = false;
    this.configDialogInstrument = null;
  }

  getTotalInstrumentWeight(instruments): number {

    const totalWeight = this.getSelectedInstruments(instruments)
      .map(inst => inst.weight)
      .reduce((total, inst_weight) => total + inst_weight, 0);
    return totalWeight;
  }

  toggleSelectionView() {

    this.viewSelected.selected = !this.viewSelected.selected;
    this.viewSelected.message = this.viewSelected.selected ? 'Close' : 'Set test weight';
    if (!this.viewSelected.selected) {
      this.viewSelected.message = 'View selection';
    }
  }

  getTalentRank(instrument: Instrument, alternative_org: string): InstrumentCreationData['talent_rank'][] {

    const alternative = alternative_org.toLowerCase();

    // Version 2

    if (instrument.config.version && instrument.config.version === 2) {

      return instrument.config.rankeable_fields[alternative].fields
        .map((field: any, instrumentPosition: number) => {
          return {
            'field': field.name,
            'weighting': this.calculateWeightPercentage(100,
              instrument.config.rankeable_fields[alternative].fields.length,
              instrumentPosition),
            'reference_field': instrument.config.rankeable_fields[alternative].scale.max || 1
          };
        });

    } else {

      if (alternative in instrument.config.rankeable_fields) {

        return instrument.config.rankeable_fields[alternative]
          .map((field: string, instrumentPosition: number) => {
            return {
              field,
              'weighting': this.calculateWeightPercentage(100,
                instrument.config.rankeable_fields[alternative].length,
                instrumentPosition),
              'reference_field': instrument.job_config.scale || 1
            };
          });

      } else {

        return [];
      };
    }
  }

  calculateWeightPercentage(total: number, quantity: number, instrumentPosition: number): number {
    const result = Math.floor(total / quantity);
    if (result * quantity < 100) {
      return instrumentPosition < 100 - (result * quantity) ? result + 1 : result;
    }
    return result;
  }

  setupInstrumentUrl(id: string, language: string, privacy: string): any {
    const url = Constants.SETUP_URL_INSTRUMENT.replace('{id}', id).replace('{language}', language).replace('{privacy}', privacy);;
    return this.httpClient.get<any>(url);
  }

  downloadReport(data) {
    const url = Constants.DOWNLOAD_INSTRUMENT_REPORT;
    return this.httpClient.post<any>(url, data);
  }

  sendReportFeedbackToCandidate(data){
    const url = Constants.SEND_FEEDBACK_REPORT;
    return this.httpClient.post<any>(url, data);
  }


}
