
export enum TAG_COLORS {
  JUNGLE_GREEN = 'JUNGLE_GREEN',
  CACTUS_GREEN = 'CACTUS_GREEN',
  OASIS_AQUAMARINE = 'OASIS_AQUAMARINE',
  OCEAN_BLUE = 'OCEAN_BLUE',
  LAVENDER_VIOLET = 'LAVENDER_VIOLET',
  FLAMINGO_PINK = 'FLAMINGO_PINK',
  ROSE_RED = 'ROSE_RED',
  CARROT_ORANGE = 'CARROT_ORANGE',
  SILVER_GREY = 'SILVER_GREY',
  SPOTLESS_WHITE = 'SPOTLESS_WHITE'
}

export class Tag {

  color: TAG_COLORS;
  name: string;
  candidates_count: number;
  id?: number;
  group?: number;
  editing?: boolean;

  constructor(color = TAG_COLORS.JUNGLE_GREEN, name = '', candidates_count = 0, id?: number, group?: number) {
    this.color = color;
    this.name = name;
    this.candidates_count = candidates_count;
    if (id) {
      this.id = id;
    }
    if (group) {
      this.group = group;
    }
  }

  static getSortedTags(tags: Tag[]): Tag[] {
    return tags.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
  }

  isValid(): boolean {
    return Boolean(this.name && this.color);
  }

}
