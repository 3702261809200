import { FormElement } from './custom-forms';

export enum CREDENTIAL_TYPES {
  BASIC_AUTH = 'BASIC_AUTH',
  OAUTH2 = 'OAUTH2',
  NONE = 'NONE'
}

export enum MULTIPOSTING_REQUEST_STATUS {
  PENDING = 'PEND',
  ERROR = 'ERROR',
  DONE = 'DONE',
  INVALID_PAYMENT = 'INVPAY',
  INVALID_CREDENTIALS = 'INVCRED',
  OUT_OF_STOCK = 'OUTOSTK',
}
export class MultipostingCredentials {
  credentials: {
    username: string,
    password: string
  };
  group_multiposting_provider: number;
  id: number;
  validCredentials: boolean;

  constructor(
    credentials: { username: string, password: string } = { username: '', password: '' },
    id?: number,
    group_multiposting_provider?: number,
    credentialType?: CREDENTIAL_TYPES
  ) {
    this.id = id;
    this.credentials = credentials;
    this.group_multiposting_provider = group_multiposting_provider;
    this.validCredentials = this.areCredentialsValid(credentials, credentialType);
  }

  areCredentialsValid(
    credentials: { username: string, password: string }, credentialType: CREDENTIAL_TYPES): boolean {
    switch (credentialType) {
      case CREDENTIAL_TYPES.BASIC_AUTH:
        return Boolean(credentials && credentials.username && credentials.password);

      case CREDENTIAL_TYPES.OAUTH2:
        return Boolean(credentials && credentials.username);

      case CREDENTIAL_TYPES.NONE:
        return true;
    }
  }
}

export class MultipostingProvider {
  id: number;
  name: string;
  provider: string;
  authentication: CREDENTIAL_TYPES;
}

export class MultipostingRequestData {
  extra_config: any = {};
  job_multiposting: {
    id: number,
    job: number,
    group_multiposting_provider: number,
  };
  id: number;
  owner: number;
  status: MULTIPOSTING_REQUEST_STATUS;
}

export class GroupMultipostingProvider {
  group: number;
  id: number;
  provider: MultipostingProvider;
  type: 'manual' | 'api';
  user_credentials: MultipostingCredentials = new MultipostingCredentials();
  multiposting_request_data: MultipostingRequestData;

  static getSerializedProvidersCredentials(
    groupProviders: GroupMultipostingProvider[],
    credentials: MultipostingCredentials[]
  ): GroupMultipostingProvider[] {

    return groupProviders.map(
      provider => {

        const userCredentials = credentials.find(credential => credential.group_multiposting_provider === provider.id);


        if (userCredentials) {
          provider.user_credentials = new MultipostingCredentials(
            userCredentials.credentials,
            userCredentials.id,
            userCredentials.group_multiposting_provider,
            provider.provider.authentication
          );
        }

        return provider;
      }
    );

  }

  static getSerializedProvidersRequests(
    groupProviders: GroupMultipostingProvider[],
    requests: MultipostingRequestData[] = []
  ): GroupMultipostingProvider[] {

    return groupProviders.map(
      provider => {

        const multipostingRequest = requests.find(request => request.job_multiposting.group_multiposting_provider === provider.id);

        if (multipostingRequest) {
          provider.multiposting_request_data = multipostingRequest;
        }

        return provider;
      }
    );

  }
}

export class SaveMultiposingFormResponse {
  group_multiposting_provider: number;
  id: number;
  job: number;
  config: FormElement[];
  job_multiposting?: number;
}
