export class Doc {
  uuid: string;
  name: string;
  extension: string;

  constructor(uuid: string, name: string, extension: string) {
    this.uuid = uuid;
    this.name = name;
    this.extension = extension;
  }
}
