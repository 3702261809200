import { Tag, TAG_COLORS } from './../../classes/tag';
import { TagsControlService } from './../../tags/services/tags-control.service';
import { TranslateService } from '@ngx-translate/core';
import {
  FiltersGroupDictionary, CandidateFilters, DatabaseCandidateFilters,
  StatusFilter, RatingFilter, TaskFilter, SeenFilter
} from './../../classes/filters';
import { Filter } from './../../library/classes/filter';
import {
  TablePhase, BUSSINESS, INSCRIPTION, CANDIDATURE, PHONE, HR, OTHER_ASSESMENT,
  HIRED, CandidatesFunnel, PhaseCondition, Status, getPhaseInterviews, getJobInstruments,
  getOtherAssesmentInterviews, PhaseExtraColumn
} from './../../classes/phases';
import { Job } from './../../classes/job';
import { JobService } from './../../services/job.service';
import { Subject, Observable, Subscription, forkJoin } from 'rxjs';
import { Candidate, EMPLOYEE_STATUS, TASK_TYPES } from './../../classes/candidate';
import { Router, ActivatedRoute } from '@angular/router';
import { CandidateService } from './../../services/candidate.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Alert, AlertsService, ALERT_TYPES } from '../../services/alerts.service';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { deleteFilter, setFilter } from '../../library/utils';
import { LayoutService, LayoutActions } from '../../services/layout.service';
import { UserService } from '../../services/user-service.service';
import { WsgiEmail } from '../../classes/wsgi-email';
import { ClientService } from '../../partners/services/client.service';
import { Client } from '../../partners/models/client';
import { switchMap, take, debounceTime, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { ClientTranslationService } from './../../services/client-translation.service';
import { LOAD_IMAGE_ERROR } from '../../../assets/ckeditor/load-image-error';
import { SIZE_ERROR } from '../../../assets/ckeditor/size-error';
import { calculateContentSize, getBase64FromFile, splitStringInLines } from '../../utils';


declare const CKEDITOR: any;
declare const jQuery: any;

@Component({
  selector: 'app-candidates-table',
  templateUrl: './candidates-table.component.html',
  styleUrls: [ './candidates-table.component.scss' ]
})
export class CandidatesTableComponent implements OnInit, OnDestroy {
  ks: string;
  subscription: Subscription;
  activate$: Subscription;
  activate2$: Subscription;

  loadingJob = true;
  loadingTable = false;
  searching = false;

  job_id: string;
  job: Job = new Job();
  total = 0;

  candidate_url = '';

  colors = [ 'orange', 'green', 'blue', 'pink', 'turquoise', 'purple' ];

  search = null;
  extendedSearch = false;

  elements: { elements: Candidate[], headers: string[]; } = {
    elements: [],
    headers: []
  };

  @ViewChild('searchCandidate', { static: true }) searchCandidate;

  @ViewChild('confirmationModal', { static: false }) confirmationModal;
  @ViewChild('sendMailModal', { static: true }) sendMailModal;
  @ViewChild('viewerModal', { static: false }) viewerModal;



  searchTerm = new Subject<string>();
  header_sorted = {
    header: '',
    order: 0
  };
  page = 0;

  candidateDetail = false;
  candidateSelectedId: string;

  parameters: Filter[] = [];

  candidate_colors: string[] = [];

  TASK_TYPES = TASK_TYPES;

  itemsPerPage: number[] = [ 20, 50, 100 ];
  itemSelected = 20;


  phases: TablePhase[] = [ {
    name: INSCRIPTION.name,
    label: INSCRIPTION.name,
    annotation: null,
    value: INSCRIPTION.code,
    selected: true,
    candidates: new CandidatesFunnel(),
    extra_columns: {
      condition: null,
      columns: []
    },
    filters: [ 'form' ]
  },
  {
    name: CANDIDATURE.name,
    label: CANDIDATURE.name,
    annotation: null,
    value: CANDIDATURE.code,
    selected: false,
    candidates: new CandidatesFunnel(),
    extra_columns: {
      condition: new PhaseCondition(getJobInstruments, { arg: PHONE.code }),
      columns: []
    },
    filters: [ 'form', 'instruments' ],
  },
  {
    name: PHONE.name,
    label: PHONE.name,
    annotation: null,
    value: PHONE.code,
    selected: false,
    candidates: new CandidatesFunnel(),
    extra_columns: {
      condition: new PhaseCondition(getPhaseInterviews, { arg: PHONE.code }),
      columns: []
    },
    filters: [ 'form', 'instruments', 'interviews' ],
  },
  {
    name: OTHER_ASSESMENT.name,
    label: OTHER_ASSESMENT.name,
    annotation: null,
    value: OTHER_ASSESMENT.code,
    selected: false,
    candidates: new CandidatesFunnel(),
    extra_columns: {
      condition: new PhaseCondition(getOtherAssesmentInterviews, { arg: OTHER_ASSESMENT.code }),
      columns: []
    },
    filters: [ 'form', 'instruments', 'interviews' ],
  },
  {
    name: HR.name,
    label: HR.name,
    annotation: null,
    value: HR.code,
    selected: false,
    candidates: new CandidatesFunnel(),
    extra_columns: {
      condition: new PhaseCondition(getPhaseInterviews, { arg: HR.code }),
      columns: []
    },
    filters: [ 'form', 'instruments', 'interviews' ],
  },
  {
    name: BUSSINESS.name,
    label: BUSSINESS.name,
    annotation: null,
    value: BUSSINESS.code,
    selected: false,
    candidates: new CandidatesFunnel(),
    extra_columns: {
      condition: new PhaseCondition(getPhaseInterviews, { arg: BUSSINESS.code }),
      columns: []
    },
    filters: [ 'form', 'instruments', 'interviews' ],
  },
  {
    name: HIRED.name,
    label: HIRED.name,
    annotation: null,
    value: HIRED.code,
    selected: false,
    candidates: new CandidatesFunnel(),
    extra_columns: {
      condition: null,
      columns: []
    },
    filters: [ 'form', 'instruments', 'interviews' ],
  },
  ] as TablePhase[];

  selectedRow: number;

  selectedCandidates: any[] = [];

  multiActionType: string;

  config = {
    extraPlugins: 'colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: [ 'Format', 'Font' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'images', items: [ 'Image', '__uploadImage' ] }
    ]
  };

  emailVariables = WsgiEmail.VARS;
  modalRef: BsModalRef;
  buttons = WsgiEmail.VARS;
  email: { subject: string, content: string; } = {
    subject: '',
    content: ''
  };
  uploadImageVariable = { title: '__uploadImage', icon: 'upload.png' };
  editorContent$: Subject<String> = new Subject();
  editorContentSize = 0;
  disableSend = true;
  loadingImages = false;
  sendingEmail = false;


  showCandidateTalentPoints: {
    [ candidate: string ]: boolean;
  } = {};

  displayFilters = false;
  filtersData: DatabaseCandidateFilters;
  availableFilters: CandidateFilters[] = [];
  appliedFilters: CandidateFilters[] = [];
  availableFiltersGroups: string[] = [];
  filtersGroupDictionary = FiltersGroupDictionary;

  status = Status;

  client: Client = new Client();

  componentSubscriptions: any = {};

  employeeStatus = EMPLOYEE_STATUS;

  availableTags: Tag[] = [];
  @ViewChild('inputFile', { static: true }) inputFile;


  constructor(
    public candidateService: CandidateService,
    private activatedRoute: ActivatedRoute,
    private jobService: JobService,
    private modalService: BsModalService,
    private alertService: AlertsService,
    private router: Router,
    private keyboardShortcutService: KeyboardShortcutService,
    public layoutService: LayoutService,
    public userService: UserService,
    private clientService: ClientService,
    private translationService: TranslateService,
    private tagsControlService: TagsControlService,
    private elementRef: ElementRef,
    private clientTranslationService: ClientTranslationService
  ) { }

  ngOnInit() {
    const phaseSelected = this.getSelectedPhase();
    this.parameters = [ new Filter('transitions', phaseSelected.value) ];
    this.getCandidates();
    this.doSearch();
    this.subscribeCandidateParam();
    this.subscribeToAvailableTags();
    this.componentSubscriptions.keyboardShortcutServiceCommands$ =
      this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));


    this.getClient();
    this.subcribeToEditorContentDebounce();
    this.subcribeToEditorContent();


  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }


  getClient() {

    this.componentSubscriptions.clientServiceLoadClient$ =
      this.clientService.loadClient(+this.activatedRoute.parent.snapshot.paramMap.get('client'))
        .subscribe(
          response => this.client = response,
          error => console.log(error)
        );
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'CandidateTableComponent.Down':
        this.goToNextCandidate();
        break;
      case 'CandidateTableComponent.Up':
        this.goToPreviousCandidate();
        break;
      case 'CandidateTableComponent.ShiftDown':
        this.goToFirstCandidate();
        break;
      case 'CandidateTableComponent.ShiftF':
        this.goToSearch();
        break;
      case 'CandidateTableComponent.Space':
        this.openCandidateDetail();
        break;

    }
  }

  goToNextCandidate() {
    if (this.candidateDetail) {
      let candidateIndex = this.elements.elements.findIndex(candidate => +candidate.id === this.selectedRow);
      this.elements.elements[ candidateIndex ].is_seen = true;
      if (++candidateIndex < this.elements.elements.length) {
        this.selectedRow = +this.elements.elements[ candidateIndex ].id;
        this.router.navigate([ this.router.url.split('?')[ 0 ] ], { queryParams: { candidate: this.elements.elements[ candidateIndex ].uuid } });
      } else {
        --candidateIndex;
      }
    }
  }

  goToPreviousCandidate() {
    if (this.candidateDetail) {
      let candidateIndex = this.elements.elements.findIndex(candidate => +candidate.id === this.selectedRow);
      this.elements.elements[ candidateIndex ].is_seen = true;
      if (candidateIndex > 0) {
        this.selectedRow = +this.elements.elements[ --candidateIndex ].id;
        this.router.navigate([ this.router.url.split('?')[ 0 ] ], { queryParams: { candidate: this.elements.elements[ candidateIndex ].uuid } });
      }
    }
  }

  goToFirstCandidate() {
    const url_splitted = this.router.url.split('?');
    if (!url_splitted[ 1 ] && this.elements.elements.length) {
      this.selectedRow = +this.elements.elements[ 0 ].id;
      this.router.navigate([ url_splitted[ 0 ] ], { queryParams: { candidate: this.elements.elements[ 0 ].uuid } });
    }
  }

  goToSearch() {
    setTimeout(() => {
      this.searchCandidate.nativeElement.focus();
    }, 100);
  }

  openCandidateDetail() {
    if (this.candidateDetail) {
      const candidateIndex = this.elements.elements.findIndex(candidate => +candidate.id === this.selectedRow);
      this.checkCandidate(this.elements.elements[ candidateIndex ]);
    }
  }

  onCandidateAction({ candidate, event }: { candidate: Candidate, event: String; }) {
    this.updateTable();
    if (event === Candidate.ACTIONS.MOVE) {
      this.updateFunnel();
    }
  }

  getCandidates(): void {
    this.componentSubscriptions.activatedRouteParentParamMapPipe$ = this.activatedRoute.parent.paramMap.pipe(switchMap(params => {
      this.job_id = params.get('job');
      this.loadingJob = true;
      this.selectedCandidates = [];
      this.getCandidatesFilters();
      return this.jobService.getJobDetail(this.job_id);
    }))
      .pipe(switchMap(
        job => {
          this.layoutService.handleLayoutServiceAction(LayoutActions.RESET_JOB_DASHBOARD);
          if (this.job.id && job.id !== this.job.id) {
            Object.values(this.parameters).forEach(param => {
              if (param.name === 'transitions') {
                param.value = 'INS';
              }
            });

            this.search = null;
            this.parameters = setFilter(this.parameters, new Filter('search', null));
            this.parameters = setFilter(this.parameters, new Filter('extended_search', null));
          }
          this.job = job;
          this.loadingJob = false;
          this.loadingTable = true;
          this.setPhasesCandidatesNumber();
          this.setExtraColumns();
          return this.candidateService.getCandidatesFromJob(this.job_id, this.itemSelected, ...this.parameters);
        }))
      .subscribe(candidates => this.saveCandidatesToTable(candidates));
  }

  getSelectedPhase(): TablePhase {
    const initialPhase = this.activatedRoute.snapshot.url[ 1 ].path;
    return this.phases.find(phase => phase.value === initialPhase);
  }

  getSelectedPhaseIndex(): number {
    return this.phases.findIndex(status => status.selected);
  }

  handlePhaseSelected(selectedPhase: TablePhase) {
    if (!selectedPhase.disabled && selectedPhase.value !== this.getCurrentPhase()) {
      this.loadingTable = true;
      this.resetPhaseFilters(selectedPhase);
      this.selectedCandidates = [];
      this.updatePhaseParameters();
      this.getSelectedPhaseCandidates();
      this.search = null;
      this.parameters = setFilter(this.parameters, new Filter('search', null));
      this.parameters = setFilter(this.parameters, new Filter('extended_search', null));
      this.router.navigate([ '..', selectedPhase.value ], { queryParamsHandling: 'merge', relativeTo: this.activatedRoute });
    }
  }

  getCurrentPhase() {
    return this.activatedRoute.snapshot.params.phase;
  }

  inscriptedLastWeek(inscriptionDate) {
    const aWeekAgo: Date = new Date(new Date()
      .getTime() - (7 * 24 * 60 * 60 * 1000));
    return new Date(inscriptionDate) < aWeekAgo;
  }

  setExtraColumns() {
    this.phases.forEach(phase => {
      if (phase.extra_columns.condition) {
        phase.extra_columns.condition.updateArgs({ job: this.job });
        phase.extra_columns.columns = phase.extra_columns.condition.execute();
      }
    });
  }

  updatePhaseParameters() {
    this.parameters = setFilter(this.parameters,
      new Filter('transitions', this.phases.find(status_arr => status_arr.selected === true)
        .value)
    );
  }

  updateFunnel() {
    this.componentSubscriptions.jobServiceGetJobDetail$ = this.jobService.getJobDetail(this.job_id)
      .pipe(take(1))
      .subscribe(job => {
        this.job = job;
        this.setPhasesCandidatesNumber();
      });
  }

  getSelectedPhaseCandidates() {
    this.componentSubscriptions.candidateServiceGetCandidatesFromJob$ =
      this.candidateService.getCandidatesFromJob(this.job_id, this.itemSelected, ...this.parameters)
        .subscribe(candidates =>
          this.saveCandidatesToTable(candidates)
        );
  }

  setPhasesCandidatesNumber() {
    this.phases = this.phases.map(phase => {
      phase.candidates = Object.assign(phase.candidates, this.job.transitions[ phase.value ]);
      phase.candidates.getTotalCandidates();
      return phase;
    });
  }

  getCandidatesFilters(): void {
    this.componentSubscriptions.candidateServiceGetCandidatesFilters$ = this.candidateService.getCandidatesFilters(this.job_id)
      .subscribe(
        response => {
          this.filtersData = response;
          this.buildAvailableFiltersAndGroups(response, this.getSelectedPhase());
        },
        error => console.log(error)
      );
  }

  buildAvailableFiltersAndGroups(filters: any, phase: TablePhase) {

    this.availableFilters = this.buildDefaultFilters(filters, phase);

    const employeeFilter: CandidateFilters = {
      name: 'is_employee',
      label: '__typeOfCandidate',
      group: 'general',
      values: [
        { value: 'EMP', label: '__employees' },
        { value: 'NOT', label: '__externalCandidates' }
      ]
    };

    const tagsFilters: CandidateFilters = {
      name: 'tag',
      label: '__candidateTags',
      group: 'general',
      values: this.availableTags.map(tag => ({ value: tag.id.toString(), label: tag.name }))
    };

    this.availableFilters = [ ...this.availableFilters, employeeFilter, tagsFilters ];
    this.availableFiltersGroups = this.buildPhaseFiltersGroups();

  }

  buildDefaultFilters(filters: any = {}, phase: TablePhase): CandidateFilters[] {
    const initFilter = [ StatusFilter, RatingFilter, TaskFilter, SeenFilter ];

    const defaultFilters = phase.filters.reduce((totalFilters, filtersGroup) => {
      const addFilters = this.serializeGroupFilters(filters[ filtersGroup ] || [], filtersGroup);
      return totalFilters.concat(addFilters);
    }, initFilter);

    const uniqueFilters = this.removeDuplicatedFilters(defaultFilters);
    const filtersWithoutQuestionMarks = this.removeQuestionMarks(uniqueFilters);

    return this.removeChooseOneOption(filtersWithoutQuestionMarks);
  }

  serializeGroupFilters(filters: CandidateFilters[], filtersGroup: string): CandidateFilters[] {
    return filters
      .filter(filter => filter.values !== null && filter.values.length > 0)
      .map(filter => {
        filter.group = filtersGroup;
        return filter;
      });
  }

  buildPhaseFiltersGroups(): string[] {
    return this.availableFilters.reduce((totalGroups, currentFilter) => {
      if (!totalGroups.includes(currentFilter.group)) {
        return totalGroups.concat(currentFilter.group);
      }
      return totalGroups;
    }, [ 'general' ]);
  }

  getFiltersByGroup(group: string): CandidateFilters[] {
    return this.availableFilters.filter(filter => filter.group === group);
  }

  setCandidateFilter(filter, values) {
    if (values) {
      if (values.indexOf('All') !== -1) {
        if (values.length === filter.values.length + 1) {
          this.parameters = setFilter(this.parameters, new Filter(filter.name, []));
        } else {
          this.selectAllFilters(filter);
        }
      } else {
        this.parameters = setFilter(this.parameters, new Filter(filter.name, values));
      }
    }
  }

  selectAllFilters(filter) {
    this.parameters = setFilter(this.parameters, new Filter(filter.name, filter.values.map(val => val.value)));
  }

  getFilterValue(filter) {
    const filterIsSelected = this.parameters.find(param => param.name === filter.name);
    return filterIsSelected ? filterIsSelected.value : '';
  }

  resetPhaseFilters(phase: TablePhase) {
    this.buildAvailableFiltersAndGroups(this.filtersData, phase);
    this.parameters = this.parameters.filter(filter => {
      return this.availableFilters.find(availableFilter => availableFilter.name === filter.name);
    });
    this.setAppliedFilters();
  }

  resetFilters(phase: TablePhase) {
    this.buildAvailableFiltersAndGroups(this.filtersData, phase);
    this.parameters = setFilter([], new Filter('transitions', phase.value));
    this.loadingTable = true;
    this.filterCandidates();
  }

  filterCandidates() {
    this.getSelectedPhaseCandidates();
    this.setAppliedFilters();
    this.displayFilters = false;
  }

  setAppliedFilters() {
    this.appliedFilters = this.parameters.reduce((filteredParams: CandidateFilters[], param) => {
      const valueParam = param.value as String[];
      const filterAvailable: CandidateFilters = this.availableFilters.find(appliedFilter => appliedFilter.name === param.name);
      return (filterAvailable && valueParam.length) ? filteredParams.concat(filterAvailable) : filteredParams;
    }, []);
  }

  removeFilter(filter) {
    this.parameters = this.parameters.filter(param => param.name !== filter.name);
    this.loadingTable = true;
    this.filterCandidates();
  }

  removeDuplicatedFilters(filters: CandidateFilters[]): CandidateFilters[] {
    const uniqueFilters = filters.reduce((resultFilters, filter) => {
      const filterAlreadyExists = resultFilters.find(resultFilter => resultFilter.name === filter.name);
      if (!filterAlreadyExists) {
        return resultFilters.concat(filter);
      }
      return resultFilters;
    }, []);
    return uniqueFilters;
  }

  removeQuestionMarks(filters) {
    const cleanFilters = filters.map(filter => {
      filter.name = filter.name.replace(/\¿|\?/g, '');
      return filter;
    });
    return cleanFilters;
  }

  removeChooseOneOption(filters: CandidateFilters[]): CandidateFilters[] {
    const filtersWithoutChooseOne = filters.reduce((resultFilters, filter) => {
      // tslint:disable-next-line: no-shadowed-variable
      filter.values = filter.values.filter(filter => filter.value !== 'choose-one');
      return resultFilters.concat(filter);
    }, []);
    return filtersWithoutChooseOne;
  }

  openCandidate(candidate: Candidate) {
    this.selectedRow = +candidate.id;
    if (!this.layoutService.isCandidateActionOpen()) {
      this.router.navigate([], { queryParams: { candidate: candidate.uuid }, relativeTo: this.activatedRoute });
    } else {
      this.layoutService.handleLayoutServiceAction(
        LayoutActions.ATTEMPT_CANDIDATE_ACTION_CLOSE, {
        route: [],
        extras: { queryParams: { candidate: candidate.uuid }, relativeTo: this.activatedRoute }
      }
      );
    }
  }

  subscribeCandidateParam() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        if (!this.layoutService.isCandidateActionOpen()) {
          this.candidateSelectedId = queryParam.get('candidate');
          this.candidateDetail = Boolean(this.candidateSelectedId);
          Boolean(this.candidateSelectedId) ?
            this.layoutService.handleLayoutServiceAction(LayoutActions.COLLAPSE_JOBS_SIDEBAR) :
            this.layoutService.handleLayoutServiceAction(LayoutActions.EXPAND_JOBS_SIDEBAR);
          if (!this.candidateDetail) { this.selectedRow = null; }
        }
      }
    );
  }

  subscribeToAvailableTags() {
    this.componentSubscriptions.availableTags$ = this.tagsControlService.availableTags$.subscribe(
      tags => {
        this.availableTags = tags;

        if (this.filtersData) {
          this.buildAvailableFiltersAndGroups(this.filtersData, this.getSelectedPhase());
        }
      }
    );
  }

  extendedSearchChange(event: boolean) {
    this.extendedSearch = event;
    this.doSearch();
    this.searchTerm.next(this.search);
  }

  doSearch(): void {
    this.componentSubscriptions.searchTerm$ = this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged((previous, current) => current === this.search),
        switchMap(search => {
          this.search = search;
          this.searching = true;
          if (this.extendedSearch) {
            this.parameters = deleteFilter(this.parameters, 'search');
            this.parameters = setFilter(this.parameters, new Filter('extended_search', search));
          } else {
            this.parameters = deleteFilter(this.parameters, 'extended_search');
            this.parameters = setFilter(this.parameters, new Filter('search', search));
          }
          return this.candidateService.getCandidatesFromJob(this.job_id, this.itemSelected, ...this.parameters);
        }))
      .subscribe(
        candidates => {
          this.searching = false;
          this.saveCandidatesToTable(candidates);
        }
      );
  }

  saveCandidatesToTable(candidates: HttpResponse<Candidate[]>) {
    this.total = +candidates.headers.get('count');
    this.elements.elements = candidates.body;
    this.elements.headers = [ 'created', 'identifier' ];
    this.loadingJob = false;
    this.loadingTable = false;
    this.updateSelectedCandidates();
  }

  updateSelectedCandidates() {
    this.selectedCandidates = this.selectedCandidates.map(selectedCandidate => {
      const updatedCandidate = this.elements.elements.find(element => element.id === selectedCandidate.id);
      selectedCandidate = Object.assign(selectedCandidate, updatedCandidate);
      return selectedCandidate;
    });
  }

  sort(header: string): void {
    this.header_sorted.header = header;
    this.header_sorted.order = this.header_sorted.order <= 0 ? this.header_sorted.order + 1 : -1;
    const order = this._getSortedOrder();
    this.parameters = setFilter(this.parameters, new Filter('ordering', order));
    this.getSelectedPhaseCandidates();
  }

  sorted(header: string): boolean {
    return this.header_sorted.header === header && this.header_sorted.order > 0;
  }

  sortedInverse(header: string): boolean {
    return this.header_sorted.header === header && this.header_sorted.order < 0;
  }

  _getSortedOrder(): string {
    let order = '';
    order += this.header_sorted.order < 0 ? '-' : '';
    order += this.header_sorted.order === 0 ? '' : this.header_sorted.header;
    return order;
  }

  getColors(candidate: any) {
    const colLength = this.colors.length;
    if (colLength > 0 && candidate.email) {
      const index = candidate.email[ 0 ].charCodeAt(0) % this.colors.length;
      return this.colors[ index ];
    }
  }

  getCandidateInitials(candidate) {
    if (candidate.first_name && candidate.last_name) {
      return `${candidate.first_name[ 0 ]}${candidate.last_name[ 0 ]}`;
    }
    return `${candidate.email[ 0 ]}${candidate.email[ 1 ]}`;
  }

  changePage(event: { page, itemsPerPage; }) {
    this.parameters = setFilter(this.parameters, new Filter('page', event.page));
    this.getSelectedPhaseCandidates();
    this.scrollTop();

  }

  checkCandidate(candidate: Candidate) {
    if (this.isChecked(candidate)) {
      this.selectedCandidates.splice(
        this.selectedCandidates
          .map(candidateUuid => candidateUuid.uuid)
          .indexOf(candidate.uuid), 1);
    } else {
      this.selectedCandidates.push(candidate);
    }
  }

  checkAllPageCandidates() {
    const checkCandidates = this.areAllChecked() ? this.elements.elements : this.elements.elements.filter(candidate => {
      if (!this.isChecked(candidate)) { return candidate; }
    });
    checkCandidates.forEach(candidate => this.checkCandidate(candidate));
  }

  areSelectedCandidatesInStatus(status) {
    let areInStatus = true;
    areInStatus = this.selectedCandidates.every(candidate => candidate.status === status);
    return areInStatus;
  }

  isChecked(candidate: Candidate) {
    return this.selectedCandidates.map(cand => cand.uuid)
      .includes(candidate.uuid);
  }

  areAllChecked() {
    const pageSelection = this.elements.elements.filter(candidate => this.isChecked(candidate));
    return pageSelection.length === this.elements.elements.length;
  }

  rescueCandidates() {
    const candidatesToRescue = this.selectedCandidates.filter(candidate => candidate.status === 'KO');
    candidatesToRescue.forEach(candidate => {
      this.componentSubscriptions.candidateServiceChangeStatus$ = this.candidateService.changeStatus(candidate, Status.PASS)
        .subscribe(
          response => {
            candidate.status = response.status;
            this.alertService.setAlert({
              type: 'success', message: `${candidatesToRescue.length}
            candidate${candidatesToRescue.length > 1 ? 's' : ''} rescued correctly`
            });
          },
          error => {
            this.alertService.setAlert(this.alertService.formatErrorMessage(error));
          }
        );
    });
  }

  moveCandidates() {
    const candidatesToMove = this.selectedCandidates.filter(candidate => candidate.status === Status.PASS.value)
      .map(candidate => +candidate.id);
    if (candidatesToMove.length > 0) {
      this.componentSubscriptions.candidateServiceMoveCandidates$ = this.candidateService.moveCandidates(candidatesToMove, this.job.id)
        .subscribe(
          response => {
            this.updateTable();
            this.updateFunnel();
            this.selectedCandidates = [];
            this.alertService.setAlert({
              type: 'success', message: `${candidatesToMove.length}
            candidate${candidatesToMove.length > 1 ? 's' : ''} moved correctly`
            });
          },
          error => {
            this.alertService.setAlert(this.alertService.formatErrorMessage(error));
          });
    }
  }

  updateTable() {
    this.loadingTable = true;
    this.componentSubscriptions.candidateServiceGetCandidatesFromJobUpdateTable$ =
      this.candidateService.getCandidatesFromJob(this.job_id, this.itemSelected, ...this.parameters)
        .pipe(take(1))
        .subscribe(
          candidates => {
            this.saveCandidatesToTable(candidates);
            this.loadingTable = false;
          });
  }

  updateComponent() {
    this.updateFunnel();
    this.updateTable();
  }

  handleAction(action: string) {
    switch (action) {
      case Candidate.ACTIONS.CHANGE_STATUS:
      case Candidate.ACTIONS.TASK:
        this.updateTable();
        break;
      case Candidate.ACTIONS.SHARE:
        this.modalRef = this.modalService.show(this.viewerModal, { class: '', backdrop: 'static' });
        break;
      case Candidate.ACTIONS.EMAIL:
        this.modalRef = this.modalService.show(this.sendMailModal, { class: '', backdrop: 'static' });
        break;
      default:
        this.modalRef = this.modalService.show(this.confirmationModal, { class: 'prevent-dialog', backdrop: 'static' });
        break;
    }
  }

  addVar(button) {
    // tslint:disable-next-line: forin
    for (const instance in CKEDITOR.instances) {
      CKEDITOR.instances[ instance ].insertText(button.value);
    }
  }

  setEmailTemplate(email_key) {
    const { subject, content } = this.job.emails.OTHERS[ email_key ];
    this.email.subject = subject;
    this.email.content = content;
    this.disableSend = this.email.content.length > 0 ? false : true;
  }

  async sendEmail() {
    const sendEmailObservables: Observable<any>[] = this.selectedCandidates.map(candidate =>
      this.candidateService.sendEmail(candidate, this.email));

    this.sendingEmail = true;
    await this.transformImageUrlToBase64(this.email.content).then(() => {
      this.componentSubscriptions.forkJoinSendMail$ = forkJoin(sendEmailObservables)
        .subscribe(
          response => {
            this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__emailSentSuccesfully'));
            this.modalRef.hide();
            this.sendingEmail = false;
            //clear email
            this.email.subject = '';
            this.email.content = '';
          },
          error => {
            this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, '__errorSendingEmail'));
            this.modalRef.hide();
            console.log(error);
            this.sendingEmail = false;
          }
        );
    });
  }

  uploadImage(event) {
    event.target.files[ 0 ].size < 1_000_000 ?
      getBase64FromFile(event.target.files[ 0 ]).then(
        base64Image => {
          // incluir img en el email
          for (const instance in CKEDITOR.instances) {
            CKEDITOR.instances[ instance ].insertHtml(`<img alt="" src="${base64Image}" />`);
          }
        }
      )
      :
      this.insertSizeError();
  }

  emitEmailContentChange($event) {
    this.editorContent$.next($event);
  }

  subcribeToEditorContentDebounce() {
    this.componentSubscriptions.editorContent$ = this.editorContent$.pipe(
      debounceTime(500)
    ).subscribe(
      editorContent => {
        this.transformImageUrlToBase64(editorContent);
      });
  }
  subcribeToEditorContent() {
    this.componentSubscriptions.editorContent$ = this.editorContent$.pipe(
      throttleTime(1000)
    ).subscribe(
      editorContent => {
        if (editorContent) {
          const contentSizeInfo = calculateContentSize(editorContent);
          this.editorContentSize = contentSizeInfo.size;
          this.disableSend = contentSizeInfo.exeedsMaxSize;
        }
      });
  }

  async transformImageUrlToBase64(editorContent) {
    //Regex buscar img con srg url
    const regex = /<img\s.+src="((?!data:)[^"]+)"/m;
    const matches = editorContent.match(regex);
    const url = matches && matches[ 1 ];
    //si url
    if (url) {
      this.loadingImages = true;
      this.componentSubscriptions.base64Image$ = this.candidateService.getImageAsBase64(url).subscribe(
        base64Image => {
          this.email.content = editorContent.replace(`src="${url}"`, `src="${splitStringInLines(base64Image.dataUrl)}"`);
          this.loadingImages = false;
          this.editorContent$.next(this.email.content);
        },
        error => {

          /* ERRORS
            { "code": 0, "message": "Unespecified error." }
            { "code": 1, "message": "The resource exceeds the maximum file size." }
            { "code": 2, "message": "The resource is not an image." }

            We only control the size one, for the other two we use a generic error
          */
          error.error.error.code === '1'
            ?
            this.insertSizeError(false, editorContent, url)
            :
            this.insertGenericError(editorContent, url);
          this.loadingImages = false;
        });

    }
  }

  insertSizeError(localImage = true, editorContent?, url?) {

    const languageKey = this.getLanguageKey();
    //For local image there is no img tag to replase, so we insert it, otherwise we just replace the src with the error
    if (localImage) {
      // tslint:disable-next-line:forin
      for (const instance in CKEDITOR.instances) {
        CKEDITOR.instances[ instance ].insertHtml(`<img alt="" src="${SIZE_ERROR[ languageKey ]}" style="height:auto; width:600px"/>`);
      }
    } else {
      this.email.content = editorContent.replace(`src="${url}"`, `src="${SIZE_ERROR[ languageKey ]}" style="height:auto; width:600px"`);
    }
  }

  insertGenericError(editorContent, url) {
    const languageKey = this.getLanguageKey();
    this.email.content = editorContent.replace(`src="${url}"`, `src="${LOAD_IMAGE_ERROR[ languageKey ]}" style="height:auto; width:600px"`);
    this.loadingImages = false;
  }

  getLanguageKey() {
    const browserLanguage = this.clientTranslationService.getNavigatorLanguage();

    switch (browserLanguage) {
      case 'de':
      case 'el':
      case 'en':
      case 'es':
      case 'fr':
      case 'it':
        return browserLanguage;

      case 'pt':
      case 'pt-br':
        return 'pt';

      default:
        return 'en';
    }
  }

  showCandidateTooltip(event: any, tooltip_class: string = '.candidate-tooltip') {
    const tooltip = jQuery(event.target).parent().parent().parent().find(tooltip_class)[ 0 ];
    const TOP_OFFSET = 30;
    tooltip.style.position = 'fixed';
    tooltip.style.display = 'block';
    tooltip.style.top = (jQuery(event.target).offset().top + TOP_OFFSET) + 'px';
    tooltip.style.left = (jQuery(event.target).offset().left) + 'px';
  }

  hideCandidateTooltip(event: any, tooltip_class: string = '.candidate-tooltip') {
    const tooltip = jQuery(event.target).parent().parent().parent().find(tooltip_class)[ 0 ];
    tooltip.style.display = 'none';
  }

  getInterviewCase(candidate: Candidate, phase: PhaseExtraColumn) {
    candidate.phases_interviews[ phase.phase ] = candidate.phases_interviews[ phase.phase ] || {};
    return candidate.phases_interviews[ phase.phase ][ phase.slug ];
  }

  getInstrumentsTemplate(candidate: Candidate) {
    return `${candidate.instruments_done} / ${candidate.instruments_total}`;
  }

  getVidassessTemplate(candidate: Candidate, phase: PhaseExtraColumn) {
    if (candidate.phases_interviews[ phase.phase ]) {
      const score = candidate.phases_interviews[ phase.phase ][ phase.title ];
      return score !== 0 ? score : `<i class="fas fa-minus"></i>`;
    } else {
      return `<i class="fas fa-minus"></i>`;
    }
  }

  getFilterLabel(filter: CandidateFilters): CandidateFilters[ 'values' ] {
    const filteredParameter = this.parameters.find(parametro => parametro.name === filter.name).value as string[];
    return filter.values.filter(valores => filteredParameter.includes(valores.value));
  }

  trackByIndex(index: number): number {
    return index;
  }


  scrollTop() {
    setTimeout(() =>
      document.getElementById('pageTop').scrollTop = 0
      , 0);
  }


  filterLabelTransform(label: string) {
    return this.translationService.instant(label) + ': ';
  }

  getTagColor(tagId: number): TAG_COLORS {
    return this.availableTags.find(tag => tag.id === tagId).color;
  }

  selectAllCandidates() {
    this.candidateService.getAllCandidates(this.job_id, ...this.parameters).subscribe(
      response => {
        const allCandidatesArray = response.body.map(d => {
          return {
            uuid: d
          };
        });

        this.selectedCandidates = allCandidatesArray;
        this.total = allCandidatesArray.length;
      },
      error => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error));
      });
    ;

  }

  unselectAllCandidates() {
    this.selectedCandidates = [];
  }
}

