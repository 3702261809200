import { Filter } from '../../../library/classes/filter';
import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { INDUSTRIES } from '../../constants';
import { JobCategory, Job } from '../../../classes/job';
import { setFilter } from '../../../library/utils';
import { Client, ClientLite } from '../../models/client';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-partner-analytics-filters',
  templateUrl: './partner-analytics-filters.component.html',
  styleUrls: ['./partner-analytics-filters.component.scss']
})
export class PartnerAnalyticsFiltersComponent implements OnInit, OnDestroy {

  @Input() partner: number;
  @Input() closeFilters = true;

  @Output() appliedFilters = new EventEmitter<Filter[]>();
  @Output() closeFiltersChange: EventEmitter<Boolean> = new EventEmitter();

  statusSelected: string = '';
  privacySelected: string = '';

  parameters: Filter[] = [];
  parametersGetClients: Filter[] = [];

  filters: any[] = [];
  clients: ClientLite[] = [];

  jobTypes: Array<any> = [
    { value: Job.JOB_PUB, label: Job.TEXT_PUB },
    { value: Job.JOB_PRI, label: Job.TEXT_PRI },
    { value: Job.JOB_BTH, label: '__both' }
  ];

  jobStatus: Array<any> = [
    { value: Job.ACTIVE, label: '__active' },
    { value: Job.CLOSED, label: '__closed' },
    { value: Job.EXPIRED, label: '__expired' }
  ];



  componentSubscriptions: any = {};

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.parametersGetClients = setFilter(this.parameters, new Filter('partners', this.partner));
    this.getClients();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getClients() {
    this.componentSubscriptions.getClientListLite$ = this.clientService
      .getClientlistLite(new Filter('partner', this.partner))
      .subscribe(
        clients => {
          this.clients = clients;
          this.buildFilters();
        },
        error => this.buildFilters()
      );
  }

  buildFilters() {

    const singleIndustriesKeys: string[] = Array.from(new Set(this.clients.map(client => client.industry)));

    const industriesValues: any[] = singleIndustriesKeys.map(industry => {
      return {
        value: industry,
        label: '__' + industry
      };
    });

    const clientsValues: any[] = this.clients.map(client => {
      return {
        value: client.pk,
        label: client.name
      };
    });

    this.filters = [
      { name: 'clients', label: '__clients', group: 'general', values: clientsValues },
      { name: 'industry', label: '__industry', group: 'general', values: industriesValues },
      { name: 'privacy', label: '__jobType', group: 'general', values: this.jobTypes },
      { name: 'status', label: '__jobStatus', group: 'general', values: this.jobStatus },
    ];
  }

  setStatusFilter(status) {
    this.statusSelected = status;
    this.parameters = setFilter(this.parameters, new Filter('status', status));
  }

  setPrivacyFilter(privacy) {
    this.privacySelected = privacy;
    this.parameters = setFilter(this.parameters, new Filter('privacy', privacy));
  }

  getFilterValue(filter) {
    const filterIsSelected = this.parameters.find(param => param.name === filter.name);
    return filterIsSelected ? filterIsSelected.value : null;
  }

  setFilterValue(filter, values) {
    if (values) {
      this.parameters = setFilter(this.parameters, new Filter(filter.name, values));
    }
  }

  applyFilters() {
    this.changeCloseFilters();
    this.appliedFilters.emit(this.parameters);
  }

  resetFilters() {
    this.parameters = [];
    this.statusSelected = '';
    this.privacySelected = '';
    this.applyFilters();
  }

  changeCloseFilters() {
    this.closeFilters = !this.closeFilters;
    this.emitcloseFilters();
  }

  emitcloseFilters() {
    this.closeFiltersChange.emit(this.closeFilters);
  }

}
