import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { COLOR_VARIABLES } from '../../utils/color-variables';
declare const Highcharts: any

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() data: { color: string, name: string, data: number }[];
  @Input() name: string;

  config = {
    chart: {
      type: 'line'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    colors: COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT'],
    xAxis: {
      categories: []
    },
    yAxis: {
      title: {
        text: 'Total'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: true
      }
    },
    series: []
  };

  constructor(private hostElement: ElementRef) { }

  ngOnInit() {
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.line-chart__content'), this.config);
  }

  _setData() {
    this.config.xAxis.categories = this.data.map(value => value.name);
    this.config.series.push({ name: this.name, data: this.data.map(value => value.data) });
    this.config.title.text = this.name;
  }
}
