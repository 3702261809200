import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import { merge, fromEvent } from 'rxjs';
import { Subscription } from 'rxjs';
import { LayoutService } from './../../../services/layout.service';
import { Alert, AlertsService, ALERT_TYPES } from './../../../services/alerts.service';
import { TagsService } from './../../../services/tag.service';
import { TagsControlService } from './../../services/tags-control.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Tag } from './../../../classes/tag';
import { Component, Input, OnInit, ViewChild, ElementRef, OnChanges, AfterViewChecked, DoCheck, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-applied-tags-display',
  templateUrl: './applied-tags-display.component.html',
  styleUrls: ['./applied-tags-display.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(150, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class AppliedTagsDisplayComponent implements OnInit, AfterViewChecked, OnDestroy {

  @Input() candidatesIds: number[] = [];

  _tags: Tag[] = [];

  @Input() set tags(tags: Tag[]) {
    this._tags = Tag.getSortedTags(tags);
  }

  get tags(): Tag[] {
    return this._tags;
  }

  @Input() readonly = false;

  @Output() tagsChange: EventEmitter<Tag[]> = new EventEmitter();

  oldTagsLength = 0;
  lastVisibleTag = 0;

  removingTagsIds: number[] = [];

  showTagManager = false;

  @ViewChild('tagsWrapper', { static: true }) tagsWrapper: ElementRef;

  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private tagsControlService: TagsControlService,
    private tagsService: TagsService,
    private alertsService: AlertsService,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.subscribeToCandidateActionClosing();
    this.subscribeToWindowResize();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  ngAfterViewChecked() {
    if (this.oldTagsLength !== this.tags.length) {
      this.oldTagsLength = this.tags.length;
      this.setLastVisibleTag(0);
    }
  }

  subscribeToCandidateActionClosing() {
    this.componentSubscriptions.candidateActionOpen$ = this.layoutService.jobDashboardSubjects.candidateActionOpen.subscribe(
      response => {
        this.setLastVisibleTag(400);
      }
    );
  }

  setLastVisibleTag(time: number) {
    setTimeout(
      () => {

        const wrapperWidth = this.tagsWrapper.nativeElement.offsetWidth;
        let tagsWidth = 0;
        let lastTag = this.tags.length;

        Array.from(this.tagsWrapper.nativeElement.children).every(
          (tag: any, i: number) => {
            tagsWidth = tagsWidth + tag.offsetWidth + 10;

            if (tagsWidth > wrapperWidth) {
              lastTag = i;
              return false;
            } else {
              return true;
            }
          }
        );

        this.lastVisibleTag = lastTag;
      },
      time
    );
  }

  subscribeToWindowResize() {
    merge(
      fromEvent(window, 'resize').pipe(
        map(event => (event.target as Window).innerWidth)
      )
    ).pipe(
      distinctUntilChanged(),
      debounceTime(300),
    ).subscribe(
      value => this.setLastVisibleTag(400)
    );
  }

  removeTags(tags: Tag[]) {
    const tagsIds = tags.map(tag => +tag.id);

    this.removingTagsIds = [...this.removingTagsIds, ...tagsIds];

    this.tagsService.removeTagsFromCandidates(this.tagsControlService.getClientUuid(), this.candidatesIds, tagsIds).subscribe(
      res => {
        this.tags = this.tags.filter(tag => !tagsIds.includes(tag.id));
        this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__tagRemovedSuccessfully'));
        this.emitTagsChange();
      },
      error => this.alertsService.setAlert(null, error),
      () => this.removingTagsIds = this.removingTagsIds.filter(id => !tagsIds.includes(id))
    );
  }

  emitTagsChange() {
    this.tagsChange.emit(this.tags);
  }

}
