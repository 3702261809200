import { Filter } from './classes/filter';
import { ActivatedRoute } from '@angular/router';
import { AlertsService, Alert, ALERT_TYPES } from "../services/alerts.service";

import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export function checkIdFromFilter(...filters: Filter[]): boolean {
  return Boolean(filters.find(filter => filter.name === 'id'));
}

export function buildFilter(...filters: Filter[]): string {
  const serializeFilters = _deestructureArrayFilters(filters);
  const query = serializeFilters.length > 0 ? `?${serializeFilters.filter(filter => filter.value)
    .map(filter => `${filter.name}=${filter.value}`).join('&')}` : '';

  return query === '?' ? '' : query;
}

export function setFilter(parameters: Filter[],
  filter: Filter): Filter[] {
  const element = parameters.find(parameter => parameter.name === filter.name);
  const newParameters = parameters.slice();
  if (element) {
    element.value = filter.value;
  } else {
    newParameters.push(filter);
  }
  return newParameters;
}

export function deleteFilter(parameters: Filter[],
  filterName: string): Filter[] {
  const element = parameters.find(parameter => parameter.name === filterName);
  const newParameters = parameters.slice();
  if (element) {
    newParameters.splice(newParameters.indexOf(element), 1);
  }
  return newParameters;
}

export function _deestructureArrayFilters(filters: Filter[]): Filter[] {
  let totalFilter = [];
  filters.filter(a => a != null).forEach(filter => { totalFilter = totalFilter.concat(filter.getFilterValues()) });
  return totalFilter;
}

export function removeSpacesAndReplaceNonAsciiChars(text: string) {
  return text.toLowerCase()
    .replace(/\s+/g, '') // Remove spaces
    .replace(/[àáâãäå]/g, "a")
    .replace(/[èéêë]/g, "e")
    .replace(/[ìíîï]/g, "i")
    .replace(/[òóôõöø]/g, "o")
    .replace(/[ùúûü]/g, "u")
    .replace(/[æ]/g, "ae")
    .replace(/[œ]/g, "oe")
    .replace(/[ß]/g, "ss")
    .replace(/[š]/g, "s")
    .replace(/[ž]/g, "z")
    .replace(/[ç]/g, "c")
    .replace(/[ñ]/g, "n")
    .replace(/[ð]/g, "th")
    .replace(/[ýþÿ]/g, "y")
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
}

export function slugify(text: string) {
  return text.toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text;
}

export function unSlugify(text: string) {
  return text.toString()
    .toLowerCase()
    .replace(/-/g, ' ') // Replace spaces with -
    .replace(/_/g, ' ') // Remove all non-word chars
    .replace(/  /g, ' ') // Remove all non-word chars
}

export function doSearch(search$: Subject<string>): Observable<any> {
  return search$.pipe(debounceTime(300), distinctUntilChanged());
}

export function sortArrayOfObjectsBy(arrayOfObjects: any[], sorter: string, order: number) {
  const compare = (a, b) => {
    const nameA = a[sorter].toLowerCase();
    const nameB = b[sorter].toLowerCase();
    if (nameA < nameB) { return (-1 * order) }
    if (nameA > nameB) { return (1 * order) }
    return 0;
  }
  return arrayOfObjects.sort(compare);
}

export function stringToCamelCase(text: string): string {
  return text.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
  })
    .replace(/\s+/g, '');
}


export const buildQueryParams = (param: { name: string, value: string }, relative?: ActivatedRoute) => {
  const queryParams = { relativeTo: relative, queryParams: {} };
  queryParams.queryParams[param.name] = param.value;
  return queryParams;
};

export function hexToRgbA(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', .15)';
  }
  throw new Error('Bad Hex');
}

export function normalizeString(str: string): string {
  return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function copyTextToClipboard(element, alertText) {
  const url_copied = alertText ? this.translateService.instant(alertText) : this.translateService.instant('__urlCopied')
  element.select();
  document.execCommand('copy');
  element.setSelectionRange(0, 0);
  this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, url_copied));
}
