export class Role {
  public static HQ = 0;
  public static PARTNER_ADMIN = 100;
  public static PARTNER_MANAGER = 200;
  public static CLIENT_ADMIN = 300;
  public static CLIENT_RECRUITER = 400;
  public static CLIENT_EVALUATOR = 450;
  public static CLIENT_HIRING_MANAGER = 500;
  public static CLIENT_BUSSINESS = 600;
  public static CLIENT_VIEWER = 700;
}

export class RolesDesc {
    clientRoles = {
        '300': '__roleDescAdmin',
        '400': '__roleDescRecruiters',
        '450': '__roleDescEvaluators',
        '500': '__roleDescHiringManager',
        '600': '__roleDescBusiness',
        '700': '__roleDescViewer'
    };
  }
