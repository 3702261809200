import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { CandidateService } from '../../services/candidate.service';
import { CandidateAppliedJob } from '../../classes/candidate';
import { Status, ShortPhasesLabels } from '../../classes/phases';
import { ClientService } from '../../partners/services/client.service';

@Component({
  selector: 'app-job-filters',
  templateUrl: './job-filters.component.html',
  styleUrls: ['./job-filters.component.scss']
})
export class JobFiltersComponent implements OnInit, OnDestroy {


  style: any = {};
  // @Input() jobsApplied: number;
  // @Input() candidateId: number;
  @Input() templatesFilter: boolean;
  @Input() client: number;

  templatesList: any[];

  loading: boolean = false;

  componentSubscriptions: any = {};

  constructor(private elementRef: ElementRef, private clientService: ClientService) { }

  ngOnInit() { }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  showPophover(event: any) {

    this.loading = true;

    this.style.position = 'absolute';
    this.style.display = 'block';

    if (this.templatesFilter) {
      this.getTemplatesList();
    }

    this.style.top = '25px';
    this.style.left = '70px';
    // this.candidateService.getCandidateAppliedJobs(this.candidateId.toString())
    //   .subscribe(

    //     cand => {
    //       if (this.isATemplate()) {
    //         this.separateByProfession(cand);
    //       } else {
    //         this.jobsAppliedInfo = cand;
    //       }
    //     }
    //   )
  }

  hidePophover() {
    this.style.display = 'none';
  }

  getTemplatesList() {
    this.componentSubscriptions.clientServiceGetProfessionsAndJobsByClient$ = this.clientService.getProfessionsAndJobsByClient(this.client).subscribe(

      data => {
        this.templatesList = data.body.professions;
      }
    )
  }
}
