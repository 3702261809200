import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export enum ALERT_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'

}

export class Alert {
  type: string;
  message: string;

  constructor(type: string = '', message: string = '') {
    this.type = type;
    this.message = message;
  }
}

@Injectable()
export class AlertsService {

  constructor(private translateService: TranslateService) { }

  private showAlertSource = new Subject<Alert>();

  showAlert$ = this.showAlertSource.asObservable();

  setAlert(alertData = new Alert(), error?: any) {
    if (error) {
      switch (error.status) {
        case 0:
        case 404:
        case 500:
        case 501:
        case 502:
          alertData = this.formatErrorMessage(error);
          alertData.message = '__backendErrorMessage';
          break;
        default:
          error.error && Object.entries(error.error).length && Object.entries(error.error)[0].pop().toString()
            ? this.showAlertSource.next(this.formatErrorMessage(error))
            : (alertData = this.formatErrorMessage(error),
              alertData.message = '__backendErrorMessage');
      }
    }

    this.showAlertSource.next(alertData);
  }

  formatErrorMessage(error): Alert {
    return new Alert(ALERT_TYPES.ERROR, Object.entries(error.error)[0].join(': '));
  }


}
