import { TagsControlService } from './../../tags/services/tags-control.service';
import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user-service.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Job } from '../../classes/job';
import { LayoutService, LayoutActions } from '../../services/layout.service';
import { ClientService } from '../../partners/services/client.service';
import { Client } from '../../partners/models/client';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss']
})
export class SidebarHeaderComponent implements OnInit, OnDestroy {

  _client: Client;
  @Input() set client(client: Client) {
    this._client = client;
    this.tagsControlService.setNewGroup(client);
  }

  get client(): Client {
    return this._client;
  }

  @Input() buttonCollapse?= false;
  @Input() total = 0;

  @Output() collapasedSidebar = new EventEmitter<boolean>();
  @Output() jobAction: EventEmitter<{ type: string, job: Job }> = new EventEmitter();
  @Output() currentLocationChange = new EventEmitter<string>();
  @ViewChild('partnersMenu', { static: false }) partnersMenu: ElementRef;
  @ViewChild('collapsableElems', { static: true }) collapsableElemsBox: ElementRef;


  currentLocation = '';

  layoutActions = LayoutActions;

  collapsableElems: { logo: HTMLElement, client: HTMLElement } = { logo: null, client: null };

  sidebarCollapsed = false;
  headerCollapsed = false;

  componentSubscriptions: any = {};

  constructor(
    public userService: UserService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public clientService: ClientService,
    public layoutService: LayoutService,
    private renderer: Renderer2,
    private tagsControlService: TagsControlService
  ) {

    this.componentSubscriptions.layoutServiceJobDashboardSubjectsJobsSidebarCollapsed$ = this.layoutService.jobDashboardSubjects.jobsSidebarCollapsed.subscribe(data => this.sidebarCollapsed = data);

  }

  ngOnInit() {
    this.getCollapsableElems();
    this.changeCurrentLocationOnSideBar();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getCollapsableElems() {
    this.componentSubscriptions.layoutServiceJobDashboardSubjectsJobsSidebarCollapsedGet$ = this.layoutService.jobDashboardSubjects.jobsSidebarCollapsed.subscribe(status => {
      if (!status) {
        setTimeout(() => {
          this.collapsableElems.logo = this.collapsableElemsBox.nativeElement.children[0] as HTMLElement;
          this.collapsableElems.client = this.collapsableElemsBox.nativeElement.children[1] as HTMLElement;
        }, 500);
      }
    });
  }

  isJobsPath() {
    return this.currentLocation.includes('jobs');
  }

  isCandidatesPoolPath() {
    return this.currentLocation.includes('canPool');
  }

  changeCurrentLocationOnSideBar() {

    this.currentLocation = this.getCurrentMenuLocation(this.activatedRoute.snapshot);

    this.componentSubscriptions.routerEventsFilter$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.currentLocation = this.getCurrentMenuLocation(this.activatedRoute.snapshot);
      this.emitCurrentLocation(this.currentLocation);
    });

    this.emitCurrentLocation(this.currentLocation);

  }

  emitCurrentLocation(location: string) {
    this.currentLocationChange.emit(location);
  }

  getCurrentMenuLocation(routeSnapshot): string {
    if ('data' in (routeSnapshot.firstChild || {}) && 'menuLocation' in routeSnapshot.firstChild.data) {
      return routeSnapshot.firstChild.data.menuLocation || '';
    } else {
      return 'menuLocation' in routeSnapshot.data ? routeSnapshot.data.menuLocation : '';
    }
  }

  createJob() {
    this.jobAction.emit({ type: Job.CREATE_ACTION, job: null });
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['login']);
  }

  collapseHeader() {

    if (!this.sidebarCollapsed) {

      this.renderer.setStyle(this.collapsableElemsBox.nativeElement, 'padding', '15px 0');
      this.renderer.setStyle(this.partnersMenu.nativeElement, 'display', 'none');
      this.renderer.setStyle(this.collapsableElems.logo, 'maxWidth', '75px');
      this.renderer.setStyle(this.collapsableElems.client, 'display', 'none');

      this.headerCollapsed = true;

    }

  }

  normalizeHeader() {

    if (!this.sidebarCollapsed) {

      this.renderer.setStyle(this.collapsableElemsBox.nativeElement, 'padding', '50px 0');
      this.renderer.setStyle(this.partnersMenu.nativeElement, 'display', 'flex');
      this.renderer.setStyle(this.collapsableElems.logo, 'maxWidth', '150px');
      this.renderer.setStyle(this.collapsableElems.client, 'display', 'block');

      this.headerCollapsed = false;

    }

  }

  toggleSidebar() {

    this.layoutService.handleLayoutServiceAction(this.layoutActions.TOGGLE_JOBS_SIDEBAR);

    if (this.headerCollapsed) {
      setTimeout(() => {
        this.normalizeHeader();
      }, 500);
    }

  }

}
