import { COLOR_VARIABLES } from './../../utils/color-variables';
import { Component, OnInit, ElementRef, Input } from '@angular/core';
declare const Highcharts: any;

@Component({
  selector: 'app-semi-circle-chart',
  templateUrl: './semi-circle-chart.component.html',
  styleUrls: ['./semi-circle-chart.component.scss']
})
export class SemiCircleChartComponent implements OnInit {

  @Input() data;

  config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },
    title: null,
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    colors: [COLOR_VARIABLES['$_AUX_PURPLE_15'], COLOR_VARIABLES['$_AUX_GREY_12'], COLOR_VARIABLES['$_CHART_LIGHT_PURPLE'], COLOR_VARIABLES['$_TOOLBAR_COLOR']],
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -40,
          style: {
            fontWeight: 'bold',
            color: 'white',
            fontSize: '11px'
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '90%'],
        size: '160%'
      }
    },
    series: [{
      type: 'pie',
      innerSize: '30%',
      data: []
    }]
  };

  constructor(
    private hostElement: ElementRef
  ) { }

  ngOnInit() {
    this.setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.semi-circle-chart'), this.config);
  }

  setData() {
    const seriesData = this.data.map(companyData => {
      return [companyData.company, companyData.index]
    })
    this.config.series[0].data = seriesData
  }

}
