import { Component, OnInit, Input } from '@angular/core';
import { Interview } from '../../classes/interview';

@Component({
  selector: 'app-candidate-interview-vidassess',
  templateUrl: './candidate-interview-vidassess.component.html',
  styleUrls: ['./candidate-interview-vidassess.component.scss']
})
export class CandidateInterviewVidassessComponent implements OnInit {

  @Input() report: any;

  constructor() { }

  ngOnInit() {
    this.report = Object.values(this.report)[0];
  }

}
