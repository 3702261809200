import { JobsStatus } from './../../../classes/client-analytics';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ClientAnalyticsService } from './../../../services/client-analytics.service';

import { Filter } from './../../../library/classes/filter';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-partner-analytics-top-jobs',
  templateUrl: './partner-analytics-top-jobs.component.html',
  styleUrls: ['./partner-analytics-top-jobs.component.scss']
})
export class PartnerAnalyticsTopJobsComponent implements OnInit, OnDestroy {


  _groupId: number;
  _parameters: Filter[];


  @Input() mode: 'CANDIDATES' | 'VISITS' = 'CANDIDATES';

  @Input() set parameters(parameters: Filter[]) {
    this._parameters = parameters;
    this.getTopJobs();
  }

  get parameters(): Filter[] {
    return this._parameters;
  }

  loading = false;

  topJobsByCandidate: { name: string, candidates: number }[] = [];
  topJobs: { client_name: string, total: number, job_name: string }[] = [];

  jobsStatus: JobsStatus = new JobsStatus();

  totalOpenedClosedJobs = 0;

  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private clientAnalyticsService: ClientAnalyticsService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getTopJobs() {
    setTimeout(() => this.loading = true, 0);

    this.componentSubscriptions.topJobsCandidates$ = this._getTopJobs$().subscribe(
      data => setTimeout(() => this.serializeTopJobs(data), 300),
      error => this.loading = false
    );
  }

  serializeTopJobs(data: { client_name: string, total: number, job_name: string }[]) {
    this.topJobs = data.sort((a, b) => b.total - a.total);
    setTimeout(() => this.loading = false, 0);
  }

  _getTopJobs$(): Observable<{ client_name: string, total: number, job_name: string }[]> {
    switch (this.mode) {
      case 'CANDIDATES':
        return this.clientAnalyticsService.getGroupTopJobsByCandidates(...this.parameters);

      case 'VISITS':
        return this.clientAnalyticsService.getGroupTopJobsByVisits(...this.parameters);
    }
  }
}
