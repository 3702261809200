import { environment } from '../../environments/environment';

export class Constants {
  static BASE_URL = environment.BASE_URL;
  static BASE_API: string = Constants.BASE_URL.concat('api/v1/');

  static USERS_URL: string = Constants.BASE_API + 'users/';
  static GROUP_URL: string = Constants.BASE_API + 'groups/';

  static PARTNER_LIST: string = Constants.BASE_API + 'groups/partners/';

  static CLIENT_LIST: string = Constants.BASE_API + 'groups/clients/';
  static PARTNER_CLIENT_LIST: string = Constants.BASE_API + 'groups/clients/';
  static PARTNER_CLIENT_LIST_LITE: string = Constants.BASE_API + 'groups/clients-lite/';

  static REFRESH_URL: string = Constants.BASE_API + 'jwt-refresh/';
  static VERIFY_URL: string = Constants.BASE_API + 'jwt-verify/';

  static LOGOUT_URL: string = Constants.BASE_API + 'logout/';
  static LOGIN_URL: string = Constants.BASE_API + 'login/';
  static VERIFY_CODE_URL: string = Constants.BASE_API + 'verify-code/';

  static USER_URL: string = Constants.BASE_API + 'user/';

  static SUBMIT_FILE: string = Constants.BASE_API + 'upload/';
  static SUBMIT_PUBLIC_FILE: string = Constants.BASE_API + 'public-upload/';



  static FILE_URL: string = Constants.BASE_API + 'file/';

  static ADD_USER_TO_GROUP_URL: string = Constants.GROUP_URL + '{id}/add-users/';
  static PUBLIC_CLIENT_URL: string = Constants.GROUP_URL + 'public/{uuid}/';
  static CLIENT_HUB_URL: string = Constants.GROUP_URL + '{uuid}/jobs-by-profession/';
  static CLIENT_HUB_PRIVATE_URL: string = Constants.GROUP_URL + '{uuid}/jobs-by-profession/private/';
  static PROFESSIONS_LIST_BY_GROUP: string = Constants.GROUP_URL + '{id}/professions';
  static PROFESSIONS_TINY: string = Constants.BASE_API + 'professions/tiny/';
  static CLIENT_ACTIVE_JOBS_COUNT: string = Constants.GROUP_URL + '{id}/active-jobs/';

  static RESET_PASSWORD: string = Constants.BASE_API + 'password/reset/';
  static CONFIRM_NEW_PASSWORD: string = Constants.BASE_API + 'password/reset/confirm/';

  static JOB_URL: string = Constants.BASE_API + 'jobs/';
  static JOB_INFO_URL: string = Constants.BASE_API + 'jobs/{id}/info/';
  static PROFESSION_URL: string = Constants.BASE_API + 'professions/';
  static CANDIDATE_URL: string = Constants.JOB_URL + 'candidates/{uuid}/';
  static ALL_CANDIDATES_URL: string = Constants.BASE_API + 'candidates/';
  static CANDIDATE_APPLIED_JOBS: string = Constants.BASE_API + 'candidates/{id}/applied-job/';
  static JOB_CANDIDATE_URL: string = Constants.JOB_URL + '{id}/candidates/';
  static JOB_ALL_CANDIDATES_URL: string = Constants.JOB_URL + '{id}/candidates-ids/';
  static JOB_CANDIDATES_FILTERS: string = Constants.JOB_URL + '{id}/candidates-filtrables/';
  static JOB_ANALYTICS_URL: string = Constants.BASE_API + 'job-analytics/{id}/';
  static JOB_PASS_CANDIDATE_URL: string = Constants.JOB_URL + '{id}/pass-candidates/';
  static JOB_DISABLE_URL: string = Constants.JOB_URL + '{id}/disable-job/';
  static JOB_CLOSE_URL: string = Constants.JOB_URL + '{id}/close-job/';
  static JOB_EXPORT_CSV: string = Constants.JOB_URL + '{id}/export-to-csv/';
  static CANDIDATE_CHANGE_STATUS_URL: string = Constants.BASE_API + 'candidates/change-status/{uuid}/';
  static CANDIDATES_CHANGE_STATUS_URL: string = Constants.JOB_URL + '{id}/candidates/change-status/';
  static JOB_LANDING_URL_URL: string = Constants.JOB_URL + '{id}/landing-url/';
  static JOB_MULTIPOSTING_CONFIG: string = Constants.JOB_URL + '{id}/multiposting-config/';
  static CANIDATES_VIEWERS_URL: string = Constants.BASE_API + 'candidates-viewers/group/{id}';
  static HIGHLIGHT_JOB: string = Constants.JOB_URL + '{id}/highlight/';

  static MULTIPOSTING_PROVIDER_FORM: string = Constants.BASE_API + 'multiposting-group-providers/{id}/jobs/{job-id}/form/';
  static MULTIPOSTING_JOBS: string = Constants.BASE_API + 'multiposting-jobs/';
  static MULTIPOSTING_JOBS_DETAIL: string = Constants.BASE_API + 'multiposting-jobs/{id}/';
  static MULTIPOSTING_REQUESTS: string = Constants.BASE_API + 'multiposting-requests/';
  static MULTIPOSTING_REQUESTS_RETRY: string = Constants.BASE_API + 'multiposting-requests/{id}/retry/';
  static MULTIPOSTING_REQUESTS_JOB: string = Constants.MULTIPOSTING_REQUESTS + 'job/{id}/';

  static SUBSCRIPTIONS: string = Constants.GROUP_URL + '{uuid}/subscription/';
  static UNSUBSCRIBE: string = Constants.GROUP_URL + 'unsubscribe/{uuid}/';
  static CLIENT_FROM_SUBSCRIPTION: string = Constants.GROUP_URL + 'appearance/subscription/{uuid}';

  static ADD_USERS_URL: string = Constants.JOB_URL + '{id}/assign-users/';
  static RATE_CANDIDATE = Constants.JOB_URL + 'rate-candidate/';

  static LANDING_BUILDER_CRUD_URL: string = Constants.BASE_API + 'templates/';
  static LANDING_BUILDER_URL: string = Constants.BASE_URL + 'builder/';
  static LANDING_PREVIEW_URL: string = Constants.BASE_URL + 'template/preview/{id}/?jwt={jwt}';

  static EMAIL_TEMPLATES: string = Constants.BASE_API + 'email_templates/';


  static JOB_CATEGORIES_URL: string = Constants.BASE_API + 'jobcategory/';
  static JOB_CATEGORY_URL: string = Constants.BASE_API + 'jobcategory/{id}/';
  static JOB_CATEGORIES_BY_CLIENT_UUID_URL: string = Constants.JOB_CATEGORIES_URL + 'groups/{uuid}/';

  static ADD_CATEGORIES_URL: string = Constants.USERS_URL + '{id}/add-categories/';
  static UPDATE_CATEGORIES_URL: string = Constants.USERS_URL + '{id}/update-categories/';
  static ADD_JOBS_URL: string = Constants.USERS_URL + '{id}/asign-jobs/';
  static USER_JOB_CATEGORIES: string = Constants.USERS_URL + '{id}/job-categories/';

  static USER_GROUP_NETWORK_CREDENTIALS_URL: string = Constants.BASE_API + 'multiposting-users/groups/{id}/';
  static CREATE_MULTIPOSTING_USER: string = Constants.BASE_API + 'multiposting-users/';
  static DELETE_MULTIPOSTING_USER: string = Constants.BASE_API + 'multiposting-users/{id}/';

  static GROUP_MULTIPOSTING_PROVIDER: string = Constants.GROUP_URL + '{id}/multiposting-providers/';

  static CANDIDATES_URL = Constants.BASE_API + 'candidates/';
  static INSTRUMENT_RESULT_URL = Constants.BASE_API + 'instrument-result/';

  static CANDIDATE_TIMELINE_URL: string = Constants.CANDIDATES_URL + '{id}/timeline/';
  static CANDIDATE_PUBLIC_TIMELINE_URL: string = Constants.CANDIDATES_URL + 'timeline/';
  static CANDIDATE_ADD_COMMENT: string = Constants.CANDIDATES_URL + '{uuid}/comment/';
  static COMMENT: string = Constants.BASE_API + 'candidates/comment/{id}/';
  static CANDIDATE_TASK: string = Constants.CANDIDATES_URL + '{uuid}/marks/';
  static CANDIDATE_SEND_EMAIL: string = Constants.CANDIDATES_URL + 'send-mail/{uuid}/';
  static CANDIDATE_SEND_POOL_EMAIL: string = Constants.CANDIDATES_URL + '{id}/send-pool-mail/';
  static CANDIDATE_SHARE: string = Constants.CANDIDATES_URL + 'get-share-token/?cid={id}';
  static CANDIDATE_PUBLIC: string = Constants.CANDIDATES_URL + 'public/{token}/';
  static CANDIDATE_MOVE_TRANSITION: string = Constants.CANDIDATES_URL + 'get-share-token/?token={uuid}';
  static CANDIDATE_EVALUATE_INTERVIEW: string = Constants.CANDIDATES_URL + '{uuid}/evaluate-interview/{iws}/';
  static CANDIDATE_HUB = Constants.CANDIDATES_URL + '{uuid}/hub-candidate/';
  static SETUP_URL_INSTRUMENT = Constants.INSTRUMENT_RESULT_URL + '{id}/setup_url/{language}/{privacy}';
  static ALL_VIEWERS_CANDIDATES: string = Constants.CANDIDATES_URL + 'all-client-viewers/{uuid}';
  static CREATE_VIEWERS_CANDIDATES: string = Constants.CANDIDATES_URL + 'create-viewers-to-candidate/{uuid}';
  static UNASSIGN_VIEWER_TO_CANDIDATE: string = Constants.CANDIDATES_URL + 'unassign-viewer-to-candidate/{uuid}';
  static ASSIGNED_VIEWERS_TO_CANDIDATE: string = Constants.CANDIDATES_URL + 'assigned-viewers/{uuid}';
  static DOWNLOAD_INSTRUMENT_REPORT = Constants.INSTRUMENT_RESULT_URL + 'get-instrument-report/';
  static SEND_FEEDBACK_REPORT = Constants.INSTRUMENT_RESULT_URL + 'send-feedback-report/';
  static COLLECT_GDPR_CONSENT: string = Constants.CANDIDATES_URL + '{uuid}/collect-gdpr-consent/';
  static SUBMIT_TO_CANDIDATES: string = Constants.CANDIDATES_URL + '{id}/upload-file/';


  static TAGS_URL: string = Constants.GROUP_URL + '{uuid}/tags/';
  static ASSIGN_TAGS_URL: string = Constants.CANDIDATES_URL + '{uuid}/tags/assign/';
  static UNASSIGN_TAGS_URL: string = Constants.CANDIDATES_URL + '{uuid}/tags/unassign/';


  static CLIENT_ANALYTICS_URL = Constants.BASE_API + 'client-analytics/';
  static CLIENT_ANALYTICS_FILTER = Constants.CLIENT_ANALYTICS_URL + '{id}/filter/';
  static CLIENT_ANALYTICS_JOBS_STATUS = Constants.CLIENT_ANALYTICS_URL + '{id}/status-jobs/';
  static CLIENT_ANALYTICS_CANDIDATES_STATUS = Constants.CLIENT_ANALYTICS_URL + '{id}/status-candidates/';
  static CLIENT_ANALYTICS_JOBS_SUMMARY = Constants.CLIENT_ANALYTICS_URL + '{id}/jobs-summary/';
  static CLIENT_ANALYTICS_PROFESSIONS_SUMMARY = Constants.CLIENT_ANALYTICS_URL + '{id}/professions-summary/';
  static CLIENT_ANALYTICS_CANDIDATES_SUMMARY = Constants.CLIENT_ANALYTICS_URL + '{id}/candidates-summary/';
  static CLIENT_ANALYTICS_HIRE_SUMMARY = Constants.CLIENT_ANALYTICS_URL + '{id}/hire-summary/';
  static CLIENT_ANALYTICS_MAIN_ANALYTICS = Constants.CLIENT_ANALYTICS_URL + '{id}/main-global-analytics/';
  static CLIENT_ANALYTICS_USER_BY_DATE = Constants.CLIENT_ANALYTICS_URL + '{id}/user-by-date/';
  static CLIENT_ANALYTICS_USER_BY_COUNTRY = Constants.CLIENT_ANALYTICS_URL + '{id}/user-by-country/';
  static CLIENT_ANALYTICS_JOBS_SITUATION_BY_CATEGORIES = Constants.CLIENT_ANALYTICS_URL + '{id}/jobs-situation-by-categories/';
  static CLIENT_ANALYTICS_CANDIDATES_PER_TYPE = Constants.CLIENT_ANALYTICS_URL + '{id}/candidates-per-type/';
  static CLIENT_ANALYTICS_CANDIDATES_PER_AGE_GENDER = Constants.CLIENT_ANALYTICS_URL + '{id}/candidates-per-age-and-gender/';
  static CLIENT_ANALYTICS_CANDIDATES_PER_CAMPAIGNS = Constants.CLIENT_ANALYTICS_URL + '{id}/candidates-per-campaigns/';
  static CLIENT_ANALYTICS_CANDIDATES_PER_INSTRUMENTS = Constants.CLIENT_ANALYTICS_URL + '{id}/candidates-per-instruments/';
  static CLIENT_ANALYTICS_ALL_INSTRUMENTS = Constants.CLIENT_ANALYTICS_URL + '{id}/results-per-instruments/';
  static CLIENT_ANALYTICS_CANDIDATES_PER_INTERVIEWS = Constants.CLIENT_ANALYTICS_URL + '{id}/candidates-per-interviews/';
  static CLIENT_ANALYTICS_TIMES_PER_INTERVIEWS = Constants.CLIENT_ANALYTICS_URL + '{id}/times-per-interviews/';
  static CLIENT_ANALYTICS_TIME_TO_PHASES = Constants.CLIENT_ANALYTICS_URL + '{id}/time-to-phases/';
  static CLIENT_ANALYTICS_CANDIDATES_BOUNCE_RATE = Constants.CLIENT_ANALYTICS_URL + '{id}/bounce-rate/';
  static CLIENT_ANALYTICS_VISITS_BY_DEVICE = Constants.CLIENT_ANALYTICS_URL + '{id}/visits-by-device/';
  static CLIENT_ANALYTICS_USER_VISITS_FROM_CAMPAIGNS = Constants.CLIENT_ANALYTICS_URL + '{id}/user-by-campaign/';



  static GROUP_ANALYTICS_URL = Constants.BASE_API + 'group-analytics/';
  static GROUP_ANALYTICS_CANDIDATES_CONVERSION_RATE = Constants.GROUP_ANALYTICS_URL + 'candidates-conversion-rate/';
  static GROUP_ANALYTICS_JOBS_SITUATION_BY_STATUS = Constants.GROUP_ANALYTICS_URL + 'jobs-situation-by-status/';
  static GROUP_ANALYTICS_TOP_JOBS_BY_VISITS = Constants.GROUP_ANALYTICS_URL + 'top-jobs-by-views/';
  static GROUP_ANALYTICS_TOP_JOBS_BY_CANDIDATES = Constants.GROUP_ANALYTICS_URL + 'top-jobs-by-candidates/';
  static GROUP_ANALYTICS_JOBS_SUMMARY = Constants.GROUP_ANALYTICS_URL + 'jobs-summary/';
  static GROUP_ANALYTICS_INSTRUMENTS_BY_PROVIDER = Constants.GROUP_ANALYTICS_URL + 'instruments_usage_by_provider/';
  static GROUP_ANALYTICS_CLIENTS_BY_INDUSTRY = Constants.GROUP_ANALYTICS_URL + 'clients-by-industry/';

  static GROUP_URL_LEGAL_DATA = Constants.GROUP_URL + 'legal-data/';

  static SUPPORT: string = Constants.USERS_URL + '{id}/support-email/';
  static SUPPORT_EMAIL: string = 'support@aplygo.com';

  static PARTNER = 'partner';
  static PROFESSION = 'profession';
  static PROFESSION_FROM_GROUP = 'profession-group';

  static DEFAULT_LANGUAGE = 'en';
  static TRANSLATIONS_URL = 'https://s3-eu-west-1.amazonaws.com/tkt-translations/';

  static JWT = 'jwt';
  static EXPIRATION_TIME_JWT = 'expiration_time_jwt';

  static USER_LOCAL_STORAGE = 'user';
  static CLIENT_LOCAL_STORAGE = 'client';

  static FEEDBACK_APP = 'https://feedback.thekeytalent.com/';

  static COOKIE_BOT_URL = 'https://consent.cookiefirst.com/banner.js';
  static COOKIE_BOT_UUID = environment.cookiebot_uuid;

  static BASE64_IMAGE: string = Constants.ALL_CANDIDATES_URL + 'convert-image-from-url-to-data-url/';
}
