import { Client } from '../models/client';
import { Filter } from '../../library/classes/filter';
import { Constants } from '../../classes/constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IRestService } from '../../library/interfaces/irest-service';
import { Injectable } from '@angular/core';
import { buildFilter } from '../../library/utils';

@Injectable()
export class ClientKpiService implements IRestService<Client> {

  endpoint = Constants.PARTNER_CLIENT_LIST;

  constructor(private httpClient: HttpClient) { }

  retrieve(id: number): Observable<Client> {
    throw new Error('Method not allowed.');
  }

  list(...filters: Filter[]): Observable<Client[]> {
    const url = `${this.endpoint}${buildFilter(...filters)}`;
    return this.httpClient.get<Client[]>(url);
  }

  create(value: Client): Observable<Client> {
    throw new Error('Method not allowed.');
  }

  update(value: Client): Observable<Client> {
    throw new Error('Method not allowed.');
  }

  partialUpdate(value: Client): Observable<Client> {
    throw new Error('Method not allowed.');
  }

  remove(id: number): Observable<Client> {
    throw new Error('Method not allowed.');
  }

}
