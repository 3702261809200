import { FormElement } from './../../../classes/custom-forms';
import { MultipostingService } from './../../../services/multiposting.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable } from 'rxjs';
import { Alert, AlertsService, ALERT_TYPES } from './../../../services/alerts.service';
import { ActivatedRoute } from '@angular/router';
import { UserDataService } from './../../../services/user-data-service.service';
import { GroupMultipostingProvider, CREDENTIAL_TYPES, MultipostingCredentials, MULTIPOSTING_REQUEST_STATUS, SaveMultiposingFormResponse } from './../../../classes/multiposting';
import { OnDestroy, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiposting-post',
  templateUrl: './multiposting-post.component.html',
  styleUrls: ['./multiposting-post.component.scss']
})
export class MultipostingPostComponent implements OnInit, OnDestroy {

  @Input() clientId: number;

  @Input() jobId: number;

  @Output() navigatingToConfig: EventEmitter<boolean> = new EventEmitter();

  groupProviders: GroupMultipostingProvider[] = [];

  selectedProvider: GroupMultipostingProvider = new GroupMultipostingProvider();

  credentialTypes = CREDENTIAL_TYPES;
  multipostingRequestStatus = MULTIPOSTING_REQUEST_STATUS;

  loadingProvider = false;
  loadingForm = false;

  readonlyForm = false;
  providerForm: FormElement[] = [];
  retry: boolean = false;
  modalRef: BsModalRef;
  alreadyFormSavedId: number;

  @ViewChild('formModal', { read: TemplateRef, static: true }) formModalTemplate: TemplateRef<any>;
  formModalRef: BsModalRef;

  componentSubscriptions: any = {};

  constructor(
    private userDataService: UserDataService,
    private activatedRoute: ActivatedRoute,
    private alertsService: AlertsService,
    private modalService: BsModalService,
    private multipostingService: MultipostingService
  ) { }

  ngOnInit() {
    this.getMultipostingData();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getMultipostingData() {
    this.loadingProvider = true;

    this.componentSubscriptions.retrieveMultiposting$ = forkJoin(
      this.userDataService.retrieveMultipostingProviders(this.clientId),
      this.userDataService.retrieveSocialNetworksUserGroup(this.clientId),
      this.multipostingService.getJobMultipostingRequests(this.jobId)
    ).subscribe(
      ([providers, credentials, requests]) => {
        this.groupProviders = providers.map(provider => Object.assign(new GroupMultipostingProvider(), provider));
        this.groupProviders = GroupMultipostingProvider.getSerializedProvidersCredentials(this.groupProviders, credentials);
        this.groupProviders = GroupMultipostingProvider.getSerializedProvidersRequests(this.groupProviders, requests);
        this.loadingProvider = false;
      },
      error => {
        this.alertsService.setAlert(null, error);
        this.loadingProvider = false;
      }
    );
  }

  getProviderForm(provider: GroupMultipostingProvider) {
    if (provider.user_credentials.validCredentials) {

      this.loadingForm = true;
      this.providerForm = [];
      this.modalRef = this.modalService.show(this.formModalTemplate, { backdrop: 'static' });

      this.componentSubscriptions.getForm = this.multipostingService.getMultipostingProviderForm(provider.id, this.jobId).subscribe(
        response => {
          this.selectedProvider = provider;
          this.providerForm = response.config;
          this.alreadyFormSavedId = response.job_multiposting;
          this.loadingForm = false;
        },
        error => {
          this.alertsService.setAlert(null, error);
          this.loadingForm = false;
        }
      );
    }
  }

  refreshProviderForm(form: FormElement[], updatedElement: FormElement) {
    this.loadingForm = true;

    this.componentSubscriptions.refreshForm = this.multipostingService.createMultipostingProviderForm(this.selectedProvider.id, this.jobId, form, updatedElement.name).subscribe(
      updatedForm => {
        this.providerForm = FormElement.serializeFormAnswers(updatedForm.config);
        this.loadingForm = false;
      },
      error => {
        this.alertsService.setAlert(null, error);
        this.loadingForm = false;
      }
    );
  }


  saveProviderForm(form: FormElement[]) {
    this.loadingForm = true;

    this.componentSubscriptions.getForm = this._getSaveUpdateProviderFormObservable(form).subscribe(
      response => {
        this._getMultipostingRequestObservable(response.id).subscribe(
          () => {
            this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__multipostingRequestSentSuccessfully'));
            this.getProvidersMultipostingRequests();
            this.closeFormModal();
          },
          error => {
            this.alertsService.setAlert(null, error);
            this.loadingForm = false;
          }
        );
      },
      error => {
        this.loadingForm = false;
        this.alertsService.setAlert(null, error);
      }
    );
  }

  _getSaveUpdateProviderFormObservable(form: FormElement[]): Observable<SaveMultiposingFormResponse> {
    if (!this.retry && !this.alreadyFormSavedId) {
      return this.multipostingService.saveMultipostingProviderForm(this.selectedProvider.id, form, this.jobId);
    } else {
      const editPostId = this.retry ? this.selectedProvider.multiposting_request_data.job_multiposting.id : this.alreadyFormSavedId;
      return this.multipostingService.editMultipostingProviderForm(form, editPostId);
    }
  }

  _getMultipostingRequestObservable(postId: number): Observable<any> {
    if (!this.retry) {
      return this.multipostingService.createMultipostingRequest(postId);
    } else {
      return this.multipostingService.retryMultipostingRequest(this.selectedProvider.multiposting_request_data.id);
    }
  }

  getProvidersMultipostingRequests() {
    this.loadingProvider = true;

    this.componentSubscriptions.getPostRequests = this.multipostingService.getJobMultipostingRequests(this.jobId).subscribe(
      requests => {
        this.groupProviders = GroupMultipostingProvider.getSerializedProvidersRequests(this.groupProviders, requests);
        this.loadingProvider = false;
      },
      error => {
        this.alertsService.setAlert(null, error);
        this.loadingProvider = false;
      }
    );
  }

  viewPostDetails(groupProvider: GroupMultipostingProvider, retry = false) {
    this.readonlyForm = !retry;
    this.loadingForm = true;
    this.providerForm = [];
    this.selectedProvider = groupProvider;
    this.modalRef = this.modalService.show(this.formModalTemplate, { backdrop: 'static' });
    this.retry = retry;

    this.componentSubscriptions.getPostDetails = this.multipostingService.getMultipostingPostDetail(groupProvider.multiposting_request_data.job_multiposting.id).subscribe(
      response => {
        this.providerForm = FormElement.serializeFormAnswers(response.config);
        this.loadingForm = false;
      },
      error => {
        this.alertsService.setAlert(null, error);
        this.loadingForm = false;
      }
    );
  }

  closeFormModal() {
    this.providerForm = [];
    this.readonlyForm = false;
    this.loadingForm = false;
    this.retry = false;
    this.alreadyFormSavedId = null;
    this.modalRef.hide();
    this.selectedProvider = new GroupMultipostingProvider();
  }

}
