import { Filter } from './../library/classes/filter';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { User } from '../classes/user';
import { Constants } from '../classes/constants';
import { Group } from '../classes/group';
import { AlertsService } from './../services/alerts.service';
import { buildFilter } from '../library/utils';
import { delayedRetry } from '../utils';
import { SupportTicket } from '../classes/supportTicket';
import { Role } from '../classes/role';
@Injectable()
export class UserService implements OnDestroy {
  private me: User;

  componentSubscriptions: any = {};

  constructor(private httpClient: HttpClient, private router: Router, private alertService: AlertsService) { }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  login(username: string, password: string): Observable<any> {
    return this.httpClient.post<any>(Constants.LOGIN_URL, { username, password });
  }

  logout() {
    this.removeToken();
    this.me = null;
    this.httpClient.post(Constants.LOGOUT_URL, {})
      .toPromise()
      .then((req) => console.dir(req), (error) => console.dir(error));
  }

  startRefreshToken() {
    setInterval(() => {
      if (this.isTokenExpiring()) {
        this.refreshToken();
      }
    }, 1000 * 3600);
  }

  isTokenExpiring(): boolean {
    const expiration_time = localStorage.getItem(Constants.EXPIRATION_TIME_JWT);
    const now = new Date();

    now.setHours(now.getHours() - 2);
    return new Date(expiration_time) < now;
  }

  saveToken(token: any): void {
    const today = new Date();

    today.setHours(today.getHours() + 18);
    localStorage.setItem(Constants.EXPIRATION_TIME_JWT, today.toString());
    localStorage.setItem(Constants.JWT, token.token);

    this.startRefreshToken();
  }

  removeToken(): void {
    localStorage.removeItem(Constants.JWT);
    localStorage.removeItem(Constants.EXPIRATION_TIME_JWT);
    localStorage.removeItem(Constants.USER_LOCAL_STORAGE);
  }

  refreshToken() {
    const token = localStorage.getItem(Constants.JWT);
    this.componentSubscriptions.httpClientPostPipe$ = this.httpClient.post<any>(Constants.REFRESH_URL, { token })
      .pipe(
        delayedRetry(1000 * 5, 5)
      )
      .subscribe(
        refreshed_token => this.saveToken(refreshed_token),
        error => this.inactiveLogout()
      );
  }

  saveUser(): void {
    localStorage.setItem(Constants.USER_LOCAL_STORAGE, JSON.stringify(this.me));
  }

  checkHasPermissions(role: number, customRoles?: number[]): boolean {
    let hasPermissions = false;
    if (customRoles && customRoles.length > 0) {
      hasPermissions = customRoles.includes(this.me.role);
    }
    return role === undefined || this.me.role <= role || hasPermissions;
  }

  checkIsLogged(): boolean {
    const userInLocalStorage = Boolean(localStorage.getItem(Constants.JWT)) && Boolean(localStorage.getItem(Constants.USER_LOCAL_STORAGE));
    if (userInLocalStorage || !this.me) {
      this.loadUser();
    }
    return this.me && !this.me.has_two_auth;
  }

  checkExistingToken(): void {
    const token = localStorage.getItem(Constants.JWT);
    this.componentSubscriptions.httpClientPost$ = this.httpClient.post<any>(Constants.VERIFY_URL, { token })
      .subscribe(
        res => this.startRefreshToken(),
        error => this.inactiveLogout()
      );
  }

  inactiveLogout() {
    this.alertService.setAlert({
      type: 'warning',
      message: '__inactivityLogoutWarning'
    });
    this.logout();
    this.router.navigate(['login']);
  }

  getUser(): User {
    return this.me;
  }

  getUserFromClient(...filter: Filter[]): Observable<HttpResponse<any>> {
    const query = buildFilter(...filter);
    const url = Constants.USERS_URL + query;
    return this.httpClient.get<User[]>(url, { observe: 'response' });
  }

  loadUser(): User {
    if (localStorage.getItem(Constants.USER_LOCAL_STORAGE) && !this.me) {
      const json_user = JSON.parse(localStorage.getItem(Constants.USER_LOCAL_STORAGE));
      this.setUser(json_user.id, json_user.email, json_user.role, json_user.group, json_user.first_name, json_user.last_name);
      return this.me;
    } else if (this.me) {
      return this.me;
    }
  }

  setUser(id: number, email: string, role, group: any = {}, first_name: string, last_name: string, has_two_auth: boolean = false): User {
    this.me = User.fromData(id, email, role, group, first_name, last_name, has_two_auth);
    return this.me;
  }

  getGroup(user: User): Promise<Group[]> {
    let url;
    if (user.myType() !== 'partner') {
      url = `${Constants.GROUP_URL}?type=${user.myType()}`;
    } else {
      url = `${Constants.PARTNER_LIST}`;
    }
    return this.httpClient.get<Group[]>(url)
      .toPromise();
  }

  getMyInitialPath(): string {
    if (this.me.isViewer()) {
      return `viewers-candidates/${this.me.group.id}/`;
    } else if (this.me.isClient()) {
      return `main/${this.me.group.id}/`;
    } else if (this.me.isHQ()) {
      return 'partner';
    } else if (this.me.isPartner()) {
      return 'client';
    }
  }

  sendVerificationCode(code, email): Observable<any> {
    return this.httpClient.post(Constants.VERIFY_CODE_URL, { code, email });
  }

  isPartner(): Boolean {
    return this.me && this.me.isPartner();
  }

  isClient() {
    return this.me && this.me.isClient();
  }
  isHQ() {
    return this.me && this.me.isHQ();
  }

  hasHQPermissions(): boolean {
    return this.me && this.me.hasHQPermissions();
  }

  hasPartnerAdminPermissions(): boolean {
    return this.me && this.me.hasPartnerAdminPermissions();
  }

  hasPartnerManagerPermissions(): boolean {
    return this.me && this.me.hasPartnerManagerPermissions();
  }

  hasClientAdminPermissions(): boolean {
    return this.me && this.me.hasClientAdminPermissions();
  }

  hasClientRecruiterPermissions(): boolean {
    return this.me && this.me.hasClientRecruiterPermissions();
  }

  hasClientEvaluatorPermissions(): boolean {
    return this.me && this.me.hasClientEvaluatorPermissions();
  }

  hasClientHiringManagerPermissions() {
    return this.me && this.me.hasClientHiringManagerPermissions();
  }

  hasClientBusinessPermissions() {
    return this.me && this.me.hasClientBusinessPermissions();
  }

  noIsViewer() {
    return this.me && this.me.noIsViewer();
  }

  isBusinessRole() {
    return this.me && this.me.hasSpecificRole(Role.CLIENT_BUSSINESS);
  }

  isRecruiterRole() {
    return this.me && this.me.hasSpecificRole(Role.CLIENT_RECRUITER);
  }

  sendSupportTicket(body: SupportTicket) {

    const url = `${Constants.SUPPORT.replace('{id}', '' + body.client)}`;

    return this.httpClient.post(url, body)
      .toPromise();
  }

  getJobCategories(): Observable<any> {
    const url = `${Constants.USER_JOB_CATEGORIES.replace('{id}', this.me.id)}`;
    return this.httpClient.get<any>(url);
  }
}
