import { Constants } from '../classes/constants';

export const PARTNER_URL: string = Constants.BASE_API + 'groups/partners/';

export const GROUP_URL: string = Constants.BASE_API + 'groups/';

export const CLIENT_LIST: string = Constants.BASE_API + 'groups/clients/';
export const PARTNER_CLIENT_LIST: string = Constants.BASE_API + 'groups/clients/';


export const ACTIVE = 'ACT';
export const INACTIVE = 'INA';

export const INDUSTRIES = [
  {
    clave: 'CALLCN',
    name: 'Call centers',
  },
  {
    clave: 'CONSTR',
    name: 'Building',
  },
  {
    clave: 'CONSUL',
    name: 'Consultancy',
  },
  {
    clave: 'BIENES',
    name: 'Non-durable consumer goods',
  },
  {
    clave: 'EDU',
    name: 'Education and formation',
  },
  {
    clave: 'COMERCIO',
    name: 'Retail / commerce',
  },
  {
    clave: 'BANCA',
    name: 'Banking and insurance',
  },
  {
    clave: 'HOST',
    name: 'Hospitality and leisure',
  },
  {
    clave: 'SS',
    name: 'Social and health services',
  },
  {
    clave: 'MEDIOS',
    name: 'Media and internet',
  },
  {
    clave: 'ADMINPUB',
    name: 'Public administration',
  },
  {
    clave: 'MARKETING',
    name: 'Marketing',
  },
  {
    clave: 'TELECO',
    name: 'Telecommunications',
  },
  {
    clave: 'TURISMO',
    name: 'Tourism and travel',
  },
  {
    clave: 'TRANSPORTE',
    name: 'Transport and distribution',
  },
  {
    clave: 'VENTAS',
    name: 'Sales',
  },
  {
    clave: 'CIENCIA',
    name: 'Research and science',
  },
  {
    clave: 'OTROS',
    name: 'Others',
  }
];
