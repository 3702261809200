import { Role } from './role';
import { Group } from './group';
import { JobCategory } from './job';


export class User {

  email = '';
  role = Role.CLIENT_BUSSINESS;
  id;
  group: Group;
  first_name?: string;
  last_name?: string;
  password?: string;
  categories_data?: JobCategory[];
  categories?: number[];
  has_two_auth: Boolean = false;

  static fromData(
    id: number,
    email: string,
    role: number = Role.CLIENT_BUSSINESS,
    group: Group,
    first_name: string,
    last_name: string,
    has_two_auth: boolean,
  ): User {

    const user = new User();
    user.email = email;
    user.role = role;
    user.id = id;
    user.group = group;
    user.first_name = first_name;
    user.last_name = last_name;
    user.has_two_auth = has_two_auth;
    return user;
  }

  setGroup(group: Group[]): any {
    this.group = group[0];
    return this;
  }

  isPartner(): Boolean {
    return this.role < Role.CLIENT_ADMIN;
  }

  isClient() {
    return this.role >= Role.CLIENT_ADMIN;
  }

  isHQ() {
    return this.role === Role.HQ;
  }

  isViewer() {
    return this.role == Role.CLIENT_VIEWER;
  }

  noIsViewer() {
    return this.role != Role.CLIENT_VIEWER;
  }

  hasHQPermissions(): boolean {
    return this.isHQ();
  }

  hasPartnerAdminPermissions(): boolean {
    return this.role <= Role.PARTNER_ADMIN;
  }

  hasPartnerManagerPermissions(): boolean {
    return this.role <= Role.PARTNER_MANAGER;
  }

  hasClientAdminPermissions(): boolean {
    return this.role <= Role.CLIENT_ADMIN;
  }

  hasClientRecruiterPermissions(): boolean {
    return this.role <= Role.CLIENT_RECRUITER;
  }

  hasClientEvaluatorPermissions(): boolean {
    return this.role <= Role.CLIENT_EVALUATOR;
  }

  hasClientHiringManagerPermissions(): boolean {
    return this.role <= Role.CLIENT_HIRING_MANAGER;
  }

  hasClientBusinessPermissions(): boolean {
    return this.role <= Role.CLIENT_BUSSINESS;
  }

  hasSpecificRole(role): boolean {
    return this.role === role;
  }

  myType(): string {
    let type = '';

    if (this.isClient()) {
      type = Group.CLIENT;
    } else if (this.isPartner()) {
      type = Group.PARTNER;
    }
    return type;
  }

}

export class UserJson {
  users: User[];
}

export class UserFilter extends User {
  selected = false;
  visible = true;
}
