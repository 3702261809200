import { Injectable, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { TranslateService, TranslationChangeEvent } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Constants } from "../classes/constants";

const CLIENT_ROUTES = [
  '/main/',
  '/candidates-pool/',
  '/viewers-candidates/'
];

@Injectable()
export class ClientTranslationService implements OnDestroy {

  callback: any = null;
  clientId: string = null;
  translationsLoaded: boolean = false;

  componentSubscriptions: any = {};

  constructor(
    private router: Router,
    private translationService: TranslateService,
    private httpClient: HttpClient) { }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  getClientTranslations(client_id: string, language: string): Observable<any> {
    const url = Constants.TRANSLATIONS_URL + environment.environment_language_file + client_id + '_' + language + '.json';
    return this.httpClient.get<any>(url);
  }

  public getNavigatorLanguage(): string {
    const availableLanguages = [ 'en', 'es', 'pt', 'pt-BR', 'it', 'fr', 'de', 'cs', 'nl', 'pl', 'sv', 'ro', 'el' ];
    const userLanguage = availableLanguages.find(lang => navigator.language.includes(lang)) || Constants.DEFAULT_LANGUAGE;
    return userLanguage;
  }

  start(callback: any) {

    this.callback = callback;

    const self = this;

    // Translations Loaded.

    var myVar = setInterval(() => {

      this.callback(false);

      if (Object.keys(self.translationService.store.translations).length) {
        self.translationsLoaded = true;
        self.loadClientTranslations();
        clearInterval(myVar);
      }

    }, 100);

    // Router changes.

    this.componentSubscriptions.routerEvents$ = this.router.events.subscribe((val) => {
      if (self.translationsLoaded && val instanceof NavigationStart) {
        self.loadClientTranslations();
      }
    });
  }

  /**
   * loadClientTranslations
   */
  async loadClientTranslations() {

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const url = this.router.url;

    if (CLIENT_ROUTES.find(r => url.startsWith(r))) {

      // This is a client route

      const clientId = url.split("/")[ 2 ];

      if (this.clientId != clientId) {

        this.callback(false);

        this.clientId = clientId;

        this.componentSubscriptions.getClientTranslations$ = this.getClientTranslations(this.clientId, this.getNavigatorLanguage()).subscribe((response) => {
          for (const key of Object.keys(response)) {
            this.translationService.set(key, response[ key ]);
          }
          this.callback(true);
        },
          (error) => {
            this.callback(true);
          });
      }

    } else {

      this.callback(true);
    }
  }
}
