import { UserService } from './../../services/user-service.service';
import { ActivatedRoute } from '@angular/router';
import { Job } from './../../classes/job';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { JobService } from '../../services/job.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { Subscription } from 'rxjs';
import { UserFilter } from '../../classes/user';
import { Subject } from '../../../../node_modules/rxjs';
import { ClientService } from '../../partners/services/client.service';
import { Client } from '../../partners/models/client';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SupportTicket, HelpRequestTypes } from '../../classes/supportTicket';
import { take, switchMap } from 'rxjs/operators';
import { COLOR_VARIABLES } from '../../utils/color-variables';

declare const jQuery: any;

@Component({
  selector: 'app-job-header',
  templateUrl: './job-header.component.html',
  styleUrls: ['./job-header.component.scss']
})
export class JobHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('switchCandidates', { static: true }) btnCandidates: ElementRef;
  @ViewChild('switchAnalytics', { static: true }) btnAnalytics: ElementRef;
  ks: string;
  subscription: Subscription;

  config = {
    toolbarGroups: [
      { name: 'fonts', groups: ['font', 'fontsize', 'format', 'styles'] },
      { name: 'basicstyles', groups: ['basicstyles', 'colors', 'cleanup'] },
      { name: 'paragraph', groups: ['align', 'list'] },
      { name: 'links' },
      { name: 'variables', groups: ['buttons'] },
    ],
    removeButtons: 'Subscript,Superscript,Strike,Anchor,Styles',
    uiColor: COLOR_VARIABLES['$_AUX_GRAY_COLOR'],
  };

  client_id: string;
  job: Job;
  jobLocationCategory: string;
  client: Client = new Client();

  availableUsers: UserFilter[] = [];
  displayUsersFilterPopup = false;
  searchTerm = new Subject();

  modalRef: BsModalRef;

  helpRequestSent: boolean;
  helpRequestSentError: boolean;
  helpRequestTypes = HelpRequestTypes;
  supportTicket: SupportTicket;
  modalEnabled = false;

  componentSubscriptions: any = {};

  constructor(
    public jobService: JobService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    public clientService: ClientService,
    private keyboardShortcutService: KeyboardShortcutService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.client_id = this.activatedRoute.parent.snapshot.paramMap.get('client')
    this.subscribeJob();
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'JobHeaderComponent.ShiftLeft':
        this.goToView();
        break;
      case 'JobHeaderComponent.ShiftRight':
        this.goToView();
        break;
    }
  }

  goToView() {
    const event = new MouseEvent('click', { bubbles: true });
    const path = this.activatedRoute.firstChild.snapshot.url.toString();
    switch (path) {
      case 'candidates':
        this.btnAnalytics.nativeElement.dispatchEvent(event);
        break;
      case 'analytics':
        this.btnCandidates.nativeElement.dispatchEvent(event);
        break;
    }
  }

  subscribeJob() {

    this.componentSubscriptions.clientServiceLoadClientPipe$ = this.clientService.loadClient(+this.client_id)
      .pipe(
        take(1),
        switchMap(
          client => {
            this.client = client;
            return this.activatedRoute.paramMap;
          }
        ))
      .pipe(switchMap(
        params => this.jobService.getJobInfo(params.get('job'))
      ))
      .subscribe(
        job => this.job = Object.assign(new Job(), job)
      );
  }

  setActiveClass(ev) {
    jQuery('.btn-switch')
      .removeClass('btn-switch-active');
    jQuery(ev.target)
      .addClass('btn-switch-active');
  }

  showHelpModal() {
    this.modalEnabled = true;
  }
  closedHelpModal(closed: boolean) {
    this.modalEnabled = closed;
  }
}
