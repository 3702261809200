import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'literaltrans'
})

export class LiteralTranformPipe implements PipeTransform {

  transform(text: string): string {
    const textArray = this.camelCaseString(text.split(' '));
    return `__${textArray.join('')}`;
  }

  camelCaseString(textArray: string[]) {

    return textArray.map((word, index) => {

      word.toLowerCase();

      if (index > 0) {
        return word.charAt(0).toUpperCase() + word.substring(1);
      } else {
        return word;
      }

    });

  }

}
