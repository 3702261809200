import { ActivatedRoute, Router } from '@angular/router';
import { TablePhase, INSCRIPTION, CANDIDATURE, StatusPhases, ShortPhasesLabels } from './../../classes/phases';
import { KeyboardShortcut, KeyboardShortcutService } from './../../services/keyboard-shortcut.service';
import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-funnel-table',
  templateUrl: './funnel-table.component.html',
  styleUrls: ['./funnel-table.component.scss']
})
export class FunnelTableComponent implements OnInit, OnDestroy {

  @Output() phaseSelected: EventEmitter<TablePhase> = new EventEmitter();
  @Input() defaultPhase: TablePhase;
  @Input() phases: TablePhase[] = [];
  @Input() statusPhases: StatusPhases = new StatusPhases();

  @Input() totalCandidates?: number;
  @Input() suitableCandidates?: number;
  @Input() isAuto? = false;

  @Input() set job_id (job: number) {
    this.setPhase();
  }

  ks: string;
  subscription: Subscription;
  shortPhaseLabels = ShortPhasesLabels;

  job: string;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private keyboardShortcutService: KeyboardShortcutService,
  ) { }

  ngOnInit() {
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  addPhasesAnnotation(): void {
    this.phases.forEach( phase =>
      phase.annotation = phase.disabled ? `${phase.name} Disabled` : null
    );
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    switch (ks.name) {
      case 'CandidateTableComponent.Right': this.goToNextPhase(); break;
      case 'CandidateTableComponent.Left': this.goToPreviousPhase(); break;
    }
  }

  setPhase() {
    const params = this.activatedRoute.snapshot.params;
    const selectedPhase = this.phases.find(phase => phase.value === params.phase);
    this.job = params.job;
    this.selectPhase(selectedPhase);
  }

  selectPhase(selectedPhase: TablePhase) {
    this.phases.map(phase => {
      phase.selected = phase.value === selectedPhase.value;
      return phase;
    });
    this.emitSelectedPhase(selectedPhase);
  }

  emitSelectedPhase(phase: TablePhase): void {
    this.phaseSelected.emit(phase);
  }

  getSelectedPhaseIndex(): number {
    return this.phases.findIndex(status => status.selected);
  }

  goToNextPhase(): void {
    if (!this.activatedRoute.snapshot.queryParams['candidate'] && this.getSelectedPhaseIndex() < this.phases.length - 1) {
      this.selectPhase(this.phases[this.getSelectedPhaseIndex() + 1]);
    }
  }

  goToPreviousPhase(): void{
    if (!this.activatedRoute.snapshot.queryParams['candidate'] && this.getSelectedPhaseIndex() > 0) {
      this.selectPhase(this.phases[this.getSelectedPhaseIndex() - 1]);
    }
  }

  isAutoPhase(phase: TablePhase): boolean {
    const autoPhases = [INSCRIPTION.code, CANDIDATURE.code];
    return autoPhases.includes(phase.value) && this.statusPhases[phase.value];
  }
}
