import { OriginsService } from './../../instruments/origins.service';
import { FormService } from './../../instruments/form.service';
import { PhaseConfig, OTHER_ASSESMENT } from './../../classes/phases';
import { Step } from './../../classes/step';
import { Form, FormTemplate } from './../../classes/form';
import { InstrumentService } from './../../instruments/instrument.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Profession } from '../../classes/profession';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ClientService } from '../../partners/services/client.service';
import { Landing } from '../../classes/landing';
import { ActivatedRoute } from '@angular/router';
import { ProfessionService } from '../../services/profession.service';
import { Observable, combineLatest } from 'rxjs';
import { AlertsService, ALERT_TYPES, Alert } from '../../services/alerts.service';
import { Client } from '../../partners/models/client';
import { Job } from '../../classes/job';
import { EmailState } from '../../classes/wsgi-email';
import { Filter } from '../../library/classes/filter';
import { Instrument, WizardInstruments } from '../../instruments/models/instrument';
import { Origin } from '../../models/origin';
import { INSTRUMENT_TYPES } from '../../instruments/constants';
import { TranslateService } from '@ngx-translate/core';
import { Interview } from '../../classes/interview';
import { ReportConfig } from '../../classes/reportConfig';
import { InstrumentsService } from '../../instruments/instruments.service';
import { InterviewsService } from '../../wizard-job/interviews/interviews.service'

@Component({
  selector: 'app-wizard-profession',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnDestroy {
  loading = true;

  @ViewChild('continueProfession', { read: TemplateRef, static: true }) modalProfession: TemplateRef<any>;

  steps = [
    new Step(1, '__general', ['name', 'description']),
    new Step(2, '__landing', ['landing']),
    new Step(3, '__inscriptionForm', null),
    new Step(4, '__evaluation', ['instruments']),
    new Step(5, '__interviews', null),
    new Step(6, '__emails', null),
    new Step(7, '__overview', null)
  ];

  finished = false;
  step_index = 1;
  registrationForm: any = {};
  modalRef: BsModalRef;
  profession: Profession;
  landing: Landing;
  instruments: Instrument[] = [];
  instrumentsSelected: Instrument[] = [];
  selectedFormInstruments: Instrument[] = [];
  instrumentsData: WizardInstruments = new WizardInstruments();
  errors = true;
  showLanding = false;
  update = false;
  clone = false;
  client: Client = new Client();

  landingReady: boolean = false;

  selectedFormOAInstruments: Instrument[] = [];
  selectedFormOAInterviews: Interview[] = [];

  formOptions = Form.PROFESSION_OPTIONS;

  inputProfession: Profession;

  @Output() hideWizard: EventEmitter<any> = new EventEmitter<any>();
  @Output() setProfession: EventEmitter<Profession> = new EventEmitter<Profession>();
  disableTemplateName = true;

  reportsData: Array<ReportConfig> = new Array<ReportConfig>();


  @Input() set wizardData(value: any) {
    const action: String = value.type;
    this.setProfessionFlags(action);
    this.inputProfession = value.profession;
  }

  componentSubscriptions: any = {};

  constructor(
    public professionService: ProfessionService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private clientService: ClientService,
    private instrumentService: InstrumentService,
    private alertService: AlertsService,
    private formService: FormService,
    private originService: OriginsService,
    private translateService: TranslateService,
    private instrumentsService: InstrumentsService,
    public interviewsService: InterviewsService
  ) { }

  ngOnInit() {
    this.client.id = +this.activatedRoute.parent.snapshot.paramMap.get('client');
    this.getWizardData();
    this.setEventListeners();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getWizardData() {

    this.componentSubscriptions.getObservablesData$ = this.getObservablesData()
      .subscribe(({ client, emails, instrumentsProviders, instruments, formTemplates, profession }) => {
        this.client = Object.assign(new Client, client);
        this.instrumentsData = new WizardInstruments(
          instrumentsProviders,
          instruments,
          client.instruments_config,
          formTemplates
        );
        if (this.update || this.clone) {
          this.actionProfession(profession);
        } else {
          this.profession = Profession.getDefaultProfession();
          this.profession.group = this.client.id;
          this.profession.emails = emails.length > 0 ? emails[0].content : new EmailState();

        }
        this.reportsData = this.client.reports_config;

        this.showLanding = true;
        this.loading = false;
      });
  }

  getObservablesData(): Observable<any> {
    return combineLatest(
      ...this.getObservables(),
      (client: Client,
        emails: EmailState,
        instrumentsProviders: Origin[],
        instruments: Instrument[],
        formTemplates: FormTemplate[],
        profession: Profession | {} = {}
      ) => ({ client, emails, instrumentsProviders, instruments, formTemplates, profession })
    );
  }

  getObservables(): Observable<any>[] {
    let observablesArray = [
      this.clientService.loadClient(this.client.id),
      this.clientService.getClientEmails(this.client.id),
      this.originService.list(),
      this.instrumentService.list(new Filter('group', this.client.id)),
      this.formService.list(new Filter('group', this.client.id))
    ];

    observablesArray = this.update || this.clone ? observablesArray.concat(this.getEditProfessionObservables()) : observablesArray;
    return observablesArray;
  }

  getEditProfessionObservables(): Observable<any> {
    const jobObservablesArray = [
      this.professionService.retrieve(this.inputProfession.id)
    ];
    return combineLatest(...jobObservablesArray, (profession) => ({ profession }));
  }

  actionProfession({ profession }) {
    profession.form = profession.form.form || profession.form;
    this.profession = Object.assign(Profession.getDefaultProfession(), profession);
    this.selectedFormInstruments = this.getInstrumentForm();
    this.profession.phase_config = Object.keys(profession.phase_config)
      .length > 0 ?
      this.profession.phase_config : new PhaseConfig();
    this.profession.landing = null;

    this.profession.setInstrumentsData(profession.instruments_data);

    this.selectedFormOAInstruments = this.getFormOAInstruments();
    this.selectedFormOAInterviews = this.getFormOAInterviews();

    if (this.clone) {
      this.profession.id = null;
      this.profession.name = profession.name + ' - copy';
    }
    this.landing = new Landing(profession.landing.id, profession.landing.name);
    this.landing.group = this.profession.group;
    this.instrumentsSelected = this.profession.instruments as Instrument[];
    this.instrumentsService.setSelectedReports(this.profession.reports_config);
  }

  setProfessionFlags(action) {
    switch (action) {
      case Job.UPDATE_ACTION:
        this.update = true;
        break;
      case Job.CLONE_ACTION:
        this.clone = true;
        break;
    }
  }

  private getFormOAInstruments() {
    return (this.profession.instruments as Instrument[])
      .filter(instrument => instrument.phase === OTHER_ASSESMENT.code);
  }

  private getFormOAInterviews() {
    return this.profession.interview_guides.OA;
  }

  setEventListeners() {
    if (!this.update) {
      window.onbeforeunload = this.alertExit.bind(this);
    }
    this.finished = false;
    window.onmessage = this.iframeListener.bind(this);
  }

  alertExit = e => {
    if (!e.target.querySelector('.btn-mail')) {
      window.onmessage = null;
      if (this.profession) {
        this.finished = true;
        this.profession.landing = this.landing.id;
        this.professionService.saveProfessionToLocal(this.profession);
      }
      setTimeout(this.setEventListeners.bind(this), 200);
      (e || window.event)
        .returnValue = null;
      return null;
    }
  }

  iframeListener(data) {
    const is_valid_data = data.data && data.data.type && data.data.type.includes('builder');

    if (is_valid_data) {
      setTimeout(() => {
        const editedProfession = this.translateService.instant('__professionEdited');
        const createdProfession = this.translateService.instant('__professionCreated');
        const message = this.update ? editedProfession : createdProfession;
        this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, message));
        this.hideWizard.emit(true);
        this.setProfession.emit(this.profession);
        this.finished = false;
        window.onmessage = null;
      }, 150);
    } else if (data.data && data.data.type && data.data.type === 'landingReady') {
      this.landingReady = true;
    }
  }

  landingIsReady(stepId: number): boolean {
    return stepId > 2 ? this.landingReady : true;
  }

  setCuteInstrument(instruments: Instrument[]) {
    if (!instruments) {
      this.selectedFormOAInstruments = ((this.profession.instruments as Instrument[]) || this.selectedFormOAInstruments)
        .filter(instrument => instrument.phase === OTHER_ASSESMENT.code);

      this.profession.instruments = null;

    } else {
      this.profession.instruments = this.selectedFormOAInstruments;

      this.profession.instruments = this.profession.instruments.concat(instruments);
    }
  }

  lastStep() {
    return this.step_index === this.steps.length;
  }

  nextStep(): void {
    this.step_index += 1;
  }

  previousStep() {
    this.step_index = this.step_index <= 0 ? 0 : this.step_index - 1;
  }

  finish() {
    if (!this.landing) {
      const haveToSelectProfession = this.translateService.instant('__haveToSelectProfession');
      return this.alertService.setAlert({ type: 'error', message: haveToSelectProfession });
    }

    this.profession.landing = this.landing.id;
    this.profession.reports_config = this.instrumentsService.getSelectedReports().length ? this.instrumentsService.getSelectedReports() : [];
    const finishProfession = this.update ? this.updateProfession() : this.createProfession();

    this.componentSubscriptions.finishProfession$ = finishProfession.subscribe(
      () => this.confirmedAnswer(),
      error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
    );

  }

  setLocalProfession() {
    this.profession = this.professionService.getLocalProfession();
    this.instrumentsSelected = this.profession.instruments as Instrument[];
    if (this.profession.landing) {
      this.landing = new Landing(this.profession.landing as number, 'Template', this.profession.group);
      this.showLanding = true;
    }
  }

  cancelWizard() {
    this.professionService.clearLocalProfession();
    this.interviewsService.removeBuilder();
    this.interviewsService.setEditorMode(false);
    this.interviewsService.setCreatingMode(false);
    window.onbeforeunload = null;
    this.hideWizard.emit(true);
  }

  createProfession(): Observable<Profession> {
    return this.professionService.create(this.profession);
  }

  updateProfession(): Observable<Profession> {
    return this.professionService.update(this.profession);
  }

  confirmedAnswer() {
    window.onbeforeunload = null;
    this.profession.landing ? this.finished = true : this.finish();
    this.professionService.clearLocalProfession();
  }

  getInstrumentsIDs(instruments: Instrument[]): number[] {
    return instruments.map((instrument: Instrument) => (instrument.id));
  }

  showModal(modalTemplate) {
    this.modalRef = this.modalService.show(modalTemplate, { class: 'prevent-dialog', backdrop: 'static' });
  }

  _getCurrentStep(): Step {
    return this.steps.find(step => step.id === this.step_index);
  }

  checkRequirements(): boolean {
    return this._getCurrentStep() && this._getCurrentStep().requirements
      ? this._getCurrentStep().requirements.every(key => this.profession[key])
      : true && !this.checkEditingInterview();
  }

  checkDisableStep(stepId: number) {
    return !this.landingIsReady(stepId)
      || this.checkEditingInterview()
      || (this.getNextRequirementStep() && this.getNextRequirementStep().id < stepId);
  }

  getNextRequirementStep(): Step {
    const nextStep = !this.checkRequirements() ?
      this._getCurrentStep() :
      this.steps.slice(this.step_index)
        .find(step => step.requirements != null && step.requirements.every(key => !this.profession[key]));
    return nextStep;
  }

  isCurrentStep(step_id): boolean {
    return this.step_index === step_id;
  }

  setLanding(landing: Landing): void {
    this.landing = landing;
    this.profession.landing = null;
  }

  private getInstrumentForm(): Instrument[] {
    return this.profession.instruments_data.filter(instrument => instrument.origin === INSTRUMENT_TYPES.FORMS);
  }

  setOAInstruments(instruments: Instrument[]) {
    const onlineAssessmentInstruments = this.profession.instruments.filter(instrument => instrument.phase !== OTHER_ASSESMENT.code);
    this.profession.instruments = onlineAssessmentInstruments.concat(instruments);
  }

  setOAInterviews(interviews: Interview[]) {
    this.profession.interview_guides.OA = interviews;
  }

  checkEditingInterview(): boolean {
    var isEditing: boolean;
    var isCreating: boolean;
    this.componentSubscriptions.interviewServiceGetEditorMode$ = this.interviewsService.getEditorMode().subscribe(val => {
      isEditing = val;
    });
    this.componentSubscriptions.interviewsServiceGetCreatingMode$ = this.interviewsService.getCreatingMode().subscribe(val => {
      isCreating = val;
    });
    return isEditing || isCreating;
  }
}
