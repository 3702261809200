import { Alert, AlertsService, ALERT_TYPES } from '../../../services/alerts.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { TagsService } from '../../../services/tag.service';
import { TagsControlService } from '../../services/tags-control.service';
import { Subscription } from 'rxjs';
import { TAG_COLORS } from '../../../classes/tag';
import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Tag } from '../../../classes/tag';

@Component({
  selector: 'app-settings-tags-manager',
  templateUrl: './settings-tags-manager.component.html',
  styleUrls: ['./settings-tags-manager.component.scss']
})
export class SettingsTagsManagerComponent implements OnInit, OnDestroy {

  tags: Tag[] = [];

  newTag: Tag = null;
  deletingTagIndex: number = null;

  colorOptions = Object.values(TAG_COLORS);

  modalRef: BsModalRef;

  @ViewChild('newTagInput', { static: false }) newTagInput: ElementRef;
  @ViewChild('deleteTagModal', { static: true }) deleteTagModal: ElementRef;

  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private tagsService: TagsService,
    private tagsControlService: TagsControlService,
    private modalService: BsModalService,
    private alertsService: AlertsService
  ) { }

  ngOnInit() {
    this.subscribeToAvailableTags();
  }

  ngOnDestroy() {
    this.tagsControlService.getAvailableTags();

    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  subscribeToAvailableTags() {
    this.tagsControlService.availableTags$.subscribe(
      tags => this.tags = tags.map(tag => Object.assign(new Tag(), tag)),
    );
  }

  showDeleteTagModal(i: number) {
    this.deletingTagIndex = i;
    this.modalRef = this.modalService.show(this.deleteTagModal, { class: '', backdrop: 'static' });
  }

  hideDeleteTagModal() {
    this.modalRef.hide();
    this.deletingTagIndex = null;
  }

  /* NEW TAG */

  createNewTag() {
    this.newTag = new Tag();
    setTimeout(() => this.newTagInput.nativeElement.focus(), 0);
  }

  deleteNewTag() {
    this.newTag = null;
  }

  /* CRUD TAGS */

  createTag(newTagAfterCreation = false) {
    if (this.newTag.isValid()) {

      this.tagsService.createTag(this.tagsControlService.getClientUuid(), this.newTag).subscribe(
        tag => {
          this.newTag = newTagAfterCreation ? new Tag() : null;
          this.tags = Tag.getSortedTags(this.tags.concat(Object.assign(new Tag(), tag)));
          this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__tagCreatedSuccessfully'));
        },
        error => this.alertsService.setAlert(null, error)
      );

    } else {
      this.alertsService.setAlert(new Alert(ALERT_TYPES.ERROR, '__tagsEmptyNameError'));
    }
  }

  updateTag(i: number) {
    if (this.tags[i].isValid()) {

      this.tagsService.updateTag(this.tagsControlService.getClientUuid(), this.tags[i]).subscribe(
        res => {
          this.tags[i].editing = !this.tags[i].editing;
          Tag.getSortedTags(this.tags);
          this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__tagEditedSuccessfully'));
        },
        error => this.alertsService.setAlert(null, error)
      );

    } else {
      this.alertsService.setAlert(new Alert(ALERT_TYPES.ERROR, '__tagsEmptyNameError'));
    }
  }

  deleteTag(i: number) {
    this.tagsService.deleteTag(this.tagsControlService.getClientUuid(), this.tags[i]).subscribe(
      res => {
        this.tags.splice(i, 1);
        this.hideDeleteTagModal();
        this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__tagDeletedSuccessfully'));
      },
      error => this.alertsService.setAlert(null, error)
    );
  }

  trackByIdx(i: number): any {
    return i;
  }

}
