import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'momentTimeAgo'
})
export class MomentTimeAgoPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, args?: any): any {
    moment.locale(this.translateService.currentLang);
    return moment(value).fromNow();
  }

}
