import { Injectable } from '@angular/core';
import { InterviewGuides, Interview } from '../../classes/interview';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InterviewsService {

  private builder = false;
  private $editorMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private $creatingMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private interviewToEdit: Interview;

  constructor() { }

  activateBuilder() {
    this.builder = true;
  }

  removeBuilder() {
    this.builder = false;
  }

  isBuilderActive(): boolean {
    return this.builder;
  }

  setInterviewToEdit(interview: Interview) {
    this.interviewToEdit = interview;
  }

  getInterviewToEdit(): Interview {
    return this.interviewToEdit;
  }

  setEditorMode(status: boolean) {
    this.$editorMode.next(status);
  }

  setCreatingMode (status: boolean) {
    this.$creatingMode.next(status);
  }

  getEditorMode(): BehaviorSubject<boolean> {
    return this.$editorMode;
  }

  getCreatingMode(): BehaviorSubject<boolean> {
    return this.$creatingMode;
  }

}
