import { Component, OnInit, Input } from '@angular/core';
import { ReportConfig } from '../../../classes/reportConfig';
import { InstrumentsService } from '../../../instruments/instruments.service';

@Component({
  selector: 'app-reports-cute',
  templateUrl: './reports-cute.component.html',
  styleUrls: ['./reports-cute.component.scss']
})

export class ReportsCuteComponent implements OnInit {

  @Input() reports: ReportConfig[] = [];
  @Input() update;
  @Input() set selectedReports(selectedReports: ReportConfig[]) {
    this.selectedReportSlugs = selectedReports.map(report => report.slug);
  }

  selectedReportSlugs: string[] = [];
  constructor(
    private instrumentsService: InstrumentsService
  ) { }

  ngOnInit() {

  }

  selectReport(report: ReportConfig) {
    this.instrumentsService.changeSelectedOneReport(report);
  }
}
