import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { Subscription } from 'rxjs';
import { LayoutService, LayoutActions } from '../../services/layout.service';
import { ClientDataService } from '../../services/client-data.service';

@Component({
  selector: 'app-job-dashboard',
  templateUrl: './job-dashboard.component.html',
  styleUrls: [ './job-dashboard.component.scss' ]
})
export class JobDashboardComponent implements OnInit, OnDestroy {
  ks: string;
  subscription: Subscription;

  showWizardJob: any = { show: false, type: 'create', };
  candidateSelected = false;
  selectedRow: number;

  layoutActions = LayoutActions;

  componentSubscriptions: any = {};

  constructor(
    private keyboardShortcutService: KeyboardShortcutService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public layoutService: LayoutService,
    private clientdataService: ClientDataService
  ) { }

  ngOnInit() {
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
    this.subscribeCandidateParam();
    this.layoutService.handleLayoutServiceAction(LayoutActions.RESET_JOB_DASHBOARD);
    const client = this.activatedRoute.snapshot.paramMap.get('client');
    this.clientdataService.getGroupsData(client);

  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }


  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'JobDashboardComponent.AltS': this.goToSettings(); break;
    }
  }

  goToSettings() {
    const client = this.activatedRoute.snapshot.paramMap.get('client');
    this.router.navigate([ 'setting-client', client ]);
  }

  subscribeCandidateParam() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        this.candidateSelected = Boolean(queryParam.get('candidate'));
        if (!this.candidateSelected) { this.selectedRow = null; }
      }
    );
  }

}
