import { Component, OnInit, Input } from '@angular/core';
import { Instrument } from '../../../instruments/models/instrument';
import { InstrumentsService } from '../../../instruments/instruments.service'

@Component({
  selector: 'app-instruments-tkt',
  templateUrl: './instruments-tkt.component.html',
  styleUrls: ['../_instruments-content.scss']
})
export class InstrumentsTktComponent implements OnInit {

  @Input() instruments;
  @Input() update;

  constructor(
    public instrumentsService: InstrumentsService,
  ) { }

  ngOnInit() {
  }

  showDetailsDialog(instrument: Instrument) {
    this.instrumentsService.infoDialogInstrument = instrument;
    this.instrumentsService.viewInfoDialog = true;
  }

}
