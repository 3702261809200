import { Constants } from './../classes/constants';
import { Injectable } from '@angular/core';
import { RestService } from '../library/classes/rest-service';
import { INSTRUMENT_ORIGIN_URL } from './constants';
import { Origin } from '../models/origin';

@Injectable()
export class OriginsService extends RestService<Origin> {

  endpoint = INSTRUMENT_ORIGIN_URL;

}
