export class Filter {
  name: string;
  value: number | string | string[] | boolean;

  constructor(name: string, value: number | string | string[] | boolean) {
    this.name = name;
    this.value = value;
  }

  public getFilterValues(): Filter[] {
    const values = Array.isArray(this.value) ? this.value : [this.value];
    return values.map( value => new Filter(this.name, value));
  }
}
