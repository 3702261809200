import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitArray'
})
export class SplitArrayPipe implements PipeTransform {

  transform(value_object: {value: string, id: number}[] = [], args?: any): any {
    return value_object.map(value => value.value).join('\n');
  }

}
