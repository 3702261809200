import { Job } from '../../../classes/job';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { INTERVIEW_PHASES, Phase, StatusPhases } from '../../../classes/phases';
import { InterviewGuides, Interview } from '../../../classes/interview';
import { Instrument } from '../../../instruments/models/instrument';
import { ActivatedRoute } from '@angular/router';
import { InstrumentService } from '../../../instruments/instrument.service';
import { Filter } from '../../../library/classes/filter';
import { InterviewsService } from '../interviews.service';

@Component({
  selector: 'app-interviews-phases',
  templateUrl: './interviews-phases.component.html',
  styleUrls: ['./interviews-phases.component.scss']
})
export class InterviewsPhasesComponent implements OnInit, OnDestroy {

  helperPhases: Phase[] = INTERVIEW_PHASES;
  activePhaseIndex = 0;
  _statusPhases: StatusPhases = new StatusPhases();
  disabledPhasesFlag = false;
  OAinstruments: Instrument[] = [];
  OAInterviews: Interview[] = [];
  interviewToEdit: Interview;

  @Input() update = false;
  @Input() job: Job;

  @Input() interviewPhases: InterviewGuides = new InterviewGuides();
  @Input() set statusPhases(statusPhases: Job['phases']) {
    this.setStatusPhases(statusPhases);
    this.setActivePhase();
    this.setDisabledPhasesFlag();
  }

  _selectedFormVidassess: Instrument[];
  _selectedFormOAInterviews: Interview[];

  @Input() set selectedFormOAInterviews(forms: Interview[]) {
    this._selectedFormOAInterviews = forms || [];
    if (this._selectedFormOAInterviews.length > 0) { this.setSelectedInterviews(); }

  }

  get selectedFormOAInterviews(): Interview[] {
    return this._selectedFormOAInterviews;
  }

  @Input() set selectedFormVidassess(vidassess: Instrument[]) {
    this._selectedFormVidassess = vidassess || [];
    if (this._selectedFormVidassess.length > 0) { this.setSelectedVidassess(); }
  }

  get selectedFormVidassess(): Instrument[] {
    return this._selectedFormVidassess;
  }


  @Output() interviewPhasesChange: EventEmitter < InterviewGuides > = new EventEmitter();

  @Output() OAinstrumentsChange: EventEmitter < Instrument[] > = new EventEmitter();
  @Output() OAInterviewsChange: EventEmitter < Interview[] > = new EventEmitter();

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private instrumentService: InstrumentService,
    public interviewsService: InterviewsService
  ) {}

  ngOnInit() {
    this.setActiveInterviews();
    this.getOAInstruments();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  setStatusPhases(phases: Job['phases']) {
    Object.keys(phases)
      .forEach(phase => {
        this._statusPhases[phase] = phases[phase].is_active;
      });
  }

  setActivePhase() {
    const firstActivePhase = this.helperPhases.find(phase => this._statusPhases[phase.code]);
    this.activePhaseIndex = this.helperPhases.indexOf(firstActivePhase);
  }

  handleInterview(interview: Interview) {
    this.interviewPhases[this.helperPhases[this.activePhaseIndex].code].push(interview);
    this.emitPhases();
  }

  setDisabledPhasesFlag() {
    this.disabledPhasesFlag = !this.update && this.helperPhases.some(phase => !this._statusPhases[phase.code]);
  }

  emitPhases() {
    this.interviewPhasesChange.emit(this.interviewPhases);
  }

  setActiveInterviews() {
    Object.keys(this.interviewPhases).forEach(key => {
      if (this.interviewPhases[key].length > 0) {
        this.interviewPhases[key].forEach(elem => {
          elem.selected = true;
        });
       this.interviewToEdit = this.interviewPhases[key][0];
      }
    });
  }

  getOAInstruments() {
    const clientId = this.activatedRoute.snapshot.paramMap.get('client');
    this.componentSubscriptions.instrumentServiceList$ = this.instrumentService.list(new Filter('group', clientId), new Filter('phase', 'OA'))
      .subscribe(
        (res: Instrument[]) => {
          this.OAinstruments = res;
          if (this.selectedFormOAInterviews.length > 0) { this.setSelectedInterviews(); }
          if (this.selectedFormVidassess.length > 0) { this.setSelectedVidassess(); }
        }, error => {
          console.log(error);
        }
      );
  }

  setSelectedInterviews() {
    this.selectedFormOAInterviews.forEach(interview => interview.selected = true);
    this.OAInterviews = this.selectedFormOAInterviews;
  }

  setSelectedVidassess() {
    const vidassessIds = this.selectedFormVidassess.map(instrument => instrument.instrument_id || instrument.id);
    this.OAinstruments.forEach(instrument => {
      if (vidassessIds.indexOf(instrument.id) !== -1) {
        instrument.selected = true;
      }
    });
  }

  emitOAInstruments(instruments: Instrument[]) {
    this.OAinstrumentsChange.emit(instruments);
  }

  phaseChange(index: number) {
    this.activePhaseIndex = index;
    this.interviewsService.setEditorMode(false);
    this.interviewsService.setCreatingMode(false);
    this.interviewsService.setInterviewToEdit(null);
  }

}
