import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { normalizeString } from '../../library/utils';
@Component({
  selector: 'app-select-search-input',
  templateUrl: './select-search-input.component.html',
  styleUrls: ['./select-search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectSearchInputComponent),
    }
  ]
})
export class SelectSearchInputComponent implements OnInit, OnDestroy {

  allOptions: { value: any, label: string }[] = [];
  filteredOptions: { value: any, label: string }[] = [];

  @Input() set options(options: { value: any, label: string }[]) {
    this.allOptions = options;
    this.setFilteredOptions();

    if (this.selectedValue && !this.selectedLabel) {
      this.setSelectedLabelFromValue();
    }
  }

  @Input() set ngModel(ngModel: string) {
    if (ngModel && ngModel !== this.selectedValue) {
      this.selectedValue = ngModel;
      this.setSelectedLabelFromValue();
    }
  }

  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  selectedValue: any = null;
  selectedLabel: string = null;

  optionsDisplayed = false;

  search$ = new Subject<string>();
  search: string = '';

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('optionsContainer', { static: false }) optionsContainer: ElementRef;

  componentSubscriptions: any = {};

  constructor() { }

  ngOnInit() {
    this.doSearch();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  setSelectedLabelFromValue() {
    const selectedOption = this.allOptions.find(option => option.value === this.selectedValue);
    this.selectedLabel = selectedOption ? selectedOption.label : null;
  }

  setFilteredOptions() {
    this.filteredOptions = this.allOptions.filter(option =>
      normalizeString(option.label).includes(normalizeString(this.search))
    );
  }

  openOptionsPanel() {
    this.optionsDisplayed = true;
    this.searchInput.nativeElement.focus();
  }

  closeOptionsPanel() {
    this.optionsDisplayed = false;
    this.search = '';
    this.setFilteredOptions();
  }

  focusFirstOption(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    const firstElement: any = this.optionsContainer.nativeElement.firstElementChild;
    if (firstElement && firstElement.className.includes('option-element')) {
      firstElement.focus();
    }
  }

  focusPrevOption(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    const prevElement: any = document.activeElement.previousElementSibling;
    if (prevElement) {
      prevElement.focus();
    }
  }

  focusNextOption(e: KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    const nextElement: any = document.activeElement.nextElementSibling;
    if (nextElement) {
      nextElement.focus();
    }
  }

  selectOption(option: { value: any, label: string }) {
    this.selectedValue = option.value;
    this.selectedLabel = option.label;
    this.closeOptionsPanel();
    this.emitNgModelChange();
  }

  selectRandomOption() {
    if (this.allOptions.length) {
      const randomIdx = this.allOptions.length * Math.random() | 0;
      this.selectOption(this.allOptions[randomIdx]);
    }
  }

  doSearch(): void {
    this.componentSubscriptions.searchPipe$ = this.search$
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(
        search => this.setFilteredOptions()
      );
  }

  emitNgModelChange() {
    this.ngModelChange.emit(this.selectedValue);
  }

  // These methods are required for the NgModel binding to work properly
  writeValue() {
  }

  registerOnChange() {
  }

  registerOnTouched() {
  }

}
