import { Constants } from '../../classes/constants';
import { LandingBuilderService } from './../../services/landing-builder-service.service';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Filter } from './../../library/classes/filter';
import { EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Landing } from './../../classes/landing';
import { Component } from '@angular/core';
import { environment } from '../../../environments/environment.dev';
import { UserService } from '../../services/user-service.service';
import { setFilter, doSearch } from '../../library/utils';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-landing-list',
  templateUrl: './landing-list.component.html',
  styleUrls: ['./landing-list.component.scss']
})
export class LandingListComponent implements OnInit {

  @Input() group: number;
  @Output() landingChange: EventEmitter < Landing > = new EventEmitter < Landing > ();

  isZero = !environment.production;

  defaultLanding: Landing = new Landing();

  parameters: Filter[] = [];
  searchBar$: BehaviorSubject < string > = new BehaviorSubject < string > ('');
  landing_list$: Observable < Landing[] > ;

  premiumList = [
    { name: 'Premium 1', url: 'https://zero-old.aplygo.com/external_static/premium_templates/premium1', img: '../../assets/landing_0.jpg' },
    { name: 'Premium 2', url: 'https://zero-old.aplygo.com/external_static/premium_templates/premium2', img: '../../assets/landing_1.jpg' },
    { name: 'Premium 3', url: 'https://zero-old.aplygo.com/external_static/premium_templates/premium3', img: '../../assets/landing_2.jpg' },
    { name: 'Premium 4', url: 'https://zero-old.aplygo.com/external_static/premium_templates/premium4', img: '../../assets/landing_3.jpg' },
    { name: 'Premium 5', url: 'https://zero-old.aplygo.com/external_static/premium_templates/premium5', img: '../../assets/landing_4.jpg' },
    { name: 'Premium 6', url: 'https://zero-old.aplygo.com/external_static/premium_templates/premium6', img: '../../assets/landing_5.jpg' },
  ];

  constructor(
    private landingService: LandingBuilderService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.defaultParameters();
    this.doSearch();
  }

  defaultParameters() {
    this.defaultLanding.group = this.group;
    this.parameters = setFilter(this.parameters, new Filter('group', String(this.group)));
    this.parameters = setFilter(this.parameters, new Filter('market', String(true)));
  }

  getLandingList(): Observable < Landing[] > {
    this.landing_list$ = this.landingService.getLandingList(...this.parameters);
    return this.landing_list$;
  }

  doSearch() {
    this.landing_list$ = doSearch(this.searchBar$)
      .pipe(switchMap(
        term => {
          this.parameters = setFilter(this.parameters, new Filter('search', term));
          return this.landingService.getLandingList(...this.parameters);
        }));
  }

  getPreviewUrl(landing: Landing): string {
    return Constants.LANDING_PREVIEW_URL.replace('{id}', String(landing.id))
      .replace('{jwt}', String(localStorage.getItem(Constants.JWT)));
  }

  landingContact(landing: Landing): void {
    const group = this.userService.getUser()
      .group;
    window.open(`mailto:info@aplygo.com?subject=Landing ad hoc for ${group.name}&body=Hello! Your client ${group.name} has indicated that he wants to create the landing ${landing.name} with url ${landing.url}. Please contact with the client on the e-mail: ${group.client_email}.`, '_self');
  }

}
