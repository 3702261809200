import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { Filter } from './../../library/classes/filter';
import { Observable, forkJoin } from 'rxjs';
import { ClientService } from '../../partners/services/client.service';
import { JobCategory, Job } from '../../classes/job';
import { setFilter } from '../../library/utils';
import { PHASES } from '../../classes/phases';
import { ProfessionService } from '../../services/profession.service';
import { FormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';

@Component({
  selector: 'app-new-dashboard-client-filters',
  templateUrl: './new-dashboard-client-filters.component.html',
  styleUrls: ['./new-dashboard-client-filters.component.scss']
})
export class NewDashboardClientFiltersComponent implements OnInit, OnDestroy {

  style: any = {};
  @Input() client: number;
  @Input() defaultFilters: any = null;
  @Input() closeFilters = true;
  @Output() appliedFilters = new EventEmitter<Filter[]>();
  @Output() closeFiltersChange: EventEmitter<Boolean> = new EventEmitter();

  templates: any[] = [];
  organizations: Array<any> = [];
  locations: Array<any> = [];
  functions: Array<any> = [];
  professions: Array<any> = [];
  candidateStatusList = { PND: false, PASS: false, KO: false };

  dropdownFilters = ['organization', 'function', 'location', 'profession', 'template'];

  minDate = new Date(2018, 4, 17);
  minDateEnd = new Date(2018, 4, 17);
  maxDateStart = new Date();
  maxDateEnd = new Date();
  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    showWeekNumbers: false,
    dateInputFormat: 'D/M/YYYY'
  };
  newForm: FormGroup;

  jobTypes: Array<any> = [
    { value: Job.JOB_PUB, label: Job.TEXT_PUB },
    { value: Job.JOB_PRI, label: Job.TEXT_PRI },
    { value: Job.JOB_BTH, label: '__both' }
  ];

  jobStatus: Array<any> = [
    { value: Job.ACTIVE, label: '__active' },
    { value: Job.CLOSED, label: '__closed' },
    { value: Job.EXPIRED, label: '__expired' }
  ];

  candidateStatuses = [
    { value: 'PASS', label: '__approved' },
    { value: 'PND', label: '__pending' },
    { value: 'KO', label: '__rejected' },
  ];

  originValues = [
    { value: 'employee', label: '__employees' },
    { value: 'external', label: '__externalCandidates' }
  ];



  parameters: Filter[] = [];
  filters: any[] = [];

  statusSelected: any = '';
  privacySelected: any = '';
  originSelected: any = '';
  dateFromFilter: any;
  dateToFilter: any;

  componentSubscriptions: any = {};

  constructor(
    private clientService: ClientService,
    private professionService: ProfessionService,
  ) { }

  ngOnInit() {
    this.buildFilters();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  buildFilters() {

    let observables: Observable<any>[] = [
      this.professionService.list(new Filter('group', this.client)),
      this.clientService.getJobCategories(this.client)
    ];

    this.componentSubscriptions.forkJoin$ = forkJoin(
      observables, (templates, jobCategories) => ([
        templates,
        jobCategories
      ]))

      .subscribe(([templates, jobCategories]) => {
        interface IKeys {
          value: string;
          label: string
        }

        // Templates

        const templatesValues = templates.map(m => <IKeys>{ value: m.id, label: m.name });

        this.filters = this.filters.concat(
          [
            {
              name: 'template',
              label: '__jobTemplate',
              group: 'general',
              values: templatesValues
            }
          ]);

        // Job categories

        (
          {
            organizations: this.organizations,
            locations: this.locations,
            functions: this.functions,
            professions: this.professions
          }
          = this._initJobCategoriesFilter(jobCategories)
        );

        this.filters = this.filters.concat([
          { name: 'organization', label: '__organization', group: 'general', values: this.organizations },
          { name: 'function', label: '__function', group: 'general', values: this.functions },
          { name: 'location', label: '__location', group: 'general', values: this.locations },
          { name: 'profession', label: '__profession', group: 'general', values: this.professions },
        ]);

        // Job Status and type
        this.filters = this.filters.concat([
          { name: 'status', label: '__jobStatus', group: 'general', values: this.jobStatus },
          { name: 'privacy', label: '__jobType', group: 'general', values: this.jobTypes },
        ]);

        const phasesValues: any[] = PHASES.map(p => <IKeys>{
          value: p.code,
          label: p.name
        });

        //candidates filters
        this.filters = this.filters.concat([
          { name: 'candidate_status', label: '__status', group: 'general', values: this.candidateStatuses },
          { name: 'phase', label: '__phases', group: 'general', values: phasesValues },
          { name: 'is_employee', label: '__typeOfCandidate', group: 'general', values: this.originValues },
        ]);

        if (this.defaultFilters && this.defaultFilters.length > 0) {
          this.setDefaultFilters();
        }


      });

  }

  _initJobCategoriesFilter(jobCategories: JobCategory[]) {

    interface IKeys {
      value: number;
      label: string;
    }

    const organizations = jobCategories.filter(jobCategory => jobCategory.category === 'ORG')
      .map(m => <IKeys>{ value: m.id, label: m.value });
    const locations = jobCategories.filter(jobCategory => jobCategory.category === 'LOC')
      .map(m => <IKeys>{ value: m.id, label: m.value });
    const functions = jobCategories.filter(jobCategory => jobCategory.category === 'FUN')
      .map(m => <IKeys>{ value: m.id, label: m.value });
    const professions = jobCategories.filter(jobCategory => jobCategory.category === 'PRO')
      .map(m => <IKeys>{ value: m.id, label: m.value });

    organizations.sort((a, b) => (a.label.localeCompare(b.label)));
    functions.sort((a, b) => (a.label.localeCompare(b.label)));
    locations.sort((a, b) => (a.label.localeCompare(b.label)));
    professions.sort((a, b) => (a.label.localeCompare(b.label)));

    return { organizations, locations, functions, professions };
  }

  getFilterValue(filter) {

    const filterIsSelected = this.parameters.find(param => param.name === filter.name);
    return filterIsSelected ? filterIsSelected.value : null;
  }

  getFilterValueLabel(filter) {

    const selectedFilter: any = this.getFilterValue(filter);
    return selectedFilter ? filter.values.filter(value => selectedFilter.includes(value.value)).map(value => value.label) : null;
  }

  setFilterValue(filter, values) {
    if (values) {
      this.parameters = setFilter(this.parameters, new Filter(filter.name, values));
    }
  }

  applyFilters() {
    this.createDateFilter();
    this.createCandidateStatusFilter();
    this.changeCloseFilters();
    this.appliedFilters.emit(this.parameters);
  }

  createDateFilter() {
    if (this.dateFromFilter != null) {
      this.dateFromFilter.setHours(0, 0, 0, 0);
      this.parameters = setFilter(this.parameters,
        new Filter('date_from', this.dateFromFilter.toString()));

    }
    if (this.dateToFilter != null) {
      this.dateToFilter.setHours(23, 59, 0, 0);
      this.parameters = setFilter(this.parameters,
        new Filter('date_to', this.dateToFilter.toString()));
    }
  }

  setStatusFilter(status) {
    this.statusSelected = status;
    this.parameters = setFilter(this.parameters, new Filter('status', status));
  }

  setPrivacyFilter(privacy) {
    this.privacySelected = privacy;
    this.parameters = setFilter(this.parameters, new Filter('privacy', privacy));
  }

  filterCandidateStatus(value: string) {
    this.candidateStatusList[value] = !this.candidateStatusList[value];
  }

  setEmployeeFilter(origin) {
    this.originSelected = origin;
    this.parameters = setFilter(this.parameters, new Filter('is_employee', origin === 'employee' ? 'true' : 'false'));
  }

  createCandidateStatusFilter() {

    let status = [];

    if (this.candidateStatusList.PASS) {
      status = status.concat('PASS');
    }
    if (this.candidateStatusList.PND) {
      status = status.concat('PND')
    }
    if (this.candidateStatusList.KO) {
      status = status.concat('KO');
    }

    this.parameters = setFilter(this.parameters, new Filter('candidate_status', status));
  }

  resetFilters() {
    this.parameters = [];
    this.dateFromFilter = null;
    this.dateToFilter = null;
    this.statusSelected = '';
    this.privacySelected = '';
    this.originSelected = '';
    this.candidateStatusList = { PND: false, PASS: false, KO: false };
    this.minDate = new Date(2018, 4, 17);
    this.minDateEnd = new Date(2018, 4, 17);
    this.maxDateStart = new Date();
    this.maxDateEnd = new Date();
    this.applyFilters();
  }

  setDefaultFilters() {
    const locationFilters = [];
    const organizationFilters = [];
    const functionFilters = [];
    const professionFilters = [];

    this.defaultFilters.forEach(element => {
      switch (element.category) {
        case 'LOC':
          locationFilters.push(element.id);
          break;
        case 'FUN':
          functionFilters.push(element.id);
          break;
        case 'ORG':
          organizationFilters.push(element.id);
          break;
        case 'PRO':
          professionFilters.push(element.id);
          break;
        default:
          break;
      }
    });

    if (locationFilters.length > 0) {
      this.parameters = setFilter(this.parameters, new Filter('location', locationFilters));
    }
    if (organizationFilters.length > 0) {
      this.parameters = setFilter(this.parameters, new Filter('organization', organizationFilters));
    }
    if (functionFilters.length > 0) {
      this.parameters = setFilter(this.parameters, new Filter('function', functionFilters));

    }
    if (professionFilters.length > 0) {
      this.parameters = setFilter(this.parameters, new Filter('profession', professionFilters));
    }

    if (this.parameters.length > 0) {
      this.applyFilters();
    }
  }

  changeCloseFilters() {
    this.closeFilters = !this.closeFilters;
    this.emitcloseFilters();
  }

  emitcloseFilters() {
    this.closeFiltersChange.emit(this.closeFilters);
  }

  filterFixedInterval(interval: string) {

    const dateStart = new Date();
    dateStart.setHours(0, 0, 0, 0);
    const dateEnd = new Date();
    switch (interval) {
      case 'y':
        dateStart.setMonth(dateStart.getMonth() - 12);
        break;
      case 's':
        dateStart.setMonth(dateStart.getMonth() - 6);
        break;
      case 'm':
        dateStart.setMonth(dateStart.getMonth() - 1);
        break;
    }

    this.dateFromFilter = dateStart;
    this.dateToFilter = dateEnd;

  }
}
