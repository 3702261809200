import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../classes/constants';
import { Subject } from 'rxjs';
import { sortArrayOfObjectsBy } from '../../library/utils';
import { Job, JobCategory } from '../../classes/job';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { MatDialog } from '@angular/material/dialog';
import { JobsSubscriptionComponent } from './jobs-subscription/jobs-subscription.component';
import { AlertsService } from '../../services/alerts.service';
import { Subscription } from '../../partners/models/subscription';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { JobsSubscriptionFirstStepComponent } from './jobs-subscription-first-step/jobs-subscription-first-step.component';
import { injectScript } from '../../utils';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-client-hub',
  templateUrl: './client-hub.component.html',
  styleUrls: [ './client-hub.component.scss' ]
})
export class ClientHubComponent implements OnInit, OnDestroy {

  loading = false;
  loadingCategories = false;

  client_uuid: string;
  jobs: any[] = [];
  filteredJobs: any[] = [];
  base_url = Constants.BASE_URL.slice(0, -1);
  LEGAL_DATA_URL = Constants.GROUP_URL_LEGAL_DATA;
  client: Client;
  clientIsAllowed = false;
  isPrivate = false;
  hasSubscriptions: boolean = false;

  displayClosedMessage = false;

  categoriesFilters = {
    PRO: [],
    ORG: [],
    FUN: [],
    LOC: [],
  };

  filteredCategoriesFilters = {
    PRO: [],
    ORG: [],
    FUN: [],
    LOC: [],
  };

  selectedFilters = [];

  displayFilterPopup = {
    PRO: false,
    ORG: false,
    FUN: false,
    LOC: false,
  };

  searchTerm = {
    PRO: new Subject<string>(),
    ORG: new Subject<string>(),
    FUN: new Subject<string>(),
    LOC: new Subject<string>(),
  };

  parsedCategoriesGroups = {
    PRO: '__profession',
    ORG: '__organization',
    FUN: '__function',
    LOC: '__localization',
  };

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private dialog: MatDialog,
    private alertService: AlertsService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.client_uuid = this.activatedRoute.snapshot.paramMap.get('client');
    this.isPrivate = this.activatedRoute.snapshot.url[ 0 ].path === 'private-jobs-hub';
    this.getClientData(this.client_uuid);
    this.isPrivate ? this.getPrivateJobs(this.client_uuid) : this.getJobs(this.client_uuid);
    this.doSearch();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  getClientData(client_uuid: string) {
    this.componentSubscriptions.clientServiceGetPublicClient$ = this.clientService.getPublicClient(client_uuid)
      .subscribe(
        response => {
          this.client = response;
          if (response.logo && response.logo.indexOf('http') === -1) {
            this.client.logo = `${Constants.FILE_URL}${response.logo}`;
          }
          if (this.client.extra_config.appearance) {
            this.setClientFavicon();
          }
          this.addCookieBot();
          this.setupSubscriptions();
          if (response.extra_config.landbot) {
            injectScript(response.extra_config.landbot, this.elementRef);
          }
        },
        error => this.displayClosedMessage = true
      );
  }


  getSanitizedClientFont(): SafeStyle {
    const font = this.client.extra_config.appearance.font.primary ? this.client.extra_config.appearance.font.primary : 'Roboto, sans-serif';

    return this.sanitizer.bypassSecurityTrustStyle(`font-family:${font} !important;`);
  }

  setClientFavicon() {
    const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = this.client.extra_config.appearance.favicon ? this.client.extra_config.appearance.favicon : 'favicon.ico';
    document.getElementsByTagName('head')[ 0 ].appendChild(link);
  }

  getJobs(client_uuid: string) {
    this.loading = true;
    this.componentSubscriptions.clientServiceGetPublicProfessions$ = this.clientService.getPublicProfessions(client_uuid)
      .subscribe(
        response => {
          this.jobs = this._serializeJobs(response);
          this.filteredJobs = this.jobs;
          this._serializeFilterCategories();
          this.clientIsAllowed = true;
          this.loading = false;
        },
        error => this.displayClosedMessage = true
      );
  }

  getPrivateJobs(client_uuid: string) {
    this.loading = true;
    this.componentSubscriptions.clientServiceGetPrivateProfessions$ = this.clientService.getPrivateProfessions(client_uuid)
      .subscribe(
        response => {
          this.jobs = this._serializeJobs(response);
          this.filteredJobs = this.jobs;
          this._serializeFilterCategories();
          this.clientIsAllowed = true;
          this.loading = false;
        },
        error => console.log(error)
      );
  }

  _serializeJobs(professions: JobsByProfession[]) {
    const jobs_show = professions
      .filter(profession => profession.jobs.length > 0)
      .reduce((jobs, profession) => jobs = jobs.concat(this._addProfessionToJobs(profession)), [])
      .map(job => {
        job.categories = this.sortJobCategories(job.categories);
        return job;
      });
    return sortArrayOfObjectsBy(jobs_show, 'name', 1);
  }

  _addProfessionToJobs(profession: JobsByProfession): Job[] {
    return profession.jobs.map(job => {
      job.profession_name = profession.name;
      return job;
    });
  }

  sortJobCategories(categories: JobCategory[]) {
    const getCategoriesOrder = (category: JobCategory) => {
      const categoriesOrder = Object.keys(this.categoriesFilters);
      return categoriesOrder.indexOf(category.category);
    };

    const sortedCategories = categories.sort((a, b) => getCategoriesOrder(a) - getCategoriesOrder(b));
    return sortedCategories;
  }

  _serializeFilterCategories() {

    this.loadingCategories = true;

    this.componentSubscriptions.clientServiceGetJobCaegories$ = this.clientService.getJobCategoriesByUUID(this.client_uuid)
      .subscribe(
        categories => {

          const jobCategories = categories.map(category => {
            return {
              'id': category[ 'id' ],
              'category': category[ 'category' ],
              'value': category[ 'value' ],
              'display_value': category[ 'display_value' ]
            };
          });

          Object.keys(this.categoriesFilters)
            .forEach(categoryGroup => {
              const categoriesBycategoryGroup = jobCategories.filter(jobCategory => jobCategory.category === categoryGroup && jobCategory.value.toUpperCase() !== 'ALL');
              this.categoriesFilters[ categoryGroup ] = sortArrayOfObjectsBy(categoriesBycategoryGroup, 'value', 1);
              this.filteredCategoriesFilters[ categoryGroup ] = this.categoriesFilters[ categoryGroup ];
            });

          this.loadingCategories = false;
        },
        error => console.log(error)
      );
  }


  doSearch(): void {

    Object.keys(this.searchTerm)

      .forEach(categoryGroup => {

        this.componentSubscriptions.searchTerm$ = this.searchTerm[ categoryGroup ].pipe(
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe(search => {
          return this.filterCategories(search, categoryGroup);
        });

      });
  }

  setupSubscriptions() {
    switch (this.client.extra_config.alert_subscriptions) {
      case 'BTH':
        this.hasSubscriptions = true;
        break;
      case 'PUB':
        this.hasSubscriptions = this.isPrivate ? false : true;
        break;
      case 'PRI':
        this.hasSubscriptions = this.isPrivate ? true : false;
        break;
    }
  }

  toggleFilter(category) {
    const filterIndex = this.selectedCategoryIndex(category);
    filterIndex > -1 ? this.selectedFilters.splice(filterIndex, 1) : this.selectedFilters.push(category);
    this.filteredJobs = this.selectedFilters.length > 0 ? this.filterJobs() : this.jobs;
  }

  filterJobs() {
    const filteredJobs = this.jobs.filter(job => job.categories.length > 0)
      .filter(job => {
        return job.categories.every((category) => this._categoryEqualsAll(category) || this._checkFilterInCategoryGroup(category));
      });
    return filteredJobs;
  }

  _checkFilterInCategoryGroup(category) {
    const categoryGroupValues = this.selectedFilters
      .filter(item => item.category === category.category)
      .map(filteredItem => filteredItem.value);
    return categoryGroupValues.length > 0 ? categoryGroupValues.indexOf(category.value) > -1 : true;
  }

  _categoryEqualsAll(category) {
    return category.value.toUpperCase() === 'ALL';
  }

  selectedCategoryIndex(category) {
    return this.selectedFilters.findIndex(item => item.value === category.value && item.category === category.category);
  }

  filterCategories(search, categoryGroup) {
    this.filteredCategoriesFilters[ categoryGroup ] = this.categoriesFilters[ categoryGroup ].filter(category => {
      return category.value.toLowerCase()
        .indexOf(search.toLowerCase()) > -1;
    });
  }

  filterProfessionCategory(categories: JobCategory[]): JobCategory[] {
    return categories.filter(category => category.category !== 'PRO');
  }

  showModalAskEmployee() {

    const dialogRef = this.dialog.open(JobsSubscriptionFirstStepComponent, {
      disableClose: true,
      width: '480px',
      maxHeight: '40vh',
      panelClass: 'jobs-subscription__modal',
      data: {
        filters: this.categoriesFilters,
        color: this.client.extra_config.appearance.color.primary,
        private: this.isPrivate,
        terms: this.client.terms_and_conditions
      }
    });

    this.componentSubscriptions.dialogRefAfterClosed$ = dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') this.showModal(result);
    });

  }

  showModal(option) {

    const dialogRef = this.dialog.open(JobsSubscriptionComponent, {
      disableClose: true,
      width: '980px',
      maxHeight: '80vh',
      panelClass: 'jobs-subscription__modal',
      data: {
        filters: this.categoriesFilters,
        color: this.client.extra_config.appearance.color.primary,
        private: this.isPrivate,
        terms: this.client.terms_and_conditions,
        option: option,
        client: this.client
      }
    });

    this.componentSubscriptions.dialogRefComponentInstanceActiveFiltersChange$ = dialogRef.componentInstance.activeFiltersChange.subscribe((subscription: Subscription) => {
      this.createSubscription(subscription, dialogRef);
    });

    this.componentSubscriptions.dialogRefAfterClosedShowModal$ = dialogRef.afterClosed().subscribe(result => {
      if (result == 'back') this.showModalAskEmployee();
    });

  }

  createSubscription(subscription: Subscription, dialogRef) {
    this.componentSubscriptions.clientServiceCreateSubscriptionForClient$ = this.clientService.createSubscriptionForClient(subscription, this.client_uuid).subscribe(
      () => {
        this.alertService.setAlert({ type: 'success', message: 'Subscription was successful' });
        dialogRef.close();
      },
      () => this.alertService.setAlert({ type: 'error', message: 'Error when trying to subscribe' })
    );
  }

  addCookieBot() {
    let script = document.createElement('script');
    script.setAttribute('src', Constants.COOKIE_BOT_URL);
    if (this.client.extra_config.analytics && this.client.extra_config.analytics.cookieBotCode) {
      script.setAttribute('data-cookiefirst-key', this.client.extra_config.analytics.cookieBotCode);
    } else {
      script.setAttribute('data-cookiefirst-key', Constants.COOKIE_BOT_UUID);
    }
    script.setAttribute('type', 'text/javascript');
    document.head.appendChild(script);
  }

}

class JobsByProfession {
  description: string;
  name: string;
  jobs: Job[];
}
