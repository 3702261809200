import { HttpClient, HttpResponse } from '@angular/common/http';
import { JobCategory, Job } from './../classes/job';
import { Injectable } from '@angular/core';
import { Constants } from '../classes/constants';
import { buildFilter } from '../library/utils';
import { Observable, BehaviorSubject } from 'rxjs';
import { Filter } from './../library/classes/filter';
import { take } from 'rxjs/operators';

@Injectable()
export class JobService {
  pathType = new BehaviorSubject<string>('candidates');

  constructor(private httpClient: HttpClient) { }

  saveJobToLocal(job) {
    localStorage.setItem('job', JSON.stringify(job));
  }

  clearLocalJob() {
    localStorage.removeItem('job');
  }

  getLocalJob(): Job {
    const localJob = localStorage.getItem('job')
    return Boolean(localJob) ? Object.assign(new Job(), JSON.parse(localStorage.getItem('job'))) : null;
  }

  isExpired(job: Job): boolean {
    return job.expiration_time ? new Date(job.expiration_time as Date) < new Date() : false;
  }

  isClosed(job: Job): boolean {
    return job.closed ? true : false;
  }

  isPrivate(job: Job): boolean {
    return job.privacy === Job.JOB_PRI;
  }

  getJobList(...parameters): Observable<HttpResponse<Job[]>> {

    const filter = buildFilter(...parameters);
    const url = `${Constants.JOB_URL}${filter}`;

    return this.httpClient.get<Job[]>(url, { observe: 'response' });
  }

  getJobDetail(pk: string): Observable<Job> {
    const url = `${Constants.JOB_URL}${pk}/`;
    return this.httpClient.get<Job>(url)
      .pipe(take(1));
  }

  getJobFeedbackResults(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}feedback-results/`
    return this.httpClient.get(url);
  }

  getJobInfo(pk: string): Observable<Job> {
    const url = Constants.JOB_INFO_URL.replace('{id}', pk);
    return this.httpClient.get<Job>(url)
      .pipe(take(1));
  }

  createJob(job: Job): Observable<Job> {
    return this.httpClient.post<Job>(Constants.JOB_URL, this.removeInterviewEmails(job));
  }

  setJobCategories(job: Job, jobCategories: JobCategory[]): Observable<JobCategory[]> {
    const url = `${Constants.JOB_URL}${job.id}/add-categories/`;
    return this.httpClient.post<JobCategory[]>(url, jobCategories.map(jobCategory => jobCategory.id));
  }

  updateJob(job: Job): Observable<Job> {
    const url = `${Constants.JOB_URL}${job.id}/`;
    return this.httpClient.put<Job>(url, this.removeInterviewEmails(job));
  }
  highlightJob(jobId: number): Observable<any> {
    const url = Constants.HIGHLIGHT_JOB.replace('{id}', jobId.toString());
    return this.httpClient.put<any>(url, {});
  }

  updateMultipostingConfig(job: Job, multipostingConfig: any): Observable<Job> {
    const url = Constants.JOB_MULTIPOSTING_CONFIG.replace('{id}', job.id.toString());
    return this.httpClient.put<Job>(url, multipostingConfig);
  }

  removeJob(job: Job): Observable<Job> {
    const url = `${Constants.JOB_URL}${job.id}/`;
    return this.httpClient.delete<Job>(url);
  }

  getJobCategories(client_id: string): Observable<JobCategory[]> {
    const filter = buildFilter(new Filter('group', client_id));
    const url = `${Constants.JOB_CATEGORIES_URL}${filter}`;
    return this.httpClient.get<JobCategory[]>(url);
  }

  getJobCategoriesByUuid(client_uuid: string): Observable<JobCategory[]> {
    const filter = buildFilter(new Filter('group_uuid', client_uuid));
    const url = `${Constants.JOB_CATEGORIES_URL}${filter}`;
    return this.httpClient.get<JobCategory[]>(url);
  }

  submitFile(file: FormData): Observable<any> {
    return this.httpClient.post<any>(Constants.SUBMIT_FILE, file);
  }

  submitFiletoCandidates(file: FormData, candidateId: string): Observable<any> {
    const url = `${Constants.SUBMIT_TO_CANDIDATES.replace('{id}', candidateId)}`;
    return this.httpClient.post<any>(url, file);
  }

  submitPublicFile(file: FormData): Observable<any> {
    return this.httpClient.post<any>(Constants.SUBMIT_PUBLIC_FILE, file);
  }

  getFunnelData(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}funnel/`;
    return this.httpClient.get(url);
  }

  getCandidatesByCampaign(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}candidates-by-campaign`;
    return this.httpClient.get(url);
  }

  getAgeGenderData(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}age-gender-distribution/`;
    return this.httpClient.get(url);
  }

  getDataByField(job_id: string, field: string): Observable<any> {
    const query = buildFilter(new Filter('field', field));
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}count/` + query;
    return this.httpClient.get(url);
  }

  getFormData(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}form/questions/`;
    return this.httpClient.get(url);
  }

  getStatusData(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}form/stats/`;
    return this.httpClient.get(url);
  }

  getCuteData(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}instruments/`;
    return this.httpClient.get(url);
  }

  getKPIData(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}kpi/`;
    return this.httpClient.get(url);
  }

  getGoogleData(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}google/`;
    return this.httpClient.get(url);
  }

  getCuteInstruments(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}instruments/`;
    return this.httpClient.get(url);
  }

  getCuteStats(job_id: string): Observable<any> {
    const url = `${Constants.JOB_ANALYTICS_URL.replace('{id}', job_id)}instruments/stats/`;
    return this.httpClient.get(url);
  }

  disableJob(job_id: string): Observable<any> {
    const url = `${Constants.JOB_DISABLE_URL.replace('{id}', job_id)}`;
    return this.httpClient.get(url)
      .pipe(take(1));
  }

  closeJob(job_id: string, close_cause: string, request_feedback: boolean = false): Observable<Job> {
    const url = `${Constants.JOB_CLOSE_URL.replace('{id}', job_id)}`;
    const body = { close_cause: close_cause, request_feedback: request_feedback };
    return this.httpClient.post<Job>(url, body);
  }

  exportCsv(job_id: string): Observable<any> {
    const url = `${Constants.JOB_EXPORT_CSV.replace('{id}', job_id)}`;
    return this.httpClient.get(url);
  }

  removeInterviewEmails(job: Job): Job {
    delete job.emails[ 'INTERVIEWS' ];
    return job;
  }

  getLandingUrl(job_id): Observable<any> {
    const url = `${Constants.JOB_LANDING_URL_URL.replace('{id}', job_id)}`;
    return this.httpClient.get(url);
  }

}
