import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carriagereturn'
})
export class CarriagereturnPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(/&#13;/gm, "\n").replace(/<br>/gm, "\n");
  }
}
