import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Instrument } from '../../../instruments/models/instrument';
import { UserService } from '../../../services/user-service.service';
import { InstrumentsService } from '../../../instruments/instruments.service';

@Component({
  selector: 'app-instruments-info',
  templateUrl: './instruments-info.component.html',
  styleUrls: ['./instruments-info.component.scss']
})
export class InstrumentsInfoComponent implements OnInit {

  @Output() selectedInstrumentsEmitter = new EventEmitter();

  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    public instrumentsService: InstrumentsService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  closeInfoDialog() {
    this.instrumentsService.viewInfoDialog = false;
    this.instrumentsService.infoDialogInstrument = null;
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  instrumentContact(instrument: Instrument): void {
    const group = this.userService.getUser()
      .group;
    window.open(`mailto:info@aplygo.com?subject=TKT Instrument for ${group.name}&body=Hello! Your client ${group.name} has indicated that he wants to use the instrument ${instrument.name}. Please contact with the client on the e-mail: ${group.client_email}.`, '_self');
  }

  setConfigDialogRankeableAlternative(alternative: any, value: number) {

    this.instrumentsService.configDialogInstrument.job_config[alternative] = value;

    if (alternative === this.instrumentsService.configDialogInstrument.config.rankeable_alternative_field) {
      this.instrumentsService.configDialogInstrument.rankeable_fields = this.instrumentsService.configDialogInstrument.config.rankeable_fields[value];
    }

    let mappingIdKey = this.instrumentsService.configDialogInstrument.job_config["mappingid"];

    if (alternative === 'mappingid') {
      mappingIdKey = value == null ? 'default' : '' + value;
    } else if (mappingIdKey === null || mappingIdKey === undefined) {
      mappingIdKey = 'default';
    }

    if (alternative === 'scale' || alternative === 'mappingid') {
      this.instrumentsService.configDialogInstrument.rankeable_fields = this.instrumentsService.configDialogInstrument.config.rankeable_fields['' + mappingIdKey];
      this.instrumentsService.configDialogInstrument.talent_rank = this.instrumentsService.getTalentRank(this.instrumentsService.configDialogInstrument, '' + mappingIdKey);
    }
  }

  setReferenceField(field: any, max: number) {
    field.field = field.field.replace(' ', '_');

    setTimeout(() => {
      if (field.reference_field > max) {
        field.reference_field = +max;
      } else if (field.reference_field < 1) {
        field.reference_field = 1;
      }
    }, 0);
  }

  getRadarPreviewData(): { 'Reference Profile': any[] } {
    return { 'Reference Profile': this.instrumentsService.configDialogInstrument.talent_rank.map(item => item.reference_field) };
  }

  getRadarPreviewDataForGroups(group): { 'Reference Profile': any[] } {
    return { 'Reference Profile': group.fields.map(item => item.reference_field) };
  }

  getRadarPreviewLabels(): Instrument[] {
    return this.instrumentsService.configDialogInstrument.talent_rank.map(item => item.field);
  }

  getRadarPreviewLabelsForGroups(group): Instrument[] {
    return group.fields.map(item => item.field);
  }

  toggleInstrument(instrument: Instrument) {
    instrument.selected = !instrument.selected;
    this.selectedInstrumentsEmitter.emit();
  }

}
