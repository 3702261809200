import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { FeedbackResult, FeedbackConfigItem } from './../../classes/feedback';
import { Subscription, Observable, concat, forkJoin, zip } from 'rxjs';
import { PHASES, Phase } from './../../classes/phases';
import { Job } from './../../classes/job';
import { ActivatedRoute } from '@angular/router/';
import { JobService } from './../../services/job.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Constants } from '../../classes/constants';
import * as moment from 'moment';
import { FunnelData } from '../../classes/funnel-data';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { slugify } from '../../library/utils';
import { TranslateService } from '@ngx-translate/core';
import { COLOR_VARIABLES } from '../../utils/color-variables';
import { keyframes } from '@angular/animations';

@Component({
  selector: 'app-job-analytics',
  templateUrl: './job-analytics.component.html',
  styleUrls: [ './job-analytics.component.scss' ]
})


export class JobAnalyticsComponent implements OnInit, OnDestroy {
  client: Client;

  job: Job;
  job_id = '';
  analytics$: Subscription;
  activate$: Subscription;
  indexSelected = 0;
  charts: any = {};
  conversionRateList: Array<any>;

  totalVisitsLanding = 0;
  totalCandidates = 0;
  rejectedInscription = 0;

  isAgeAndGenderFormEmpty: any;
  isQuestionsResponseEmpty = false;
  isUserTimeEmpty = false;
  isAgeGenderGoogleEmpty = false;
  isCampaingEmpty = false;
  isCityEmpty = false;
  isIstrumentsEmpty = false;
  isIstrumentsStatsEmpty = false;
  hasFeedbackResultsData = false;
  hasSourceData = false;

  getFunnelLabel = PHASES.map(phase => phase.name);

  phases: Phase[] = PHASES;

  componentSubscriptions: any = {};
  jobsPerFunction: { categories: string[], labels: string[], data: any } = {
    labels: [],
    categories: [],
    data: []
  };

  feedbackResults: { labels: string[], data: { name: string, data: number[] }[] } = {
    labels: [],
    data: [],
  };

  feedbackPhasesContacted: { labels: string[], categories: string[], data: { [ key: string ]: number }[] } = {
    labels: [],
    categories: [],
    data: [],
  };

  candidatesSource: { labels: string[], data: { name: string, data: number[] }[] } = {
    labels: [],
    data: [],
  };

  totalFeedbackContacted: number = 0;
  totalFeedbackCompleted: number = 0;

  sourceToolTip;
  feedbackToolTip;

  constructor(
    private jobService: JobService,
    private activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
    private clientService: ClientService
  ) { }




  ngOnInit() {
    this.getJobDetail();
    this.sourceTooltip();
    this.feedbackTooltip();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  /*source*/
  getSourceData() {

    this.componentSubscriptions.getCandidatesByCampaign$ = this.jobService.getCandidatesByCampaign(this.job_id).subscribe(sources => {

      if (Object.keys(sources).length) {
        this.hasSourceData = true;
        this.candidatesSource.labels = Object.keys(sources);
        const sourceValues = Object.values(sources);
        sourceValues.forEach(source => {
          Object.entries(source).forEach(([ phase, nCandidates ]) => {
            let phaseExists = this.candidatesSource.data.findIndex(data => data.name === phase);
            if (phaseExists !== -1) {
              this.candidatesSource.data[ phaseExists ].data.push(nCandidates);
            } else {
              this.candidatesSource.data.push({ name: phase, data: [ nCandidates ] });
            }
          });
        });
        this.candidatesSource.data.map(data => data.name = this.translateService.instant('__' + data.name));
      }

    });
  }

  sourceTooltip() {
    this.componentSubscriptions.translateServiceGet$ = this.translateService.get('__phase').subscribe(textTranslated => {
      const phaseTranslate = this.translateService.instant('__phase');
      const totalTranslate = this.translateService.instant('__total');
      this.sourceToolTip = {
        tooltip: {
          formatter: function () {
            let tooltipText = '<table>';
            tooltipText += "<tr><td style='padding:0;text-transform:capitalize;'>" + this.x + '</td></tr>';
            tooltipText += "<tr><td class='tooltipTitles' style='padding:0;'>" + phaseTranslate + "</td><td class='tooltipTitles' style='padding:0;'> " + totalTranslate + '</td></tr>';
            this.points.forEach(element => {
              if (element.y > 0) {
                tooltipText += "<tr><td style='padding:0;'> <span class='dot-tooltip' style='padding:0; background-color: " + element.series.color + "'></span> <span>" + element.series.name + "</span>" + ': ' + "</td><td class='points-position' style='padding:0;'> " + element.y + '</td></tr>';
              }
            });
            tooltipText += "<tr><td style='padding:0'>Total:</td> " + "</td><td class='points-position' style='padding:0;'> " + this.points[ 0 ].total + '</td></tr></table';
            return tooltipText;
          },
          backgroundColor: 'white',
          borderRadius: 10,
          borderWidth: 0
        },
        yAxis: {
          allowDecimals: false,
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color: 'gray'
            }
          }
        },
        xAxis: {
          labels: {
            style: {
              textTransform: 'capitalize'
            }
          }
        }
      };
    });
  }

  feedbackTooltip() {
    this.componentSubscriptions.translateServiceGet$ = this.translateService.get('__phase').subscribe(textTranslated => {
      const phaseTranslate = this.translateService.instant('__phase');
      const totalTranslate = this.translateService.instant('__total');
      this.feedbackToolTip = {
        tooltip: {
          formatter: function () {
            let tooltipText = '<table>';
            tooltipText += "<tr><td style='padding:0;text-transform:capitalize;display:flex;align-items:center;'>" + this.x + '</td></tr>';
            tooltipText += "<tr><td class='tooltipTitles' style='padding:0;'>" + phaseTranslate + "</td><td class='tooltipTitles' style='padding:0;'> " + totalTranslate + '</td></tr>';
            this.points.forEach(element => {
              if (element.y > 0) {
                tooltipText += "<tr><td style='padding:0;'> <span class='dot-tooltip' style='padding:0; background-color: " + element.series.color + "'></span> <span>" + element.series.name + "</span>" + ': ' + "</td><td class='points-position' style='padding:0;'> " + element.y + '</td></tr>';
              }
            });
            tooltipText += "<tr><td style='padding:0'>Total:</td> " + "</td><td class='points-position' style='padding:0;'> " + this.points[ 0 ].total + '</td></tr></table';
            return tooltipText;
          },
          backgroundColor: 'white',
          borderRadius: 10,
          borderWidth: 0
        }
      };
    });
  }



  /* FEEDBACK ANALYTICS */

  getFeedbackResults() {
    this.componentSubscriptions.jobGetFeedbackResults$ = this.jobService.getJobFeedbackResults(this.job_id).subscribe(
      (feedbackResult: FeedbackResult) => {
        this.hasFeedbackResultsData = false;

        feedbackResult.phases = this.getOrderedPhases(feedbackResult.phases);

        const configItems: FeedbackConfigItem[] = this._getAvailableLanguageConfig(feedbackResult.configuration);
        this.feedbackResults.data = this.getSerializedFeedbackResults(feedbackResult.phases, configItems.length);

        this.feedbackPhasesContacted.labels = [ this.translateService.instant('__contacted'), this.translateService.instant('__completed') ];
        this.feedbackPhasesContacted.data = this.getFeedbackContactedData(feedbackResult.phases);

        this.feedbackPhasesContacted.categories = Object.keys(feedbackResult.phases).map(phase => this.translateService.instant('__' + phase));

        this.totalFeedbackContacted = this.feedbackPhasesContacted.data.reduce((result, currentPhase) => result += currentPhase[ this.feedbackPhasesContacted.labels[ 0 ] ], 0);

        if (this.hasFeedbackResultsData) {
          const totalLevelVotes: number[] = this.getTotalLevelVotes(this.feedbackResults.data);
          this.feedbackResults.labels = this.getFeedbackLabels(configItems, totalLevelVotes, Math.max(...totalLevelVotes));
          this.totalFeedbackCompleted = this.feedbackPhasesContacted.data.reduce((result, currentPhase) => result += currentPhase[ this.feedbackPhasesContacted.labels[ 1 ] ], 0);
        }
      }
    );
  }

  getOrderedPhases(phases: FeedbackResult[ 'phases' ]): FeedbackResult[ 'phases' ] {
    const filteredOrder = [ 'PHO', 'OA', 'HR', 'BUS', 'HIR' ].filter(phaseKey => Boolean(phases[ phaseKey ]));
    return filteredOrder.reduce((result, phaseKey) => {
      result[ phaseKey ] = phases[ phaseKey ];
      return result;
    }, {});
  }

  getSerializedFeedbackResults(phases: FeedbackResult[ 'phases' ], items: number): { name: string, data: number[] }[] {
    return Object.entries(phases).map(
      ([ phaseKey, phaseValues ]: [ string, any ]) => {
        const dataArray = Array.from(Array(items).keys()).map(e => 0);

        Object.entries(phaseValues.results).forEach(
          ([ level, score ]: [ string, string ]) => {
            dataArray[ parseInt(level) - 1 ] = parseInt(score);
            if (+score > 0) {
              this.hasFeedbackResultsData = true;
            }
          }
        )

        return {
          name: this.translateService.instant('__' + phaseKey),
          data: dataArray
        };
      }
    )
  }

  getTotalLevelVotes(resultsData: { name: string, data: number[] }[]): number[] {
    return resultsData.reduce((result, currentPhase) => {
      currentPhase.data.forEach(
        (levelScore, j) => {
          result[ j ] = result[ j ] === undefined ? 0 : result[ j ];
          result[ j ] += levelScore;
        }
      );
      return result
    }, []);
  }


  getFeedbackLabels(feedbackItems: FeedbackConfigItem[], totalLevelVotes: number[], mostVotedLevelCount: number): string[] {
    return feedbackItems.map((item, i) => {
      const spanClass = `${totalLevelVotes[ i ] === mostVotedLevelCount ? 'class="highlighted"' : ''}"`;
      const itemText = item.text ? `<span ${spanClass} title="${item.text}">${item.text}</span>` : '';

      if (item.image) {
        return `<img style="width: 38px; padding: 0 8px 0 0" src="${item.image}"></img> ${itemText}`;
      } else {
        return `<span ${spanClass}> ${item.val} </span> ${itemText}`;
      }
    });
  }

  _getAvailableLanguageConfig(feedbackConfig: FeedbackResult[ 'configuration' ]): FeedbackConfigItem[] {
    const currentLang = this.translateService.getBrowserLang();
    const defaultLang = this.translateService.getDefaultLang();
    const configLangs = Object.keys(feedbackConfig);

    if (configLangs.includes(currentLang)) {
      return feedbackConfig[ currentLang ].items;

    } else if (configLangs.includes(defaultLang)) {
      return feedbackConfig[ defaultLang ].items;

    } else {
      return configLangs.length ? feedbackConfig[ configLangs[ 0 ] ].items : [];
    }
  }

  getFeedbackContactedData(phases: FeedbackResult[ 'phases' ]): { [ key: string ]: number }[] {
    return Object.keys(phases).reduce(
      (result, currentKey, i) => {
        result[ i ] = {
          [ this.translateService.instant('__contacted') ]: +phases[ currentKey ].contacted_candidates,
          [ this.translateService.instant('__completed') ]: +phases[ currentKey ].responses_count
        }
        return result;
      }, []
    )
  }

  /* ALL JOB ANALYTICS */

  getJobDetail() {
    this.componentSubscriptions.activatedRoute$ = this.activatedRoute.parent.paramMap.
      subscribe(param => {
        this.job_id = param.get('job');
        this.getJobData();
        this.getAllData();
        this.getClientAndFeedback();
        this.getSourceData();
      },
        error => console.log(error)
      );
  }

  getJobData() {
    this.componentSubscriptions.jobServiceGetJobDetail$ = this.jobService.getJobDetail(this.job_id)
      .subscribe(
        job => this.job = job
      );
  }

  getClientAndFeedback() {
    this.componentSubscriptions.client$ = this.clientService.loadClient(+this.activatedRoute.parent.snapshot.paramMap.get('client'))
      .subscribe(
        client => {
          this.client = client;
          if (client.candidates_feedback_request) {
            this.getFeedbackResults();
          }
        },
        error => console.log(error)
      );
  }

  resetSourceData() {
    this.candidatesSource = { labels: [], data: [] };
    this.hasSourceData = false;
  }

  getAllData() {
    this.charts = {};
    this.resetSourceData();
    this.componentSubscriptions.concatAnalytics$ = concat(
      this.generalTab(),
      this.screeningTab(),
      this.formTab(),
      this.evaluationTab(),
    ).subscribe(
      data => {this.charts = Object.assign(this.charts, data);}
    );
  }

  generalTab(): Observable<any> {
    const funnel$ = this.jobService.getFunnelData(this.job_id);
    const incoming_task$ = this.jobService.getDataByField(this.job_id, 'state');
    const kpi$ = this.jobService.getKPIData(this.job_id);
    return forkJoin(funnel$, incoming_task$, kpi$,
      (funnel, incoming_task, kpi) => ({ funnel: this.serializerFunnel(funnel), incoming_task: this.serializerIncomingTask(incoming_task), kpi }));
  }

  formTab(): Observable<any> {
    const questions$ = this.jobService.getFormData(this.job_id)
    const status_killer$ = this.jobService.getStatusData(this.job_id)
    const age_gender$ = this.jobService.getAgeGenderData(this.job_id)

    return forkJoin(questions$, status_killer$, age_gender$,
      (questions, status_killer, age_gender) => ({
        questions: this.serializerFormQuestion(questions),
        status_killer: this.serializerStatusKiller(status_killer),
        age_gender: this.serializerAgeGender(age_gender)
      })
    )
  }

  screeningTab(): Observable<any> {
    const googleAnalytics = this.jobService.getGoogleData(this.job_id);
    return zip(googleAnalytics, googleData => ({ google: this._serializerGoogleData(googleData) }));
  }

  _serializeGoogleCampaignData(label, value) {
    switch (label) {
      case 'avgTimeOnPage':
        return Math.round(parseFloat(value)) + ' sec';
      case 'campaign':
        return this._setNameCampaign(value);
      default:
        return value;
    }
  }

  _serializerGoogleData(data) {
    Object.keys(data).map((analyticKey) => {
      if (!data[ analyticKey ]) {
        data[ analyticKey ] = [];
      }
    })

    const ageAndGenderDistribution = data.age_and_gender_distribution || [];
    const age_gender = {
      name: 'Age Gender',
      labels: Array.from(new Set(ageAndGenderDistribution.map(value => value.userAgeBracket))),
      male: ageAndGenderDistribution.filter(value => value.userGender === 'male')
        .map(value => parseInt(value.users)),
      female: ageAndGenderDistribution.filter(value => value.userGender === 'female')
        .map(value => parseInt(value.users))
    }
    this.isAgeGenderGoogleEmpty = age_gender.female.every(m => m.length === 0) && age_gender.female.every(f => f.length === 0);

    const defaultCampaignDistr = data.campaign_distribution || [];
    let labels = Object.keys(defaultCampaignDistr[ 0 ] || {});
    labels = labels.filter(label => label !== 'avgTimeOnPage'); //filtering avg time in campaigns for ga4 update.
    const values = defaultCampaignDistr.map(campaign => labels.map(label => this._serializeGoogleCampaignData(label, campaign[ label ])));
    const campaign = {
      name: 'Campaign',
      labels: labels.map(label => label === 'avgTimeOnPage' ? 'AVG Time on Page' : label),
      data: values
    }
    this.isCampaingEmpty = campaign.data.length === 0;

    const device_type = {
      name: 'Device Type',
      labels: (data.device_type_distribution || [])
        .map(value => value.deviceCategory),
      data: (data.device_type_distribution || [])
        .map(value => value.users || 0)
    }
    this.totalVisitsLanding = device_type.data.length !== 0 ? device_type.data.reduce((a, b) => parseInt(a) + parseInt(b)) : 0;
    device_type.data.length === 0 ? device_type.data = [ 0, 0, 0 ] : '';


    const userTimeDistribution = data.users_time_distribution || [];
    const users_time = {
      name: "Users Time",
      data: userTimeDistribution.map(value => ({
        data: parseInt(value.users),
        name: moment(value.date)
          .format('l')
      }))
    }
    this.isUserTimeEmpty = users_time.data.every(data => data.data === 0);

    const defaultCountryDistr = (data.country_distribution || []);
    const city = {
      name: "City",
      labels: Object.keys(defaultCountryDistr[ 0 ] || {}),
      data: defaultCountryDistr.map(value => Object.keys(defaultCountryDistr[ 0 ] || {})
        .map(label => value[ label ]))
    }
    const avgTimeIndex = city.labels.findIndex(label => label === 'avgTimeOnPage');
    city.labels[ avgTimeIndex ] = 'AVG Time on Page';
    city.data.map(e => e[ avgTimeIndex ] = Math.round(e[ avgTimeIndex ]) + ' sec');
    this.isCityEmpty = city.data.length === 0;

    return { age_gender, users_time, city, device_type, campaign };
  }

  _setNameCampaign(name) {
    switch (name) {
      case '(not set)':
        return 'Direct';
      case 'facebook_mkt':
        return 'Facebook AplyGo';
      case 'linkedin_mkt':
        return 'Linkedin AplyGo';
      case 'twitter_mkt':
        return 'Twitter AplyGo';
      case 'instagram_mkt':
        return 'Instagram AplyGo';
      case 'googleplus_mkt':
        return 'Google Plus AplyGo';
      default:
        return name;
    }
  }

  evaluationTab(): Observable<any> {
    const instruments$ = this.jobService.getCuteInstruments(this.job_id);
    const instruments_stats$ = this.jobService.getCuteStats(this.job_id);
    return forkJoin(instruments$, instruments_stats$, (instruments, instruments_stats) => ({ instruments: this.serializerInstrumentsData(instruments), instrument_stats: this.serializerInstrumentStats(instruments_stats) }))
  }

  serializerInstrumentStats(remote_data) {
    let labels = Object.keys(remote_data)
      .filter(value => value !== 'all_done_instruments' && value !== 'all_instruments')
    const name = 'Instrument Stats';
    const data = {
      name: 'Candidates',
      values: labels.map(value => ({ data: remote_data[ value ] }))
    };
    const config = {
      yAxis: {
        max: remote_data.all,
        title: {
          text: "Candidates"
        }
      }
    };
    const done = remote_data.all_done_instruments;
    const all = remote_data.all_instruments;
    this.isIstrumentsStatsEmpty = labels.length === 0;
    return { labels, name, data, config, all, done };
  }

  serializerInstrumentsData(data) {
    this.isIstrumentsEmpty = Object.keys(data)
      .length === 0;
    let charts = [];
    for (const value in data) {
      charts = data[ value ].__type__ === 'radar' ? charts.concat(this._getRadarInstrumentResults(data[ value ])) : charts.concat(this._getInstrumentResults(data[ value ]));
    }
    return charts;
  }

  _getInstrumentResults(instrument: any): any[] {
    const results = [];
    if (Object.keys(instrument.results).length <= 1) {
      for (const result in instrument.results) {
        const values = Object.keys(instrument.results[ result ])
          .map(label => ({ data: instrument.results[ result ][ label ] || 0 }));
        const data = instrument.__type__ === 'bar' || instrument.__type__ === 'horizontal-bar' ? { name: 'Candidates', values: values } : values;
        const result_obj = {
          name: instrument.name,
          labels: Object.keys(instrument.results[ result ]),
          data: values.length > 0 ? data : [],
          type: instrument.__type__
        };
        results.push(result_obj);
      }
    } else {
      const values = Object.keys(instrument.results)
        .map(label => ({ data: instrument.results[ label ] || 0 }));
      const data = instrument.__type__ === 'bar' || instrument.__type__ === 'horizontal-bar' ? { name: 'Candidates', values: values } : values;
      const result_obj = {
        name: instrument.name,
        labels: Object.keys(instrument.results),
        data: values.length > 0 ? data : [],
        type: instrument.__type__
      };
      results.push(result_obj);
    }
    return results;
  }

  _getRadarInstrumentResults(instrument: any): any[] {
    let labels: string[] = Object.values(instrument.field_mappings)
      .filter(label => instrument.results[ String(label) ] != null
        && instrument.results[ String(label) ].constructor != Object)
      .map(label => String(label));
    const reference_profile = instrument.__profile_reference__
    const data = {};
    data[ instrument.operation ] = labels.map(label => instrument.results[ label ])

    if (Object.values(reference_profile)[ 0 ]) {
      data[ 'reference_profile' ] = labels.map(label => reference_profile[ label ] || 0)
    }

    const result_obj = {
      name: instrument.name,
      labels: labels,
      data: data[ instrument.operation ].length > 0 ? data : [],
      type: instrument.__type__,
      scale: instrument.scale
    };
    return [ result_obj ];
  }

  serializerFunnel(server_data: any): FunnelData[] {
    const totalCandidates = server_data.visits ? server_data.visits : server_data.INS;
    const phases: Phase[] = [ new Phase('__landingVisits', 'visits') ].concat(PHASES);

    return FunnelData.getFunnelFromPhasesData(phases, server_data, totalCandidates);
  }

  serializerIncomingTask(server_data: any) {
    const name = 'Incoming Task';
    const labels = Object.keys(server_data);
    const data = labels.map(value => ({ data: server_data[ value ] || 0 }));
    return { name, data, labels };
  }

  serializerFormQuestion(server_data: any): any[] {
    const charts = Object.keys(server_data)
      .map(value => ({
        name: server_data[ value ].label,
        labels: this._getLabelsFromFormQuestion(server_data[ value ]),
        data: this._getDataFromFormQuestion(server_data[ value ])
      }));
    this.isQuestionsResponseEmpty = charts.length === 0 ? true : false;
    return charts;
  }

  _getLabelsFromFormQuestion(data): string[] {
    return Object.keys(data.values)
      .map(slug_question => data.values[ slug_question ].label || slug_question);
  }

  _getDataFromFormQuestion(data): any[] {
    return Object.keys(data.values)
      .map(slug_question => {
        return { data: data.values[ slug_question ].count, color: data.values[ slug_question ].kq === true ? COLOR_VARIABLES[ '$_CHART_PINK' ] : '' };
      });
  }

  serializerStatusKiller(server_data) {
    this.rejectedInscription = server_data.ko ? Math.round((server_data.ko * 100) / server_data.total) : 0;
    if (server_data.length === 0) { server_data = [ 0, 0, 0 ]; }
    return server_data;
  }

  serializerAgeGender(server_data) {
    this.isAgeAndGenderFormEmpty = Object.entries(server_data)
      .map(rank => rank[ 1 ]);
    this.isAgeAndGenderFormEmpty = this.isAgeAndGenderFormEmpty.filter(filter => filter.male !== 0 || filter.female !== 0)
    this.isAgeAndGenderFormEmpty = this.isAgeAndGenderFormEmpty.length === 0 ? true : false
    const name = 'Age Gender';
    const labels = Object.keys(server_data)
      .sort((a, b) => parseInt(a.split('-')[ 0 ]) - parseInt(b.split('-')[ 0 ]));
    return Object.assign({ name, labels }, {
      male: labels.map(label => server_data[ label ].male),
      female: labels.map(label => server_data[ label ].female)
    });
  }

  createLandingUrl() {
    return `${Constants.BASE_URL}job/${this.job.slug}`;
  }

  createFormUrl() {
    return `${Constants.BASE_URL}job/${this.job.slug}/register`;
  }

  _isDisabled(job: Job): boolean {
    return job ? new Date(job.expiration_time as Date) > new Date() : false;
  }

  changeTab(tab: MatTabChangeEvent) {
    setTimeout(() => {
      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 200);
  }

  getDeviceVisits(device_type: string) {
    let index = this.charts.google.device_type.labels.indexOf(device_type)
    if (index === -1) {
      return 0;
    }
    return this.charts.google.device_type.data[index];
  }

}
