import { Client } from "../partners/models/client";

export class SupportTicket {

  client: number;
  subject: string;
  body: string;
  request_type: string;
  image: string;
  identifier: string = 'Empty';
}

const HelpRequestTypes = [{ value: 'new_functionality', label: '__newFunctionality' }, { value: 'feedback', label: '__feedback' }, { value: 'bug', label: '__bug' }, { value: 'doubts', label: '__doubts' }, { value: 'other', label: '__other' }];
export { HelpRequestTypes };
