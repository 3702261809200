import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../classes/user';
import { Constants } from '../classes/constants';
import { Job } from '../classes/job';
import { GroupMultipostingProvider, MultipostingCredentials } from '../classes/multiposting';

@Injectable()
export class UserDataService {

  constructor(private httpClient: HttpClient) { }

  getUserDetail(user_id: string): Observable<User> {
    const url = `${Constants.USERS_URL}${user_id}/`;
    return this.httpClient.get<User>(url);
  }

  createUser(user: User): Observable<any> {
    const url = Constants.USERS_URL;
    return this.httpClient.post(url, user);
  }

  updateUser(user: User): Promise<any> {
    const url = `${Constants.USERS_URL}${user.id}/`;
    return this.httpClient.put(url, user)
      .toPromise();
  }

  partialUpdateUser(user: User): Observable<any> {
    const url = `${Constants.USERS_URL}${user.id}/`;
    return this.httpClient.patch(url, user);
  }

  deleteUser(user: User): Observable<any> {
    const url = `${Constants.USERS_URL}${user.id}/`;
    return this.httpClient.delete(url);
  }

  addUserGroup(users, group): Observable<any> {
    const url = Constants.ADD_USER_TO_GROUP_URL.replace('{id}', group);
    return this.httpClient.post(url, users);
  }

  addUserCategories(user: User, categories): Observable<any> {
    const url = Constants.ADD_CATEGORIES_URL.replace('{id}', user.id);
    return this.httpClient.post(url, categories);
  }

  updateUserCategories(user: User, categories): Observable<any> {
    const url = Constants.UPDATE_CATEGORIES_URL.replace('{id}', user.id);
    return this.httpClient.put(url, categories);
  }

  addJobsToUser(user: User, jobs: number[]) {
    const url = Constants.ADD_JOBS_URL.replace('{id}', user.id);
    return this.httpClient.post(url, { jobs });
  }

  addUsersToJob(job_id: string, emails: string[]) {
    const url = Constants.ADD_USERS_URL.replace('{id}', job_id);
    return this.httpClient.post(url, { emails });
  }

  retrieveMultipostingProviders(group: number): Observable<GroupMultipostingProvider[]> {
    const url = Constants.GROUP_MULTIPOSTING_PROVIDER.replace('{id}', group.toString());
    return this.httpClient.get<GroupMultipostingProvider[]>(url);
  }

  retrieveSocialNetworksUserGroup(groupId): Observable<MultipostingCredentials[]> {
    const url = Constants.USER_GROUP_NETWORK_CREDENTIALS_URL.replace('{id}', groupId);
    return this.httpClient.get<MultipostingCredentials[]>(url);
  }

  addSocialNetworks(socialNetworks: any): any {
    const url = Constants.CREATE_MULTIPOSTING_USER;
    return this.httpClient.post<any>(url, socialNetworks);
  }

  deleteSocialNetworks(credentialsId: number): any {
    const url = Constants.DELETE_MULTIPOSTING_USER.replace('{id}', credentialsId.toString());
    return this.httpClient.delete<any>(url);
  }
}
