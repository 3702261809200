
import { Alert } from './../../services/alerts.service';
import { Observable } from 'rxjs';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ClientService } from '../../partners/services/client.service';
import { Client } from '../../partners/models/client';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from '../../services/candidate.service';
import { AlertsService, ALERT_TYPES } from '../../services/alerts.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: [ './privacy-policy.component.scss' ]
})
export class PrivacyPolicyComponent implements OnInit {
  collectConsent: boolean;
  termsAccept: { key: boolean, value: string; } = { key: true, value: '__maintainMyData' };
  termsReject: { key: boolean, value: string; } = { key: false, value: '__deleteMyData' };
  validCandidate: boolean = false;
  acceptedTerms: boolean = false;
  candidate_uuid: string;
  gdprConsentPeriod: string;

  client: Client = new Client();
  client_uuid: string;
  showModal = false;

  componentSubscriptions: any = {};

  constructor(
    public clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private candidateService: CandidateService,
    private alertService: AlertsService
  ) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params.uuid) {
      this.client_uuid = this.activatedRoute.snapshot.params.uuid;
    } else {
      this.activatedRoute.firstChild.params.subscribe(
        (params: any) => {
          if (params.hasOwnProperty('client') != '') {
            this.client_uuid = params.client;
          }
        });
    }
    this.getClientDetails(this.client_uuid);
    this.candidate_uuid = this.activatedRoute.snapshot.queryParamMap.get('candidate');
    if (this.candidate_uuid) {
      this.checkIfCandidateCancollectGDPRConsent();
    }
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  getClientDetails(client_uuid) {
    this.componentSubscriptions.clientServiceGetPublicClient$ = this.clientService.getPublicClient(client_uuid)
      .subscribe(
        client => {
          this.client = Object.assign(client);
          this.client.uuid = client_uuid;
          this.gdprConsentPeriod = this.client.gdpr_consent_period;
          this.showModal = true;
        },
        error => {
          this.alertService.setAlert(this.alertService.formatErrorMessage(error));
        }
      );
  }

  collectGdprConsent() {
    this.componentSubscriptions.candidateServicecollectGdprConsent$ = this.candidateService.collectGdprConsent(this.candidate_uuid, this.collectConsent).subscribe(
      (reponse) => {
        this.acceptedTerms = true;
        this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__accountConnectedSuccessfully'));
      },
      (error) => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
    );
  }

  checkIfCandidateCancollectGDPRConsent() {
    this.componentSubscriptions.candidateServiceCancollectGDPRConsent$ = this.candidateService.checkIfCandidateCancollectGDPRConsent(this.candidate_uuid).subscribe(
      (reponse) => {
        this.validCandidate = true;
      },
      (error) => {
        this.validCandidate = false;
      }
    );
  }


}
