import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeurl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {}

  transform(v: string , args?: any): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(v);
  }
}
