import { COLOR_VARIABLES } from './../../utils/color-variables';
import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare const Highcharts: any;
@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PiechartComponent implements OnInit {

  @Input() data: { data: number, color: string }[];
  @Input() labels: string[];
  @Input() name: string;
  colors = [COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT'], COLOR_VARIABLES['$_CHART_MEDIUM_BLUE'], COLOR_VARIABLES['$_CHART_ORANGE'], COLOR_VARIABLES['$_CHART_LIGTH_GREEN'], COLOR_VARIABLES['$_AUX_PURPLE_25'], COLOR_VARIABLES['$_CHART_LIGTH_BLUE'], COLOR_VARIABLES['$_AUX_GREY_12'], COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT']];

  config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      reflow: true
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: []
  };

  constructor(
    private hostElement: ElementRef,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.name = this.translateService.instant(this.name);
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.pie-chart__content'), this.config);
  }

  _setData() {
    const data = this.data.map((value, index) => new Object({ name: this.labels[index], y: value.data, color: this._getColor(index, value.color) }));
    this.config.series = [{ name: this.name, colorByPoint: true, data: data }];
  }

  _getColor(index: number, color?: string): string {
    if (color) {
      return color;
    } else if (this.colors[index]) {
      return this.colors[index];
    } else {
      return null;
    }
  }
}
