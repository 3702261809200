import { Tag } from './tag';
import { Constants } from './constants';
import { Doc } from './file';
import { InstrumentExplanation } from '../instruments/models/instrument';
import { InstrumentReport } from './instrumentReport';

export class Candidate {

  static ACTIONS = {
    MOVE: 'MOVE',
    CHANGE_STATUS: 'CHANGE STATUS',
    INTERVIEW: 'INTERVIEW',
    TASK: 'TASK',
    COMMENT: 'COMMENT',
    SHARE: 'ASSIGN',
    EMAIL: 'EMAIL',
    LEAVE_APP: 'LEAVE APP',
    TAGS: 'TAGS'
  };

  uuid: string;
  name: string;
  first_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  email: string;
  form: any;
  id: string;
  job: number;
  jobs: Array<any>;
  values: any;
  dni: string;
  cv: string;
  status: string;
  phone_number: string;
  created: Date;
  modified: Date;
  identifier?: string;
  linkedin?: string;
  photo: string;
  group?: number;
  comments: Array<any> = [];
  candidate?: any;
  rate: number;
  talent_points?: number;
  talent_points_explain?: number;
  transitions: string;
  talent_point_explanation: {
    [key: string]: InstrumentExplanation
  } = {};
  social: {
    linkedin: any;
  };
  phases_interviews: {
    PHO: string,
    HR: string,
    OA: string,
    BUS: string,
  };
  instruments_done: number;
  instruments_total: number;
  mark: string;
  is_seen: boolean;
  files: Doc[];
  phone: Array<number> = [];
  viewers: number;
  viewers_info: { first_name: string, last_name: string }[];
  reports_config?: InstrumentReport[];
  job_name: string;
  hub_url: string;
  employee_status: string;
  tags: Tag[] = [];
  inscription_date: string;
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  source: string[];

  static sortCandidateComments(comments) {
    const compare = (a, b) => {
      if (a.created < b.created) {
        return 1;
      } else if (a.created > b.created) {
        return -1;
      } else {
        return 0;
      }
    };
    return comments.sort(compare);
  }

  static filterFormData(form: any[]): any[] {
    const filterInputs: Array<string> = ['cv', 'sube-tu-cvmx-5-mb-cv', 'first_name', 'last_name', 'dni', 'DNI', 'phone_number', 'email'];
    return form.filter(answer => !filterInputs.includes(answer.name));
  }
}

export class CandidateCVToken {

  static candidateTypes = {
    JWT: Constants.JWT,
    SHARED_TOKEN: 'shared_token'
  };

  static getCandidateCvUrl(file: string = '',
    type: string = CandidateCVToken.candidateTypes.JWT,
    token: string = ''): string {
    token = token || localStorage.getItem('jwt');
    return file ? `${file}?${type}=${token}` : file;
  }
}

export class CandidateActions {
  title: string;
  text: string;
  action: Function;
  argument: string;

  constructor(title: string, text: string, action: Function, argument: string) {
    this.title = title;
    this.text = text;
    this.action = action;
    this.argument = argument;
  }
}

export enum TASK_TYPES {
  PHONED = 'phoned',
  TO_SCHEDULE = 'to-schedule',
  SCHEDULED = 'scheduled',
  TO_RATE = 'to-rate'
}

export enum EMPLOYEE_STATUS {
  NOT_EMPLOYEE = "NOT",
  EMPLOYEE = "EMP",
  EMPLOYEE_CONFIRMED = "EMC"
}

export class CandidateAppliedJob {

  job_name: string;
  rate: number;
  status: string;
  talent_points: number;
  template: number;
  template_name: string;
  transitions: string;
}
