import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InstrumentReport } from '../../classes/instrumentReport';

@Component({
  selector: 'app-instrument-report-form',
  templateUrl: './instrument-report-form.component.html',
  styleUrls: ['./instrument-report-form.component.scss']
})
export class InstrumentReportFormComponent implements OnInit {

  @Input() report: InstrumentReport;
  @Output() downloadReport: EventEmitter<InstrumentReport> = new EventEmitter<InstrumentReport>();
  @Output() sendReportToCandidate: EventEmitter<InstrumentReport> = new EventEmitter<InstrumentReport>();

  constructor() { }

  ngOnInit() {
  }

  download(){
    this.downloadReport.emit(this.report);
  }

  sendToCandidate() {
    this.sendReportToCandidate.emit(this.report);
  }

}
