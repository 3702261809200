import { FormTemplate } from './../classes/form';
import { RestService } from './../library/classes/rest-service';
import { Injectable } from '@angular/core';
import { FORM_TEMPLATES } from './constants';

@Injectable()
export class FormService extends RestService<FormTemplate>{

  endpoint = FORM_TEMPLATES;

}
