import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output
} from "@angular/core";
import { InstrumentCreationData } from "../../instruments/models/instrument";
import { InstrumentReport } from "../../classes/instrumentReport";
import { UserService } from "../../services/user-service.service";
import { ClientTranslationService } from '../../services/client-translation.service';
import { unSlugify } from '../../library/utils';
import { TranslateService } from '@ngx-translate/core';
import { LiteralTranformPipe } from '../../pipes/literal-transform.pipe';
import { INSTRUMENT_TYPES } from "../../instruments/constants";

@Component({
  selector: "app-candidate-evaluation",
  templateUrl: "./candidate-evaluation.component.html",
  styleUrls: ["./candidate-evaluation.component.scss"],
  providers: [LiteralTranformPipe]
})
export class CandidateEvaluationComponent implements OnInit, OnChanges {
  @Input() instruments: any[];
  @Input() reports: any[] = [];
  @Output() downloadInstrumentReport: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendToCandidateReport: EventEmitter<any> = new EventEmitter<any>();

  serializedInstruments: any[];
  atLeastOneEvaluation: boolean;
  instrumentTypes = INSTRUMENT_TYPES;

  constructor(
    public userService: UserService,
    private clientTranslationService: ClientTranslationService,
    private translateService: TranslateService,
    private literalTransform: LiteralTranformPipe
  ) {}

  ngOnInit() {
    this.atLeastOneEvaluation = false;
    this._serializeCandidateInstruments(this.instruments);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.atLeastOneEvaluation = false;
    this._serializeCandidateInstruments(changes.instruments.currentValue);
  }

  _serializeCandidateInstruments(input: any) {
    this.serializedInstruments = [];
    Object.entries(input).map((inst: any) => {
      if(inst[1]['type'] !== INSTRUMENT_TYPES.VIDASSESS) {
        if (inst[1]["__type__"] && inst[1]["__profile_reference__"]) {
          this._serializeVersionOneCandidateInstrument(inst);
        } else if (inst[1][inst[1].length - 1] && inst[1][inst[1].length - 1].type === "form") {
          this._serializeCandidateFormInstrument(inst);
        }
        else {
          this._serializeVersionTwoCandidateInstrument(inst[0], inst[1]);
        }
      }
    });
  }

  _serializeInstrumentData(checkedValues, reference_profile_dict, scale=9, texts = []) {
    const data = {
      labels: [],
      values: [],
      reference_profile: [],
      scale: 9,
      texts: [],
    };

    data.labels = checkedValues.map(value => value[0]).filter(label =>  label !== "texts").filter(label =>  label !== "scale");
    data.texts = texts;
    data.values = checkedValues.map(value => +value[1]).filter(value =>  !isNaN(value) );
    data.scale = scale;

    data.reference_profile =
      Object.keys(reference_profile_dict).length > 0
        ? data.labels.map(label => reference_profile_dict[label] || 0)
        : null;
    if (data.values && data.values.length > 0) {
      this.atLeastOneEvaluation = true;
    }

    return data;
  }

  _serializeInstrumentGraph(title, graph, data) {
    switch (graph[1]["__type__"]) {
      case "radar":
        this.serializedInstruments.push(
          this._serializeRadarData(
            title,
            graph,
            data.labels,
            data.values,
            data.reference_profile,
            data.scale,
            data.texts
          )
        );
        break;
      case "bar":
        this.serializedInstruments.push(
          this._serializeBarData(title, graph, data.labels, data.values, data.scale, data.texts)
        );
        break;
      case "pie":
        this.serializedInstruments.push(this._serializePieData(graph, data.scale, data.texts));
        break;
    }
  }

  _serializeCandidateFormInstrument([instrumentName, instrumentData]) {
    const response_array = instrumentData.slice(0, instrumentData.length - 1);
    const has_value = response_array.find( value => value.value )

    if ( has_value) {
      this.serializedInstruments.push({
        title: instrumentName,
        responses: response_array,
        type: "form"
      });
      this.atLeastOneEvaluation = true;
    }

  }

  _serializeVersionOneCandidateInstrument(inst) {
    const _checkValues = Object.entries(inst[1]).filter(
      value => !value[0].includes("__")
    );
    const reference_profile_dict = (inst[1] as any).__profile_reference__;
    const scale = (inst[1] as any).__scale__;
    const data = this._serializeInstrumentData(
      _checkValues,
      reference_profile_dict,
      scale
    );

    this._serializeInstrumentGraph(inst[0], inst, data);
  }

  _serializeVersionTwoCandidateInstrument(inst, groups) {
    Object.entries(groups).map(group => {
      const _checkValues = Object.entries(group[1]).filter(
        value => !value[0].includes("__")
      ).filter(value => !value[0].includes("texts")).filter(value => !value[0].includes("scale"));
      const texts = Object.entries(group[1]).filter(label => label[0].includes("texts")).map(element => element[1])[0];
      const scales = Object.entries(group[1]).filter(label => label[0].includes("scale")).map(element => element[1])[0];
      const scale = Object.values(scales)[0] as number;
      const reference_profile_dict = (group[1] as any).__profile_reference__;
      const data = this._serializeInstrumentData(
        _checkValues,
        reference_profile_dict,
        scale,
        texts,
      );

      const title = inst + " - " + group[0];
      this._serializeInstrumentGraph(title, group, data);
    });
  }

  _serializeRadarData(
    title: string[],
    instrument: string[],
    labels: string[],
    values: string[],
    profile_reference: any = null,
    scale: number,
    texts: [{name: string, value: string, data: string}],
  ) {
    
    
    
    const data = profile_reference
      ? { candidate: values, profile_reference }
      : { candidate: values };
    return {
      title: title,
      type: instrument[1]["__type__"],
      values: { labels: labels, data },
      scale,
      texts: this.objectTextToArray(texts, instrument),
    };
  }

  objectTextToArray(texts, instrument){
    let arrTexts = [];
    const navigatorLanguage = this.clientTranslationService.getNavigatorLanguage();
    if(texts){
      Object.entries(texts).forEach((key) => {
        let currentVariable = this.translateService.instant(this.literalTransform.transform(unSlugify(key[0])));
        let interpretatives = Object.entries(key[1]).filter(textType => textType.includes("interpretative")).map(element => element[1])[0];
         if(interpretatives){
          let currentInterpretativeByLanguage = Object.entries(interpretatives).filter(language => language.includes(navigatorLanguage)).map(text => text[1])[0];
          let data = Object.entries(instrument[1]).filter(element => element[0].includes(key[0])).map(element => element[1])[0];
          arrTexts.push({name:currentVariable, value:currentInterpretativeByLanguage, data: data});
        }
      })
    }
    return arrTexts;
  }

  _serializeBarData(
    title: string,
    instrument: InstrumentCreationData[],
    labels: string[],
    values: string[],
    scale: number,
    texts: [{name: string, value: string, data: string}],
  ) {
    const _values = Object.values(values).map(value => ({ data: value }));
    return {
      title,
      type: instrument[1]["__type__"],
      values: { labels: labels, data: { name: "candidate", values: _values } },
      scale,
      texts: this.objectTextToArray(texts, instrument),
    };
  }

  _serializePieData(instrument,
    scale: number,
    texts: [{name: string, value: string, data: string}]) {
    const values = Object.entries(instrument[1])
      .filter(([label, value]) => !label.includes("_"))
      .map(([label, value]) => ({ label, value }));
    return { title: instrument[0], type: instrument[1]["__type__"], values, scale, texts: this.objectTextToArray(texts, instrument),};
  }

  downloadReport(report: InstrumentReport) {
    this.downloadInstrumentReport.emit(report);
  }

  sendToCandidate(report: InstrumentReport) {
    this.sendToCandidateReport.emit(report);
  }

}
