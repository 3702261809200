import { ActivatedRoute } from '@angular/router/';
import { Component, OnInit, OnDestroy, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Candidate, CandidateCVToken } from '../../classes/candidate';
import { Constants } from '../../classes/constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { JobService } from '../../services/job.service';
import { AlertsService } from '../../services/alerts.service';
import { Doc } from '../../classes/file';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user-service.service';


@Component({
  selector: 'app-candidate-docs-handler',
  templateUrl: './candidate-docs-handler.component.html',
  styleUrls: ['./candidate-docs-handler.component.scss']
})
export class CandidateDocsHandlerComponent implements OnInit, OnDestroy {

  _files: Doc[] = [];

  @Input() candidate: Candidate;
  @Input() set files(files: Doc[]) {
    this._files = files;
    if (files && files.length > 0) {
      this.changeFileTab(0);
    }
  }

  @Input() isPublic = false;

  type: string;
  token: string;
  currentFile: Doc;
  currentTab: number = 0;
  @Output() filesChange: EventEmitter<Doc[]> = new EventEmitter();
  @Input() url: string;

  loading = false;
  getCvUrl = CandidateCVToken.getCandidateCvUrl;

  componentSubscriptions: any = {};

  constructor(
    private jobService: JobService,
    private alertService: AlertsService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.type = this.isPublic ? CandidateCVToken.candidateTypes.SHARED_TOKEN : CandidateCVToken.candidateTypes.JWT;
    this.token = this.isPublic ? this.activatedRoute.snapshot.paramMap.get('token') : localStorage.getItem(Constants.JWT);
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  getFileData(file: File): { formData: FormData, name: string, extension: string } {

    const extension = this.getFileExtension(file.name);
    const name = file.name.substr(0, file.name.length - extension.length - 1);
    const formData = new FormData();
    const candidateId = this.candidate.candidate ? this.candidate.candidate : this.candidate.id;
    formData.append('file', file);
    formData.append('c_owner', candidateId);
    formData.append('name', name);
    formData.append('extension', extension);
    return { formData, name, extension };
  }

  getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  uploadImage(file: File) {
    this.loading = true;
    const fileData = this.getFileData(file);
    if (fileData.extension.match(/^(doc|docx|pdf)$/)) {
      const candidateId = this.candidate.candidate ? this.candidate.candidate : this.candidate.id;
      this.componentSubscriptions.jobServiceSubmitFile$ = this.jobService.submitFiletoCandidates(fileData.formData, candidateId).subscribe(
        response => {
          const file_message = this.translateService.instant('__fileUploaded');
          this.alertService.setAlert({ type: 'success', message: file_message });
          const uploadFile = new Doc(response.uuid, fileData.name, fileData.extension);
          this.changeFiles(uploadFile);
          this.loading = false;
        },
        error => {
          this.alertService.setAlert(this.alertService.formatErrorMessage(error));
          this.loading = false;
        }
      );
    } else {
      this.loading = false;
      this.alertService.setAlert({ type: 'error', message: this.translateService.instant('__invalidCVType') });
    }
  }

  changeFiles(file: Doc) {
    this._files.push(file);
    this.filesChange.emit(this._files);
  }

  changeFileTab(tab: number) {
    this.currentFile = this._files[tab];
    this.currentTab = tab;
  }

}
