import { CandidateService } from './../../services/candidate.service';
import { Candidate } from './../../classes/candidate';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertsService } from '../../services/alerts.service';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-public-candidate',
  templateUrl: './public-candidate.component.html',
  styleUrls: ['./public-candidate.component.scss']
})
export class PublicCandidateComponent implements OnInit, OnDestroy {

  candidate:Candidate;

  componentSubscriptions: any = {};

  constructor( 
    private activateRoute:ActivatedRoute, 
    private candidateService: CandidateService, 
    private alertService: AlertsService,
    private translateService: TranslateService 
    ) { }

  ngOnInit() {
    this.componentSubscriptions.activateRouteParamMapPipe$ = this.activateRoute.paramMap.pipe(switchMap( paramMap => {
      const token = paramMap.get('token');
      return this.candidateService.getPubliClient(token);
    })).subscribe(
      candidate => this.candidate = candidate,
      error => this.alertService.setAlert({ type: 'error', message: this.translateService.instant('__errorWithCandidate') })
    );
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


}
