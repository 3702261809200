import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { InstrumentExplanation } from '../../instruments/models/instrument';
declare const jQuery: any;

@Component({
  selector: 'app-talent-points',
  templateUrl: './talent-points.component.html',
  styleUrls: ['./talent-points.component.scss']
})
export class TalentPointsComponent implements OnInit {

  hasInstrument = false;
  private _explanation: { [instrument_key: string]: InstrumentExplanation } = {};

  visualPoints = 0;
  visualExplanations: any = {};

  style: any = {};


  @Input() points: number;

  @Input() set explanation(value: { [instrument_key: string]: InstrumentExplanation }) {

    this._explanation = value || {};
    this.hasInstrument = Object.keys(this._explanation).length > 0;
    this.visualExplanations = {};

    this.visualPoints = 0;

    for (const key of Object.keys(this._explanation)) {

      const score: number = parseFloat(this._explanation[key].points.toFixed(2));
      const weight: number = +(+this._explanation[key].weighting).toFixed(2) * 100;
      const points: number = (score * weight) / 100;

      this.visualExplanations[key] = {
        score, weight, points
      };

      this.visualPoints += points;
    }
  }

  @Input() completedEvaluation: boolean;

  get explanation(): { [instrument_key: string]: InstrumentExplanation } {
    return this._explanation;
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {

  }

  showPophover(event: any) {
    const TOP_OFFSET = 30;
    let POPHOVER_WIDTH;
    if(this.hasInstrument && this.completedEvaluation){
      POPHOVER_WIDTH = (jQuery('#talent-points-completed').width());
    }else{
      POPHOVER_WIDTH = 275;
      this.style.width = '300px';
    }
    
    const LEFT_OFFSET = 50;

    this.style.position = 'fixed';
    this.style.display = 'block';
    this.style.top = (jQuery(event.target).offset().top - TOP_OFFSET) + 'px';
    this.style.left = (jQuery(event.target).offset().left - POPHOVER_WIDTH - LEFT_OFFSET) + 'px';
  }

  hidePophover() {
    this.style.display = 'none';
  }

}
