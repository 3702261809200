import { ClientAnalyticsService } from './../../../services/client-analytics.service';
import { Filter } from './../../../library/classes/filter';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { COLOR_VARIABLES } from './../../../utils/color-variables';

@Component({
  selector: 'app-partner-analytics-jobs',
  templateUrl: './partner-analytics-jobs.component.html',
  styleUrls: ['./partner-analytics-jobs.component.scss']
})
export class PartnerAnalyticsJobsComponent implements OnInit, OnDestroy {

  _parameters: Filter[];

  @Input() set parameters(parameters: Filter[]) {
    this._parameters = parameters;
    this.getJobsAnalytics();
  }

  get parameters(): Filter[] {
    return this._parameters;
  }

  @Input() showOpenedJobs = true;

  loading = false;

  jobsSituationByPrivacy: {
    labels: string[],
    data: number[],
    hide?: boolean,
    total: number
  } = {
      labels: [],
      data: [],
      hide: true,
      total: 0
    };

  jobsSituationByStatus: {
    labels: string[],
    data: number[],
    hide?: boolean,
    total: number
  } = {
      labels: [],
      data: [],
      hide: true,
      total: 0
    };

  closureReasons: { name: string, value: any }[] = [];
  totalClosedWithReasons = 0;

  totalJobs = 0;

  openedJobs: {
    labels: string[],
    data: { [key: string]: number }[],
    hide?: boolean
  } = {
      labels: [],
      data: [],
      hide: true,
    };

  columnsConfig = {
    chart: {
      height: 200,
      spacingTop: 50
    },
    colors: [COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT']],
    yAxis: {
      visible: true,
      labels: {
        enabled: true,
        style: {
          color: COLOR_VARIABLES['$_AUX_GREY_15']
        }
      },
      title: {
        enabled: false
      }
    },
  };


  donutConfig = {
    chart: {
      height: 300,
      width: 300,
    },
    legend: {
      className: 'vertical-donut-legend',
    }
  };

  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private clientAnalyticsService: ClientAnalyticsService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getJobsAnalytics() {
    setTimeout(() => this.loading = true, 0);

    this.componentSubscriptions.jobsAnalytics$ = this.clientAnalyticsService.getGroupJobsAnalytics(...this.parameters).subscribe(
      data => setTimeout(() => this.serializeJobsData(data), 300),
      error => this.loading = false
    );
  }

  serializeJobsData(data: any) {
    this.totalJobs = data.total_jobs;
    this.serializeJobsSituationByStatus(data.status, data.total_jobs);
    this.serializeJobsSituationByPrivacy(data.privacy, data.total_jobs);
    this.serializeClosureReasons(data.closure_reasons_summary);

    if (this.showOpenedJobs) {
      this.serializeOpenedJobs(data.opened_jobs_per_month);
    }

    setTimeout(() => this.loading = false, 0);
  }

  serializeJobsSituationByStatus(data: { closed: number, created: number, expired: number }, totalJobs: number) {

    const sortedData = Object.keys(data)
      .reduce((result, currentKey) => result.concat({ key: currentKey, value: data[currentKey] }), [])
      .sort((a, b) => b.value - a.value);

    this.jobsSituationByStatus.labels = sortedData.map(element => element.key);
    this.jobsSituationByStatus.data = sortedData.map(element => element.value);
    this.jobsSituationByStatus.hide = Object.values(data).every(element => element === 0);
    this.jobsSituationByStatus.total = totalJobs;

  }

  serializeJobsSituationByPrivacy(data: { private: number, both: number, public: number }, totalJobs: number) {

    const sortedData = Object.keys(data)
      .reduce((result, currentKey) => result.concat({ key: currentKey, value: data[currentKey] }), [])
      .sort((a, b) => b.value - a.value);

    this.jobsSituationByPrivacy.labels = sortedData.map(element => element.key);
    this.jobsSituationByPrivacy.data = sortedData.map(element => element.value);
    this.jobsSituationByPrivacy.total = totalJobs;

  }

  serializeClosureReasons(data: { name: string, value: number }[]) {
    this.totalClosedWithReasons = data.reduce((result, currentReason) => result + currentReason.value, 0);
    this.closureReasons = data;
  }

  serializeOpenedJobs(data: { [key: string]: number }[]) {
    const serializedDates = data.length ? this.getSerializedDatesData(data) : [];

    this.openedJobs.labels = serializedDates.map(month => month.date);
    this.openedJobs.data = serializedDates.map(month => ({ [month.date]: month.value }));
  }

  getSerializedDatesData(months: { [key: string]: number }[]): { date: string, value: number }[] {
    const firstDate = Object.keys(months[0])[0];
    const allDates = this.getFullMonthDates(firstDate);
    const monthObj = months.reduce((result, currentMonth) => Object.assign(result, currentMonth), {});
    return this.getSerializedToolUsageData(monthObj, allDates);
  }

  getSerializedToolUsageData(months: { [key: string]: number }, allDates: string[]): { date: string, value: number }[] {
    return this.getFullMonthsData(months, allDates);
  }

  getFullMonthDates(firstDate: string): string[] {
    const lastMonth = +new Date().getMonth() + 1;
    const lastYear = +new Date().getFullYear();
    const firstYear = +firstDate.slice(firstDate.length - 4);
    const firstMonth = +firstDate.slice(0, -5);
    const fullMonthDates = [];

    for (let year = firstYear; year <= lastYear; year++) {
      for (let month = 1; month <= 12; month++) {
        if (!(year === firstYear && month < firstMonth) && !(year === lastYear && month > lastMonth)) {
          fullMonthDates.push(`${month}_${year}`);
        }
      }
    }
    return fullMonthDates;
  }

  getFullMonthsData(months: { [key: string]: number }, allDates: string[]): { date: string, value: number }[] {
    return allDates.reduce((serializedMonths, currentDate) => {
      const currentDateLabel = `${currentDate.slice(0, -5)}/${currentDate.slice(currentDate.length - 4)}`;

      const newMonth = { date: currentDateLabel, value: months[currentDate] || 0 };
      return serializedMonths.concat(newMonth);
    }, []);
  }
}
