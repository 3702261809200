import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.scss']
})
export class TableChartComponent implements OnInit {

  @Input() title = '';
  @Input() headers: string[] = [];
  @Input() body: Array<string[]> = [];
  constructor() { }

  ngOnInit() {
  }

}
