import { INSTRUMENT_TYPES } from "../instruments/constants";

export class Phase {
  name: string;
  code: string;

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }
}

export interface TablePhase {
  name: string;
  label: string;
  annotation: string;
  value: string;
  selected: boolean;
  candidates: CandidatesFunnel;
  disabled: boolean;
  condition: PhaseCondition;
  extra_columns: any;
  filters: string[];
}

export const ShortPhasesLabels = {
  INS: '__application',
  CAN: '__onlineAsses',
  PHO: '__phoneScreen',
  OA: '__otherAsses',
  HR: '__hrInterview',
  BUS: '__businessInterv',
  HIR: '__hired'
};

export const FullPhasesLabels = {
  INS: '__application',
  CAN: '__onlineAssesments',
  PHO: '__phoneScreen',
  OA: '__otherAssesments',
  HR: '__hrInterview',
  BUS: '__businessInterv',
  HIR: '__hired'
};

export enum conditionType {
  INTERVIEWS = 'INT',
  INSTRUMENTS = 'INS'
}

export class WizardPhase extends Phase {
  is_active = true;
  constructor(name: string, code: string, disabled: boolean) {
    super(name, code);
    this.is_active = disabled;
  }
}

export class StatusPhases {
  INS = true;
  CAN = true;
  PHO = true;
  OA = true;
  HR = true;
  BUS = true;
  HIR = true;
}

export class CandidatesFunnel {
  KO = 0;
  PND = 0;
  PASS = 0;
  total = 0;

  getTotalCandidates() {
    this.total = Object.keys(this)
      .filter(statusKey => statusKey !== 'total')
      .reduce((total: number, currentStatus: string) => total += this[currentStatus], 0);
  }
}

export const Status = {
  PASS: { value: 'PASS', label: '__approved' },
  PND: { value: 'PND', label: '__pending' },
  KO: { value: 'KO', label: '__rejected' },
};

export const _StatusPhases = {
  INS: { value: 'INS', label: '__application' },
  CAN: { value: 'PND', label: '__onlineAssessment' },
  PHO: { value: 'KO', label: '__phonePhase' },
  OA: { value: 'OA', label: '__otherAssesment' },
  HR: { value: 'PND', label: '__hrInterview' },
  BUS: { value: 'BUS', label: '__business' },
  HIR: { value: 'HIR', label: '__hired' },

};


export const INSCRIPTION = new Phase('__application', 'INS');
export const CANDIDATURE = new Phase('__onlineAssessment', 'CAN');
export const PHONE = new Phase('__phonePhase', 'PHO');
export const OTHER_ASSESMENT = new Phase('__otherAssesment', 'OA');
export const HR = new Phase('__hrInterview', 'HR');
export const BUSSINESS = new Phase('__business', 'BUS');
export const HIRED = new Phase('__hired', 'HIR');


export const PHASES: Phase[] = [INSCRIPTION, CANDIDATURE, PHONE, OTHER_ASSESMENT, HR, BUSSINESS, HIRED];

export function findPhaseIndexByCode(phase_code: string): null | number {
  return PHASES.findIndex(phase => phase.code === phase_code);
}

export function findPhaseIndexByName(phase_name: string): null | number {
  return PHASES.findIndex(phase => phase.name === phase_name);
}

export const INTERVIEW_PHASES: Phase[] = [PHONE, OTHER_ASSESMENT, HR, BUSSINESS];

export class PhaseCondition {
  args: { [key: string]: any };
  fn: Function;

  constructor(fn: Function, args: { [key: string]: any } = {}) {
    this.args = args;
    this.fn = fn;
  }

  setArgs(arg: { [key: string]: any }): { [key: string]: any } {
    this.args = arg;
    return this.args;
  }

  updateArgs(arg: { [key: string]: any }): { [key: string]: any } {
    this.args = Object.assign(this.args, arg);
    return this.args;
  }

  execute(): any {
    return this.fn(this.args);
  }
}

export function checkInterviews({ arg, job }): boolean {
  return job.interview_guides[arg] && job.interview_guides[arg][0];
}

export function getJobInstruments({ arg, job }) {
  return [new PhaseExtraColumn(arg, 'Instruments', 'instruments', 'instruments_total')];
}

export function getPhaseInterviews({ arg, job }): PhaseExtraColumn[] {
  return (job.interview_guides[arg] || []).map(interview =>
    new PhaseExtraColumn(arg, interview.name, 'interview', 'interview-' + interview.slug, interview.slug)
  );
}

export function getOtherAssesmentInterviews({ arg, job }): PhaseExtraColumn[] {
  const columns = (job.interview_guides[arg] || []).map(interview =>
    new PhaseExtraColumn(arg, interview.name, 'interview', 'interview-' + interview.slug, interview.slug)
  );

  return columns.concat(
    (job.instruments_data || [])
      .filter(instrument => instrument.origin === INSTRUMENT_TYPES.VIDASSESS || instrument.origin === INSTRUMENT_TYPES.VIDASSESS + '_' + INSTRUMENT_TYPES.VIDASSESS_AI)
      .map(vidasses => new PhaseExtraColumn(arg, vidasses.name, 'vidassess', 'vidassess-' + vidasses.instrument_id, 'vidassess'))
  )
}

export class PhaseExtraColumn {
  phase: string;
  title: string;
  type: string;
  sort_property: string;
  slug: string;

  constructor(phase: string, title: string, type: string, sort_property: string, slug: string = '') {
    this.phase = phase;
    this.title = title;
    this.type = type;
    this.sort_property = sort_property;
    this.slug = slug;
  }

  getTemplate(candidate): any {
    switch (this.type) {
      case 'interview':
        return this.getInterviewTemplate(candidate);
      case 'instruments':
        return this.getInstrumentsTemplate(candidate);
      case 'vidassess':
        return this.getVidassessTemplate(candidate);
    }
  }

  getInterviewTemplate(candidate) {
    candidate.phases_interviews[this.phase] = candidate.phases_interviews[this.phase] || {};

    switch (candidate.phases_interviews[this.phase][this.slug]) {
      case 'PASS':
        return `<i class="fas fa-check"></i>`;
      case 'KO':
        return `<i class="fas fa-times"></i>`;
      case 'STANDBY':
        return `<i class="fas fa-question"></i>`;
      default:
        return `<i class="fas fa-minus"></i>`;
    }
  }

  getInstrumentsTemplate(candidate) {
    return `${candidate.instruments_done} / ${candidate.instruments_total}`;
  }

  getVidassessTemplate(candidate) {
    const score = candidate.phases_interviews[this.phase] ? candidate.phases_interviews[this.phase][this.title] : null;
    return score !== 0 ? score : `<i class="fas fa-minus"></i>`;
  }

}

export class PhaseConfig {
  CAN: {
    kq_instrument: number,
    is_active: boolean,
    disney?: { project_uuid: string }
  } = {
      kq_instrument: 0,
      is_active: true
    };
  PHO: { is_active: boolean } = { is_active: true };
  HR: { is_active: boolean } = { is_active: true };
  OA: { is_active: boolean } = { is_active: true };
  BUS: { is_active: boolean } = { is_active: true };

  static buildFromPhaseConfig(newConfig: PhaseConfig): PhaseConfig {
    const newPhaseConfig = new PhaseConfig();

    Object.keys(newConfig).forEach(phaseKey => {
      Object.keys(newConfig[phaseKey]).forEach(phaseConfigKey => {
        newPhaseConfig[phaseKey][phaseConfigKey] = newConfig[phaseKey][phaseConfigKey];
      });
    });

    return newPhaseConfig;
  }
}
