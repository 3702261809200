import { Component, OnInit } from '@angular/core';
import { User } from '../../classes/user';
import { UserService } from '../../services/user-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RecoverPasswordService } from '../../services/recover-password.service';
import { Group } from '../../classes/group';
import { AlertsService, ALERT_TYPES } from '../../services/alerts.service';
import { ACTIVE } from '../../partners/constants';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email = '';
  success = '';
  password = '';
  error: boolean;
  user: User;
  state: string;
  refer: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private recoverService: RecoverPasswordService,
    private alertService: AlertsService
  ) {
    this.refer = this.route.snapshot.queryParams.refer;
  }

  ngOnInit() {
    this.error = false;
    this.state = this.route.snapshot.data.state;
  }

  handleAction(): void {
    this.state = this.route.snapshot.data.state;
    if (this.state === 'login') {
      this.login();
    }
    if (this.state === 'recover') {
      this.recover();
    }
  }

  login(): void {
    this.userService.login(this.email, this.password).toPromise().then(
      (response) => {
        if (response['two_authentication_factor']) {
          // GO TO VERIFICATION CODE PAGE
          this.user = this.userService.setUser(null, response['user'], null, null, null, null, true);
          this.goToVerificationCodePage();
        } else {
          this.saveLoginData(response);
        }
      },
      (error) => {

        const handleError = Object.keys(error.error)[0] === 'non_field_errors' ?
          { type: ALERT_TYPES.ERROR, message: '__loginError' } : this.alertService.formatErrorMessage(error);
        this.alertService.setAlert(handleError);
      });
  }

  goToVerificationCodePage(): void {
    this.router.navigate(['verify-code']);
  }

  saveLoginData(token): void {
    this.userService.saveToken(token);
    this.user = this.userService.setUser(token.user.id, token.user.email, token.user.role, new Group(), token.user.first_name, token.user.last_name);
    this.getGroupUser();
  }

  getGroupUser() {
    this.userService.getGroup(this.user).then(
      groups => {
        const group = groups[0] as Group;
        if ((group && group.status === ACTIVE) || this.user.isHQ()) {
          this.user = this.userService.setUser(this.user.id, this.user.email, this.user.role, group, this.user.first_name, this.user.last_name);
          this.userService.saveUser();
          this.navigatePath();
        } else {
          this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: '__thisClientIsInactive' });
        }
      },
      error => console.log(error)
    );
  }

  recover(): void {
    this.recoverService.resetPassword(this.email).then(
      response => {
        this.alertService.setAlert({ type: ALERT_TYPES.SUCCESS, message: '__resetEmailSent' })
      },
      error => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error));
      }
    );
  }

  navigatePath(): void {

    if (this.refer) {
      this.router.navigate([this.refer]);
    } else {
      this.router.navigate([this.userService.getMyInitialPath()]);
    }
  }

}
