import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CANIDATE_INSTRUMENT_FORM } from './constants';
import { RestService } from './../library/classes/rest-service';
import { Injectable } from '@angular/core';
import { FormJob } from './models/candidate-form';

@Injectable()
export class FormJobService {

  endpoint = CANIDATE_INSTRUMENT_FORM;

  constructor(private httpClient: HttpClient) {}

  retrieve(uuid: string, form_slug: string): Observable<FormJob> {
    const url = `${this.endpoint}${uuid}/${form_slug}`;
    return this.httpClient.get < FormJob > (url);
  }

  saveCandidateAnswers(data: any, uuid: string, form_slug: string): Observable<string> {
    const url = `${this.endpoint}${uuid}/${form_slug}`;
    return this.httpClient.post < string > (url, data);
  }



}
