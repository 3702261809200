
import { Router, ActivatedRoute } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { UserService } from '../../services/user-service.service';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  ks: string;
  subscription: Subscription;

  state: string;

  componentSubscriptions: any = {};

  constructor(private userService: UserService,
    private keyboardShortcutService: KeyboardShortcutService,
    private router: Router,
    private activatedRoute: ActivatedRoute
     ) {  }

  ngOnInit() {
    this.userService.loadUser();
    this.userService.checkExistingToken();
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
  }


  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'DashboardComponent.AltC': this.goToClients(); break;
    }
  }

  goToClients() {
    this.router.navigate(['partner']);
  }

}
