import { Component, OnInit, Input } from '@angular/core';
import { InstrumentsService } from '../../../instruments/instruments.service';
import { INSTRUMENT_TYPES } from '../../../instruments/constants';

@Component({
  selector: 'app-instruments-cute',
  templateUrl: './instruments-cute.component.html',
  styleUrls: ['../_instruments-content.scss']
})
export class InstrumentsCuteComponent implements OnInit {

  @Input() set instruments(instruments) {
    this.visibleInstruments = this.instrumentsService.getVisibleInstruments(instruments).filter(element => element.provider_origin == this.instrumentTypes.CUTE)
  } ;
  @Input() update;
  visibleInstruments;

  instrumentTypes = INSTRUMENT_TYPES;

  constructor(
    public instrumentsService: InstrumentsService,
  ) { }

  ngOnInit() {
    
  }

}
