import { IRestModel } from './../library/models/rest-model';
import { environment } from '../../environments/environment';
export class Form {
  static FIRST_NAME = {
    label: 'First Name',
    iconClassName: 'icon-user',
    name: 'first_name',
    showHeader: false,
    fields: [{
      type: 'text',
      required: true,
      label: 'Name',
      className: 'form-control',
      name: 'first_name',
      subtype: 'text'
    }, ]
  };
  static LAST_NAME = {
    label: 'Last Name',
    iconClassName: 'icon-user',
    name: 'last_name',
    showHeader: false,
    fields: [{
      type: 'text',
      required: true,
      label: 'Last Name',
      className: 'form-control',
      name: 'last_name',
      subtype: 'text'
    }, ]
  };

  static PHONE_NUMBER = {
    label: 'Phone',
    iconClassName: 'icon-phone',
    name: 'phone',
    showHeader: false,
    fields: [{
      type: 'number',
      required: true,
      label: 'Phone',
      className: 'form-control',
      name: 'phone_number',
      placeholder: '612345678',
      max: '10000000000000'
    }]
  };

  static PERSONAL_FIELDS = [{
      type: 'text',
      subtype: 'text',
      label: 'DNI',
      className: 'form-control',
      name: 'dni',
      required: true
    },
    {
      type: 'text',
      required: true,
      label: 'Name',
      placeholder: 'Name',
      className: 'form-control',
      name: 'first_name',
      subtype: 'text'
    },
    {
      type: 'text',
      required: true,
      label: 'Last Name',
      placeholder: 'Last Name',
      className: 'form-control',
      name: 'last_name',
      subtype: 'text'
    },
    {
      type: 'text',
      required: true,
      label: 'E-mail',
      placeholder: 'Email',
      className: 'form-control',
      name: 'email',
      subtype: 'email'
    },
    {
      type: 'number',
      required: true,
      label: 'Phone',
      className: 'form-control',
      name: 'phone_number',
      max: '10000000000000'
    }
  ];
  static PERSONAL_DATA = {
    label: 'Personal Data',
    iconClassName: 'icon-user',
    name: 'user-details',
    showHeader: true, // optional
    fields: [{
        type: 'radio-group',
        label: 'Gender',
        inline: true,
        name: 'gender',
        values: [{
            label: 'Male',
            value: 'MLE',
            selected: true
          },
          {
            label: 'Female',
            value: 'FME'
          }
        ]
      },
      {
        type: 'select',
        required: true,
        label: 'Residencia',
        name: 'residence',
        className: 'form-control',
        values: [{
            label: 'A Coruña',
            value: 'a-coruña',
            selected: true
          },
          {
            label: 'Alava',
            value: 'alava'
          },
          {
            label: 'Albacete',
            value: 'albacete'
          },
          {
            label: 'Alicante',
            value: 'alicante'
          },
          {
            label: 'Almería',
            value: 'almeria'
          },
          {
            label: 'Aragón',
            value: 'aragon'
          },
          {
            label: 'Asturias',
            value: 'asturias'
          },
          {
            label: 'Ávila',
            value: 'avila'
          },
          {
            label: 'Badajoz',
            value: 'badajoz'
          },
          {
            label: 'Baleares',
            value: 'baleares'
          },
          {
            label: 'Barcelona',
            value: 'barcelona'
          },
          {
            label: 'Burgos',
            value: 'burgos'
          },
          {
            label: 'Cáceres',
            value: 'cáceres'
          },
          {
            label: 'Cádiz',
            value: 'cádiz'
          },
          {
            label: 'Cantabria',
            value: 'cantabria'
          },
          {
            label: 'Castellón',
            value: 'castellón'
          },
          {
            label: 'Ceuta',
            value: 'ceuta'
          },
          {
            label: 'Ciudad Real',
            value: 'ciudad real'
          },
          {
            label: 'Córdoba',
            value: 'córdoba'
          },
          {
            label: 'Cuenca',
            value: 'cuenca'
          },
          {
            label: 'Girona',
            value: 'girona'
          },
          {
            label: 'Granda',
            value: 'granda'
          },
          {
            label: 'Guadalajara',
            value: 'guadalajara'
          },
          {
            label: 'Guipuzcoa',
            value: 'guipuzcoa'
          },
          {
            label: 'Huelva',
            value: 'huelva'
          },
          {
            label: 'Huesca',
            value: 'huesca'
          },
          {
            label: 'Jaén',
            value: 'jaén'
          },
          {
            label: 'La Rioja',
            value: 'la-rioja'
          },
          {
            label: 'Las Palmas',
            value: 'las-palmas'
          },
          {
            label: 'León',
            value: 'león'
          },
          {
            label: 'Lleida',
            value: 'lleida'
          },
          {
            label: 'Lugo',
            value: 'lugo'
          },
          {
            label: 'Madrid',
            value: 'madrid'
          },
          {
            label: 'Málaga',
            value: 'málaga'
          },
          {
            label: 'Melilla',
            value: 'melilla'
          },
          {
            label: 'Murcia',
            value: 'murcia'
          },
          {
            label: 'Navarra',
            value: 'navarra'
          },
          {
            label: 'Ourense',
            value: 'ourense'
          },
          {
            label: 'Palencia',
            value: 'palencia'
          },
          {
            label: 'Pontevedra',
            value: 'pontevedra'
          },
          {
            label: 'Salamanca',
            value: 'salamanca'
          },
          {
            label: 'Santa Cruz de Tenerife',
            value: 'santa-cruz-de-tenerife'
          },
          {
            label: 'Segovia',
            value: 'segovia'
          },
          {
            label: 'Sevilla',
            value: 'sevilla'
          },
          {
            label: 'Soria',
            value: 'soria'
          },
          {
            label: 'Tarragona',
            value: 'tarragona'
          },
          {
            label: 'Teruel',
            value: 'teruel'
          },
          {
            label: 'Toledo',
            value: 'toledo'
          },
          {
            label: 'Valencia',
            value: 'valencia'
          },
          {
            label: 'Valladolid',
            value: 'valladolid'
          },
          {
            label: 'Vizcaya',
            value: 'vizcaya'
          },
          {
            label: 'Zamora',
            value: 'zamora'
          },
          {
            label: 'Zaragoza',
            value: 'zaragoza'
          }
        ]
      },
      {
        type: 'autocomplete',
        label: 'Nacionalidad',
        name: 'nationality',
        className: 'form-control',
        values: [{
            label: 'Spanish',
            value: 'spanish'
          },
          {
            label: 'American',
            value: 'american'
          },
          {
            label: 'Brazilian',
            value: 'brazilian'
          },
          {
            label: 'British',
            value: 'british'
          },
          {
            label: 'Colombian',
            value: 'colombian'
          },
          {
            label: 'Dutch',
            value: 'dutch'
          },
          {
            label: 'French',
            value: 'french'
          },
          {
            label: 'German',
            value: 'german'
          },
          {
            label: 'Greek',
            value: 'greek'
          },
          {
            label: 'Irish',
            value: 'irish'
          },
          {
            label: 'Italian',
            value: 'italian'
          },
          {
            label: 'Polish',
            value: 'polish'
          },
          {
            label: 'Portuguese',
            value: 'portuguese'
          },
          {
            label: 'Swiss',
            value: 'swiss'
          },
          {
            label: 'Turkish',
            value: 'turkish'
          },
          {
            label: 'Indian',
            value: 'indian'
          },
          {
            label: 'Canadian',
            value: 'canadian'
          },
          {
            label: 'Chilian',
            value: 'chilian'
          },
          {
            label: 'Romanian',
            value: 'romanian'
          }
        ]
      },
      {
        type: 'autocomplete',
        label: '¿Como encontraste el programa?',
        name: 'how-did-you-find-the-program?',
        className: 'form-control',
        values: [{
            label: 'Facebook',
            value: 'facebook'
          },
          {
            label: 'LinkedIn',
            value: 'linkedin'
          },
          {
            label: 'Twitter',
            value: 'twitter'
          },
          {
            label: 'Instagram',
            value: 'instagram'
          },
          {
            label: 'Infojobs',
            value: 'infojobs'
          },
          {
            label: 'University Job Posting',
            value: 'university-job-posting'
          },
          {
            label: 'University Career Forum',
            value: 'university-career-forum'
          },
          {
            label: 'Web of the Company',
            value: 'web-of-the-company'
          },
          {
            label: 'Online Press',
            value: 'online-press'
          },
          {
            label: 'Offline Press',
            value: 'offline-press'
          },
          {
            label: 'Referral by an employee',
            value: 'referral-by-an-employee'
          }
        ]
      }
    ]
  };

  static ACADEMIC_DATA = {
    label: 'Academic Data',
    iconClassName: 'icon-graduation-cap',
    name: 'academic-data',
    showHeader: true, // optional
    fields: [{
        type: 'select',
        label: '¿Tienes titulación de enfermería?',
        name: 'your-nursing',
        className: 'form-control',
        values: [{
            label: 'Sí',
            value: 'si',
            selected: true
          },
          {
            label: 'Estoy cursandolos actualmente',
            value: 'in-progress'
          },
          {
            label: 'No',
            value: 'no'
          }
        ]
      },
      {
        type: 'select',
        required: true,
        label: 'En caso de que no seas enfermero, ¿Cual es tu máximo nivel de estudios?',
        name: 'your-studies',
        className: 'form-control',
        values: [{
            label: 'ESO',
            value: 'eso'
          },
          {
            label: 'Bachillerato',
            value: 'bachillerato',
            selected: true
          },
          {
            label: 'FP Grado Medio',
            value: 'bacheloor-degree'
          },
          {
            label: 'FP Grado Superior',
            value: 'master-degree'
          }
        ]
      },
      {
        type: 'select',
        required: true,
        label: 'Si tienes algún FP de Grado Medio.¿Has cursado alguna de estas?',
        name: 'fp-medium',
        className: 'form-control',
        values: [{
          label: 'grado1',
          value: 'grado1'
        }]
      },
      {
        type: 'select',
        required: true,
        label: 'Si tienes algún FP de Grado Superior.¿Has cursado alguna de estas?',
        className: 'form-control',
        name: 'fp-superior',
        values: [{
          label: 'grado1',
          value: 'grado1'
        }]
      }
    ]
  };
  static PROFESSIONAL_EXPERIENCE = {
    label: 'Professional Experience',
    iconClassName: 'icon-briefcase',
    name: 'professional-experience',
    showHeader: true, // optional
    fields: [{
        type: 'select',
        required: true,
        label: '¿Cuantos años de experiencia tienes?',
        className: 'form-control',
        name: 'years-of-experience?',
        values: [{
            label: 'No tengo experiencia',
            value: 'no'
          },
          {
            label: 'Menos de 1 año',
            value: 'less-1'
          },
          {
            label: 'De 1 a 3 años',
            value: '1-3'
          },
          {
            label: 'De 3 a 5 años',
            value: '3-5'
          },
          {
            label: 'Más de 5 años',
            value: 'more-5'
          }
        ]
      },
      {
        type: 'text',
        required: true,
        label: 'Describe brevemente tu experiencia',
        className: 'form-control',
        name: 'last_name',
        subtype: 'text'
      },
      {
        type: 'select',
        required: true,
        label: '¿Tienes experiencia en cuidado de mayores?',
        name: 'years-of-experience-elderly-people?',
        className: 'form-control',
        values: [{
            label: 'No tengo experiencia',
            value: 'no'
          },
          {
            label: 'Menos de 1 año',
            value: 'less-1'
          },
          {
            label: 'De 1 a 3 años',
            value: '1-3'
          },
          {
            label: 'De 3 a 5 años',
            value: '3-5'
          },
          {
            label: 'Más de 5 años',
            value: 'more-5'
          }
        ]
      },
      {
        type: 'file',
        subtype: 'file',
        label: 'Sube tu cv(máx 5 MB)',
        className: 'form-control',
        name: 'cv',
        required: true
      }
    ]
  };
  static LANGUAGES = {
    label: 'Languages',
    iconClassName: 'icon-language',
    name: 'languages',
    showHeader: true, // optional
    fields: [{
      type: 'select',
      label: 'Please, specify your level of INSERT LANGUAGE',
      className: 'form-control',
      values: [{
          label: 'I don´t speak',
          value: 'dont-speak',
          selected: true
        },
        {
          label: 'A1 Breakthrough or beginner',
          value: 'A1'
        },
        {
          label: 'A2 Waystage or elementary',
          value: 'A2'
        },
        {
          label: 'B1 Threshold or intermediate',
          value: 'B1'
        },
        {
          label: 'B2 Vantage or upper intermediate',
          value: 'B2'
        },
        {
          label: 'C1 Effective Operational Proficiency or Advanced',
          value: 'C1'
        },
        {
          label: 'C2 Mastery or Proficiency',
          value: 'C2'
        }
      ]
    }]
  };
  static CV_INPUT = {
    label: 'CV Upload',
    iconClassName: 'icon-file',
    name: 'cv',
    showHeader: false, // optional
    fields: [{
      type: 'file',
      subtype: 'file',
      label: 'Upload your cv(max 5 MB)',
      className: 'form-control',
      name: 'cv',
      required: true
    }]
  };

  static GENDER = {
    label: 'Gender',
    iconClassName: 'icon-user',
    name: 'gender',
    showHeader: false,
    fields: [{
      type: 'radio-group',
      label: 'Género',
      inline: true,
      name: 'gender',
      required: true,
      values: [{
          label: 'Hombre',
          value: 'MLE',
          selected: true
        },
        {
          label: 'Mujer',
          value: 'FME'
        }
      ]
    }, ]
  };

  static BIRTH_DATE = {
    label: 'Birth Date',
    iconClassName: 'icon-date',
    name: 'birth_date',
    showHeader: false,
    fields: [{
      type: 'date',
      label: 'Birthday',
      name: 'birth_date',
      required: true,
    }, ]
  };

  static STYLE_TEXTAREA = {
    label: 'Style Text',
    iconClassName: 'icon-textarea',
    name: 'rich_textarea',
    showHeader: false,
    fields: [{
      type: 'textarea',
      label: 'Style Text',
      name: 'textarea',
      subtype: 'with style text',
    }, ]
  };

  static NORMAL_TEXTAREA = {
    label: 'Normal Area',
    iconClassName: 'icon-textarea',
    name: 'limited_textarea',
    showHeader: false,
    fields: [{
      type: 'textarea',
      label: 'Text Area',
      name: 'textarea',
      subtype: 'textarea',
    }, ]
  };

  static INPUTS: any = [
    Form.FIRST_NAME,
    Form.LAST_NAME,
    Form.PHONE_NUMBER,
    Form.LANGUAGES,
    Form.CV_INPUT,
    Form.GENDER,
    Form.BIRTH_DATE,
    Form.NORMAL_TEXTAREA,
    Form.STYLE_TEXTAREA,
  ];

  static INPUTS_ORDER = [
    'user-details',
    'academic-data',
    'professional-experience',
    'first_name',
    'last_name',
    'phone',
    'gender',
    'birth_date',
    'languages',
    'cv',
    'header',
    'paragraph',
    'text',
    'limited_textarea',
    'rich_textarea',
    'select',
    'checkbox-group',
    'radio-group',
    'date',
    'file',
    'number',
    'autocomplete'
  ];

  static JOB_OPTIONS = {
    showActionButtons: false,
    sortableControls: false,
    defaultSelectOption: false,
    priorityConfig: {
      options: [
        { title: 'priority' },
        { title: 'image', type: 'file' }
      ],
      showLabels: true
    },
    stickyControls: {
      enable: false
    },
    typeUserAttrs: {
      date: {
        minKQ: {
          label: 'Year min',
          placeholder: 'Year min',
          type: 'number',
        },
        maxKQ: {
          label: 'Year max',
          placeholder: 'Year max',
          type: 'number',
        }
      },
      'text': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'checkbox-group': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'radio-group': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'priority': {
        image: {
          label: 'Upload Image',
          type: 'file'
        }
      }
    },
    selectConfig: {
      options: [{
          title: 'kq',
          type: 'checkbox'
        },
        {
          title: 'image',
          type: 'file'
        }
      ],
      showLabels: true
    },
    disabledAttrs: ['className', 'rows', 'access', 'other', 'value', 'toggle'],
    disableFields: ['button', 'hidden', 'textarea'],
    inputSets: environment.sas ? Form.INPUTS : Form.INPUTS.concat([Form.ACADEMIC_DATA, Form.PROFESSIONAL_EXPERIENCE, Form.PERSONAL_DATA]),
    controlPosition: 'left',
    controlOrder: Form.INPUTS_ORDER,
    defaultFields: []
  };
  static PROFESSION_OPTIONS = {
    showActionButtons: false,
    sortableControls: false,
    notAppendOnClick: true,
    disabledEditFieldName: true,
    defaultSelectOption: false,
    stickyControls: {
      enable: false
    },
    typeUserAttrs: {
      date: {
        minKQ: {
          label: 'Year max',
          description: 'Year max'
        },
        maxKQ: {
          label: 'Year min',
          placeholder: 'Year min'
        }
      },
      'text': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'checkbox-group': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'radio-group': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'priority': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
    },
    selectConfig: {
      options: [{ title: 'kq', type: 'checkbox' }, { title: 'image', type: 'file' }],
      showLabels: true
    },
    priorityConfig: {
      options: [
        { title: 'priority' },
        { title: 'image', type: 'file' }
      ],
      showLabels: true
    },
    inputSets: environment.sas ? Form.INPUTS : Form.INPUTS.concat([Form.ACADEMIC_DATA, Form.PROFESSIONAL_EXPERIENCE, Form.PERSONAL_DATA]),
    disabledAttrs: ['className', 'rows', 'access', 'other', 'value', 'toggle'],
    disableFields: ['button', 'hidden', 'textarea'],
    controlPosition: 'left',
    controlOrder: Form.INPUTS_ORDER,
    defaultFields: []
  };
  static INTERVIEW_OPTIONS = {
    showActionButtons: false,
    sortableControls: false,
    notAppendOnClick: true,
    disabledEditFieldName: true,
    defaultSelectOption: false,
    typeUserAttrs: {
      date: {
        minKQ: {
          label: 'Year max',
          description: 'Year max'
        },
        maxKQ: {
          label: 'Year min',
          placeholder: 'Year min'
        }
      },
      'text': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'checkbox-group': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'radio-group': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
      'priority': {
        image: {
          label: 'Upload Image',
          type: 'file'
        },
      },
    },
    stickyControls: {
      enable: false
    },
    inputSets: environment.sas ? Form.INPUTS : Form.INPUTS.concat([Form.ACADEMIC_DATA, Form.PROFESSIONAL_EXPERIENCE, Form.PERSONAL_DATA]),
    disabledAttrs: ['className', 'rows', 'access', 'other', 'value', 'toggle'],
    disableFields: ['button', 'hidden', 'textarea'],
    controlPosition: 'left',
    controlOrder: Form.INPUTS_ORDER,
    uploadFile: null,
    defaultFields: [],
    selectConfig: {
      options: [{ title: 'image', type: 'file' }],
      showLabels: true
    },
    priorityConfig: {
      options: [
        { title: 'priority' },
        { title: 'image', type: 'file' }
      ],
      showLabels: true
    },
  };

}

export class FormTemplate implements IRestModel {
  name: string;
  description: string;
  group: number;
  content: any[];
  id ? : number;
  selected ? : boolean;
}
