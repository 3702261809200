import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user-service.service';
import { DomSanitizer, SafeStyle } from '../../../../node_modules/@angular/platform-browser';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { Appearance } from '../../classes/appearance';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { COLOR_VARIABLES } from './../../utils/color-variables';

@Component({
  selector: 'app-client-appearance',
  templateUrl: './client-appearance.component.html',
  styleUrls: ['./client-appearance.component.scss']
})
export class ClientAppearanceComponent implements OnInit, OnDestroy {

  private _appearance: Appearance = new Appearance();
  private _logo: string;

  @Input()
  set appearance(appearance: Client['extra_config']['appearance']) {
    this._appearance = Object.assign(this._appearance, appearance);
    this._appearance.color = this.sortProperties(this._appearance.color);
    this._appearance.font = this.sortProperties(this._appearance.font);
  }
  get appearance(): Client['extra_config']['appearance'] {
    return this._appearance;
  }

  @Output() appearanceOnChange: any = new EventEmitter();

  client: Client = new Client();

  fonts: string[] = [
    'Arial, sans-serif',
    'Helvetica, sans-serif',
    'Times New Roman, serif',
    'Courier New, monospaced',
    'Verdana, sans-serif',
    'Georgia, serif',
    'Palatino, serif',
    'Garamond, serif',
    'Bookman, serif',
    'Trebuchet, sans-serif',
    'Roboto, sans-serif',
    'Nunito, sans-serif'
  ];

  edit: { description: boolean, graphics: boolean, media: boolean } = {
    description: true,
    graphics: true,
    media: true,
  };

  ckEditorConfig = {
    extraPlugins: 'divarea,colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: ['Format', 'Font'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
      { name: 'links', items: ['Link', 'Unlink'] }
    ],
    readOnly: false,
  };

  componentSubscriptions: any = {};

  constructor(
    public userService: UserService,
    private sanitizer: DomSanitizer,
    public clientService: ClientService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getClient();
    this.setCkEditorPermissions();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getClient() {

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(+this.activatedRoute.parent.snapshot.paramMap.get('client'))
      .subscribe(
        response => this.client = response,
        error => console.log(error)
      );
  }

  setCkEditorPermissions() {
    this.ckEditorConfig.readOnly = Boolean(!this.userService.hasClientAdminPermissions() && !this.clientService.isActionActive(this.client));
  }

  async save() {
    this.appearanceOnChange.emit(this.appearance);
  }

  getSanitizedFont(type: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`font-family:${this.appearance.font[type]};`);
  }

  getElement(element_id) {
    return document.getElementById(element_id);
  }

  sortProperties(properties): { primary: string, secondary: string } {
    return {
      primary: properties.primary !== '' ? properties.primary : COLOR_VARIABLES['$_AUX_GREY_20'],
      secondary: properties.secondary
    };
  }
}
