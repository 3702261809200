import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user-service.service';
import { User } from '../../classes/user';
import { Group } from '../../classes/group';
import { AlertsService, ALERT_TYPES, Alert } from '../../services/alerts.service';
import { ACTIVE } from '../../partners/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent implements OnInit, OnDestroy {

  code: any = '';
  user: any = '';

  componentSubscriptions: any = {};

  constructor(
    private userService: UserService,
    private alertService: AlertsService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  sendVerificationCode() {
    // TODO: send code to endpoint
    let user = this.userService.getUser();
    this.componentSubscriptions.userServiceSendVerificationCode$ = this.userService.sendVerificationCode(this.code, user.email).subscribe(
      token => {
        this.saveLoginData(token);
      },
      error => this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, '__verifyCodeInvalid'))

    );
  }

  saveLoginData(token): void {
    this.userService.saveToken(token);
    this.user = this.userService.setUser(token.user.id, token.user.email, token.user.role, new Group(), token.user.first_name, token.user.last_name);
    this.getGroupUser();
  }

  getGroupUser() {
    this.userService.getGroup(this.user).then(
      groups => {
        const group = groups[0] as Group;
        if ((group && group.status === ACTIVE) || this.user.isHQ()) {
          this.user = this.userService.setUser(this.user.id, this.user.email, this.user.role, group, this.user.first_name, this.user.last_name);
          this.userService.saveUser();
          this.navigatePath();
        } else {
          this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: '__thisClientIsInactive' });
        }
      },
      error => console.log(error)
    );
  }

  navigatePath(): void {
    this.router.navigate([this.userService.getMyInitialPath()]);
  }

}
