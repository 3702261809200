import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Job } from '../../classes/job';
import { ShortPhasesLabels, Status } from '../../classes/phases';
import { Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-candidate-multijob-selector',
  templateUrl: './candidate-multijob-selector.component.html',
  styleUrls: ['./candidate-multijob-selector.component.scss']
})
export class CandidateMultijobSelectorComponent implements OnInit, OnDestroy {

  @Input() candidateJobs: any[];
  @Input() jobSelected: number;
  @Input() showJobSelector: boolean;

  @Output() jobSelectedChange: EventEmitter<number> = new EventEmitter();

  shortPhasesLabels = ShortPhasesLabels;
  status = Status;

  showMoreJobs = false;

  componentSubscriptions: any = {};

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.subscribeToCandidateChange();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  subscribeToCandidateChange() {
    this.componentSubscriptions.routerEventsFilter$ = this.router.events
      .pipe(
        filter(url => url instanceof NavigationEnd)
      )
      .subscribe((url: any) => {
        if (url.url.indexOf('?candidate=') !== -1) {
          this.showJobSelector = false;
          this.showMoreJobs = false;
        }
      });
  }

  selectJob(i: number) {
    this.jobSelectedChange.emit(i);
  }

  setSelectedJob() {
    this.showJobSelector = !this.showJobSelector;
    if (this.showJobSelector) { this.candidateJobs[this.jobSelected].selected = true; }
  }

}
