import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { CandidateService } from '../../services/candidate.service';
import { CandidateAppliedJob } from '../../classes/candidate';
import { Status, FullPhasesLabels } from '../../classes/phases';
import { isNumeric } from '../../utils';

@Component({
  selector: 'app-candidate-pool-details',
  templateUrl: './candidate-pool-details.component.html',
  styleUrls: ['./candidate-pool-details.component.scss']
})
export class CandidatePoolDetailsComponent implements OnInit, OnDestroy {

  visualPoints = 0;
  visualExplanations: any = {};

  style: any = {};
  @Input() jobsApplied: number;
  @Input() candidateId: number;
  @Input() template: string;
  jobsAppliedInfo: CandidateAppliedJob[] = [];
  jobsAppliedInfoOtherTemplates: CandidateAppliedJob[] = [];

  shortPhaseLabels = FullPhasesLabels;
  status = Status;

  showInvited = false;
  loading: boolean = false;

  componentSubscriptions: any = {};

  constructor(private elementRef: ElementRef, private candidateService: CandidateService) { }

  ngOnInit() { }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  showPophover(event: any) {

    this.loading = true;
    this.componentSubscriptions.candidateServiceGetCandidateAppliedJobs$ = this.candidateService.getCandidateAppliedJobs(this.candidateId.toString())
      .subscribe(
        cand => {
          if (this.isATemplate()) {
            this.separateByProfession(cand);
          } else {
            this.jobsAppliedInfo = cand;
          }
        }
      );

  }

  hidePophover() {
    this.style.display = 'none';
  }

  separateByProfession(candidates: any[]) {

    let self = this;

    this.jobsAppliedInfo = candidates.filter(function (job) {

      return job.template == self.template;

    });

    this.jobsAppliedInfoOtherTemplates = candidates.filter(function (job) {
      return !self.jobsAppliedInfo.includes(job);
    });
  }

  isATemplate() {

    if (this.template != 'undefined' && isNumeric(this.template)) {
      return true;
    }
    return false;
  }
}
