import { Alert, AlertsService, ALERT_TYPES } from './../../../services/alerts.service';
import { GroupMultipostingProvider, CREDENTIAL_TYPES, MultipostingCredentials } from './../../../classes/multiposting';
import { forkJoin } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../services/user-service.service';
import { UserDataService } from '../../../services/user-data-service.service';
import { User } from '../../../classes/user';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-multiposting-accounts',
  templateUrl: './multiposting-accounts.component.html',
  styleUrls: ['./multiposting-accounts.component.scss']
})

export class MultipostingAccountsComponent implements OnInit, OnDestroy {
  groupId: number;

  groupProviders: GroupMultipostingProvider[] = [];

  credentialTypes = CREDENTIAL_TYPES;

  componentSubscriptions: any = {};

  constructor(
    private userDataService: UserDataService,
    private activatedRoute: ActivatedRoute,
    private alertsService: AlertsService
  ) { }

  ngOnInit() {

    this.componentSubscriptions.activateRouteParamMap$ = this.activatedRoute.paramMap.subscribe(
      params => this.groupId = +params.get('client')
    );

    this.getMultipostingData();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getMultipostingData() {
    this.componentSubscriptions.retrieveMultiposting$ = forkJoin(
      this.userDataService.retrieveMultipostingProviders(this.groupId),
      this.userDataService.retrieveSocialNetworksUserGroup(this.groupId)
    ).subscribe(
      ([providers, credentials]) => {
        this.groupProviders = providers.map(provider => Object.assign(new GroupMultipostingProvider(), provider));
        this.groupProviders = GroupMultipostingProvider.getSerializedProvidersCredentials(this.groupProviders, credentials);
      },
      error => this.alertsService.setAlert(null, error)
    );
  }

  connect(groupProvider: GroupMultipostingProvider) {
    this.componentSubscriptions.userDataServiceAddSocialNetworks$ = this.userDataService.addSocialNetworks(
      this.buildRequest(groupProvider)
    ).subscribe(
      credentials => {
        this.groupProviders = GroupMultipostingProvider.getSerializedProvidersCredentials(this.groupProviders, [credentials]);
        this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__accountConnectedSuccessfully'));
      },
      error => this.alertsService.setAlert(null, error)
    );
  }

  disconnect(credentials: MultipostingCredentials) {
    this.componentSubscriptions.userDataServiceDeleteSocialNetworks$ = this.userDataService.deleteSocialNetworks(credentials.id)
      .subscribe(
        response => {
          const existingProvider = this.groupProviders.find(provider => provider.id === credentials.group_multiposting_provider);
          existingProvider.user_credentials = new MultipostingCredentials();
          this.alertsService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__accountDisconnectedSuccessfully'));
        },
        error => this.alertsService.setAlert(null, error)
      );
  }

  buildRequest(groupProvider: GroupMultipostingProvider): { [key: string]: any } {

    switch (groupProvider.provider.authentication) {
      case CREDENTIAL_TYPES.BASIC_AUTH:
        return {
          username: groupProvider.user_credentials.credentials.username,
          password: groupProvider.user_credentials.credentials.password,
          group_multiposting_provider: groupProvider.id,
        };

      case CREDENTIAL_TYPES.OAUTH2:
        return {
          username: groupProvider.user_credentials.credentials.username,
          group_multiposting_provider: groupProvider.id,
        };
    }
  }
}
