import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertsService } from '../../../services/alerts.service';
import { Subscription } from '../../../partners/models/subscription';
import { TranslateService } from '@ngx-translate/core';
import { PrivacyPolicyComponent } from '../../privacy-policy/privacy-policy.component';
import { Client } from '../../../partners/models/client';

@Component({
  selector: 'app-jobs-subscription',
  templateUrl: './jobs-subscription.component.html',
  styleUrls: [ './jobs-subscription.component.scss' ]
})
export class JobsSubscriptionComponent implements OnInit {

  @Output() activeFiltersChange: EventEmitter<Subscription> = new EventEmitter();


  userForm: FormGroup;
  formKeys: string[];
  client_id: string;
  client: Client;
  filtersCondition: boolean = false;
  saveCondition: boolean = false;
  lightColor: string;
  subscription;
  categoriesFilters = [ 'ORG', 'FUN', 'LOC', 'PRO' ];
  privacy = { checked: false };
  modalOpen = false;

  constructor(
    private form: FormBuilder,
    public dialogRef: MatDialogRef<JobsSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { color: string, filters: any, private: boolean, terms: any, option: boolean, client: Client; },
    private alertService: AlertsService,
    private translateService: TranslateService,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.createForm();
    this.setLightColor();
    this.setColor(this.data.color);
  }

  createForm() {
    const basicValidators = [ '', Validators.required ];
    let groupForm = {
      name: basicValidators,
      surname: basicValidators,
      email: basicValidators,
    };

    const requiredQuestions = this.data.client.extra_config.required_form.filter((question) => question.required == true && question.name == 'dni');

    if (requiredQuestions.length > 0) {
      for (let question of requiredQuestions) {
        groupForm[ question.name ] = basicValidators;
      }
    }
    this.userForm = this.form.group(groupForm);
    this.formKeys = Object.keys(this.userForm.value);
  }

  setLightColor() {
    this.lightColor = this.hexToRgbA(this.data.color);
  }

  hexToRgbA(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [ c[ 0 ], c[ 0 ], c[ 1 ], c[ 1 ], c[ 2 ], c[ 2 ] ];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [ (c >> 16) & 255, (c >> 8) & 255, c & 255 ].join(',') + ', .15)';
    }
    throw new Error('Bad Hex');
  }


  saveData() {
    if (this.userForm.valid) {

      this.activeFilters();

      if (this.filtersCondition) {

        if (this.privacy.checked) {
          this.categoriesFilters.forEach(category => {
            this.data.filters[ category ].forEach(filter => {
              filter.checked = false;
            });
          });

          this.activeFiltersChange.emit(this.subscription);
          this.saveCondition = false;

        } else {

          const privacyMsg = this.translateService.instant('__pleaseAcceptPrivacyPolicy');
          this.alertService.setAlert({ type: 'error', message: privacyMsg });
        }

      } else {

        const checkMsg = this.translateService.instant('__pleaseCheckOneOption');
        this.alertService.setAlert({ type: 'error', message: checkMsg });
      }

    } else {

      const contactDataMsg = this.translateService.instant('__pleaseCompleteContactData');
      this.alertService.setAlert({ type: 'error', message: contactDataMsg });
    }

  }

  buildSubscriptionObject(activeFilters): Subscription {

    var res;
    res = {
      first_name: this.userForm.get('name').value,
      last_name: this.userForm.get('surname').value,
      email: this.userForm.get('email').value,
      dni: this.userForm.get('dni') ? this.userForm.get('dni').value : "",
      origin: this.data.private ? 'PRI' : 'PUB',
      organization: activeFilters[ 'ORG' ].map(filter => filter.id),
      function: activeFilters[ 'FUN' ].map(filter => filter.id),
      location: activeFilters[ 'LOC' ].map(filter => filter.id),
      job_profession: activeFilters[ 'PRO' ].map(filter => filter.id),
      language: this.translateService.currentLang,
      is_employee: this.data.option
    };

    return res;
  }

  setColor(color: string) {
    document.documentElement.style.setProperty('--color-custom', color);
    document.documentElement.style.setProperty('--color-custom-opacity', color + '66');
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkSaveCondition() {
    this.activeFilters();
    return this.userForm.valid && this.filtersCondition && this.privacy.checked && this.validateEmail(this.userForm.value.email);
  }

  activeFilters() {
    const activeFilters = {};

    Object.entries(this.data.filters).forEach(element => {
      activeFilters[ element[ 0 ] ] = (element[ 1 ] as any).filter(item => item.checked);
    });

    this.subscription = this.buildSubscriptionObject(activeFilters);

    this.filtersCondition = Object.values(activeFilters).some((filter: any) => filter.length > 0);
  }

  onClose(option: string): void {
    this.dialogRef.close(option);
  }
}
