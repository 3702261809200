import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-service',
  templateUrl: './no-service.component.html',
  styleUrls: ['./no-service.component.scss']
})
export class NoServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
