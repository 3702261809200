import { Injectable, OnDestroy } from "@angular/core"
import { Observable, BehaviorSubject, merge, of, fromEvent } from 'rxjs';

import { mapTo, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class NetworkService implements OnDestroy {

  online$: BehaviorSubject < boolean > = new BehaviorSubject(true);

  componentSubscriptions: any = {};

  constructor() {
  
    this.componentSubscriptions.merge$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online')
        .pipe(mapTo(true)),
        fromEvent(window, 'offline')
          .pipe(mapTo(false))
      )
      .pipe(distinctUntilChanged())
        .subscribe(value => this.online$.next(value));
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }
}
