import { Component, OnInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Form } from '../../classes/form';

import 'tkt-form-builder/form-builder.js';
import { Constants } from '../../classes/constants';

declare const $: any;

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('200ms ease-out')),
      transition('default => rotated', animate('200ms ease-in'))
    ])
  ]
})

export class FormBuilderComponent implements OnInit {

  @Output() formModelChange: EventEmitter < any > = new EventEmitter();
  @Input() requiredFields ? = false;
  @Input() set clientRequiredFields(fields) {
    if (fields) {
      this.requiredForm = fields;
    }
  }
  @Input() set formModel(values) {
    const labels = this.requiredForm.map(element => (element.label || '')
      .replace(/[^a-zA-Z ]/g, '')
      .toLowerCase());
    this._formModel = Array.isArray(values) ? values.filter(
      value => !labels.includes((value.label || '')
        .replace(/[^a-zA-Z ]/g, '')
        .toLowerCase())
    ) : values;
  }

  @Input() formOptions = Form.INTERVIEW_OPTIONS;

  _formModel?: any;
  formJson: any;
  isCollapsed = true;
  state = 'default';
  requiredForm: any[] = [];

  constructor(
    private hostElement: ElementRef
  ) {}

  ngOnInit() {

    const formArea = $(this.hostElement.nativeElement.querySelector('.builder'));
    const formModel_condition = this._formModel && Object.keys(this._formModel)
      .length > 0;

    this.formOptions.defaultFields = formModel_condition ? this._formModel : [];
    this.formOptions.uploadFile = this.uploadFile();

    this.formJson = formArea.formBuilder(this.formOptions);

    $(this.hostElement.nativeElement.querySelector('.builder'))
      .on('fieldAdded fieldEdited fieldRemoved formLeft', ev => {
        const data = this.requiredFields ?
          this.requiredForm.concat(JSON.parse(this.formJson.formData)) : JSON.parse(this.formJson.formData);
        this.formModelChange.emit(data);
      });

    setTimeout(() => {
      const builder = this.hostElement.nativeElement.querySelector('.frmb');
      const data = this.requiredFields ?
        this.requiredForm.concat(JSON.parse(this.formJson.formData)) : JSON.parse(this.formJson.formData);
      this.formModelChange.emit(data);
      if (builder.style.minHeight === '0px') {
        builder.style.minHeight = '400px';
      }
    }, 300);
  }

  rotate() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
  }

  uploadFile(): Function {
    return (image, callback) => {
      const formData = new FormData();

      formData.append('file', image);

      const url = Constants.SUBMIT_PUBLIC_FILE;

      $.ajax({
        url: url,
        data: formData,
        headers: {
          'Authorization': 'JWT ' + localStorage.getItem(Constants.JWT)
        },
        type: 'POST',
        contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
        processData: false, // NEEDED, DON'T OMIT THIS
        success: function (data) {
          callback(data.url);
        },
        error: function (e) {
          console.log(e);
        }
      });
    };
  }

}
