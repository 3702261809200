import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WizardPhase, PHASES, Phase, INTERVIEW_PHASES, StatusPhases } from '../../classes/phases';

@Component({
  selector: 'app-active-phases-funnel',
  templateUrl: './active-phases-funnel.component.html',
  styleUrls: ['./active-phases-funnel.component.scss']
})
export class ActivePhasesFunnelComponent implements OnInit {

  _statusPhases: WizardPhase[] = [];

  interviewPhasesCodes = INTERVIEW_PHASES.map(phase => phase.code);
  minInterviewError = false;
  publishedJobError = false;

  @Input() readOnly = false;
  @Input() set statusPhases (phases: StatusPhases) {
    this._statusPhases = this.serializeStatusPhases(phases);
  }

  @Output() statusPhasesChange: EventEmitter<StatusPhases> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  serializeStatusPhases(statusPhases: StatusPhases): WizardPhase[] {
    return PHASES.map(phase => new WizardPhase(phase.name, phase.code, statusPhases[phase.code]));
  }

  handlePhaseClick(phase: WizardPhase) {
    this.minInterviewError = false;
    this.readOnly ? this.publishedJobError = true : this.handlePhaseToggle(phase);
  }

  handlePhaseToggle(phase: WizardPhase) {
    if (phase.is_active && this.interviewPhasesCodes.indexOf(phase.code) > -1) {
      this.minInterviewPhases() ? this.toggleWizardPhase(phase) : this.minInterviewError = true;
    } else {
      this.toggleWizardPhase(phase);
    }
  }

  minInterviewPhases(): boolean {
    const activeInterviews = this._statusPhases.filter(statusPhase => {
      return this.interviewPhasesCodes.indexOf(statusPhase.code) > -1 && statusPhase.is_active;
    });
    return activeInterviews.length > 1;
  }

  toggleWizardPhase(phase: WizardPhase) {
    phase.is_active = !phase.is_active;
    this.emitStatusPhases();
  }

  emitStatusPhases() {
    const phasesStatus = new StatusPhases();
    this._statusPhases.forEach( phase => phasesStatus[phase.code] = phase.is_active);
    this.statusPhasesChange.emit(phasesStatus);
  }

}
