import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Instrument } from '../../../instruments/models/instrument';
import { InstrumentsService } from '../../../instruments/instruments.service';
import { ReportConfig } from '../../../classes/reportConfig';
import { INSTRUMENT_TYPES } from '../../../instruments/constants';

@Component({
  selector: 'app-instruments-selected',
  templateUrl: './instruments-selected.component.html',
  styleUrls: ['./instruments-selected.component.scss']
})
export class InstrumentsSelectedComponent implements OnInit {

  @Input() selectedForms;
  @Input() killerTalentPoints;
  @Input() instruments;
  @Input() errors;
  @Input() editingWeight;
  @Input() update;

  @Output() toggleWeightEditEmitter = new EventEmitter();
  @Output() selectedInstrumentsEmitter = new EventEmitter();
  @Output() killerTalentPointsEmitter = new EventEmitter();
  @Output() selectedFormsChange = new EventEmitter();

  editingKillerPoints = false;
  math = Math;
  viewSelectionMessage = '';
  instrumentTypes = INSTRUMENT_TYPES;

  constructor(
    public instrumentsService: InstrumentsService
  ) { }

  ngOnInit() {
    this.instrumentsService.viewSelected = { selected: false, message: '__setTestWeight' };
    this.getMessage();
  }

  getMessage() {
    this.viewSelectionMessage = !this.instrumentsService.viewSelected.selected ? '__viewSelection' : '__setTestWeight';
  }
  getErrors(): string {
    return this.errors.join('\n');
  }

  toggleWeightEditEmitterFunc(instrumentPosition: number) {
    this.toggleWeightEditEmitter.emit(instrumentPosition);
  }

  toggleInstrument(instrument: Instrument) {
    instrument.selected = !instrument.selected;
    this.selectedInstrumentsEmitter.emit();
  }

  toggleReport(report: ReportConfig){
    this.instrumentsService.changeSelectedOneReport(report);
  }

  removeFormTemplate(formId: number) {
    this.selectedForms = this.selectedForms.filter(selectedForm => selectedForm.id !== formId);
    this.selectedFormsChange.emit(this.selectedForms);
  }

  saveKillerTalentPointsEmitter(talentPoints: number) {
    talentPoints = talentPoints || 0;
    this.killerTalentPoints = talentPoints;
    this.killerTalentPointsEmitter.emit(this.killerTalentPoints);
  }

}
