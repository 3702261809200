import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './classes/constants';
import { AlertsService } from './services/alerts.service';
import { NetworkService } from './services/network.service';
import { debounceTime } from 'rxjs/operators';
import { ClientTranslationService } from './services/client-translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {

  ready: boolean = false;

  componentSubscriptions: any = {};

  constructor(
    @Inject(DOCUMENT) private doc: any,
    private translate: TranslateService,
    public networkService: NetworkService,
    private alertService: AlertsService,
    private clientTranslationService: ClientTranslationService
  ) {
    this.translate.setDefaultLang(Constants.DEFAULT_LANGUAGE);
    this.translate.use(this.clientTranslationService.getNavigatorLanguage());
  }

  ngOnInit(): void {

    // NetworkService

    this.componentSubscriptions.networkServiceOnline$ = this.networkService.online$.pipe(debounceTime(5000)).subscribe(value => {
      if (!value) {
        this.alertService.setAlert({
          type: 'warning',
          message: '__connectionLostWarning'
        });
      }
    });

    // Client Translations

    this.clientTranslationService.start((ready: boolean) => {
      this.ready = ready;
    });
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

}
