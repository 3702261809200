import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-jobs-subscription-first-step',
  templateUrl: './jobs-subscription-first-step.component.html',
  styleUrls: [ './jobs-subscription-first-step.component.scss' ]
})
export class JobsSubscriptionFirstStepComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JobsSubscriptionFirstStepComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { color: string, filters: any, private: boolean, terms: any },
  ) { }

  ngOnInit() {
  }

  onClose(option: boolean | string): void {
    this.dialogRef.close(option);
  }
}
