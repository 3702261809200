import { Client } from "../partners/models/client";

export interface EmailTemplate {
  name: string;
  group: number;
  content: EmailState;
  id: number;
}

export class WsgiEmail {
  static VARS = [
    { title: '__jobName', value: '{{job_name}}', icon: 'briefcase-2.png' },
    { title: '__clientName', value: '{{client_name}}', icon: 'briefcase-2.png' },
    { title: '__candidateName', value: '{{candidate_first_name}} {{candidate_last_name}}', icon: 'user-2.png' },

  ];
  static GROUP_VARS = [
    { title: '__clientName', value: '{{client_name}}', icon: 'briefcase-2.png' },
    { title: '__candidateName', value: '{{candidate_first_name}} {{candidate_last_name}}', icon: 'user-2.png' },
  ];
  data: {
    content: string,
    subject: string
  };
  title: string;
  constructor(title: string, data: {
    content: string,
    subject: string
  } = {
      content: '',
      subject: ''
    }) {
    this.data = data;
    this.title = title;
  }
}

export class Email {
  content = '';
  subject = '';
  send_email = true;
}

export class PhaseEmails {
  PENDING = new Email();
  PASS = new Email();
  KO = new Email();
}

export class PhaseOAEmails {
  PENDING = new Email();
}

export class EmailState {

  static INSCRIPTIONS = 'INSCRIPTIONS';

  static CANDIDATURES = 'CANDIDATURES';

  static OTHER_ASSESSMENTS = 'OTHER_ASSESSMENTS';

  static OTHERS = 'OTHERS';

  INSCRIPTIONS = new PhaseEmails();

  CANDIDATURES = new PhaseEmails();

  OTHER_ASSESSMENTS = new PhaseOAEmails();

  OTHERS = {};


  static addEmail(state: string, email: WsgiEmail, emailState: EmailState): EmailState {
    emailState[state][email.title] = email.data;
    return emailState;
  }

  static setTemplateEmail(
    emails: Array<EmailTemplate>,
    emailState: EmailState
  ): EmailState {
    for (const email of emails) {
      const email_content = email.content || {};
      Object.keys(email_content)
        .forEach(value => emailState[value] = email_content);
    }
    return emailState;
  }

  static getEmaiTemplate(
    client: Client,
    emailState: EmailState,
    email_id?: number
  ): EmailTemplate {
    const emailTemplate = { name: client.name, group: client.id, content: emailState, id: email_id } as EmailTemplate;
    return emailTemplate;
  }

}
