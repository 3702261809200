import { INACTIVE } from './../constants';
import { IRestModel } from "../../library/models/rest-model";
import { JobCategory } from '../../classes/job';
import { InstrumentsConfig } from './client-instrument-config';
import { Appearance } from '../../classes/appearance';
import { ReportConfig } from '../../classes/reportConfig';

export class Client implements IRestModel {

  id = 0;
  name = '';
  contact = '';
  description = '';
  industry = '';
  status: string = INACTIVE;
  parent: number;
  categories: Array<JobCategory> = [];
  public_hub = false;
  private_hub = false;
  // retrieves the logo file uuid
  logo = '';
  email_sender = '';
  client_email: string;
  terms_and_conditions: string;
  tax_id = '';
  legal_advice: string = '';
  cookies_policy: string = '';
  instruments_config: InstrumentsConfig = new InstrumentsConfig();
  gdpr_consent_period: string;
  extra_config: {
    social_networks: { name: string, url: string; }[],
    appearance: Appearance,
    required_form: any[],
    multiposting?: boolean,
    alert_subscriptions: string,
    analytics: any,
    inscription_form: any;
  } = {
      social_networks: [
        { name: 'Facebook', url: '' },
        { name: 'Twitter', url: '' },
        { name: 'LinkedIn', url: '' }
      ],
      appearance: new Appearance(),
      required_form: [ {
        'type': 'text',
        'subtype': 'email',
        'label': 'E-mail',
        'className': 'form-control',
        'required': true,
        'name': 'email'
      } ],
      alert_subscriptions: 'NON',
      analytics: {},
      inscription_form: { "hide_linkedin_sso": true }
    };
  private_extra_config: {
    reputation_analytics: any;
    analytics: any;
  };
  uuid: string;
  site: {
    id: number,
    domain: string,
    name: string;
  };
  support_email = '';
  reports_config: Array<ReportConfig> = [];
  candidates_feedback_request: boolean = false;
  active_jobs?: number;
  forecasted_jobs?: number;
  cv_search: boolean;
}

export class ClientLite {
  pk: number;
  name: string;
  industry: string;
}
