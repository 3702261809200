import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AlertsService, ALERT_TYPES } from '../../services/alerts.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('slide', [
      state('true', style({
        transform: 'translateX(0%)',
        opacity: '1',
        display: 'flex'
      })),
      state('false', style({
        transform: 'translateX(100%)',
        opacity: '0',
        display: 'none'
      })),
      transition('true => false', animate('300ms ease-in')),
      transition('false => true', animate('300ms ease-in'))
    ])
  ]
})
export class AlertComponent implements OnInit, OnDestroy {

  showAlert: boolean = false;
  autoClose;
  private _type: string = '';
  private _message: string = '';

  set type(type: string) {
    if (type) {
      clearTimeout(this.autoClose);
      this.showAlert = true;
      this._type = type;

      switch (type) {
      case ALERT_TYPES.ERROR:
        this.autoClose = setTimeout(this.hideAlert.bind(this), 3000);
        break;
      case ALERT_TYPES.WARNING:
        this.autoClose = setTimeout(this.hideAlert.bind(this), 5000);
        break
      case ALERT_TYPES.SUCCESS:
        this.autoClose = setTimeout(this.hideAlert.bind(this), 5000);
        break
      default:
        this.autoClose = setTimeout(this.hideAlert.bind(this), 3000);
      }
    }
  }

  get type(): string {
    return this._type
  }

  set message(message: string) {
    if (message) {
      this._message = message;
    }
  }
  get message(): string {
    return this._message
  }

  componentSubscriptions: any = {};

  constructor(private alertService: AlertsService) {}

  ngOnInit() {
    this.componentSubscriptions.alertServiceShowAlert$ = this.alertService.showAlert$.subscribe(alertData => this.setAlertData(alertData));
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  hideAlert() {
    this.showAlert = false;
    this.type = '';
    this.message = '';
  }

  setAlertData(alertData) {
    this.type = alertData.type;
    this.message = alertData.message;
  }

}
