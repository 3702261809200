import { ShortPhasesLabels, Status, StatusPhases, _StatusPhases } from './../../classes/phases';
import { Component, OnInit, OnDestroy, Input, TemplateRef, ViewChild } from '@angular/core';
import { Timeline, TimelineDictionary, PhaseAndStatusToText, SerializedTimelineEvent } from '../../classes/timeline';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CandidateService } from '../../services/candidate.service';
import { AlertsService } from '../../services/alerts.service';
import { UserService } from '../../services/user-service.service';
import { sortArrayOfObjectsBy } from '../../library/utils';
import { TranslateService } from '@ngx-translate/core';
import { TagsControlService } from './../../tags/services/tags-control.service';
import { Tag } from './../../classes/tag';

@Component({
  selector: 'app-candidate-timeline',
  templateUrl: './candidate-timeline.component.html',
  styleUrls: [ './candidate-timeline.component.scss' ]
})
export class CandidateTimelineComponent implements OnInit, OnDestroy {
  status = Status;
  statusphases = _StatusPhases;
  _timeline: any[];
  _job: number;
  availableTags: Tag[] = []

  @Input() set timeline(timeline: Timeline[]) {
    this.serializedTimeline = this.serializeTimeline(timeline);
    this.generateSplittedTimeline();
    this.addTimelineDropdowns(this.serializedTimeline);
    this._timeline = timeline;
  }
  get timeline(): Timeline[] {
    return this._timeline;
  }

  @Input() set job(job: number) {
    this._job = job;
    this.serializedTimeline = this.serializeTimeline(this.timeline);
    this.generateSplittedTimeline();
    this.addTimelineDropdowns(this.serializedTimeline);
  }

  get job(): number {
    return this._job;
  }

  @Input() multijob: boolean = false;

  serializedTimeline: SerializedTimelineEvent[];
  serializedTimelineCandidate: SerializedTimelineEvent[] = [];
  serializedTimelineJob: SerializedTimelineEvent[] = [];
  showEventDetail: {} = {};

  inActionComment: number;
  editingComment: any = { content: null, privacy: null, highlight: null };
  editModalRef: BsModalRef;
  @ViewChild('editCommentModal', { read: TemplateRef, static: true }) editModalTemplate: TemplateRef<any>;
  deleteModalRef: BsModalRef;
  @ViewChild('deleteCommentModal', { read: TemplateRef, static: true }) deleteModalTemplate: TemplateRef<any>;
  config = {
    extraPlugins: 'colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: [ 'Format', 'Font' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
    ]
  };

  componentSubscriptions: any = {};

  constructor(
    private modalService: BsModalService,
    private candidateService: CandidateService,
    public userService: UserService,
    private alertService: AlertsService,
    private translateService: TranslateService,
    private tagsControlService: TagsControlService,
  ) { }

  ngOnInit() {
    this.subscribeToAvailableTags();

  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }


  serializeTimeline(timeline: Timeline[]) {
    const jobFilteredTimeline: Timeline[] = timeline.filter(event =>
      (!event.params.job || +event.params.job === +this._job || event.message === 'apply' || event.message === 'comment') && event.message !== 'unlocked_user'
    );
    const sortedTimeline: Timeline[] = sortArrayOfObjectsBy(jobFilteredTimeline, 'created', -1);
    const result: SerializedTimelineEvent[] = sortedTimeline
      .filter(event => TimelineDictionary[ event.message ])
      .map((event) => {
        const newEvent = {
          id: event.id,
          message: event.message,
          date: event.created,
          title: this.translateService.instant(TimelineDictionary[ event.message ].title),
          owner: event.owner ? `${event.owner.first_name} ${event.owner.last_name}` : '',
          owner_id: event.owner ? event.owner.id : null,
          icon: TimelineDictionary[ event.message ].icon,
          status: event.params.status,
          previous_status: event.params.previous_status,
          text: this.generateTimelineText(event.params, TimelineDictionary[ event.message ].params, TimelineDictionary[ event.message ].text,),
          previous_transition: event.params.previous_transition,
          transition: event.params.transition,
          dropdown: TimelineDictionary[ event.message ].dropdown,
          privacy: event.params.privacy,
          comment_id: event.params.comment_id,
          job: event.params.job_name ? `${this.translateService.instant('__inCap')} ${event.params.job_name}` : '',
          job_id: event.params.job,
          tag: this.availableTags.find(tag => tag.id === event.params.tag),
          untag: event.params.tag,
          is_highlight: event.params.highlight
        }
        return newEvent
      });

    return result;
  }

  generateSplittedTimeline() {
    this.serializedTimelineCandidate = [];
    this.serializedTimelineJob = [];
    this.serializedTimeline.forEach(event => {

      // check if apply to this offer
      if (event.message === "apply") {
        if (!this.multijob) { //single job
          if (event.job_id === this._job) {
            this.serializedTimelineCandidate.push(event);
            this.serializedTimelineJob.push(event);
          }
        }
        else { // multijob
          this.serializedTimelineCandidate.push(event);
          if (event.job_id === this._job) {
            this.serializedTimelineJob.push(event);
          }
        }
        return;
      }

      if (event.is_highlight) {
        if (event.icon === "chat") event.icon = "announcement";
        this.serializedTimelineCandidate.push(event);
      }
      else {
        if (event.icon === "announcement") event.icon = "chat";
        this.serializedTimelineJob.push(event);
      }
      return;
    });
  }

  generateTimelineText(eventParams: Timeline[ 'params' ], textParams: string[], text: string) {
    const params = {};

    // tslint:disable-next-line:max-line-length
    textParams.forEach(textParam => params[ textParam ] = ShortPhasesLabels[ textParam ] ? ShortPhasesLabels[ textParam ] : eventParams[ textParam ]);

    return text ? this.translateService.instant(text, params) : text;
  }

  addTimelineDropdowns(serializedTimeline: SerializedTimelineEvent[]) {
    serializedTimeline.forEach(event => {
      if (event.dropdown) {
        this.showEventDetail[ event.id ] = false;
      }
    });
  }

  openEditCommentModal(comment) {
    this.inActionComment = comment.comment_id;
    this.editingComment.content = comment.text;
    this.editingComment.privacy = comment.privacy;
    this.editingComment.highlight = comment.is_highlight;
    this.editModalRef = this.modalService.show(this.editModalTemplate, { backdrop: 'static' });
    return false;
  }

  editComment() {
    const comment_id = this.inActionComment;
    const comment_edited = this.translateService.instant('__commentEdited');
    const comment_error = this.translateService.instant('__commentError');
    this.componentSubscriptions.candidateServiceEditComment$ = this.candidateService.editComment(this.inActionComment, this.editingComment).subscribe(
      response => {
        const comment = this.serializedTimeline.find(c => c[ "comment_id" ] === comment_id)
        if (comment) {
          comment.text = this.editingComment.content;
          comment.privacy = response.privacy;
          comment.is_highlight = response.highlight;
        }
        this.generateSplittedTimeline();
        this.alertService.setAlert({ type: 'success', message: comment_edited });
      },
      error => this.alertService.setAlert({ type: 'error', message: comment_error })
    );
    this.closeEditModal();
  }

  closeEditModal() {
    this.inActionComment = null;
    this.editModalRef.hide();
  }
  areCommentActionsAllowed(event) {
    return this.userService.getUser() && event.title === 'Comment' && event.owner && event.owner_id === this.userService.getUser().id;
  }

  openDeleteCommentModal(id) {
    this.inActionComment = id;
    this.deleteModalRef = this.modalService.show(this.deleteModalTemplate, { backdrop: 'static' });
    return false;
  }

  deleteComment() {
    const comment_id = this.inActionComment;
    const comment_deleted = this.translateService.instant('__commentDeleted');
    const comment_error = this.translateService.instant('__commentDeleteError');
    this.componentSubscriptions.candidateServiceDeleteComment$ = this.candidateService.deleteComment(this.inActionComment).subscribe(
      response => {
        this.serializedTimeline = this.serializedTimeline.filter(comment => comment[ 'comment_id' ] !== comment_id);
        this.generateSplittedTimeline();
        this.alertService.setAlert({ type: 'success', message: comment_deleted });
      },
      error => this.alertService.setAlert({ type: 'error', message: comment_error })
    );
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    this.inActionComment = null;
    this.deleteModalRef.hide();
  }

  subscribeToAvailableTags() {
    this.tagsControlService.availableTags$.subscribe(
      tags => {
        this.availableTags = tags;
        this.serializedTimeline = this.serializeTimeline(this._timeline);
        this.generateSplittedTimeline();
        this.addTimelineDropdowns(this.serializedTimeline)
      }
    );

  }
}
