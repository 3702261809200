import { COLOR_VARIABLES } from './../../utils/color-variables';
import { Component, OnInit, Input, ElementRef, SimpleChanges } from '@angular/core';
declare const Highcharts: any

@Component({
  selector: 'app-line-chart-dates',
  templateUrl: './line-chart-dates.component.html',
  styleUrls: ['./line-chart-dates.component.scss']
})
export class LineChartDatesComponent implements OnInit {

  @Input() data: { x: number, y: number }[];
  @Input() options: any;

  constructor(private hostElement: ElementRef) { }

  config = {
    plotOptions: {
      series: {
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT']],
            [1, 'rgb(255, 255, 255)']
          ]
        }
      }
    },
    chart: {
      type: 'area'
    },
    title: {
      text: ''
    },
    rangeSelector: {
      selected: 1
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 1000 * 3600 * 24 * 30 // 1 month
    },
    series: [{
      type: 'line',
      name: '',
      data: []
    }],
    legend: {
      enabled: false,
      reversed: true
    },
    credits: {
      enabled: false
    },
    colors: [
      COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT']
    ],
  };

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._setData();
    Highcharts.chart(this.hostElement.nativeElement.querySelector('.line-chart__content'), this.config);
  }

  _setData() {

    this.config.series = [{
      type: 'area',
      name: 'Views',
      data: this.data
    }];
    this.config.xAxis.tickInterval = this.options.tickInterval;
  }
}
