import { Component, Input, Output, ElementRef, TemplateRef, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Interview } from '../../../classes/interview';
import { Form } from '../../../classes/form';
import { InterviewsService } from '../interviews.service';

@Component({
  selector: 'app-interviews-builder',
  templateUrl: './interviews-builder.component.html',
  styleUrls: ['./interviews-builder.component.scss']
})
export class InterviewsBuilderComponent implements OnDestroy {

  interview: Interview;
  modalRef: BsModalRef;
  interviewName: string;
  formOptions = Form.INTERVIEW_OPTIONS;
  isEditing: boolean;

  @Output() interviewEmitter: EventEmitter<Interview> = new EventEmitter();

  @Input() interviews: Interview[];
  @Input() title = 'Interview Guides';
  @Input() activePhaseIndex: number;
  @Input() interviewToEdit: Interview;

  componentSubscriptions: any = {};

  constructor(
    private hostElement: ElementRef,
    private modalService: BsModalService,
    public interviewsService: InterviewsService
  ) {

    this.componentSubscriptions.interviewServiceGetEditorMode$ = this.interviewsService.getEditorMode().subscribe(val => {

      this.isEditing = val;

      if (this.isEditing) {
        this.interviewsService.activateBuilder();
        this.editInterview(this.interviewsService.getInterviewToEdit());
      }

    });

  }

  ngOnInit(){
    this.interview = this.interviewToEdit;
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }             

  openModal (modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal, {backdrop: 'static'});
  }

  changeRemoveIcons() {
    const remove_button = Array.from(
      this.hostElement.nativeElement.querySelectorAll('.bs-remove-tab')
    );
    remove_button.forEach( e => {
      (<HTMLElement>e).innerHTML = ' <i class="fas fa-times"></i>';
    });
  }

  interviewIsDuplicated() {
    return this.interviews.some(interview => interview.name === this.interviewName);
  }

  createInterview() {
    this.interview = new Interview(this.interviewName);
    this.interviewsService.activateBuilder();
    this.interviewsService.setCreatingMode(true);
  }

  editInterview(interview: Interview) {
    this.interview = interview;
    this.interviewName = interview.name;
    this.interviewsService.activateBuilder();
  }

  removeInterview(index: number) {
    this.interviews.splice(index, 1);
  }

  saveInterview() {

    this.interviewsService.removeBuilder();

    if (!this.isEditing) {
      this.interview.selected = true;
      this.interviewsService.setCreatingMode(false);
      this.emitInterview();
    } else {
      this.interviewsService.setEditorMode(false);
      this.interviewsService.setInterviewToEdit(null);
    }
    this.interviewName = '';

  }

  emitInterview() {
    this.interviewEmitter.emit(this.interview);
  }

  // saveOAInterview() {
  //   this.formBuilderStatus = false;
  //   this.interviewName = '';
  //   this.builderStatusChange();
  //   this.OAInterview.selected = true;

  //   if (!this.interviewToEdit) {
  //     this.OAInterviews.push(this.OAInterview);
  //   } else {
  //     this.interviewToEditChange.emit(undefined);
  //   }

  //   this.OAInterviewsChange.emit(this.OAInterviews);

  // }

  cancelBuilder() {
    this.interviewsService.removeBuilder();
    this.interviewsService.setEditorMode(false);
    this.interviewsService.setCreatingMode(false);
    this.interviewName = '';
  }

}
