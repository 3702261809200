import { Filter } from './../../library/classes/filter';
import { Subject } from 'rxjs';
import { User } from './../../classes/user';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router/';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { setFilter } from '../../library/utils';
import { UserDataService } from '../../services/user-data-service.service';
import { Role } from '../../classes/role';
import { RolesDesc } from '../../classes/role';
import { JobCategory, JobCategoryTemplate } from '../../classes/job';
import { UserService } from './../../services/user-service.service';
import { SortPipe } from '../../pipes/sort.pipe'
import { AlertsService } from '../../services/alerts.service';
import { ClientService } from '../../partners/services/client.service';
import { Client } from '../../partners/models/client';
import { switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-users-settings-client',
  templateUrl: './users-settings-client.component.html',
  styleUrls: ['./users-settings-client.component.scss'],
  providers: [SortPipe]
})
export class UsersSettingsClientComponent implements OnInit, OnDestroy {

  loadingUsers = true;
  loadingUserDetail = true;

  users: User[] = [];
  userSelected: User;
  rolesList: { name: string, id: number }[] = [];
  roleSelected: number;
  rolesDesc: RolesDesc = new RolesDesc();
  displayNewUser = false;
  userJobCategoriesTemplate;
  jobCategoriesList: any;
  searchTerm = new Subject<string>();
  parameters: Filter[] = [];
  appliedFilters: Array<any>;
  client = new Client;
  clientId: any;
  showFilters = false;
  filters = {
    role: null,
    organization: null,
    function: null,
    location: null
  };

  editUserId: number = null;

  componentSubscriptions: any = {};

  constructor(
    public clientService: ClientService,
    private userService: UserService,
    private userDataService: UserDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sortPipe: SortPipe,
    private alertService: AlertsService,
  ) {

  }

  ngOnInit() {
    this.getUsers();
    this.clientId = this.activatedRoute.snapshot.parent.params.client;
    this.getClient();
    this.parameters.push(new Filter('group', this.clientId));
    this.doSearch();
    this.setJobCategories();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getUsers() {
    const users$ = this.activatedRoute.parent.paramMap.pipe(switchMap(params => this.clientService.getUsersByClient(params.get('client'))));
    this.componentSubscriptions.users$ = users$.subscribe(
      users => this.setUsers(users)
    );
  }

  getClient() {
    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(this.clientId)
      .subscribe(
        response => this.client = response,
        error => console.log(error)
      );
  }

  setUsers(users: User[]) {
    this.users = this.sortPipe.transform(users, ['first_name', 'last_name']);
    this.loadingUsers = false;
    this.subscribeQueryParams();
    const user_id = this.users.length > 0 ? this.users[0].id : null;
    this.setUserId(user_id);
  }

  setUserId(user_id: string) {
    if (user_id) {
      const queryParams = { relativeTo: this.activatedRoute, queryParams: { user: user_id } };
      this.router.navigate([], queryParams);
    }
  }

  setJobCategories() {
    this.client.id = this.clientId;
    this.clientService.getJobCategories(this.client.id)
      .toPromise()
      .then(
        response => {
          this.jobCategoriesList = this.formatCategories(response),
            this.client.categories = response;
          this.clientService.saveClient(this.client);
        },
        error => console.log(error)
      );
  }

  formatCategories(jobCategories: JobCategory[]): JobCategoryTemplate[] {
    const parsedCategories = JobCategory.jobCategoryToTemplate(jobCategories);
    const parsedCategoriesWithoutAll = JobCategory.removeCategoriesAll(parsedCategories);
    return parsedCategoriesWithoutAll;
  }

  updateUsersList() {
    this.componentSubscriptions.clientServiceGetUsersByClient$ = this.clientService.getUsersByClient(this.client.id.toString())
      .subscribe(
        users => {
          this.users = this.sortPipe.transform(users, ['first_name', 'last_name']);
          this.loadingUsers = false;
        }
      );
  }

  doSearch(): void {
    this.componentSubscriptions.searchTermPipe$ = this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(search => {
          this.parameters = setFilter(this.parameters, new Filter('search', search));
          return this.userService.getUserFromClient(...this.parameters);
        }))
      .subscribe(
        filteredUsers => this.setUsers(filteredUsers.body)
      );
  }

  openFilters(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showFilters = !this.showFilters;
  }

  closeFilters(event) {
    this.showFilters = false;
  }

  clearSelectFilters() {
    this.filters.organization = null;
    this.filters.function = null;
    this.filters.location = null;
    this.initializeJobParams();
  }

  botonResetAll() {
    this.resetFilters();
    this.filterUsers();
  }

  resetFilters() {
    this.filters = {
      role: null,
      organization: null,
      function: null,
      location: null
    };
    this.initializeJobParams();
  }

  removeFilter(category: string) {
    this.filters[category] = null;
    this.filterUsers();
  }

  initializeJobParams() {
    this.parameters = [];
    this.parameters = setFilter(this.parameters, new Filter('group', String(this.client.id)));
    this.appliedFilters = [];
  }

  filterUsers() {
    Object.keys(this.filters)
      .forEach(filter => {
        this.appliedFilters = Object.entries(this.filters).filter(value => value[1]);
        this.parameters = setFilter(
          this.parameters, new Filter(filter, this.filters[filter])
        );
      });

    this.componentSubscriptions.userServiceGetUserFromClient$ = this.userService.getUserFromClient(...this.parameters)
      .subscribe(
        response => {
          this.setUsers(response.body);
          this.showFilters = false;
        }
      );
  }

  subscribeQueryParams() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        if (queryParam.get('user')) {
          this.loadingUserDetail = true;
          this.getUserDetail(queryParam.get('user'));
        } else { this.loadingUserDetail = false }
      }
    );
  }

  getUserDetail(user_id: string) {
    this.componentSubscriptions.userDataServiceGetUserDetail$ = this.userDataService.getUserDetail(user_id)
      .subscribe(
        response => {
          this.userSelected = Object.assign(new User(), response);
          this.loadingUserDetail = false;
          this.getClientRoles();
          if (this.userSelected.categories_data) {
            // Professions must not be here.
            this.userJobCategoriesTemplate = JobCategory.jobCategoryToTemplate(this.userSelected.categories_data)
              .filter(a => a.category != "profession");
          }
        },
        error => console.log(error)
      );
  }

  getClientRoles() {
    this.rolesList = Object.keys(Role)
      .filter(role => role.indexOf('CLIENT') > -1)
      .map(role => {
        return {
          name: role.toLowerCase()
            .split("_")
            .join(" "),
          id: Role[role]
        };
      });
  }

  getRoleName(): any {
    const role = this.rolesList.find(role => role.id === this.userSelected.role);
    return role ? role.id : '';
  }

  editUser(user: User) {
    this.displayNewUser = true;
    this.editUserId = user.id;
  }

  deleteUser(user: User) {
    this.componentSubscriptions.userDataServiceDeleteUser$ = this.userDataService.deleteUser(user)
      .subscribe(
        response => {
          this.getUsers();
          this.alertService.setAlert({ type: 'success', message: '__userDeletedOk' });
        },
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
      )
  }

  userIsAllowedToDelete(user) {
    return this.clientService.isActionActive(this.client) && this.userService.hasClientAdminPermissions() && user.id !== this.userService.getUser()
      .id;
  }

  hideModal(userId) {
    if (userId) {
      this.updateUsersList();
      this.getUserDetail(userId);
    }
    this.displayNewUser = false;
    this.editUserId = null;
  }

}
