import { MatTabChangeEvent } from '@angular/material/tabs';
import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter, TemplateRef, Renderer2 } from '@angular/core';
import { Candidate, CandidateCVToken } from '../../classes/candidate';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CandidateService } from '../../services/candidate.service';
import { AlertsService, ALERT_TYPES, Alert } from '../../services/alerts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { Subscription, Observable, of } from 'rxjs';
import { UserService } from '../../services/user-service.service';
import { Constants } from '../../classes/constants';
import { INTERVIEW_PHASES, INSCRIPTION, Phase, ShortPhasesLabels, Status } from '../../classes/phases';
import { WsgiEmail } from '../../classes/wsgi-email';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { Job } from '../../classes/job';
import { copyTextToClipboard } from '../../library/utils';

declare const CKEDITOR: any;

@Component({
  selector: 'app-dashboard-candidate-multijob',
  templateUrl: './dashboard-candidate-multijob.component.html',
  styleUrls: [ './dashboard-candidate-multijob.component.scss' ]
})
export class DashboardCandidateMultijobComponent implements OnInit, OnDestroy {
  loading = true;

  ks: string;
  subscription: Subscription;
  client: Client;
  phase = 'INS';
  math = Math;
  displayCopiedInfo = false;
  textToCopy: string;

  private _candidate: Candidate;
  copyTextToClipboard = copyTextToClipboard;

  @Output() actionCandidate = new EventEmitter<{ candidate: Candidate, event: string }>();
  @Input() isPublic: boolean;
  @Input()
  set candidate(candidate: Candidate) {
    this.loading = true;
    if (candidate && candidate.jobs) {
      this._candidate = candidate;
      this.resetCandidateData();
      this._setCandidateTimeline(candidate.id);
      candidate.jobs.forEach((job, i) => {
        this.serializeJobData(job, i);
        this.checkJobData(job, i);
      });
      this.loading = false;
    } else {
      this.alertService.setAlert({ type: 'error', message: this.translateService.instant('__errorWithCandidate') });
    }

  }
  get candidate(): Candidate {
    return this._candidate;
  }


  colors: Array<string> = [ 'orange', 'green', 'blue', 'pink', 'turquoise', 'purple' ];

  jobSelected: number;
  candidateData: Array<{ form: boolean, interview_guides: boolean }>;
  cvToken: CandidateCVToken;
  modalSelected;
  candidateTimeline: any[] = [];
  interviewPhases = INTERVIEW_PHASES;
  inscriptionPhase: Phase = INSCRIPTION;

  @ViewChild('tabGroup', { static: false }) tabGroup;

  @ViewChild('mailModal', { read: TemplateRef, static: true })
  mailModal: TemplateRef<any>;
  @ViewChild('shareModal', { read: TemplateRef, static: true })
  shareModal: TemplateRef<any>;

  emailVariables = WsgiEmail.GROUP_VARS;
  modalRef: BsModalRef;
  buttons = WsgiEmail.GROUP_VARS;
  config = {
    extraPlugins: 'divarea,colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: [ 'Format', 'Font' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'variables', items: [ '__jobName', '__clientName', '__candidateName' ] }
    ]
  };

  candidate_url = '';
  email: { subject: string, content: string } = {
    subject: '',
    content: ''
  };
  comment: { content: string, privacy: string } = {
    content: '',
    privacy: 'PUB'
  };

  status = Status;
  shortPhasesLabels = ShortPhasesLabels;

  showJobSelector = false;
  showPhones = false;

  isInPublicCandidate = false;

  componentSubscriptions: any = {};

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,
    public candidateService: CandidateService,
    private alertService: AlertsService,
    private router: Router,
    private keyboardShortcutService: KeyboardShortcutService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    public clientService: ClientService,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.checkUrl();
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
    this.getClient();
    this.setJobSelected();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }


  checkUrl() {
    if (this.activatedRoute.snapshot.url.length > 0 && this.activatedRoute.snapshot.url[ 0 ].path === 'public-candidate') {
      this.isInPublicCandidate = true;
    }
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'DashboardCandidateMultijob.Right':
        this.goToNextTab();
        break;
      case 'DashboardCandidateMultijob.Left':
        this.goToPreviousTab();
        break;
      case 'DashboardCandidateMultijob.ShiftM':
        this.showModal(this.mailModal);
        break;
      case 'DashboardCandidateMultijob.ShiftS':
        this.showModal(this.shareModal);
        break;
      case 'DashboardCandidateMultijob.ShiftX':
        this.closeCandidateDetailsOrModal();
        break;
    }
  }

  goToNextTab() {
    if (this.tabGroup.selectedIndex < this.tabGroup._tabs.length) {
      this.tabGroup.selectedIndex++;
    }
  }

  goToPreviousTab() {
    if (this.tabGroup.selectedIndex > 0) {
      this.tabGroup.selectedIndex--;
    }
  }

  closeCandidateDetailsOrModal() {
    if (this.modalSelected) {
      this.closeModal();
    } else {
      this.router.navigate([ this.router.url.split('?')[ 0 ] ]);
    }
  }

  getClient() {
    const client_id = +this.activatedRoute.parent.snapshot.paramMap.get('client');
    this.componentSubscriptions.checkClient$ = this.checkClient(client_id)
      .subscribe(
        response => this.client = response,
        error => console.log(error)
      );
  }

  checkClient(client_id): Observable<Client> {
    if (!client_id) {
      const client = new Client();
      client.status = 'INA';
      return of(client);
    }

    return this.clientService.loadClient(client_id);
  }

  resetCandidateData() {
    this.jobSelected = 0;
    this.candidateData = [];
    this.candidateTimeline = [];
  }

  serializeJobData(job, i) {
    this.candidateData.push({ form: false, interview_guides: false });

    this.candidate.jobs[ i ].values.form = Candidate.filterFormData(this.candidate.jobs[ i ].values.form);

    if (!this.isPublic && job.comments.length > 0) {
      this.candidate.jobs[ i ].comments = Candidate.sortCandidateComments(job.comments);
    }
  }

  checkJobData(job, i) {
    this.candidateData[ i ].form = Object.keys(job.values.form)
      .length > 0;
  }

  _setCandidateTimeline(candidate_id) {
    const token = this.isPublic ? this.activatedRoute.snapshot.paramMap.get('token') : '';
    const timelineObs = this.isPublic ?
      this.candidateService.getCandidatePublicTimeline(token) :
      this.candidateService.getCandidateTimeline(candidate_id);
    this.componentSubscriptions.timelineObs$ = timelineObs.subscribe(
      timeline => this.candidateTimeline = timeline,
      error => console.log(error)
    );
  }

  getCandidateColor(colors) {
    const index = this.candidate.email[ 0 ].charCodeAt(0) % colors.length;
    return colors[ index ];
  }

  getCandidateInitials() {
    if (this.candidate.first_name && this.candidate.last_name) {
      return `${this.candidate.first_name[ 0 ]}${this.candidate.last_name[ 0 ]}`;
    }
    return `${this.candidate.email[ 0 ]}${this.candidate.email[ 1 ]}`;
  }

  copyCandidateUrl() {

    this.textToCopy = this.candidate_url;
    this.textToCopy = this.textToCopy.replace(/<br>/g, "\n").replace(/<\/p><p>/g, "\n").replace(/<[^>]+>/g, "");

    navigator.clipboard.writeText(this.textToCopy)
      .then(() => {
        this.displayCopiedInfo = true;
        setTimeout(() => { this.displayCopiedInfo = false; }, 3100);
      })
      .catch((error) => { console.error('Copy failed!'); });

    const url_copied = this.translateService.instant('__urlCopied')
    this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, url_copied));

  }

  sanitizeUrl(url) {
    const completeUrl = `${Constants.BASE_URL.slice(0, -1)}${url}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(completeUrl);
  }

  convertSlugsToText(slugs: string[]) {
    return slugs.map(slug => slug.replace(/_/g, ' '));
  }

  shareCandidate() {
    this.componentSubscriptions.candidateServiceShareCandidate$ = this.candidateService.shareCandidate(this.candidate.id)
      .subscribe(
        response => {
          this.candidate_url = this.candidateService.pathToPublicCandidate(response.token);
          this.showModal(this.shareModal);
        },
        error => console.log(error)
      );
  }

  showModal(modal) {
    this.modalSelected = modal;
    this.modalRef = this.modalService.show(modal, { backdrop: 'static' });
  }

  addVar(button) {
    for (const instance in CKEDITOR.instances) {
      CKEDITOR.instances[ instance ].insertText(button.value);
    }
  }

  sendEmail() {
    this.componentSubscriptions.candidateServiceSendPoolEmail$ = this.candidateService.sendPoolEmail(this.candidate, this.email)
      .subscribe(
        () => {
          this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__emailSentSuccesfully'));
          this.closeModal();
        },
        () => {
          this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, '__emailSentError'));
          this.closeModal();
        }
      );
  }

  saveComment() {
    this.componentSubscriptions.candidateServiceAddComment$ = this.candidateService.addComment(this.candidate.jobs[ this.jobSelected ], this.comment)
      .subscribe(
        response => {
          response.owner_name = `${this.userService.getUser().first_name} ${this.userService.getUser().last_name}`;
          this.candidate.jobs[ this.jobSelected ].comments.unshift(response);
          this.closeModal();
          this.comment.content = '';
        },
        error => console.log(error)
      );
  }

  closeModal() {
    this.modalSelected = '';
    this.modalRef.hide();
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.tab.textLabel.toLowerCase() === 'evaluation') {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    }
  }

  getCandidateCurrentPhase(candidate: Candidate) {
    return candidate.jobs[ this.jobSelected ].transitions ? this.candidate.jobs[ this.jobSelected ].transitions : 'INS';
  }

  setJobSelected() {
    this.phase = this.getCandidateCurrentPhase(this.candidate);
    this.jobSelected = 0;
    this.candidate.jobs[ 0 ].selected = true;
  }

  selectJob(i: number) {
    this.jobSelected = i;
    this.candidate.jobs.forEach((candJob: Job) => candJob.selected = false);
    this.candidate.jobs[ i ].selected = true;
  }

  goToOffer() {

    const job = this.candidate.jobs[ this.jobSelected ];

    this.router.navigate([ 'main', this.activatedRoute.snapshot.params.client, job.job_id.toString(), 'candidates', job.transitions ],
      {
        queryParams: { candidate: job.uuid }
      });

  }

  showPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'block');
  }

  dismissPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'none');
  }


  conditionHubLink(): boolean {

    const videoInterview: boolean = this.candidate.jobs[ this.jobSelected ].instruments_data && this.candidate.jobs[ this.jobSelected ].instruments_data.some(instrument => instrument.phase === 'OA');

    const jobPhase: boolean = (this.candidate.jobs[ this.jobSelected ].phases && this.candidate.jobs[ this.jobSelected ].phases.CAN) || videoInterview;

    const candidatePhase: boolean = this.candidate.jobs[ this.jobSelected ].transitions !== 'INS' && !(this.candidate.jobs[ this.jobSelected ].transitions === 'PHO' && (this.candidate.jobs[ this.jobSelected ].phases && !this.candidate.jobs[ this.jobSelected ].phases.CAN));

    return jobPhase && candidatePhase && this.userService.hasClientEvaluatorPermissions();
  }

  emitTableUpdate(event) {
    this.actionCandidate.emit({ candidate: this.candidate, event });
  }

  updateCandidateTags() {
    this.emitTableUpdate(Candidate.ACTIONS.TAGS);
    this._setCandidateTimeline(this.candidate.id)
  }

  toggleShowPhonesList() {
    this.showPhones = !this.showPhones;
  }

}
