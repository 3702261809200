import { NgModule } from '@angular/core';

// Pipes
import { CapitalizePipe } from './capitalize.pipe';
import { FormatIfIsDatePipe } from './format-if-is-date';
import { FormatTimePipe } from './format-time.pipe';
import { KeyValuePipe } from './key-value.pipe';
import { LiteralTranformPipe } from './literal-transform.pipe';
import { MomentTimeAgoPipe } from './moment-time-ago.pipe';
import { ObjectToArrayPipe } from './object-to-array.pipe';
import { ObjectToIteratorPipe } from './object-to-iterator.pipe';
import { SafehtmlPipe } from './safehtml.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { SortPipe } from './sort.pipe';
import { SplitArrayPipe } from './split-array.pipe';
import { UnslugifyPipe } from './unslugify.pipe';
import { CarriagereturnPipe } from './carriagereturn.pipe';


@NgModule({
  declarations: [
    CapitalizePipe,
    FormatIfIsDatePipe,
    FormatTimePipe,
    KeyValuePipe,
    LiteralTranformPipe,
    MomentTimeAgoPipe,
    ObjectToArrayPipe,
    ObjectToIteratorPipe,
    SafehtmlPipe,
    SlugifyPipe,
    SortPipe,
    SplitArrayPipe,
    UnslugifyPipe,
    CarriagereturnPipe,
  ],
  exports: [
    CapitalizePipe,
    FormatIfIsDatePipe,
    FormatTimePipe,
    KeyValuePipe,
    LiteralTranformPipe,
    MomentTimeAgoPipe,
    ObjectToArrayPipe,
    ObjectToIteratorPipe,
    SafehtmlPipe,
    SlugifyPipe,
    SortPipe,
    SplitArrayPipe,
    UnslugifyPipe,
    CarriagereturnPipe,
  ]
})

export class PipesModule {}
