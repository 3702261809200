import { Alert, AlertsService, ALERT_TYPES } from './../../services/alerts.service';
import { InterviewStatus } from './../../classes/interview';
import { Status } from './../../classes/phases';
import { CandidateService } from './../../services/candidate.service';
import { Component, OnInit, AfterViewInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Constants } from '../../classes/constants';
import { InterviewAnswers } from '../../classes/interview';
import { UserService } from '../../services/user-service.service';
declare const $: any;

import 'tkt-form-builder/form-render.js';
import { TranslateService } from '@ngx-translate/core';
import { FormRenderService } from '../../services/form-render.service';

@Component({
  selector: 'app-form-render',
  templateUrl: './form-render.component.html',
  styleUrls: [ './form-render.component.scss' ]
})

export class FormRenderComponent implements OnInit, OnDestroy, AfterViewInit {
  _formModel: any;
  answers: any = {};

  @Input() set formModel(form) {
    this._formModel = form;
    this.renderForm();
  }

  @Output() formModelChange: EventEmitter<any> = new EventEmitter<Object>();
  @Output() formAndStatusChange: EventEmitter<any> = new EventEmitter<InterviewAnswers>();

  _isInterview = false;
  @Input() candidate = null;
  @Input() isInterview = false;
  @Input() candidateStatus: string;

  localCandidateStatus: string;
  @Input() interviewResult: string;


  status = Status;
  interviewStatus = InterviewStatus;

  componentSubscriptions: any = {};
  filesToUpload: number;
  enabledSaveInterview: boolean;

  constructor(
    private hostElement: ElementRef,
    private candidateService: CandidateService,
    public userService: UserService,
    private translateService: TranslateService,
    private alertsService: AlertsService,
    private formRenderService: FormRenderService
  ) { }

  ngOnInit() {

    this.formRenderService.getEnabledSaveInterview().subscribe(data => this.enabledSaveInterview = data);

    if (this.isInterview) {
      this.localCandidateStatus = this.candidateStatus;
    }
    this.checkPriorityFields();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }


  ngAfterViewInit() {
    this.componentSubscriptions.translateServiceGet$ = this.translateService.get('__noFileSelected').subscribe(textTranslated => {
      this.inputFiles();
    });
  }

  checkPriorityFields() {
    $('.priority-list').children('li').each(function () {
      if ($(this).has('img').length) {
        $(this).addClass('with-img');
        $(this).prepend('<div class="priority-element-row"></div>');
        $(this).children('p').appendTo($(this).children('.priority-element-row'));
        $(this).children('span').appendTo($(this).children('.priority-element-row'));
      }
    });
  }

  filterFormKeys(form) {
    return form.filter(element => element.name[ 0 ] !== '_');
  }

  renderForm() {
    const container_form = $(this.hostElement.nativeElement.querySelector('.form'));
    const formRenderOpts = {
      formData: this._formModel,
      dataType: 'json',
      container: container_form
    };
    container_form.formRender(formRenderOpts);
    setTimeout(this.validateForm.bind(this), 500);
  }

  handlerSubmit() {
    this.formRenderService.setEnabledSaveInterview(false);
    setTimeout(this._handlerAnswer.bind(this), 500);
  }

  _handlerAnswer() {

    const errorSelector = [ 'input.error', 'input.custom_error', 'select.error', 'select.custom_error' ];
    const errors: Array<any> = this.hostElement.nativeElement.querySelectorAll(errorSelector.join(',')) || [];

    const invalidPhone = this.checkMissingPhone();

    if (errors.length === 0 && !invalidPhone) {
      //this._getCandidateFields();
      this.answers = Object.assign(this.answers, this.getAnswers());
      this.answers = Object.assign(this.answers, this.getCheckbox());
      this.answers = Object.assign(this.answers, this.getRadio());
      this.answers = Object.assign(this.answers, this.getEmptyCheckbox());
      this.answers = Object.assign(this.answers, this.getDateAnswers());
      this.answers = Object.assign(this.answers, this.getEmptyRadio());
      this.answers = Object.assign(this.answers, this.getMultiSelects());
      this.answers = Object.assign(this.answers, this.getQuillTextareas());
      this.answers = Object.assign(this.answers, this.getPriority());
      this._handlerFilesAndEmitAnswers();
    }
  }

  checkMissingPhone(): boolean {
    const requiredInputs: HTMLInputElement[] = Array.prototype.slice.call(document.querySelectorAll('input[required]'));
    const missingPhoneNumbers = requiredInputs.some(input => input.name.includes('phone_number') && !input.value);

    if (missingPhoneNumbers) {
      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      if (isIEOrEdge) {
        this.alertsService.setAlert(new Alert(ALERT_TYPES.ERROR, '__phoneRequired'));
      }
      return true;
    }
    return false;
  }

  validateForm() {
    const container_form = $(this.hostElement.nativeElement.querySelector('form'));
    container_form.validate();
  }

  getPriority() {
    const prioriyValues = $('.priority-list').toArray().reduce((past, element) => {
      const name = $(element).attr('name');
      const value = JSON.parse($(element).val());
      past[ name ] = value;
      return past;
    }, {});

    return prioriyValues;
  }

  _getCandidateFields(): any {
    this.answers.email = $(this.hostElement.nativeElement.querySelector('input[type=email]'))
      .val() || '';
    this.answers.birth_date = $(this.hostElement.nativeElement.querySelector('input[type=date]'))
      .val();
    this.answers.gender = $(this.hostElement.nativeElement.querySelector('input[name=gender]'))
      .val();
    this.answers.dni = $(this.hostElement.nativeElement.querySelector('input[name=dni]'))
      .val();
  }

  getAnswers() {
    const good_inputs = [ 'input', 'textarea', 'select' ];
    const bad_inputs = [ 'input[id*="radio"]', 'input[type="checkbox"]', 'input[type="file"]', 'input[autocomplete=off]' ];
    const answer_form = $(this.hostElement.nativeElement.querySelectorAll(good_inputs.join(',')))
      .not(bad_inputs.join(','))
      .toArray()
      .reduce((pastValue, input) => {
        pastValue[ input.name || input.id ] = $(input)
          .val();
        return pastValue;
      }, {});
    return answer_form;
  }


  getDNI() {

  }

  getRadio() {
    const selector = 'input:radio:checked';
    const answer_form = $(selector, this.hostElement.nativeElement)
      .toArray()
      .reduce((pastValue, input) => {
        pastValue[ input.name ] = $(input)
          .val();
        return pastValue;
      }, {});
    return answer_form;
  }

  getCheckbox() {
    const selector = 'input:checkbox:checked';
    const answer_form = $(selector, this.hostElement.nativeElement)
      .toArray()
      .reduce((pastValue, input) => {
        const name = input.name.replace('[]', '');
        if (!pastValue.hasOwnProperty(name)) {
          pastValue[ name ] = [];
        }
        pastValue[ name ].push($(input)
          .val());
        return pastValue;
      }, {});
    return answer_form;
  }

  getMultiSelects() {
    const selector = 'select[multiple="true"]';
    const answer_form = $(selector, this.hostElement.nativeElement)
      .toArray()
      .reduce(this._getValueFromMultiSelect, {});
    return answer_form;
  }

  _getValueFromMultiSelect(pastValue = {}, input) {
    const name = input.name.replace('[]', '');
    pastValue[ name ] = $(input)
      .val();
    return pastValue;
  }

  getEmptyCheckbox() {
    const selector = '.checkbox-group input:checkbox';
    const answer_form = $(selector, this.hostElement.nativeElement)
      .toArray()
      .reduce(this._getValueFromEmptyCheckbox.bind(this), {});
    return answer_form;
  }

  _getValueFromEmptyCheckbox(emptyChecks = {}, input) {
    const name = input.name.replace('[]', '');
    const form_answers = Object.keys(this.answers);
    if (form_answers.indexOf(name) === -1) {
      emptyChecks[ name ] = [];
      return emptyChecks;
    }
    return emptyChecks;
  }

  getEmptyRadio() {
    const selector = '.radio-group input:radio';
    const answer_form = $(selector, this.hostElement.nativeElement)
      .toArray()
      .reduce(this._getValueFromEmptyRadio.bind(this), {});
    return answer_form;
  }

  _getValueFromEmptyRadio(emptyChecks = {}, input) {
    const name = input.name;
    const form_answers = Object.keys(this.answers);
    if (form_answers.indexOf(name) === -1) {
      emptyChecks[ name ] = [];
      return emptyChecks;
    }
    return emptyChecks;
  }

  getDateAnswers() {
    const selector = 'input[type=date]';
    const answer_form = $(selector, this.hostElement.nativeElement)
      .toArray()
      .reduce(this._getValueFromDateArray, {});
    return answer_form;
  }

  _getValueFromDateArray(pastValue, input) {
    pastValue[ input.name ] = new Date($(input)
      .val());
    return pastValue;
  }

  getQuillTextareas() {
    const selector = 'div[type="quill"]';
    const answer_form = $(selector, this.hostElement.nativeElement)
      .toArray()
      .reduce(this._getValueFromQuillTextarea, {});
    return answer_form;
  }

  _getValueFromQuillTextarea(pastValue, htmlNode) {
    pastValue[ htmlNode.id ] = htmlNode.firstElementChild.innerHTML;
    return pastValue;
  }

  async _handlerFilesAndEmitAnswers() {
    const files = this.hostElement.nativeElement.querySelectorAll('input[type=file]');
    this.filesToUpload = files.length;
    if (files.length > 0) {
      for (const file of files) {
        const uploaded = await this._uploadFile(file.files, file);
      }
    } else {
      this.checkAndEmitAnswers(this.answers);
    }
  }

  async _uploadFile(file: any, answer) {
    if (file.length > 0) {
      const extension = file[ 0 ].name.substring(file[ 0 ].name.lastIndexOf('.') + 1);

      const formData = new FormData();
      formData.append('file', file[ 0 ]);

      if (extension.match(/^(doc|docx|pdf)$/)) {
        return this.componentSubscriptions.candidateServiceSubmitFile$ = this.candidateService.submitFile(formData)
          .subscribe(
            response => {
              this.answers[ answer.id ] = `${Constants.FILE_URL}${response.uuid}`;
              this.filesToUpload--;
              if (this.filesToUpload === 0) {
                this.checkAndEmitAnswers(this.answers);
              }
              return true;
            },
            error => false
          );
      }
    } else {
      const fileValue = answer.getAttribute('value') !== '' ? answer.getAttribute('value') : null;
      this.answers[ answer.id ] = fileValue;
      this.checkAndEmitAnswers(this.answers);
      return true;
    }
  }

  checkAndEmitAnswers(answers) {
    if (this.isInterview) {
      answers.__interview_result = this.interviewResult;
      const answersAndStatus: InterviewAnswers = { answers, status: this.getNewStatus() };
      this.emitFormModelAndStatus(answersAndStatus);
    } else {
      this.emitFormModel(answers);
    }
  }

  emitFormModel(answers: Object) {
    this.formModelChange.emit(answers);
  }

  emitFormModelAndStatus(answersAndStatus: InterviewAnswers) {
    this.formAndStatusChange.emit(answersAndStatus);
  }

  getNewStatus(): string {
    return this.localCandidateStatus !== this.candidateStatus ? this.localCandidateStatus : null;
  }

  inputFiles() {

    $('input:file').each(this.addInputFileStyles.bind(this));

  }

  addInputFileStyles(index, el) {
    const $inputFile = $(el);

    this.inputAcceptedFiles($inputFile);

    this.addInputFileLabel($inputFile);

    const labelRef = $inputFile.siblings('label.input-info');

    labelRef.children('.file-upload').click(function () {
      $inputFile.click();
    });

    $inputFile.change(this.handleInputFileChange.bind(this));

  }

  inputAcceptedFiles($inputFile) {
    $inputFile.attr('accept', '.pdf, .doc, .docx');
  }

  addInputFileLabel($inputFile) {
    if ($inputFile.siblings('label.input-info').length === 0) {
      $inputFile.after(`<label class="input-info"></label>`);
    }

    const labelRef = $inputFile.siblings('label.input-info');

    if (labelRef.text()) {
      const labelText = labelRef[ 0 ].innerText;
      labelRef.text('');
      labelRef.siblings('.fb-file-label').after(`<span class='file-settings'>${labelText}</span>`);
    }

    const noFileTranslate = this.translateService.instant('__noFileSelected');
    const uploadFileTranslate = this.translateService.instant('__uploadFile');

    $(labelRef).append(`<span class='file-upload'>${uploadFileTranslate}</span>`);
    $(`<span class='file-name'>${noFileTranslate}</span>`).prependTo(labelRef);

  }

  handleInputFileChange(event) {
    let fileName = '';
    const $inputFile = $(event.target);

    if ($inputFile[ 0 ].files) {
      if ($inputFile[ 0 ].files.length > 1) {
        const selectedFilesTranslate = this.translateService.instant('__selectedFiles');
        fileName = `${$inputFile[ 0 ].files.length} ${selectedFilesTranslate}`;
      } else {
        fileName = $inputFile.val().split('\\').pop();
      }
    }
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    if (fileName && extension.match(/^(doc|docx|pdf)$/)) {
      const labelRef = $inputFile.siblings('label.input-info');
      labelRef.children('.file-name').text(fileName);
    } else {
      this.alertsService.setAlert({ type: 'error', message: this.translateService.instant('__invalidCVType') });
      const labelRef = $inputFile.siblings('label.input-info');
      labelRef.children('.file-name').text(this.translateService.instant('__noFileSelected'));
    }
  }

}
