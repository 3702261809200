import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-interpretative',
  templateUrl: './interpretative.component.html',
  styleUrls: ['./interpretative.component.scss']
})
export class InterpretativeComponent implements OnInit {

  @Input() instrument;
  constructor(
  ) { }

  ngOnInit() {
  }
  

}
