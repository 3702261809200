import { NgForm } from '@angular/forms';
import { FormElement, FORM_ELEMENT_SUBTYPES, FORM_ELEMENT_TYPES } from '../../classes/custom-forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DummyFormInfojobs, DummyFormLinkedin } from './json-form-response.js';

@Component({
  selector: 'app-custom-form-renderer',
  templateUrl: './custom-form-renderer.component.html',
  styleUrls: ['./custom-form-renderer.component.scss']
})
export class CustomFormRendererComponent implements OnInit {

  @Input() finalWarning: string;
  @Input() readonly: boolean;

  @Input() form: FormElement[] = [];

  @Output() refreshForm: EventEmitter<{ form: FormElement[], updatedElement: FormElement }> = new EventEmitter();
  @Output() cancelForm: EventEmitter<boolean> = new EventEmitter();
  @Output() saveForm: EventEmitter<FormElement[]> = new EventEmitter();

  // dummyForm: FormElement[] = DummyFormInfojobs;
  dummyForm: FormElement[] = DummyFormLinkedin;

  elementTypes = FORM_ELEMENT_TYPES;
  elementSubTypes = FORM_ELEMENT_SUBTYPES;

  constructor() { }

  ngOnInit() {
  }

  updateSelect(select: FormElement) {
    this.setSelectOptionsSelected(select);

    if (select.refresh_on_change) {
      this.emitRefreshForm(select);
    }
  }

  setSelectOptionsSelected(select: FormElement) {
    select.values = select.values.map(value => {
      value.selected = select.value === value.value;
      return value;
    });
  }

  validateForm(form: NgForm) {
    if (form.valid && this.numberFieldsAreValid()) {
      this.emitSaveForm();
    }
  }

  numberFieldsAreValid(): boolean {
    return this.form
      .filter(element => element.type === FORM_ELEMENT_TYPES.NUMBER)
      .every(element => {
        const validMin = element.min || element.min === 0 ? element.value >= element.min : true;
        const validMax = element.max || element.max === 0 ? element.value <= element.max : true;
        return validMin && validMax;
      });
  }

  /**
   * EMITERS
   */

  emitRefreshForm(updatedElement: FormElement) {
    this.refreshForm.emit({ form: this.form, updatedElement });
  }

  emitCancelForm() {
    this.cancelForm.emit(true);
  }

  emitSaveForm() {
    this.saveForm.emit(this.form);
  }

}
