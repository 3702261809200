import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string = ''  , args?: any): any {
    value = value || ' '
    return value[0].toLocaleUpperCase() + value.slice(1);
  }

}
