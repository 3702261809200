import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Client } from "../../partners/models/client";
import { ClientService } from "../../partners/services/client.service";
import { Constants } from "../../classes/constants";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {

    client: Client;
    jobId: number;
    phase: string;
    candidateUuid: string;
    url: SafeResourceUrl;

    componentSubscriptions: any = {};

    constructor(
        private activatedRoute: ActivatedRoute,
        private clientService: ClientService,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit() {

        let clientUuid = this.activatedRoute.snapshot.params.client;

        this.jobId = this.activatedRoute.snapshot.params.job;
        this.phase = this.activatedRoute.snapshot.params.phase;
        this.candidateUuid = this.activatedRoute.snapshot.params.candidate;

        this.getPublicClient(clientUuid);
        this.buildFeedbackUrl(clientUuid);
    }

    ngOnDestroy() {
        Object.keys(this.componentSubscriptions).forEach(
            subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
        );
    }


    getPublicClient(clientUuid: string) {
        this.componentSubscriptions.clientServiceGetPublicClient$ = this.clientService.getPublicClient(clientUuid).subscribe(
            result => this.client = result
        )
    }

    buildFeedbackUrl(clientUuid: string) {

        const l1 = 'aplygo-' + environment.feedbackClient;
        const l2 = clientUuid;
        const l3 = this.jobId;
        const l4 = this.phase;

        const u = this.candidateUuid;

        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(Constants.FEEDBACK_APP
            + '?l1=' + l1 + '&l2=' + l2 + '&l3=' + l3 + '&l4=' + l4 + '&u=' + u);
    }
}
