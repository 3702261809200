import { InterviewGuides } from './../../classes/interview';
import { Phase, INTERVIEW_PHASES, BUSSINESS, HIRED, PhaseConfig } from './../../classes/phases';
import { Component, OnInit, Input } from '@angular/core';
import { INSTRUMENT_TYPES } from '../../instruments/constants';

@Component({
  selector: 'app-candidate-interview',
  templateUrl: './candidate-interview.component.html',
  styleUrls: ['./candidate-interview.component.scss']
})
export class CandidateInterviewComponent implements OnInit {

  candidateInterviews: InterviewGuides = new InterviewGuides();
  candidateInstruments : InterviewGuides = new InterviewGuides();
  _phaseConfig: PhaseConfig = new PhaseConfig();
  instrumentTypes = INSTRUMENT_TYPES;

  @Input() currentPhase: string = BUSSINESS.code;
  @Input() statusInterview: any;
  @Input() set interviewGuides (interviewGuides: InterviewGuides) {
    if (interviewGuides) {
      let onlyInterviews = new InterviewGuides();
      let onlyInstruments = new InterviewGuides();

      Object.keys(interviewGuides).map((phase) => {
        return onlyInterviews[phase] = interviewGuides[phase].interviews
      })

      Object.keys(interviewGuides).map((phase) => {
        return onlyInstruments[phase] = interviewGuides[phase].results
      })

      this.candidateInterviews = (onlyInterviews || new InterviewGuides());
      this.candidateInstruments = (onlyInstruments || new InterviewGuides());
    }
  }
  @Input() set phaseConfig (phaseConfig: PhaseConfig) {
      this._phaseConfig = phaseConfig || new PhaseConfig();
  }

  constructor() { }

  ngOnInit() {
  }

  getCandidateInterviewPhases(candidatePhase): Phase[] {
    const showInterviewsPhases = INTERVIEW_PHASES.concat(HIRED);
    const phaseLimit = showInterviewsPhases.findIndex(phase => phase.code === this.currentPhase) + 1;
    const emptyPhasesFiltered = showInterviewsPhases
      .slice(0, phaseLimit)
      .filter(phase =>
        candidatePhase[phase.code] &&
        candidatePhase[phase.code].length > 0 &&
        this._phaseConfig[phase.code] &&
        this._phaseConfig[phase.code].is_active
      );
    return emptyPhasesFiltered;
  }

  getInstrumentType(instrument: InterviewGuides) {
    if (instrument){return Object.values(instrument)[0].type;}
  }

}
