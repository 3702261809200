import { TranslateService } from '@ngx-translate/core';
import { ClientAnalyticsService } from './../../../services/client-analytics.service';
import { Subscription } from 'rxjs';
import { Filter } from './../../../library/classes/filter';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { COLOR_VARIABLES } from './../../../utils/color-variables';

@Component({
  selector: 'app-partner-analytics-conversion',
  templateUrl: './partner-analytics-conversion.component.html',
  styleUrls: ['./partner-analytics-conversion.component.scss']
})
export class PartnerAnalyticsConversionComponent implements OnInit, OnDestroy {

  _parameters: Filter[];

  @Input() set parameters(parameters: Filter[]) {
    this._parameters = parameters;
    this.getConversionAnalytics();
  }

  get parameters(): Filter[] {
    return this._parameters;
  }

  loading = false;

  totalHiredCandidates = 0;

  candidatesConversionRate: {
    categories: string[],
    labels: string[],
    data: any,
    lineData?: any,
    percentage?: any,
    hide?: boolean
  } = {
      labels: [],
      categories: [],
      data: [],
      lineData: [],
      percentage: 0,
      hide: false
    };

  lineChartConfig = {
    chart: {
      height: 150,
      width: null
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      min: -3
    },
    plotOptions: {
      line: {
        color: COLOR_VARIABLES['$_TOOLBAR_COLOR'],
        lineWidth: 1,
        dataLabels: {
          enabled: true,
          color: COLOR_VARIABLES['$_AUX_GREY_15'],
          style: {
            fontWeight: 'normal',
            textOutline: false
          }
        },
      }
    }
  };

  columnsChartConfig = {
    chart: {
      height: 250,
      width: null
    },
    yAxis: {
      visible: true,
      labels: {
        enabled: false
      },
      title: {
        enabled: false
      }
    },
    plotOptions: {
      series: {
        maxPointWidth: 15
      }
    }
  };


  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private clientAnalyticsService: ClientAnalyticsService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getConversionAnalytics() {
    setTimeout(() => this.loading = true, 0);

    this.componentSubscriptions.conversionRate$ = this.clientAnalyticsService.getGroupAnalyticsConversionRate(...this.parameters).subscribe(
      data => setTimeout(() => this.serializeConversionData(data), 300),
      error => this.loading = false
    );
  }

  serializeConversionData(data: any) {
    const passTranslation = this.translateService.instant('__pass');
    const koTranslation = this.translateService.instant('__ko');
    const pendingTranslation = this.translateService.instant('__pending');

    this.candidatesConversionRate = { labels: [], categories: [], data: [], lineData: [], percentage: 0, hide: false };
    this.candidatesConversionRate.categories = Object.keys(data).map(element => this.translateService.instant(`__${element}`));
    this.candidatesConversionRate.labels = [koTranslation, pendingTranslation, passTranslation];

    for (const item of Object.values(data)) {
      const statuses = {};
      statuses[passTranslation] = item['statuses']['PASS'];
      statuses[koTranslation] = item['statuses']['KO'];
      statuses[pendingTranslation] = item['statuses']['PND'];

      this.candidatesConversionRate.data.push(statuses);
      this.candidatesConversionRate.lineData.push(item['total']);
    }

    this.totalHiredCandidates = data.HIR ? data.HIR.total : 0;

    this.candidatesConversionRate.percentage = (this.totalHiredCandidates * 100) / this.candidatesConversionRate.lineData[0];
    this.candidatesConversionRate.hide = Object.values(data).every(element => element['total'] === 0);

    setTimeout(() => this.loading = false, 0);
  }

}
