import { IRestModel } from './../library/models/rest-model';
import { WsgieditorEmailsComponent } from '../comps/wsgieditor-emails/wsgieditor-emails.component';
import { Landing } from './landing';
import { Interview, InterviewGuides } from './interview';
import { Form } from './form';
import { PhaseConfig } from './phases';
import { EmailState } from './wsgi-email';
import { Instrument, InstrumentCreationData } from '../instruments/models/instrument';
import { ReportConfig } from './reportConfig';

export class Profession implements IRestModel {

  static UPDATE_ACTION = 'update';
  static CLONE_ACTION = 'clone';

  public id: number;
  public name: string;
  public description: string;
  public landing: number | Landing;
  public form: any;
  public emails: EmailState;
  public group: number;
  public instruments: any[];
  public instruments_data: Instrument[];
  public interview_guides: InterviewGuides;
  public phase_config: PhaseConfig = new PhaseConfig();
  public emails_notifications: { 'email': string };
  public reports_config: ReportConfig[];

  constructor(
    name: string,
    description: string,
    group: number,
    landing: number,
    form: any,
    emails: EmailState,
    instruments: any[],
    interview_guides: InterviewGuides,
    emails_notifications: string
  ) {
    this.name = name;
    this.description = description;
    this.group = group;
    this.landing = landing;
    this.form = form;
    this.emails = emails;
    this.instruments = instruments;
    this.interview_guides = interview_guides;
    this.emails_notifications = { 'email': emails_notifications };
  }

  static getDefaultProfession(): Profession {
    return new Profession('', '', 0, 0, [], new EmailState(), [], new InterviewGuides(), '');
  }

  setInstrumentsData(instruments: { instrument_id: number, config: Instrument } []): InstrumentCreationData[] {
    this.instruments = instruments.map(instrument => InstrumentCreationData.buildFromInstrumentsData(instrument));
    return this.instruments;
  }

}

