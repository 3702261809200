import { Tag } from '../classes/tag';
import { Filter } from '../library/classes/filter';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buildFilter } from '../library/utils';
import { Constants } from '../classes/constants';
import { Observable } from 'rxjs';

@Injectable()
export class TagsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getTags(uuid: string, ...filter: Filter[]): Observable<HttpResponse<Tag[]>> {
    const query = buildFilter(...filter);
    const url = `${Constants.TAGS_URL.replace('{uuid}', uuid)}${query}`;
    return this.httpClient.get<Tag[]>(url, { observe: 'response' });
  }

  createTag(uuid: string, tag: Tag): Observable<Tag> {
    const url = `${Constants.TAGS_URL.replace('{uuid}', uuid)}`;
    return this.httpClient.post<Tag>(url, { name: tag.name, color: tag.color });
  }

  updateTag(uuid: string, tag: Tag): Observable<Tag> {
    const url = `${Constants.TAGS_URL.replace('{uuid}', uuid)}${tag.id}/`;
    return this.httpClient.put<Tag>(url, { name: tag.name, color: tag.color });
  }

  deleteTag(uuid: string, tag: Tag): Observable<Tag> {
    const url = `${Constants.TAGS_URL.replace('{uuid}', uuid)}${tag.id}/`;
    return this.httpClient.delete<Tag>(url);
  }

  assignTagsToCandidates(groupUuid: string, candidates_ids: number[], tags_ids: number[]): Observable<null> {
    const url = `${Constants.ASSIGN_TAGS_URL.replace('{uuid}', groupUuid)}`;
    return this.httpClient.post<null>(url, { candidates_ids, tags_ids });
  }

  removeTagsFromCandidates(groupUuid: string, candidates_ids: number[], tags_ids: number[]): Observable<null> {
    const url = `${Constants.UNASSIGN_TAGS_URL.replace('{uuid}', groupUuid)}`;
    return this.httpClient.request<null>('delete', url, { body: { candidates_ids, tags_ids } });
  }
}
