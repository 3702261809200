import { Filter } from './../library/classes/filter';
import { Landing } from './../classes/landing';
import { buildFilter } from '../library/utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../classes/constants';
import { UserService } from './user-service.service';
import { Observable } from 'rxjs';


@Injectable()
export class LandingBuilderService {

  constructor(private httpClient: HttpClient, private userService: UserService) {}

  createLandingFromTemplate(landing: Landing): Promise < any > {
    return this.httpClient.post(Constants.LANDING_BUILDER_CRUD_URL, {
        name: landing.name,
        template_pk: landing.id,
        group: landing.group
      })
      .toPromise();
  }

  getLandingList(...parameters: Filter[]): Observable < Landing[] > {
    const filter = buildFilter(...parameters);
    const url = `${Constants.LANDING_BUILDER_CRUD_URL}${filter}`;
    return this.httpClient.get < Landing[] > (url);
  }


}
