import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToIterator'
})
export class ObjectToIteratorPipe implements PipeTransform {

  transform(value: any, args?: any): [string, any][] {
    return Object.entries(value);
  }

}
