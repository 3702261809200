import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../classes/user';
import { JobCategoryTemplate, JobCategory } from '../../classes/job';
import { ActivatedRoute } from '@angular/router';
import { Role, RolesDesc } from '../../classes/role';
import { UserDataService } from '../../services/user-data-service.service';
import { AlertsService, Alert, ALERT_TYPES } from '../../services/alerts.service';
import { Observable } from '../../../../node_modules/rxjs';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {

  @Input() editUserId: number = null;

  @Output() hideModal: EventEmitter<any> = new EventEmitter();

  user: User = new User();
  userCategories: JobCategory[];

  jobCategoriesTemplate: JobCategoryTemplate[];
  client: Client = new Client();
  roles: Role = new Role();
  rolesList: { name: string, id: number }[] = this.getClientRoles();
  rolesDesc: RolesDesc = new RolesDesc();
  roleWithCategories = true;
  selectedCategories: {
    organization: number[],
    function: number[],
    location: number[]
  } = {
      organization: [],
      function: [],
      location: []
    };

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private userDataService: UserDataService,
    private alertService: AlertsService,
  ) { }

  ngOnInit() {
    this.getClientId();
    this.roleWithCategories = this.checkRoleCategories(this.user.role);
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getClientId() {
    const client_id = this.activatedRoute.parent.snapshot.paramMap.get('client');
    this.client.id = +client_id;
    this.getJobCategories();
  }

  getJobCategories() {
    this.clientService.getJobCategories(this.client.id)
      .toPromise()
      .then(
        response => {

          // Professions must not be here.
          this.jobCategoriesTemplate = this.formatCategories(response).filter(a => a.category != "profession");

          this.client.categories = response;

          if (this.editUserId) {
            this.getEditUser();
          }
        },
        error => console.log(error)
      );
  }

  formatCategories(jobCategories: JobCategory[]): JobCategoryTemplate[] {
    const parsedCategories = JobCategory.jobCategoryToTemplate(jobCategories);
    const parsedCategoriesWithoutAll = JobCategory.removeCategoriesAll(parsedCategories);
    return parsedCategoriesWithoutAll;
  }

  getEditUser() {
    this.componentSubscriptions.userDataServiceGetUserDetail$ = this.userDataService.getUserDetail(this.editUserId.toString())
      .subscribe(
        user => this.setUserData(user),
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
      );
  }

  setUserData(user: User) {
    this.user = user;
    this.roleWithCategories = this.checkRoleCategories(user.role);
    if (this.roleWithCategories) {
      this.setSelectedJobCategories(user.categories_data);
    }
  }

  setSelectedJobCategories(userCategories: JobCategory[]) {
    this.selectedCategories.organization = this.getCategoriesFromType(userCategories, 'ORG');
    this.selectedCategories.function = this.getCategoriesFromType(userCategories, 'FUN');
    this.selectedCategories.location = this.getCategoriesFromType(userCategories, 'LOC');
  }

  getCategoriesFromType(categories: JobCategory[], type: string) {
    return categories.filter(category => category.category === type)
      .map(category => category.id);
  }

  getClientRoles(): { name: string, id: number }[] {
    return Object.keys(Role)
      .filter(role => role.indexOf("CLIENT") > -1)
      .map(role => {
        return {
          name: role.toLowerCase()
            .split("_")
            .join(" "),
          id: Role[role]
        }
      })
  }

  createUser() {
    this.user.categories = this.getCategoriesValues();
    this.componentSubscriptions.userDataServiceCreateUser$ = this.userDataService.createUser(this.user)
      .pipe(catchError(error => this.handleCreateUserError(error, 'user')))
      .pipe(switchMap(user => this.addUserGroup(user)
        .pipe(catchError(error => this.handleCreateUserError(error, 'group')))))
      .subscribe(
        next => next,
        error => error,
        () => {
          this.closeModal();
          this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__userCreatedOK'));
        }
      );
  }

  editUser() {
    this.user.categories = this.getCategoriesValues();
    this.componentSubscriptions.userDataServicePatialUpdateUser$ = this.userDataService.partialUpdateUser(this.user)
      .subscribe(
        user => {
          this.closeModal();
          this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__userEditedOK'));
        },
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
      );
  }

  getCategoriesValues(): number[] {
    return [].concat(...Object.values(this.selectedCategories))
  }

  addUserGroup(user: User): Observable<any> {
    this.user = user;
    let users = { 'users': [user.id] }
    return this.userDataService.addUserGroup(users, this.client.id)
  }

  handleCreateUserError(error, type) {
    let errorMessage = "";
    switch (type) {
      case "user":
        errorMessage = "__errorCreatingUser";
        break;
      case "group":
        errorMessage = "__errorAddingUser";
        break;
    }
    this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, errorMessage))
    return error
  }

  toggleAllCategories(jobCategory) {
    this.selectedCategories[jobCategory.category] = this.selectedCategories[jobCategory.category].filter(cat => cat !== undefined);
    if (this.selectedCategories[jobCategory.category].length === jobCategory.values.length) {
      this.reset(jobCategory);
    } else {
      this.selectAll(jobCategory);
    }
  }

  selectAll(jobCategory) {
    this.selectedCategories[jobCategory.category] = jobCategory.values.map(item => item.id);
  }

  reset(jobCategory) {
    this.selectedCategories[jobCategory.category] = [];
  }

  roleChange(role) {
    this.user.role = role;
    this.roleWithCategories = this.checkRoleCategories(role)
  }

  checkRoleCategories(role) {
    return role === Role.CLIENT_RECRUITER || role === Role.CLIENT_HIRING_MANAGER;
  }

  closeModal() {
    this.hideModal.emit(this.user.id);
  }

  isUserDataCompleted(): boolean {
    const categoriesCompleted = this.roleWithCategories ? Object.values(this.selectedCategories)
      .every(value => value.length > 0) : true;
    return [this.user.first_name, this.user.last_name, this.user.email, this.user.role, categoriesCompleted].every(cond => Boolean(cond));
  }

}
