import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user-service.service';

@Injectable()
export class VerifyCodeGuardGuard implements CanActivate {

  constructor(public auth: UserService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable < boolean > | Promise < boolean > {
    return this.checkEmailExists();
  }

  checkEmailExists(state = ''): boolean {
    if (!this.auth.getUser() || !this.auth.getUser().email) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
