import { Filter } from '../../../library/classes/filter';
import { EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InstrumentService } from '../../../instruments/instrument.service';
import { Form, FormTemplate } from '../../../classes/form';
import { Component, OnInit, Input, Output } from '@angular/core';
import { AlertsService } from '../../../services/alerts.service';
import { Subject, Observable, combineLatest } from 'rxjs';
import { setFilter } from '../../../library/utils';
import { FormService } from '../../../instruments/form.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-instruments-forms',
  templateUrl: './instruments-forms.component.html',
  styleUrls: ['./instruments-forms.component.scss']
})

export class InstrumentsFormsComponent implements OnInit, OnDestroy {
  @Input() formTemplates: FormTemplate[] = [];


  @Input() update = false;

  @Input() set selectedFormTemplates(selectedTemplates: FormTemplate[]) {
    this.selectedTemplatesIds = selectedTemplates.map(form => form.id);
    this.initSelectedTemplates();
  }

  @Output() selectedFormsChange: EventEmitter < FormTemplate[] > = new EventEmitter();


  displayFormBuilder = false;

  searchTerm = new Subject < string > ();
  parameters: Filter[] = [];
  selectedTemplatesIds: number[] = [];


  newForm: FormTemplate = {
    name: null,
    description: '',
    group: null,
    content: []
  };

  formOptions = Form.INTERVIEW_OPTIONS;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private instrumentService: InstrumentService,
    private alertsService: AlertsService,
    private formService: FormService
  ) {}

  ngOnInit() {
    this.newForm.group = this.activatedRoute.snapshot.params.client;
    this.parameters = setFilter(this.parameters, new Filter('group', this.newForm.group));
    this.doSearch();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  doSearch(): void {
    this.componentSubscriptions.searchTermPipe$ = this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(search => {
          this.parameters = setFilter(this.parameters, new Filter('name', search));
          return this.formService.list(...this.parameters);
        })
      )
      .subscribe(
        templates => this.formTemplates = this.setSelectedTemplates(templates),
      );
  }

  cloneTemplate(template: FormTemplate) {
    this.newForm.content = template.content;
    this.componentSubscriptions.formServiceRetrieve$ = this.formService.retrieve(template.id)
      .subscribe(
        formTemplate => {
          this.newForm.content = formTemplate.content;
          this.openFormBuilder();
        },
        error => this.alertsService.setAlert(null, error)
      );

  }

  openFormBuilder() {
    this.displayFormBuilder = true;
  }

  closeFormBuilder() {
    this.displayFormBuilder = false;
    this.resetForm();
  }

  saveForm() {
    this.componentSubscriptions.formServiceCreate$ = this.formService.create(this.newForm)
      .subscribe(
        formTemplate => {
          formTemplate.selected = true;
          this.formTemplates.push(formTemplate);
          this.emitSelectedTemplates();
          this.closeFormBuilder();
        },
        error => this.alertsService.setAlert(null, error)
      );
  }

  resetForm() {
    this.newForm = {
      name: null,
      description: '',
      group: this.activatedRoute.snapshot.params.client,
      content: []
    };
  }

  selectTemplate(template: FormTemplate) {
    template.selected = !template.selected;
    this.emitSelectedTemplates();
  }

  initSelectedTemplates() {
    if (!this._isSameSelectionOfTemplates(this.selectedTemplatesIds)) {
      this.formTemplates = this.setSelectedTemplates(this.formTemplates);
      this.emitSelectedTemplates();
    }
  }

  setSelectedTemplates(formTemplates: FormTemplate[]): FormTemplate[] {
    return formTemplates.map(formTemplate => {
      formTemplate.selected = this.selectedTemplatesIds.indexOf(formTemplate.id) > -1;
      return formTemplate;
    });
  }

  _isSameSelectionOfTemplates(newSelectedTemplateIds): boolean {
    const currentSelectedTemplateIds = this.formTemplates
      .filter(template => template.selected)
      .map(template => template.id);

    const currentIds = currentSelectedTemplateIds.sort()
      .join(',');
    const newIds = newSelectedTemplateIds.sort()
      .join(',');

    return currentIds === newIds;
  }

  isNewFormIncomplete(): boolean {
    return !this.newForm.name || !this.newForm.description || this.newForm.content.length === 0;
  }

  emitSelectedTemplates() {
    const selectedTemplates = this.formTemplates.filter(template => template.selected);
    if (selectedTemplates.length === 0) {
      this.selectedFormsChange.emit(selectedTemplates);
    } else {
      this.componentSubscriptions.getTemplateFormsContent$ = this.getTemplatesFormsContent(selectedTemplates)
        .subscribe(
          completeTemplates => this.selectedFormsChange.emit(completeTemplates)
        );
    }
  }

  getTemplatesFormsContent(formTemplates: FormTemplate[]) {
    return combineLatest(...this.getTemplatesFormsContentObservables(formTemplates));
  }

  getTemplatesFormsContentObservables(formTemplates: FormTemplate[]): Observable < FormTemplate > [] {
    return formTemplates.map(template => this.formService.retrieve(template.id));
  }

}
