export enum FORM_ELEMENT_TYPES {
  HEADER = 'header',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  NUMBER = 'number',
  HIDDEN = 'hidden',
  CHECKBOX = 'checkbox'
}

export enum FORM_ELEMENT_SUBTYPES {
  H3 = 'h3',
  TEXT = 'text',
  TEXTAREA = 'textarea',
}

export class FormElementValues {
  image: string;
  label: string;
  value: any;
  disabled: boolean;
  selected: boolean;
}

export class FormElement {
  type: FORM_ELEMENT_TYPES;
  label: string;
  subtype: FORM_ELEMENT_SUBTYPES;
  name: string;
  placeholder: string;
  value: any;
  required: boolean;
  hidden: boolean;
  refresh_on_change?: boolean;
  minlength: number;
  maxlength: number;
  min: number;
  max: number;
  pattern: string;
  backend?: boolean;
  values: FormElementValues[];

  static serializeFormAnswers(form: FormElement[]): FormElement[] {
    return form.map(element => {
      switch (element.type) {
        case FORM_ELEMENT_TYPES.SELECT:
          const selectedOption = element.values.find(value => value.selected);
          element.value = selectedOption ? selectedOption.value : '';
          return element;

        default:
          return element;
      }
    });
  }
}
