import { LoginGuardService } from './../guards/login-guard.service';
import { JobEmptyComponent } from './../components/job-empty/job-empty.component';
import { PublicCandidateComponent } from './../components/public-candidate/public-candidate.component';
import { JobAnalyticsComponent } from './../components/job-analytics/job-analytics.component';
import { DashboardClientComponent } from './../components/dashboard-client/dashboard-client.component';
import { CandidatesTableComponent } from './../components/candidates-table/candidates-table.component';
import { JobDashboardComponent } from './../components/job-dashboard/job-dashboard.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { AuthGuardService } from '../guards/auth-guard.service';
import { LoginComponent } from '../components/login/login.component';
import { RecoverPasswordComponent } from '../components/recover-password/recover-password.component';
import { SettingClientComponent } from '../components/setting-client/setting-client.component';
import { ProfessionDetailComponent } from '../components/profession-detail/profession-detail.component';
import { UsersSettingsClientComponent } from '../components/users-settings-client/users-settings-client.component';
import { MainSettingsClientComponent } from '../components/main-settings-client/main-settings-client.component';
import { JobHeaderComponent } from '../components/job-header/job-header.component';
import { AllCandidatesTableComponent } from '../components/all-candidates-table/all-candidates-table.component';
import { NoServiceComponent } from '../components/no-service/no-service.component';
import { Role } from './role';
import { ClientHubComponent } from '../components/client-hub/client-hub.component';
import { NoResultsComponent } from '../components/no-results/no-results.component';
import { HelpComponent } from '../components/help/help.component';
import { ExtraOptions } from '@angular/router';
import { ReputationAnalyticsComponent } from '../components/reputation-analytics/reputation-analytics.component';
import { PartnerTableComponent } from '../partners/components/partner-table/partner-table.component';
import { ClientTableComponent } from '../partners/components/client-table/client-table.component';
import { CandidateInstrumentFormComponent } from '../instruments/candidate-instrument-form/candidate-instrument-form.component';
import { CuteDoneComponent } from '../instruments/cute-done/cute-done.component';
import { VerifyCodeComponent } from '../components/verify-code/verify-code.component';
import { VerifyCodeGuardGuard } from '../guards/verify-code-guard.guard';
import { DashboardCandidatesPoolComponent } from '../components/dashboard-candidates-pool/DashboardCandidatesPoolComponent';
import { AccountComponent } from '../components/account/account.component';
import { AccountProfileComponent } from '../components/account/account-profile/account-profile.component';
import { MultipostingAccountsComponent } from '../components/account/multiposting-accounts/multiposting-accounts.component';
import { UnsubscriptionLandingComponent } from '../components/unsubscription-landing/unsubscription-landing.component';
import { AllCandidatesViewerComponent } from '../components/all-candidates-viewer/all-candidates-viewer.component';
import { NewDashboardClientComponent } from '../components/new-dashboard-client/new-dashboard-client.component';
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';
import { FeedbackComponent } from '../components/feedback/feedback.component';
import { DashboardClientContainerComponent } from '../components/dashboard-client-container/dashboard-client-container.component';
import { CustomDashboardClientComponent } from '../components/custom-dashboard-client/custom-dashboard-client.component';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  useHash: true
};


export const Routes = [{
  component: DashboardComponent,
  path: '',
  canActivate: [AuthGuardService],
  canActivateChild: [AuthGuardService],
  children: [{
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'partner',
    component: PartnerTableComponent,
    data: {
      permission: Role.HQ,
    }
  },
  {
    path: 'client',
    component: ClientTableComponent,
    data: {
      permission: Role.PARTNER_MANAGER,
    }
  },
  {
    path: 'dashboard/:client',
    component: DashboardClientContainerComponent,
    data: {
      permission: Role.CLIENT_RECRUITER,
      customPermissions: [Role.CLIENT_BUSSINESS],
      menuLocation: '__dashboard'
    },
    children: [
      {
        path: 'dashboard',
        component: NewDashboardClientComponent,
        data: {
          permission: Role.CLIENT_RECRUITER,
        }
      },
      {
        path: 'custom_dashboard',
        component: CustomDashboardClientComponent,
        data: {
          permission: Role.CLIENT_RECRUITER,
        }
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  // {
  //   path: 'new-dashboard/:client',
  //   component: DashboardClientComponent,
  //   data: {
  //     permission: Role.CLIENT_ADMIN,
  //     menuLocation: '__dashboard'
  //   }
  // },
  {
    path: 'reputation-analytics/:client',
    component: ReputationAnalyticsComponent,
    data: {
      permission: Role.CLIENT_ADMIN,
      menuLocation: '__dashboard'
    },
  },
  {
    path: 'main/:client',
    component: JobDashboardComponent,
    data: {
      permission: Role.CLIENT_BUSSINESS,
      menuLocation: '__jobs'
    },
    children: [
      {
        path: 'no-job',
        component: JobEmptyComponent,
        data: {
          menuLocation: '__jobs'
        }
      },
      {
        path: 'no-results',
        component: NoResultsComponent,
        data: {
          menuLocation: '__jobs'
        }
      },

      {
        path: 'account',
        component: AccountComponent,
        data: {
          menuLocation: '__account'
        },
        children: [
          { path: '', redirectTo: 'profile', pathMatch: 'full' },
          { path: 'profile', component: AccountProfileComponent },
          { path: 'multiposting', component: MultipostingAccountsComponent }
        ]
      },
      {
        path: ':job',
        component: JobHeaderComponent,
        children: [{
          path: 'candidates/:phase',
          component: CandidatesTableComponent,
        },
        {
          path: 'candidates',
          redirectTo: 'candidates/INS'
        },
        {
          path: 'analytics',
          component: JobAnalyticsComponent,
        }
        ],
        data: {
          menuLocation: '__jobs'
        }
      }
    ]
  },
  {
    path: 'candidates-pool/:client',
    component: DashboardCandidatesPoolComponent,
    data: {
      permission: Role.CLIENT_RECRUITER,
      menuLocation: '__dashboard'
    },
    children: [{
      path: '',
      component: AllCandidatesTableComponent,
      data: {
        permission: Role.CLIENT_RECRUITER,
        menuLocation: '__canPool'
      }
    },
    {
      path: 'template/:template',
      component: AllCandidatesTableComponent,
      data: {
        permission: Role.CLIENT_RECRUITER,
        menuLocation: '__canPool'
      }
    },
    ]
  },
  {
    path: 'viewers-candidates/:client',
    component: AllCandidatesViewerComponent,
    data: {
      permission: Role.CLIENT_VIEWER,
    }
  }
  ]
},
{
  path: 'setting-client/:client',
  component: MainSettingsClientComponent,
  canActivate: [AuthGuardService],
  canActivateChild: [AuthGuardService],
  data: {
    permission: Role.CLIENT_RECRUITER
  },
  children: [{
    path: 'professions',
    component: ProfessionDetailComponent,
    data: {
      menuLocation: '__professions'
    }
  },
  {
    path: 'users',
    component: UsersSettingsClientComponent,
    data: {
      menuLocation: '__teamUsers'
    }
  },
  {
    path: 'settings',
    component: SettingClientComponent,
    data: {
      menuLocation: '__settings'
    }
  }
  ],
},
{
  path: 'login',
  component: LoginComponent,
  canActivate: [LoginGuardService],
  data: { state: 'login' }
},
{
  path: 'verify-code',
  component: VerifyCodeComponent,
  canActivate: [VerifyCodeGuardGuard],
  data: { state: 'verify-code' }
},
{
  path: 'recover',
  component: LoginComponent,
  canActivate: [LoginGuardService],
  data: { state: 'recover' }
},
{
  path: 'confirm-password/:uid/:token',
  component: RecoverPasswordComponent,
  data: { state: 'confirm-password' }
},
{
  path: 'public-candidate/:token',
  component: PublicCandidateComponent,
},
{
  path: 'job/:job/hub/:uuid',
  component: CuteDoneComponent
},
{
  path: 'no-service',
  component: NoServiceComponent
},
{
  path: 'jobs-hub/:client',
  component: ClientHubComponent,
},
{
  path: 'private-jobs-hub/:client',
  component: ClientHubComponent,
},
{
  path: ':uuid/test/:form_slug',
  component: CandidateInstrumentFormComponent
},
{
  path: 'unsubscribe/:uuid',
  component: UnsubscriptionLandingComponent
},
{
  path: 'privacy-policy/:uuid',
  component: PrivacyPolicyComponent
},
{
  path: 'feedback/:client/:job/:phase/:candidate',
  component: FeedbackComponent
},
];
