import { ClientTranslationService } from './../../services/client-translation.service';
import { WsgiEmail } from './../../classes/wsgi-email';
import { Interview, InterviewAnswers } from './../../classes/interview';
import { INTERVIEW_PHASES, Phase, INSCRIPTION, Status } from './../../classes/phases';
import { Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal/bs-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { JobService } from './../../services/job.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Candidate, CandidateCVToken } from './../../classes/candidate';
import { CandidateService } from './../../services/candidate.service';
import { UserService } from '../../services/user-service.service';
import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { Job } from '../../classes/job';
import { AlertsService, Alert, ALERT_TYPES } from '../../services/alerts.service';
declare const CKEDITOR: any;
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { Subscription, Observable, concat, Subject } from 'rxjs';
import { ClientService } from '../../partners/services/client.service';
import { Client } from '../../partners/models/client';;
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LayoutService, LayoutActions } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs/operators';
import { UserFilter } from '../../classes/user';
import { NgForm } from '@angular/forms';
import { InstrumentsService } from '../../instruments/instruments.service';
import { InstrumentReport } from '../../classes/instrumentReport';
import { copyTextToClipboard } from '../../library/utils';
import { debounceTime, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { LOAD_IMAGE_ERROR } from '../../../assets/ckeditor/load-image-error';
import { SIZE_ERROR } from '../../../assets/ckeditor/size-error';
import { calculateContentSize, getBase64FromFile, splitStringInLines } from '../../utils';
import { FormRenderService } from '../../services/form-render.service';

@Component({
  selector: 'app-dashboard-candidate',
  templateUrl: './dashboard-candidate.component.html',
  styleUrls: [ './dashboard-candidate.component.scss' ],
})
export class DashboardCandidateComponent implements OnInit, OnDestroy {

  @Output() actionCandidate = new EventEmitter<{ candidate: Candidate, event: string; }>();

  @Input() job_input: string = '0';

  ks: string;
  subscription: Subscription;
  CUTE_ENGLISH_INTRUMENT = {
    TITLE: 'scales lt-e - Specialists / Experts - technical EN',
    SCALE: 'cefr overalllevel'
  };

  loading = true;

  config = {
    extraPlugins: 'colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: [ 'Format', 'Font' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'paragraph', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList' ] },
      { name: 'links', items: [ 'Link', 'Unlink' ] },
      { name: 'variables', items: [ '__jobName', '__clientName', '__candidateName' ] },
      { name: 'images', items: [ 'Image', '__uploadImage' ] }
    ]
  };

  @ViewChild('modalTemplate', { read: TemplateRef, static: true })
  modalTemplate: TemplateRef<any>;
  @ViewChild('actionCandidateModal', { read: TemplateRef, static: false })
  actionCandidateModal: TemplateRef<any>;
  @ViewChild('sideActionCloseModal', { read: TemplateRef, static: true })
  sideActionCloseModal: TemplateRef<any>;

  @ViewChild('sendFeedbackReportToCandidate', { read: TemplateRef, static: true })
  sendFeedbackReportToCandidate: TemplateRef<any>;

  @ViewChild('tabGroup', { static: true }) tabGroup;

  client: Client = new Client();
  candidate: Candidate = new Candidate();
  saveModal = false;
  job: Job = new Job();
  emailVariables = WsgiEmail.VARS;
  uploadImageVariable = { title: '__uploadImage', icon: 'upload.png' };
  editorContent$: Subject<String> = new Subject();
  editorContentSize = 0;
  modalRef: BsModalRef;
  buttons = WsgiEmail.VARS;
  ratingEditable = false;
  feedbackReport: InstrumentReport;

  email: { subject: string, content: string; } = {
    subject: '',
    content: ''
  };
  comment: { content: string, privacy: string, highlight: boolean; } = {
    content: '',
    privacy: 'PUB',
    highlight: false
  };
  interviewSelected: Interview = new Interview();

  CANDIDATE_ACTIONS = Candidate.ACTIONS;
  candidate_url = '';

  currentAction = 'rescue';
  typeSelected = '';
  sideActionSelected = '';
  sideActionClosedRoute: { route: any[], extras: NavigationExtras; } = null;

  colors: Array<string> = [ 'orange', 'green', 'blue', 'pink', 'turquoise', 'purple' ];
  graphData: any = {};

  cvToken: CandidateCVToken;
  selectedTab: any;

  inscriptionPhase: Phase = INSCRIPTION;

  candidateData: { form: boolean, interview_guides: boolean; } = {
    form: false,
    interview_guides: false
  };

  serializedInstruments: any[];
  candidateTimeline: any[] = [];

  completedEvaluation = true;

  status = Status;
  layoutActions = LayoutActions;

  vidassessUrl: string;

  availableViewers: UserFilter[] = [];
  assignedUsers: UserFilter[] = [];

  displayUsersFilterPopup = false;

  componentSubscriptions: any = {};

  copyTextToClipboard = copyTextToClipboard;

  disableSend = true;
  loadingImages = false;
  sendingEmail = false;


  @ViewChild('inputFile', { static: true }) inputFile;
  constructor(
    public userService: UserService,
    public candidateService: CandidateService,
    private jobService: JobService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private alertService: AlertsService,
    private router: Router,
    private keyboardShortcutService: KeyboardShortcutService,
    public clientService: ClientService,
    public layoutService: LayoutService,
    private translateService: TranslateService,
    private instrumentsService: InstrumentsService,
    private renderer: Renderer2,
    private clientTranslationService: ClientTranslationService,
    private formRenderService: FormRenderService,
  ) { }

  ngOnInit() {
    this.getClient();
    this.getJobDetail();
    this.subscribeToCandidateParams();
    this.subscribeToCandidateActionClosing();
    this.subcribeToEditorContentDebounce();
    this.subcribeToEditorContent();
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  closeCandidateDetailsOrModal() {
    if (this.typeSelected !== '') {
      this.closeModal();
    } else {
      this.router.navigate([ this.router.url.split('?')[ 0 ] ]);
    }
  }

  getClient() {
    const client_id = Object.keys(this.activatedRoute.parent.parent.snapshot.params).length != 0 ? +this.activatedRoute.parent.parent.snapshot.paramMap.get('client') : +this.activatedRoute.snapshot.paramMap.get('client');

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(client_id)
      .subscribe(
        response => this.client = response,
        error => console.log(error)
      );
  }

  getJobDetail() {
    const job_id = this.job_input === '0' ? this.activatedRoute.parent.snapshot.paramMap.get('job') : this.job_input;
    this.componentSubscriptions.jobServiceGetJobDetailPipe$ = this.jobService.getJobDetail(job_id)
      .pipe(take(1))
      .subscribe(
        job => this.job = job
      );
  }

  subscribeToCandidateParams() {
    this.componentSubscriptions.candidateParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        const candidateUuid = queryParam.get('candidate');
        if (this.layoutService.isCandidateActionOpen()) {
          this.layoutService.handleLayoutServiceAction(
            LayoutActions.ATTEMPT_CANDIDATE_ACTION_CLOSE, {
            route: [],
            extras: { queryParams: { candidate: candidateUuid }, relativeTo: this.activatedRoute }
          }
          );
        } else {
          this.getCandidateDetail(candidateUuid);
        }
      }
    );
  }

  getCandidateDetail(candidateUuid: string) {
    if (candidateUuid) {
      this.loading = true;
      this.componentSubscriptions.candidateServiceGetCandidate$ = this.candidateService.getCandidate(candidateUuid)
        .subscribe(
          candidate => {
            this._setCandidate(candidate);
            this._setCandidateTimeline(candidate.candidate);
            this.loading = false;
          },
          () => this.loading = false,
        );
    }
  }

  private _setCandidate(candidate: Candidate) {
    this.candidate = candidate;
    if (this.candidate.values.form) {
      this.candidate.values.form = Candidate.filterFormData(this.candidate.values.form);
    }
    if (this.candidate.comments.length > 0) {
      this.candidate.comments = Candidate.sortCandidateComments(this.candidate.comments);
    }
    this.completedEvaluation = this.candidateHasCompletedEvaluation(candidate);
    this.checkCandidateData();
    if ((this.userService.noIsViewer())) {
      this.getViewersForCandidate();
      this.assignedViewers();
    }
  }


  private _setCandidateTimeline(candidate_id: string) {
    this.componentSubscriptions.candidateServiceGetCandidateTimeline$ = this.candidateService.getCandidateTimeline(candidate_id)
      .subscribe(
        timeline => this.candidateTimeline = timeline,
        error => console.log(error)
      );
  }

  subscribeToCandidateActionClosing() {
    this.componentSubscriptions.layoutServiceJobDashboardSubjectsCandidateActionCloseAttempt$ = this.layoutService.jobDashboardSubjects.candidateActionCloseAttempt.subscribe(
      response => {
        this.sideActionClosedRoute = response.navigateRoute;
        if (this.sideActionSelected && response.status) {
          this.showSideActionCloseModal();
        }
      }
    );
  }

  updateComponent() {
    this.getCandidateDetail(this.candidate.uuid);
    this.emitTableUpdate(Candidate.ACTIONS.MOVE);
  }

  candidateHasCompletedEvaluation(candidate: Candidate): boolean {
    return Object.keys(candidate.talent_points_explain)
      .length === Object.keys(candidate.values.instruments)
        .length;
  }

  checkCandidateData() {
    this.candidateData.form = Object.keys(this.candidate.values.form)
      .length > 0;
  }

  isInterviewPhase(candidatePhase: string): boolean {
    return INTERVIEW_PHASES.some(phase => phase.code === candidatePhase);
  }

  candidateRated(rate) {
    this.candidate.rate = rate;
    this.emitTableUpdate('rated');
    this._setCandidateTimeline(this.candidate.candidate);
  }

  emitTableUpdate(event) {
    this.actionCandidate.emit({ candidate: this.candidate, event });
  }

  handleAction(action) {
    switch (action) {
      case Candidate.ACTIONS.EMAIL:
        this.showModal(Candidate.ACTIONS.EMAIL);
        break;
      case Candidate.ACTIONS.SHARE:
        this.getUrlToShareCandidate();
        break;
      case Candidate.ACTIONS.COMMENT:
        this.writeComment();
        break;
      case Candidate.ACTIONS.TASK:
        this.updateComponent();
        break;
      case Candidate.ACTIONS.LEAVE_APP:
        this.showModal(Candidate.ACTIONS.LEAVE_APP);
    }
  }

  showSideActionCloseModal() {
    this.modalRef = this.modalService.show(this.sideActionCloseModal, { backdrop: 'static' });
  }

  showActionModal(action: string) {
    this.currentAction = action;
    this.modalRef = this.modalService.show(this.actionCandidateModal, { backdrop: 'static' });
  }



  setEmailTemplate(email_key) {
    const { subject, content } = this.job.emails.OTHERS[ email_key ];
    this.email.subject = subject;
    this.email.content = content;
    this.disableSend = this.email.content.length > 0 ? false : true;
  }

  writeEmail() {
    this.showModal(Candidate.ACTIONS.EMAIL);
  }

  async sendEmail() {
    this.sendingEmail = true;
    await this.transformImageUrlToBase64(this.email.content).then(() => {
      this.componentSubscriptions.candidateServiceSendEmail$ = this.candidateService.sendEmail(this.candidate, this.email)
        .subscribe(
          () => {
            this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__emailSentSuccesfully'));
            this._setCandidateTimeline(this.candidate.candidate);
            this.closeModal();
            this.sendingEmail = false;
            //clear email
            this.email.subject = '';
            this.email.content = '';
          },
          () => {
            this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, '__errorSendingEmail'));
            this.closeModal();
            this.sendingEmail = false;
          }
        );
    }
    );
  }

  writeComment() {
    this.showModal(Candidate.ACTIONS.COMMENT);
  }

  saveComment() {
    this.componentSubscriptions.candidateServiceAddComment$ = this.candidateService.addComment(this.candidate, this.comment)
      .subscribe(
        () => {
          this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__commentAddedSuccesfully'));
          this.closeModal();
          this.comment.content = '';
          this.comment.highlight = false;
          this._setCandidateTimeline(this.candidate.candidate);
        },
        () => {
          this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, '__errorAddingComment'));
        }
      );
  }

  doInterview(interview: Interview) {
    this.interviewSelected = Object.assign(new Interview(), interview);
    this.interviewSelected.form = this.getInterviewGuides(this.interviewSelected);
    this.sideActionSelected = Candidate.ACTIONS.INTERVIEW;
    this.layoutService.handleLayoutServiceAction(LayoutActions.OPEN_CANDIDATE_ACTION);
  }

  getInterviewResult(): string {
    if (!this.candidate.phases_interviews[ this.candidate.transitions ] || !this.candidate.phases_interviews[ this.candidate.transitions ][ this.interviewSelected.slug ]) {
      return null;
    }
    return this.candidate.phases_interviews[ this.candidate.transitions ][ this.interviewSelected.slug ];
  }

  getInterviewGuides(jobInterview: Interview): any {
    const candidateAnswers = this.candidate.values.interview_guides[ this.candidate.transitions ].interviews
      .find(interview => interview.slug === jobInterview.slug)
      .form;

    let form = [].concat(...jobInterview.form.map(field => Object.assign({}, field)));

    if (candidateAnswers.length > 0) {
      form = [].concat(form.map(question => this.setQuestionValue(question, candidateAnswers)));
    }

    return form;
  }

  setQuestionValue(question, answers: any[]): any {
    const candidateAnswer = Object.assign({}, answers.find(answer => answer.name === question.name) || {});
    return this.getQuestionAnswer(question, candidateAnswer.value || '');
  }

  getQuestionAnswer(question, answer) {
    switch (question.type) {
      case 'text':
      case 'textarea':
      case 'number':
      case 'file':
        question.value = answer;
        break;
      case 'date':
        question.value = answer.substring(0, 10);
        break;
      case 'select':
        question = question.multiple ? this._getMultipleSelectedAnswers(question, answer) : this._getSelectedAnswers(question, answer);
        break;
      case 'radio-group':
        question = this._getSelectedAnswers(question, answer);
        break;
      case 'checkbox-group':
        question = this._getMultipleSelectedAnswers(question, answer);
        break;
      case 'autocomplete':
        question = this._getAutocompletedAnswers(question, answer);
        break;
    }
    return question;
  }

  _getSelectedAnswers(question, answer) {
    let values = [ ...question.values.map(value => Object.assign({}, value)) ];
    if (answer.value) {
      values = values.map(value => {
        value.selected = (value.value === answer.value);
        return value;
      });
    }
    question.values = values;
    return question;
  }

  _getMultipleSelectedAnswers(question, answer) {
    let values = [ ...question.values.map(value => Object.assign({}, value)) ];

    if (answer) {
      values = values.map(value => {
        value.selected = Boolean(answer.find(option => option.value === value.value));
        return value;
      });
    }

    question.values = values;
    return question;
  }

  _getAutocompletedAnswers(question, answer) {
    if (answer.label) {
      question.value = answer.label;
    }
    return question;
  }

  saveInterview(answersAndStatus: InterviewAnswers) {

    this.componentSubscriptions.getSaveInterviewObservables$ = this.getSaveInterviewObservables(answersAndStatus)
      .subscribe(
        () => {
          this.alertService.setAlert(new Alert(ALERT_TYPES.SUCCESS, '__interviewSavedSuccesfully'));
          this.formRenderService.setEnabledSaveInterview(true);
        },
        () => {
          this.alertService.setAlert(new Alert(ALERT_TYPES.ERROR, '__errorSavingInterview'));
          this.formRenderService.setEnabledSaveInterview(true);
        }
      );

  }

  getSaveInterviewObservables(answersAndStatus: InterviewAnswers): Observable<any> {
    const setSaveInterviewObservables = answersAndStatus.status ?
      concat(
        this.candidateService.saveInterview(this.candidate, this.interviewSelected.slug, answersAndStatus.answers),
        this.candidateService.changeStatus(this.candidate, answersAndStatus.status)
          .pipe(tap(() => this.successfulySavedInterview()))
      ) : this.candidateService.saveInterview(this.candidate, this.interviewSelected.slug, answersAndStatus.answers)
        .pipe(tap(() => this.successfulySavedInterview()));
    return setSaveInterviewObservables;
  }

  successfulySavedInterview() {
    this.closeSideAction();
    this.getCandidateDetail(this.candidate.uuid);
    this.emitTableUpdate(Candidate.ACTIONS.INTERVIEW);
  }

  getUrlToShareCandidate() {
    this.componentSubscriptions.candidateServiceGetCandidateUrl$ = this.candidateService.getCandidateUrl(this.candidate.candidate.toString())
      .pipe(take(1))
      .subscribe(
        response => {
          this.candidate_url = this.candidateService.pathToPublicCandidate(response.token);
          this.showModal(Candidate.ACTIONS.SHARE);
        },
        error => console.log(error)
      );
  }

  showModal(type: string) {
    this.typeSelected = type;
    this.modalRef = this.modalService.show(this.modalTemplate, { backdrop: 'static' });
  }

  closeModal() {
    this.typeSelected = '';
    this.modalRef.hide();
  }

  closeSideActionWarningModal() {
    this.layoutService.handleLayoutServiceAction(LayoutActions.RESET_ATTEMPT_CANDIDATE_ACTION_CLOSE);
  }

  closeSideAction() {
    this.sideActionSelected = '';
    if (this.sideActionClosedRoute) {
      this.router.navigate(this.sideActionClosedRoute.route, this.sideActionClosedRoute.extras);
    }
    this.layoutService.handleLayoutServiceAction(LayoutActions.CLOSE_CANDIDATE_ACTION);
  }

  closeCandidateDashboard() {

    if (this.sideActionSelected) {
      this.layoutService.handleLayoutServiceAction(
        LayoutActions.ATTEMPT_CANDIDATE_ACTION_CLOSE, {
        route: [],
        extras: { queryParams: {}, relativeTo: this.activatedRoute }
      }
      );
    } else {
      this.router.navigate([], { queryParams: {}, relativeTo: this.activatedRoute });
    }

  }

  addVar(button) {
    for (const instance in CKEDITOR.instances) {
      CKEDITOR.instances[ instance ].insertText(button.value);
    }
  }

  uploadImage(event) {
    event.target.files[ 0 ].size < 1_000_000 ?
      getBase64FromFile(event.target.files[ 0 ]).then(
        base64Image => {
          // incluir img en el email
          for (const instance in CKEDITOR.instances) {
            CKEDITOR.instances[ instance ].insertHtml(`<img alt="" src="${base64Image}" />`);
          }
        }
      )
      :
      this.insertSizeError();

  }

  emitEmailContentChange($event) {
    this.editorContent$.next($event);
  }

  subcribeToEditorContentDebounce() {
    this.componentSubscriptions.editorContent$ = this.editorContent$.pipe(
      debounceTime(500)
    ).subscribe(
      editorContent => {
        this.transformImageUrlToBase64(editorContent);
      });
  }
  subcribeToEditorContent() {
    this.componentSubscriptions.editorContent$ = this.editorContent$.pipe(
      throttleTime(1000)
    ).subscribe(
      editorContent => {
        if (editorContent) {
          const contentSizeInfo = calculateContentSize(editorContent);
          this.editorContentSize = contentSizeInfo.size;
          this.disableSend = contentSizeInfo.exeedsMaxSize;
        }
      });
  }

  async transformImageUrlToBase64(editorContent) {
    //Regex buscar img con srg url
    const regex = /<img\s.+src="((?!data:)[^"]+)"/m;
    const matches = editorContent.match(regex);
    const url = matches && matches[ 1 ];
    //si url
    if (url) {
      this.loadingImages = true;
      this.componentSubscriptions.base64Image$ = this.candidateService.getImageAsBase64(url).subscribe(
        base64Image => {
          this.email.content = editorContent.replace(`src="${url}"`, `src="${splitStringInLines(base64Image.dataUrl)}"`);
          this.loadingImages = false;
          this.editorContent$.next(this.email.content);
        },
        error => {
          /* ERRORS
            { "code": 0, "message": "Unespecified error." }
            { "code": 1, "message": "The resource exceeds the maximum file size." }
            { "code": 2, "message": "The resource is not an image." }

            We only control the size one, for the other two we use a generic error
          */
          error.error.error.code === '1'
            ?
            this.insertSizeError(false, editorContent, url)
            :
            this.insertGenericError(editorContent, url);
          this.loadingImages = false;
        });
    }
  }

  insertSizeError(localImage = true, editorContent?, url?) {

    const languageKey = this.getLanguageKey();
    //For local image there is no img tag to replase, so we insert it, otherwise we just replace the src with the error
    if (localImage) {
      // tslint:disable-next-line:forin
      for (const instance in CKEDITOR.instances) {
        CKEDITOR.instances[ instance ].insertHtml(`<img alt="" src="${SIZE_ERROR[ languageKey ]}" style="height:auto; width:600px"/>`);
      }
    } else {
      this.email.content = editorContent.replace(`src="${url}"`, `src="${SIZE_ERROR[ languageKey ]}" style="height:auto; width:600px"`);
    }
  }

  insertGenericError(editorContent, url) {
    const languageKey = this.getLanguageKey();
    this.email.content = editorContent.replace(`src="${url}"`, `src="${LOAD_IMAGE_ERROR[ languageKey ]}" style="height:auto; width:600px"`);
    this.loadingImages = false;
  }

  getLanguageKey() {
    const browserLanguage = this.clientTranslationService.getNavigatorLanguage();

    switch (browserLanguage) {
      case 'de':
      case 'el':
      case 'en':
      case 'es':
      case 'fr':
      case 'it':
        return browserLanguage;

      case 'pt':
      case 'pt-br':
        return 'pt';

      default:
        return 'en';
    }
  }


  getCandidateColor(colors) {
    this.candidate.email = this.candidate.email || '  ';
    const index = this.candidate.email[ 0 ].charCodeAt(0) % colors.length;
    return colors[ index ];
  }

  getCandidateInitials() {
    this.candidate.email = this.candidate.email || '  ';
    if (this.candidate.first_name && this.candidate.last_name) {
      return `${this.candidate.first_name[ 0 ]}${this.candidate.last_name[ 0 ]}`;
    }
    return `${this.candidate.email[ 0 ]}${this.candidate.email[ 1 ]}`;
  }

  convertSlugsToText(slugs: string[]) {
    return slugs.map(slug => slug.replace(/_/g, ' '));
  }

  changeTab(tab: MatTabChangeEvent) {
    this.selectedTab = tab;
    switch (tab.tab.textLabel.toLowerCase()) {
      case 'Online Assessment'.toLowerCase():
        setTimeout(() => {
          const resizeEvent = window.document.createEvent('UIEvents');
          resizeEvent.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(resizeEvent);
        }, 300);
        break;
    }
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'DashboardCandidate.Right':
        this.goToNextTab();
        break;
      case 'DashboardCandidate.Left':
        this.goToPreviousTab();
        break;
      case 'DashboardCandidate.ShiftM':
        this.writeEmail();
        break;
      case 'DashboardCandidate.ShiftX':
        this.closeCandidateDetailsOrModal();
        break;
    }
  }

  goToNextTab() {
    if (this.tabGroup.selectedIndex < this.tabGroup._tabs.length) {
      this.tabGroup.selectedIndex++;
    }
  }

  goToPreviousTab() {
    if (this.tabGroup.selectedIndex > 0) {
      this.tabGroup.selectedIndex--;
    }
  }

  goToVidassess() {
    window.open(this.vidassessUrl, '_blank');
    this.closeModal();
  }

  getViewersForCandidate() {
    if (this.userService.noIsViewer()) {
      this.componentSubscriptions.candidateServiceGetAllCandidateViewers$ = this.candidateService.getAllCandidateViewers(this.candidate).subscribe(
        data => {
          this.availableViewers = data.map(
            (user) => {
              user.visible = true;
              user.selected = false;
              return user;
            }
          );
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  filterClientUsers(search: string) {
    this.availableViewers = this.availableViewers.map(user => {
      const userText = user.first_name + user.last_name + user.email;
      user.visible = userText.toLowerCase()
        .indexOf(search.toLowerCase()) > -1;
      return user;
    });
  }


  addSelectedUser(form: NgForm) {
    if (form.valid) {
      const newUser = new UserFilter();
      newUser.email = form.value[ 'filterUsers' ];
      newUser.selected = true;
      this.availableViewers.push(newUser);
    }
  }

  clearSelectedUsers() {
    if (this.userService.noIsViewer()) {
      this.availableViewers = this.availableViewers.map(user => {
        user.selected = false;
        user.visible = true;
        return user;
      });
    }
  }

  getSelectedUsers() {
    return this.availableViewers.filter(user => user.selected);
  }

  getNoSelectedViewers() {
    return this.assignedUsers.filter(user => !user.selected);
  }

  addViewersToCandidate() {
    if (this.userService.noIsViewer()) {
      const selectedUsers = this.getSelectedUsers().map(user => user.email);
      const unassignedViewers = this.getNoSelectedViewers().map(user => user.email);

      this.componentSubscriptions.candidateServiceAddUsersToCandidate$ = this.candidateService.addUsersToCandidate(this.candidate, selectedUsers)
        .subscribe(
          () => {
            this.getViewersForCandidate();
            this.assignedViewers();
            this.subscribeToCandidateParams();
            this.actionCandidate.emit({ candidate: this.candidate, event: "assign" });
          },
          error => this.alertService.formatErrorMessage(error)
        );

      this.candidateService.deleteUsersForCandidate(this.candidate, unassignedViewers).subscribe(
        () => {
          this.getViewersForCandidate();
          this.assignedViewers();
          this.subscribeToCandidateParams();
          this.actionCandidate.emit({ candidate: this.candidate, event: "assign" });
        },
        () => {
          const translate_message = this.translateService.instant('__errorUnassignViewer');
          this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: translate_message });
        }
      );
    }
  }

  assignedViewers() {
    this.componentSubscriptions.candidateServiceGetAssignedViewers$ = this.candidateService.getAssignedViewers(this.candidate).subscribe(
      (data) => {
        this.assignedUsers = data;
        this.assignedUsers.map((user) => {
          user.selected = true;
        });
      },
      () => {
        const translate_message = this.translateService.instant('__errorRetrieveViewers');
        this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: translate_message });
      }
    );

  }

  getVisibleUsers() {
    return this.availableViewers.filter(user => user.visible);
  }

  downloadInstrumentReport(report) {
    this.loading = true;
    const requestData = {
      instruments: report.instruments,
      candidate_id: this.candidate.candidate,
      language: report.language,
      report_id: report.report_id
    };

    this.componentSubscriptions.instrumentServiceDownloadReport$ = this.instrumentsService.downloadReport(requestData).subscribe(
      response => {
        if (response.url) {
          if (response.url.includes('.pdf')) {
            window.location.assign(response.url);
          } else {
            window.open(response.url, '_blank');
          }
          this.loading = false;
        }
        else {
          const translate_message = this.translateService.instant('__errorRetrieveReport');
          this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: translate_message });
          this.loading = false;
        }
      },
      () => {
        const translate_message = this.translateService.instant('__errorRetrieveReport');
        this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: translate_message });
        this.loading = false;
      }
    );
  }

  sendToCandidateReport(report: InstrumentReport) {
    this.feedbackReport = report;
    this.modalRef = this.modalService.show(this.sendFeedbackReportToCandidate, { backdrop: 'static' });
  }

  sendReportFeedbackToCandidate() {
    this.loading = true;
    this.closeModal();
    const requestData = {
      instruments: this.feedbackReport.instruments,
      candidate_id: this.candidate.candidate,
      report: this.feedbackReport
    };

    this.componentSubscriptions.sendFeedbackToCandidate$ = this.instrumentsService.sendReportFeedbackToCandidate(requestData).subscribe(
      () => {
        const translate_message = this.translateService.instant('__successSendFeedbackReport');
        this.alertService.setAlert({ type: ALERT_TYPES.SUCCESS, message: translate_message });
        this.loading = false;
      },
      () => {
        const translate_message = this.translateService.instant('__errorSendFeedbackReport');
        this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: translate_message });
        this.loading = false;
      }
    );
  }

  showPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'block');
  }

  dismissPopHover(tem: HTMLElement) {
    this.renderer.setStyle(tem, 'display', 'none');
  }

  conditionHubLink(): boolean {

    const videoInterview: boolean = this.job.instruments_data && this.job.instruments_data.some(instrument => instrument.phase === 'OA');

    const jobPhase: boolean = this.job.phases.CAN || videoInterview;

    const candidatePhase: boolean = this.candidate.transitions !== 'INS' && !(this.candidate.transitions === 'PHO' && !this.job.phases.CAN);

    return jobPhase && candidatePhase && this.userService.hasClientEvaluatorPermissions();
  }

  updateCandidateTags() {
    this.emitTableUpdate(Candidate.ACTIONS.TAGS);
    this._setCandidateTimeline(this.candidate.candidate);
  }

}
