
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { retryWhen, delay, mergeMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertsService } from "./services/alerts.service";
import { isArray } from 'util';
import { ElementRef } from '@angular/core';
declare const Highcharts: any;

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;


export function delayedRetry(delayMs: number, maxRetries: number = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {

  let retries = maxRetries;
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap(error => {
          if (retries-- > 0) {
            const backoffTime = delayMs + (maxRetries - retries) * backoffMs;
            return of(error)
              .pipe(delay(backoffTime));
          }
          return observableThrowError(error);
        })
      ))
    );
}

export function handleDisconnectedError(error: HttpErrorResponse, alertService: AlertsService) {
  if (error.status === 0) {
    alertService.setAlert({
      type: 'warning',
      message: '__retryRequestConnectionLostWarning'
    });
  }
}

export const customHighchartsRadius = (function (H) {
  H.wrap(H.seriesTypes.column.prototype, 'translate', function (proceed) {
    var options = this.options,
      rTopLeft = options.borderRadiusTopLeft || 0,
      rTopRight = options.borderRadiusTopRight || 0,
      rBottomRight = options.borderRadiusBottomRight || 0,
      rBottomLeft = options.borderRadiusBottomLeft || 0;

    proceed.call(this);

    if (rTopLeft || rTopRight || rBottomRight || rBottomLeft) {
      H.each(this.points, function (point) {
        var shapeArgs = point.shapeArgs,
          w = shapeArgs.width,
          h = shapeArgs.height,
          x = shapeArgs.x,
          y = shapeArgs.y;
        point.shapeType = 'path';
        point.shapeArgs = [
          'M', x + rTopLeft, y,
          // top side
          'L', x + w - rTopRight, y,
          // top right corner
          'C', x + w - rTopRight / 2, y, x + w, y + rTopRight / 2, x + w, y + rTopRight,
          // right side
          'L', x + w, y + h - rBottomRight,
          // bottom right corner
          'C', x + w, y + h - rBottomRight / 2, x + w - rBottomRight / 2, y + h, x + w - rBottomRight, y + h,
          // bottom side
          'L', x + rBottomLeft, y + h,
          // bottom left corner
          'C', x + rBottomLeft / 2, y + h, x, y + h - rBottomLeft / 2, x, y + h - rBottomLeft,
          // left side
          'L', x, y + rTopLeft,
          // top left corner
          'C', x, y + rTopLeft / 2, x + rTopLeft / 2, y, x + rTopLeft, y,
          'Z'
        ];

      });
    }
  });
}(Highcharts));

export function isNumeric(val) {
  // Copied from the RxJS documentation
  // parseFloat NaNs numeric-cast false positives (null|true|false|"")
  // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
  // subtraction forces infinities to NaN
  // adding 1 corrects loss of precision from parseFloat (#15100)
  return !isArray(val) && (val - parseFloat(val) + 1) >= 0;
}

export function injectScript(scriptText: string, elementRef: ElementRef) {

  //remove script tags
  scriptText = scriptText.replace('<script>', '');
  scriptText = scriptText.replace('</script>', '');

  //create script
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.text = scriptText;

  //inject script into element
  elementRef.nativeElement.appendChild(script);
}

export function calculateContentSize(content: String, maxSize = 25): { size: number, exeedsMaxSize: boolean; } {
  let contentSize = content.length / 1000000;
  if (contentSize < 0.1 && contentSize !== 0) {
    contentSize = 0.1;
  }
  contentSize = parseFloat(contentSize.toFixed(1));

  let exeedsMaxSize = contentSize > maxSize ? true : false;
  exeedsMaxSize = contentSize === 0 ? true : false;

  return { size: contentSize, exeedsMaxSize: exeedsMaxSize };
}


export async function getBase64FromFile(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

export function splitStringInLines(stringToSplit, lineLength = 79) {
  const regex = new RegExp(`(.{${lineLength}})`, 'g');
  const splittedString = stringToSplit.replaceAll(regex, "$1\n");
  return splittedString;
}

