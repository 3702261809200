export class Landing {

    url:  string;
    id: number;
    name: string;
    group: number;
    isTemplate = false;

    constructor(id: number= 1, name: string = 'Template', group?:number) {
        this.name = name;
        this.id = id;
        this.group = group;
    }
}
