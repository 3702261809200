import { Component, OnInit, Input } from '@angular/core';
import { InstrumentsService } from '../../../instruments/instruments.service';
import { Origin, ReportOrigin } from '../../../instruments/models/instrument';
import { Constants } from '../../../classes/constants';
import { INSTRUMENT_TYPES } from '../../../instruments/constants';

@Component({
  selector: 'app-instruments-providers',
  templateUrl: './instruments-providers.component.html',
  styleUrls: ['./instruments-providers.component.scss']
})
export class InstrumentsProvidersComponent implements OnInit {

  @Input() set testProviders(providers) {
    this._testProviders = providers.filter(provider => provider.provider === provider.slug && provider.provider !== INSTRUMENT_TYPES.VIDASSESS);
  }
  _testProviders: any[];

  @Input() reportProviders;

  @Input() viewMode;

  @Input() instruments;
  @Input() reports;
  @Input() selectedForms;

  viewTktInstruments = false;

  constructor(
    private instrumentsService: InstrumentsService
  ) { }

  ngOnInit() {
  }

  selectTestProvider(provider: Origin) {

    this.instrumentsService.selectTestProvider(this._testProviders, provider);
    this.setInstrumentsVisibility(provider.provider);
    this.viewTktInstruments = provider.id === 2;
  }

  selectReportProvider(provider: ReportOrigin) {
    this.instrumentsService.selectReportProvider(this.reportProviders, provider);
    this.viewTktInstruments = false;
  }

  setInstrumentsVisibility(provider_slug: string) {

    const selectedProvider = this._testProviders.find(provider => provider.provider === provider_slug);

    this.instruments.forEach(instrument => {
      instrument.visibility = instrument.provider_origin === selectedProvider.provider;
    });
  }

  getTestProviderSelectedInstrumentsQuantity(provider: Origin): number {

    if (provider.selected) {
      this.setInstrumentsVisibility(provider.provider);
    }
    if (provider.provider === INSTRUMENT_TYPES.FORMS) {
      return this.selectedForms.length;
    }
    return this.instrumentsService.getSelectedInstruments(this.instruments)
      .filter(instrument => instrument.provider_origin === provider.provider)
      .length;
  }

  getReportProviderSelectedInstrumentsQuantity(provider: ReportOrigin): number {
    const instruments = this.instrumentsService.getSelectedReports();
    const numberOfInstruments = instruments ? instruments.filter(report => report.visible == true && report.origin == provider.slug).length : 0;
    return numberOfInstruments
  }

}
