import { COLOR_VARIABLES } from './../../utils/color-variables';
import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
declare const Highcharts: any;


@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss']
})
export class ColumnChartComponent implements OnChanges {


  // @Input() data: any;
  _data: any = [];
  @Input() colors = [COLOR_VARIABLES['$_AUX_GREY_15'], COLOR_VARIABLES['$_CHART_ORANGE'], COLOR_VARIABLES['$_CHART_MEDIUM_BLUE']];

  @Input() data;
  @Input() labels: string[] = [];
  @Input() name: string;
  @Input() size: { width: number, height: number } = { width: 1000, height: 400 };
  @Input() categories: string[] = [];
  @Input() stacked: boolean;

  @Input() pagination = 6;
  @Input() starChartAtTheEnd = false;

  @Input() customConfig: any = {};

  @Input() feedBackTool: boolean = false;

  yVisible = true;

  config: any = {
    chart: {
      events: {
        load: function () {
          this.reflow();
        }
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>'
    },
    colors: [],
    xAxis: {
      categories: []
    },
    yAxis: {
      visible: false,
      title: {
        text: ''
      }
    },
    legend: {
      labelFormatter: function () {
        const sum = this.yData.reduce(function (total, actual) { return total + actual; }, 0);
        return this.name + ' <span style="color:' + this.color + '">' + sum + '</span>';
      }
    },
    series: [],
    plotOptions: {
      column: {
      },
      series: {
      },
      line: {
        dataLabels: {
          enabled: true
        }
      }
    },
  };

  constructor(private hostElement: ElementRef) {
  }

  ngOnChanges() {

    if (this.customConfig) {
      Object.keys(this.customConfig).forEach(configKey => {
        this.config[configKey] = Object.assign(this.config[configKey], this.customConfig[configKey]);
      });
    }

    if (this.data) {
      this._setData();

      if (this.stacked) {
        Highcharts.chart(
          this.hostElement.nativeElement.querySelector('.column-chart__content'),
          this.config,
          (chart) => paginatedChart(chart, this.pagination, this.starChartAtTheEnd)
        );
      } else {
        Highcharts.chart(this.hostElement.nativeElement.querySelector('.column-chart__content'), this.config);
      }
    }
  }

  _setData() {

    const data = this.getData();

    if (this.stacked) {
      this.config.plotOptions.column = {
        pointWidth: 20,
        stacking: 'normal'
      };
    } else {
      this.config.plotOptions.column = {
        pointWidth: 20,
      };
    }

    this.config.series = data;
    this.config.xAxis.categories = this.categories;
    if (this.feedBackTool) {
      this.config.yAxis.visible = this.yVisible;

      this.config.tooltip = {
        formatter: function () {
          let tooltipText = `<span><b>${this.x}</b></span><table><table>`;
          const contacted = this.points.length ? this.points[0].y : 0;
          this.points.forEach((element, i: number) => {
            tooltipText += `<tr><td style="padding: 0; color: ${element.series.color}"> ${element.series.name}: ${element.y}${i > 0 ? `/${contacted}` : ''}</td></tr>`;
          });
          return tooltipText;
        },
        shared: true,
        useHTML: true
      };
    }
  }


  getData() {
    if (this.labels.length) {
      return this.labels.map((element, index) => {
        return {
          name: element,
          data: this.data.map(obj => obj[element]),
          color: this.colors[index % this.colors.length],
          type: 'column'
        };
      });
    } else {
      return [
        {
          name: 'Data',
          data: this.data.map(obj => Object.values(obj)[0]),
          type: 'column',
          showInLegend: false
        }
      ];
    }
  }
}

function paginatedChart(chart, pagination: number, starChartAtTheEnd: boolean = false) {
  if (chart.series[0] && chart.series[0].data.length > pagination) {
    const dataLength = chart.series[0].data.length,
      buttonsNum = 3,
      options: any = {
        str: '<<',
        x: 0,
        y: 0,
        step: pagination
      };

    starChartAtTheEnd
      ? chart.xAxis[0].setExtremes(dataLength - pagination, dataLength - 1)
      : chart.xAxis[0].setExtremes(0, options.step);

    chart.customBtns = [];

    for (let i = 0; i < buttonsNum; i++) {
      if (!i) {
        renderBtn(options, chart);
        options.str = '<';
        renderBtn(options, chart);
      }

      if (i === buttonsNum - 1) {
        options.str = '>';
        renderBtn(options, chart);
        options.str = '>>';
        renderBtn(options, chart);
      }
    }
    placeBtns(chart);
  }
}

function renderBtn(options, chart) {
  chart.customBtns.push(chart.renderer.button(
    options.str,
    options.x,
    options.y,
    function () {
      setRange.call(this, options, chart.xAxis[0]);
    },
    {
      style: {
        color: 'white',
      },
    })
    .add());

  options.x += options.width;
}

function setRange(options, axis) {
  let textStr = this.text.textStr,
    min,
    max;

  if (Highcharts.isNumber(textStr)) {
    min = (textStr - 1) * options.step;
    max = (textStr - 1) * options.step + options.step;
  } else {
    switch (textStr) {
      case '<<':
        min = 0;
        max = options.step - 1;
        break;

      case '<':
        if (axis.min - options.step < 0) {
          min = 0;
          max = options.step - 1;
        } else {
          min = axis.min - options.step;
          max = axis.min - 1;
        }

        break;

      case '>>':
        min = axis.dataMax - options.step + 1;
        max = axis.dataMax;
        break;

      case '>':
        if (axis.max + options.step >= axis.dataMax) {
          min = axis.dataMax - options.step + 1;
          max = axis.dataMax;
        } else {
          min = axis.max + 1;
          max = axis.max + options.step;
        }
        break;
    }
  }

  axis.setExtremes(min, max);
}

function placeBtns(chart) {
  let btns = chart.customBtns,
    btnsWidth = 0,
    x;

  btns.forEach(function (btn) {
    btnsWidth += btn.getBBox().width;
  });

  x = (chart.chartWidth - btnsWidth) / 2;

  btns.forEach(function (btn) {
    btn.attr({
      x: x
    });
    x += btn.getBBox().width;
  });
}
