import { IRestModel } from './../models/rest-model';
import { Observable } from 'rxjs';
import { Filter } from './../../library/classes/filter';
import { HttpClient } from '@angular/common/http';
import { IRestService } from '../interfaces/irest-service';
import { Injectable } from '@angular/core';
import { buildFilter, checkIdFromFilter } from '../utils';

@Injectable()
export class RestService<T extends IRestModel> implements IRestService<T> {

  endpoint: string;

  constructor(protected httpClient: HttpClient) {}

  retrieve(id: number): Observable<T> {
    const url = `${this.endpoint}${id}/`;
    return this.httpClient.get<T>(url);
  }

  list(...filters: Filter[]): Observable<T[]> {
    const filter = buildFilter(...filters);
    const url = `${this.endpoint}${filter}`;
    return this.httpClient.get<T[]>(url);
  }

  create(value: T): Observable<T> {
    return this.httpClient.post<T>(this.endpoint, value);
  }

  update(value: T): Observable<T> {
    const url = `${this.endpoint}${value.id}/`;
    return this.httpClient.put<T>(url, value);
  }

  partialUpdate(value: T): Observable<T> {
    return this.httpClient.patch<T>(this.endpoint, value);
  }

  remove(id: number): Observable < T > {
    const url = `${this.endpoint}${id}/`;
    return this.httpClient.delete<T> (url);
  }

}
