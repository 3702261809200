import { Filter } from './../../library/classes/filter';
import { Component, OnInit, AfterViewChecked, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router/';
import { ClientAnalyticsService } from './../../services/client-analytics.service';

import { ClientAnalytics, GlobalAnalytics, CandidateBounceItem, DonutChartItem, UserVisitsFromCampaigns, CampaignsSlugs } from './../../classes/client-analytics';
import { Observable, combineLatest } from 'rxjs';
import { JobCategory } from '../../classes/job';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { setFilter } from '../../library/utils';
import { PHASES } from '../../classes/phases';
import { FunnelData } from '../../classes/funnel-data';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { switchMap, take } from 'rxjs/operators';
import { FormatTimePipe } from '../../pipes/format-time.pipe';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user-service.service';
import { Profession } from '../../classes/profession';
import { COLOR_VARIABLES } from '../../utils/color-variables';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const TICK_INTERVALS_HIGHCHARTS = {
  MONTHS: '{tickInterval: 1000 * 3600 * 24 *30}',
  DAYS: '{tickInterval: 1000 * 3600 * 24}'
};

const SELECTED_INTERVAL = {
  SINCE_START: 'since-start',
  BY_YEAR: 'by-year',
  BY_SEMESTER: 'by-semester',
  BY_MONTH: 'by-month',
};

@Component({
  selector: 'app-new-dashboard-client',
  templateUrl: './new-dashboard-client.component.html',
  styleUrls: ['./new-dashboard-client.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: FormatTimePipe, useClass: FormatTimePipe }
  ],
})
export class NewDashboardClientComponent implements OnInit, AfterViewChecked, OnDestroy {

  @Input() set clientId(clientId: number) {
    if (clientId && clientId !== this.client_id) {
      this.client_id = clientId;
      this.parameters = setFilter(this.parameters, new Filter('clients', clientId));
      this.getAnalyticsData();
    }
  }

  @Input() showName = false;
  @Input() showReputationAnalytics = true;

  closeFilters = true;
  professions: Profession[] = [];
  client: Client = new Client();
  client_id: number;
  organizations: Array<any> = [];
  locations: Array<any> = [];
  functions: Array<any> = [];
  boxActive: Number = 0;

  instrumentCharts: any = [];
  isIstrumentsEmpty = false;

  loading = false;

  filters = {
    professions: [],
    organization: [],
    function: [],
    location: [],
    status: null,
    time: null,
    created_start: null,
    created_end: null,
  };
  states: Array<{ name, value }> = [
    { name: 'Open', value: 'open' },
    { name: 'Closed', value: 'close' }
  ];
  dateFilters: { name: string, id: number, time: string, quantity: any }[] = [
    { name: 'Today', id: 0, time: 'days', quantity: 0 },
    { name: 'Last Week', id: 1, time: 'weeks', quantity: 1 },
    { name: 'Last Month', id: 2, time: 'months', quantity: 1 },
    { name: 'Last Three Month', id: 3, time: 'months', quantity: 3 },
    { name: 'Last Six Month', id: 4, time: 'months', quantity: 6 },
    { name: 'Last Year', id: 5, time: 'years', quantity: 1 },
  ];

  parameters: Filter[] = [];
  data: ClientAnalytics = new ClientAnalytics();

  globalAnalytics = new GlobalAnalytics();

  colors = ['orange', 'green', 'blue', 'pink', 'turquoise', 'purple'];

  jobsPerFunction: { categories: string[], labels: string[], data: any } = { labels: [], categories: [], data: [] };
  jobsPerLocation: { categories: string[], labels: string[], data: any } = { labels: [], categories: [], data: [] };
  jobsPerOrganization: { categories: string[], labels: string[], data: any } = { labels: [], categories: [], data: [] };
  jobsPerProfesion: { categories: string[], labels: string[], data: any } = { labels: [], categories: [], data: [] };

  userVisitsByCountry: { labels: string[], data: any, total: number, configuration: any } = { labels: [], data: [], total: 0, configuration: [] };
  userVisitsSinceStart: { x: number, y: number }[];
  userVisitsLastYear: { x: number, y: number }[];
  userVisitsLastSemester: { x: number, y: number }[];
  userVisitsLastMonth: { x: number, y: number }[];
  selectedIntervalTrafficVisits: { visits: { x: number, y: number }[], interval: string } = { visits: [], interval: '' };
  selectedInterval = SELECTED_INTERVAL.SINCE_START;
  selectedIntervaloptions = SELECTED_INTERVAL;

  applicantsPerType: { labels: string[], data: number[], hide?: boolean, total: number } = { labels: [], data: [], hide: true, total: 0 };
  applicantsPerAgeGender: any;
  applicantsBySource: { campaign: string, count: number, source: string }[];
  applicantsPerInstruments: { instruments: string[], values: any[], showMore?: boolean, total_assessed?: number, total_invited?: number, series: any } = {
    instruments: [],
    values: [],
    showMore: false,
    total_assessed: 0,
    total_invited: 0,
    series: []
  };
  applicantsPerInterviews: { interviews: string[], values: any, showMore?: boolean, total_pending?: number, total_completed?: number, series: any } = {
    interviews: [],
    values: [],
    showMore: false,
    total_pending: 0,
    total_completed: 0,
    series: []
  };

  timesPerInterviews: { labels: string[], data: number[], total?: number, hide?: boolean } = { labels: [], data: [], total: 0, hide: true };
  candidatesBounceRate: CandidateBounceItem[] = [];
  isAgeAndGenderFormEmpty: any;
  visitsByDevice: DonutChartItem = new DonutChartItem();
  userVisitsFromCampaigns: UserVisitsFromCampaigns[];

  templatePagination: any = {
    lastPage: 0,
    currentPage: 0,
    firstPage: 0,
    elementsPerPage: 8
  };

  visitCampaignsPagination: any = {
    lastPage: 0,
    currentPage: 0,
    firstPage: 0,
    elementsPerPage: 5
  };

  campaignsPagination: any = {
    lastPage: 0,
    currentPage: 0,
    firstPage: 0,
    elementsPerPage: 8
  };

  time_to_interviews = 0;
  time_to_shortlist = 0;
  time_to_hired = 0;


  defaultFilters: any = [];

  customDonutConfig = {
    legend: {
      className: 'vertical-donut-legend',
    }
  };

  componentSubscriptions: any = {};

  constructor(
    private clientAnalyticsService: ClientAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private formatTimePipe: FormatTimePipe,
    public translateService: TranslateService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.client_id) {
      this.client_id = +this.activatedRoute.snapshot.paramMap.get('client');
      this.parameters = setFilter(this.parameters, new Filter('clients', this.client_id));

      if (this.userService.isRecruiterRole()) {
        this.componentSubscriptions.userServiceGetJobCategories$ = this.userService.getJobCategories().subscribe(
          categories => {
            if (categories && categories.length > 0) {
              this.defaultFilters = this.defaultFilters.concat(categories);
            } else {
              this.getAnalyticsData();
            }
          }
        );
      } else {
        this.getAnalyticsData();
      }
    }
  }


  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getAnalyticsData() {
    this.getMainGlobalAnalytics();
    this.getClientAnalytics();
    this.getOrganizationClient();
    this.setJobsTab();
    this.setApplicantsTab();
    this.setEfficiencyTab();
  }

  ngAfterViewChecked() {
    window.dispatchEvent(new Event('resize'));
  }

  setJobsTab() {
    this.getJobsSituationByCategories();
    this.getTemplatesSummary();
    this.getVisitsByDevice();
    this.getUsersVisitsFromCampaign();
    this.getUserVisitsByDate();
    this.getUserVisitsByCountry();
  }

  setApplicantsTab() {
    this.getApplicantPerType();
    this.getApplicantsPerAgeGender();
    this.getApplicantsBySource();
    this.getApplicantsPerInstruments();
    this.getAllInstruments();
    this.getApplicantsPerInterviews();
    this.getTimesPerInterviews();
  }

  setEfficiencyTab() {
    this.getCandidateBounceRates();
    this.getTimeToPhases();
  }

  getJobsSituationByCategories() {
    this.componentSubscriptions.clientAnalyticsServiceGetJobSituationByCategories$ = this.clientAnalyticsService.getJobsSituationByCategories(this.client_id, ...this.parameters).subscribe(
      response => {

        const categories = [
          'ORG',
          'FUN',
          'LOC',
          'PRO',
        ];

        const jobsPerCategories = [
          'jobsPerOrganization',
          'jobsPerFunction',
          'jobsPerLocation',
          'jobsPerProfesion',
        ];

        categories.forEach((categorie, index) => this[jobsPerCategories[index]] = this.formatJobCategories(response[categories[index]]));
      }
    );
  }

  formatJobCategories(responseCategorie) {
    const activeTranslation = this.translateService.instant('__active');
    const closedTranslation = this.translateService.instant('__closed');
    const expiredTranslation = this.translateService.instant('__expired');

    const jobsPer: { categories: string[], labels: string[], data: any } = { labels: [], categories: [], data: [] };

    jobsPer.categories = responseCategorie.map(element => element.name);
    jobsPer.labels = ['expired', 'closed', 'active'];
    jobsPer.data = responseCategorie.map(element => {
      return {
        'expired': element.expired,
        'closed': element.closed,
        'active': element.active,
      };
    });

    return jobsPer;
  }

  getTemplatesSummary() {
    this.componentSubscriptions.clientAnalyticsServiceGetProfessionsSummary$ = this.clientAnalyticsService.getProfessionsSummary(this.client_id, ...this.parameters).subscribe(
      data => {
        this.templatePagination.lastPage = Math.ceil(data.length / 8);
        this.templatePagination.currentPage = 1;
        this.templatePagination.firstPage = 1;
      }
    );
  }

  goToNextPage() {
    if (this.templatePagination.currentPage < this.templatePagination.lastPage) {
      this.templatePagination.currentPage += 1;
    }
  }
  goToPreviousPage() {
    if (this.templatePagination.currentPage > this.templatePagination.firstPage) {
      this.templatePagination.currentPage -= 1;
    }
  }

  getUserVisitsByDate() {
    this.componentSubscriptions.clientAnalyticsServiceGetUserVisitsByDate$ = this.clientAnalyticsService.getUserVisitsByDate(this.client_id, ...this.parameters).subscribe(
      data => {
        const days: any = Object.keys(data);
        const views: number[] = Object.values(data);
        const realMonthsVisits: number[] = [];
        const realMonths = [];

        this.userVisitsLastMonth = days.map((day, i) => {
          return { x: Date.UTC(day.substr(0, 4), day.substr(4, 2) - 1, day.substr(6, 2)), y: views[i] };
        });
        const monthLength = this.userVisitsLastMonth.length;
        if (monthLength > 31) {
          this.userVisitsLastMonth = this.userVisitsLastMonth.slice(monthLength - 31, monthLength);
        }


        days.forEach((day, i) => {
          const index = realMonths.indexOf(day.substr(0, 6));
          if (index > -1) {
            realMonthsVisits[index] += views[i];
          } else {
            realMonths.push(day.substr(0, 6));
            realMonthsVisits.push(views[i]);
          }
        });

        this.userVisitsSinceStart = realMonths.map((month, i) => {
          return { x: Date.UTC(month.substr(0, 4), month.substr(4, 2) - 1, 1), y: realMonthsVisits[i] };
        });

        this.userVisitsLastYear = this.userVisitsSinceStart;
        const yearLength = this.userVisitsLastYear.length;
        if (yearLength > 12) {
          this.userVisitsLastYear = this.userVisitsLastYear.slice(yearLength - 12, yearLength);
        }

        const semesterLength = this.userVisitsLastYear.length;
        this.userVisitsLastSemester = semesterLength > 6 ? this.userVisitsLastYear.slice(semesterLength - 6, semesterLength) : this.userVisitsLastYear;

        this.selectedInterval = this.selectedIntervaloptions.SINCE_START;
        this.selectedIntervalTrafficVisits.visits = this.userVisitsSinceStart;
        this.selectedIntervalTrafficVisits.interval = TICK_INTERVALS_HIGHCHARTS.MONTHS;
      }
    );
  }

  selectIntervalVisitsByDate(interval) {
    switch (interval) {
      case SELECTED_INTERVAL.SINCE_START:
        this.selectedIntervalTrafficVisits.visits = this.userVisitsSinceStart;
        this.selectedIntervalTrafficVisits.interval = TICK_INTERVALS_HIGHCHARTS.MONTHS;
        break;

      case SELECTED_INTERVAL.BY_YEAR:
        this.selectedIntervalTrafficVisits.visits = this.userVisitsLastYear;
        this.selectedIntervalTrafficVisits.interval = TICK_INTERVALS_HIGHCHARTS.MONTHS;
        break;

      case SELECTED_INTERVAL.BY_SEMESTER:
        this.selectedIntervalTrafficVisits.visits = this.userVisitsLastSemester;
        this.selectedIntervalTrafficVisits.interval = TICK_INTERVALS_HIGHCHARTS.MONTHS;
        break;

      case SELECTED_INTERVAL.BY_MONTH:
        this.selectedIntervalTrafficVisits.visits = this.userVisitsLastMonth;
        this.selectedIntervalTrafficVisits.interval = TICK_INTERVALS_HIGHCHARTS.DAYS;
        break;

      default:
        this.selectedIntervalTrafficVisits.visits = this.userVisitsSinceStart;
        this.selectedIntervalTrafficVisits.interval = TICK_INTERVALS_HIGHCHARTS.MONTHS;
    }
  }


  getUserVisitsByCountry() {
    this.componentSubscriptions.clientAnalyticsServiceGetUserVisitsByCountry$ = this.clientAnalyticsService.getUserVisitsByCountry(this.client_id, ...this.parameters).subscribe(
      data => {
        this.userVisitsByCountry.labels = Object.keys(data).slice(0, 10);
        const values: number[] = Object.values(data);
        this.userVisitsByCountry.total = values.reduce((a: number, b: number) => a + b, 0);
        this.userVisitsByCountry.data = [{ data: values.slice(0, 10) }];
        this.userVisitsByCountry.configuration = [{ legendEnabled: true }];
      }
    );
  }

  getClientAnalytics() {
    this.componentSubscriptions.activatedRouteParamMapPipe$ = this.activatedRoute.paramMap.pipe(switchMap((param) => {
      if (!this.client_id) {
        this.client_id = +param.get('client');
      }
      return this.getData();
    }))
      .subscribe(data => {
        this.data = data;
      });
  }

  getApplicantPerType() {
    this.componentSubscriptions.clientAnalyticsServiceGetApplicantsPerType$ = this.clientAnalyticsService.getApplicantsPerType(this.client_id, ...this.parameters).subscribe(
      data => {
        const employeesTranslated = this.translateService.instant('__employees');
        const externalTranslated = this.translateService.instant('__externalCandidates');
        this.applicantsPerType.labels = Object.keys(data.applicants).map(element => element === 'employees' ? employeesTranslated : externalTranslated);

        this.applicantsPerType.data = Object.values(data.applicants);
        this.applicantsPerType.total = data.total;
        this.applicantsPerType.hide = Object.values(data.applicants).every(element => element === 0);
      }
    );
  }

  getApplicantsPerAgeGender() {
    this.componentSubscriptions.clientAnalyticsServiceGetApplicantsPerAgeGender$ = this.clientAnalyticsService.getApplicantsPerAgeGender(this.client_id, ...this.parameters).subscribe(
      data => {
        this.applicantsPerAgeGender = this.serializerAgeGender(data);
      }
    );
  }

  serializerAgeGender(server_data) {
    this.isAgeAndGenderFormEmpty = Object.entries(server_data)
      .map(rank => rank[1]);
    this.isAgeAndGenderFormEmpty = this.isAgeAndGenderFormEmpty.filter(filter => filter.male !== 0 || filter.female !== 0);
    this.isAgeAndGenderFormEmpty = this.isAgeAndGenderFormEmpty.length === 0 ? true : false;
    const labels = Object.keys(server_data)
      .sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]));
    return Object.assign({ name, labels }, {
      male: labels.map(label => server_data[label].male),
      female: labels.map(label => server_data[label].female)
    });
  }

  getApplicantsBySource() {
    this.componentSubscriptions.clientAnalyticsServiceGetApplicantsPerCampaigns$ = this.clientAnalyticsService.getApplicantsPerCampaigns(this.client_id, ...this.parameters).subscribe(
      data => {
        this.applicantsBySource = data;
        this.campaignsPagination.lastPage =
          Math.ceil(this.applicantsBySource.length / this.campaignsPagination.elementsPerPage);
        this.campaignsPagination.currentPage = 1;
        this.campaignsPagination.firstPage = 1;
      }
    );
  }

  getApplicantsPerInstruments() {
    this.componentSubscriptions.clientAnalyticsServiceGetApplicantsPerInstruments$ = this.clientAnalyticsService.getApplicantsPerInstruments(this.client_id, ...this.parameters).subscribe(
      data => {
        this.applicantsPerInstruments.total_assessed = data.total_assessed;
        this.applicantsPerInstruments.total_invited = data.total_invited;
        this.applicantsPerInstruments.instruments = Object.keys(data.items).map(label => this.translateService.instant(label));
        this.applicantsPerInstruments.values = Object.values(data.items);
        this.applicantsPerInstruments.series = [
          {
            name: this.translateService.instant('__completed'),
            data: this.applicantsPerInstruments.values.map(value => value.CMP)
          },
          {
            name: this.translateService.instant('__invited'),
            data: this.applicantsPerInstruments.values.map(value => value.INV)
          },
        ];
      }
    );
  }
  getAllInstruments() {
    this.componentSubscriptions.clientAnalyticsServiceGetAllInstruments$ = this.clientAnalyticsService.getAllInstruments(this.client_id, ...this.parameters).subscribe(
      data => {
        this.instrumentCharts = this.serializerInstrumentsData(data);
      }
    );
  }

  serializerInstrumentsData(data) {
    this.isIstrumentsEmpty = Object.keys(data)
      .length === 0;
    let charts = [];
    for (const value in data) {
      if (data.hasOwnProperty(value)) {
        charts = data[value].__type__ === 'radar' ?
          charts.concat(this._getRadarInstrumentResults(data[value])) : charts.concat(this._getInstrumentResults(data[value]));
      }

    }
    return charts;
  }

  _getInstrumentResults(instrument: any): any[] {
    const results = [];
    for (const result in instrument.results) {
      if (instrument.results.hasOwnProperty(result)) {
        const values = Object.keys(instrument.results[result])
          .map(label => ({ data: instrument.results[result][label] || 0 }));
        const data = instrument.__type__ === 'bar' || instrument.__type__ === 'horizontal-bar' ? { name: 'Candidates', values: values } : values;
        const result_obj = {
          name: instrument.name,
          labels: Object.keys(instrument.results[result]),
          data: values.length > 0 ? data : [],
          type: instrument.__type__,
          total_assessed: instrument.total_assessed,
          total_invited: instrument.total_invited
        };
        results.push(result_obj);
      }
    }
    return results;
  }

  _getRadarInstrumentResults(instrument: any): any[] {
    const labels: string[] = Object.values(instrument.field_mappings)
      .filter(label => instrument.results[String(label)] != null
        && instrument.results[String(label)].constructor != Object)
      .map(label => String(label));
    const reference_profile = instrument.__profile_reference__;
    const data = {};
    data[instrument.operation] = labels.map(label => instrument.results[label]);

    if (Object.values(reference_profile)[0]) {
      data['reference_profile'] = labels.map(label => reference_profile[label] || 0);
    }

    const result_obj = {
      name: instrument.name,
      labels: labels,
      data: data[instrument.operation].length > 0 ? data : [],
      type: instrument.__type__,
      total_assessed: instrument.total_assessed,
      total_invited: instrument.total_invited
    };
    return [result_obj];
  }

  getApplicantsPerInterviews() {
    this.componentSubscriptions.clientAnalyticsServiceGetApplicantsPerInterviews$ = this.clientAnalyticsService.getApplicantsPerInterviews(this.client_id, ...this.parameters).subscribe(
      data => {
        this.applicantsPerInterviews.total_completed = data.total_completed;
        this.applicantsPerInterviews.total_pending = data.total_candidates;

        const orderedPhases = ['PHO', 'OA', 'HR', 'BUS'];
        const orderedObject = orderedPhases.reduce((resultObj, currentPhase) => {
          resultObj[currentPhase] = data.items[currentPhase];
          return resultObj;
        }, {});

        this.applicantsPerInterviews.interviews = Object.keys(orderedObject).map(phase => this.translateService.instant('__' + phase));
        this.applicantsPerInterviews.values = Object.values(orderedObject);
        this.applicantsPerInterviews.series = [
          {
            name: this.translateService.instant('__completed'),
            data: this.applicantsPerInterviews.values.map(value => value.CMP),
          },
          {
            name: this.translateService.instant('__pending'),
            data: this.applicantsPerInterviews.values.map(value => value.PND),
          }
        ];
      }
    );
  }

  getTimesPerInterviews() {
    this.componentSubscriptions.clientAnalyticsServiceGetTimesPerInterviews$ = this.clientAnalyticsService.getTimesPerInterviews(this.client_id, ...this.parameters).subscribe(
      data => {
        const orderedPhases = ['PHO', 'OA', 'HR', 'BUS'];
        const orderedObject = orderedPhases.reduce((resultObj, currentPhase) => {
          resultObj[currentPhase] = data.data[currentPhase];
          return resultObj;
        }, {});

        this.timesPerInterviews.labels = Object.keys(orderedObject).map(phase => this.translateService.instant('__' + phase));
        this.timesPerInterviews.data = Object.values(orderedObject);
        this.timesPerInterviews.hide = data.total === 0;
        this.timesPerInterviews.total = this.formatTimePipe.transformToDays(data.total);
      }
    );
  }


  getTimeToPhases() {
    this.componentSubscriptions.clientAnalyticsServiceGetTimeToPhases$ = this.clientAnalyticsService.getTimeToPhases(this.client_id, ...this.parameters).subscribe(
      data => {
        this.time_to_interviews = data.time_to_interviews;
        this.time_to_shortlist = data.time_to_shortlist;
        this.time_to_hired = data.time_to_hired;
      }
    );
  }

  getCandidateBounceRates() {

    const colors = {
      '__landingVisits': COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHTER'],
      '__registrationForm': COLOR_VARIABLES['$_ANOTHER_PRIMARY_COLOR'],
      '__registrations': COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT'],
    };

    this.componentSubscriptions.clientAnalyticsServiceGetCandidatesBounceRates$ = this.clientAnalyticsService.getCandidatesBounceRates(this.client_id, ...this.parameters).subscribe(
      data => {
        this.candidatesBounceRate = [];
        if (Object.keys(data).length > 0) {
          for (const [key, value] of Object.entries(data.visits)) {
            if (value !== -1) {
              this.candidatesBounceRate.push({ name: key, visits: value, color: colors[key] });
            }
          }

          this.candidatesBounceRate = this.candidatesBounceRate.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            }
            return 0;
          });


          for (const [key, value] of Object.entries(data.bounce_rates)) {
            const index = this.candidatesBounceRate.findIndex(element => element.name === key);
            if (index >= 0) {
              this.candidatesBounceRate[index]['rate'] = value;
            }
          }
        }
      }
    );
  }

  getVisitsByDevice() {
    this.componentSubscriptions.clientAnalyticsServiceGetVisitsByDevice$ = this.clientAnalyticsService.getVisitsByDevice(this.client_id, ...this.parameters).subscribe(
      data => {

        const filtered = Object.keys(data)
          .filter(key => !key.includes('percentage') && !key.includes('total'))
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});

        this.visitsByDevice.labels = Object.keys(filtered);
        this.visitsByDevice.data = Object.values(filtered);
        this.visitsByDevice.total = data.total;


      }
    );
  }


  getUsersVisitsFromCampaign() {
    this.componentSubscriptions.clientAnalyticsServiceGetUserVisitsFromCampaigns$ = this.clientAnalyticsService.getUserVisitsFromCampaigns(this.client_id, ...this.parameters).subscribe(
      data => {
        this.userVisitsFromCampaigns = Object.entries(data).map(
          ([key, value]) => {
            return {
              campaign: this.formatSentence(key),
              count: value as number,
              source: this.getCampaignSource(this.getCampaignRawName(key))
            };
          }
        ).filter(campaign => campaign.count > 10);

        this.visitCampaignsPagination.lastPage =
          Math.ceil(this.userVisitsFromCampaigns.length / this.visitCampaignsPagination.elementsPerPage);
        this.visitCampaignsPagination.currentPage = 1;
        this.visitCampaignsPagination.firstPage = 1;
      }
    );
  }

  goToVisitsNextPage() {
    if (this.visitCampaignsPagination.currentPage < this.visitCampaignsPagination.lastPage) {
      this.visitCampaignsPagination.currentPage += 1;
    }
  }
  goToVisitsPreviousPage() {
    if (this.visitCampaignsPagination.currentPage > this.visitCampaignsPagination.firstPage) {
      this.visitCampaignsPagination.currentPage -= 1;
    }
  }


  goToCampaignsNextPage() {
    if (this.campaignsPagination.currentPage < this.campaignsPagination.lastPage) {
      this.campaignsPagination.currentPage += 1;
    }
  }
  goToCampaignsPreviousPage() {
    if (this.campaignsPagination.currentPage > this.campaignsPagination.firstPage) {
      this.campaignsPagination.currentPage -= 1;
    }
  }

  getCampaignRawName(campaign): string {
    return campaign.split(/_| /)[0].toLowerCase();
  }

  getCampaignSource(campaign): string {
    let campaignName = 'organic';
    const campaignsSlugsList = new CampaignsSlugs().getCampaignsSlugsList();
    for (const element of campaignsSlugsList) {
      if (element.includes(campaign)) {
        campaignName = element[0];
        break;
      }
    }

    return campaignName;
  }

  formatSentence(sentence): string {
    const formattedElement = sentence.replace('_', ' ').toLowerCase();
    return `${formattedElement.charAt(0).toUpperCase()}${formattedElement.slice(1)}`;
  }

  setBoxActive(tab) {
    this.boxActive = tab.index;
  }

  getData(): Observable<any> {
    const clientAnalytics = combineLatest(
      ...this.getAnalyticsObservables(),
      (jobStatus, candidateStatus: any, jobSummary, professionSummary, candidateSummary, hireSummary) =>
        ClientAnalytics.buildClientAnalytics(
          jobStatus,
          candidateStatus,
          jobSummary,
          professionSummary,
          hireSummary,
          this.parseMaxJobsInProfession(professionSummary),
          this.parseMaxCandidatesInProfession(professionSummary),
          this.parsemaxCandidatesInJob(jobSummary),
          FunnelData.getFunnelFromPhasesData(
            PHASES,
            candidateSummary,
            candidateSummary.INS)
        )
    )
      .pipe(take(1));

    return clientAnalytics;
  }

  getMainGlobalAnalytics() {
    this.componentSubscriptions.clientAnalyticsServiceGetMainGlobalAnalytics$ = this.clientAnalyticsService.getMainGlobalAnalytics(this.client_id, ...this.parameters)
      .subscribe(results => {
        this.globalAnalytics = GlobalAnalytics.buildGlobalAnalytics(
          results.jobs_count,
          results.candidates_count,
          results.candidates_assessed_count,
          results.suitable_candidates_count,
          results.hired_candidates_count,
          results.templates_count,
          results.active_candidates_count,
          results.expired_candidates_count
        );
      });
  }

  getAnalyticsObservables() {
    const observablesArray = [
      this.clientAnalyticsService.getJobsStatus(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getCandidatesStatus(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getJobsSummary(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getProfessionsSummary(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getCandidatesSummary(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getHireSummary(this.client_id, ...this.parameters),
      this.clientAnalyticsService.getMainGlobalAnalytics(this.client_id, ...this.parameters),
    ];
    return observablesArray;
  }

  parseMaxJobsInProfession(data) {
    return Math.max(...data.map(profession => profession.jobs));
  }

  parseMaxCandidatesInProfession(data) {
    return Math.max(...data.map(profession => profession.candidates_total));
  }

  parsemaxCandidatesInJob(data) {
    return Math.max(...data.map(job => job.total));
  }

  getColors(candidate: any) {
    const colLength = this.colors.length;
    if (colLength > 0 && candidate.email) {
      const index = candidate.email[0].charCodeAt(0) % this.colors.length;
      return this.colors[index];
    }
  }

  getCandidateInitials(candidate) {
    if (candidate.first_name && candidate.last_name) {
      return `${candidate.first_name[0]}${candidate.last_name[0]}`;
    }
    return `${candidate.email[0]}${candidate.email[1]}`;
  }

  getOrganizationClient() {
    this.loading = true;

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(this.client_id)
      .subscribe(
        response => {
          this.client = response;
          this.getClientCategories();
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        },
      );
  }

  getClientCategories() {
    this.componentSubscriptions.clientServiceGetJobCategories$ = this.clientService.getJobCategories(this.client_id)
      .subscribe(
        jobCategories => {
          ({ organizations: this.organizations, locations: this.locations, functions: this.functions } =
            this._initJobCategoriesFilter(jobCategories)
          );
        },
        error => console.log(error)
      );
  }

  setClientFilter(filter, value) {
    this.filters[filter] = value;

    const timeFilters = ['created_start', 'created_end'];
    if (timeFilters.includes(filter)) {
      value = this._formatDateFilter(value);
      this.filters.time = null;
    }
    if (filter === 'time') {
      filter = 'created_start';
      value = this._formatDateFilterToTimeAgo(value);
      this.parameters = this.parameters.filter(param => param.name !== 'created_end' && param.name !== 'created_start');
      this.filters.created_start = null;
      this.filters.created_end = null;
    }
    this.parameters = setFilter(this.parameters, new Filter(filter, value));
    this.getClientAnalytics();
  }

  _initJobCategoriesFilter(jobCategories: JobCategory[]) {
    const organizations = jobCategories.filter(jobCategory => jobCategory.category === 'ORG');
    const locations = jobCategories.filter(jobCategory => jobCategory.category === 'LOC');
    const functions = jobCategories.filter(jobCategory => jobCategory.category === 'FUN');
    const professions = jobCategories.filter(jobCategory => jobCategory.category === 'PRO');
    return { organizations, locations, functions, professions };
  }

  selectAll(filter: string, values: any[], id_key: string) {
    this.filters[filter] = values.map(e => e[id_key]);
  }

  deselectAll(categoryName) {
    this.filters[categoryName] = [];
  }

  toggle(values, filter: string, key: string) {
    if (values.length === this.filters[filter].length - 1) {
      this.deselectAll(filter);
    } else {
      this.selectAll(filter, values, key);
    }
    this.setClientFilter(filter, this.filters[filter]);
  }

  _formatDateFilterToTimeAgo(value) {
    const today = moment()
      .startOf('day');
    const start = today.subtract(this.dateFilters[value].quantity, this.dateFilters[value].time);
    return start.toISOString();
  }

  _formatDateFilter(value) {
    return moment(value)
      .toISOString();
  }

  filter(parameters: Filter[]) {
    this.parameters = [];
    this.parameters = setFilter(this.parameters, new Filter('clients', this.client_id));

    parameters.forEach(p => {
      this.parameters = setFilter(this.parameters, p);
    });

    this.getMainGlobalAnalytics();
    this.getClientAnalytics();
    this.setJobsTab();
    this.setApplicantsTab();
    this.setEfficiencyTab();
    window.dispatchEvent(new Event('resize'));
  }

}
