import { Subject } from 'rxjs';
import { Filter } from '../../../library/classes/filter';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { setFilter } from '../../../library/utils';
import { PartnerService } from '../../services/partner.service';
import { Partner } from '../../models/partner';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-partner-table',
  templateUrl: './partner-table.component.html',
  styleUrls: ['./partner-table.component.scss']
})
export class PartnerTableComponent implements OnInit, OnDestroy {
  showCreatePartner = false;

  loading = true;
  sorted = true;

  elements: any = {
    elements: [],
    headers: []
  };

  parameters: Filter[] = [];

  searchTerm = new Subject<string>();
  name_order = 1;
  total = 0;
  search = '';

  componentSubscriptions: any = {};

  constructor(
    private partnerService: PartnerService
  ) { }

  ngOnInit() {
    this.startSearch();
    this.getPartners();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  startSearch(): void {
    this.componentSubscriptions.searchTermPipe$ = this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(search => {
          this.parameters = setFilter(this.parameters, new Filter('page', 1));
          this.parameters = setFilter(this.parameters, new Filter('search', search));
          return this.partnerService.getPartnerlist(...this.parameters);
        })
      )
      .subscribe(partners => this.saveElementsTable(partners));
  }

  sortName() {
    this.name_order = this.name_order < 0 ? 1 : -1;
    this.parameters = setFilter(this.parameters, new Filter('ordering', this.name_order < 0 ? '-name' : 'name'));
    this.getPartners();
  }

  saveElementsTable(partners: HttpResponse<Partner[]>) {

    this.total = +partners.headers.get('count');
    this.elements.elements = partners.body;

    this.elements.headers = [
      {
        key: 'status',
        label: '__status',
      },
      {
        key: 'email',
        label: '__partnerEmail',
      },
      {
        key: 'phone_contact',
        label: '__phone_contact',
      },
      {
        key: 'clients_count',
        label: '__clients',
      }];

    this.loading = false;
  }

  createPartner() {
    this.showCreatePartner = true;
  }

  getPartners() {
    this.componentSubscriptions.partnerServiceList$ = this.partnerService.getPartnerlist(...this.parameters).subscribe(
      partners => this.saveElementsTable(partners)
    );
  }

  partnerSaved() {
    this.getPartners();
    this.showCreatePartner = false;
  }

  changePage(event: { page, itemsPerPage }) {
    this.parameters = setFilter(this.parameters, new Filter('page', event.page));
    this.getPartners();
  }
}
