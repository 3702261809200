import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatIfIsDate'
})
export class FormatIfIsDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
      const date = new Date(value);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    return value;
  }

}

