
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Job } from '../../classes/job';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertsService, ALERT_TYPES } from '../../services/alerts.service';
import { JobService } from '../../services/job.service';
import { UserFilter, User } from '../../classes/user';
import { NgForm } from '@angular/forms';
import { UserDataService } from '../../services/user-data-service.service';
import { UserService } from '../../services/user-service.service';
import { Constants } from '../../classes/constants';

import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientDataService } from '../../services/client-data.service';
@Component({
  selector: 'app-sidebar-job-actions',
  templateUrl: './sidebar-job-actions.component.html',
  styleUrls: [ './sidebar-job-actions.component.scss' ]
})
export class SidebarJobActionsComponent implements OnInit, OnDestroy {

  @Input() job: Job = new Job();
  @Input() client: Client = new Client();
  @Input() index: number;
  @Output() jobAction: EventEmitter<{ type: string, job: Job }> = new EventEmitter();
  @Output() jobUpdated: EventEmitter<{ index: number, job: Job }> = new EventEmitter();

  modalRef: BsModalRef;

  requestFeedback: boolean = false;
  closeCause = '';
  closeCauses = Job.CLOSE_CAUSES;
  hiredCloseCauses = Job.HIRED_CLOSE_CAUSES;

  availableUsers: UserFilter[] = [];

  displayUsersFilterPopup = false;

  jobUrl = {
    landing: '',
    campaigns: [],
    campaignsMail: []
  };

  multiposting_config_valid: boolean = false;

  selectedNetwork = '';
  urlCampaigns = [
    {
      name: 'facebook',
      url: '?utm_source=facebook&utm_medium=aplygo&utm_campaign=facebook_mkt'
    },
    {
      name: 'linkedin',
      url: '?utm_source=linkedin&utm_medium=aplygo&utm_campaign=linkedin_mkt'
    },
    {
      name: 'twitter',
      url: '?utm_source=twitter&utm_medium=aplygo&utm_campaign=twitter_mkt'
    },
    {
      name: 'instagram',
      url: '?utm_source=instagram&utm_medium=aplygo&utm_campaign=instagram_mkt'
    }
  ];

  hidePostAndShareOption: boolean = false;

  componentSubscriptions: any = {};

  dataGroup: any
  showHighlightButton = false

  constructor(
    public jobService: JobService,
    public clientService: ClientService,
    public userService: UserService,
    private userDataService: UserDataService,
    private alertService: AlertsService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private clientDataService: ClientDataService
  ) { }

  ngOnInit() {
    this.jobUrl.campaigns = this.urlCampaigns;
    this.hidePostAndShareOption = this.job.privacy === 'DRA' || this.job.privacy === 'PRI' || this.jobService.isExpired(this.job) || this.job.closed != null;
    this.showHighlightButton = this.job.privacy !== 'DRA' && !this.job.closed;
    this.componentSubscriptions.dataGroup$ = this.clientDataService.dataGroup$.subscribe(data => { this.dataGroup = data; });

  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[ subscriptionKey ].unsubscribe()
    );
  }

  showModal(modalTemplate, addClass = '') {
    this.modalRef = this.modalService.show(modalTemplate, { class: addClass, backdrop: 'static' });
  }

  showCloseModal(modalTemplate, addClass) {
    this.componentSubscriptions.jobServiceGetJobDetail$ = this.jobService.getJobDetail(String(this.job.id))
      .subscribe(response => {
        this.job.transitions = response.transitions;
        this.showModal(modalTemplate, addClass);
      },
        () => {
          const translate_message = this.translateService.instant('__jobCannotClosed');
          this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: translate_message })
        }
      );
  }

  cloneJob() {
    this.jobAction.emit({ type: Job.CLONE_ACTION, job: this.job });
  }

  editJob() {
    this.modalRef.hide();
    this.jobAction.emit({ type: Job.UPDATE_ACTION, job: this.job });
  }

  closeJob() {
    if (this.closeCause !== '') {
      this.componentSubscriptions.jobServiceCloseJob$ = this.jobService.closeJob(String(this.job.id), this.closeCause, this.requestFeedback)
        .subscribe(
          job => {
            this.job = job;
            this.jobAction.emit({ type: Job.CLOSE_ACTION, job: this.job });
            this.closeCause = '';
            const translate_message = this.translateService.instant('__jobClosed');
            this.alertService.setAlert({ type: ALERT_TYPES.SUCCESS, message: translate_message });
            this.clientService.getActiveJobsCount(this.client.id);
          },
          error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
        );
    } else {
      const translate_message = this.translateService.instant('__jobCloseReasonNotSelected');
      this.alertService.setAlert({ type: ALERT_TYPES.ERROR, message: translate_message });
    }
  }

  removeJob() {
    this.componentSubscriptions.jobServiceRemoveJob$ = this.jobService.removeJob(this.job)
      .subscribe(
        () => {
          this.clientService.getActiveJobsCount(this.client.id);
          this._removeJob();
          const translate_message = this.translateService.instant('__jobDeletedCorrectly');
          this.alertService.setAlert({ type: ALERT_TYPES.SUCCESS, message: translate_message });
        },
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
      );
  }

  _removeJob() {
    this.modalRef.hide();
    this.jobAction.emit({ type: Job.REMOVE_ACTION, job: this.job });
  }

  exportToCsv() {
    this.componentSubscriptions.jobServiceExportCsv$ = this.jobService.exportCsv(this.job.id.toString())
      .subscribe(
        () => {
          const translate_message = this.translateService.instant('__sendCSV');
          this.alertService.setAlert({ type: ALERT_TYPES.SUCCESS, message: translate_message })
        },
        error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
      );
  }

  checkHiredCandidates() {
    if (this.job.transitions) { return this.job.transitions.HIR > 0; }
  }

  getClientUsers() {
    this.componentSubscriptions.clientServiceGetUsersByClient$ = this.clientService.getUsersByClient(this.client.id.toString())
      .subscribe(
        response => this.availableUsers = this.getAvailableUsers(response),
        error => console.error(error)
      );
  }

  getAvailableUsers(users: User[]): UserFilter[] {
    return users
      .map(user => Object.assign(new UserFilter(), user))
      .filter(user => user.hasClientHiringManagerPermissions() || user.hasClientRecruiterPermissions());
  }

  getSelectedUsers() {
    return this.availableUsers.filter(user => user.selected);
  }

  getVisibleUsers() {
    return this.availableUsers.filter(user => user.visible);
  }

  addSelectedUser(form: NgForm) {
    if (form.valid) {
      const newUser = new UserFilter();
      newUser.email = form.value[ 'filterUsers' ];
      newUser.selected = true;
      this.availableUsers.push(newUser);
    }
  }

  clearSelectedUsers() {
    this.availableUsers = this.availableUsers.map(user => {
      user.selected = false;
      user.visible = true;
      return user;
    });
  }

  addUsersToJob() {
    const selectedUsers = this.getSelectedUsers()
      .map(user => user.email);
    this.componentSubscriptions.userDataServiceAddUsersToJob$ = this.userDataService.addUsersToJob(`${this.job.id}`, selectedUsers)
      .subscribe(
        () => {
          const translate_message = this.translateService.instant('__jobAssignedCorrectly');
          this.alertService.setAlert({ type: ALERT_TYPES.SUCCESS, message: translate_message });
        },
        error => this.alertService.formatErrorMessage(error)
      );
  }

  filterClientUsers(search: string) {
    this.availableUsers = this.availableUsers.map(user => {
      const userText = user.first_name + user.last_name + user.email;
      user.visible = userText.toLowerCase()
        .indexOf(search.toLowerCase()) > -1;
      return user;
    });
  }

  getClientUrl() {
    return this.client.site.domain ? `https://${this.client.site.domain}/` : Constants.BASE_URL;
  }

  goToLanding() {
    this.componentSubscriptions.jobServiceGetLandingUrl$ = this.jobService.getLandingUrl(this.job.id.toString())
      .subscribe(
        data => {
          window.open(data.landing_url, '_blank');
        }
      );
  }

  createLandingUrl() {
    this.jobService.getLandingUrl(this.job.id.toString())
      .toPromise()
      .then(result => {
        return result.landing_url;

      });
  }

  createUrlCampaigns() {
    return this.urlCampaigns
      .map(element => element);
  }

  createCampaignsMail(campaigns) {
    const translate_message = this.translateService.instant('__campaignsLinks');
    window.open(`mailto:?subject=${translate_message + this.job.name
      || ''}&body=${campaigns.join('%0D%0A')}`);
  }

  openCampaingsMail() {
    this.componentSubscriptions.jobServiceGetLandingUrl$ = this.jobService.getLandingUrl(this.job.id.toString())
      .subscribe(data => {
        const landingUrl = data.landing_url;
        const campaignsMail = this.jobUrl.campaigns.map(element => `${landingUrl}${element.url}`);
        this.createCampaignsMail(campaignsMail);
      }, () => {
        const translate_message = this.translateService.instant('__campaignUrlError');
        this.alertService.setAlert({ type: 'error', message: translate_message });
      });

  }

  copyCampaign(campaign) {
    const input: any = document.getElementById('copyNetworkUrl');
    this.componentSubscriptions.jobServiceGetLandingUrlCopyCampaign$ = this.jobService.getLandingUrl(this.job.id.toString())
      .subscribe(data => {
        const url = `${data.landing_url}${campaign.url}`;
        input.value = url;
        input.select();
        document.execCommand('copy');
        const translate_message = this.translateService.instant('__campaignUrlCorrectly');
        this.alertService.setAlert({ type: 'success', message: `${campaign.name} ${translate_message}` });
      }, () => {
        const translate_message = this.translateService.instant('__campaignUrlError');
        this.alertService.setAlert({ type: 'error', message: `${campaign.name} ${translate_message}` });
      })
  }

  editJobMultipostingConfig() {

    if (this.multiposting_config_valid) {
      this.componentSubscriptions.jobServiceUpdateMultipostingConfig$ = this.jobService.updateMultipostingConfig(this.job, { job_multiposting_config: this.job.multiposting_config })
        .subscribe(
          result => {
            this.alertService.setAlert({ type: 'success', message: '__jobConfigUpdatedCorrectly' });
            this.jobUpdated.emit({ index: this.index, job: this.job });
          });
    }
  }

  highlightJob() {
    this.jobService.highlightJob(this.job.id).subscribe(
      response => {
        const translate_message = this.translateService.instant('__success');
        this.alertService.setAlert({ type: 'success', message: `${translate_message}` });
      },
      error => {
        this.alertService.setAlert(this.alertService.formatErrorMessage(error));
      }
    );
  }

}
