import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecoverPasswordService } from '../../services/recover-password.service';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})

export class RecoverPasswordComponent implements OnInit, OnDestroy {

  token = '';
  uid = '';
  confirmationSuccess = false;
  confirmationError = false;
  passwordMatch = false;
  showMeRequire = false;
  security = false;
  requirements = [
    {name: 'charactersLong', status: false},
    {name: 'specialCharacter', status: false},
    {name: 'oneCapital', status: false},
    {name: 'oneLowercase', status: false},
    {name: 'oneNumber', status: false}
  ];
  error = '';

  passMatchForm: FormGroup;

  componentSubscriptions: any = {};

  constructor(
    private recoverService: RecoverPasswordService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private form: FormBuilder
  ) { }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    this.token = params['token'];
    this.uid = params['uid'];
    this.reactiveForm();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  reactiveForm() {
    this.passMatchForm = this.form.group({
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    });
    this.passMatchForm.get('confirm_password').disable();
    this.newPass();
  }

  newPass() {
    this.componentSubscriptions.passMatchFormGetValueChangesPipe$ = this.passMatchForm.get('new_password').valueChanges.pipe(debounceTime(100)).subscribe(newPass => {
      this._validation(newPass);
      if (this.security) {
        this.passMatchForm.get('confirm_password').enable();
        this.confirmPass();
      } else {
        if ( this.passMatchForm.get('confirm_password').value !== newPass ) {
          this.passwordMatch = false;
        }
      }
    });
  }

  confirmPass() {
    this.componentSubscriptions.passMatchFormGetValueChangesPipeConfirmPass$ = this.passMatchForm.get('confirm_password').valueChanges.pipe(debounceTime(100)).subscribe(confirmPass => {
      if ( this.passMatchForm.get('new_password').value === confirmPass ) {
        this.passwordMatch = true;
      } else {
        this.passwordMatch = false;
      }
    });
  }

  logIn() {
    this.router.navigate(['login']);
  }

  reloadPage() {
    window.location.reload();
  }

  recover_password(): void {
    if (this.passwordMatch) {
      this.recoverService.newPassword(this.token, this.uid, this.passMatchForm.get('new_password').value, this.passMatchForm.get('confirm_password').value)
        .then(
          (response) => { this.confirmationSuccess = true; }
        )
        .catch(
          (response) => {
            this.confirmationError = true;

            if (response.error.hasOwnProperty('new_password2')) {
              this.error = response.error.new_password2[0];
            } else if (response.error.hasOwnProperty('uid')) {
              this.error = response.error.uid[0];
            }else if (response.error.hasOwnProperty('token')) {
              this.error = response.error.token[0];
            }
          }
        );
    }
  }

  _validation(pass) {
    const regExpMayus = /(?=\w*[A-Z])/;
    const regExpMinus = /(?=\w*[a-z])/;
    const regExpSymbol = /[,;:_!"·$%&/()=?¿|@#¢∞¬÷“”≠´¡^*`+´ç¨Ç{}\.-]/g;
    const regExpNumber = /[0-9]/;
    const regExpLength = /\S{8}$/;

    this._setValue('charactersLong', regExpLength.test(pass));
    this._setValue('charactersLong', regExpLength.test(pass));
    this._setValue('specialCharacter', regExpSymbol.test(pass));
    this._setValue('oneCapital', regExpMayus.test(pass));
    this._setValue('oneLowercase', regExpMinus.test(pass));
    this._setValue('oneNumber', regExpNumber.test(pass));

    this.security = this.requirements.every(requirement => requirement.status);
  }

  _setValue (name: string, value: boolean) {
    this.requirements.map(e => e.name === name ? e.status = value : '');
  }
}
