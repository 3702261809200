import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, EventEmitter, Output, ElementRef, Input } from '@angular/core';
import { LandingBuilderService } from '../../services/landing-builder-service.service';
import { Constants } from '../../classes/constants';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Landing } from '../../classes/landing';
import { UserService } from '../../services/user-service.service';

@Component({
  selector: 'app-landing-builder',
  templateUrl: './landing-builder.component.html',
  styleUrls: ['./landing-builder.component.scss']
})
export class LandingBuilderComponent implements OnInit {
  _landing: Landing;
  url: SafeResourceUrl;

  @Input() edit? = false;

  @Input() set landing(landing: Landing){
    const jobHasCustomLanding = !landing.id;
    if (!jobHasCustomLanding) {
      this._landing = landing;
      this.edit ? this.getLandingBuilder(landing.id) : this.cloneLandingTemplate();
    }
  }

  @Output() landingChange: EventEmitter<Landing> = new EventEmitter();

  @Input() set save(value) {
    if (value) {
      const token = localStorage.getItem(Constants.JWT);
      const iframeParameters = { type: 'savePage', data: {
        jwt: token,
        user: this.userService.getUser().id,
        title: this._landing.name,
        market: this._landing.isTemplate
      } };
      this.hostElement.nativeElement.querySelector('iframe').contentWindow.postMessage(iframeParameters, '*');
    }
  }

  constructor(
    private landingService: LandingBuilderService,
    private satinizer: DomSanitizer,
    private hostElement: ElementRef,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

  }

  getSatinizeUrl(url: string): void {
    this.url = this.satinizer.bypassSecurityTrustResourceUrl(url);
  }


  cloneLandingTemplate() {
    this.landingService.createLandingFromTemplate(this._landing).then(
      response => {
        this.getLandingBuilder(response.id);
      },
      error => console.log(error)
    );
  }

  getClientFromUrlParams() {
    return this.activatedRoute.snapshot.paramMap.get('client');
  }

  iframeLoaded(e) {
    this.landingChange.emit(this._landing);
  }

  private getLandingBuilder(id: number) {
    const token = localStorage.getItem(Constants.JWT);
    const client_id = this.getClientFromUrlParams();
    const url = Constants.LANDING_BUILDER_URL + '?jwt=' + token + '&group=' + client_id + '&tid=';
    this._landing.url = url + id;
    this._landing.id = id;
    this.getSatinizeUrl(this._landing.url);
    const iframe = this.hostElement.nativeElement.querySelector('iframe') || document.createElement('iframe');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('src', this._landing.url);
    const div = document.getElementById('landing-builder-iframe');
    div.appendChild(iframe);
    this.hostElement.nativeElement.querySelector('iframe').onload = this.iframeLoaded.bind(this);
  }
}
