import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ShortPhasesLabels, PHASES } from '../../classes/phases';
import { ClientService } from '../../partners/services/client.service';
import { Filter } from '../../library/classes/filter';
import { setFilter } from '../../library/utils';
import { StatusFilter, CandidateFilters } from '../../classes/filters';
import { JobCategory } from '../../classes/job';
import { FormControl } from '@angular/forms';
import { Observable, forkJoin, of } from 'rxjs';
import { ProfessionService } from '../../services/profession.service';
import { Tag, TAG_COLORS } from './../../classes/tag';
import { TagsControlService } from './../../tags/services/tags-control.service';
import { COLOR_VARIABLES } from './../../utils/color-variables';


@Component({
  selector: 'app-candidates-filters',
  templateUrl: './candidates-filters.component.html',
  styleUrls: ['./candidates-filters.component.scss']
})
export class CandidatesFiltersComponent implements OnInit, OnDestroy {

  panelColor = new FormControl(COLOR_VARIABLES['$_AUX_GREY_5']);

  style: any = {};
  @Input() templatesFilter: boolean;
  @Input() client: number;
  @Output() appliedFilters = new EventEmitter<Filter[]>();

  templates: any[] = [];
  organizations: Array<any> = [];
  locations: Array<any> = [];
  functions: Array<any> = [];
  professions: Array<any> = [];

  parameters: Filter[] = [];
  filters: CandidateFilters[] = [];

  notInvited: boolean = false;

  lastJobAppliedFrom: Date;
  lastJobAppliedTo: Date;

  phases = ShortPhasesLabels;

  sort = { jobs: false, inscription: false, asc: false, desc: false };
  originStatus = { PRI: false, PUB: false };
  statusList = { PND: false, PASS: false, KO: false };

  loading: boolean = true;

  componentSubscriptions: any = {};
  availableTags: Tag[] = [];

  constructor(
    private clientService: ClientService,
    private professionService: ProfessionService,
    private tagsControlService: TagsControlService
  ) { }

  ngOnInit() {
    this.subscribeToAvailableTags();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  subscribeToAvailableTags() {
    this.componentSubscriptions.availableTags$ = this.tagsControlService.availableTags$.subscribe(
      tags => {
        this.availableTags = tags;
        this.buildFilters();
      }
    );
  }

  buildFilters() {
    this.filters = [];

    const observables: Observable<any>[] = [
      this.templatesFilter ? this.professionService.list(new Filter('group', this.client)) : of<any>([]),
      this.clientService.getJobCategories(this.client)
    ];

    this.componentSubscriptions.forkJoin$ = forkJoin(
      observables, (templates, jobCategories: JobCategory[]) => ([
        templates,
        jobCategories
      ]))
      .subscribe(([templates, jobCategories]) => {
        interface IKeys {
          value: string;
          label: string;
        }

        // Professions
        if (this.templatesFilter) {
          const templatesValues = templates.map(m => <IKeys>{ value: m.id, label: m.name });
          this.filters = this.filters.concat([{ name: 'profession', label: '__jobTemplate', group: 'general', values: templatesValues }]);
        }

        // Job categories
        ({ organizations: this.organizations, locations: this.locations, functions: this.functions, professions: this.professions } =
          this._initJobCategoriesFilter(jobCategories as JobCategory[])
        );

        this.filters = this.filters.concat([
          { name: 'organization', label: '__organization', group: 'general', values: this.organizations },
          { name: 'function', label: '__function', group: 'general', values: this.functions },
          { name: 'location', label: '__location', group: 'general', values: this.locations },
          { name: 'job_profession', label: '__profession', group: 'general', values: this.professions },
        ]);

        // Phases
        const phasesValues: any[] = PHASES.map(p => <IKeys>{
          value: p.code,
          label: p.name
        });

        this.filters = this.filters.concat([{ name: 'phase', label: '__phases', group: 'general', values: phasesValues }]);

        // Candidates Tags

        const tagsFilters: CandidateFilters = {
          name: 'tag',
          label: '__candidateTags',
          group: 'general',
          values: this.availableTags.map(tag => ({ value: tag.id.toString(), label: tag.name }))
        };

        this.filters = this.filters.concat(tagsFilters);

        // Origin
        const originValues = [
          { value: 'true', label: '__employees' },
          { value: 'false', label: '__externalCandidates' }
        ];

        this.filters = this.filters.concat([{ name: 'is_employee', label: '__typeOfCandidate', group: 'general', values: originValues }]);

        // Status
        this.filters = this.filters.concat(StatusFilter);

      });

  }

  _initJobCategoriesFilter(jobCategories: JobCategory[]) {
    interface IKeys {
      value: number;
      label: string
    }
    const organizations = jobCategories.filter(jobCategory => jobCategory.category === 'ORG')
      .map(m => <IKeys>{ value: m.id, label: m.value });
    const locations = jobCategories.filter(jobCategory => jobCategory.category === 'LOC')
      .map(m => <IKeys>{ value: m.id, label: m.value });
    const functions = jobCategories.filter(jobCategory => jobCategory.category === 'FUN')
      .map(m => <IKeys>{ value: m.id, label: m.value });
    const professions = jobCategories.filter(jobCategory => jobCategory.category === 'PRO')
      .map(m => <IKeys>{ value: m.id, label: m.value });
    return { organizations, locations, functions, professions };
  }

  getFilterValue(filter) {
    const filterIsSelected = this.parameters.find(param => param.name === filter.name);
    return filterIsSelected ? filterIsSelected.value : '';
  }

  setCandidateFilter(filter, values) {
    if (values) {
      if (values.indexOf('all') !== -1) {

        this.toggleAllCategories(filter);

        // if (values.length === filter.values.length + 1) {
        //   this.parameters = setFilter(this.parameters, new Filter(filter.name, []));
        // } else {
        //   this.toggleAllCategories(filter);
        // }
      } else {
        this.parameters = setFilter(this.parameters, new Filter(filter.name, values));
      }
    }
  }

  setTagFilterValue(event) {
    event.preventDefault();
    event.stopPropagation();
    event.target.parentElement.previousElementSibling.click();
  }

  toggleAllCategories(filter) {
    if (this.parameters.filter(f => f.name === filter.name).length == 0) {
      this.selectAll(filter);
    } else {
      this.parameters.forEach(f => {
        if (f.name === filter.name) {
          if (f.getFilterValues()
            .length == filter.values.length) {
            return f.value = [];
          } else {
            this.selectAll(filter);
          }
        }
      })
    }
  }

  selectAll(filter) {
    this.parameters = setFilter(this.parameters, new Filter(filter.name, filter.values.map(val => val.value)));
  }

  sortBy(filter) {
    this.sort[filter] = !this.sort[filter];
    if (filter === 'asc' && this.sort[filter]) {
      this.sort.desc = false;
    } else if (filter === 'desc' && this.sort[filter]) {
      this.sort.asc = false;
    }
  }

  filterCandidatesOrigin(value: string) {

    if (this.originStatus[value]) {
      this.originStatus[value] = false;
      return;
    }

    Object.keys(this.originStatus).forEach(k => {
      this.originStatus[k] = false;
    });

    this.originStatus[value] = !this.originStatus[value];

  }

  filterCandidateStatus(value: string) {
    this.statusList[value] = !this.statusList[value];
  }

  applyFilters() {

    this.createOriginFilter();
    this.createStatusFilter();
    this.createDateFilter();
    this.createOrderingFilter();

    this.appliedFilters.emit(this.parameters);

  }

  createOriginFilter() {

    let origin;

    Object.keys(this.originStatus).forEach(k => {
      if (this.originStatus[k]) {
        origin = k;
      }
    });

    if (origin) {
      this.parameters = setFilter(this.parameters, new Filter('is_employee', [origin]));
    }

  }

  createNotInvitedFilter() {
    if (this.notInvited) {
      this.parameters = setFilter(this.parameters, new Filter('not_invited', 'true'));
    }
  }

  createStatusFilter() {

    let status = [];

    if (this.statusList.PASS) {
      status = status.concat('PASS');
    }
    if (this.statusList.PND) {
      status = status.concat('PND')
    }
    if (this.statusList.KO) {
      status = status.concat('KO');
    }

    this.parameters = setFilter(this.parameters, new Filter('status', status));
  }

  createDateFilter() {
    if (this.lastJobAppliedFrom != null) {
      let dateFrom = this.lastJobAppliedFrom.toString()
        .split('-');
      let formattedDateFrom = new Date(Number(dateFrom[0]), Number(dateFrom[1]) - 1, Number(dateFrom[2]));
      this.parameters = setFilter(this.parameters, new Filter('inscription_date_from', formattedDateFrom.toISOString()));
    }
    if (this.lastJobAppliedTo != null) {
      let dateTo = this.lastJobAppliedTo.toString().split('-');
      let formattedDateTo = new Date(Number(dateTo[0]), Number(dateTo[1]), Number(dateTo[2]));
      this.parameters = setFilter(this.parameters, new Filter('inscription_date_to', formattedDateTo.toISOString()));
    }
  }

  createOrderingFilter() {

    let ordering = [];

    if (this.sort.jobs) {
      ordering = ordering.concat('-jobs');
    }
    if (this.sort.inscription) {
      ordering = ordering.concat('-inscription')
    }
    if (this.sort.asc) {
      ordering = ordering.concat('first_name');
    } else if (this.sort.desc) {
      ordering = ordering.concat('-first_name');
    }

    this.parameters = setFilter(this.parameters, new Filter('ordering', ordering));
  }

  resetFilters() {
    this.parameters = [];
    this.notInvited = false;
    this.statusList = { PND: false, PASS: false, KO: false };
    this.sort = { jobs: false, inscription: false, asc: false, desc: false };
    this.lastJobAppliedFrom = null;
    this.lastJobAppliedTo = null;
    this.originStatus = { PRI: false, PUB: false };
    this.appliedFilters.emit(this.parameters);
  }

  getTagColor(tagId: number): TAG_COLORS {
    return this.availableTags.find(tag => tag.id === tagId).color;
  }
}
