import { INSTRUMENT_URL } from './../instruments/constants';
import { FormTemplate } from './../classes/form';
import { buildFilter } from '../library/utils';
import { Constants } from '../classes/constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from './../library/classes/filter';
import { RestService } from '../library/classes/rest-service';
import { Instrument } from '../instruments/models/instrument';

@Injectable()
export class InstrumentService extends RestService<Instrument> {

  endpoint = INSTRUMENT_URL;

  getVidassessUrl(vidassessId: number): Observable<any> {
    const url = `${Constants.BASE_API}instrument-result/${vidassessId}/get-url-to-rate/`;
    return this.httpClient.get(url);
  }

}
