import { Filter } from './../library/classes/filter';
import { Constants } from '../classes/constants';
import { buildFilter } from '../library/utils';
import { Candidate, CandidateAppliedJob } from './../classes/candidate';
import { Observable, merge } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

@Injectable()
export class CandidateService {

  constructor(private httpClient: HttpClient) { }

  getCandidatesFromJob(id: string, pageSize: number, ...filter: Filter[]): Observable<HttpResponse<any>> {
    const query = buildFilter(...filter);
    // const headers = new HttpHeaders({ 'Size': pageSize.toString() },);
    const url = Constants.JOB_CANDIDATE_URL.replace('{id}', id) + query;
    return this.httpClient.get<Candidate[]>(url, { observe: 'response' });
  }

  getAllCandidates(id: string, ...filter: Filter[]): Observable<HttpResponse<any>> {
    const query = buildFilter(...filter);
    const url = Constants.JOB_ALL_CANDIDATES_URL.replace('{id}', id) + query;
    return this.httpClient.get<Candidate[]>(url, { observe: 'response' });
  }

  getCandidatesFromGroup(...filter: Filter[]): Observable<HttpResponse<any>> {
    const query = buildFilter(...filter);
    const url = `${Constants.ALL_CANDIDATES_URL}${query}`;
    return this.httpClient.get<Candidate[]>(url, { observe: 'response' });
  }

  getCandidatesFromViewer(group_id: number): Observable<Candidate[]> {
    const url = Constants.CANIDATES_VIEWERS_URL.replace('{id}', group_id + '');
    return this.httpClient.get<Candidate[]>(url);
  }

  getCandidateUrl(candidate_id: string) {
    const url = `${Constants.CANDIDATE_SHARE.replace('{id}', candidate_id)}`;
    return this.httpClient.get<{ token; }>(url);
  }

  getCandidate(id: string): Observable<Candidate> {
    const url = `${Constants.CANDIDATE_URL.replace('{uuid}', id)}`;
    return this.httpClient.get<Candidate>(url);
  }

  getCandidateTimeline(candidate_id: string): Observable<any[]> {
    const url = `${Constants.CANDIDATE_TIMELINE_URL.replace('{id}', candidate_id)}`;
    return this.httpClient.get<any[]>(url);
  }

  getCandidatePublicTimeline(token: string): Observable<any[]> {
    const url = `${Constants.CANDIDATE_PUBLIC_TIMELINE_URL}${token}/`;
    return this.httpClient.get<any[]>(url);
  }

  getClientCandidate(id: string): Observable<Candidate> {
    const url = `${Constants.ALL_CANDIDATES_URL}${id}/`;
    return this.httpClient.get<Candidate>(url);
  }

  getCandidateAppliedJobs(id: string): Observable<CandidateAppliedJob[]> {
    const url = `${Constants.CANDIDATE_APPLIED_JOBS.replace('{id}', id)}`;
    return this.httpClient.get<CandidateAppliedJob[]>(url);
  }

  getCandidatesFilters(job_id: string): Observable<any> {
    const url = `${Constants.JOB_CANDIDATES_FILTERS.replace('{id}', job_id)}`;
    return this.httpClient.get<any>(url);
  }

  moveCandidate(candidate: Candidate): Observable<Array<any>> {
    const url = `${Constants.JOB_PASS_CANDIDATE_URL.replace('{id}', candidate.job.toString())}`;
    return this.httpClient.post<any>(url, { 'candidates': candidate.id });
  }

  moveCandidates(candidates: number[], job: number): Observable<Array<any>> {
    const url = `${Constants.JOB_PASS_CANDIDATE_URL.replace('{id}', job.toString())}`;
    return this.httpClient.post<any>(url, { 'candidates': candidates });
  }

  changeStatusAndTransition(candidate: Candidate, transitions: string, status: string): Observable<{ status: string, transition: string; }> {
    const url = `${Constants.CANDIDATE_CHANGE_STATUS_URL.replace('{uuid}', candidate.uuid)}`;
    return this.httpClient.post<any>(url, { 'transition': transitions, 'status': status });
  }

  changeCandidatesStatusAndTransition(status: string, transitions: string, ...candidates: Candidate[]): Observable<any> {
    const candidates$ = candidates.map(candidate => this.changeStatusAndTransition(candidate, transitions, status));
    return merge(...candidates$);
  }

  changeStatus(candidate: Candidate, status): Observable<{ status: string, transition: string; }> {
    const url = `${Constants.CANDIDATE_CHANGE_STATUS_URL.replace('{uuid}', candidate.uuid)}`;
    return this.httpClient.post<any>(url, { 'status': status });
  }

  changeCandidatesStatus(status: string, ...candidates: Candidate[]): Observable<any> {
    const candidates$ = candidates.map(candidate => this.changeStatus(candidate, status));
    return merge(...candidates$);
  }

  addComment(candidate: Candidate, comment): Observable<any> {
    const url = `${Constants.CANDIDATE_ADD_COMMENT.replace('{uuid}', candidate.uuid)}`;
    return this.httpClient.post<Candidate>(url, comment);
  }

  editComment(id: number, comment): Observable<any> {
    const url = `${Constants.COMMENT.replace('{id}', id.toString())}`;
    return this.httpClient.patch<any>(url, comment);
  }

  deleteComment(id: number): Observable<any> {
    const url = `${Constants.COMMENT.replace('{id}', id.toString())}`;
    return this.httpClient.delete<any>(url);
  }

  sendEmail(candidate: Candidate, email: { subject: string, content: string; }): Observable<any> {
    const url = `${Constants.CANDIDATE_SEND_EMAIL.replace('{uuid}', candidate.uuid)}`;
    return this.httpClient.post<Candidate>(url, email);
  }

  sendPoolEmail(candidate: Candidate, email: { subject: string, content: string; }): Observable<any> {
    const url = `${Constants.CANDIDATE_SEND_POOL_EMAIL.replace('{id}', candidate.id)}`;
    return this.httpClient.post<Candidate>(url, email);
  }

  saveInterview(candidate: Candidate, interview_slug: string, interview: any) {
    const url = `${Constants.CANDIDATE_EVALUATE_INTERVIEW.replace('{uuid}', candidate.uuid).replace('{iws}', interview_slug)}`;
    return this.httpClient.post<Candidate>(url, interview);
  }

  shareCandidate(candidate_id: string): Observable<{ token; }> {
    const url = `${Constants.CANDIDATE_SHARE.replace('{id}', candidate_id)}`;
    return this.httpClient.get<{ token; }>(url);
  }

  getPubliClient(token: string): Observable<Candidate> {
    const url = Constants.CANDIDATE_PUBLIC.replace('{token}', token);
    return this.httpClient.get<Candidate>(url)
      .pipe(take(1));
  }

  pathToPublicCandidate(token: string): string {
    return `${window.location.origin}/#/public-candidate/${token}/`;
  }

  getCandidateHub(uuid: string) {
    const url = Constants.CANDIDATE_HUB.replace('{uuid}', uuid);
    return this.httpClient.get<any>(url);
  }

  submitFile(file: FormData): Observable<any> {
    return this.httpClient.post<any>(Constants.SUBMIT_FILE, file);
  }

  rateCandidate(candidate_uuid, rate): Observable<any> {
    return this.httpClient.post<any>(`${Constants.RATE_CANDIDATE}${candidate_uuid}/`, { rate });
  }

  getCandidateTask(candidate: Candidate): Observable<any> {
    return this.httpClient.get<any>(`${Constants.CANDIDATE_TASK.replace('{uuid}', candidate.uuid)}`);
  }

  addTask(candidate: Candidate, task: string, data: string): Observable<any> {
    return this.httpClient.post<any>(`${Constants.CANDIDATE_TASK.replace('{uuid}', candidate.uuid)}${task}/`, data);
  }

  updateTask(candidate: Candidate, task: string, data: string): Observable<any> {
    return this.httpClient.put<any>(`${Constants.CANDIDATE_TASK.replace('{uuid}', candidate.uuid)}${task}/`, data);
  }

  removeTask(candidate: Candidate, task_type: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${Constants.CANDIDATE_TASK.replace('{uuid}', candidate.uuid)}${task_type}/`,
    );
  }

  getAllCandidateViewers(candidate: Candidate) {
    return this.httpClient.get<any>(`${Constants.ALL_VIEWERS_CANDIDATES.replace('{uuid}', candidate.uuid)}`);
  }

  addUsersToCandidate(candidate: Candidate, users: string[]) {
    return this.httpClient.post<any>(`${Constants.CREATE_VIEWERS_CANDIDATES.replace('{uuid}', candidate.uuid)}/`, users);
  }

  deleteUsersForCandidate(candidate: Candidate, email: string[]) {
    return this.httpClient.post<any>(`${Constants.UNASSIGN_VIEWER_TO_CANDIDATE.replace('{uuid}', candidate.uuid)}/`, email);
  }

  getAssignedViewers(candidate: Candidate) {
    return this.httpClient.get<any>(`${Constants.ASSIGNED_VIEWERS_TO_CANDIDATE.replace('{uuid}', candidate.uuid)}`);
  }

  checkIfCandidateCancollectGDPRConsent(uuid: string) {
    return this.httpClient.get<any>(`${Constants.COLLECT_GDPR_CONSENT.replace('{uuid}', uuid)}`);
  }

  collectGdprConsent(uuid: string, option: boolean) {
    return this.httpClient.post<any>(`${Constants.COLLECT_GDPR_CONSENT.replace('{uuid}', uuid)}`, { 'option': option });
  }

  getImageAsBase64(ImageUrl: string): Observable<any> {
    const url = Constants.BASE64_IMAGE;
    return this.httpClient.post<string>(url, { url: ImageUrl });
  }
}
