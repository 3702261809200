import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

// Components
import { UploadImgComponent } from './upload-img/upload-img.component';
import { WsgieditorEmailsComponent } from './wsgieditor-emails/wsgieditor-emails.component';
import { MultipostingComponent } from './multiposting/multiposting.component';
import { MultipostingShareComponent } from './multiposting/multiposting-share/multiposting-share.component';

// Modules
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { MaterialModule } from '../material.module';
import { CKEditorModule } from 'ng2-ckeditor';
import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { LineChartDatesComponent } from './line-chart-dates/line-chart-dates.component';
import { BounceRateItemComponent } from './bounce-rate-item/bounce-rate-item.component';
import { CampaignItemComponent } from './campaign-item/campaign-item.component';
import { NoDataBoxComponent } from './no-data-box/no-data-box.component';
import { SelectSearchInputComponent } from './select-search-input/select-search-input.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { BasicLineChartComponent } from './basic-line-chart/basic-line-chart.component';
import { MultipostingPostComponent } from './multiposting/multiposting-post/multiposting-post.component';
import { CustomFormRendererComponent } from './custom-form-renderer/custom-form-renderer.component';


@NgModule({
  declarations: [
    UploadImgComponent,
    WsgieditorEmailsComponent,
    MultipostingComponent,
    MultipostingShareComponent,
    RadarChartComponent,
    ColumnChartComponent,
    BounceRateItemComponent,
    CampaignItemComponent,
    LineChartDatesComponent,
    NoDataBoxComponent,
    SelectSearchInputComponent,
    BasicLineChartComponent,
    CustomFormRendererComponent,
    MultipostingPostComponent
  ],
  exports: [
    UploadImgComponent,
    WsgieditorEmailsComponent,
    MultipostingComponent,
    MultipostingShareComponent,
    CKEditorModule,
    RadarChartComponent,
    ColumnChartComponent,
    BounceRateItemComponent,
    CampaignItemComponent,
    LineChartDatesComponent,
    NoDataBoxComponent,
    SelectSearchInputComponent,
    BasicLineChartComponent,
    CustomFormRendererComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MaterialModule,
    CKEditorModule,
    ClickOutsideModule,
    RouterModule
  ]
})

export class ComponentsModule { }
