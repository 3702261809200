import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user-service.service';

@Injectable()
export class LoginGuardService implements CanActivate {

  constructor(public auth: UserService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable < boolean > | Promise < boolean > {
    return this.goToMyPath();
  }

  goToMyPath(state = ''): boolean {
    if (this.auth.checkIsLogged()) {
      const mypath = this.auth.getMyInitialPath();
      this.router.navigate([mypath]);
      return false;
    }
    return true;
  }


}
