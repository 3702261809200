import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FormRenderService {

  private enabledSaveInterview = true;
  private enabledSaveInterview$ = new BehaviorSubject<boolean>(this.enabledSaveInterview);

  setEnabledSaveInterview(enabledSaveInterview: boolean) {
    this.enabledSaveInterview$.next(enabledSaveInterview);
  }

  getEnabledSaveInterview() {
    return this.enabledSaveInterview$.asObservable();
  }

  constructor() { }
}
