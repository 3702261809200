import { Component, OnInit, Input, ElementRef, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { COLOR_VARIABLES } from './../../utils/color-variables';
import { FormatTimePipe } from '../../pipes/format-time.pipe';
declare const Highcharts: any;
let globalFormatTimePipe = null;

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  providers: [FormatTimePipe]

})
export class DonutChartComponent implements OnChanges {


  // @Input() data: any;
  _data: any = [];
  colors = [COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT'], COLOR_VARIABLES['$_CHART_MEDIUM_BLUE'], COLOR_VARIABLES['$_CHART_ORANGE'], COLOR_VARIABLES['$_CHART_LIGTH_GREEN'], COLOR_VARIABLES['$_AUX_PURPLE_30'], COLOR_VARIABLES['$_CHART_LIGTH_BLUE']];

  @Input() set data(data) {

    this._data = data.reduce((parsedData, value, index) => {
      return parsedData.concat({ data: value, color: this.colors[index] });
    }, []);
  }

  @Input() labels: string[];
  @Input() name: string;
  @Input() size: { width: number, height: number };
  @Input() total = 0;
  @Input() isCustom: Boolean = true;
  @Input() labelCenterDonut = null;
  @Input() displayPercentage: Boolean = true;

  @Input() customConfig: any = {};

  self: any = null;

  parsedData: { data: number, color: string }[];
  config = {
    chart: {
      reflow: true,
      animation: false,
      type: 'pie',
      backgroundColor: null,
      events: {
        load: function () {
          this.reflow();
        }
      }
    },
    colors: [COLOR_VARIABLES['$_CHART_PURPLE'], COLOR_VARIABLES['$_CHART_GREYISH_LIGHT_BLUE'], COLOR_VARIABLES['$_CHART_LIGHT_PURPLE'], COLOR_VARIABLES['$_CHART_DARK_PURPLE'], COLOR_VARIABLES['$_CHART_LIGTH_GREEN']],
    title: {
      text: '',
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: '{point.y}',
      formatter: null
    },
    plotOptions: {
      pie: {
        size: '100%',
        innerSize: '70%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true,
        center: ['50%', '50%'],
        shadow: false,
      }
    },
    legend: {
      layout: 'vertical',
      useHTML: true,
      symbolWidth: 10,
      symbolHeight: 10,
      labelFormatter: function () {
        return `<span>${this.name}</span>`;
      },
    },
    series: [],

  };

  constructor(private hostElement: ElementRef, private translateService: TranslateService, private formatTimePipe: FormatTimePipe) {

    this.self = this;

    globalFormatTimePipe = this.formatTimePipe;

    this.parsedData = [
      {
        data: 300,
        color: COLOR_VARIABLES['$_PRIMARY_COLOR_LIGHT'],
      },
      {
        data: 50,
        color: COLOR_VARIABLES['$_CHART_ORANGE'],
      },
      {
        data: 100,
        color: COLOR_VARIABLES['$_CHART_MEDIUM_BLUE'],
      }
    ];

    this.labels = [
      'label 1',
      'label'
    ];

  }

  ngOnChanges() {

    const pieChartElement = this.hostElement.nativeElement.querySelector('.pie-chart__content');

    if (this._data.length) {

      this._setData();

      Highcharts.chart(this.hostElement.nativeElement.querySelector('.pie-chart__content'), this.config,
        chart => {

          const insideTheDonutText = document.createElement('div');
          insideTheDonutText.className = 'donut-text';
          insideTheDonutText.innerHTML = `<span class="donut-total">${this.total}</span> <span class="donut-subtext">${this.translateService.instant(this.labelCenterDonut)}</span>`;

          const insideTheDonutContainer = document.createElement('div');
          insideTheDonutContainer.className = 'donut-text-container';
          insideTheDonutContainer.style['width'] = (chart.plotWidth) + 'px';
          insideTheDonutContainer.style['top'] = ((chart.plotHeight / 2) - 40) + 'px';

          insideTheDonutContainer.appendChild(insideTheDonutText);

          pieChartElement.appendChild(insideTheDonutContainer);

        });
    }
  }

  parseData() {

  }

  _setData() {
    const data = this._data.map((value, index) => new Object({ name: this.labels[index], y: value.data, color: value.color || '' }));

    this.config.series = [{ name: this.name, colorByPoint: true, data: data, type: 'pie' }];

    if (this.customConfig) {
      Object.keys(this.customConfig).forEach(configKey => {
        this.config[configKey] = Object.assign(this.config[configKey], this.customConfig[configKey]);
      });
    }

    if (this.size) {
      this.config.chart['height'] = this.size.height;
      this.config.chart['width'] = this.size.width;
    }

    if (this.isCustom) {
      if (this.displayPercentage) {
        this.config.legend.labelFormatter = function () {
          return `
          <div class="legend-element">
            <span class="legend-name">${this.name}</span>
            <span class="legend-value">${this.percentage.toFixed(0)}%</span>
          </div>
          `;
        };
      } else {
        this.config.legend.labelFormatter = function () {
          return `
          <div style="display: flex; justify-content: space-between; align-items: flex-start; min-width: 300px; margin-bottom: 20px;">
            <span style="margin: 0 15px; font-weight: 300; color: lightgrey; font-size: 14px; text-transform: capitalize;">${this.name}</span>
            <span style="font-size: 16px; font-family: "Roboto";">${globalFormatTimePipe.transformToDays(this.y)}</span>
          </div>
          `;
        };

        this.config.tooltip.formatter = function () {
          return this.key + ': ' + globalFormatTimePipe.transformToDays(this.y);
        };
      }

    }
  }


}
