import { Constants } from '../classes/constants';

export const INSTRUMENT_URL: string = Constants.BASE_API + 'instrument/';
export const INSTRUMENT_ORIGIN_URL: string = Constants.BASE_API + 'instrument-origin/';
export const FORM_TEMPLATES: string = Constants.BASE_API + 'form_templates/';
export const CANIDATE_INSTRUMENT_FORM: string = Constants.BASE_API + 'instruments/form/hook/';


export enum INSTRUMENT_TYPES {
    TKT = 'disney',
    FORMS = 'form',
    CUTE = 'cute',
    VIDASSESS = 'vidassess',
    VIDASSESS_AI = 'ai',
    VIDEOASK = 'videoask'
}

export const intrumentsOrder = [102, 108, 315, 344, 327, 301, 302, 366];

export const tktInstruments: any[] = [{
    id: 1000,
    name: 'Bot Recruiter',
    origin: 10,
    selected: false,
    duration: 10,
    description: 'A bot that speeds up the data collection of candidates and will guide users at all times.',
    weight: 0,
    appearance: {
      color: 'bot',
      icon: '',
      long_description: 'The chat bot is a software that simulates having a conversation with the candidates so you can easily collect all the necessary data for candidate’s registration in your recruitment processes. The bot will ask the necessary questions to get all the information. These questions are previously defined and are fully customizable, from personal data to information related to work experience. Ask us about Bot Recruiter and we will elaborate a personalized bot for your selection process.',
    },
    media: [
      { type: 'video', url: 'https://s3-eu-west-1.amazonaws.com/aplygo-saas/media/bot-recruiter.mp4' }
    ],
  },
  {
    id: 1001,
    name: 'Around the World',
    origin: 10,
    selected: false,
    duration: 10,
    description: 'Evaluate candidates in a simulated work environment and analyze their behavior in a real situation.',
    weight: 0,
    appearance: {
      color: 'day-trip',
      long_description: 'Around The World is a digital role-playing test where candidates interact in the work environment. The candidate will have to take decisions about his team for approximately 25 minutes and will perform the interactions that he/she considers necessary to understand the environment. After that, they will have 15 minutes to provide conclusions.<br><br>This test is useful to analyze which competences are stronger in the candidate: Socializer, Explorer, Thinker and Achiever. Ask for information now and discover the perfect candidate for the position.',
    },
    media: [
      { type: 'image', url: 'https://s3-eu-west-1.amazonaws.com/aplygo-saas/media/around-the-world.png' }
    ],
  },
  {
    id: 1003,
    name: 'Video Assessment',
    origin: 10,
    selected: false,
    duration: 10,
    description: 'Interview your candidates at any time through personalized video interviews.',
    weight: 0,
    appearance: {
      color: 'video',
      long_description: 'The video assessment test allows you to evaluate the candidates through videos that can be saved so you can access at all times. The time of the test is unlimited and the questions can be set freely with the aim of evaluating those competences that are considered most appropriate. Request information now and create your test quickly.',
    },
    media: [
      { type: 'image', url: ' https://s3-eu-west-1.amazonaws.com/aplygo-saas/media/video-assessment-pantalla-entrevista.jpg' }
    ],
  },
  {
    id: 1005,
    name: 'Escape Room AR',
    origin: 10,
    selected: false,
    duration: 40,
    description: 'Turn your recruitment process into a unique adventure and improve your employer branding.',
    weight: 0,
    appearance: {
      color: 'escape-room',
      long_description: 'The escape room AR is an evaluation test presented in the form of an adventure game in which candidates must solve puzzles and riddles in a collaborative way. You can adapt the settings between the different options available or create your own.<br><br>It is a 40 minutes dynamic through which the previously defined competences will be evaluated. Request an escape room AR for your recruitment process and find the right candidates.',
    },
    media: [
      { type: 'video', url: 'https://s3-eu-west-1.amazonaws.com/aplygo-saas/media/escaperoom.mp4' }
    ],
  },
  {
    id: 1006,
    name: 'Último Descenso',
    origin: 10,
    selected: false,
    duration: 15,
    description: 'Group dynamics in which different competences can be evaluated depending on the position to be covered.',
    weight: 0,
    appearance: {
      color: 'descenso',
      icon: '../../../assets/instruments/instruments-descenso.png',
      long_description: 'Último Descenso is a group dynamic where the candidates will have to gather information that will help them prepare the group meeting they will face after 30 minutes. Once this time has elapsed, they will have 45 minutes to achieve the goals that are proposed.',
    },
    media: [
      { type: 'image', url: 'https://s3-eu-west-1.amazonaws.com/aplygo-saas/media/ultimo-descenso-2.png' }
    ],
  },
];
