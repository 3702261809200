import { Doc } from './../../classes/file';
import { Constants } from '../../classes/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input, ElementRef } from '@angular/core';

declare const $: any;
@Component({
  selector: 'app-doc-render',
  templateUrl: './doc-render.component.html',
  styleUrls: ['./doc-render.component.scss']
})
export class DocRenderComponent implements OnInit {


  fileUrl: string = null;

  @Input() file: Doc;
  @Input() set url(value: string) {
    this.loading = true;
    this.fileUrl = value ? this.getFileUrl(value) : null;
  }

  loading = false;
  sanitizeUrl: any;
  constructor(
    public sanitizer: DomSanitizer,
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {

  }


  getFileUrl(url: string): string {
    let completeUrl = url;
    if (url && url.indexOf('http') === -1 && url.indexOf('api/v1/') === -1) {
      completeUrl = `${Constants.FILE_URL}${url}`;
    } else if (url && url.indexOf('http') === -1) {
      completeUrl = `${Constants.BASE_URL.slice(0, -1)}${url}`;
    }
    return completeUrl;
  }

  reloadCvObject() {
    // old method to append pdf render
    const objectContainer = this.elementRef.nativeElement.querySelector('.doc-render__content');
    objectContainer.innerHTML = '';

    if (this.fileUrl && this.isNotWord()) {
      const objectElement = document.createElement('object');
      objectElement.setAttribute('data', this.getSanitizedUrl()
        .changingThisBreaksApplicationSecurity);
      $(objectContainer).append(objectElement);
      this.loading = false;
    }
  }

  getSanitizedUrl(): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
  }

  isNotWord() {
    return Boolean(this.file
      && this.file.extension !== 'doc'
      && this.file.extension !== 'docx'
      && this.file.extension !== 'odt'
      && this.file.extension !== null);
  }


}
