import { AlertsService } from './../../services/alerts.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Candidate } from '../../classes/candidate';
import { CandidateService } from '../../services/candidate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { deleteFilter, setFilter } from '../../library/utils';
import { Filter } from '../../library/classes/filter';
import { ProfessionService } from '../../services/profession.service';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user-service.service';

@Component({
  selector: 'app-all-candidates-table',
  templateUrl: './all-candidates-table.component.html',
  styleUrls: ['./all-candidates-table.component.scss'],
  animations: [
    trigger('openDetail', [
      state('true', style({
        transform: 'translateX(0%)'
      })),
      state('false', style({
        transform: 'translateX(100%)'
      })),
      transition('true => false', animate('200ms ease-in')),
      transition('false => true', animate('200ms ease-in'))
    ])
  ]
})
export class AllCandidatesTableComponent implements OnInit, OnDestroy {
  ks: string;

  group_id: string;
  template_id: string;
  parameters: Filter[] = [];
  professionName: string = '';
  jobsCount: number = 0;
  elements: { candidates: Candidate[], headers: string[] } = {
    candidates: [],
    headers: []
  };
  total: number;

  colors = ['orange', 'green', 'blue', 'pink', 'turquoise', 'purple'];
  selectedRow;
  header_sorted = {
    header: '',
    order: 0
  };

  @ViewChild('searchCandidate', { static: true }) searchCandidate;
  searchTerm = new Subject<string>();

  candidateSelected: boolean;
  selectedCandidateData: Candidate;

  client: Client = new Client();

  componentSubscriptions: any = {};

  selectedCandidates: Candidate[] = [];

  extendedSearch = false;
  search = null;
  searching = false;

  constructor(
    private candidateService: CandidateService,
    private professionService: ProfessionService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private router: Router,
    private keyboardShortcutService: KeyboardShortcutService,
    private clientService: ClientService,
    private translateService: TranslateService,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.getClient();
    this.getCandidates();
    this.doSearch();
    this.subscribeToCandidateParam();
    this.componentSubscriptions.keyboardShortcutServiceCommands$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  getClient() {

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(+this.activatedRoute.parent.snapshot.paramMap.get('client'))
      .subscribe(
        response => this.client = response,
        error => console.log(error)
      );
  }

  getCandidates() {
    this.componentSubscriptions.activatedRouteParamMapPipe$ = this.activatedRoute.paramMap.pipe(switchMap(params => {
      this.group_id = params.get('client');
      this.template_id = params.get('template');

      if (this.template_id != 'undefined' && this.template_id != null) {
        this.parameters = setFilter(this.parameters, new Filter('profession', this.template_id))
      }
      this.parameters = setFilter(this.parameters, new Filter('group', this.group_id));

      return this.candidateService.getCandidatesFromGroup(...this.parameters);
    }))
      .subscribe(
        candidates => {
          this.saveCandidatesToTable(candidates)
        });
  }

  saveCandidatesToTable(candidates: HttpResponse<Candidate[]>) {
    this.total = +candidates.headers.get('count');
    this.elements.candidates = candidates.body;
    this.elements.headers = ['created', 'identifier'];

    const groupProfessions = this.professionService.getLocalProfessionsFromGroup();
    this.jobsCount = groupProfessions.total_jobs;

    if (this.template_id != 'undefined' && this.template_id != null) {

      groupProfessions.professions.filter(p => {
        if (p.profession.id == parseInt(this.template_id)) {
          this.professionName = p.profession.name;
          this.jobsCount = p.total_jobs;
        }
      });

    }
  }

  subscribeToCandidateParam() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        this.candidateSelected = Boolean(queryParam.get('candidate'));
        if (this.candidateSelected) {
          this.componentSubscriptions.candidateServiceGetClientCandidate$ = this.candidateService.getClientCandidate(queryParam.get('candidate'))
            .
            subscribe(
              response => {
                this.selectedCandidateData = Object.assign(new Candidate(), response);
                this.selectedCandidateData.id = queryParam.get('candidate');
              },
              error => this.alertService.setAlert({ type: 'error', message: this.translateService.instant('__errorWithCandidate') })
            )
        } else { this.selectedRow = null; }
      }
    );
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'AllCandidatesTableComponent.ShiftDown':
        this.goToFirstCandidate();
        break;
      case 'AllCandidatesTableComponent.Down':
        this.goToNextCandidate();
        break;
      case 'AllCandidatesTableComponent.Up':
        this.goToPreviousCandidate();
        break;
      case 'AllCandidatesTableComponent.ShiftF':
        this.goToSearch();
        break;
    }
  }

  goToFirstCandidate() {
    const url_splitted = this.router.url.split('?');
    if (!url_splitted[1] && this.elements.candidates.length) {
      this.selectedRow = +this.elements.candidates[0].id;
      this.router.navigate([url_splitted[0]], { queryParams: { candidate: this.elements.candidates[0].id } });
    }
  }

  goToNextCandidate() {
    if (this.candidateSelected) {
      let candidateIndex = this.elements.candidates.findIndex(candidate => +candidate.id === this.selectedRow)
      if (++candidateIndex < this.elements.candidates.length) {
        this.selectedRow = +this.elements.candidates[candidateIndex].id;
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: { candidate: this.elements.candidates[candidateIndex].id } });
      } else {
        --candidateIndex
      }
    }
  }

  goToPreviousCandidate() {
    if (this.candidateSelected) {
      let candidateIndex = this.elements.candidates.findIndex(candidate => +candidate.id === this.selectedRow)
      if (candidateIndex > 0) {
        this.selectedRow = +this.elements.candidates[--candidateIndex].id;
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: { candidate: this.elements.candidates[candidateIndex].id } });
      }
    }
  }

  goToSearch() {
    setTimeout(() => {
      this.searchCandidate.nativeElement.focus()
    }, 100);
  }

  sort(header) {
    this.header_sorted.header = header;
    this.header_sorted.order = this.header_sorted.order <= 0 ? this.header_sorted.order + 1 : -1;
    const order = this._getSortedOrder();
    this.parameters = setFilter(this.parameters, new Filter('ordering', order));
    this.subscriptionCandidateServiceGetCandidatesFromGroup();
  }

  sorted(header) {
    return this.header_sorted.header === header && this.header_sorted.order > 0;
  }

  sortedInverse(header) {
    return this.header_sorted.header === header && this.header_sorted.order < 0;
  }

  _getSortedOrder(): string {
    let order = '';
    order += this.header_sorted.order < 0 ? '-' : '';
    order += this.header_sorted.order === 0 ? '' : this.header_sorted.header;
    return order;
  }

  subscriptionCandidateServiceGetCandidatesFromGroup() {
    this.componentSubscriptions.candidateServiceGetCandidatesFromGroup$ = this.candidateService.getCandidatesFromGroup(...this.parameters)
      .subscribe(
        candidates => this.saveCandidatesToTable(candidates)
      );
  }

  changePage(event: { page, itemsPerPage }) {

    this.parameters = setFilter(this.parameters, new Filter('page', event.page));
    this.subscriptionCandidateServiceGetCandidatesFromGroup();
  }

  extendedSearchChange(event: boolean) {
    this.extendedSearch = event;
    this.doSearch();
    this.searchTerm.next(this.search);
  }

  doSearch(): void {
    this.componentSubscriptions.searchTermPipe$ = this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((search: any) => {
          this.search = search;
          this.searching = true;
          if (this.extendedSearch) {
            this.parameters = deleteFilter(this.parameters, 'search');
            this.parameters = setFilter(this.parameters, new Filter('extended_search', search));
          } else {
            this.parameters = deleteFilter(this.parameters, 'extended_search');
            this.parameters = setFilter(this.parameters, new Filter('search', search));
          }
          return this.candidateService.getCandidatesFromGroup(...this.parameters);
        }))
      .subscribe(
        candidates => {
          this.searching = false;
          this.saveCandidatesToTable(candidates);
        }
      );
  }

  filter(parameters: Filter[]) {
    parameters.forEach(p => {
      this.parameters = setFilter(this.parameters, p);
    });
    this.getCandidates();
  }

  getColors(candidate: any) {
    const colLength = this.colors.length;
    if (colLength > 0 && candidate.email) {
      const index = candidate.email[0].charCodeAt(0) % this.colors.length;
      return this.colors[index];
    }
  }

  getCandidateInitials(candidate) {
    if (candidate.first_name && candidate.last_name) {
      return `${candidate.first_name[0]}${candidate.last_name[0]}`;
    }
    return `${candidate.email[0]}${candidate.email[1]}`;
  }

  onCandidateAction({ candidate, event }: { candidate: Candidate, event: String }) {
    this.getCandidates();
  }

  checkCandidate(candidate: Candidate) {
    if (this.isChecked(candidate)) {
      this.selectedCandidates.splice(
        this.selectedCandidates
          .map(candidateId => candidateId.id)
          .indexOf(candidate.id), 1);
    } else {
      this.selectedCandidates.push(candidate);
    }
  }

  isChecked(candidate: Candidate) {
    return this.selectedCandidates.map(cand => cand.id)
      .includes(candidate.id);
  }

  checkAllCandidates() {
    const checkCandidates = this.areAllChecked() ? this.elements.candidates : this.elements.candidates.filter(candidate => {
      if (!this.isChecked(candidate)) { return candidate; }
    });
    checkCandidates.forEach(candidate => this.checkCandidate(candidate));
  }

  areAllChecked() {
    const pageSelection = this.elements.candidates.filter(candidate => this.isChecked(candidate));
    return pageSelection.length === this.elements.candidates.length;
  }

}
