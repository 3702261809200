import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Filter } from '../../library/classes/filter';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {


  style: any = {};

  @Input() candidatesFilter: boolean = false;
  @Input() jobFilter: boolean = false;

  @Input() templatesFilter: boolean;
  @Input() client: number;

  @Output() appliedFilters = new EventEmitter<Filter[]>();

  constructor() { }

  ngOnInit() { }

  showPophover($event) {

    this.style.position = 'absolute';
    this.style.display = 'block';

    this.style.top = '45px';
    this.style.left = '-100px';
  }

  hidePophover($event) {
    if ($event == 'hide') {
      this.style.display = 'none';
    } else if ($event.srcElement.className && $event.srcElement.className.baseVal === undefined && !$event.srcElement.className.includes('mat-') && !$event.srcElement.className.includes('cdk-')) {
      this.style.display = 'none';
    }
  }

  filter(parameters: Filter[]) {
    this.appliedFilters.emit(parameters);
    this.hidePophover('hide');
  }
}
