import { Client } from './../../partners/models/client';
import { TagsService } from './../../services/tag.service';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Filter } from '../../library/classes/filter';
import { Tag } from '../../classes/tag';

@Injectable({
  providedIn: 'root'
})
export class TagsControlService {

  private clientUuid: string;
  private clientId: number;

  availableTags$: BehaviorSubject<Tag[]> = new BehaviorSubject([]);
  filteredTags$: BehaviorSubject<Tag[]> = new BehaviorSubject([]);

  filterTagsStream$: Subject<Filter[]> = new Subject();

  constructor(
    private tagsService: TagsService,
  ) {
    this.subscribeToFilterTags();
  }

  setNewGroup(client: Client) {
    if (client.uuid && client.uuid !== this.clientUuid) {
      this.clientUuid = client.uuid;
      this.clientId = client.id;
      this.getAvailableTags();
    }
  }

  getClientUuid() {
    return this.clientUuid;
  }

  getClientId() {
    return this.clientId;
  }

  getAvailableTags() {
    this.tagsService.getTags(this.clientUuid).subscribe(
      tags => this.emitAvailableTags(tags.body)
    );
  }

  /* SEARCH */

  subscribeToFilterTags() {
    this.filterTagsStream$.pipe(
      switchMap(
        filters => this.tagsService.getTags(this.clientUuid, ...filters)
      ),
    ).subscribe(
      tags => this.emitFilteredTags(tags.body)
    );
  }

  filterTags(searchTerm: string) {
    const filters = [new Filter('search', searchTerm)];
    this.filterTagsStream$.next(filters);
  }

  /* EMITERS */

  emitAvailableTags(tags: Tag[]) {
    this.availableTags$.next(tags);
  }

  emitFilteredTags(tags: Tag[]) {
    this.availableTags$.next(tags);
  }
}
