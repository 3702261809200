import { Tag } from "./tag";

export interface Timeline {
  id: number;
  candidate: number;
  created: Date;
  message: string;
  owner: null | {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
  };
  params: {
    job?: number;
    job_name?: string;
    content?: string;
    subject?: string;
    status?: string;
    transition?: string;
    previous_status?: string;
    previous_transition?: string;
    interview?: string;
    privacy?: string;
    comment_id?: number;
    tag?: number;
    candidate_tag?: number;
    highlight?: boolean;
  }
}

export interface SerializedTimelineEvent {
  id: number;
  title: string;
  icon: string;
  text: string;
  status: string;
  previous_status: string;
  owner: string;
  date: Date;
  message: string;
  dropdown: boolean;
  privacy: string;
  job: string;
  job_id: number;
  comment_id: number;
  tag: Tag;
  previous_transition: string;
  transition: string;
  is_highlight: boolean;
}

class TimelineEvents {
  title: string;
  icon: string;
  params: any[];
  text: string;
  dropdown: boolean;

  constructor(
    title: string,
    icon: string,
    params: string[],
    text: string,
    dropdown: boolean) {
    this.title = title;
    this.icon = icon;
    this.params = params;
    this.text = text;
    this.dropdown = dropdown;
  }

}

export const TimelineDictionary = {
  apply: new TimelineEvents(
    '__candidateAppliedJobTimeline',
    'assignment_ind',
    [],
    '',
    false
  ),
  send_mail: new TimelineEvents(
    '__emailSentTimeline',
    'email',
    [ 'subject', 'content' ],
    '__emailSentTextTimeline',
    true
  ),
  comment: new TimelineEvents(
    'Comment',
    'chat',
    [ 'content' ],
    '__candidateCommentTextTimeline',
    false
  ),
  mark: new TimelineEvents(
    '__newTaskTimeline',
    'assignment_ind',
    [ 'message' ],
    '__candidateTaskChangedTextTimeline',
    false
  ),
  create_share_token: new TimelineEvents(
    '__candidateSharedTimeline',
    'share',
    [],
    '',
    false
  ),
  change_status: new TimelineEvents(
    '__candidateStatusChangedTimeline',
    'forward',
    [],
    '',
    false
  ),
  change_transition: new TimelineEvents(
    '__candidatePhaseChangedTimeline',
    'forward',
    [],
    '__candidatePhaseChangedTextTimeline_',
    false
  ),
  evaluate_interview: new TimelineEvents(
    '__candidateInterviewedTimeline',
    'record_voice_over',
    [ 'interview' ],
    '__candidateInteviewedTextTimeline',
    false
  ),
  rate_candidate: new TimelineEvents(
    '__candidateRatedTimeline',
    'star',
    [ 'rate' ],
    '__candidateRatedTextTimeline',
    false
  ),
  passed_INS: new TimelineEvents(
    '__candidatePassedInsTimelineTag',
    'check',
    [],
    '',
    false
  ),
  passed_CAN: new TimelineEvents(
    '__candidatePassedCanTimelineTag',
    'check',
    [],
    '',
    false
  ),
  passed_PHO: new TimelineEvents(
    '__candidatePassedPhoTimelineTag',
    'check',
    [],
    '',
    false
  ),
  passed_HR: new TimelineEvents(
    '__candidatePassedHrTimelineTag',
    'check',
    [],
    '',
    false
  ),
  passed_OA: new TimelineEvents(
    '__candidatePassedOATimelineTag',
    'check',
    [],
    '',
    false
  ),
  passed_BUS: new TimelineEvents(
    '__candidatePassedBusTimelineTag',
    'check',
    [],
    '',
    false
  ),
  passed_FIN: new TimelineEvents(
    '__candidatePassedFinTimeline',
    'check',
    [],
    '',
    false
  ),

  transitioned_CAN: new TimelineEvents(
    '__candidateTransitionedCanTimeline',
    'forward',
    [],
    '',
    false
  ),
  transitioned_PHO: new TimelineEvents(
    '__candidateTransitionedPhoTimeline',
    'forward',
    [],
    '',
    false
  ),
  transitioned_HR: new TimelineEvents(
    '__candidateTransitionedHrTimeline',
    'forward',
    [],
    '',
    false
  ),
  transitioned_OA: new TimelineEvents(
    '__candidateTransitionedOATimeline',
    'forward',
    [],
    '',
    false
  ),
  transitioned_BUS: new TimelineEvents(
    'candidateTransitionedBusTimeline',
    'forward',
    [],
    '',
    false
  ),
  killed_INS: new TimelineEvents(
    '__candidateKilledInsTimelineTag',
    'clear',
    [],
    '',
    false
  ),
  killed_CAN: new TimelineEvents(
    '__candidateKilledCanTimelineTag',
    'clear',
    [],
    '',
    false
  ),
  killed_PHO: new TimelineEvents(
    '__candidateKilledPhoTimelineTag',
    'clear',
    [],
    '',
    false
  ),
  killed_HR: new TimelineEvents(
    '__candidateKilledHrTimelineTag',
    'clear',
    [],
    '',
    false
  ),
  killed_OA: new TimelineEvents(
    '__candidateKilledOATimelineTag',
    'clear',
    [],
    '',
    false
  ),
  killed_BUS: new TimelineEvents(
    '__candidateKilledBusTimelineTag',
    'clear',
    [],
    '',
    false
  ),
  assign_tag: new TimelineEvents(
    '__candidateTagged',
    'new_label',
    [],
    '',
    false
  ),
  unassign_tag: new TimelineEvents(
    '__candidateUntagged',
    'label_off',
    [],
    '',
    false
  ),
}

export const PhaseAndStatusToText = {
  INS: 'Inscription',
  CAN: 'Candidature',
  IWS: 'Interview',
  FIN: 'Finalist',
  HIR: 'Hired',
  PND: 'PENDING',
  KO: 'KO',
  PASS: 'PASS',
};
