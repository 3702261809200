import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ClientAnalyticsService } from './../../../services/client-analytics.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Filter } from '../../../library/classes/filter';
import { COLOR_VARIABLES } from './../../../utils/color-variables';

@Component({
  selector: 'app-partner-analytics-instruments',
  templateUrl: './partner-analytics-instruments.component.html',
  styleUrls: ['./partner-analytics-instruments.component.scss']
})
export class PartnerAnalyticsInstrumentsComponent implements OnInit, OnDestroy {
  _parameters: Filter[];

  @Input() set parameters(parameters: Filter[]) {
    this._parameters = parameters;
    this.getInstrumentsData();
  }

  get parameters(): Filter[] {
    return this._parameters;
  }

  loading = false;

  availableProviders: { name: string }[] = [];
  selectedProviderName: string;

  instrumentsByProvider: { labels: string[], data: { data: number[], name: string }[], total: number } = { labels: [], data: [], total: 0 };

  allInstrumentsData: {
    [key: string]: {
      count: number,
      instrument_name: string,
      instrument_id: number
    }[]
  } = {};

  noAvailableData = false;

  chartConfig = {
    chart: {
      events: {
        load: function () {
          const categoryHeight = 35;
          this.update({
            chart: {
              height: categoryHeight * this.pointCount + (this.chartHeight - this.plotHeight)
            }
          });
        }
      }
    },
    tooltip: {
      formatter: function () {
        let tooltipText = '<span><b>' + this.points[0].key + '</b></span><table>';
        this.points.forEach(element => {
          tooltipText += `<tr><td style="padding:0; color: ${element.series.color}"'>  ${element.series.name}: ${element.y}</td></tr>`;
        });
        return tooltipText;
      },
      shared: true,
      useHTML: true
    },
    colors: [COLOR_VARIABLES['$_AUX_GREY_15']],
    legend: {
      labelFormatter: function () {
        const sum = this.yData.reduce(function (total, actual) { return total + actual; }, 0);
        return this.name + ' <span style="color:' + this.color + '">' + sum + '</span>';
      }
    },
    plotOptions: {
      series: {
        groupPadding: 0.5,
        borderWidth: 0,
        stacking: 'normal',
        pointWidth: 20,
      }
    },
    xAxis: {
      labels: {
        style: {
          color: COLOR_VARIABLES['$_TOOLBAR_COLOR'],
        }
      }
    },
  };


  componentSubscriptions: { [key: string]: Subscription } = {};

  constructor(
    private clientAnalyticsService: ClientAnalyticsService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getInstrumentsData() {
    setTimeout(() => this.loading = true, 0);

    this.componentSubscriptions.instrumentsByProvider$ = this.clientAnalyticsService.getGroupAnalyticsInstrumentsByProvider(...this.parameters).subscribe(
      data => setTimeout(() => this.serializeInstrumentsData(data), 300),
      error => this.loading = false
    );
  }

  serializeInstrumentsData(data: {
    [key: string]: {
      count: number;
      instrument_name: string;
      instrument_id: number;
    }[];
  }) {
    this.allInstrumentsData = Object.keys(data).reduce((result, currentProvider) => {
      if (currentProvider !== 'form') {
        result[currentProvider] = data[currentProvider];
      }
      return result;
    }, {});

    const idealOrder = ['cute', 'disney', 'vidassess'];

    this.availableProviders = Object.keys(this.allInstrumentsData).sort((a, b) => idealOrder.indexOf(a) - idealOrder.indexOf(b)).map(provider => ({ name: provider }));

    if (this.availableProviders.length) {
      this.selectProvider(this.availableProviders[0].name);
      this.noAvailableData = false;
    } else {
      this.noAvailableData = true;
    }

    setTimeout(() => this.loading = false, 0);
  }

  setChartData(data: {
    count: number,
    instrument_name: string,
    instrument_id: number
  }[]
  ) {
    this.instrumentsByProvider.labels = data.map(instrument => this.translateService.instant(instrument.instrument_name));
    this.instrumentsByProvider.data = [
      { data: data.map(instrument => instrument.count), name: this.translateService.instant('__used') }
    ];

    this.instrumentsByProvider.total = data.reduce((result, currentInstrument) => result + currentInstrument.count, 0);
  }

  selectProvider(provider: string) {
    this.selectedProviderName = provider;
    this.setChartData(this.allInstrumentsData[provider]);
  }

}
