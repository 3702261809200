export class Interview {
  name: string;
  slug: string;
  form: any;
  js?: string;
  selected?: boolean;

  constructor(name = '', form = [], slug = '') {
    this.name = name;
    this.form = form;
    this.slug = slug;
  }
}

export class InterviewGuides {
    PHO: Interview[] = [];
    HR: Interview[] = [];
    OA: Interview[] = [];
    BUS: Interview[] = [];
}

export class InterviewAnswers {
  answers: Object;
  status: string;
}
export const InterviewStatus = {
  PASS: { value: 'PASS', label: 'Suitable'},
  STANDBY: { value: 'STANDBY', label: 'Stand-by'},
  KO: { value: 'KO', label: 'Failed'},
};
