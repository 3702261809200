import { Filter } from '../../library/classes/filter';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { UserService } from '../../services/user-service.service';
import { ProfessionService } from '../../services/profession.service';
import { AlertsService } from '../../services/alerts.service';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { LayoutService, LayoutActions } from '../../services/layout.service';
import { JobService } from '../../services/job.service';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';
import { delayedRetry, handleDisconnectedError } from '../../utils';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { SidebarHeaderComponent } from '../sidebar-header/sidebar-header.component';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar-candidates-pool',
  templateUrl: './sidebar-candidates-pool.component.html',
  styleUrls: ['./sidebar-candidates-pool.component.scss']
})
export class SidebarCandidatesPoolComponent implements OnInit, OnDestroy {

  @ViewChild(SidebarHeaderComponent, { static: true }) child: SidebarHeaderComponent;

  ks: string;
  subscription: Subscription;
  buttonCollapse = false;
  loading = true;

  parameters: Filter[] = [];
  client: Client = new Client();
  queryParams: any = { queryParams: {} };
  professions: any[] = [];
  total_jobs: number;
  total_candidates: number;

  link_active = 'candidates';
  profession_active: string = null;
  candidateSelected = 'open-sidebar';
  toggle_sidebar = true;

  modalRef: BsModalRef;

  scrollHeight = 0;
  professionsPage = '1';
  moreProfessions: boolean;

  componentSubscriptions: any = {};

  constructor(
    public jobService: JobService,
    private router: Router,
    public userService: UserService,
    public clientService: ClientService,
    private professionService: ProfessionService,
    public activatedRoute: ActivatedRoute,
    private keyboardShortcutService: KeyboardShortcutService,
    public layoutService: LayoutService,
    public translateService: TranslateService,
    private alertService: AlertsService,
  ) { }

  ngOnInit() {
    this.client.id = +this.activatedRoute.snapshot.paramMap.get('client');
    this.profession_active = this.activatedRoute.firstChild ? `${this.activatedRoute.firstChild.snapshot.paramMap.get('template')}` : '';
    this.setData();
    this.subscribeRouter();
    this.subscribeCandidateParam();
    this.componentSubscriptions.keyboardShortcutServiceCommandsFilter$ = this.keyboardShortcutService.commands
      .pipe(
        filter(c => c.name.includes('SidebarCandidatesPoolComponent'))
      )
      .subscribe(c => {
        this.handleKeyboardShortcut(c);
      });
  }

  setData() {

    this.componentSubscriptions.getDataPipe$ = this.getData()
      .pipe(
        delayedRetry(3000, 3)
      )
      .subscribe(data => {
        this.client = data.client;
        this.total_jobs = data.professionsList.headers.get('Count');
        this.total_candidates = data.professionsList.body.total_candidates;
        this.getPaginatedJobs(data.professionsList);
        this.professionService.saveProfessionsFromGroupToLocal(data.professionsList.body);
      },
        err => {
          handleDisconnectedError(err, this.alertService);
        }
      );
  }

  getData(): Observable<any> {
    const data = combineLatest(
      ...this.getRequiredObservables(),
      (client, professionsList) => ({
        client,
        professionsList,
      })
    );

    return data;
  }

  getPaginatedJobs(data) {
    this.moreProfessions = data.headers.get('Next-Page') !== 'None' ? true : false;
    this.professions = this.professions.concat(data.body.professions);
    this.loading = false;
  }

  getRequiredObservables() {

    const observablesArray = [
      this.clientService.loadClient(this.client.id),
      this.clientService.getProfessionsAndJobsByClient(this.client.id, new Filter('page', this.professionsPage))
    ];

    return observablesArray;

  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'SidebarCandidatesPoolComponent.Down':
        this.goToNextProfession();
        break;
      case 'SidebarCandidatesPoolComponent.Up':
        this.goToPreviousProfession();
        break;
    }
  }

  goToNextProfession() {
    if (!this.activatedRoute.snapshot.paramMap.get('template')) {
      let index = this.professions.findIndex(profession => profession.profession.id === +this.profession_active);
      if (index >= 0) {
        if (++index < this.professions.length) {
          this.router.navigateByUrl(`/candidates-pool/${this.client.id}/template/${this.professions[index].profession.id}`);
        }

      } else {
        this.router.navigateByUrl(`/candidates-pool/${this.client.id}/template/${this.professions[0].profession.id}`);

      }
    }
  }

  goToPreviousProfession() {
    if (!this.activatedRoute.snapshot.queryParams['candidate']) {
      let index = this.professions.findIndex(profession => profession.profession.id === +this.profession_active);
      if (index > 0) {
        this.router.navigateByUrl(`/candidates-pool/${this.client.id}/template/${this.professions[index - 1].profession.id}`);
      } else {
        this.router.navigateByUrl(`/candidates-pool/${this.client.id}`);

      }
    }
  }

  subscribeRouter() {
    this.componentSubscriptions.routerEventsFilterPipe$ = this.router.events.pipe(
      filter(url => url instanceof NavigationEnd)
    )
      .pipe(map(
        (url: NavigationEnd) => this.router.parseUrl(url.url)
          .root.children[PRIMARY_OUTLET].segments
      ))
      .subscribe(
        url => {
          if (url[0].path === 'candidates-pool') {
            this.profession_active = this.activatedRoute.firstChild.snapshot.paramMap.get('template');
          }
        }
      );
  }

  subscribeCandidateParam() {
    this.componentSubscriptions.activatedRouteQueryParamMap$ = this.activatedRoute.queryParamMap.subscribe(
      queryParam => {
        Boolean(queryParam.get('candidate')) ?
          this.layoutService.handleLayoutServiceAction(LayoutActions.COLLAPSE_JOBS_SIDEBAR) :
          this.layoutService.handleLayoutServiceAction(LayoutActions.EXPAND_JOBS_SIDEBAR);
      }
    );
  }

  scrollHandler(e) {

    if (e.target.scrollTop < this.scrollHeight && e.target.scrollTop === 0) {
      this.child.normalizeHeader();
    } else if (e.target.scrollTop > 0) {
      this.child.collapseHeader();
    }

    this.scrollHeight = e.target.scrollTop;

  }

  showMoreProfessions() {

    this.loading = true;

    this.componentSubscriptions.clientServiceGetProfessionsAndJobsByClientPipe$ = this.clientService.getProfessionsAndJobsByClient(this.client.id, new Filter('page', `${Number(this.professionsPage) + 1}`))
      .pipe(delayedRetry(3000, 3))
      .subscribe(data => this.getPaginatedJobs(data));
  }
}
