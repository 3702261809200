import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { NavigationExtras } from '@angular/router';

@Injectable()
export class LayoutService {

  constructor() { }

  private jobDashboardStatus = {
    jobsSidebarCollapsed: false,
    candidatesSidebarCollapsed: false,
    candidateActionOpen: false,
    candidateActionCollapsed: false,
    candidateActionCloseAttempt: {
      status: false,
      navigateRoute: {route: [], extras: {}}
    },
  };

  jobDashboardSubjects = {
    jobsSidebarCollapsed: new BehaviorSubject<boolean>(this.jobDashboardStatus.jobsSidebarCollapsed),
    candidatesSidebarCollapsed: new BehaviorSubject<boolean>(this.jobDashboardStatus.candidatesSidebarCollapsed),
    candidateActionOpen: new BehaviorSubject<boolean>(this.jobDashboardStatus.candidateActionOpen),
    candidateActionCollapsed: new BehaviorSubject<boolean>(this.jobDashboardStatus.candidateActionCollapsed),
    candidateActionCloseAttempt: new BehaviorSubject<{
      status: boolean,
      navigateRoute: {route: any[], extras: NavigationExtras}
    }>(this.jobDashboardStatus.candidateActionCloseAttempt)
  };

  private setCandidatesSidebarCollapseStatus(status: boolean) {
    this.jobDashboardStatus.candidatesSidebarCollapsed = status;
  }

  private setCandidateActionOpenStatus(status: boolean) {
    this.jobDashboardStatus.candidateActionOpen = status;
  }

  private setCandidateActionCloseAttemptStatus(
    status: boolean,
    navigateRoute: {route: any[], extras: NavigationExtras} = null
  ) {
    this.jobDashboardStatus.candidateActionCloseAttempt = {status, navigateRoute};
  }

  private setCandidateActionCollapseStatus(status: boolean) {
    this.jobDashboardStatus.candidateActionCollapsed = status;
  }

  private setjobsSidebarCollapseStatus(status: boolean) {
    this.jobDashboardStatus.jobsSidebarCollapsed = status;
  }

  private emitJobDashboardStatus() {
    Object.keys(this.jobDashboardStatus).forEach(key => {
      this.jobDashboardSubjects[key].next(this.jobDashboardStatus[key]);
    });
  }

  private resetInitialJobDashboardStatus() {
    this.setCandidateActionOpenStatus(false);
    this.setCandidateActionCollapseStatus(false);
    this.setCandidatesSidebarCollapseStatus(false);
    this.setjobsSidebarCollapseStatus(false);
    this.setCandidateActionCloseAttemptStatus(false, null);
  }

  private openCandidateAction() {
    this.setCandidateActionOpenStatus(true);
    this.setCandidateActionCollapseStatus(false);
    this.setjobsSidebarCollapseStatus(true);
    this.setCandidatesSidebarCollapseStatus(true);
  }

  private closeCandidateAction() {
    this.setCandidateActionOpenStatus(false);
    this.setCandidateActionCollapseStatus(false);
    this.setCandidatesSidebarCollapseStatus(false);
    this.setCandidateActionCloseAttemptStatus(false, null);
  }

  private attemptCandidateActionClose(navigateRoute?: {route: any[], extras: NavigationExtras}) {
    this.setCandidateActionCloseAttemptStatus(true, navigateRoute);
  }

  private resetAttemptCandidateActionClose() {
    this.setCandidateActionCloseAttemptStatus(false, null);
  }

  private expandCandidateAction() {
    this.setCandidateActionOpenStatus(true);
    this.setCandidateActionCollapseStatus(false);
    this.setCandidatesSidebarCollapseStatus(true);
  }

  private collapseCandidateAction() {
    this.setCandidateActionOpenStatus(true);
    this.setCandidateActionCollapseStatus(true);
  }

  private expandCandidatesSidebar() {
    this.setCandidatesSidebarCollapseStatus(false);
    if (this.jobDashboardStatus.candidateActionOpen === true) {
      this.setCandidateActionCollapseStatus(true);
    }
  }

  private expandJobsSidebar() {
    this.setjobsSidebarCollapseStatus(false);
  }

  private collapseJobsSidebar() {
    this.setjobsSidebarCollapseStatus(true);
  }

  private toggleJobsSidebar() {
    this.jobDashboardStatus.jobsSidebarCollapsed ? this.expandJobsSidebar() : this.collapseJobsSidebar();
  }

  handleLayoutServiceAction(action: string, navigateRoute: {route: any[], extras: NavigationExtras} = null) {
    switch (action) {
      case LayoutActions.RESET_JOB_DASHBOARD:
        this.resetInitialJobDashboardStatus();
        break;
      case LayoutActions.OPEN_CANDIDATE_ACTION:
        this.openCandidateAction();
        break;
      case LayoutActions.CLOSE_CANDIDATE_ACTION:
        this.closeCandidateAction();
        break;
      case LayoutActions.ATTEMPT_CANDIDATE_ACTION_CLOSE:
        this.attemptCandidateActionClose(navigateRoute);
        break;
      case LayoutActions.RESET_ATTEMPT_CANDIDATE_ACTION_CLOSE:
        this.resetAttemptCandidateActionClose();
        break;
      case LayoutActions.EXPAND_CANDIDATE_ACTION:
        this.expandCandidateAction();
        break;
      case LayoutActions.COLLAPSE_CANDIDATE_ACTION:
        this.collapseCandidateAction();
        break;
      case LayoutActions.EXPAND_CANDIDATES_SIDEBAR:
        this.expandCandidatesSidebar();
        break;
      case LayoutActions.EXPAND_JOBS_SIDEBAR:
        this.expandJobsSidebar();
        break;
      case LayoutActions.COLLAPSE_JOBS_SIDEBAR:
        this.collapseJobsSidebar();
        break;
      case LayoutActions.TOGGLE_JOBS_SIDEBAR:
        this.toggleJobsSidebar();
        break;
    }
    this.emitJobDashboardStatus();
  }

  isCandidateActionOpen(): boolean {
    return this.jobDashboardStatus.candidateActionOpen;
  }

}

export const LayoutActions = {
  RESET_JOB_DASHBOARD: 'RESET_JOB_DASHBOARD',
  OPEN_CANDIDATE_ACTION: 'OPEN_CANDIDATE_ACTION',
  CLOSE_CANDIDATE_ACTION: 'CLOSE_CANDIDATE_ACTION',
  ATTEMPT_CANDIDATE_ACTION_CLOSE: 'ATTEMPT_CANDIDATE_ACTION_CLOSE',
  RESET_ATTEMPT_CANDIDATE_ACTION_CLOSE: 'RESET_ATTEMPT_CANDIDATE_ACTION_CLOSE',
  EXPAND_CANDIDATE_ACTION: 'EXPAND_CANDIDATE_ACTION',
  COLLAPSE_CANDIDATE_ACTION: 'COLLAPSE_CANDIDATE_ACTION',
  EXPAND_CANDIDATES_SIDEBAR: 'EXPAND_CANDIDATES_SIDEBAR',
  EXPAND_JOBS_SIDEBAR: 'EXPAND_JOBS_SIDEBAR',
  COLLAPSE_JOBS_SIDEBAR: 'COLLAPSE_JOBS_SIDEBAR',
  TOGGLE_JOBS_SIDEBAR: 'TOGGLE_JOBS_SIDEBAR'
};
