import { Instrument } from './instrument';
import { IRestModel } from './../../library/models/rest-model';
import { FormTemplate } from '../../classes/form';

export class FormJob implements IRestModel {
  id: number;
  config: {
    name: string,
    slug ? : string,
    description: string,
    form: any[],
    form_template_id: number
  };

  static fromFormTemplate(formTemplate: FormTemplate, primitiveFormInstrument: Instrument): FormJob {
    const formInstrument = new FormJob();
    formInstrument.id = primitiveFormInstrument.id;
    formInstrument.config = {
      form_template_id: formTemplate.id,
      name: formTemplate.name,
      description: formTemplate.description,
      form: formTemplate.content
    };
    return formInstrument;
  }
}
