import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { setFilter } from '../../../library/utils';
import { Filter } from '../../../library/classes/filter';
import { Client } from '../../models/client';

@Component({
  selector: 'app-partner-analytics',
  templateUrl: './partner-analytics.component.html',
  styleUrls: ['./partner-analytics.component.scss']
})
export class PartnerAnalyticsComponent implements OnInit {

  _partnerId: number;
  @Input() set partnerId(partnerId: number) {
    this._partnerId = partnerId;
    this.setPartnerFilter();
  }

  @Input() clients: Client[] = [];

  get partnerId(): number {
    return this._partnerId;
  }

  closeFilters = true;
  parameters: Filter[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.partnerId = +this.activatedRoute.snapshot.queryParams.parent;
    this.setPartnerFilter();
  }

  setPartnerFilter() {
    this.parameters = setFilter(this.parameters, new Filter('partners', this.partnerId));
  }

  filter(filters: Filter[]) {
    this.parameters = [];
    this.setPartnerFilter();

    filters.forEach(filter => {
      this.parameters = setFilter(this.parameters, filter);
    });
  }

}
