import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from '../../services/alerts.service';
import { ReputationAnalyticsDictionary, ReputationAnalyticsIcons } from '../../classes/client-analytics';
import { Client } from '../../partners/models/client';
import { ClientService } from '../../partners/services/client.service';

@Component({
  selector: 'app-reputation-analytics',
  templateUrl: './reputation-analytics.component.html',
  styleUrls: ['./reputation-analytics.component.scss']
})
export class ReputationAnalyticsComponent implements OnInit, OnDestroy {

  client_id: number;
  client: Client;
  reputationAnalytics: {
    title: string,
    values: {
      company: string,
      index: number,
      icon: string
    }[]
  }[] = [];

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private alertService: AlertsService
  ) { }

  ngOnInit() {
    this.client_id = +this.activatedRoute.snapshot.paramMap.get('client')
    this.getClientData();
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  getClientData() {

    this.componentSubscriptions.clientServiceLoadClient$ = this.clientService.loadClient(this.client_id).subscribe(
      response => {
        this.client = response;
        this.reputationAnalytics = this.parseReputationAnalytics(response.private_extra_config.reputation_analytics.data)
      },
      error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
    )
  }

  parseReputationAnalytics(reputationData) {
    const parsedReputationData = ReputationAnalyticsDictionary.map(reputationType => {
      const reputationTypeData = {
        title: reputationType.title,
        values: this._parseCompanyData(reputationData, reputationType)
      }
      return reputationTypeData;
    })
    return parsedReputationData;
  }

  _parseCompanyData(reputationData, reputationType): { company: string, index: number, icon: string }[] {
    return reputationData.map(dataBlock => {
      return {
        company: dataBlock.name,
        index: dataBlock[reputationType.values.index],
        icon: ReputationAnalyticsIcons[dataBlock[reputationType.values.icon]]
      }
    })
  }

}
