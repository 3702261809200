import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-candidate-interview-videoask',
  templateUrl: './candidate-interview-videoask.component.html',
  styleUrls: [ './candidate-interview-videoask.component.scss' ]
})
export class CandidateInterviewVideoaskComponent implements OnInit {

  @Input() report: any;
  name: string;
  videoInterview: any;
  constructor() { }

  ngOnInit() {
    this.name = Object.keys(this.report)[ 0 ];
    this.report = Object.values(this.report)[ 0 ];
    this.serializeVideoAskReport();
  }

  serializeVideoAskReport() {
    if (this.report.report) {
      this.videoInterview = Object.values(this.report.report.VIDEOASK)
        .sort((questionPrev: any, questionCurr: any) => questionPrev.order - questionCurr.order)
        .map((question: any) => {
          const data = question.texts.question;
          if (data.type === "poll" && typeof data.answer_text === "string") {
            data.answer_text = [ {
              content: data.answer_text
            }];
          }
          return data;
        });
    }
  }


}
