import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Candidate } from '../../classes/candidate';
import { CandidateService } from '../../services/candidate.service';
import { Observable } from 'rxjs';
import { InstrumentService } from '../../instruments/instrument.service';
import { AlertsService } from '../../services/alerts.service';
import { FormJobService } from '../../instruments/form-job.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-candidate-instrument-form',
  templateUrl: './candidate-instrument-form.component.html',
  styleUrls: ['./candidate-instrument-form.component.scss']
})
export class CandidateInstrumentFormComponent implements OnInit, OnDestroy {
  candidate: Candidate = new Candidate();
  formTemplate = null;
  candidate_uuid = '';
  form_slug = '';
  loading = false;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private candidateService: CandidateService,
    private instrumentService: InstrumentService,
    private alertService: AlertsService,
    private formJobService: FormJobService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.candidate_uuid = this.activatedRoute.snapshot.paramMap.get('uuid');
    this.form_slug = this.activatedRoute.snapshot.paramMap.get('form_slug');
    this.setupForm();
    //this.getCandidateDetail(this.candidate_uuid);
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }

  setupForm() {
    this.componentSubscriptions.formJobServiceRetrieve$ = this.formJobService.retrieve(this.candidate_uuid, this.form_slug).subscribe(
      formTemplate => {
        this.formTemplate = formTemplate;
      },
      error => window.location.href = error.error
    );
  }

  getCandidateDetail(candidateUuid: string) {
    if (candidateUuid) {
      this.loading = true;
      this.componentSubscriptions.candidateServiceGetCandidate$ = this.candidateService.getCandidate(candidateUuid)
        .subscribe(
          candidate => {
            this._setCandidate(candidate);
            this.loading = false;
          },
          error => this.loading = false,
        );
    }
  }

  private _setCandidate(candidate: Candidate) {
    this.candidate = candidate;
  }

  saveForm(event) {
    this.loading = true;
    this.componentSubscriptions.formJobServiceSaveCandidateAnswers$ = this.formJobService.saveCandidateAnswers(event, this.candidate_uuid, this.form_slug).subscribe(
      redirectUrl => {
        this.loading = false;
        window.location.href = redirectUrl;
      },
      error => {
        this.loading = false;
        const cantSaveResponses = this.translateService.instant('__cantSaveResponses');
        this.alertService.setAlert({ type: 'error', message: cantSaveResponses });
      }
    );
  }
}
