import { JobCategory, JobCategoryTemplate } from './../../classes/job';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Client } from '../../partners/models/client';

import { ClientService } from '../../partners/services/client.service';
import { UserService } from '../../services/user-service.service';

import { Router, ActivatedRoute } from '@angular/router';
import { KeyboardShortcut, KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { Subscription } from 'rxjs';
import { AlertsService } from '../../services/alerts.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EmailState } from '../../classes/wsgi-email';
import { INACTIVE, ACTIVE, INDUSTRIES } from '../../partners/constants';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-setting-client',
  templateUrl: './setting-client.component.html',
  styleUrls: ['./setting-client.component.scss']
})
export class SettingClientComponent implements OnInit, OnDestroy {
  ks: string;
  subscription: Subscription;
  numberOfTab: number;

  loading: boolean = true;

  client: Client = new Client();
  logoUrl = null;
  logoFile: File = null;
  editTerms = false;
  editBasicInfo = false;
  editSocialMedia = false;
  editIntegrations = false;
  categoriesEditable = false;
  information: any = {};
  emails: EmailState = new EmailState();
  email_id = 0;
  jobCategoriesTemplate: JobCategoryTemplate[] = [];

  publicMailSender = '';
  publicMailSenderOriginal = '';

  terms_ckeditor_config = {
    extraPlugins: 'divarea,colorbutton,copyformatting,font,justify',
    toolbar: [
      { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'CopyFormatting', 'RemoveFormat'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
      { name: 'links', items: ['Link', 'Unlink'] },
    ],
    width: '960px',
    height: '500px',
  };

  sections = [
    {
      title: 'basicInformation',
      content: ['name', 'description', 'contact', 'client_email', 'email_sender', 'support_email', 'tax_id', 'legal_advice', 'cookies_policy'],
      editable: false
    },
    {
      title: 'industry',
      content: ['industry'],
      editable: false
    },
    // TODO: The status will be available when a partner area is developed
    // {
    //   title: 'status',
    //   content: ['status'],
    //   editable: false,
    //   type: 'toggle'
    // }
  ];

  integrationSection = [
    {
      title: 'Cut-e',
      name: 'cute',
      content: ['project_id', 'client_id', 'secure_code'],
      editable: false
    },
    {
      title: 'Taleo',
      name: 'taleo',
      content: ['key'],
      editable: false
    },
    {
      title: '__successFactors',
      name: 'successFactors',
      content: ['key'],
      editable: false
    }
  ];

  industries_list = INDUSTRIES;

  componentSubscriptions: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    public clientService: ClientService,
    private router: Router,
    private keyboardShortcutService: KeyboardShortcutService,
    private alertService: AlertsService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.getClient();
    this.componentSubscriptions.keyboardShortcutServiceCommandsSubscribe$ = this.keyboardShortcutService.commands.subscribe(c => this.handleKeyboardShortcut(c));
  }

  ngOnDestroy() {
    Object.keys(this.componentSubscriptions).forEach(
      subscriptionKey => this.componentSubscriptions[subscriptionKey].unsubscribe()
    );
  }


  handleKeyboardShortcut(ks: KeyboardShortcut) {
    this.ks = ks.name;
    switch (ks.name) {
      case 'SettingClientComponent.AltJ': this.goToJobs(); break;
    }
  }

  goToJobs() {
    const client = this.activatedRoute.parent.snapshot.paramMap.get('client');
    this.router.navigate(['main', client]);
  }

  getClient() {
    this.formatInfo();
    const client_observable = this.activatedRoute.parent.paramMap.pipe(switchMap(params => this.clientService.loadClient(+params.get('client'))));
    const organization_observable = client_observable.pipe(switchMap(client => this.getOrganizationClient(client)));
    const emails_observable = organization_observable.pipe(switchMap(() => this.clientService.getClientEmails(this.client.id)));
    this.componentSubscriptions.emails_observablePipe$ = emails_observable.pipe(take(1)).subscribe(
      emails => {
        this.emails = emails.length > 0 ? emails[0].content : new EmailState();
      }
    );
  }

  putIndustry(industry) {
    this.client.industry = industry;
    this.componentSubscriptions.clientServiceUpdate$ = this.clientService.update(this.client).subscribe(
      response => {
        this.client = Object.assign(this.client, response);
        this.clientService.saveClient(this.client);
      },
      error => console.log(error)
    );
  }

  async getOrganizationClient(client) {
    this._setClient(client);
    this.clientService.getJobCategories(this.client.id).toPromise().then(
      response => {
        this.jobCategoriesTemplate = JobCategory.jobCategoryToTemplate(response)
        this.client.categories = response;
        this.clientService.saveClient(this.client);
      },
      error => console.log(error)
    );
  }

  _setClient(client: Client) {
    this.client = Object.assign(this.client, client);
    this.client.instruments_config = Object.assign(new Client().instruments_config, client.instruments_config || {});
    this.client.categories = null;
    this.publicMailSender = this.client.email_sender ? this.client.email_sender.split('@')[0] : '';
    this.publicMailSenderOriginal = this.publicMailSender;
    this.loading = false;
  }

  saveClient() {
    this.updateEntityEmailSender();
    this.componentSubscriptions.clientServiceUpdateClient$ = this.clientService.update(this.client).subscribe(
      client => {
        this.publicMailSenderOriginal = this.publicMailSender;
        this.client = Object.assign(this.client, client);
        this.clientService.saveClient(this.client);
        const translate_message = this.translateService.instant('__clientEditedCorrectly');
        this.alertService.setAlert({ type: 'success', message: translate_message });
      },
      error => {
        if (error.error && error.error.email_sender) {
          this.publicMailSender = this.publicMailSenderOriginal;
          this.client.email_sender = this.publicMailSender.concat('@aplygo.com');
        }
        this.alertService.setAlert(undefined, error)
      }
    );
  }

  updateEntityEmailSender() {
    this.client.email_sender = this.publicMailSender.concat('@aplygo.com');
  }

  saveEmails(emails) {
    this.clientService.setClientEmails(emails, EmailState.getEmaiTemplate(this.client, emails, this.email_id)).then(
      response => {
        this.emails = response.content;
      },
      error => this.alertService.setAlert(this.alertService.formatErrorMessage(error))
    );
  }

  saveAppearance(appearance) {
    this.client.extra_config.appearance = appearance;
    this.client.logo = appearance.logo;
    this.saveClient();
  }

  toggleStatusClient(event) {
    this.client.status = event.checked ? ACTIVE : INACTIVE;
    this.componentSubscriptions.clientServiceUpdateToggleStatusClient$ = this.clientService.update(this.client).subscribe(
      () => this.clientService.saveClient(this.client),
      err => this.alertService.setAlert(this.alertService.formatErrorMessage(err))
    );
  }

  toggleBasicInfo(section) {
    if (section.editable) {
      this.saveClient();
    }
    section.editable = !section.editable;
  }

  toggleSocialMedia() {
    if (this.editSocialMedia) {
      this.saveClient();
    }
    this.editSocialMedia = !this.editSocialMedia;
  }

  toggleEditTerms() {
    if (this.editTerms) {
      this.saveClient();
    }
    this.editTerms = !this.editTerms;
  }

  toggleEditIntegrations(section) {
    if (section.editable) {
      this.saveClient();
    }
    section.editable = !section.editable;
  }

  formatInfo() {
    this.jobCategoriesTemplate = JobCategory.startJobCategories();
  }

  changeJobCategories(category: JobCategoryTemplate, text) {
    category.values = text.trim().split('\n').map(value => new Object({ value }));
  }

  getPublicJobsUrl() {
    return `https://${this.client.site.domain}/#/jobs-hub/${this.client.uuid}`;
  }

  getPrivateJobsUrl() {
    return `https://${this.client.site.domain}/#/private-jobs-hub/${this.client.uuid}`;
  }

  changeTabName(event: MatTabChangeEvent) {
    this.numberOfTab = event.index;
  }
}
