import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { hexToRgbA } from '../../../library/utils'
import { Subscription } from '../../../partners/models/subscription';

@Component({
  selector: 'app-jobs-subscription-filter',
  templateUrl: './jobs-subscription-filter.component.html',
  styleUrls: ['./jobs-subscription-filter.component.scss']
})
export class JobsSubscriptionFilterComponent implements OnInit {

  @Input() filter: any;
  lightColor: string;
  checkedAll: boolean = false;
  @Output() changeFilter: EventEmitter<Subscription> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { color: string, filters: any, private: boolean, terms: any },
  ) { }

  ngOnInit() {
    this.setLightColor();
    this.setHover(this.data.color)
  }

  setLightColor() {
    this.lightColor = hexToRgbA(this.data.color);
  }

  setHover(color: string) {
    document.documentElement.style.setProperty('--color-custom', color);
  }

  checkAll() {
    if (this.checkedAll) {
      this.data.filters[this.filter].forEach(element => {
        element.checked = false;
      });
      this.checkedAll = false;
    }
    else {
      this.data.filters[this.filter].forEach(element => {
        element.checked = true;
      });
      this.checkedAll = true;
    }
  }

  checkin(checkbox) {
    checkbox.checked = !checkbox.checked
    if (this.checkedAll) {
      this.checkedAll = false;
    }

  }

  onChangeFilter() {
    this.changeFilter.emit();
  }


}


